import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import * as UserActions from "src/reducers/userReducer";
import Table from "src/components/structure/Table";
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
import { blue, lightBlue } from '@material-ui/core/colors';
import DatePicker from "src/components/structure/DatePicker";
import moment from "moment";
import { IPet } from "src/api/pets";
import { PetsAPI } from "src/api";
import { IColumns } from "src/utils/customTypes";
import DailyMetrics from "src/components/screens/Support/PetTabs/DailyMetrics";

const MetricSwitch = withStyles({
  switchBase: {
    color: lightBlue[300],
    '&$checked': {
      color: blue[500],
    },
    '&$checked + $track': {
      backgroundColor: blue[400],
    },
  },
  checked: {},
  track: {},
})(Switch);

interface IMetricsTabProps {
  userId: number;
  location: any;
  userActions: any;
  userState: any;
  pet: IPet;
}

interface IMetricsTabState {
  loading: boolean;
  userId: any;
  showDaily: boolean;
  start: moment.Moment;
  end: moment.Moment;
  columns: IColumns[];
  hourlyRows: any[];
}

class MetricsTab extends Component<IMetricsTabProps, IMetricsTabState> {
  constructor(props: IMetricsTabProps) {
    super(props);

    this.state = {
      loading: false,
      userId: "",
      showDaily: false,
      start: moment().subtract(14, "days"),
      end: moment().add(1, "day"),
      hourlyRows: [],
      columns: [
        {
          label: "Start Time",
          field: "startTime",
          sort: "asc",
        },
        {
          label: "Device ID",
          field: "deviceId",
          sort: "asc",
        },
        {
          label: "Fit Units",
          field: "fitUnits",
          sort: "asc",
        },
        {
          label: "Temp High",
          field: "tempHigh",
          sort: "asc",
        },
        {
          label: "Temp Low",
          field: "tempLow",
          sort: "asc",
        },
        {
          label: "HH Score",
          field: "healthHappinessScore",
          sort: "asc",
        },
        {
          label: "Steps",
          field: "steps",
          sort: "asc",
        },
        {
          label: "Cell Mins",
          field: "cellMinutes",
          sort: "asc",
        },
        {
          label: "Wi-fi Mins",
          field: "wifiMinutes",
          sort: "asc",
        },
        {
          label: "Companion Mins",
          field: "companionMinutes",
          sort: "asc",
        },
        {
          label: "Exercise Mins",
          field: "exerciseMinutes",
          sort: "asc",
        },
        {
          label: "Sleep Mins",
          field: "sleepMinutes",
          sort: "asc",
        },
        {
          label: "Manual Corr.",
          field: "manualCorrections",
          sort: "asc",
        },
        {
          label: "Geofence Corr.",
          field: "geofenceCorrections",
          sort: "asc",
        },
        {
          label: "Leash Corr.",
          field: "leashCorrections",
          sort: "asc",
        },
        {
          label: "Tagz Corr.",
          field: "shieldViolations",
          sort: "asc",
        },
      ],
    };

    this.handleEndDateChanged = this.handleEndDateChanged.bind(this);
    this.handleStartDateChanged = this.handleStartDateChanged.bind(this);
    this.handleSwitch = this.handleSwitch.bind(this);
  }

  componentDidMount() {
    this.getMetrics();
  }

  processStartTime(t: any) {
    const date = t.slice(5, 7) + "/" + t.slice(8, 10) + "/" + t.slice(2, 4);
    const time = t.slice(11, 16);
    return date + " " + time;
  }

  getMetrics() {
    this.setState({ loading: true }, async () => {
      try {
        const results = await PetsAPI.getPetMetrics(
          "wagz",
          this.props.userId,
          this.props.pet.id,
          {
            start: this.state.start.format("YYYY-MM-DD"),
            end: this.state.end.format("YYYY-MM-DD"),
          }
        );

        const petMetrics = [];
        for (let result of results.body.data) {
          petMetrics.push({
            ...result,
            deviceId: (
              <Link to={`/support/devices/${result.deviceId}`} id="device_page" style={{ color: "blue", textDecoration: "underline" }}>
                {result.deviceId}
              </Link>),
            startTime: this.processStartTime(result.startTime),
            tempHigh: result.temperature.high,
            tempLow: result.temperature.low,
            healthHappinessScore: result.healthHappinessScore.total,
          });
        }
        this.setState({
          loading: false,
          hourlyRows: petMetrics,
        });
      } catch (err) {
        this.setState({ loading: false });
      }
    });
  }

  render() {
    return (
      <div>
        <div style={{ display: 'flex', justifyContent:'flex-end', marginRight: 30, marginBottom: -30, marginTop: 30 }}>
          <p style={{ paddingTop: 5, fontSize: 20 }}>Hourly</p>
          <MetricSwitch checked={this.state.showDaily} onChange={this.handleSwitch}></MetricSwitch>
          <p style={{ paddingTop: 5, fontSize: 20 }}>Daily</p>
        </div>
        {!this.state.showDaily && (
          <div>
            <div
              className="col-12 col-lg-12"
              style={{ marginBottom: 30, marginTop: 15 }}
            >
              <div className="row">
                <div className="col-12">
                  <div className="row">
                    <label>From</label>
                    <div className="col-3">
                      <DatePicker
                        date={this.state.start}
                        onDateSaved={this.handleStartDateChanged}
                      />
                    </div>
                    <label>To</label>
                    <div className="col-3">
                      <DatePicker
                        date={this.state.end}
                        onDateSaved={this.handleEndDateChanged}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
                <p>* Note: Start Time is in UTC</p>
            <div className="row">
              <div className="col-12">
                <Table
                  title="Hourly Metrics"
                  csvFileName={`metricsFor-petID-${this.props.pet.id}`}
                  showDownload={true}
                  columns={this.state.columns}
                  rows={this.state.hourlyRows}
                />
              </div>
            </div>
          </div>
        )}
        {this.state.showDaily && (
          
          <div className="row">
            <div className="col-12">
              <DailyMetrics
                loading={this.state.loading}
                pet={this.props.pet.id}
                user={this.props.userId}
              ></DailyMetrics>
            </div>
          </div>
        )}
      </div>
    );
  }

  private handleStartDateChanged(newDate: moment.Moment) {
    this.setState({ start: newDate }, this.getMetrics);
  }

  private handleEndDateChanged(newDate: moment.Moment) {
    this.setState({ end: newDate }, this.getMetrics);
  }

  private handleSwitch() {
    this.setState({ showDaily: !this.state.showDaily });
  }
}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default connect<{}, {}, any>(
  mapStateToProps,
  mapDispatchToProps
)(MetricsTab);
