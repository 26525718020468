import { makeCall } from "./client";

export class AnalyticsAPI {
  
  /**
   * Get all events in range
   * @param app 
   * @param options 
   * @returns 
   */
  public getAnalyticEvents = (app: string, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('GET', `admin/${app}/analytics/events`, data);
  }

  /**
   * Gets the known events
   * @param app 
   * @param options 
   * @returns 
   */
  public getAnalyticKnownEvents = (app: string, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('GET', `admin/${app}/analytics/knownevents`, data);
  }

  /**
   * Gets the events for an event
   * @param app 
   * @param event 
   * @param options 
   * @returns 
   */
  public getAnalyticEventsForEvent = (app: string, event: string, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('GET', `admin/${app}/analytics/events/${event}`, data);
  }
  
  /**
   * Gets the events for a user
   * @param app 
   * @param userId 
   * @param options 
   * @returns 
   */
  public getAnalyticEventsForUser = (app: string, userId: number, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('GET', `admin/${app}/analytics/users/${userId}/events`, data);
  }
  
  /**
   * Gets the events for device
   * @param app 
   * @param deviceId 
   * @param options 
   * @returns 
   */
  public getAnalyticEventsForDevice = (app: string, deviceId: number, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('GET', `admin/${app}/analytics/devices/${deviceId}/events`, data);
  }
  
  /**
   * Gets the events for a pet
   * @param app 
   * @param petId 
   * @param options 
   * @returns 
   */
  public getAnalyticEventsForPet = (app: string, petId: number, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('GET', `admin/${app}/analytics/pets/${petId}/events`, data);
  }

  /**
   * Gets the locations for a platform
   * @param app 
   * @param options 
   * @returns 
   */
  public getAnalyticLocations = (app: string, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('GET', `admin/${app}/analytics/locations`, data);
  }

  /**
   * Gets the locations for a user
   * @param app 
   * @param userId 
   * @param options 
   * @returns 
   */
  public getAnalyticLocationsForUser = (app: string, userId: number, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('GET', `admin/${app}/analytics/users/${userId}/locations`, data);
  }

  /**
   * Get devices for a platform
   * @param app 
   * @param options 
   * @returns 
   */
  public getAnalyticDevices = (app: string, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('GET', `admin/${app}/analytics/devices`, data);
  }

  /**
   * Gets devices for a user
   * @param app 
   * @param userId 
   * @param options 
   * @returns 
   */
  public getAnalyticsDevicesForUser = (app: string, userId: number, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('GET', `admin/${app}/analytics/users/${userId}/devices`, data);
  }

  /**
   * Get the github events
   * @param app 
   * @param options 
   * @returns 
   */
  public getAnalyticGithubEvents = (app: string, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('GET', `admin/${app}/analytics/github/events`, data);
  }

  /**
   * Get the github events for a user
   * @param app 
   * @param user 
   * @param options 
   * @returns 
   */
  public getAnalyticGithubEventsForUser = (app: string, user: string, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('GET', `admin/${app}/analytics/github/users/${user}/events`, data);
  }

  /**
   * Get the github events for a repository
   * @param app 
   * @param repository 
   * @param options 
   * @returns 
   */
  public getAnalyticGithubEventsForRepository = (app: string, repository: string, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('GET', `admin/${app}/analytics/github/repositories/${repository}/events`, data);
  }



  /**
   * Get the CI events
   * @param app 
   * @param options 
   * @returns 
   */
   public getAnalyticCIEvents = (app: string, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('GET', `admin/${app}/analytics/ci/events`, data);
  }

  /**
   * Get the CI events for a user
   * @param app 
   * @param user 
   * @param options 
   * @returns 
   */
  public getAnalyticCIEventsForUser = (app: string, user: string, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('GET', `admin/${app}/analytics/ci/users/${user}/events`, data);
  }

  /**
   * Get the CI events for a repository
   * @param app 
   * @param repository 
   * @param options 
   * @returns 
   */
  public getAnalyticCIEventsForRepository = (app: string, repository: string, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('GET', `admin/${app}/analytics/ci/repositories/${repository}/events`, data);
  }


  //////
  // Query Engine
  //////

  public getSavedQueries = (userID: number, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('GET', `admin/users/${userID}/analytics/queries/saved`, data);
  }

  public createSavedQuery = (userID: number, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('POST', `admin/users/${userID}/analytics/queries/saved`, data);
  }

  public getSharedSavedQueries = (userID: number, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('GET', `admin/users/${userID}/analytics/queries/saved/shared`, data);
  }

  public runQueryWithoutSaving = (userID: number, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('POST', `admin/users/${userID}/analytics/queries/run`, data);
  }

  public getSavedQuery = (userID: number, queryID: number, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('GET', `admin/users/${userID}/analytics/queries/saved/${queryID}`, data);
  }

  public updateSavedQuery = (userID: number, queryID: number, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('PATCH', `admin/users/${userID}/analytics/queries/saved/${queryID}`, data);
  }


  public deleteSavedQuery = (userID: number, queryID: number, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('DELETE', `admin/users/${userID}/analytics/queries/saved/${queryID}`, data);
  }


  public runSavedQuery = (userID: number, queryID: number, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('POST', `admin/users/${userID}/analytics/queries/saved/${queryID}/run`, data);
  }

  public shareSavedQuery = (userID: number, queryID: number, targetUserID: number, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('PUT', `admin/users/${userID}/analytics/queries/saved/${queryID}/${targetUserID}`, data);
  }

  public unshareSavedQuery = (userID: number, queryID: number, targetUserID: number, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('DELETE', `admin/users/${userID}/analytics/queries/saved/${queryID}/${targetUserID}`, data);
  }
}

export interface IAnalyticKnownEvent {
  id: number;
  platform: "wagz2" | "chd",
  event: string;
}

export interface IAnalyticEvent {
  deviceId: number;
  event: string;
  id: number;
  params: any;
  petId: number;
  platform: "wagz2" | "chd";
  posted: string;
  userId: number;

  petLink?: number;
  deviceLink?:number;
  userLink?: number;
}

export interface IAnalyticDevice {
  browser: string;
  browserVersion: string;
  deviceType: string;
  id: number;
  manufacturer: string;
  model: string;
  os: string;
  osVersion: string;
  params: any;
  platform: "wagz2" | "chd";
  posted: string;
  screenHeight: number;
  screenWidth: number;
  userId: number;

  view?: any; // used for table
  userIdLink?: any; // used for table
}

export const analyticOSDisplay = (os: string): string => {
  switch(os.toLowerCase()){
    case "android":
      return "Android";
    case "ios":
      return "iOS";
    case "windows":
      return "Windows";
    case "macos":
      return "Mac OS";
    case "linux":
      return "Linux";
    case "chromeos":
      return "Chrome OS";
    case "fire":
      return "Fire";
    case "unknown":
      return "Unknown";
    case "other":
      return "Other";
  }
  return "Unknown";
}

export const analyticDeviceTypeDisplay = (deviceType: string): string => {
  switch(deviceType.toLowerCase()){
    case "pc":
      return "PC / Laptop";
    case "tablet":
      return "Tablet";
    case "phone":
      return "Phone";
    case "unknown":
      return "Unknown";
    case "other":
      return "Other";
  }
  return "Unknown";
}

export interface IAnalyticLocation {
  city: string;
  country: string;
  id: number;
  lat: number;
  lng: number;
  params: any;
  platform: "wagz2" | "chd";
  posted: string;
  state: string;
  userId: number;
  zip: string;
}