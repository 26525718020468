import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Select from "react-select";
import * as UserActions from "src/reducers/userReducer";
import { BillingAPI, DeviceAPI, OTAAPI } from "src/api";
import { Modal, Table } from "react-bootstrap";
import moment from "moment";
import * as Alert from "src/components/structure/Alert";
import { DatePicker } from "src/components/structure";
import { styles } from "src/styles";

interface IManageTabProps {
  deviceId: number;
  location: any;
  userActions: any;
  userState: any;
}

interface IManageTabState {
  loading: boolean;
  nickname: string;
  status: string;
  registeredBy: number;
  newNickname: string;
  newStatus: string;
  newChannel: string;
  channelChosen: boolean;
  channels: any[];
  showReturnNowModal: boolean;
  showReturnLaterModal: boolean;
  showOTA: boolean;
  deviceType: string;
  productFamily: string;
  otaChannel: string;
  selectedDeviceId: number;
  billingDevices: any[];
  deactivateOn: moment.Moment;
  dateUpdated: boolean;
  specifyDate: "yes" | "no";
}

class ManageTab extends Component<IManageTabProps, IManageTabState> {
  constructor(props: IManageTabProps) {
    super(props);

    this.state = {
      loading: false,
      nickname: "",
      status: "",
      registeredBy: 0,
      newNickname: "",
      newStatus: "",
      newChannel: "",
      channelChosen: false,
      channels: [],
      showReturnNowModal: false,
      showReturnLaterModal: false,
      showOTA: false,
      deviceType: "",
      productFamily: "",
      otaChannel: "",
      selectedDeviceId: 0,
      billingDevices: [],
      deactivateOn: moment(),
      dateUpdated: false,
      specifyDate: "no",
    };

    this.updateField = this.updateField.bind(this);
    this.updateNickname = this.updateNickname.bind(this);
    this.updateStatus = this.updateStatus.bind(this);
    this.updateChannel = this.updateChannel.bind(this);
    this.removeDevice = this.removeDevice.bind(this);
    this.removeDeviceNow = this.removeDeviceNow.bind(this);
    this.exchangeSubscriptions = this.exchangeSubscriptions.bind(this);
    this.clearFirmwareCache = this.clearFirmwareCache.bind(this);
    this.updateDate = this.updateDate.bind(this);
    this.handleSpecifyDate = this.handleSpecifyDate.bind(this);
  }

  updateField(e: any) {
    let ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  handleChange = (input: any) => {
    const selected = input.value;
    this.setState({ newChannel: selected, channelChosen: true });
  };

  componentDidMount() {
    this.getDeviceInfo();
    console.log(this.state.deactivateOn)
  }

  checkPermissions() {
    const roles = this.props.userState.user.roles;
    let hasPermission = roles.includes("administrator") || roles.includes("qa");
    // we also allow them to always change theirs
    for (const id of this.props.userState.user.wagzUserIds) {
      if (id === this.state.registeredBy) {
        hasPermission = true;
      }
    }
    if (hasPermission) {
      // we want to get the channels in here only; no need to fetch if not able to change
      this.setState({ loading: true }, async () => {
        try {
          const res = await DeviceAPI.getAvailableOTAChannels(
            this.state.deviceType,
            this.state.productFamily
          );
          const availableChannels = [];
          for (let channel of res.body.data) {
            availableChannels.push({ value: channel, label: channel });
          }
          this.setState({
            loading: false,
            showOTA: true,
            channels: availableChannels,
          });
        } catch (err) {
          this.setState({ loading: false });
        }
      });
    }
  }

  getDeviceInfo() {
    this.setState({ loading: true }, async () => {
      try {
        const res = await DeviceAPI.getDeviceById("wagz", this.props.deviceId);
        const device = res.body.data;
        if (device.productFamily === "" && device.productId.length === 10) {
          device.productFamily = `${device.productId.substr(4, 2)}`;
        }
        this.setState(
          {
            loading: false,
            nickname: device.nickname,
            status: device.status,
            registeredBy: device.registeredBy,
            newStatus: device.status,
            newNickname: device.nickname,
            deviceType: device.deviceType,
            productFamily: device.productFamilyId,
            otaChannel: device.otaChannel,
          },
          () => this.checkPermissions()
        );
        this.getUserBillingDevices();
      } catch (err) {
        this.setState({ loading: false });
      }
    });
  }

  getUserBillingDevices() {
    this.setState({ loading: true }, async () => {
      try {
        const res = await BillingAPI.getUserBillingDevices(
          "wagz2",
          this.state.registeredBy
        );
        this.setState({
          loading: false,
          billingDevices: res.body.data,
        });
      } catch (err) {
        this.setState({ loading: false });
      }
    });
  }

  updateNickname() {
    this.setState({ loading: true }, async () => {
      try {
        await DeviceAPI.updateDevice(
          "wagz",
          this.state.registeredBy,
          this.props.deviceId,
          { nickname: this.state.newNickname }
        );
        Alert.success("Nickname has been updated");
        this.setState({ loading: false, nickname: this.state.newNickname });
      } catch (error) {
        this.setState({ loading: false });
        Alert.error(
          "Hmm, that doesn't seem to be working right now.  Try again."
        );
      }
    });
  }

  updateStatus() {
    this.setState({ loading: true }, async () => {
      try {
        await DeviceAPI.updateDevice(
          "wagz",
          this.state.registeredBy,
          this.props.deviceId,
          { status: this.state.newStatus }
        );
        Alert.success("Status has been updated");
        this.setState({ loading: false, status: this.state.newStatus });
      } catch (error) {
        this.setState({ loading: false });
        Alert.error(
          "Hmm, that doesn't seem to be working right now.  Try again."
        );
      }
    });
  }

  removeDeviceNow() {
    this.setState({ loading: true }, async () => {
      try {
        await DeviceAPI.removeDeviceFromAccount(
          "wagz",
          this.state.registeredBy,
          this.props.deviceId,
          { cancelPlanImmediately: "yes" }
        );
        Alert.success("Device has been processed for return");
        this.setState({ loading: false, showReturnNowModal: false, showReturnLaterModal: false });
      } catch (error) {
        this.setState({ loading: false, showReturnNowModal: false, showReturnLaterModal: false });
        Alert.error(
          "Hmm, that doesn't seem to be working right now. Try again."
        );
      }
    });
  }

  removeDevice() {
    this.setState({ loading: true }, async () => {
      try {
        if (this.state.dateUpdated) {
          const deactivateOn = moment(this.state.deactivateOn).startOf("day").utc().format("YYYY-MM-DDTHH:mm:ss") + "Z";
          await DeviceAPI.removeDeviceFromAccount(
            "wagz",
            this.state.registeredBy,
            this.props.deviceId,
            { deactivateOn }
          );
        } else {
          await DeviceAPI.removeDeviceFromAccount(
            "wagz",
            this.state.registeredBy,
            this.props.deviceId
          );
        }

        Alert.success("Device has been processed for return");
        this.setState({ loading: false, showReturnNowModal: false, showReturnLaterModal: false });
      } catch (error) {
        this.setState({ loading: false, showReturnNowModal: false, showReturnLaterModal: false });
        Alert.error(
          "Hmm, that doesn't seem to be working right now. Try again."
        );
      }
    });
  }

  exchangeSubscriptions() {
    this.setState({ loading: true }, async () => {
      try {
        await BillingAPI.swapDeviceSubscriptions(
          "wagz",
          this.state.registeredBy,
          this.props.deviceId,
          this.state.selectedDeviceId
        );
        Alert.success("Device subscription have been swapped");
        this.setState({ loading: false, selectedDeviceId: 0 });
      } catch (error) {
        this.setState({ loading: false, selectedDeviceId: 0 });
        Alert.error(
          "Hmm, that doesn't seem to be working right now.  Try again."
        );
      }
    });
  }

  updateChannel() {
    this.setState({ loading: true }, async () => {
      try {
        await DeviceAPI.updateOTAChannelForDevice(
          "wagz",
          this.state.registeredBy,
          this.props.deviceId,
          this.state.newChannel
        );
        Alert.success("OTA channel has been updated");
        this.setState({ loading: false, channelChosen: false });
        this.getDeviceInfo();
      } catch (error) {
        this.setState({ loading: false });
        Alert.error(
          "Hmm, that doesn't seem to be working right now.  Try again."
        );
      }
    });
  }

  clearFirmwareCache(){
    this.setState({ loading: true }, async () => {
      try {
        await OTAAPI.clearOTACacheForFamily(this.state.deviceType, this.state.productFamily);
        this.checkPermissions();
        Alert.success("Cache cleared and permissions reset");
      } catch (error) {
        this.setState({ loading: false });
        Alert.error(
          "Hmm, that doesn't seem to be working right now.  Try again."
        );
      }
    });
  }

  render() {
    return (
      <div>
        <Table striped bordered hover>
          <thead style={styles.thead}>
            <tr>
              <th style={styles.tableHead}>Update Device Nickname</th>
              <th style={styles.tableHead}>Return Device</th>
              <th style={styles.tableHead}>Swap Device Subscriptions</th>
              <th style={styles.tableHead}>Update OTA Channel</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={styles.tCellWidth}>
                <p>
                  The nickname is used to identify the device in a list. This
                  will not assign the device to a dog.
                </p>
                <div className="form-group">
                  <input
                    id="newNickname"
                    type="text"
                    className="form-control"
                    autoComplete="new-password"
                    placeholder={this.state.nickname}
                    onChange={this.updateField}
                    value={this.state.newNickname}
                  />
                </div>
                <button
                  className="btn btn-block btn-primary"
                  onClick={this.updateNickname}
                >
                  Update now
                </button>
              </td>
              <td style={styles.tCellWidth}>
                <div style={styles.borderBottom}>
                  <p>
                    This will process the device as being returned by the
                    customer and will cancel it effective immediately. Please only use this if you KNOW we have received
                    the device back from the customer.
                  </p>
                  <button
                    style={{ color: "white" }}
                    className="btn btn-delete btn-block"
                    onClick={() => {
                      this.setState({ showReturnNowModal: true });
                    }}
                  >
                    Return & Cancel Immediately - 
                  </button>
                </div>
                <div style={{ marginTop: 10 }}>
                  <p>
                    This will process the device as being returned by the
                    customer, and set the cancel date to a time of your choosing, or to the end of their billing period.
                  </p>
                  <button
                    style={{ color: "white" }}
                    className="btn btn-delete btn-block"
                    onClick={() => {
                      this.setState({ showReturnLaterModal: true });
                    }}
                  >
                  Return & Choose date for cancelation
                  </button> 
                </div>
              </td>
              <td style={styles.tCellWidth}>
                <p>
                  Select one of the other user's eligible devices below. This
                  will take this devices subscription and exchange it with the
                  one on that device.
                </p>

                <div className="form-group">
                  <select
                    id="selectedDeviceId"
                    disabled={
                      this.props.userState.user.accessLevel === "read_only"
                    }
                    className="form-control"
                    autoComplete="new-password"
                    value={this.state.selectedDeviceId}
                    onChange={this.updateField}
                  >
                    <option value={0}>Select a device to swap with</option>
                    {this.state.billingDevices.map((e) => {
                      if (e.deviceType === this.state.deviceType && e.status === "active" && e.deviceId !== this.props.deviceId) {
                        return (
                          <option
                            key={e.id}
                            value={e.deviceId}
                          >{`(Device ${e.deviceId}) - Plan ${e.plan.id}, Term: ${e.plan.term}`}</option>
                        );
                      }
                      return null
                    }
                    )}
                  </select>
                </div>
                <button
                  style={{ color: "white" }}
                  className="btn btn-delete btn-block"
                  onClick={this.exchangeSubscriptions}
                >
                  Swap these two subscriptions
                </button>
              </td>
              {this.state.showOTA && (
                <td style={styles.tCellWidth}>
                  <div style={styles.borderBottom}>
                    <h4>Current OTA Channel: {this.state.otaChannel}</h4>
                  </div>
                  {this.state.channels.length === 0 ? (
                    <div className="row">
                      <div className="col-12">
                        <p
                          style={{
                            fontWeight: "bold",
                            fontSize: 20,
                            textAlign: "center",
                          }}
                        >
                          No OTA channels exist or this device does not support
                          OTA.
                        </p>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="center">
                        <p style={{ fontSize: 14, textAlign: "center" }}>
                          <strong>
                            Select the new OTA channel for this device:
                          </strong>
                        </p>
                        <div style={{ textAlign: "center" }}>
                          <p style={{ fontSize: 16 }}>
                            {this.state.channelChosen && (
                              <span style={styles.actionWord}>
                                {this.state.newChannel}
                              </span>
                            )}
                          </p>
                        </div>
                      </div>
                      <Select
                        options={this.state.channels}
                        isMulti={false}
                        value={this.state.newChannel}
                        onChange={this.handleChange}
                      />
                      <button
                        style={{ marginTop: 10 }}
                        className="btn btn-block btn-primary"
                        onClick={this.updateChannel}
                      >
                        Update Now
                      </button>
                      <p style={{marginTop: 20}}>Not seeing the channel? If it is recent, you can hit the button below to clear the cache and update the list. Warning: this can have performance implications if done frequently, so please be carefule!</p>
                      <button className="btn btn-block btn-danger" onClick={this.clearFirmwareCache}>Clear Cache</button>
                    </>
                  )}
                </td>
              )}
            </tr>
          </tbody>
        </Table>
        <div>
          <Modal
            dialogClassName="modal-75"
            centered
            show={this.state.showReturnNowModal}
            onHide={() => {
              this.setState({ showReturnNowModal: false });
            }}
          >
            <Modal.Header closeButton>
              <div
                style={{
                  color: "#5e72e4",
                  fontSize: 36,
                  textAlign: "center",
                  fontWeight: "bold",
                  width: "100%",
                }}
              >
                <p>Are you sure you want to cancel the device immediately?</p>
              </div>
            </Modal.Header>
            <Modal.Body>
              <div>
                <button
                  style={{ color: "white" }}
                  className="btn btn-delete btn-block"
                  onClick={() => this.removeDeviceNow()}
                >
                  Yes, Process Return and cancel device immediately
                </button>
                <button
                  style={{ color: "white", marginTop: 10 }}
                  className="btn btn-warning btn-block"
                  onClick={() => {
                    this.setState({ showReturnNowModal: false });
                  }}
                >
                  Just kidding, take me back
                </button>
              </div>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>
        </div>

        <div>
          <Modal
            dialogClassName="modal-75"
            centered
            show={this.state.showReturnLaterModal}
            onHide={() => {
              this.setState({ showReturnLaterModal: false });
            }}
          >
            <Modal.Header closeButton>
              <div
                style={{
                  color: "#5e72e4",
                  fontSize: 36,
                  textAlign: "center",
                  fontWeight: "bold",
                  width: "100%",
                }}
              >
                <p>Are you sure?</p>
              </div>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div style={{ margin: 10 }}>
                  <div onChange={() => this.updateField}>
                    <input type="radio" value="no" name="specifyDate" checked={this.state.specifyDate === 'no'} onChange={() => this.handleSpecifyDate("no")}/>
                    <span className="radio-text" style={{ marginLeft: 5 }}>Cancel at end of cycle</span>
                  </div>
                  <div style={{ marginTop: 10 }} onChange={() => this.updateField}>
                    <input type="radio" value="yes" name="specifyDate" checked={this.state.specifyDate === 'yes'} onChange={() => this.handleSpecifyDate("yes")}/>
                    <span className="radio-text" style={{ marginLeft: 5 }}>Choose date to cancel</span> 
                    { this.state.specifyDate === 'yes' && (
                      <div style={{ margin: 5 }}>
                        <DatePicker date={this.state.deactivateOn} onDateSaved={this.updateDate} />
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  <button
                    style={{ color: "white" }}
                    className="btn btn-delete btn-block"
                    onClick={() => this.removeDevice()}
                  >
                    Yes, process return and cancel on the selected date
                  </button>
                  <button
                    style={{ color: "white", marginTop: 10 }}
                    className="btn btn-warning btn-block"
                    onClick={() => {
                      this.setState({ showReturnLaterModal: false });
                    }}
                  >
                    Just kidding, take me back
                  </button>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>
        </div>
      </div>
    );
  }

  private updateDate(newDate: moment.Moment){
    this.setState({ deactivateOn: newDate, dateUpdated: true });
  }

  private handleSpecifyDate(input: any) {
    if (input === "no") {
      this.setState({
        specifyDate: input,
        dateUpdated: false
      });
    } else {
      this.setState({
        specifyDate: input,
      });
    }
  }
}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default connect<{}, {}, any>(
  mapStateToProps,
  mapDispatchToProps
)(ManageTab);
