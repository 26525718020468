import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Popover, OverlayTrigger } from "react-bootstrap";

import Screen from "src/components/structure/Screen";
import * as AppActions from "src/reducers/appReducer";
import { Table } from "react-bootstrap";
import { styles } from "src/styles";
import { BillingAPI } from "src/api";
import { formatCurrency } from "src/utils/currency";
import { unixToDate } from "src/utils/utilities";

interface IStripeLogsProps {
  userId: number;
}

interface IStripeLogsState {
  loading: boolean;
  logs: any;
}

class StripeLogs extends Component<IStripeLogsProps, IStripeLogsState> {
  constructor(props: IStripeLogsProps) {
    super(props);
    this.state = {
      loading: false,
      logs: [],
    };

    this.getStripeLogs = this.getStripeLogs.bind(this);
  }

  componentDidMount() {
    this.getStripeLogs();
  }

  getStripeLogs() {
    this.setState({ loading: true }, async () => {
      try {
        const result = await BillingAPI.getUserStripeLogs(
          "wagz2",
          this.props.userId
        );
        this.setState({
          loading: false,
          logs: result.body.data,
        });
      } catch (err) {
        console.log("Could not fetch any logs");
        this.setState({ loading: false });
      }
    });
  }

  public render() {
    return (
      <Screen fileName="StripeLogs.tsx" requiredRoles={["support"]}>
        {this.state.logs.length === 0 && (
          <div style={styles.shadowBox}>
            <div style={{ margin: 10 }}>
              <p>User has no Stripe Payment logs</p>
            </div>
          </div>
        )}
        {this.state.logs.length > 0 && (
          <div style={styles.shadowBox}>
            <Table striped bordered hover>
              <thead style={{ textAlign: "center" }}>
                <th>Date</th>
                <th>Amount</th>
                <th>Status</th>
              </thead>
              <tbody>
                {this.state.logs.map((e: any) => {
                  const pop = (
                    <Popover id="popover-trigger-click" title="Message">
                      <div style={{ padding: 5 }}>{e.paymentInfo.charges.data[0].failure_message}</div>
                    </Popover>
                  );
                  return (
                    <tr>
                      <td>{unixToDate(e.paymentInfo.created)}</td>
                      <td>{formatCurrency(e.paymentInfo.amount)}</td>
                      <td>
                        <OverlayTrigger trigger="hover" overlay={pop}>
                          <span style={{ fontSize: 12 }}>
                            {e.paymentInfo.status}
                          </span>
                        </OverlayTrigger>
                      </td>
                      <div style={styles.divider} />
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        )}
      </Screen>
    );
  }
}

const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StripeLogs);
