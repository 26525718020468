import * as React from "react";

interface ITimePickerProps {
  onTimeSaved: (time: string, hour: number, minute: number, amOrPM: string) => any;
  time: string;
  amOrPM: string;
}

interface ITimePickerState {
  loading: boolean;
  time: string;
  hour: string;
  minute: string;
  amOrPM: string;
}

class TimePicker extends React.Component<ITimePickerProps, ITimePickerState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      time: this.props.time,
      hour: "00",
      minute: "00",
      amOrPM: this.props.amOrPM,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  public componentDidMount(){
    const split = this.state.time.split(":")
    if(split.length === 2){
      this.setState({
        hour: split[0],
        minute: split[1],
        time: `${split[0]}:${split[1]}`
      })
    }
  }

  public render() {
    return (
      <div className="row">
        <div className="col-8">
          <input type="text" id="time" className="form-control" value={this.state.time} onChange={this.handleChange} />
        </div>
        <div className="col-4">
          <select id="amOrPM" className="form-control" value={this.state.amOrPM} onChange={this.handleChange}>
            <option>AM</option>
            <option>PM</option>
          </select>
        </div>
      </div>
    );
  }

  private handleChange(e: any){
    const id = e.target.id;
    const val = e.target.value;
    const ns = this.state;
    ns[id] = val;
    this.setState(ns, () => {
      const split = this.state.time.split(":");
      if(split.length === 2){
        const h = parseInt(split[0]);
        if(isNaN(h) || h < 0 || h > 23){
          return;
        }
        const m = parseInt(split[1]);
        if(isNaN(m) || m < 0 || m > 59){
          return;
        }
        this.props.onTimeSaved(this.state.time, h, m, this.state.amOrPM);
      }
    })
  }

}

export default TimePicker;