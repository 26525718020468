import { makeCall } from "./client";

export interface IAdminUser {
  badLoginAttemptsSinceLogin: number;
  created: string;
  email: string;
  firstName: string;
  id: number;
  lastLoggedIn: string;
  lastName: string;
  otpStatus: string;
  roles: string[];
  smsNumber: string;
  status: string;
  updated: string;
  wagzUserIds: any[];
}

export interface IUser {
  created: string;
  email: string;
  firstName: string;
  homePhone: string;
  id: number;
  lastLoggedIn: string;
  lastName: string;
  marketingSub: boolean;
  mobilePhone: string;
  newAccount: boolean;
  password: string;
  service: string;
  status: string;
}

export const BlankUser: IUser = {
  created: "",
  email: "",
  firstName: "",
  homePhone: "",
  id: 0,
  lastLoggedIn: "",
  lastName: "",
  marketingSub: false,
  mobilePhone: "",
  newAccount: false,
  password: "",
  service: "wagz2",
  status: "pending",
};

export type Address = {
  addressType: string;
  city: string;
  country: string;
  id: number;
  state: string;
  street1: string;
  street2: string;
  userId: number;
  zip: string;
}

export const BlankAddress: Address = {
  addressType: "",
  city: "",
  country: "",
  id: 0,
  state: "",
  street1: "",
  street2: "",
  userId: 0,
  zip: "",
}

export class UserAPI {
  
  /**
   * Logs in an admin user
   * @param email 
   * @param password 
   * @param options 
   */
  public login = (email: string, password: string, options: any = {}) => {
    const data = {
      email,
      password,
      ...options
    };
    return makeCall('POST', 'admin/users/login', data);
  }

  /**
   * Attempts to refresh the token
   * @param userId 
   * @param refreshToken 
   * @param options 
   */  
  public refreshToken = (userId: number, refreshToken: string, options: any = {}) => {
    const data = {
      ...options,
      refresh_token: refreshToken,
      grant_type: "refresh_token",
    };
    return makeCall('POST', `/admin/users/${userId}/oauth/token`, data);
  }

  /**
   * Gets all of the users who can access the panel
   * @param options 
   */
  public getUsers = (options: any = {}) => {
    const data = {
      ...options
    };
    return makeCall('GET', `/admin/users`, data);
  }

  /**
   * Gets an administrator
   * @param id 
   * @param options 
   */
  public getAdminUserById = (id: number, options: any = {}) => {
    const data = {
      ...options
    };
    return makeCall('GET', `/admin/users/${id}`, data);
  }

  /**
   * Gets the linked wagz user ids for an administrator
   * @param adminId
   * @param options 
   */
  public getWagzUserLinksForAdminId = (adminId: number, options: any = {}) => {
    const data = {
      ...options
    };
    return makeCall('GET', `/admin/users/${adminId}/links`, data);
  }

  /**
   * Gets the linked wagz user ids for an administrator
   * @param adminId
   * @param options 
   */
  public linkWagzUserAndAdminId = (adminId: number, options: any = {}) => {
    const data = {
      ...options
    };
    return makeCall('POST', `/admin/users/${adminId}/links`, data);
  }

  /**
   * Unlinks a wagz user id from an administrator
   * @param adminId
   * @param wagzUserId
   * @param options 
   */
  public unlinkWagzUserAndAdminId = (adminId: number, wagzUserId: number, options: any = {}) => {
    const data = {
      ...options
    };
    return makeCall('DELETE', `/admin/users/${adminId}/wagz/${wagzUserId}`, data);
  }

  /**
   * Unlinks all wagz user ids from an administrator
   * @param id 
   * @param options 
   */
  public unlinkAllWagzUsersFromAdminId = (adminId: number, options: any = {}) => {
    const data = {
      ...options
    };
    return makeCall('DELETE', `/admin/users/${adminId}/links`, data);
  }

  /**
   * Gets all of the users
   * @param app 
   * @param options 
   */
  public getAllUsers = (app: string, options: any = {}) => {
    const data = {
      ...options
    };
    return makeCall('GET', `/admin/${app}/users`, data);
  }
  
  /**
   * Gets a single user by id
   * @param app 
   * @param userId 
   * @param options 
   */
  public getUserById = (app: string, userId: number, options: any = {}) => {
    const data = {
      ...options
    };
    return makeCall('GET', `/admin/${app}/users/${userId}`, data);
  }
  
  /**
   * Gets a single user by their email
   * @param app 
   * @param email 
   * @param options 
   */
  public getUserByEmail = (app: string, email: string, options: any = {}) => {
    const data = {
      ...options
    };
    return makeCall('GET', `/admin/${app}/users/email/${email}`, data);
  }

  /**
   * Add a new administrative user directly to the database
   * @param firstName 
   * @param lastName 
   * @param email 
   * @param password 
   * @param options 
   */
  public addUser(firstName: string, lastName: string, email: string, password: string, options: any = {}) {
    const data = {
      firstName,
      lastName,
      email,
      password,
      ...options
    };
    return makeCall("POST", `/admin/users`, data);
  }

  /**
   * Add a role to an administrative user
   * @param id 
   * @param role 
   * @param options 
   */
  public addUserRole(id: number, role: string, options: any = {}) {
    const data = {
      ...options
    };
    return makeCall("PUT", `/admin/users/${id}/roles/${role}`, data);
  }

  /**
   * Remove roles from an administrative user
   * @param id 
   * @param role 
   * @param options 
   */
  public deleteUserRoles(id: number, role: string, options: any = {}) {
    const data = {
      ...options
    };
    return makeCall("DELETE", `/admin/users/${id}/roles/${role}`, data);
  }

  /**
   * Deletes all of the roles for a user in a single call
   * @param id 
   * @param options 
   */
  public deleteAllUserRoles(id: number, options: any = {}) {
    const data = {
      ...options
    };
    return makeCall("DELETE", `/admin/users/${id}/roles/`, data);
  }

  /**
   * Edit an administrative user
   * @param userId 
   * @param options 
   */
  public editUser(userId: number, options: any = {}) {
    const data = {
      ...options
    };
    return makeCall("PATCH", `/admin/users/${userId}`, data);
  }

  /**
   * Starts the password reset process
   * @param app 
   * @param email 
   */
  public startPasswordReset = (app: string, email: string) => {
    const data = {
      email
    };
    return makeCall('post', `/admin/${app}/users/password/reset`, data);
  }
  
  /**
   * Manually reset a user's password
   * @param id 
   * @param app 
   * @param options 
   */
  public manualPasswordReset = (app: string, userId: number, profileInfo: any) => {
    const data = {
      ...profileInfo,
    };
    return makeCall('patch', `/admin/${app}/users/${userId}/password`, data);
  }
  
  /**
   * Update a user's profile
   * @param id 
   * @param app 
   * @param options 
   */
  public updateUserProfile = (app: string, userId: number, profileInfo: any) => {
    const data = {
      ...profileInfo,
    };
    return makeCall('patch', `/admin/${app}/users/${userId}`, data);
  }

  /**
   * Gets the geofences for a single user
   * @param app 
   * @param userId 
   * @param options 
   */
  public getGeofencesForUser = (app: string, userId: number, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall("GET", `/admin/${app}/users/${userId}/geofences`, data);
  };

  /**
   * Gets the walks for a single user
   * @param app 
   * @param userId 
   * @param options 
   */
  public getWalksForUser = (app: string, userId: number, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall("GET", `/admin/${app}/users/${userId}/walks`, data);
  };

  /**
   * Gets the user's addresses
   * @param app 
   * @param userId 
   * @returns 
   */
  public getUserAddresses = (app: string, userId: number) => {
    const data = {};
    return makeCall('get', `/admin/${app}/users/${userId}/addresses`, data);
  }

  /**
   * Create an address
   * @param app 
   * @param userId 
   * @param address 
   * @returns 
   */
  public createAddress = (app: string, userId: number, address: Address) => {
    return makeCall('post', `/admin/${app}/users/${userId}/addresses`, address);
  }

  /**
   * Update an address
   * @param app 
   * @param userId 
   * @param addressId 
   * @param address 
   * @returns 
   */
  public updateAddress = (app: string, userId: number, addressId: number, address: Address) => {
    return makeCall('patch', `/admin/${app}/users/${userId}/addresses/${addressId}`, address);
  }

  /**
   * Get the devices for a user
   * @param app 
   * @param userId 
   * @param options 
   * @returns 
   */
  public getMobileDevices = (app: string, userId: number, options: any = {}) => {
    return makeCall('get', `/admin/${app}/users/${userId}/mobiledevices`, options);
  }

  /**
   * Save a new or update an existing mobile device
   * @param app 
   * @param userId 
   * @param data 
   * @returns 
   */
  public saveMobileDevice = (app: string, userId: number, data: any = {}) => {
    return makeCall('post', `/admin/${app}/users/${userId}/mobiledevices`, data);
  }
  
  /**
   * Gets the otp methods for a user
   * @param app 
   * @param userId 
   * @param options 
   */
  public getUserOTP = (app: string, userId: number, options: any = {}) => {
    const data = {
      ...options
    };
    return makeCall('GET', `/admin/${app}/users/${userId}/otp`, data);
  }

  /**
   * Deletes a user otp method
   * @param app 
   * @param userId 
   * @param method 
   * @param options 
   */
  public deleteOTPMethodForUser = (app: string, userId: number, method: string, options: any = {}) => {
    const data = {
      ...options
    };
    return makeCall('DELETE', `/admin/${app}/users/${userId}/otp/${method}`, data);
  }

  /**
   * Deletes a user
   * @param app 
   * @param userId 
   * @param options 
   */
  public deleteUser = (app: string, userId: number, options: any = {}) => {
    const data = {
      ...options
    };
    return makeCall('DELETE', `/admin/${app}/users/${userId}/delete`, data);
  }

  /**
   * Restores a user
   * @param app 
   * @param userId 
   * @param options 
   */
  public restoreUser = (app: string, userId: number, options: any = {}) => {
    const data = {
      ...options
    };
    return makeCall('PATCH', `/admin/${app}/users/${userId}/restore`, data);
  }

  /**
   * Generates a JWT with the specified credentials
   * @param app 
   * @param userId 
   * @param email 
   * @param otherData 
   * @returns 
   */
  public generateJWT = (app: string, userId: number, email: string, otherData: any = {}) => {
    const data = {
      ...otherData,
      app,
      id: userId,
      email
    }
    return makeCall('post', `/admin/backend/users/jwt`, data);
  }

  /**
   * Gets all of the locations for all of the user's pets in a single call
   * @param app 
   * @param userId 
   * @param options 
   * @returns 
   */
  public getLocationsForUser = (app: string, userId: number, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall("GET", `/admin/${app}/users/${userId}/locations`, data);
  };
  
}

export const states = [
  {
      "name": "Alabama",
      "abbreviation": "AL"
  },
  {
      "name": "Alaska",
      "abbreviation": "AK"
  },
  {
      "name": "American Samoa",
      "abbreviation": "AS"
  },
  {
      "name": "Arizona",
      "abbreviation": "AZ"
  },
  {
      "name": "Arkansas",
      "abbreviation": "AR"
  },
  {
      "name": "California",
      "abbreviation": "CA"
  },
  {
      "name": "Colorado",
      "abbreviation": "CO"
  },
  {
      "name": "Connecticut",
      "abbreviation": "CT"
  },
  {
      "name": "Delaware",
      "abbreviation": "DE"
  },
  {
      "name": "District Of Columbia",
      "abbreviation": "DC"
  },
  {
      "name": "Federated States Of Micronesia",
      "abbreviation": "FM"
  },
  {
      "name": "Florida",
      "abbreviation": "FL"
  },
  {
      "name": "Georgia",
      "abbreviation": "GA"
  },
  {
      "name": "Guam",
      "abbreviation": "GU"
  },
  {
      "name": "Hawaii",
      "abbreviation": "HI"
  },
  {
      "name": "Idaho",
      "abbreviation": "ID"
  },
  {
      "name": "Illinois",
      "abbreviation": "IL"
  },
  {
      "name": "Indiana",
      "abbreviation": "IN"
  },
  {
      "name": "Iowa",
      "abbreviation": "IA"
  },
  {
      "name": "Kansas",
      "abbreviation": "KS"
  },
  {
      "name": "Kentucky",
      "abbreviation": "KY"
  },
  {
      "name": "Louisiana",
      "abbreviation": "LA"
  },
  {
      "name": "Maine",
      "abbreviation": "ME"
  },
  {
      "name": "Marshall Islands",
      "abbreviation": "MH"
  },
  {
      "name": "Maryland",
      "abbreviation": "MD"
  },
  {
      "name": "Massachusetts",
      "abbreviation": "MA"
  },
  {
      "name": "Michigan",
      "abbreviation": "MI"
  },
  {
      "name": "Minnesota",
      "abbreviation": "MN"
  },
  {
      "name": "Mississippi",
      "abbreviation": "MS"
  },
  {
      "name": "Missouri",
      "abbreviation": "MO"
  },
  {
      "name": "Montana",
      "abbreviation": "MT"
  },
  {
      "name": "Nebraska",
      "abbreviation": "NE"
  },
  {
      "name": "Nevada",
      "abbreviation": "NV"
  },
  {
      "name": "New Hampshire",
      "abbreviation": "NH"
  },
  {
      "name": "New Jersey",
      "abbreviation": "NJ"
  },
  {
      "name": "New Mexico",
      "abbreviation": "NM"
  },
  {
      "name": "New York",
      "abbreviation": "NY"
  },
  {
      "name": "North Carolina",
      "abbreviation": "NC"
  },
  {
      "name": "North Dakota",
      "abbreviation": "ND"
  },
  {
      "name": "Northern Mariana Islands",
      "abbreviation": "MP"
  },
  {
      "name": "Ohio",
      "abbreviation": "OH"
  },
  {
      "name": "Oklahoma",
      "abbreviation": "OK"
  },
  {
      "name": "Oregon",
      "abbreviation": "OR"
  },
  {
      "name": "Palau",
      "abbreviation": "PW"
  },
  {
      "name": "Pennsylvania",
      "abbreviation": "PA"
  },
  {
      "name": "Puerto Rico",
      "abbreviation": "PR"
  },
  {
      "name": "Rhode Island",
      "abbreviation": "RI"
  },
  {
      "name": "South Carolina",
      "abbreviation": "SC"
  },
  {
      "name": "South Dakota",
      "abbreviation": "SD"
  },
  {
      "name": "Tennessee",
      "abbreviation": "TN"
  },
  {
      "name": "Texas",
      "abbreviation": "TX"
  },
  {
      "name": "Utah",
      "abbreviation": "UT"
  },
  {
      "name": "Vermont",
      "abbreviation": "VT"
  },
  {
      "name": "Virgin Islands",
      "abbreviation": "VI"
  },
  {
      "name": "Virginia",
      "abbreviation": "VA"
  },
  {
      "name": "Washington",
      "abbreviation": "WA"
  },
  {
      "name": "West Virginia",
      "abbreviation": "WV"
  },
  {
      "name": "Wisconsin",
      "abbreviation": "WI"
  },
  {
      "name": "Wyoming",
      "abbreviation": "WY"
  }
];