import React, { Component } from "react";
import { Link } from "react-router-dom";
import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";
import { blue, lightBlue } from "@material-ui/core/colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getDistanceFromLatLngInKm,
  getIcon,
  getColor,
} from "src/utils/utilities";
import GoogleMapReact from "google-map-react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import { styles } from "src/styles";
import { PetsAPI } from "src/api";
import { Modal } from "react-bootstrap";
import * as Alert from "src/components/structure/Alert";

const mapKey = process.env.REACT_APP_GOOGLE_MAP_KEY || "";

const MapTypeSwitch = withStyles({
  switchBase: {
    color: lightBlue[300],
    "&$checked": {
      color: blue[500],
    },
    "&$checked + $track": {
      backgroundColor: blue[400],
    },
  },
  checked: {},
  track: {},
})(Switch);

interface IGeofenceProps {
  key: number;
  loading: boolean;
  fence: any;
  userId: number;
  simple: boolean;
}

interface IGeofenceState {
  active: string;
  area: any[];
  areaClosed: any[];
  pets: any[];
  name: string;
  type: string;
  showInsetModal: boolean;
  fenceColor: string;
  id: number;
  mapType: string;
  mapTypeChecked: boolean;
  perimeter: number;
  areaSizeMeters: number;
  insetIncluded: boolean;
  insetArea: any;
  insetAreaClosed: any;
  insetDistance: string;
  loading: boolean;
}
class Geofence extends Component<IGeofenceProps, IGeofenceState> {
  constructor(props: any) {
    super(props);
    this.state = {
      active: "",
      area: this.props.fence.area,
      areaClosed: [],
      pets: [],
      showInsetModal: false,
      name: this.props.fence.name,
      type: "",
      fenceColor: "#FFFFFF",
      id: this.props.fence.id,
      mapType: "satellite",
      mapTypeChecked: true,
      perimeter: 0,
      areaSizeMeters: this.props.fence.areaSizeMeters,
      insetIncluded: false,
      insetArea: [],
      insetAreaClosed: [],
      insetDistance: "",
      loading: false,
    };

    this.updateField = this.updateField.bind(this);
    this.setDisplay = this.setDisplay.bind(this);
    this.amendArea = this.amendArea.bind(this);
    this.amendInsetArea = this.amendInsetArea.bind(this);
    this.handleSwitch = this.handleSwitch.bind(this);
    this.calculatePerimeter = this.calculatePerimeter.bind(this);
    this.fetchInset = this.fetchInset.bind(this);
    this.toggleInsetModal = this.toggleInsetModal.bind(this);
  }

  componentDidMount() {
    this.setDisplay();
  }

  updateField(e: any) {
    let ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  fetchInset(fenceID: number) {
    this.setState({ loading: true }, async () => {
      try {
        const res = await PetsAPI.getGeofenceInset(
          "wagz",
          this.props.userId,
          fenceID,
          { insetDistance: this.state.insetDistance }
        );
        this.setState({
          loading: false,
          insetArea: res.body.data,
          insetIncluded: true,
        });
        this.amendInsetArea();
        this.toggleInsetModal();
      } catch (err) {
        this.setState({ loading: false });
        Alert.error(
          "Error rendering that inset. Either the distance is too extreme or the shape of the geofence is not conducive to being able to display a proper inset."
        );
      }
    });
  }

  // need this to close off the geofence for viewing
  amendArea() {
    var amendedArea = this.state.area;
    amendedArea.push(this.state.area[0]);
    this.calculatePerimeter(amendedArea);
    this.setState({ areaClosed: amendedArea });
  }
  amendInsetArea() {
    var amendedInsetArea = this.state.insetArea;
    amendedInsetArea.push(this.state.insetArea[0]);
    this.setState({ insetAreaClosed: amendedInsetArea });
  }

  calculatePerimeter(a: any) {
    let perimeter: number = 0;
    let pointCount: number = 0;
    while (pointCount < a.length - 1) {
      const distance = getDistanceFromLatLngInKm(
        a[pointCount].lat,
        a[pointCount].lng,
        a[pointCount + 1].lat,
        a[pointCount + 1].lng
      );
      perimeter += distance;
      pointCount++;
    }

    // convert to meters
    const perimeterInMeters = perimeter * 1000;
    this.setState({ perimeter: Math.round(perimeterInMeters) });
  }

  // we have a few bools & camel case parts of the props that need to be dealt with here
  setDisplay() {
    this.amendArea();
    const geofence = this.props.fence;

    if (geofence.active) {
      this.setState({ active: "Yes" });
    } else {
      this.setState({ active: "No" });
    }
    switch (geofence.type) {
      case "allowed": {
        this.setState({ type: "Allowed", fenceColor: "#11cdef" });
        break;
      }
      case "notAllowed": {
        this.setState({ type: "Not Allowed", fenceColor: "#FF4433" });
        break;
      }
      default: {
        this.setState({ type: "unknown" });
        break;
      }
    }

    const assignedPets = [];

    for (let pets of geofence.pets) {
      if (pets.fenceActive) {
        assignedPets.push(
          <div>
            <Link
              to={`/support/users/${this.props.userId}/pets/${pets.id}`}
              id="pet_page"
              style={{ color: "blue", textDecoration: "underline" }}
            >
              {pets.name}
            </Link>
            <br />
          </div>
        );
      }
      this.setState({ pets: assignedPets });
    }
  }

  handleGoogleMapApi = (google: {
    maps: {
      Polyline: new (arg0: {
        path: { lat: number; lng: number }[];
        geodesic: boolean;
        strokeColor: string;
        strokeOpacity: number;
        strokeWeight: number;
      }) => any;
    };
    map: any;
  }) => {
    var flightPath = new google.maps.Polyline({
      path: this.state.areaClosed,
      geodesic: true,
      strokeColor: this.state.fenceColor,
      strokeOpacity: 1,
      strokeWeight: 5,
    });

    flightPath.setMap(google.map);
    if (this.state.insetIncluded) {
      var insetPath = new google.maps.Polyline({
        path: this.state.insetAreaClosed,
        geodesic: true,
        strokeColor: "#FFD700",
        strokeOpacity: 1,
        strokeWeight: 5,
      });

      insetPath.setMap(google.map);
    }
  };

  render() {
    return (
      <div style={{ backgroundColor: "transparent" }}>
        <div key={this.state.id}>
          {!this.props.simple && (
            <Accordion allowZeroExpanded={true} allowMultipleExpanded={true}>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>{this.state.name}</AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <div className="row">
                    <div style={{ height: "60vh", width: "50%" }}>
                      <div style={{ marginLeft: 20, marginTop: 20 }}>
                        <div>
                          Fence ID:
                          {this.state.id}
                        </div>
                        <div>
                          Assigned Pets:
                          {this.state.pets}
                        </div>
                        <div>
                          Perimeter:
                          {this.state.perimeter} meters (
                          {this.state.perimeter * 3.2808} feet)
                        </div>
                        <div>
                          Area:
                          {this.state.areaSizeMeters === 0
                            ? "unknown"
                            : this.state.areaSizeMeters +
                              " meters squared"}{" "}
                          (
                          {this.state.areaSizeMeters === 0
                            ? "unknown"
                            : "~" +
                              Math.round(
                                this.state.areaSizeMeters * 10.76389379999997864
                              ) +
                              " square feet"}
                          )
                        </div>
                      </div>
                      <div className="col-6" style={{ marginTop: 30 }}>
                        <div style={styles.shadowBox}>
                          <div style={{ padding: 15 }}>
                            <p>
                              {" "}
                              Enter the distance (in meters) of desired inset:
                            </p>
                            <input
                              type="text"
                              id="insetDistance"
                              className="form-control"
                              autoComplete="off"
                              value={this.state.insetDistance}
                              onChange={this.updateField}
                            />
                            <button
                              className="btn btn-block"
                              style={{
                                color: "black",
                                backgroundColor: "#FFD700",
                                marginTop: 5,
                              }}
                              onClick={() => this.fetchInset(this.state.id)}
                            >
                              Show Fence Inset
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={{ height: "56vh", width: "50%" }}>
                      <div className="row" style={{ marginLeft: 5 }}>
                        <p style={{ fontSize: 20 }}>Map</p>
                        <MapTypeSwitch
                          checked={this.state.mapTypeChecked}
                          onChange={this.handleSwitch}
                        ></MapTypeSwitch>
                        <p style={{ fontSize: 20 }}>Satellite</p>
                      </div>
                      <GoogleMapReact
                        yesIWantToUseGoogleMapApiInternals
                        onGoogleApiLoaded={this.handleGoogleMapApi}
                        bootstrapURLKeys={{
                          key: mapKey,
                        }}
                        defaultCenter={{
                          lat: this.state.area[0].lat,
                          lng: this.state.area[0].lng,
                        }}
                        defaultZoom={18}
                        options={{
                          tilt: 0,
                          keyboardShortcuts: false,
                          mapTypeId: this.state.mapType,
                          scaleControl: true,
                        }}
                      ></GoogleMapReact>
                    </div>
                    <div>
                      <Modal
                        dialogClassName="modal-100"
                        show={this.state.showInsetModal}
                        onHide={this.toggleInsetModal}
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>
                            Viewing Inset of {this.state.insetDistance} meters
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div
                            className="center"
                            style={{ height: "60vh", width: "100%" }}
                          >
                            <GoogleMapReact
                              yesIWantToUseGoogleMapApiInternals
                              onGoogleApiLoaded={this.handleGoogleMapApi}
                              bootstrapURLKeys={{
                                key: mapKey,
                              }}
                              defaultCenter={{
                                lat: this.state.area[0].lat,
                                lng: this.state.area[0].lng,
                              }}
                              defaultZoom={17}
                              options={{
                                keyboardShortcuts: false,
                                tilt: 0,
                                mapTypeId: "satellite",
                              }}
                            ></GoogleMapReact>
                          </div>
                          <div className="text" style={{ marginTop: 15 }}>
                            <button
                              className="btn btn-block btn-primary"
                              style={styles.button}
                              onClick={this.toggleInsetModal}
                            >
                              Close
                            </button>
                          </div>
                        </Modal.Body>
                        <Modal.Footer></Modal.Footer>
                      </Modal>
                    </div>
                  </div>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          )}
        </div>
        {this.props.simple && (
          <div>
            <div style={styles.borderBottom}></div>
            <div className="col-sm-12 col-lg-12" style={{ height: "56vh" }}>
              <div className="row">
                <div
                  className="col-sm-12 col-lg-4"
                  style={{ fontSize: 14, textAlign: "center", paddingTop: 10 }}
                >
                  <label>
                    Active:
                    <div className="fa fa-fw">
                      <FontAwesomeIcon
                        icon={getIcon(this.state.active)}
                        className={getColor(this.state.active)}
                      />
                    </div>
                  </label>
                </div>
                <div className="col-sm-12 col-lg-4" style={styles.geofenceName}>
                  <label style={{ marginTop: 5 }}>{this.state.name}</label>
                </div>
                <div className="col-sm-12 col-lg-4">
                  <div
                    className="row"
                    style={{ marginLeft: "auto", marginRight: 0 }}
                  >
                    <p
                      style={{
                        fontSize: 14,
                        paddingTop: 10,
                        display: "block",
                        marginLeft: "auto",
                        marginRight: 0,
                      }}
                    >
                      Map
                    </p>
                    <MapTypeSwitch
                      checked={this.state.mapTypeChecked}
                      onChange={this.handleSwitch}
                    ></MapTypeSwitch>
                    <p style={{ fontSize: 14, paddingTop: 10 }}>Satellite</p>
                  </div>
                </div>
              </div>
              <GoogleMapReact
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={this.handleGoogleMapApi}
                bootstrapURLKeys={{
                  key: mapKey,
                }}
                defaultCenter={{
                  lat: this.state.area[0].lat,
                  lng: this.state.area[0].lng,
                }}
                defaultZoom={18}
                options={{
                  tilt: 0,
                  keyboardShortcuts: false,
                  mapTypeId: this.state.mapType,
                  scaleControl: true,
                }}
              ></GoogleMapReact>
            </div>
          </div>
        )}
      </div>
    );
  }

  private handleSwitch() {
    if (this.state.mapType === "satellite") {
      this.setState({
        mapType: "roadmap",
        mapTypeChecked: !this.state.mapTypeChecked,
      });
    }
    if (this.state.mapType === "roadmap") {
      this.setState({
        mapType: "satellite",
        mapTypeChecked: !this.state.mapTypeChecked,
      });
    }
  }

  private toggleInsetModal() {
    this.setState({ showInsetModal: !this.state.showInsetModal });
  }
}

export default Geofence;
