import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan } from '@fortawesome/free-solid-svg-icons';

import Card from "src/components/structure/Card";
import Screen from "src/components/structure/Screen";
import { DeviceAPI, OTAAPI } from "src/api";
import { Modal } from "react-bootstrap";
import {Alert, LoadingButton} from "src/components/structure";
import { success, error } from "src/components/structure/Alert";

// we don't get this from the server because we don't want things without
// an ota cache to have this; we could probably put in some logic somewhere,
// but would need to think it through, maybe with a "hasOTA" field on the device
// metadata call or something
export const deviceTypesMap = {
  "collar": {
    "23": "Freedom Collar",
    "24": "Collar 24",
    "25": "Tracking Collar",
    "30": "Connect Collar",
  }, 
  "healthBox": {
    "26": "Health Box"
  },
  "bowl": {
    "31": "Food Bowl"
  }
}


interface IOTAChannelsProps {
  userState: any;
}

interface IOTAChannelsState {
  loading: boolean;
  channels: string[];
  selectedChannel: string;
  selectedDeviceType: "collar";
  selectedFamily: string;

  showDeleteModal: boolean;
  canDelete: boolean;
  countDevicesOnChannel: number;

  newOTAChannel: string;
}
class OTAChannels extends Component<IOTAChannelsProps, IOTAChannelsState> {
  constructor(props: IOTAChannelsProps) {
    super(props);

    this.state = {
      loading: false,
      channels: [],
      selectedChannel: "",
      selectedDeviceType: "collar",
      selectedFamily: "23",

      showDeleteModal: false,
      canDelete: false,
      countDevicesOnChannel: 0,

      newOTAChannel: "",
    };

    this.updateField = this.updateField.bind(this);
    this.fetchChannels = this.fetchChannels.bind(this);
    this.selectChannelForDeletion = this.selectChannelForDeletion.bind(this);
    this.deleteChannel = this.deleteChannel.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.createOTAChannel = this.createOTAChannel.bind(this);
    this.clearCacheForProductFamily = this.clearCacheForProductFamily.bind(this);
    this.clearAllFirmwareCaches = this.clearAllFirmwareCaches.bind(this);
  }

  componentDidMount() {
    this.fetchChannels();
  }

  render() {

    return (
      <Screen id="qa_otas" requiredRoles={["qa"]}>
        <div className="row">

          <div className="col-3 offset-1">
            <Card title="Info">
              <p>OTA Channels are the folders that devices will look at for new Firmware/OS images. Do not touch anything here unless you know exactly what you are doing. You can delete an OTA channel <strong>ONLY</strong> if there are no devices with that channel set. You would be wise to change the channel of any existing devices to something else prior to attempting to delete. <strong>You can never delete <em>home_user_test, develop, main, or master</em>.</strong></p>
              <p>You can create a new channel and then you will be able to assign a device to that channel. When the device boots next, it will pull the new image.</p>
              <p><strong className="text-danger">Warning: </strong> If you don't see the channel you think you should, you should try to clear out the cache by selecting the device type / family id in the middle card, scrolling to the bottom, and hitting the reset button. <strong>If that doesn't work,</strong> use the button below for the nuclear option. It will reset ALL OTA caches for known cache keys. It will have a big performance hit <strong>on both dev and production</strong> so use only if needed.</p>
              <div className="row">
                <div className="col-12">
                  <button className="btn btn-block btn-danger" onClick={this.clearAllFirmwareCaches}>Reset ALL Caches</button>
                </div>
              </div>
            </Card>
          </div>
          <div className="col-4">
            <Card title="Current OTA Channels" loading={this.state.loading}>
              <div className="row" style={{marginBottom: 20}}>
                <div className="col-6">
                  <label>Device Type:</label>
                  <select className="form-control" id="selectedDeviceType" value={this.state.selectedDeviceType} onChange={this.updateField}>
                    {Object.keys(deviceTypesMap).map((key) => {
                      return <option value={key} key={key}>{key}</option>
                    })}
                  </select>
                </div>
                <div className="col-6">
                  <label>Product Family</label>
                  <select className="form-control" id="selectedFamily" value={this.state.selectedFamily} onChange={this.updateField}>
                    {Object.keys(deviceTypesMap[this.state.selectedDeviceType]).map((family) => {
                      return <option value={family} key={family}>{deviceTypesMap[this.state.selectedDeviceType][family]}</option>
                    })}
                  </select>
                </div>
              </div>
              {this.state.channels.length === 0 && (
                <div className="row">
                  <div className="col-12" style={{textAlign: "center", fontWeight: "bold"}}>
                    No OTA Channels exist for that combination.
                  </div>
                </div>
              )}
              {this.state.channels.map((channel: string) => {
                
                return (
                  <div className="row" style={{marginBottom: 5}} key={channel}>
                    <div className="col-8">
                      {channel}
                    </div>
                    <div className="col-4">
                      {channel !== "develop" && channel !== "main" && channel !== "master" && channel !== "home_user_test" && (
                        <FontAwesomeIcon icon={faBan} onClick={() => {this.selectChannelForDeletion(channel)}} className="text-danger" />
                      )}                      
                    </div>
                  </div>
                )
              })}
              <div className="row">
                <div className="col-12" style={{marginTop: 20}}>
                  <button className="btn btn-block btn-danger" onClick={this.clearCacheForProductFamily}>Reset Cache and Re-Fetch</button>
                </div>
              </div>              
            </Card>
          </div>
          <div className="col-3">
            <Card title="Create New OTA Channel" loading={this.state.loading}>
              <div className="form-group">
                <label>New Channel Name</label>
                <input type="text" className="form-control" id="newOTAChannel" value={this.state.newOTAChannel} onChange={this.updateField} />
              </div>
              <div className="form-group">
                <button className="btn btn-block btn-success" onClick={this.createOTAChannel}>Create New OTA Channel</button>
              </div>
            </Card>
          </div>
        </div>

        <Modal show={this.state.showDeleteModal} onHide={this.hideModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              Delete {this.state.selectedChannel}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.canDelete ? (
              <div>
                <p>Are you absolutely sure you want to delete <strong>{this.state.selectedChannel}</strong>? This cannot be undone and will physically delete the image files on the OTA server. This could have ramifications if any device is still linked, or becomes linked, to that channel. Use only if you are certain this is what you want to do.</p>
              </div>
            ) : (
              <div>
                <p>You cannot delete that channel. There are currently <strong>{this.state.countDevicesOnChannel}</strong> devices still looking at that channel. Change them (or contact an administrator) before moving forward with deletion.</p>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            {this.state.canDelete && (
              <LoadingButton
                loading={this.state.loading}
                className="btn btn-primary btn-brn-danger"
                loadingStyles={{ textAlign: "center", width: "100%" }}
                onClick={this.deleteChannel}
              >
                Delete OTA Channel
              </LoadingButton>
            )}
            
          </Modal.Footer>
        </Modal>
      </Screen>
    )
  }


  updateField(e: any) {
    let ns: any = this.state;
    const id = e.target.id;
    const val = e.target.value;
    ns[id] = val;
    this.setState(ns, () => {
      if(id === "selectedFamily" || id === "selectedDeviceType"){
        this.fetchChannels();
      }
    });
  }

  private fetchChannels() {
    this.setState({ loading: true }, async () => {
      try {
        const result = await DeviceAPI.getAvailableOTAChannels(this.state.selectedDeviceType, this.state.selectedFamily);
        this.setState({loading: false, channels: result.body.data});
      } catch (err) {
        this.setState({ loading: false });
      }
    })
  }

  private clearCacheForProductFamily(){
    this.setState({ loading: true }, async () => {
      try {
        await OTAAPI.clearOTACacheForFamily(this.state.selectedDeviceType, this.state.selectedFamily);
        Alert.success("Cache cleared and channels refreshed");
        this.fetchChannels();
      } catch (err) {
        this.setState({ loading: false });
      }
    })
  }

  private clearAllFirmwareCaches(){
    this.setState({ loading: true }, async () => {
      // first, each individual type
      Object.keys(deviceTypesMap).forEach((deviceType) => {
        Object.keys(deviceTypesMap[deviceType]).forEach(async (family) => {
          try {
            await OTAAPI.clearOTACacheForFamily(deviceType, family);
          } catch (err) {
            Alert.error(`Could not reset ${deviceType} - ${family}`);
          }
        });
      });
      // now the master auth one
      try {
        await OTAAPI.clearOTACacheForAuthenticated();
      } catch (err) {
        Alert.error(`Could not reset auth key`);
      }
      this.fetchChannels();
    });
  }

  private selectChannelForDeletion(channel: string){
    this.setState({ loading: true }, async () => {
      try{
        const infoResult = await DeviceAPI.getOTAChannelInfo(this.state.selectedDeviceType, this.state.selectedFamily, channel);
        const res = infoResult.body.data;
        this.setState({ selectedChannel: channel, canDelete: res.countOfDevices === 0, countDevicesOnChannel: res.countOfDevices, loading: false, showDeleteModal: true});
      }catch(err){
        error("Could not select that channel. Please contact an administrator.");
        this.setState({ loading: false });
      }
    });
  }

  private hideModal(){
    this.setState({ showDeleteModal: false, selectedChannel: ""});
  }

  private deleteChannel(){
    this.setState({loading: true}, async () => {
      try{
        await DeviceAPI.deleteOTAChannel(this.state.selectedDeviceType, this.state.selectedFamily, this.state.selectedChannel);
        success("Channel deleted!");
        this.setState({ selectedChannel: "", showDeleteModal: false }, () => {
          this.fetchChannels();
        });
      }catch(err){
        error("Could not delete that channel. Please contact an administrator.");
        this.setState({ loading: false });
      }
    })
  }

  private createOTAChannel(){
    if (this.state.newOTAChannel.indexOf(' ') >= 0) {
      error("OTA channels should not contain space, please try a different name");
      return
    }
    this.setState({loading: true}, async () => {
      try{
        await DeviceAPI.createOTAChannel(this.state.selectedDeviceType, this.state.selectedFamily, this.state.newOTAChannel);
        success("Channel created!");
        this.setState({ newOTAChannel: "" }, () => {
          this.fetchChannels();
        });
      }catch(err){
        error("Could not create that channel. Please contact an administrator.");
        this.setState({ loading: false });
      }
    })
  }
}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps() {
  return {};
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OTAChannels) as any
);
