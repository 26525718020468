import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import { UserAPI } from "src/api";

import store from "./store";
import { Menu, Nav } from "src/components/structure";

// Login and Password
import Login from "./components/screens/Login/Login"

// Administration
import Administration from "./components/screens/Administration/Administration";
import AdminLogs from "./components/screens/Administration/AdminLogs";
import LogIntervalManagement from "src/components/screens/Backend/LogIntervalManagement";
import AnomaliesScreen from "src/components/screens/Backend/AnomaliesScreen";
import GenerateJWTScreen from "src/components/screens/Backend/GenerateJWTScreen";

// Support
import UserManagement from "./components/screens/Support/UserManagement";
import UserPage from "./components/screens/Support/UserPage";
import DeviceManagement from "./components/screens/Support/DeviceManagement";
import DevicePage from "./components/screens/Support/DevicePage";
import PetManagement from "./components/screens/Support/PetManagement";
import PetPage from "./components/screens/Support/PetPage";
import TicketManagement from "./components/screens/Support/Tickets/TicketManagement";
import TicketPage from "./components/screens/Support/Tickets/TicketPage";
import FreeUsersPage from "src/components/screens/Support/FreeUsersPage";
import AppVersions from "src/components/screens/Support/AppVersions";
import DeviceInformation from "src/components/screens/Support/DeviceInfo";

// Account
import Profile from "src/components/screens/Account/Profile";
import MyDevices from "src/components/screens/Account/MyDevices";
import MyMobileDevices from "src/components/screens/Account/MyMobileDevices";

// Reporting
import OrderManagement from "src/components/screens/Reports/OrderManagement";
import OrderPage from "src/components/screens/Reports/OrderPage";
import BreedActivityReports from "./components/screens/Reports/BreedActivityReports";
import RankingsScreen from "./components/screens/Reports/RankingsReport";
import ReportsPage from "src/components/screens/Reports/ReportsPage";
import AnalyticsScreen from "src/components/screens/Reports/Analytics";
import JobsListScreen from "src/components/screens/Reports/JobsList";
import JobViewScreen from "src/components/screens/Reports/JobView";
import QueryEngineScreen from "src/components/screens/Reports/QueryEngineScreen";

// QA
import OTAChannels from "src/components/screens/QA/OTAChannels";
import QASupportTickets from "src/components/screens/QA/QASupportTickets";

// Backend
import ManageActions from "src/components/screens/Backend/ManageActions";
import OTAManagement from "src/components/screens/Backend/OTAManagement";
import ProductIDPlans from "src/components/screens/Backend/ProductIDPlans";
import BackendSupportTickets from "src/components/screens/Backend/BackendSupportTickets";
import BackendDeviceGroups from "src/components/screens/Backend/BackendDeviceGroups";

// Mobile
import MobileReleases from "src/components/screens/Mobile/MobileReleases";
import MobileSupportTickets from "src/components/screens/Mobile/MobileSupportTickets";

// Product
import Coupons from "src/components/screens/Product/Coupons";
import ManageEvents from "src/components/screens/Product/ManageEvents";
import ManagePlans from "src/components/screens/Product/ManagePlans";
import UploadCouponsScreen from "./components/screens/Product/UploadCoupons";
import ProductSupportTickets from "src/components/screens/Product/ProductSupportTickets";
import GeofenceInset from "src/components/screens/Product/GeofenceInset";
import UploadEmailPreferencesScreen from "src/components/screens/Product/UploadEmailPreferences";

// Device Secrets, which are accessible by many
import GenerateDeviceSecretsScreen from "src/components/screens/DeviceSecrets/GenerateDeviceSecretScreen";
import ManageDeviceSecrets from "src/components/screens/DeviceSecrets/ManageDeviceSecrets";
import UploadDeviceSecretsScreen from "src/components/screens/DeviceSecrets/UploadDeviceSecretsScreen";

// RMAs
import RMAListScreen from "src/components/screens/RMAs/RMAList";
import RMAInfoScreen from "src/components/screens/RMAs/RMAInfo";

import ErrorCodesListScreen from "./components/screens/Errors/ErrorCodesListScreen";

import GPSTestsScreen from "./components/screens/GPSTests/GPSTestsScreen";

import RefundCalculatorScreen from "./components/screens/Support/RefundCalculatorScreen";

import AllEventsScreen from "./components/screens/Events/AllEventsScreen";

// Firmware
import FirmwareReleases from "./components/screens/Firmware/FirmwareReleases";
import OTADownloadsScreen from "./components/screens/Firmware/OTADownloads";

class App extends React.Component<any, any> {
  public async componentDidMount() {
    (window as any).Stripe.setPublishableKey(process.env.REACT_APP_STRIPE_KEY || "pk_test_XZTbTNqBORPxHsbsiFRmoawW");
    const refreshToken = window.localStorage.getItem("refresh_token");
    const expires = window.localStorage.getItem("expires");
    const foundUserId = window.localStorage.getItem("userId");
    if(expires && refreshToken && foundUserId){
      // since it's a fresh load, just try to refresh
      const userId = parseInt(foundUserId, 10);
      const result = await UserAPI.refreshToken(userId, refreshToken);
      window.localStorage.setItem("access_token", result.body.data.accessToken);
      window.localStorage.setItem("expires", result.body.data.expires);
    }
  }

  render() {
    return (
      <Provider store={store}>

        <Router>
          <Menu>
          <div id="page-container">

            <div className="container-fluid" id="content">

              <div id="app">
                <Nav />

                <div className="row">
                  <div className="col-12">
                    <Switch>  
                      <Route exact={true} path="/" component={Login} />
                      <Route exact={true} path="/login" component={Login} />
                      <Route exact={true} path="/admin/logs" component={AdminLogs} />
                      <Route exact={true} path="/admin/users" component={Administration} />

                      <Route exact={true} path="/backend/ota" component={OTAManagement} />
                      <Route exact={true} path="/backend/plans" component={ProductIDPlans} />
                      <Route exact={true} path="/backend/tickets" component={BackendSupportTickets} />
                      <Route exact={true} path="/backend/devices/logInterval" component={LogIntervalManagement} />
                      <Route exact={true} path="/backend/actions" component={ManageActions} />

                      <Route exact={true} path="/devices/secrets" component={ManageDeviceSecrets} />
                      <Route exact={true} path="/devices/secrets/upload" component={UploadDeviceSecretsScreen} />
                      <Route exact={true} path="/devices/secrets/generate" component={GenerateDeviceSecretsScreen} />


                      <Route exact={true} path="/firmware/releases" component={FirmwareReleases} />
                      <Route exact={true} path="/firmware/ota/downloads" component={OTADownloadsScreen} />


                      <Route exact={true} path="/backend/devices/logInterval" component={LogIntervalManagement} />
                      <Route exact={true} path="/backend/anomalies" component={AnomaliesScreen} />
                      <Route exact={true} path="/backend/users/jwt" component={GenerateJWTScreen} />
                      <Route exact={true} path="/backend/devices/groups" component={BackendDeviceGroups} />

                      <Route exact={true} path="/mobile/releases" component={MobileReleases} />
                      <Route exact={true} path="/mobile/tickets" component={MobileSupportTickets} />

                      <Route exact={true} path="/product/coupons" component={Coupons} />
                      <Route exact={true} path="/product/coupons/upload" component={UploadCouponsScreen} />
                      <Route exact={true} path="/product/actions" component={ManageActions} />
                      <Route exact={true} path="/product/events" component={ManageEvents} />
                      <Route exact={true} path="/product/plans" component={ManagePlans} />
                      <Route exact={true} path="/product/tickets" component={ProductSupportTickets} />
                      <Route exact={true} path="/product/emails/preferences/upload" component={UploadEmailPreferencesScreen} />

                      <Route exact={true} path="/support/users" component={UserManagement} />
                      <Route exact={true} path="/support/users/:userId" component={UserPage} />
                      <Route exact={true} path="/support/devices" component={DeviceManagement} />
                      <Route exact={true} path="/support/devices/:deviceId" component={DevicePage} />
                      <Route exact={true} path="/support/info/devices" component={DeviceInformation} />
                      <Route exact={true} path="/support/pets" component={PetManagement} />
                      <Route exact={true} path="/support/users/:userId/pets/:petId" component={PetPage} />
                      <Route exact={true} path="/support/freeUsers" component={FreeUsersPage} />
                      <Route exact={true} path="/support/mobile" component={AppVersions} />
                      <Route exact={true} path="/support/tickets" component={TicketManagement} />
                      <Route exact={true} path="/support/tickets/:ticketId" component={TicketPage} />

                      <Route exact={true} path="/qa/ota/channels" component={OTAChannels} />
                      <Route exact={true} path="/qa/tickets" component={QASupportTickets} />

                      <Route exact={true} path="/reports/dashboard" component={ReportsPage} />
                      <Route exact={true} path="/reports/breeds/activities" component={BreedActivityReports} />
                      <Route exact={true} path="/reports/orders" component={OrderManagement} />
                      <Route exact={true} path="/reports/orders/:orderId" component={OrderPage} />
                      <Route exact={true} path="/reports/pets/rankings" component={RankingsScreen} />
                      <Route exact={true} path="/reports/analytics" component={AnalyticsScreen} />
                      <Route exact={true} path="/reports/jobs" component={JobsListScreen} />
                      <Route exact={true} path="/reports/jobs/:jobId" component={JobViewScreen} />
                      <Route exact={true} path="/reports/queries" component={QueryEngineScreen} />

                      <Route exact={true} path="/errors/codes" component={ErrorCodesListScreen} />

                      <Route exact={true} path="/gpsTests" component={GPSTestsScreen} />
                      <Route exact={true} path="/inset" component={GeofenceInset} />

                      <Route exact={true} path="/me" component={Profile} />
                      <Route exact={true} path="/me/devices" component={MyDevices} />
                      <Route exact={true} path="/me/mobile" component={MyMobileDevices} />


                      <Route exact={true} path="/rmas" component={RMAListScreen} />
                      <Route exact={true} path="/rmas/:rmaId" component={RMAInfoScreen} />

                      <Route exact={true} path="/refunds/calculator" component={RefundCalculatorScreen} />

                      <Route exact={true} path="/events" component={AllEventsScreen} />


                    </Switch>
                  </div>
                </div>

                <footer>
                  <div className="row" style={{ width: "100%" }}>
                    <div className="col-sm-12 col-lg-12" style={{ textAlign: "center", margin: 50 }}>
                      <small>
                        Copyright <a href="https://wagz.com/" target="_new">Wagz, Inc</a>. All rights reserved.<br />
                        Use of this application constitutes agreement with our <a href="http://www.wagz.com/pages/terms-and-conditions" target="_new"> Terms of Service</a>,
                                <a href="http://www.wagz.com/pages/privacy-policy" target="_new"> Privacy Policy</a>, and
                                <a href="http://www.wagz.com/pages/end-user-license-agreement" target="_new"> End User License Agreement</a>
                      </small>
                    </div>
                  </div>
                </footer>
              </div>

              <ToastContainer />
            </div>

          </div>
          </Menu>
        </Router>
      </Provider>
    );
  }
}

export default App;
