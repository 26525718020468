import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Table from "src/components/structure/Table";
import { capitalizeFirstLetter } from "src/utils/utilities";

import * as UserActions from "src/reducers/userReducer";
import { SupportAPI } from "src/api";

const columns = [
    {
      label: "ID",
      field: "id",
      sort: "asc",
      width: 100,
    },
    {
      label: "Title",
      field: "title",
      sort: "asc",
      width: 150,
    },
    {
      label: "Department",
      field: "department",
      sort: "asc",
    },
    {
      label: "Status",
      field: "status",
      sort: "asc",
    },
    {
      label: "Date Created",
      field: "created",
      sort: "asc",
    },
    {
      label: "User",
      field: "user",
      sort: "asc",
    },
    {
      label: "User OS",
      field: "userOS",
      sort: "asc",
    },
    {
      label: "Critical",
      field: "critical",
      sort: "asc",
    },
    {
      label: "Device",
      field: "device",
      sort: "asc",
    },
    {
      label: "Actions",
      field: "actions",
      sort: "asc",
    },
  ];

interface ITicketListProps {
  title?: string;
  department: string;
}

interface ITicketListState {
  loading: boolean;
  processedTickets: any;
}

class TicketList extends Component<
  ITicketListProps,
  ITicketListState
> {
  constructor(props: ITicketListProps) {
    super(props);

    this.state = {
      loading: false,
      processedTickets: [],
    };

    this.updateField = this.updateField.bind(this);
    this.populateTickets = this.populateTickets.bind(this);
  }

  updateField(e: any) {
    let ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  componentDidMount() {
    this.populateTickets();
  }

  populateTickets() {
    this.setState({ loading: true }, async () => {
      try {
        const results = await SupportAPI.getAllTickets({ department: this.props.department });

        const supportTickets = [];
        for (let result of results.body.data) {
          supportTickets.push({
            id: result.id,
            title: result.title,
            department: capitalizeFirstLetter(result.department),
            status: capitalizeFirstLetter(result.status),
            created: result.created,
            user: result.user,
            userOS: result.userOS,
            device: result.device,
            critical: capitalizeFirstLetter(result.critical),
            actions: (
              <Link
                to={`/support/tickets/${result.id}`}
                id="ticket_page"
                style={{ color: "blue", textDecoration: "underline" }}
              >
                View
              </Link>
            ),
          });
        }
        this.setState({
          loading: false,
          processedTickets: supportTickets,
        });
      } catch (err) {
        this.setState({ loading: false });
      }
    });
  }

  render() {
    return (
      <div>
        <Table
          title={this.props.title ? this.props.title : "Devices"}
          csvFileName="devices"
          showDownload={true}
          columns={columns}
          rows={this.state.processedTickets}
        />
      </div>
    );
  }
}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TicketList);
