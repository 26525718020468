import React, { Component } from "react";
import { error, success } from "src/components/structure/Alert";
import { UserAPI } from "src/api";
import { Address, states } from "src/api/users";

interface IManageTabAddressProps {
  accessLevel: string;
  user: any;
  address: Address;
  addressType: "mailing" | "billing" | "emergency";
  onAddressSaved: (addressType: string, address: Address) => void;
}

interface IManageTabAddressState {
  loading: boolean;
  address: Address;
}

export class ManageTabAddress extends Component<
  IManageTabAddressProps,
  IManageTabAddressState
> {
  constructor(props: IManageTabAddressProps) {
    super(props);

    this.state = {
      loading: false,
      address: this.props.address,
    };

    this.updateField = this.updateField.bind(this);
    this.updateUserAddress = this.updateUserAddress.bind(this);
  }

  updateField(e: any) {
    let ns: any = this.state;
    ns.address[e.target.id] = e.target.value;
    this.setState(ns);
  }

  render() {
    if (this.state.loading) {
      return (
        <div>
          <div className="spinner-border" role="status">
            <span className="sr-only">Saving...</span>
          </div>
        </div>
      );
    }
    return (
      <div>
        <div className="form-group">
          <label>Street:</label>
          <input
            id="street1"
            disabled={this.props.accessLevel === "read_only"}
            type="text"
            className="form-control"
            placeholder="Street"
            autoComplete="new-password"
            onChange={this.updateField}
            value={this.state.address.street1}
          />
        </div>
        <div className="form-group">
          <label>Street (cont):</label>
          <input
            id="street2"
            disabled={this.props.accessLevel === "read_only"}
            type="text"
            className="form-control"
            placeholder="Street (cont)"
            autoComplete="new-password"
            onChange={this.updateField}
            value={this.state.address.street2}
          />
        </div>
        <div className="form-group">
          <label>City:</label>
          <input
            id="city"
            disabled={this.props.accessLevel === "read_only"}
            type="text"
            className="form-control"
            placeholder="City"
            autoComplete="new-password"
            onChange={this.updateField}
            value={this.state.address.city}
          />
        </div>
        <div className="form-group">
          <label>State:</label>
          <select
            id="state"
            disabled={this.props.accessLevel === "read_only"}
            className="form-control"
            value={this.state.address.state}
            onChange={this.updateField}
          >
            {states.map((state) => {
              return (
                <option key={state.abbreviation} value={state.abbreviation}>
                  {state.name}
                </option>
              );
            })}
          </select>
        </div>
        <div className="form-group">
          <label>Zip Code:</label>
          <input
            id="zip"
            disabled={this.props.accessLevel === "read_only"}
            type="number"
            className="form-control"
            placeholder="Zip Code"
            autoComplete="new-password"
            onChange={this.updateField}
            value={this.state.address.zip}
          />
        </div>
        <div className="text">
          <div style={{ display: "flex", justifyContent: "center" }}>
            {this.props.accessLevel === "read_only" ? (
              <button className="btn btn-block btn-primary" disabled={true}>
                You do not have permission to do this
              </button>
            ) : (
              <button
                className="btn btn-block btn-send"
                style={{ marginTop: 15, width: "50%", color: "white" }}
                onClick={this.updateUserAddress}
              >
                Submit
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }

  private updateUserAddress() {
    this.setState({ loading: true }, async () => {
      try {
        // if id is 0, it's create, if not, it's a patch
        const address = this.state.address;
        address.country = "USA"; // default to USA
        address.addressType = this.props.addressType;
        if (this.state.address.id === 0) {
          const res = await UserAPI.createAddress(
            "wagz",
            this.props.user.id,
            address
          );
          address.id = res.body.data.id;
        } else {
          await UserAPI.updateAddress(
            "wagz",
            this.props.user.id,
            this.state.address.id,
            address
          );
        }
        success("Address saved!");
        this.setState(
          {
            loading: false,
            address,
          },
          () => {
            this.props.onAddressSaved(this.props.addressType, address);
          }
        );
      } catch (err) {
        error("Address could not be saved.");
        this.setState({ loading: false });
      }
    });
  }
}
