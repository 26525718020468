import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Select from "react-select";
import DatePicker from "react-date-picker";

import * as AppActions from "src/reducers/appReducer";
import * as UserActions from "src/reducers/userReducer";

import { Alert, Card, MarkdownEditor } from "src/components/structure";
import { AppsAPI } from "src/api";
import {
  mobileAppOS,
  mobileAppPlatform,
  mobileAppStatus,
  mobileAppUpdateRule,
} from "src/utils/items";
interface INewVersionProps {
  loading: boolean;
  userActions: any;
  userState: any;
  onVersionsCreated: any;
}

interface INewVersionState {
  loading: boolean;
  os: string;
  platform: string;
  releaseDate: string;
  semver: string;
  status: string;
  build: string;
  updateRule: string;
  publicNotes: string;
  privateNotes: string;
}

class newVersionScreen extends Component<INewVersionProps, INewVersionState> {
  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      os: "",
      platform: "",
      releaseDate: "",
      semver: "",
      status: "",
      build: "",
      updateRule: "",
      publicNotes: "",
      privateNotes: "",
    };
    this.updateField = this.updateField.bind(this);
    this.updateSelection = this.updateSelection.bind(this);
    this.updatePublicNotes = this.updatePublicNotes.bind(this);
    this.updatePrivateNotes = this.updatePrivateNotes.bind(this);
    this.addNewVersion = this.addNewVersion.bind(this);
  }

  render() {
    return (
      <div style={{ marginBottom: 20 }}>
        <Card
          title="Add New App Version"
          loading={this.props.loading || this.state.loading}
        >
          <div className="center">
            <p style={{ fontSize: 14, textAlign: "center" }}>
              <strong>
                Enter the following information about the version you would like
                to add.
              </strong>
            </p>
            <br />
          </div>
          <div className="form-group">
            <label style={{ fontSize: 12, marginLeft: 5 }}>OS</label>
            <Select
              options={mobileAppOS}
              onChange={this.updateSelection}
              hideSelectedOptions={true}
            />
          </div>
          <div className="form-group">
            <label style={{ fontSize: 12, marginLeft: 5 }}>Platform</label>
            <Select
              options={mobileAppPlatform}
              onChange={this.updateSelection}
              hideSelectedOptions={true}
            />
          </div>
          <div className="form-group">
            <label style={{ fontSize: 12, marginLeft: 5 }}>Semver</label>
            <input
              id="semver"
              type="text"
              className="form-control"
              placeholder="Semver"
              autoComplete="new-password"
              onChange={this.updateField}
              value={this.state.semver}
            />
          </div>
          <div className="form-group">
            <label style={{ fontSize: 12, marginLeft: 5 }}>Build</label>
            <input
              id="build"
              type="text"
              className="form-control"
              placeholder="Build"
              autoComplete="new-password"
              onChange={this.updateField}
              value={this.state.build}
            />
          </div>
          <div>
            <label style={{ fontSize: 12, marginLeft: 5 }}>Release Date</label>
            <div></div>
            <div style={{ marginLeft: 5, marginBottom: 10 }}>
              <DatePicker id="build" onChange={(value: any) => this.setState({releaseDate: value})} value={this.state.releaseDate} />
            </div>
          </div>
          <div className="form-group">
            <label style={{ fontSize: 12, marginLeft: 5 }}>Status</label>
            <Select
              options={mobileAppStatus}
              onChange={this.updateSelection}
              hideSelectedOptions={true}
            />
          </div>
          <div className="form-group">
            <label style={{ fontSize: 12, marginLeft: 5 }}>Update Rule</label>
            <Select
              options={mobileAppUpdateRule}
              onChange={this.updateSelection}
              hideSelectedOptions={true}
            />
          </div>
          <div className="form-group">
            <label style={{ fontSize: 12, marginLeft: 5 }}>Public Notes</label>
            <MarkdownEditor
              content={this.state.publicNotes}
              showToggle={true}
              showHelp={true}
              onChange={this.updatePublicNotes}
              mode="edit"
              rows={5}
            />
          </div>
          <div className="form-group">
            <label style={{ fontSize: 12, marginLeft: 5 }}>Private Notes</label>
            <MarkdownEditor
              content={this.state.privateNotes}
              showToggle={true}
              showHelp={true}
              onChange={this.updatePrivateNotes}
              mode="edit"
              rows={5}
            />
          </div>
          <div className="form-group" style={{ marginTop: 20 }}>
            <button
              className="btn btn-block btn-primary"
              onClick={this.addNewVersion}
            >
              Add New {this.state.os} App Version
            </button>
          </div>
        </Card>
      </div>
    );
  }

  updateSelection = (input: any) => {
    let ns = this.state;
    ns[input.field] = input.value;
    this.setState(ns);
  };

  updateField(e: any) {
    let ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private updatePublicNotes(content: any) {
    this.setState({ publicNotes: content });
  }

  private updatePrivateNotes(content: any) {
    this.setState({ privateNotes: content });
  }

  addNewVersion() {
    this.setState({ loading: true }, async () => {
      try {
        let build = this.state.build
        if (build === "" && this.state.os === "fwos") {
          build = "a"
        }
        let res = await AppsAPI.saveAppVersionForOS(
          this.state.platform,
          this.state.os,
          {
            os: this.state.os,
            platform: this.state.platform,
            releaseDate: this.state.releaseDate,
            semver: this.state.semver,
            status: this.state.status,
            build: build,
            updateRule: this.state.updateRule,
            publicNotes: this.state.publicNotes,
            privateNotes: this.state.privateNotes,
          }
        );
        const newVersion = res.body.data;
        return this.setState(
          {
            loading: false,
            os: "",
            platform: "",
            releaseDate: "",
            semver: "",
            status: "",
            build: "",
            updateRule: "",
            publicNotes: "",
            privateNotes: "",
          },
          () => {
            Alert.success("Version added successfully");
            this.props.onVersionsCreated(newVersion);
          }
        );
      } catch (err) {
        return this.setState({ loading: false }, () => {
          Alert.error("We could not add that version");
        });
      }
    });
  }
}

const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(AppActions, dispatch),
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(newVersionScreen);
