import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Card from "src/components/structure/Card";
import Screen from "src/components/structure/Screen";
import * as Alert from "src/components/structure/Alert";
import * as UserActions from "src/reducers/userReducer";
import { BillingAPI } from "src/api";

interface IProductIDPlansScreenProps {
  location: any;
  userActions: any;
  userState: any;
}

interface IProductIDPlansScreenState {
  loading: boolean;
  productId: string;
  plans: string;
}

class ProductIDPlansScreen extends Component<
  IProductIDPlansScreenProps,
  IProductIDPlansScreenState
> {
  constructor(props: IProductIDPlansScreenProps) {
    super(props);

    this.state = {
      loading: false,
      productId: "",
      plans: "",
    };

    this.updateField = this.updateField.bind(this);
    this.addEntryToDB = this.addEntryToDB.bind(this);
  }

  render() {
    return (
      <Screen id="productIdPlansScreen" requiredRoles={["backend"]}>
        <div className="row">
          <div className="col-8 offset-2">
            <Card title="Product ID Plans">
              <div className="row">
                <div className="col-6">
                  <p>
                    This tool exists to create a new entry in to the
                    "ProductIDPlans" database. This is only to be used when we
                    want to limit the available billing plans that will be
                    presented to the user when they register the device.
                  </p>
                  <p>
                    *Note: To add more than one Plan, type them in as comma
                    separated values. Example - xx,yy
                  </p>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label htmlFor="productId">Product ID</label>
                    <input
                      type="text"
                      className="form-control"
                      id="productId"
                      onChange={this.updateField}
                      value={this.state.productId}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="plans">Allowed Billing Plan(s) </label>
                    <input
                      type="text"
                      className="form-control"
                      id="plans"
                      onChange={this.updateField}
                      value={this.state.plans}
                    />
                  </div>
                  <div className="form-group">
                    <button
                      className="btn btn-primary btn-block"
                      onClick={this.addEntryToDB}
                    >
                      Add Now
                    </button>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </Screen>
    );
  }

  updateField(e: any) {
    let ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private addEntryToDB() {
    if (this.state.productId === "") {
      return Alert.error("You must include the product ID in your entry");
    }
    if (this.state.plans === "") {
      return Alert.error("You must include at least one plan in your entry");
    }
    const data = {
      productId: this.state.productId.trim(),
      plans: this.state.plans,
    };
    this.setState({ loading: true }, async () => {
      try {
        await BillingAPI.saveProductIDPlans(data);
        this.setState({ loading: false, productId: "", plans: "" });
        Alert.success("Product ID and its allowed plans have been added");
      } catch (err) {
        Alert.error("Could not save that data");
      }
    });
  }
}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProductIDPlansScreen) as any
);
