import { makeCall } from "./client";

export class NotificationsAPI {
  
  /**
   * Get all templates
   * @param app 
   * @param options 
   * @returns 
   */
  public getEmailTemplates = (app: string, options: any = {}) => {
    const data = {
      ...options
    };
    return makeCall('GET', `/admin/${app}/emails/templates`, data);
  }

  /**
   * Get the email history
   * @param app 
   * @param userId 
   * @param options 
   * @returns 
   */
  public getUserEmailHistory = (app: string, userId: number, options: any = {}) => {
    const data = {
      ...options
    };
    return makeCall('GET', `/admin/${app}/users/${userId}/emails`, data);
  }

  /**
   * Send an email
   * @param app 
   * @param shortName 
   * @param userId 
   * @param options 
   * @returns 
   */
  public sendEmailToUser = (app: string, shortName: string, userId: number, options: any = {}) => {
    const data = {
      ...options
    };
    return makeCall('POST', `/admin/${app}/users/${userId}/emails/${shortName}/send`, data);
  }

  /**
   * Send a push to a user
   * @param app 
   * @param userId 
   * @param options 
   * @returns 
   */
  public sendPushToUser = (app: string, userId: number, options: any = {}) => {
    const data = {
      ...options
    };
    return makeCall('POST', `/admin/${app}/users/${userId}/notifications/push`, data);
  }

  /**
   * Send push to a single token
   * @param app 
   * @param platform 
   * @param token 
   * @param text 
   * @param options 
   * @returns 
   */
  public sendPushToToken = (app: string, platform: string, token: string, text: string, options: any = {}) => {
    const data = {
      ...options,
      platform,
      token,
      text,
    };
    return makeCall('POST', `/admin/${app}/notifications/push`, data);
  }

  /**
   * Get email preference
   * @param app 
   * @param userId 
   * @param email 
   * @param options 
   * @returns 
   */
  public getUserEmailPreferences = (app: string, userId: number, email: string = "", options: any = {}) => {
    const data = {
      ...options,
    };
    if(email && email !== ""){
      data.email = email;
    }
    return makeCall('GET', `/admin/${app}/users/${userId}/email/preferences`, data);
  }

  /**
   * Save email preferences
   * @param app 
   * @param userId 
   * @param email 
   * @param options 
   * @returns 
   */
  public saveUserEmailPreferences = (app: string, userId: number, email: string = "", options: any = {}) => {
    const data = {
      ...options,
    };
    if(email && email !== ""){
      data.email = email;
    }
    return makeCall('POST', `/admin/${app}/users/${userId}/email/preferences`, data);
  }

  /**
   * Get user push pet preferences
   * @param app 
   * @param userId 
   * @param petId 
   * @param options 
   * @returns 
   */
  public getUserPushPetPreferences = (app: string, userId: number, petId: number, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('GET', `/admin/${app}/users/${userId}/push/preferences/pets/${petId}`, data);
  }

  /**
   * Save user push pet preferences
   * @param app 
   * @param userId 
   * @param petId 
   * @param options 
   * @returns 
   */
  public saveUserPushPetPreferences = (app: string, userId: number, petId: number, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('PATCH', `/admin/${app}/users/${userId}/push/preferences/pets/${petId}`, data);
  }

  /**
   * Get user push device preferences
   * @param app 
   * @param userId 
   * @param deviceId 
   * @param options 
   * @returns 
   */
  public getUserPushDevicePreferences = (app: string, userId: number, deviceId: number, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('GET', `/admin/${app}/users/${userId}/push/preferences/devices/${deviceId}`, data);
  }

  /**
   * Save user push device preferences
   * @param app 
   * @param userId 
   * @param deviceId 
   * @param options 
   * @returns 
   */
  public saveUserPushDevicePreferences = (app: string, userId: number, deviceId: number, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('PATCH', `/admin/${app}/users/${userId}/push/preferences/devices/${deviceId}`, data);
  }
}

export interface IPushPreference {
  userId: number;
  petId: number;
  deviceId: number;
  settingType: "pet" | "device";
  display: string;
  activity: "all" | "app_only" | "none";
  cleaning: "all" | "app_only" | "none";
  consumptionReport: "all" | "app_only" | "none";
  consumptionTrend: "all" | "app_only" | "none";
  fences: "all" | "app_only" | "none";
  foodAutoReplenishment: "all" | "app_only" | "none";
  foodRecommendation: "all" | "app_only" | "none";
  ota: "all" | "app_only" | "none";
  other: "all" | "app_only" | "none";
  power: "all" | "app_only" | "none";
  system: "all" | "app_only" | "none";
  tagz: "all" | "app_only" | "none";
  temperature: "all" | "app_only" | "none";
  trayChanged: "all" | "app_only" | "none";
  trayReminders: "all" | "app_only" | "none";

  // the below are for html editing in the display table
  activityDisplay?: string;
  cleaningDisplay?: string;
  consumptionReportDisplay?: string;
  consumptionTrendDisplay?: string;
  fencesDisplay?: string;
  foodAutoReplenishmentDisplay?: string;
  foodRecommendationDisplay?: string;
  otaDisplay?: string;
  otherDisplay?: string;
  powerDisplay?: string;
  systemDisplay?: string;
  tagzDisplay?: string;
  temperatureDisplay?: string;
  trayChangedDisplay?: string;
  trayRemindersDisplay?: string;
  actions?: any;
}

export const BlankPushPreference: IPushPreference = {
  userId: 0,
  petId: 0,
  deviceId: 0,
  display: "",
  settingType: "pet",
  activity: "all",
  cleaning: "all",
  consumptionReport: "all",
  consumptionTrend: "all",
  fences: "all",
  foodAutoReplenishment: "all",
  foodRecommendation: "all",
  ota: "all",
  other: "all",
  power: "all",
  system: "none",
  tagz: "all",
  temperature: "all",
  trayChanged: "all",
  trayReminders: "all",
}