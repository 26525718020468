import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as UserActions from "src/reducers/userReducer";
import { UserAPI } from "src/api";
import Card from "src/components/structure/Card";
import { Modal } from "react-bootstrap";
import { styles } from "src/styles";

import Geofence from "src/components/structure/Geofence";
import GoogleMapReact from "google-map-react";
import sizeof from "object-sizeof";
import ResponseSize from "src/components/structure/ResponseSize";

const mapKey = process.env.REACT_APP_GOOGLE_MAP_KEY || "";

interface IGeofenceTabProps {
  userId: number;
  location: any;
  userActions: any;
  userState: any;
}

interface IGeofenceTabState {
  loading: boolean;
  showAllFencesModal: boolean;
  showFencesStatus: "all" | "active" | "inactive";
  fences: any[];
  userFences: any[];
  lat: any;
  lng: any;
  responseSize: number;
}

class GeofenceTab extends Component<IGeofenceTabProps, IGeofenceTabState> {
  constructor(props: IGeofenceTabProps) {
    super(props);

    this.state = {
      loading: true,
      showAllFencesModal: false,
      showFencesStatus: "all",
      fences: [],
      userFences: [],
      lat: 0,
      lng: 0,
      responseSize: 0,
    };

    this.updateField = this.updateField.bind(this);
    this.getUserFences = this.getUserFences.bind(this);
    this.toggleShowAllFencesModal = this.toggleShowAllFencesModal.bind(this);
    this.toggleShowActiveFencesModal = this.toggleShowActiveFencesModal.bind(this);
    this.checkFenceColor = this.checkFenceColor.bind(this);
  }

  componentDidMount() {
    this.getUserFences();
  }

  updateField(e: any) {
    let ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  setFences() {
    const userFences = [];
    if (this.state.fences.length === 0) {
      userFences.push(
        <strong key={1}>No geofences exist for this user</strong>
      );
    } else {
      let index = 0;
      for (let userFence of this.state.fences) {
        index += 1;
        userFences.push(
          <Geofence
            key={index}
            fence={userFence}
            loading={false}
            userId={this.props.userId}
            simple={false}
          />
        );
      }
    }
    return userFences;
  }

  getUserFences() {
    this.setState({ loading: true }, async () => {
      try {
        const res = await UserAPI.getGeofencesForUser(
          "wagz",
          this.props.userId,
          { "includeInset": "yes"}
        );

        const fences = res.body.data;
        let lat = 0;
        let lng = 0;
        if(fences && fences.length > 0 && fences[0].area && fences[0].area[0] && fences[0].area[0].lat && fences[0].area[0].lng){
          lat = fences[0].area[0].lat;
          lng = fences[0].area[0].lng;
        }

        this.setState({
          loading: false,
          fences,
          lat,
          lng,
          responseSize: sizeof(res),
        });
      } catch (err) {
        this.setState({ loading: false });
      }
    });
  }

  handleGoogleMapApi = (google: {
    maps: {
      Polyline: new (arg0: {
        path: { lat: number; lng: number }[];
        geodesic: boolean;
        strokeColor: string;
        strokeOpacity: number;
        strokeWeight: number;
      }) => any;
    };
    map: any;
  }) => {
    for (let f of this.state.fences) {
      if(this.state.showFencesStatus === "all" || (this.state.showFencesStatus === "active" && f.active)){
        var flightPath = new google.maps.Polyline({
          path: f.area,
          geodesic: true,
          strokeColor: this.checkFenceColor(f.type),
          strokeOpacity: 1,
          strokeWeight: 5,
        });
  
        flightPath.setMap(google.map);
      }
    }
  };

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-4">
            <ResponseSize size={this.state.responseSize} />
          </div>
          <div className="col-2 offset-4">
            <button className="btn btn-success" onClick={this.toggleShowActiveFencesModal} >
              View Active Fences On Map
            </button>
          </div>
          <div className="col-2">
            <button className="btn btn-primary" onClick={this.toggleShowAllFencesModal} >
              View All Fences On Map
            </button>
          </div>
          <div>
            <Modal
              dialogClassName="modal90"
              show={this.state.showAllFencesModal}
              onHide={this.toggleShowAllFencesModal}
            >
              <Modal.Header closeButton>
                <Modal.Title>Viewing All Fences</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div
                  className="center"
                  style={{ height: "60vh", width: "100%" }}
                >
                  <GoogleMapReact
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={this.handleGoogleMapApi}
                    bootstrapURLKeys={{
                      key: mapKey,
                    }}
                    defaultCenter={{ lat: this.state.lat, lng: this.state.lng }}
                    defaultZoom={17}
                    options={{
                      keyboardShortcuts: false,
                      tilt: 0,
                      mapTypeId: "satellite",
                    }}
                  ></GoogleMapReact>
                </div>
                <div className="text" style={{ marginTop: 15 }}>
                  <button
                    className="btn btn-block btn-primary"
                    style={styles.button}
                    onClick={this.toggleShowAllFencesModal}
                  >
                    Close
                  </button>
                </div>
              </Modal.Body>
              <Modal.Footer></Modal.Footer>
            </Modal>
          </div>
        </div>
        <Card title="Geofences for User">{this.setFences()}</Card>
      </div>
    );
  }

  private checkFenceColor(type: string) {
    switch (type) {
      case "allowed": {
        return "#11cdef";
      }
      case "notAllowed": {
        return "#FF4433";
      }
      default: {
        return "#ffffff";
      }
    }
  }

  private toggleShowAllFencesModal() {
    this.setState({ showFencesStatus: "all", showAllFencesModal: !this.state.showAllFencesModal });
  }

  private toggleShowActiveFencesModal() {
    this.setState({ showFencesStatus: "active", showAllFencesModal: !this.state.showAllFencesModal })
  }
}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default connect<{}, {}, any>(
  mapStateToProps,
  mapDispatchToProps
)(GeofenceTab);
