import React, { Component } from "react";
import { UserAPI } from "src/api";


interface IAdminUserSelectProps {
  onUserSelected: any;
  onlyActive: boolean;
  selectedUserId: number;
  usersList?: any[];
}

interface IAdminUserSelectState {
  loading: boolean;
  users: any[];
}

export class AdminUserSelect extends Component<IAdminUserSelectProps, IAdminUserSelectState> {
  constructor(props: IAdminUserSelectProps) {
    super(props);

    this.state = {
      loading: false,
      users: [],
    };

    this.handleSelectedUser = this.handleSelectedUser.bind(this);
    this.setup = this.setup.bind(this);
  }

  componentDidMount(){
    this.setup();
  }

  render() {
    return (
      <div>
        <select className="form-control" value={this.props.selectedUserId} onChange={this.handleSelectedUser} id="selectedUserId">
          {this.state.users.map((user) => {
            return (<option key={user.id} value={user.id}>{user.firstName} {user.lastName}</option>)
          })}
        </select>
      </div>
    )
  }

  handleSelectedUser(e: any) {
    const user = parseInt(e.target.value, 10);
    this.props.onUserSelected(user);
  }

  private setup() {
    // is the users are provides, just set them and return
    if(this.props.usersList && this.props.usersList.length > 0){
      return this.setState({users: this.props.usersList, loading: false});
    }
    this.setState({ loading: true }, async () => {
      try{
        const data: any = {};
        if(this.props.onlyActive){
          data.status = "active";
        }
        const result = await UserAPI.getUsers(data);
        this.setState({users: result.body.data, loading: false });
      }catch(err){
        this.setState({ loading: false });
      }
    })
  }


}