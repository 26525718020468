import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Table from "src/components/structure/Table";
import { capitalizeFirstLetter } from "src/utils/utilities";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';

// import * as Alert from "../../structure/Alert";
import * as UserActions from "src/reducers/userReducer";
import { IColumns } from "src/utils/customTypes";
import { PetsAPI } from "src/api";
import { Card } from "src/components/structure";

interface IPetManagementProps {
  location: any;
  userActions: any;
  userState: any;
}

interface IPetManagementState {
  loading: boolean;
  rows: any[];
  csvData: any[];

  selectedSpecies: "cat" | "dog" | "all";
  selectedStatus: "active" | "removed" | "all";
}

const cols = [
  {
    label: "",
    field: "view",
    sort: "disabled"
  },
  {
    label: "ID",
    field: "id",
    sort: "asc",
    width: 100,
  },
  {
    label: "Name",
    field: "name",
    sort: "asc",
    width: 150,
  },
  {
    label: "Species",
    field: "species",
    sort: "asc",
    width: 200,
  },
  {
    label: "Status",
    field: "status",
    sort: "asc",
    width: 150,
  },
  {
    label: "Height",
    field: "height",
    sort: "asc",
    width: 150,
  },
  {
    label: "Height Units",
    field: "heightUnits",
    sort: "asc",
    width: 150,
  },
  {
    label: "Weight",
    field: "weight",
    sort: "asc",
    width: 150,
  },
  {
    label: "Weight Units",
    field: "weightUnits",
    sort: "asc",
    width: 150,
  },
  {
    label: "Lifestyle",
    field: "lifestyle",
    sort: "asc",
    width: 150,
  },
  {
    label: "Daily Water Goal (oz.)",
    field: "dailyWaterGoal",
    sort: "asc",
    width: 150,
  },
  {
    label: "Gender",
    field: "gender",
    sort: "asc",
    width: 150,
  },
  {
    label: "Registered By",
    field: "registeredBy",
    sort: "asc",
    width: 150,
  },
];

class PetManagement extends Component<
  IPetManagementProps,
  IPetManagementState
> {
  constructor(props: IPetManagementProps) {
    super(props);

    this.state = {
      loading: false,
      selectedSpecies: "dog",
      selectedStatus: "active",
      rows: [],
      csvData: [],
    };

    this.updateField = this.updateField.bind(this);
    this.populatePets = this.populatePets.bind(this);
    this.processData = this.processData.bind(this);
    this.refresh = this.refresh.bind(this);
  }

  updateField(e: any) {
    let ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  refresh(){
    this.populatePets();
  }

  componentDidMount() {
    this.populatePets();
  }

  populatePets() {
    this.setState({ loading: true }, async () => {
      try {
        const results = await PetsAPI.getAllPets("wagz", { count: 999999999, species: this.state.selectedSpecies, status: this.state.selectedStatus, includeBreeds: "" });

        const wagzPets = [];
        const csvData: any = [];
        for (let result of results.body.data) {
            csvData.push(result);
            wagzPets.push({
            view: (
              <div style={{textAlign: "center"}}>
                <Link to={`/support/users/${result.userId}/pets/${result.id}`} id="pet_page" className="link-light">
                  <FontAwesomeIcon icon={faEye} />
                </Link>
              </div>
            ),
            id: result.id,
            name: result.name,
            species: result.species,
            height: result.height,
            heightUnits: result.heightUnits,
            weight: result.weight,
            weightUnits: result.weightUnits,
            lifestyle: result.lifestyle,
            dailyWaterGoal: result.dailyWaterGoal,
            gender: result.gender,
            status: capitalizeFirstLetter(result.status),
            registeredBy: (
              <Link to={`/support/users/${result.userId}`} className="link-light">
                {result.userId}
              </Link>
            ),
          });
        }
        this.setState({
          loading: false,
          rows: wagzPets,
        });
      } catch (err) {
        this.setState({ loading: false });
      }
    });
  }

  processData(columns: IColumns[], rows: any) {
    const data = {
      columns,
      rows,
    };
    return data;
  }

  render() {
    return (
      <div>
        <Card title="Parameters" style={{marginBottom: 20}}>
          <div className="row">
            <div className="col-lg-4">
              <label>Species</label>
              <select className="form-control" value={this.state.selectedSpecies} id="selectedSpecies" onChange={this.updateField}>
                <option value="all">All Species</option>
                <option value="cat">Only Cats</option>
                <option value="dog">Only Dogs</option>
              </select>
            </div>
            <div className="col-lg-4">
              <label>Status</label>
              <select className="form-control" value={this.state.selectedStatus} id="selectedStatus" onChange={this.updateField}>
                <option value="all">All Statuses</option>
                <option value="active">Only Active Pets</option>
                <option value="removed">Only Removed Pets</option>
              </select>
            </div>
            <div className="col-lg-4">
              <label>&nbsp;</label>
              <button className="btn btn-block btn-primary" onClick={this.refresh}>Update</button>
            </div>
          </div>
        </Card>
        <Table
          title="Pet Management"
          csvFileName="pets"
          showDownload={true}
          columns={cols}
          rows={this.state.rows}
          csvData={this.state.csvData}
        />
      </div>
    );
  }
}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PetManagement) as any
);
