import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Screen, Card } from "src/components/structure";
import Select from "react-select";

import * as UserActions from "src/reducers/userReducer";
import { MetricsAPI, PetsAPI } from "src/api";
import moment from "moment";
import { error } from "src/components/structure/Alert";
import { LineGraph } from "src/components/structure/LineGraph";

interface IBreedActivityReportGraphProps {
  location: any;
  userActions: any;
  userState: any;
}

interface IBreedActivityReportGraphState {
  loading: boolean;
  monthStart: string;
  monthEnd: string;
  showResetWarningModal: boolean;
  entries: any; // will be metric:[]entries
  filteredEntries: any; // needed for the breed filters
  months: string[];
  filteredBreeds: any[];
  allBreeds: any[];
  allBreedOptions: any[];

  dateChanged: boolean;
  hasData: boolean;

  monthsWithData: object;
}



class BreedActivityReportGraph extends Component<
  IBreedActivityReportGraphProps,
  IBreedActivityReportGraphState
> {
  constructor(props: IBreedActivityReportGraphProps) {
    super(props);

    this.state = {
      loading: true,
      showResetWarningModal: false,
      entries: [],
      monthStart: moment().subtract(2, "months").format("YYYY-MM"),
      monthEnd: moment().format("YYYY-MM"),
      months: [],
      filteredEntries: [],
      filteredBreeds: [],
      allBreeds: [],
      allBreedOptions: [],

      dateChanged: false,
      hasData: false,
      monthsWithData: {},
    };

    this.updateField = this.updateField.bind(this);
    this.fetch = this.fetch.bind(this);
    this.filter = this.filter.bind(this);
    this.selectBreed = this.selectBreed.bind(this);
  }

  componentDidMount() {
    const months = this.getMonths()
    this.setState({months}, () => {
      this.fetch();
    });
  }


  render() {
    return (
      <Screen shouldAuthCheck={true} requiredRoles={["reporting"]} loading={this.state.loading}>
        <Card title="Graph Filters">
          <div className="row">
            <div className="col-3">
              <select id="monthStart" className="form-control" value={this.state.monthStart} onChange={this.updateField}>
                {this.state.months.map((month) => {
                  return <option value={month} key={month}>{month}</option>
                })}
              </select>
            </div>
            <div className="col-3">
              <select id="monthEnd" className="form-control" value={this.state.monthEnd} onChange={this.updateField}>
                {this.state.months.map((month) => {
                  return <option value={month} key={month}>{month}</option>
                })}
              </select>
            </div>
            <div className="col-3">
              <Select options={this.state.allBreedOptions} isMulti={true} hideSelectedOptions={true} onChange={this.selectBreed} value={this.state.filteredBreeds} />
            </div>
            <div className="col-3">
              <button className="btn btn-block btn-success" onClick={this.filter}>Update Graphs</button>
            </div>
          </div>
        </Card>
        {this.state.hasData ? (
          <>
            <Card title="Average Age in Years" loading={this.state.loading}>
              <div className="row">
                <div className="col-12">
                  <LineGraph loading={this.state.loading} data={this.state.filteredEntries["averageAgeInYears"] ? this.state.filteredEntries["averageAgeInYears"] : []} />
                </div>
              </div>
            </Card>
            <Card title="Average Weight">
              <div className="row">
                <div className="col-12">
                  <LineGraph loading={this.state.loading} data={this.state.filteredEntries["averageWeight"] ? this.state.filteredEntries["averageWeight"] : []} />
                </div>
              </div>
            </Card>
            <Card title="Average Steps">
              <div className="row">
                <div className="col-12">
                  <LineGraph loading={this.state.loading} data={this.state.filteredEntries["averageSteps"] ? this.state.filteredEntries["averageSteps"] : []} />
                </div>
              </div>
            </Card>
            <Card title="Average Fit Units">
              <div className="row">
                <div className="col-12">
                  <LineGraph loading={this.state.loading} data={this.state.filteredEntries["averageFitUnits"] ? this.state.filteredEntries["averageFitUnits"] : []} />
                </div>
              </div>
            </Card>
            <Card title="Average Companion Minutes">
              <div className="row">
                <div className="col-12">
                  <LineGraph loading={this.state.loading} data={this.state.filteredEntries["averageCompanionTime"] ? this.state.filteredEntries["averageCompanionTime"] : []} />
                </div>
              </div>
            </Card>
            <Card title="Average Exercise Minutes">
              <div className="row">
                <div className="col-12">
                  <LineGraph loading={this.state.loading} data={this.state.filteredEntries["averageExerciseMinutes"] ? this.state.filteredEntries["averageExerciseMinutes"] : []} />
                </div>
              </div>
            </Card>
            <Card title="Average Sleep Minutes">
              <div className="row">
                <div className="col-12">
                  <LineGraph loading={this.state.loading} data={this.state.filteredEntries["averageSleepMinutes"] ? this.state.filteredEntries["averageSleepMinutes"] : []} />
                </div>
              </div>
            </Card>
            <Card title="Average HHS">
              <div className="row">
                <div className="col-12">
                  <LineGraph loading={this.state.loading} data={this.state.filteredEntries["averageHHS"] ? this.state.filteredEntries["averageHHS"] : []} />
                </div>
              </div>
            </Card>
          </>
        ) : (
            <Card title="Error" loading={this.state.loading}>
              <div className="row">
                <div className="col-12">
                  There's no data for the selected filters
                </div>
              </div>
            </Card>
          )
        }
      </Screen>
    );
  }

  updateField(e: any) {
    let ns: any = this.state;
    const id = e.target.id;
    const val = e.target.value;
    ns[id] = val;
    if(id === "monthStart" || id === "monthEnd"){
      ns["dateChanged"] = true;
    }
    this.setState(ns);
  }


  private fetch(){
    this.setState({ loading: true }, async () => {
      try{
        // we need to loop and get each month and then set up the keys
        const startParsed = this.state.monthStart.split("-");
        const endParsed = this.state.monthEnd.split("-");
        const breeds = await PetsAPI.getBreeds("wagz2");
        const allBreeds = breeds.body.data;
        const allBreedOptions: any[] = [];

        const currentMonth = moment().year(parseInt(startParsed[0])).month(parseInt(startParsed[1]) -1).day(1); // 0 indexes ftw
        const endMonth = moment().year(parseInt(endParsed[0])).month(parseInt(endParsed[1]) -1).endOf("month");
        
        const months: string[] = [];

        const activities: any = [];
        const monthsWithData = this.state.monthsWithData;
        while(currentMonth.isBefore(endMonth)){
          // only fetch if there's no data for that month
          const m = moment(currentMonth).format("YYYY-MM");
          months.push(m);
          if(monthsWithData[m]){
            activities.push(...monthsWithData[m]);
          } else {
            const result = await MetricsAPI.getBreedActivityReports("wagz2", m);
            monthsWithData[m] = result.body.data;
            activities.push(...monthsWithData[m]);
          }
          currentMonth.add(1, "month");
        }

        // just set up the alls; any filtering and displays should come later
        const grouping = this.groupMetrics(activities, months);

        for(let i = 0; i < allBreeds.length; i++){
          allBreedOptions.push({ value: allBreeds[i].breed, label: allBreeds[i].breed});
        }

        this.setState({ entries: grouping, allBreeds, allBreedOptions, dateChanged: false, monthsWithData}, () => {
          this.filter();
        });
      }catch(err){
        this.setState({loading: false});
        error("Could not fetch that report. Perhaps try resetting?");
      }
    })
  }


  private groupMetrics(rawMetrics: any, months: string[]){
    // put the metrics into the following:
    // the graph expects an array like [{key: month, husky: 1, shepherd: 3}]
    // we get it as a raw array like {breedName: husky, averageAgeInYears: 5}
    // so to convert without a ton of code, we need to try to get the
    // data to line up

    // probably easiest to try to make a map like
    // {
    //   metric: {
    //     month: {
    //       breed: value
    //     }
    //   }
    // }
    //
    // then convert the months into something like
    // {
    //   metric: [{ month: "", breed: value}]
    // }

    const processed: any = {};
    const final: any = {};

    // first, let's just set up the metrics
    if(rawMetrics.length === 0){
      return rawMetrics // TODO: change out
    }
    for(const k in rawMetrics[0]){
      if(k.substring(0, 7) === "average"){
        processed[k] = {};
        for(const m of months){
          processed[k][m] = {};
        }
        final[k] = [];
      }
    }

    // now consolidate

    for(const raw of rawMetrics){
      for(const metric in raw){
        if(metric.substring(0, 7) === "average"){
          const month = raw["month"];
          const breedName = raw["breedName"];
          const val = raw[metric];
          processed[metric][month][breedName] = val;
        }
      }
    }

    for(const metric in processed){
      const a = [];
      for(const month in processed[metric]){
        const m: any = { key: month};
        for(const breed in processed[metric][month]){
          m[breed] = processed[metric][month][breed];
        }
        a.push(m);
      }
      final[metric] = a;
    }
    return final;
  }

  private filter(){
    this.setState({ loading: true }, () => {
      if(this.state.dateChanged){
        return this.fetch();
      }
      // arrays are addressed, so this will just nuke the underlying structure anyway
      // end result should be a map like:
      // {
      //   metric: [
      //     {key: "", breed1: 1, breed2: 1}
      //   ]
      // }
      let filteredEntries: any = {};
      let hasData = false;
      for(const metric of Object.keys(this.state.entries)){
        if(!filteredEntries[metric]){
          filteredEntries[metric] = [];
        }
        for(const grouping of this.state.entries[metric]){
          // each of these groupings has a key and all breeds for that metric
          // so figure out what goes where
          const filteredGroup: any = {};
          for(const key of Object.keys(grouping)){
            if(key === "key"){
              filteredGroup["key"] = grouping[key];
              continue;
            }
            for(const fb of this.state.filteredBreeds){
              if(key === fb.value){
                filteredGroup[key] = grouping[key];
                hasData = true;
                continue;
              }
            }
          }
          filteredEntries[metric].push(filteredGroup);
        }
      }

      this.setState({loading: false, filteredEntries, hasData});
    })    
  }

  private getMonths() : string[]{
    const earliest = moment().year(2021).month(11).day(1);
    const current = moment();
    const months = [];
    while(current.isAfter(earliest)){
      months.push(moment(current).format("YYYY-MM"))
      current.subtract(1, "month");
    }
    return months;
  }

  private selectBreed(filteredBreeds: any){
    if(!filteredBreeds){
      return this.setState({filteredBreeds: []});
    }
    this.setState({ filteredBreeds });
  }
}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BreedActivityReportGraph) as any
);
