import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Screen from "src/components/structure/Screen";
import * as UserActions from "src/reducers/userReducer";
import moment from "moment";
import Card from "src/components/structure/Card";
import Table from "src/components/structure/Table";
import { Modal } from "react-bootstrap";
import { BillingAPI } from "src/api";
import { error } from "src/components/structure/Alert";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DatePicker } from "src/components/structure";
import { capitalizeFirstLetter } from "src/utils/utilities";

const app = "wagz2";

interface IRMAListProps {
  location: any;
  userActions: any;
  userState: any;
}

interface IRMAListState {
  loading: boolean;
  rmas: any[];
  start: moment.Moment;
  end: moment.Moment;
  count: string;
  offset: string;

  showNewRMAModal: boolean;
  newRMACustomerFirstName: string;
  newRMACustomerLastName: string;
  newRMARecipientFirstName: string;
  newRMARecipientLastName: string;
  newRMANumber: string;
  newRMADetails: string;
  newRMANotes: string;
  showCreatedModal: boolean;
  createdId: number;
}

class RMAListScreen extends Component<IRMAListProps, IRMAListState> {
  constructor(props: IRMAListProps) {
    super(props);

    this.state = {
      loading: false,
      rmas: [],
      start: moment().add(-1, "years"),
      end: moment(),
      count: "10000",
      offset: "0",

      showNewRMAModal: false,
      newRMACustomerFirstName: "",
      newRMACustomerLastName: "",
      newRMARecipientFirstName: "",
      newRMARecipientLastName: "",
      newRMANumber: "",
      newRMADetails: "",
      newRMANotes: "",

      showCreatedModal: false,
      createdId: 0,
    };

    this.updateField = this.updateField.bind(this);
    this.updateFilterEnd = this.updateFilterEnd.bind(this);
    this.updateFilterStart = this.updateFilterStart.bind(this);
    this.toggleShowNewRMAModal = this.toggleShowNewRMAModal.bind(this);
    this.toggleShowCreatedModal = this.toggleShowCreatedModal.bind(this);
    this.setup = this.setup.bind(this);
    this.fetchRMAs = this.fetchRMAs.bind(this);
    this.createNewRMA = this.createNewRMA.bind(this);
  }

  componentDidMount() {
    this.setup();
  }

  render() {
    return (
      <Screen id="rmaListScreen" requiredRoles={["product", "support"]}>
        <Card title="Filters">
          <div className="row">
            <div className="col-lg-4">
              <label>From</label>
              <DatePicker date={this.state.start} onDateSaved={this.updateFilterStart} />
            </div>
            <div className="col-lg-4">
              <label>To</label>
              <DatePicker date={this.state.end} onDateSaved={this.updateFilterEnd} />
            </div>
            <div className="col-lg-2">
              <button className="btn btn-block btn-primary" onClick={this.fetchRMAs} style={{marginTop: 28}}>Filter</button>
            </div>
            <div className="col-lg-2">
              <button className="btn btn-block btn-success" onClick={this.toggleShowNewRMAModal} style={{marginTop: 28}}>+New</button>
            </div>
          </div>
        </Card>

        <Table
          loading={this.state.loading}
          title="RMAs"
          csvFileName="rmas"
          showDownload={false}
          columns={cols}
          rows={this.state.rmas}
        />


        <Modal show={this.state.showNewRMAModal} onHide={this.toggleShowNewRMAModal} dialogClassName="modal90">
          <Modal.Header closeButton>
            <Modal.Title>
              Create New RMA
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row" style={{ marginBottom: 10 }}>
              <div className="col-3">
                <label>Customer First Name</label>
                <input type="text" className="form-control" id="newRMACustomerFirstName" value={this.state.newRMACustomerFirstName} onChange={this.updateField} />
              </div>
              <div className="col-3">
                <label>Customer Last Name</label>
                <input type="text" className="form-control" id="newRMACustomerLastName" value={this.state.newRMACustomerLastName} onChange={this.updateField} />
              </div>
              <div className="col-3">
                <label>Recipient First Name</label>
                <input type="text" className="form-control" id="newRMARecipientFirstName" value={this.state.newRMARecipientFirstName} onChange={this.updateField} />
              </div>
              <div className="col-3">
                <label>Recipient Last Name</label>
                <input type="text" className="form-control" id="newRMARecipientLastName" value={this.state.newRMARecipientLastName} onChange={this.updateField} />
              </div>
            </div>
            <div className="row" style={{ marginBottom: 10 }}>
              <div className="col-3">
                <label>RMA Number</label>
                <input type="text" className="form-control" id="newRMANumber" value={this.state.newRMANumber} onChange={this.updateField} />
              </div>
              <div className="col-4">
                <label>Details</label>
                <textarea className="form-control" id="newRMADetails" value={this.state.newRMADetails} onChange={this.updateField} />
              </div>
              <div className="col-4">
                <label>Notes</label>
                <textarea className="form-control" id="newRMANotes" value={this.state.newRMANotes} onChange={this.updateField} />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-block btn-primary" onClick={this.createNewRMA} style={{ marginBottom: 10 }}>Create</button>
            <button className="btn btn-block btn-danger" onClick={this.toggleShowNewRMAModal}>Close</button>
          </Modal.Footer>
        </Modal>
      </Screen>
    )
  }

  updateField(e: any) {
    let ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  updateFilterStart(newDate: moment.Moment) {
    this.setState({ start: newDate });
  }

  updateFilterEnd(newDate: moment.Moment) {
    this.setState({ end: newDate });
  }

  private toggleShowNewRMAModal() {
    this.setState({ showNewRMAModal: !this.state.showNewRMAModal })
  }

  private toggleShowCreatedModal() {
    this.setState({ showCreatedModal: !this.state.showCreatedModal })
  }

  private setup() {
    // this used to fetch codes and such, but now we just hand off
    this.fetchRMAs();
  }

  private fetchRMAs() {
    this.setState({ loading: true }, async () => {
      try {
        const data = {
          start: this.state.start.format("YYYY-MM-DDT") + "00:00:00Z",
          end: this.state.end.format("YYYY-MM-DDT") + "23:59:59Z",
        };
        const result = await BillingAPI.getRMAs(app, data);
        const rmas: any = [];
        for (const r of result.body.data) {
          rmas.push(this.processRMA(r));
        }
        this.setState({ loading: false, rmas });
      } catch (err) {
        this.setState({ loading: false });
      }
    })
  }

  private processRMA(raw: any): any {
    raw.customerName = `${raw.customerFirstName} ${raw.customerLastName}`;
    raw.recipientName = `${raw.recipientFirstName} ${raw.recipientLastName}`;
    raw.status = capitalizeFirstLetter(raw.status);
    raw.createdDisplay = moment(raw.createdOn).format("MM/DD/YY HH:mm");
    raw.receivedDisplay = `${raw.quantityReceived}/${raw.quantity}`;
    raw.productRefundsDisplay = raw.pendingProductRefundCount === 0 ? "NO" : raw.pendingProductRefundCount;
    raw.subRefundsDisplay = raw.pendingSubscriptionRefundCount === 0 ? "NO" : raw.pendingSubscriptionRefundCount;
    raw.actions = (
      <div style={{textAlign: "center"}}>
        <a href={`/rmas/${raw.id}`} target={`_new_${raw.id}`}><FontAwesomeIcon icon={faEye} color="#172b4d"/></a>
      </div>
    )
    return raw;
  }

  private createNewRMA() {
    const data: any = {
      createdByAdminUserId: this.props.userState.user.id,
      handledByAdminUserId: this.props.userState.user.id,
      customerFirstName: this.state.newRMACustomerFirstName,
      customerLastName: this.state.newRMACustomerLastName,
      recipientFirstName: this.state.newRMARecipientFirstName,
      recipientLastName: this.state.newRMARecipientLastName,
      rmaNumber: this.state.newRMANumber,
      details: this.state.newRMADetails,
      notes: this.state.newRMANotes,
    };
    this.setState({ loading: true }, async () => {
      try {
        // create the RMA
        const result = await BillingAPI.createRMA(app, data);
        const rma = this.processRMA(result.body.data);
        const rmas: any[] = [];
        rmas.push(rma);
        for(const r of this.state.rmas){
          rmas.push(r);
        }

        // update the modal message
        this.setState({
          showCreatedModal: true,
          showNewRMAModal: false,
          loading: false,
          rmas,
        })

      } catch (err) {
        error("Could not create that RMA")
        this.setState({ loading: false });
      }
    })
  }

}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RMAListScreen) as any
);

const cols: any = [
  {
    "label": "id",
    "field": "id",
  },
  {
    "label": "Created",
    "field": "createdDisplay",
  },
  {
    "label": "Customer",
    "field": "customerName",
  },
  {
    "label": "Recipient",
    "field": "recipientName",
  },
  {
    "label": "Status",
    "field": "status",
  },
  {
    "label": "Items",
    "field": "countOfItems",
  },
  {
    "label": "Received",
    "field": "receivedDisplay"
  },
  {
    "label": "Pending Product Refunds",
    "field": "productRefundsDisplay"
  },
  {
    "label": "Pending Sub Refunds",
    "field": "subRefundsDisplay"
  },
  {
    "label": "",
    "field": "actions",
  },
];