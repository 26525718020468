import React, { Component } from "react";
import {  withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CSVReader from "react-csv-reader";

import Card from "src/components/structure/Card";
import Screen from "src/components/structure/Screen";
import * as UserActions from "src/reducers/userReducer";
import { NotificationsAPI, UserAPI } from "src/api";
import { UploadEmailPreferencesRow } from "./UploadEmailPreferencesRow";
import { error, success } from "src/components/structure/Alert";

interface IUploadEmailPreferencesScreenProps {
  location: any;
  userActions: any;
  userState: any;
}

interface IUploadUploadEmailPreferencesScreenState {
  loading: boolean;
  updates: number;
  uploadedEmails: string[];
  users: any[];
  parsedFilename: string;

  receiveMarketing: "" | "yes" | "no";
  receiveTips: "" | "yes" | "no";
  receiveOther: "" | "yes" | "no";
  receiveAccountUpdates: "" | "yes" | "no";
}

class UploadUploadEmailPreferencesScreen extends Component<IUploadEmailPreferencesScreenProps, IUploadUploadEmailPreferencesScreenState> {
  constructor(props: IUploadEmailPreferencesScreenProps) {
    super(props);

    this.state = {
      loading: false,
      updates: 0,
      uploadedEmails: [],
      users: [],
      parsedFilename: "",
      receiveAccountUpdates: "",
      receiveOther: "",
      receiveMarketing: "",
      receiveTips: "",
    };

    this.updateField = this.updateField.bind(this);
    this.updateAllValue = this.updateAllValue.bind(this);
    this.handleParse = this.handleParse.bind(this);
    this.handleFieldChanged = this.handleFieldChanged.bind(this);
    this.handleRowSave = this.handleRowSave.bind(this);
    this.saveAll = this.saveAll.bind(this);
  }

  private parserOptions = {
    header: true,
    trimHeaders: true,
    skipEmptyLines: true,
    dynamicTyping: false,
    transformHeader: (header: string) => header.toLowerCase()
  };


  render() {
    return (
      <Screen id="UploadCouponsScreen" requiredRoles={["backend", "product"]}>
        <div className="row">
          <div className="col-2">
            <Card title="Upload" loading={this.state.loading}>
              <p>Choose a CSV that contains email addresses with the header "email" or "emailAddress" in the first column, each on a new line. All other fields will be ignored at this time.</p>
              <div className="form-group">
                <CSVReader 
                  onFileLoaded={this.handleParse}
                  parserOptions={this.parserOptions}
                />
              </div>
            </Card>
          </div>
          <div className="col-10">
            <Card title="Manage" loading={this.state.loading}>
              {this.state.users.length === 0 ? (
                <strong>No valid rows or data</strong>
              ) : (
                <div className="row" style={{marginBottom: 15}}>
                  <div className="col-3">Update All To:</div>
                  <div className="col-2">
                    <strong className="row-header">Marketing</strong>
                    <select className="form-control" id="receiveMarketing" value={this.state.receiveMarketing} onChange={this.updateAllValue}>
                      <option value="">Current</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                  </div>
                  <div className="col-2">
                    <strong className="row-header">Tips</strong>
                    <select className="form-control" id="receiveTips" value={this.state.receiveTips} onChange={this.updateAllValue}>
                      <option value="">Current</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                  </div>
                  <div className="col-2">
                    <strong className="row-header">Account</strong>
                    <select className="form-control" id="receiveAccountUpdates" value={this.state.receiveAccountUpdates} onChange={this.updateAllValue}>
                      <option value="">Current</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                  </div>
                  <div className="col-2">
                    <strong className="row-header">Other</strong>
                    <select className="form-control" id="receiveOther" value={this.state.receiveOther} onChange={this.updateAllValue}>
                      <option value="">Current</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                  </div>
                  <div className="col-1">
                  </div>
                </div>
              )}
              {this.state.users.map((u: any, i: number) => {
                return (
                  <UploadEmailPreferencesRow 
                    key={i + this.state.updates} 
                    user={u} 
                    receiveAccountUpdates={u.receiveAccountUpdates} 
                    receiveMarketing={u.receiveMarketing} 
                    receiveOther={u.receiveOther} 
                    receiveTips={u.receiveTips} 
                    receiveTransactional={u.receiveTransactional} 
                    onFieldChanged={this.handleFieldChanged}
                    onSave={this.handleRowSave}
                  />
                );
              })}
              {this.state.users.length !== 0 && (
                <div className="row" style={{marginTop: 15}}>
                  <div className="col-12">
                    <button className="btn btn-block btn-primary" onClick={this.saveAll}>Save All</button>
                  </div>
                </div>
              )}
            </Card>
          </div>
        </div>
      </Screen>
    )
  }

  updateField(e: any) {
    let ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  updateAllValue(e: any){
    const ns: any = this.state;
    const users: any[] = [];
    const field = e.target.id;
    const val = e.target.value;
    for(const u of this.state.users){
      u[field] = val;
      users.push(u);
    }
    ns["users"] = users;
    ns[field] = val;
    this.setState({ users });
  }

  private handleParse(data: any, fileInfo: any){
    this.setState({ loading: true }, async () => {
      // grab the file name
      const fileName = fileInfo.name ? fileInfo.name : "unknown";
      const users: any[] = [];
      // we need to loop over each one and get their email preferences
      for(const d of data){
        try{
          const userResult = await UserAPI.getUserByEmail("wagz2", d.email); // we can build this out if we really need first / last name
          const user = userResult.body.data;
          const prefsResult = await NotificationsAPI.getUserEmailPreferences("wagz2", user.id);
          const prefs = prefsResult.body.data;
          const data = {
            email: d.email, 
            id: user.id, 
            firstName: user.firstName, 
            lastName: user.lastName, 
            ...prefs
          };
          users.push(data);
        }catch(err){}
      }
      this.setState({loading: false, users: users,  parsedFilename: fileName});
    })
  }

  private handleRowSave(userId: number){
    let data: any = {};
    for(const u of this.state.users){
      if(u.id === userId){
        data = u;
        break;
      }
    }
    
    this.setState({ loading: true }, async () => {
      try{
        await NotificationsAPI.saveUserEmailPreferences("wagz2", data.id, "", data);
        success("Preferences saved!");
        this.setState({ loading: false})
      }catch(err){
        error("Could not save those preferences.")
        this.setState({ loading: false})
      }
    })
  }

  private handleFieldChanged(userId: any, field: string, value: string){
    const users: any[] = [];
    for(const u of this.state.users){
      if(u.id === userId){
        u[field] = value;
      }
      users.push(u);
    }
    this.setState({ users, updates: this.state.updates + 1 });
  }

  private saveAll(){
    this.setState({ loading: true }, async () => {
      try{
        for(const u of this.state.users){
          // TODO: when this becomes large, we will want a new batch input
          await NotificationsAPI.saveUserEmailPreferences("wagz2", u.id, "", u);
        }
        success("Saved!");
        this.setState({ loading: false });
      }catch(err){
        error("Could not save those records.");
        this.setState({ loading: false });
      }
    })
  }
}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UploadUploadEmailPreferencesScreen) as any
);
