import React, { Component } from "react";
import { Table, Screen, DatePicker, PieChart } from "src/components/structure";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from '@fortawesome/free-solid-svg-icons';
import moment from "moment";
import Card from "src/components/structure/Card";
import { AnalyticsAPI } from "src/api";
import { IAnalyticEvent, IAnalyticKnownEvent } from "src/api/analytics";
import { LineGraph } from "src/components/structure/LineGraph";
import Select from "react-select";
import { error } from "src/components/structure/Alert";

interface IAnalyticEventsScreenProps {
  target: "all" | "user" | "pet" | "device";
  targetId: number;
}

interface IAnalyticEventsScreenState {
  loading: boolean;
  knownEvents: IAnalyticKnownEvent[];
  events: IAnalyticEvent[] | any[]; // could be an IAnalyticEvent OR a grouped
  csvEvents: any[];
  start: moment.Moment;
  end: moment.Moment;
  count: string;
  offset: string;
  filteredEvent: string;
  showMoreModal: boolean;
  selectedEvent: any;

  pcData: any[];
  lineData: any[];

  format: "individual" | "group";
  unit: "day" | "hour";
  formatChanged: boolean;
  knownEventsForGraph: any[];
  selectedGraphEvents: any[];
}

const app = "wagz2";
const individualCols: any[] = [
  {
    label: "Posted",
    field: "posted"
  },
  {
    label: "Event",
    field: "event"
  },
  {
    label: "User",
    field: "userLink"
  },
  {
    label: "Device",
    field: "deviceLink"
  },
  {
    label: "Pet",
    field: "petLink"
  },
  {
    label: "",
    field: "view"
  },
];
const formatCols: any[] = [
  {
    label: "Time",
    field: "timePeriod"
  },
  {
    label: "Event",
    field: "value"
  },
  {
    label: "Count",
    field: "count"
  },
];

export class AnalyticEventsScreen extends Component<
  IAnalyticEventsScreenProps,
  IAnalyticEventsScreenState
> {
  constructor(props: IAnalyticEventsScreenProps) {
    super(props);

    this.state = {
      loading: true,
      knownEvents: [],
      events: [],
      csvEvents: [],
      start: moment().subtract(3, "days"),
      end: moment(),
      count: "1000",
      offset: "0",
      filteredEvent: "all",
      pcData: [],
      lineData: [],
      showMoreModal: false,
      selectedEvent: false,
      format: "group",
      unit: "day",
      formatChanged: false,

      knownEventsForGraph: [],
      selectedGraphEvents: [],
    };

    this.updateField = this.updateField.bind(this);
    this.updateStart = this.updateStart.bind(this);
    this.updateEnd = this.updateEnd.bind(this);
    this.setup = this.setup.bind(this);
    this.getEvents = this.getEvents.bind(this);
    this.selectEvent = this.selectEvent.bind(this);
    this.toggleShowMoreModal = this.toggleShowMoreModal.bind(this);
    this.selectGraphEvent = this.selectGraphEvent.bind(this);
    this.filterGraphs = this.filterGraphs.bind(this);
  }


  componentDidMount() {
    this.setup();
  }



  render() {
    return (
      <Screen shouldAuthCheck={true} requiredRoles={["reporting", "product", "support"]} loading={this.state.loading}>
        <Card title="Filters">
          <div className="row">
            <div className={this.state.format === "group" ? "col-8" : "col-10"}>
              <div className="row">
                <div className="col-2">
                  <label>Start</label>
                  <DatePicker date={this.state.start} onDateSaved={this.updateStart} />
                </div>
                <div className="col-2">
                  <label>End</label>
                  <DatePicker date={this.state.end} onDateSaved={this.updateEnd} />
                </div>
                <div className="col-2">
                  <label>Format</label>
                  <select className="form-control" value={this.state.format} id="format" onChange={this.updateField}>
                    <option value="individual">Individual</option>
                    <option value="group">Group</option>
                  </select>
                </div>
                {this.state.format === "individual" && (
                  <>
                    <div className="col-2">
                      <label>Count</label>
                      <input type="text" className="form-control" id="count" value={this.state.count} onChange={this.updateField} />
                    </div>
                    <div className="col-2">
                      <label>Offset</label>
                      <input type="text" className="form-control" id="offset" value={this.state.offset} onChange={this.updateField} />
                    </div>
                    <div className="col-2">
                      <label>Event</label>
                      <select className="form-control" value={this.state.filteredEvent} id="filteredEvent" onChange={this.updateField}>
                        <option value="all">All</option>
                        {this.state.knownEvents.map((e) => {
                          return <option key={e.id} value={e.event}>{e.event}</option>
                        })}
                      </select>
                    </div>
                  </>
                )}
                {this.state.format === "group" && (
                  <>
                    <div className="col-2">
                      <label>Group By</label>
                      <select className="form-control" value={this.state.unit} id="unit" onChange={this.updateField}>
                        <option value="day">Day</option>
                        <option value="hour">Day and Hour</option>
                      </select>
                    </div>
                  </>
                )}
              </div>
              <div className="row" style={{marginTop: 20}}>
                <div className="col-12">
                  <button className="btn btn-block btn-primary" onClick={this.getEvents}>Update</button>
                </div>
              </div>
            </div>
            <div className={this.state.format === "group" ? "col-4" : "col-2"}>
              <label>Show On Graph:</label>
              <Select options={this.state.knownEventsForGraph} isMulti={true} hideSelectedOptions={true} onChange={this.selectGraphEvent} value={this.state.selectedGraphEvents} />
            </div>
          </div>


        </Card>
        <div style={{ marginTop: 10 }}>
          {this.state.formatChanged && (
            <div className="col-6 offset-3">
              <Card title="Parameters Changed">
                The parameters have changed in a way that you need to hit the Update button above.
              </Card>
            </div>
          )}
          {this.state.events.length !== 0 && !this.state.loading && !this.state.formatChanged && (
            <div className="row">
              <div className="col-6">
                <Table
                  title="Events"
                  csvFileName={`analytic-events-${this.state.start.format("YYYY-MM-DD")}-${this.state.end.format("YYYY-MM-DD")}-events-${this.state.filteredEvent}-${this.state.format}`}
                  showDownload={true}
                  columns={this.state.format === "group" ? formatCols : individualCols}
                  rows={this.state.events}
                />
              </div>
              <div className="col-6">
                {this.state.selectedGraphEvents.length === 0 ? (
                  <Card title="Graphs">
                    <strong>Please select some events from the filters to show on the graphs.</strong>
                  </Card>
                )
                  : (
                    <>
                      <Card title="Over Time">
                        <LineGraph loading={this.state.loading} data={this.state.lineData} minHeight={500} hideLegend={this.state.selectedGraphEvents.length > 10} />
                      </Card>
                      <Card title="Ratios">
                        <PieChart loading={this.state.loading} data={this.state.pcData} hideLegend={this.state.selectedGraphEvents.length > 10} minHeight={500} />
                      </Card>
                    </>
                  )
                }
              </div>
            </div>
          )}
        </div>

        <Modal
          show={this.state.showMoreModal}
          onHide={this.toggleShowMoreModal}
          dialogClassName="modal-third"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Viewing {this.state.selectedEvent.event}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row analytic-more-info-row">
              <div className="col-4">
                Type:
              </div>
              <div className="col-8">
                {this.state.selectedEvent.event}
              </div>
            </div>

            <div className="row analytic-more-info-row">
              <div className="col-4">
                Posted:
              </div>
              <div className="col-8">
                {this.state.selectedEvent.posted}
              </div>
            </div>

            <div className="row analytic-more-info-row">
              <div className="col-4">
                Additional Params:
              </div>
              <div className="col-8">
                <pre>{JSON.stringify(this.state.selectedEvent.params, null, 2)}</pre>
              </div>
            </div>

          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-primary btn-block" onClick={this.toggleShowMoreModal}>Done</button>
          </Modal.Footer>
        </Modal>
      </Screen>
    );
  }


  updateField(e: any) {
    let ns: any = this.state;
    const id = e.target.id;
    const val = e.target.value;
    ns[id] = val;
    if (id === "format") {
      ns.formatChanged = true;
    }
    this.setState(ns);
  }

  updateStart(newDay: moment.Moment) {
    this.setState({ start: newDay })
  }

  updateEnd(newDay: moment.Moment) {
    this.setState({ end: newDay })
  }

  selectEvent(event: any) {
    this.setState({ selectedEvent: event, showMoreModal: true });
  }

  toggleShowMoreModal() {
    this.setState({ showMoreModal: !this.state.showMoreModal });
  }

  private selectGraphEvent(selectedGraphEvents: any) {
    if (!selectedGraphEvents) {
      return this.setState({ selectedGraphEvents: [] }, () => this.filterGraphs());
    }
    this.setState({ selectedGraphEvents }, () => this.filterGraphs());
  }

  private setup() {
    this.setState({ loading: true }, async () => {
      try {
        const knownEventsResult = await AnalyticsAPI.getAnalyticKnownEvents(app);
        const knownEvents: any = [];
        const knownEventsForGraph: any = [];
        for (const e of knownEventsResult.body.data) {
          knownEvents.push(e);
          knownEventsForGraph.push({ label: e.event, value: e.event });
        }
        this.setState({ knownEvents, knownEventsForGraph }, () => {
          this.getEvents();
        });
      } catch (err) {
        error("You do not have permission to see these events at this time.");
        this.setState({ loading: false });
      }
    })
  }

  private getEvents() {
    this.setState({ loading: true }, async () => {
      try {
        let events: any[] = [];
        let csvEvents: any[] = [];

        const params: any = {
          start: this.state.start.format("YYYY-MM-DDT") + "00:00:00Z",
          end: this.state.end.format("YYYY-MM-DDT") + "23:59:59Z",
          count: 1000,
          offset: 0,
        }
        const count = parseInt(this.state.count);
        if (!isNaN(count)) {
          params.count = count;
        }
        const offset = parseInt(this.state.offset);
        if (!isNaN(offset)) {
          params.offset = offset;
        }

        // this grew out of control and could probably do with a refactor
        // we now need to look at not only the target but also the grouping settings
        // oh, and also filter in the graphs like we did for the breed reports
        if (this.state.format === "group") {
          params.format = "group";
          params.unit = this.state.unit;
        }

        if (this.props.target === "all") {
          if (this.state.filteredEvent === "all") {
            const result = await AnalyticsAPI.getAnalyticEvents(app, params);
            events = result.body.data;
          } else {
            const result = await AnalyticsAPI.getAnalyticEventsForEvent(app, this.state.filteredEvent, params);
            events = result.body.data;
          }
        } else {
          if (this.props.target === "device") {
            const result = await AnalyticsAPI.getAnalyticEventsForDevice(app, this.props.targetId, params);
            events = result.body.data;
          } else if (this.props.target === "pet") {
            const result = await AnalyticsAPI.getAnalyticEventsForPet(app, this.props.targetId, params);
            events = result.body.data;
          } else if (this.props.target === "user") {
            const result = await AnalyticsAPI.getAnalyticEventsForUser(app, this.props.targetId, params);
            events = result.body.data;
          }
          // since these don't take a known event, we filter client side
          if (this.state.filteredEvent !== "all") {
            const filtered: any[] = [];
            for (const e of events) {
              if (e.event === this.state.filteredEvent) {
                filtered.push(e);
              }
            }
            events = filtered;
          }
        }
        // now, the events could be in a weird shape due to the grouping or individual selection
        // so we will massage them here
        // we need to know the unique events so we can set up some basic filters
        // TODO: implement
        const allEvents: any = {};
        const selectedGraphEvents: any[] = [];
        for (const e of events) {
          csvEvents.push(e);
          if (this.state.format === "group") {
            let p: string = "";
            if (this.state.unit === "day") {
              p = moment(e.timePeriod, "YYYY-MM-DD").format("YYYY-MM-DD");
            } else {
              p = moment(e.timePeriod, "YYYY-MM-DD HH").format("YYYY-MM-DD HH") + ":00";
            }
            e.timePeriod = p;
            e.event = e.value;
          } else {
            e.view = (<button className="btn-link-bare" onClick={() => this.selectEvent(e)}><FontAwesomeIcon icon={faEye} /></button>)
            e.userLink = e.userId && e.userId > 0 ? (<a href={`/support/users/${e.userId}`} target="_new">{e.userId}</a>) : (<span>-</span>);
            e.petLink = e.petId && e.petId > 0 ? (<a href={`/support/pets/${e.petId}`} target="_new">{e.petId}</a>) : (<span>-</span>);
            e.deviceLink = e.deviceId && e.deviceId > 0 ? (<a href={`/support/devices/${e.deviceId}`} target="_new">{e.deviceId}</a>) : (<span>-</span>);
            e.timePeriod = moment(e.posted).local().format("MM/DD/YY HH:00");
            e.posted = moment(e.posted).local().format("MM/DD/YY HH:mm");
          }
          if (!allEvents[e.event]) {
            allEvents[e.event] = 0;
          }
          allEvents[e.event]++;
        }

        let allEventsArray: any[] = [];
        for (const event of Object.keys(allEvents)) {
          allEventsArray.push({ event, count: allEvents[event] });
        }
        allEventsArray.sort((a, b) => {
          return a.count > b.count ? -1 : 1;
        });
        for (let i = 0; i < allEventsArray.length; i++) {
          if (i > 5) {
            break;
          }
          selectedGraphEvents.push({ value: allEventsArray[i].event, label: allEventsArray[i].event })
        }

        this.setState({ events, csvEvents, formatChanged: false, selectedGraphEvents }, () => this.filterGraphs());
      } catch (err) {
        this.setState({ loading: false });
      }
    })
  }

  private filterGraphs() {
    // now we need to format them
    // again, we now need to consider the format
    this.setState({ loading: true }, () => {

      // filter for the graphs
      const filteredGraphEvents: any[] = [];
      for (const e of this.state.events) {
        for (const f of this.state.selectedGraphEvents) {
          // note the comparison here; since we moved this to after the processing, the value
          // of the event could be a different shape
          if (f.value === e.event) {
            filteredGraphEvents.push(e);
            continue;
          }
        }
      }

      const pcData: any[] = [];
      const lineData: any[] = [];
      let pcDataHolder: any = {};
      let lineDataHolder: any = {};

      if (this.state.format === "group") {
        // this one is easier
        for (const e of filteredGraphEvents) {
          if (!pcDataHolder[e.event]) {
            pcDataHolder[e.event] = 0;
          }
          pcDataHolder[e.event] += e.count;
          if (!lineDataHolder[e.timePeriod]) {
            lineDataHolder[e.timePeriod] = {};
          }
          if (!lineDataHolder[e.timePeriod][e.event]) {
            lineDataHolder[e.timePeriod][e.event] = 0;
          }
          lineDataHolder[e.timePeriod][e.event] += e.count;
        }

        for (const key of Object.keys(pcDataHolder)) {
          pcData.push({ key, count: pcDataHolder[key] });
        }
        for (const day of Object.keys(lineDataHolder)) {
          lineData.push({ key: day, ...lineDataHolder[day] });
        }

      } else {
        for (const e of filteredGraphEvents) {

          if (!pcDataHolder[e.event]) {
            pcDataHolder[e.event] = 0;
          }
          if (!lineDataHolder[e.timePeriod]) {
            lineDataHolder[e.timePeriod] = {};
          }
          if (!lineDataHolder[e.timePeriod][e.event]) {
            lineDataHolder[e.timePeriod][e.event] = 0;
          }
          pcDataHolder[e.event] += 1;
          lineDataHolder[e.timePeriod][e.event] += 1;
        }

        // IF there's only one unique key in the day, we will break it down by hour
        if (Object.keys(lineDataHolder).length <= 2) {
          // let's re-loop for the hours
          lineDataHolder = {};
          for (const e of filteredGraphEvents) {
            const p = moment(e.posted, "MM/DD/YY HH:mm").format("MM-DD hh:00 A");
            if (!lineDataHolder[p]) {
              lineDataHolder[p] = {};
            }
            if (!lineDataHolder[p][e.event]) {
              lineDataHolder[p][e.event] = 0;
            }
            lineDataHolder[p][e.event] += 1;
          }
        }

        for (const key of Object.keys(pcDataHolder)) {
          pcData.push({ key, count: pcDataHolder[key] });
        }
        for (const day of Object.keys(lineDataHolder)) {
          lineData.push({ key: day, ...lineDataHolder[day] });
        }
      }
      this.setState({ loading: false, pcData, lineData });
    })
  }

}