import { makeCall } from "./client";

export interface ISystemError {
  details?: string;
  message: string;
  service: string;
  systemCode: string;
  status: number;
}

export class ErrorsAPI {  

  /**
   * Gets a list of system error codes for a microservice
   * @param selectedMicroService 
   * @param options 
   * @returns 
   */
  public getErrorCodes = (selectedMicroService: string, options: any = {}) => {
    const data = {
      ...options
    };
    return makeCall('GET', `/admin/errors/${selectedMicroService}`, data);
  }
}
