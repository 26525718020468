import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Table, Screen, Card } from "src/components/structure";

import { Modal } from "react-bootstrap";
import * as UserActions from "src/reducers/userReducer";
import { JobsAPI, MetricsAPI } from "src/api";
import moment from "moment";
import { error, success } from "src/components/structure/Alert";

interface IBreedActivityReportProps {
  location: any;
  userActions: any;
  userState: any;
}

interface IBreedActivityReportState {
  loading: boolean;
  month: string;
  showResetWarningModal: boolean;
  entries: any[];
  months: string[];
}

const cols = [
  {
    label: "ID",
    field: "breedId",
  },
  {
    label: "Name",
    field: "breedName",
  },
  {
    label: "Count",
    field: "countOfPetsInBreed",
  },
  {
    label: "Total Age",
    field: "totalAgeInYears",
  },
  {
    label: "Avg Age",
    field: "averageAgeInYears",
  },
  {
    label: "Total Weight",
    field: "totalWeight",
  },
  {
    label: "Avg Weight",
    field: "averageWeight",
  },
  {
    label: "Total Steps",
    field: "totalSteps",
  },
  {
    label: "Avg Steps",
    field: "averageSteps",
  },
  {
    label: "Total Fit",
    field: "totalFitUnits",
  },
  {
    label: "Avg Fit",
    field: "averageFitUnits",
  },
  {
    label: "Total Miles",
    field: "totalMilesWalked",
  },
  {
    label: "Avg Miles",
    field: "averageMilesWalked",
  },
  {
    label: "Total Companion",
    field: "totalCompanionTime",
  },
  {
    label: "Avg Companion",
    field: "averageCompanionTime",
  },
  {
    label: "Total Sleep",
    field: "totalSleepMinutes",
  },
  {
    label: "Avg Sleep",
    field: "averageSleepMinutes",
  },
  {
    label: "Total Excercise",
    field: "totalExerciseMinutes",
  },
  {
    label: "Avg Exercise",
    field: "averageExerciseMinutes",
  },
  {
    label: "Total HHS",
    field: "totalHHS",
  },
  {
    label: "AVG HHS",
    field: "averageHHS",
  },
]

const app = "wagz2";

class BreedActivityReport extends Component<
  IBreedActivityReportProps,
  IBreedActivityReportState
> {
  constructor(props: IBreedActivityReportProps) {
    super(props);

    this.state = {
      loading: false,
      showResetWarningModal: false,
      entries: [],
      month: moment().format("YYYY-MM"),
      months: [],
    };

    this.updateTableMonth = this.updateTableMonth.bind(this);
    this.fetch = this.fetch.bind(this);
    this.toggleShowDeleteWarningModal = this.toggleShowDeleteWarningModal.bind(this);
    this.resetMonth = this.resetMonth.bind(this);
  }

  componentDidMount() {
    const months = this.getMonths()
    this.setState({months}, () => {
      this.fetch();
    });
  }


  render() {
    return (
      <Screen shouldAuthCheck={true} requiredRoles={["reporting"]} loading={this.state.loading}>
        <Card title="Filters">
          <div className="row">
            <div className="col-6">
              <select id="month" className="form-control" value={this.state.month} onChange={this.updateTableMonth}>
                {this.state.months.map((month) => {
                  return <option value={month} key={month}>{month}</option>
                })}
              </select>
            </div>
            <div className="col-6">
              <button className="btn btn-block btn-danger" onClick={this.toggleShowDeleteWarningModal}>Reset for this month</button>
            </div>
          </div>
        </Card>
        <div>
          <Table
            title="Breed Activity"
            csvFileName={`breed-activity-${this.state.month}`}
            showDownload={true}
            columns={cols}
            rows={this.state.entries}
          />
        </div>

          <Modal
            show={this.state.showResetWarningModal}
            onHide={this.toggleShowDeleteWarningModal}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                Are You Really Sure?
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p><strong>Warning:</strong> This is a painful operation. It does a lot of stuff in line with the HTTP call and could be a major hit on performance.</p>
              <p>You should probably check with Kevin or Andy before running this. It could harm performance for a lot of people.</p>
            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-danger btn-block" onClick={this.resetMonth}>Yes, I Checked and Want To Do This</button>
            </Modal.Footer>
          </Modal>
      </Screen>
    );
  }

  updateTableMonth(e: any) {
    let ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns, () => this.fetch());
  }


  private toggleShowDeleteWarningModal(){
    this.setState({ showResetWarningModal: !this.state.showResetWarningModal})
  }

  private fetch(){
    this.setState({ loading: true }, async () => {
      try{
        const result = await MetricsAPI.getBreedActivityReports("wagz2", this.state.month)
        this.setState({ loading: false, entries: result.body.data});
      }catch(err){
        this.setState({loading: false});
        error("Could not fetch that report. Perhaps try resetting?");
      }
    })
  }

  private getMonths() : string[]{
    const earliest = moment().year(2021).month(11).day(1);
    const current = moment();
    const months = [];
    while(current.isAfter(earliest)){
      months.push(moment(current).format("YYYY-MM"))
      current.subtract(1, "month");
    }
    return months;
  }

  private resetMonth(){
    this.setState({ loading: false }, async () => {
      try{
        // const result = await MetricsAPI.resetBreedActivityReports("wagz2", this.state.month);
        const data = {
          targetService: "metrics-service",
          name: "breed_activity_reset"
        }
        await JobsAPI.createJob(app, data);
        success("Sent the request to update the report. This may take a view minutes.");
        this.setState({ loading: false, showResetWarningModal: false, })
      }catch(err){
        this.setState({loading: false, showResetWarningModal: false});
        error("Could not reset. You should definitely contact the backend team.");
      }
    });
  }
}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BreedActivityReport) as any
);
