import * as React from "react";
import { LineChart, XAxis, YAxis, ResponsiveContainer, Tooltip, Legend, CartesianGrid, Line } from "recharts";
import { getGraphColorByIndex } from "./Colors";

interface ILineGraphProps {
  data: any[]; // should have a key, and then all the points for that key in label:count format
  hideLegend?: boolean;
  loading: boolean;
  hideAverage?: boolean;
  hideTotal?: boolean;
  hideUniqueKeys?: boolean;
  minHeight?: number;
  height?: number;
}

interface ILineGraphState {
  loading: boolean;
  total: number;
  average: number;
  uniqueKeys: string[];
}

export class LineGraph extends React.Component<ILineGraphProps, ILineGraphState> {

  constructor(props: ILineGraphProps){
    super(props);

    this.state = {
      total: 0,
      loading: true,
      average: 0,
      uniqueKeys: [],
    };
    this.getLines = this.getLines.bind(this);
  }

  componentDidMount(){
    // massage the data
    // and calc the total and average
    let total = 0;
    let average = 0.0;
    let countNonZero = 0;
    const keyMap = {};
    const keys: string[] = [];
    for(const d of this.props.data){
      const keys = Object.keys(d);
      for(const k of keys){
        if(k !== "key") {
          keyMap[k] = true;
          total += d[k];
          if(d[k] > 0){
            countNonZero++;
          }
        }
      }
    }
    for(const k in keyMap){
      keys.push(k);
    }
    if(countNonZero > 0){
      average = total / countNonZero;
    }
    this.setState({ loading: false, uniqueKeys: keys, total, average})

  }

  public render() {
    if(this.props.loading || this.state.loading){
      return (
        <div style={{textAlign: "center"}}>
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )
    }
    return (
      <div>
        <div className="row">
          <div className="col-12">
            <ResponsiveContainer width="100%" height="100%" minHeight={this.props.minHeight ? this.props.minHeight : 300}>
              <LineChart data={this.props.data} margin={{ top: 10, right: 10, left: 0, bottom: 0 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="key" />
                <YAxis />
                <Tooltip />
                {!this.props.hideLegend && this.state.uniqueKeys.length < 10 && (<Legend />)}
                {this.getLines()}
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>

        <div className="row" style={{marginTop: 10, marginBottom: 5}}>
          <div className="col-4">
            {!this.props.hideAverage && (
              <span><strong>Average: </strong> {this.state.average.toFixed(2)}</span>
            )}
          </div>
          <div className="col-4">
            {!this.props.hideTotal && (
              <span><strong>Total: </strong> {this.state.total.toFixed(2)}</span>
            )}
          </div>
          <div className="col-4">
            {!this.props.hideUniqueKeys && (
              <span><strong>Unique Keys: </strong> {this.state.uniqueKeys.length}</span>
            )}
          </div>
        </div>
      </div>
    );
  }

  private getLines(){
    const lineArray: any[] = [];
    for(let i = 0; i < this.state.uniqueKeys.length; i++){
      const key = this.state.uniqueKeys[i]
      lineArray.push(<Line type="monotone" dataKey={key} key={key} stroke={getGraphColorByIndex(i)} />);
    }
    return lineArray;
  }

}