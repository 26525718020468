import React, { Component } from "react";
import Card from "src/components/structure/Card";

interface IAllUsersDisplayProps {
  data: any;
  loading: boolean;
}

interface IAllUsersDisplayState {
  selectedApp: string;
  selectedAppStats: any;
}

const blankStats = {
  "active": 0,
  "pending": 0,
  "inactive": 0,
  "isMissing": true,
}

export class AllUsersDisplay extends Component<IAllUsersDisplayProps, IAllUsersDisplayState> {
  constructor(props: IAllUsersDisplayProps) {
    super(props);

    this.state = {
      selectedApp: "",
      selectedAppStats: blankStats,
    };

    this.handleUpdate = this.handleUpdate.bind(this);
    this.filterStats = this.filterStats.bind(this);
  }

  handleUpdate(e: any){
    const id = e.target.id;
    const val = e.target.value;
    const ns = this.state;
    ns[id] = val;
    this.setState(ns, () => { this.filterStats(); });
  }

  render() {
    return (
      <Card loading={this.props.loading} title="User Statistics">
        <div className="row">
          <div className="col-12">
            <select id="selectedApp" value={this.state.selectedApp} onChange={this.handleUpdate} className="form-control">
              <option value="">Please Select an App</option>
              <option value="wagz2">Wagz</option>
              <option value="chd">Collar 24 Distributor</option>
              <option value="bd">BLACK & DECKER</option>
            </select>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {this.state.selectedAppStats.isMissing ? (<strong>No data available</strong>): 
            (
              <div>
              <div className="row">
                <div className="col-12">
                  Active: {this.state.selectedAppStats.active}
                </div>
              </div>
                <div className="row">
                  <div className="col-12">
                    Pending: {this.state.selectedAppStats.pending}
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    Inactive: {this.state.selectedAppStats.inactive}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </Card>
    );
  }


  private filterStats(){
    // search for the stats
    for(const app in this.props.data){
      if(app === this.state.selectedApp){
        const stats = this.props.data[app];
        stats.isMissing = false;
        return this.setState({ selectedAppStats: stats });
      }
    }
    this.setState({ selectedAppStats: blankStats});
  }
}