import React, { Component } from "react";
import Card from "src/components/structure/Card";

interface IAllDevicesDisplayProps {
  data: any;
  loading: boolean;
}

interface IAllDevicesDisplayState {
  selectedProductFamilyId: string;
  selectedProductFamilyStats: any;
}

const blankStats = {
  "active": 0,
  "pending": 0,
  "deactivated": 0,
  "cancel": 0,
  "isMissing": true,
}

export class AllDevicesDisplay extends Component<IAllDevicesDisplayProps, IAllDevicesDisplayState> {
  constructor(props: IAllDevicesDisplayProps) {
    super(props);

    this.state = {
      selectedProductFamilyId: "",
      selectedProductFamilyStats: blankStats,
    };

    this.handleUpdate = this.handleUpdate.bind(this);
    this.filterStats = this.filterStats.bind(this);
  }

  componentDidMount(){
    
  }

  handleUpdate(e: any){
    const id = e.target.id;
    const val = e.target.value;
    const ns = this.state;
    ns[id] = val;
    this.setState(ns, () => { this.filterStats(); });
  }

  render() {
    return (
      <Card loading={this.props.loading} title="Device Statistics">
        <div className="row">
          <div className="col-12">
            <select id="selectedProductFamilyId" value={this.state.selectedProductFamilyId} onChange={this.handleUpdate} className="form-control">
              <option value="">Please Select a Product</option>
              <option value="23">Freedom Collar</option>
              <option value="24">Cat Collar</option>
              <option value="14">SERVE Smart Feeder</option>
              <option value="13">GO Smart Door</option>
              <option value="17">Pendant</option>
              <option value="18">ROAM Smart Shield</option>
              <option value="19">EXPLORE Smart Collar</option>
              <option value="1">BLACK & DECKER Collar</option>
            </select>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {this.state.selectedProductFamilyStats.isMissing ? (<strong>No data available</strong>): 
            (
              <div>
              <div className="row">
                <div className="col-12">
                  Active: {this.state.selectedProductFamilyStats.active}
                </div>
              </div>
                <div className="row">
                  <div className="col-12">
                    Pending: {this.state.selectedProductFamilyStats.pending}
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    Deactivated: {this.state.selectedProductFamilyStats.deactivated}
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    Cancel: {this.state.selectedProductFamilyStats.cancel}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </Card>
    );
  }


  private filterStats(){
    // search for the stats
    for(const productFamilyId in this.props.data){
      if(productFamilyId === this.state.selectedProductFamilyId){
        const stats = this.props.data[productFamilyId];
        stats.isMissing = false;
        return this.setState({ selectedProductFamilyStats: stats });
      }
    }

    this.setState({ selectedProductFamilyStats: blankStats});
  }
}