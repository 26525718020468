import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as AppActions from "src/reducers/appReducer";
import { ErrorsAPI } from "src/api";
import { ISystemError } from "src/api/errors";
import { error } from "src/components/structure/Alert";
import { Card, Loading, Screen, Table } from "src/components/structure";

interface IErrorCodesListScreenProps {
  appActions: any;
}

interface IErrorCodesListScreenState {
  loading: boolean;
  selectedMicroservice: string;
  errorCodes: ISystemError[];
}

const columns = [
  {
    label: "Service",
    field: "service",
  },
  {
    label: "Code",
    field: "systemCode",
  },
  {
    label: "HTTP Status",
    field: "status",
  },
  {
    label: "Details",
    field: "details",
  },
];

const services: string[] = [
  "apps",
  "pets",
  "users"
];
const servicesOptions = (
  <>
  <option value="none">None</option>
    <option value="all">All</option>
    {services.map((s) => {
      return (<option key={s} value={s}>{s[0].toUpperCase() + s.substring(1)}</option>)
    })}
  </>
);

class Anomalies extends React.Component<IErrorCodesListScreenProps, IErrorCodesListScreenState> {

  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      selectedMicroservice: "none",
      errorCodes: [],
    };

    this.fetch = this.fetch.bind(this);
    this.updateSelectedMicroService = this.updateSelectedMicroService.bind(this);
  }

  componentDidMount() {
    this.fetch();
  }

  public render() {
    return (
      <Screen fileName="ErrorCodesListScreen.tsx">
        <div className="row" style={{ marginBottom: 20 }}>
          <div className="col-12">
            <Card>
              <div className="row">
                <div className="col-6">
                  <p>This tool will show the potential error codes from the selected microservice. Please note that this is a work in progress. Most errors do not have system error codes yet. If you select "Show All", you will see a rather large list, so use caution.</p>  
                </div>
                <div className="col-4 offset-2">
                  <label htmlFor="selectedMicroservice">Show Service</label>
                  <select id="selectedMicroservice" className="form-control" value={this.state.selectedMicroservice} onChange={this.updateSelectedMicroService}>
                    <option value="all">Show All</option>
                      {servicesOptions}
                  </select>
                </div>
              </div>
            </Card>
          </div>
        </div>

        {this.state.loading && (
          <Loading />
        )}

        {this.state.selectedMicroservice !== "none" && !this.state.loading && (
          <div className="row" style={{ marginBottom: 20 }}>
            <div className="col-12">
              <Table
                title="Error Codes"
                csvFileName={`error-codes-${this.state.selectedMicroservice}`}
                showDownload={true}
                columns={columns}
                rows={this.state.errorCodes}
              />
            </div>
          </div>
        )}

      </Screen>
    );
  }

  private updateSelectedMicroService(e: any){
    const ns = this.state;
    const id = e.target.id;
    const service = e.target.value;

    ns[id] = service;
    this.setState(ns, () => this.fetch());
  }

  private fetch() {
    if(this.state.selectedMicroservice === "none"){
      return this.setState({ errorCodes: [], loading: false });
    }

    this.setState({ loading: true }, async () => {
      // if it's not all, then get the codes just for that single microservice
      if(this.state.selectedMicroservice !== "all"){
        try {
          const ret = await ErrorsAPI.getErrorCodes(this.state.selectedMicroservice);
          const errorCodes = this.processErrors(this.state.selectedMicroservice, ret.body.data);          
          this.setState({ errorCodes, loading: false })
        } catch (err) {
          error("Could not fetch error codes for that criteria");
          this.setState({ loading: false });
        }
      } else {
        // we need to fetch for all microservices that are currently implemented
        const errorCodes: ISystemError[] = [];
        for(const service of services){
          try{
            const ret = await ErrorsAPI.getErrorCodes(service);
            const errs = this.processErrors(service, ret.body.data); 
            errorCodes.push(...errs); 
          }catch(err){} 
        }
        this.setState({ errorCodes, loading: false });
      }
    })
  }

  private processErrors(service: string, errors: ISystemError[]): ISystemError[] {
    const errorCodes: ISystemError[] = [];
    for(const e of errors){
      e.service = service;
      // for some weird reason, if there is a message field, the table borks
      e.details = e.message;
      e.message = "";
      errorCodes.push(e);
    }
    return errorCodes;
  }
}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Anomalies) as any);