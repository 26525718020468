import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as UserActions from "src/reducers/userReducer";
import Table from "src/components/structure/Table";
import { Modal } from "react-bootstrap";

import Screen from "src/components/structure/Screen";
import {LoadingButton} from "src/components/structure";
import { error, success } from "src/components/structure/Alert";
import { DeviceAPI } from "src/api";
import { IDevice } from "src/api/devices";

interface ILogIntervalManagementProps {
  location: any;
  userActions: any;
  userState: any;
}

interface ILogIntervalManagementState {
  devicesLoading: boolean;
  updateLoading: boolean;
  devices: IDevice[];
  selectedDevice: any;
  showUpdateModal: boolean;
  newLogInterval: number;
}

const columns = [
  {
    label: "ID",
    field: "id",
    sort: "asc",
    width: 100,
  },
  {
    label: "Device Type",
    field: "deviceType",
    sort: "asc",
    width: 100,
  },
  {
    label: "Serial Number",
    field: "productId",
    sort: "asc",
    width: 200,
  },
  {
    label: "Status",
    field: "status",
    sort: "asc",
    width: 150,
  },
  {
    label: "Firmware Version",
    field: "firmwareVersion",
    sort: "asc",
    width: 120,
  },
  {
    label: "Last Heartbeat",
    field: "heartbeat",
    sort: "asc",
    width: 120,
  },
  {
    label: "Log Interval",
    field: "logInterval",
    sort: "asc",
    width: 120,
  },
  {
    label: "Actions",
    field: "actions",
    sort: "asc",
    width: 100,
  },
];

class LogIntervalManagement extends Component<
  ILogIntervalManagementProps,
  ILogIntervalManagementState
> {
  constructor(props: ILogIntervalManagementProps) {
    super(props);

    this.state = {
      devicesLoading: false,
      devices: [],

      showUpdateModal: false,
      selectedDevice: { id: 0, productId: "" },
      newLogInterval: 0,
      updateLoading: false,
    };

    this.updateField = this.updateField.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.fetchDevices = this.fetchDevices.bind(this);
    this.updateLogInterval = this.updateLogInterval.bind(this);
  }

  componentDidMount() {
    this.fetchDevices();
  }

  updateField(e: any) {
    let ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  render() {
    return (
      <Screen id="Administration-LogIntervalManagement">
        <div className="row">
          <div className="col-10 offset-1">
            <Table
              title="Log Intervals"
              csvFileName="logIntervals"
              showDownload={true}
              columns={columns}
              rows={this.state.devices}
            />
          </div>
        </div>
        <Modal show={this.state.showUpdateModal} onHide={this.hideModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              Update Log Interval For {this.state.selectedDevice.productId}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              This will update the log interval for this device. It will attempt
              to also send an action to update the device's settings to update
              the changed setting. If the device is offline or otherwise doesn't
              respond to the action, the log interval will not take effect.
            </p>
            <div className="form-group">
              <label>New Logging Interval</label>
              <select
                id="newLogInterval"
                className="form-control"
                value={this.state.newLogInterval}
                onChange={this.updateField}
              >
              <option value="0">Turn Off Logging</option>
                <option value="60">Every 1 Minute</option>
                <option value="300">Every 5 Minutes</option>
                <option value="600">Every 10 Minutes</option>
                <option value="1800">Every 30 Minutes</option>
                <option value="3600">Every 1 Hour</option>
                <option value="43200">Every 12 Hours</option>
              </select>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <LoadingButton
              loading={this.state.updateLoading}
              className="btn btn-primary btn-block"
              loadingStyles={{ textAlign: "center", width: "100%" }}
              onClick={this.updateLogInterval}
            >
              Update Log Interval
            </LoadingButton>
          </Modal.Footer>
        </Modal>
      </Screen>
    );
  }

  private fetchDevices() {
    this.setState({ devicesLoading: true }, async () => {
      try {
        const result = await DeviceAPI.getAllDevices("wagz2", {
          count: 999999999,
        });
        const devices: any = [];
        for (const d of result.body.data) {
          const logInterval = parseInt(d.logInterval, 10) / 60 + " minute(s)";
          devices.push({
            ...d,
            logInterval,
            actions: (
              <button
                className="btn btn-pri"
                onClick={() => {
                  this.setState({
                    selectedDevice: d,
                    showUpdateModal: true,
                    newLogInterval: d.logInterval,
                  });
                }}
              >
                Update
              </button>
            ),
          });
        }
        this.setState({ devicesLoading: false, devices });
      } catch (err) {
        this.setState({ devicesLoading: false });
      }
    });
  }

  private hideModal() {
    this.setState({
      selectedDevice: { id: 0, productId: "" },
      showUpdateModal: false,
      newLogInterval: 0,
    });
  }

  private updateLogInterval() {
    const logInterval = parseInt(this.state.newLogInterval + "", 10);
    this.setState({ updateLoading: true }, async () => {
      try {
        await DeviceAPI.updateDeviceLogInterval(
          "wagz2",
          this.state.selectedDevice.registeredBy,
          this.state.selectedDevice.id,
          logInterval
        );
        success("Log interval update and action queued!");
        // loop and update so that hopefully we don't need to refetch all devices on the platform
        const devices: any = [];
        for (const d of this.state.devices) {
          if (d.id === this.state.selectedDevice.id) {
            // the quotes are needed; even tho it is typed as a number,
            // the HTML select value puts it in as a string
            d.logInterval = logInterval / 60 + " minute(s)";
          }
          devices.push(d);
        }
        this.setState({ devices, updateLoading: false }, () =>
          this.hideModal()
        );
      } catch (err) {
        error("Could not save that log interval. Please contact engineering");
        this.setState({ updateLoading: false });
      }
    });
  }
}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LogIntervalManagement) as any
);
