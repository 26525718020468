import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Table from "src/components/structure/Table";
import Select from "react-select";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan } from "@fortawesome/free-solid-svg-icons";

import * as UserActions from "src/reducers/userReducer";

import { BillingAPI } from "src/api";
import { Alert, MarkdownEditor } from "src/components/structure";
import Screen from "src/components/structure/Screen";
import Card from "src/components/structure/Card";
import { justificationList } from "src/utils/items";

interface IFreeUsersProps {
  userActions: any;
  userState: any;
}

interface IFreeUsersState {
  loading: boolean;
  freeUsers: any[];
  cols: any[];
  newFreeUserEmail: string;
  newFreeUserName: string;
  newFreeUserJustification: string;
  newFreeUserNotes: string;
  showFreeUserDeleteModal: boolean;
  deleteFreeUserEmail: string;
  showModalForNotes: boolean;
  viewingNotes: string;
}

class UserPage extends Component<IFreeUsersProps, IFreeUsersState> {
  constructor(props: IFreeUsersProps) {
    super(props);

    this.state = {
      loading: false,
      freeUsers: [],
      cols: [
        {
          label: "Email",
          field: "email",
          sort: "asc",
        },
        {
          label: "Name",
          field: "name",
          sort: "asc",
        },
        {
          label: "Date Added",
          field: "dateAdded",
          sort: "asc",
        },
        {
          label: "Justification",
          field: "justification",
          sort: "asc",
        },
        {
          label: "Notes",
          field: "notes",
          sort: "asc",
        },
        {
          label: "Actions",
          field: "actions",
          sort: "asc",
        },
      ],
      newFreeUserEmail: "",
      newFreeUserName: "",
      newFreeUserJustification: "",
      newFreeUserNotes: "",
      showFreeUserDeleteModal: false,
      deleteFreeUserEmail: "",
      showModalForNotes: false,
      viewingNotes: ""
    };

    this.updateField = this.updateField.bind(this);
    this.updateNotes = this.updateNotes.bind(this);
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.addFreeUser = this.addFreeUser.bind(this);
    this.deleteFreeUser = this.deleteFreeUser.bind(this);
    this.toggleNotesModal = this.toggleNotesModal.bind(this);
  }

  updateField(e: any) {
    let ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  componentDidMount() {
    this.getFreeUsers();
  }

  getFreeUsers() {
    this.setState({ loading: true }, async () => {
      try {
        const res = await BillingAPI.getFreeUsers("wagz2");
        const freeUsers = this.processFreeUsers(res.body.data);
        this.setState({
          loading: false,
          freeUsers,
        });
      } catch (err) {
        this.setState({ loading: false });
      }
    });
  }

  private processFreeUsers(users: any[]): any[] {
    const freeUsers: any = [];
    for (const u of users) {
      let notes = u.notes
      u.email = u.email.toLowerCase();
      u.notes = (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <button
            className="btn btn-sm btn-pri"
            onClick={() => this.toggleNotesModal(notes)}
          >
            View
          </button>
        </div>
      );
      u.actions = (
        <div style={{ display: "flex", justifyContent: "center" }}>
          {this.props.userState.user.accessLevel !== "read_only" && (
            
            <div style={{ paddingLeft: 5 }}>
            <FontAwesomeIcon
              icon={faBan}
              onClick={() => this.toggleDeleteModal(u.email)}
              className="text-danger"
            />
          </div>
          )}
        </div>
      );
      freeUsers.push(u);
    }
    freeUsers.sort((a: any, b: any) => {
      return a.email > b.email ? 1 : -1;
    });
    return freeUsers;
  }

  render() {
    return (
      <Screen>
        <div className="row">
          <div className="col-8">
            <Table
              title="Free Users"
              csvFileName="freeUsers"
              showDownload={true}
              columns={this.state.cols}
              rows={this.state.freeUsers}
            />
          </div>
          <div className="col-4">
            {this.props.userState.user.accessLevel !== "read_only" && (
              <Card title="Add New Free User">
                <p>
                  You can add an email address to be set as a free user here. If
                  they are already in the table, nothing will change. Make sure
                  you know what you are doing and you have permission to give
                  away subscriptions!
                </p>
                <div className="form-group">
                  <label>Email</label>
                  <input
                    type="text"
                    className="form-control"
                    autoComplete="new-password"
                    id="newFreeUserEmail"
                    value={this.state.newFreeUserEmail}
                    onChange={this.updateField}
                  />
                </div>
                <div className="form-group">
                  <label>Name</label>
                  <input
                    type="text"
                    className="form-control"
                    autoComplete="new-password"
                    id="newFreeUserName"
                    value={this.state.newFreeUserName}
                    onChange={this.updateField}
                  />
                </div>
                <div className="form-group">
                  <label>Justification</label>
                  <Select
                    options={justificationList}
                    onChange={this.updateSelection}
                    hideSelectedOptions={true}
                  />
                </div>
                <div className="form-group">
                  <label>Notes</label>
                  <MarkdownEditor
                    content={this.state.newFreeUserNotes}
                    showToggle={true}
                    showHelp={true}
                    onChange={this.updateNotes}
                    mode="edit"
                    rows={5}
                  />
                </div>
                <div className="form-group">
                  <button
                    style={{ color: "white" }}
                    className="btn btn-send btn-block"
                    onClick={this.addFreeUser}
                  >
                    Add User
                  </button>
                </div>
              </Card>
            )}
          </div>
        </div>
        <Modal
          show={this.state.showFreeUserDeleteModal}
          onHide={this.toggleDeleteModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Remove {this.state.deleteFreeUserEmail}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <strong>Warning!</strong> You are about to remove{" "}
            {this.state.deleteFreeUserEmail} from the Free Users table. The user
            will no longer be able to activate collars without a subscription.
            Any existing free or paid subscriptions will remain in place.
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-danger" onClick={this.deleteFreeUser}>
              Yes, Remove {this.state.deleteFreeUserEmail}
            </button>
            <button
              className="btn btn-primary"
              onClick={() => this.toggleDeleteModal()}
            >
              Nevermind
            </button>
          </Modal.Footer>
        </Modal>
          <Modal
            dialogClassName="modal-75"
            show={this.state.showModalForNotes}
            onHide={() => {
              this.setState({ showModalForNotes: false });
            }}
          >
            <Modal.Header closeButton>
              <label style={{ marginLeft: 5, fontWeight: "bolder" }}>Notes:</label>
            </Modal.Header>
            <Modal.Body>
            <div className="form-group">
              <MarkdownEditor
                content={this.state.viewingNotes}
                showToggle={false}
                showHelp={false}
                mode="view"
                
                rows={5}
              />
            </div>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>
      </Screen>
    );
  }

  private toggleDeleteModal(email: string = "") {
    if (!email) {
      email = "";
    }
    this.setState({
      showFreeUserDeleteModal: !this.state.showFreeUserDeleteModal,
      deleteFreeUserEmail: email,
    });
  }

  private updateNotes(content: any) {
    this.setState({ newFreeUserNotes: content });
  }

  private updateSelection = (input: any) => {
    let ns = this.state;
    ns[input.field] = input.value;
    this.setState(ns);
  };

  toggleNotesModal(input: string) {
    console.log(input)
    this.setState({
      showModalForNotes: true,
      viewingNotes: input
    });
  }

  private addFreeUser() {
    // we want to add them, then update the table locally so we don't have to do a full fetch again
    const email = this.state.newFreeUserEmail.trim();
    if (email === "") {
      return Alert.error("Email cannot be blank!");
    }
    this.setState({ loading: true }, async () => {
      try {
        const data = {
          email: email,
          name: this.state.newFreeUserName,
          justification: this.state.newFreeUserJustification,
          notes: this.state.newFreeUserNotes,
        }
        await BillingAPI.saveFreeUser("wagz2", email, data);
        const users = this.state.freeUsers;
        users.push({ email });
        const freeUsers = this.processFreeUsers(users);
        this.setState({
          loading: false,
          freeUsers,
          newFreeUserEmail: "",
          newFreeUserName: "",
          newFreeUserJustification: "",
          newFreeUserNotes: "",
        });
        Alert.success("Free user added successfully!");
      } catch (err) {
        Alert.error("Could not save that free user");
        this.setState({ loading: false });
      }
    });
  }

  private deleteFreeUser() {
    this.setState(
      { showFreeUserDeleteModal: false, loading: true },
      async () => {
        try {
          await BillingAPI.deleteFreeUser(
            "wagz2",
            this.state.deleteFreeUserEmail
          );
          const users = this.state.freeUsers;
          const freeUsers: any = [];
          for (const u of users) {
            if (u.email !== this.state.deleteFreeUserEmail) {
              freeUsers.push(u);
            }
          }
          this.setState({ loading: false, deleteFreeUserEmail: "", freeUsers });
          Alert.success("That free user has been removed");
        } catch (err) {
          Alert.error("Could not remove that user.");
          this.setState({ loading: false });
        }
      }
    );
  }
}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserPage) as any
);
