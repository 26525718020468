import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as UserActions from "src/reducers/userReducer";
import { IPet } from "src/api/pets";
import Card from "src/components/structure/Card";
import moment from "moment";
import { error } from "src/components/structure/Alert";
import { MetricsAPI } from "src/api";
import { DatePicker } from "src/components/structure";

interface IRankingsTabProps {
  userId: number;
  location: any;
  userActions: any;
  userState: any;
  pet: IPet;
}

interface IRankingsTabState {
  loading: boolean;
  day: moment.Moment;
  breedId: string;
  breedGroupId: string;
  age: "all" | "adult" | "young";
  availableBreeds: any[];
  availableBreedGroups: any[];
  rankingInfo: any;
}

class RankingsTab extends Component<IRankingsTabProps, IRankingsTabState> {
  constructor(props: IRankingsTabProps) {
    super(props);

    this.state = {
      loading: false,
      day: moment().subtract(1, "day"),
      breedId: "0", 
      breedGroupId: "0",
      age: "all",
      availableBreedGroups: [],
      availableBreeds: [],
      rankingInfo: {},
    };

    this.updateField = this.updateField.bind(this);
    this.updateDay = this.updateDay.bind(this);
    this.setup = this.setup.bind(this);
    this.getRankings = this.getRankings.bind(this);
  }

  componentDidMount(){
    this.setup();
  }


  render() {
    return (
      <div>
        <div className="row">
          <div className="col-12">
            <Card title="Filters">
              <div className="row">
                <div className="col-2">
                  <label>Day</label>
                  <DatePicker date={this.state.day} onDateSaved={this.updateDay} />
                </div>
                <div className="col-2">
                  <label>Compare Breeds:</label>
                  <select className="form-control" id="breedId" value={this.state.breedId} onChange={this.updateField}>
                    <option value="0">All</option>
                    {this.state.availableBreeds.map((breed) => {
                      return <option key={breed.id} value={breed.id}>{breed.breed}</option>
                    })}
                  </select>
                </div>
                <div className="col-2">
                  <label>Compare Breed Groups:</label>
                  <select className="form-control" id="breedGroupId" value={this.state.breedGroupId} onChange={this.updateField}>
                    <option value="0">All</option>
                    {this.state.availableBreedGroups.map((group) => {
                      return <option key={group.id} value={group.id}>{group.groupName}</option>
                    })}
                  </select>
                </div>
                <div className="col-2">
                  <label>Compare Ages:</label>
                  <select className="form-control" id="age" value={this.state.age} onChange={this.updateField}>
                    <option value="all">All</option>
                    <option value="adult">Adult</option>
                    <option value="young">Young</option>
                  </select>
                </div>
                <div className="col-2">
                  <button className="btn btn-block btn-primary" onClick={this.getRankings}>Get Rankings</button>
                </div>
              </div>
            </Card>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Card title="Ranking Information">
                {!this.state.rankingInfo.id && this.state.rankingInfo.id !== 0 ? (
                  <strong>Nothing matches those parameters. Try again.</strong>
                ): (
                  <div>
                    <div className="row">
                      <div className="col-3">
                        <Card title="Exercise">
                          <p>{this.state.rankingInfo.exerciseMinutes} minutes ({this.state.rankingInfo.exerciseMinutesRanking}/{this.state.rankingInfo.exerciseMinutesTotalPets})</p>
                        </Card>
                      </div>
                      <div className="col-3">
                        <Card title="HHS">
                          <p>{this.state.rankingInfo.hhs}% ({this.state.rankingInfo.hhsRanking}/{this.state.rankingInfo.hhsTotalPets})</p>
                        </Card>
                      </div>
                      <div className="col-3">
                        <Card title="Sleep">
                          <p>{this.state.rankingInfo.sleepMinutes} minutes ({this.state.rankingInfo.sleepMinutesRanking}/{this.state.rankingInfo.sleepMinutesTotalPets})</p>
                        </Card>
                      </div>
                      <div className="col-3">
                        <Card title="Steps">
                          <p>{this.state.rankingInfo.steps} steps ({this.state.rankingInfo.stepsRanking}/{this.state.rankingInfo.stepsTotalPets})</p>
                        </Card>
                      </div>
                    </div>
                  </div>
                )}
            </Card>
          </div>
        </div>
      </div>
    );
  }

  updateField(e: any) {
    let ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  updateDay(newDate: moment.Moment){
    this.setState({ day: newDate});
  }

  private setup(){
    const breeds = this.props.pet.breeds? this.props.pet.breeds : [];
    const breedGroups = [];
    if(this.props.pet.breeds && this.props.pet.breeds.length > 0){
      for(const breed of this.props.pet.breeds){
        for(const bg of breed.breedGroups){
          breedGroups.push(bg);
        }
      }
    }
    this.setState({ availableBreedGroups: breedGroups, availableBreeds: breeds});
  }

  private getRankings(){
    this.setState({ loading: true }, async () => {
      try{
        const params = {
          day: this.state.day.format("YYYY-MM-DD"),
          breedId: this.state.breedId,
          breedGroupId: this.state.breedGroupId,
          age: this.state.age,
        }
        const result = await MetricsAPI.getRankingsForPet("wagz2", this.props.pet.userId, this.props.pet.id, this.state.day.format("YYYY-MM-DD"), params);
        this.setState({ loading: false, rankingInfo: result.body.data})
      }catch(err){
        error("No rankings are available for the combination of parameters.");
        this.setState({ loading: false });
      }
    })
  }
}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default connect<{}, {}, any>(
  mapStateToProps,
  mapDispatchToProps
)(RankingsTab);
