import React, { Component } from "react";
import {  withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Screen from "src/components/structure/Screen";
import * as UserActions from "src/reducers/userReducer";
import { BillingAPI } from "src/api";
import { IRMA, IRMAItem, BlankRMA } from "src/api/billing";
import { Card, DatePicker } from "src/components/structure";
import { RMAItemScreen } from "./RMAItem";
import { Modal } from "react-bootstrap";
import { RMAItemEditScreen } from "./RMAItemEdit";
import { error, success } from "src/components/structure/Alert";
import moment from "moment";
import { AdminUserSelect } from "src/components/structure/AdminUserSelect";

const app = "wagz2";

interface IRMAInfoProps {
  history: any;
  userActions: any;
  userState: any;
  match: any;
}

interface IRMAInfoState {
  rmaLoading: boolean;
  itemListLoading: boolean;
  codes: any[];
  categories: any[];
  productCodes: any[];
  rma: IRMA;
  rmaDirty: boolean;
  items: IRMAItem[];

  showCreateModal: boolean;
  showDeleteModal: boolean;
  expandedCount: number;
}

class RMAInfoScreen extends Component<IRMAInfoProps, IRMAInfoState> {
  constructor(props: IRMAInfoProps) {
    super(props);

    this.state = {
      rmaLoading: true,
      itemListLoading: true,
      codes: [],
      productCodes: [],
      categories: [],
      rma: BlankRMA,
      rmaDirty: false,
      items: [],

      showCreateModal: false,
      showDeleteModal: false,
      expandedCount: 0,
    };

    this.updateField = this.updateField.bind(this);
    this.updateCreatedBy = this.updateCreatedBy.bind(this);
    this.updateHandledBy = this.updateHandledBy.bind(this);
    this.updateRMAIssuedDate = this.updateRMAIssuedDate.bind(this);
    this.setup = this.setup.bind(this);
    this.saveRMA = this.saveRMA.bind(this);
    this.deleteRMA = this.deleteRMA.bind(this);
    this.toggleShowCreateModal = this.toggleShowCreateModal.bind(this);
    this.toggleShowDeleteModal = this.toggleShowDeleteModal.bind(this);
    this.createNewItem = this.createNewItem.bind(this);
    this.saveRMAItem = this.saveRMAItem.bind(this);
    this.deleteRMAItem = this.deleteRMAItem.bind(this);
    this.handleExpanded = this.handleExpanded.bind(this);
  }

  componentDidMount(){
    this.setup();
  }

  render() {
    return (
      <Screen id="RMAInfo" requiredRoles={["support", "product"]} loading={this.state.rmaLoading}>
        <div className="row row-margin-bottom">
          <div className="col-12">
            <Card title={`Basics ${this.state.rmaDirty ? " - Unsaved" : ""}`} loading={this.state.rmaLoading}  cardHeaderClass={this.state.rmaDirty ? "card-header-warning" : "card-header"}>
              <div className="row row-margin-bottom">
                <div className="col-3">
                  <label className="rma-item-label">Created</label>
                  <input type="text" className="form-control"  disabled={true} value={moment(this.state.rma.createdOn).format("MM/DD/YY HH:mm")} />
                </div>
                <div className="col-3">
                  <label className="rma-item-label">Status</label>
                  <select className="form-control" id="status" value={this.state.rma.status} onChange={this.updateField}>
                    <option value="open">Open</option>
                    <option value="closed">Closed</option>
                    <option value="partial">Partial</option>
                  </select>
                </div>
                <div className="col-3">
                  <label className="rma-item-label">Created By</label>
                  <AdminUserSelect onlyActive={false} onUserSelected={this.updateCreatedBy} selectedUserId={this.state.rma.createdByAdminUserId} />
                </div>
                <div className="col-3">
                  <label className="rma-item-label">Handled By</label>
                  <AdminUserSelect onlyActive={false} onUserSelected={this.updateHandledBy} selectedUserId={this.state.rma.handledByAdminUserId} />
                </div>
              </div>

              <div className="row row-margin-bottom">
                <div className="col-3">
                  <label className="rma-item-label">Customer First Name</label>
                  <input type="text" className="form-control" id="customerFirstName" value={this.state.rma.customerFirstName} onChange={this.updateField} />
                </div>
                <div className="col-3">
                  <label className="rma-item-label">Customer Last Name</label>
                  <input type="text" className="form-control" id="customerLastName" value={this.state.rma.customerLastName} onChange={this.updateField} />
                </div>
                <div className="col-3">
                  <label className="rma-item-label">Recipient First Name</label>
                  <input type="text" className="form-control" id="recipientFirstName" value={this.state.rma.recipientFirstName} onChange={this.updateField} />
                </div>
                <div className="col-3">
                  <label className="rma-item-label">Recipient Last Name</label>
                  <input type="text" className="form-control" id="recipientLastName" value={this.state.rma.recipientLastName} onChange={this.updateField} />
                </div>
              </div>

              <div className="row row-margin-bottom">
                <div className="col-3">
                  <label className="rma-item-label">RMA Number</label>
                  <input type="text" className="form-control" id="rmaNumber" value={this.state.rma.rmaNumber} onChange={this.updateField} />
                </div>
                <div className="col-3">
                  <label className="rma-item-label">RMA Issued</label>
                  <select className="form-control" id="rmaIssued" value={this.state.rma.rmaIssued} onChange={this.updateField}>
                    <option value="no">No</option>
                    <option value="yes">Yes</option>
                  </select>
                  {this.state.rma.rmaIssued && this.state.rma.rmaIssued === "yes" && (<DatePicker date={this.state.rma.rmaIssueDate} onDateSaved={this.updateRMAIssuedDate} />)}
                </div>
                <div className="col-3">
                  <label className="rma-item-label">Details</label>
                  <textarea className="form-control" id="details" value={this.state.rma.details} onChange={this.updateField} />
                </div>
                <div className="col-3">
                  <label className="rma-item-label">Notes</label>
                  <textarea className="form-control" id="notes" value={this.state.rma.notes} onChange={this.updateField} />
                </div>
              </div>
              <div className="row row-margin-bottom">
                <div className="col-6">
                  <button className="btn btn-block btn-primary" onClick={this.saveRMA}>Save Basics</button>
                </div>
                <div className="col-6">
                  <button className="btn btn-block btn-danger" onClick={this.toggleShowDeleteModal}>Delete RMA</button>
                </div>
              </div>
            </Card>
          </div>
        </div>
        <div className="row row-margin-bottom">
          <div className="col-12">
            <Card title="RMA Items" loading={this.state.itemListLoading}>
              {this.state.items.length === 0 && (
                <div className="row row-margin-bottom">
                  <div className="col-12" style={{textAlign: "center"}}>
                    <strong>No Items on this RMA</strong>
                  </div>
                </div>
              )}
              {this.state.items.length !== 0 && this.state.expandedCount === 0 && (
                <div className="row row-margin-bottom">
                  <div className="col-2">
                    <label className="rma-item-label">Serial</label>
                  </div>
                  <div className="col-3">
                    <label className="rma-item-label">Description</label>
                  </div>
                  <div className="col-1">
                    <label className="rma-item-label">Qty</label>
                  </div>
                  <div className="col-2">
                    <label className="rma-item-label">Return Code</label>
                  </div>
                  <div className="col-2">
                    <label className="rma-item-label">Primary</label>
              </div>
                </div>
              )}
              {this.state.items.map((item, index) => {
                return (
                <RMAItemScreen 
                  key={index} 
                  codes={this.state.codes}
                  categories={this.state.categories}
                  productCodes={this.state.productCodes}
                  rma={this.state.rma} 
                  item={item} 
                  index={index} 
                  handleExpanded={this.handleExpanded}
                  saveRMAItem={this.saveRMAItem} 
                  deleteRMAItem={this.deleteRMAItem} />
                );
              })}
              <div className="row">
                <div className="col-12">
                  <button className="btn btn-block btn-primary" onClick={this.toggleShowCreateModal}>Add Another Item</button>
                </div>
              </div>


              <Modal show={this.state.showCreateModal} onHide={this.toggleShowCreateModal} dialogClassName="modal90">
                <Modal.Header closeButton>
                  <Modal.Title>
                    Create New RMA Item
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <RMAItemEditScreen 
                    mode="new" 
                    item={null}
                    categories={this.state.categories}
                    codes={this.state.codes} 
                    productCodes={this.state.productCodes}
                    handleSave={this.createNewItem}
                    handleDelete={this.deleteRMAItem}
                    handleRefreshRequest={null}
                  />
                </Modal.Body>
              </Modal>

              <Modal show={this.state.showDeleteModal} onHide={this.toggleShowDeleteModal}>
                <Modal.Header closeButton>
                  <Modal.Title>
                    Delete RMA
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p><strong className="text-danger">Warning: </strong>Are you sure you want to delete this RMA? It is usually better to change the status. Please be very, very sure you want to do this as it cannot be undone!</p>
                </Modal.Body>
                <Modal.Footer>
                  <button className="btn btn-block btn-danger" onClick={this.deleteRMA}>Yes, I Know What I Am Doing</button>
                </Modal.Footer>
              </Modal>
            </Card>
          </div>
        </div>
      </Screen>
    )
  }

  updateField(e: any) {
    let rma: any = this.state.rma;
    rma[e.target.id] = e.target.value;
    this.setState({ rma, rmaDirty: true });
  }

  updateCreatedBy(id: number){
    const rma = this.state.rma;
    rma.createdByAdminUserId = id;
    this.setState({ rma, rmaDirty: true });
  }

  updateHandledBy(id: number){
    const rma = this.state.rma;
    rma.handledByAdminUserId = id;
    this.setState({ rma, rmaDirty: true });
  }

  updateRMAIssuedDate(newDate: moment.Moment){
    const rma = this.state.rma;
    rma.rmaIssueDate = newDate;
    this.setState({ rma, rmaDirty: true });
  }

  private setup() {
    this.setState({ rmaLoading: true }, async () => {
      try{
        const rmaId = this.props.match.params.rmaId;
        const codesResult = await BillingAPI.getRMACodes(app);
        const categoriesResult = await BillingAPI.getRMACategories(app);
        const productCodesResult = await BillingAPI.getProductCodes(app);

        const codes = codesResult.body.data;
        codes.sort((a: any, b: any) => {
          return a.display > b.display ? 1 : -1;
        })
        const categories = categoriesResult.body.data;
        categories.sort((a: any, b: any) => {
          return a.display > b.display ? 1 : -1;
        })
        const productCodes = productCodesResult.body.data;
        productCodes.sort((a: any, b: any) => {
          return a.productCode > b.productCode ? 1 : -1;
        });

        const rmaResult = await BillingAPI.getRMA(app, rmaId);
        const rma = rmaResult.body.data;
        rma.rmaIssued = rma.rmaIssueDate === "1970-01-01" ? "no" : "yes";
        if(rma.rmaIssued === "no"){
          rma.rmaIssueDate = moment();
        }
        // remember, amounts come in as cents, so display them nicely
      

        const iResult = await BillingAPI.getRMAItems(app, rmaId);
        const items: any[] = [];
        for(const item of iResult.body.data){
          item.subscriptionRefundAmountInCents = item.subscriptionRefundAmountInCents / 100;
          item.productRefundAmountInCents = item.productRefundAmountInCents / 100;
          items.push(item);
        }

        this.setState({ rmaLoading: false, itemListLoading: false, rma, items, codes, categories, productCodes });
      }catch(err){
        this.setState({ rmaLoading: false });
      }
    })
  }

  private saveRMA(){
    this.setState({ rmaLoading: true }, async () => {
      try{
        const rma = this.state.rma;
        if(rma.rmaIssued === "no"){
          rma.rmaIssueDate = moment("1970-01-01");
        }
        const result = await BillingAPI.updateRMA(app, this.state.rma.id, rma);
        const rmaUpdated = result.body.data;
        rmaUpdated.rmaIssued = rmaUpdated.rmaIssueDate === "1970-01-01" ? "no" : "yes";
        this.setState({ rmaDirty: false, rmaLoading: false, rma: rmaUpdated });
      }catch(err){
        error("Could not save the RMA");
        this.setState({ rmaLoading: false });
      }
    })
  }

  private deleteRMA(){
    this.setState({ rmaLoading: true }, async () => {
      try{
        await BillingAPI.deleteRMA(app, this.state.rma.id);
        success("Deleted!")
        this.props.history.replace("/rmas");        
      }catch(err){
        error("Could not delete the RMA");
        this.setState({ rmaLoading: false });
      }
    })
  }

  private toggleShowDeleteModal(){
    this.setState({ showDeleteModal: !this.state.showDeleteModal });
  }

  private saveRMAItem(index: number, item: IRMAItem){
    this.setState({ itemListLoading: true }, async () => {
      try{
        const data = BillingAPI.processRMAItemForAPI(item);
        const result = await BillingAPI.updateRMAItem(app, this.state.rma.id, item.id, data);
        const items: any[] = [];
        this.state.items.forEach((item, i) => {
          if(index === i){
            result.body.data.subscriptionRefundAmountInCents = result.body.data.subscriptionRefundAmountInCents / 100;
            result.body.data.productRefundAmountInCents = result.body.data.productRefundAmountInCents / 100;
            items.push(result.body.data);
          } else {
            items.push(item);
          }
        })
        this.setState({ itemListLoading: false, items });
      }catch(err){
        error("Could not save that item");
        this.setState({ itemListLoading: false });
      }
    });
  }

  private deleteRMAItem(index: number, item: IRMAItem,){
    this.setState({ itemListLoading: true }, async () => {
      try{
        await BillingAPI.deleteRMAItem(app, item.rmaId, item.id);
        const items: any[] = [];
        for(const i of this.state.items){
          if(i.id !== item.id){
            items.push(i);
          }
        }
        this.setState({ items, itemListLoading: false });
      }catch(err){
        error("Could not save that item");
        this.setState({ itemListLoading: false });
      }
    });
  }

  private toggleShowCreateModal(){
    this.setState({ showCreateModal: !this.state.showCreateModal });
  }

  private createNewItem(item: IRMAItem){
    const data = BillingAPI.processRMAItemForAPI(item);
    this.setState({ itemListLoading: true }, async () => {
      try{
        const result = await BillingAPI.createRMAItem(app, this.state.rma.id, data);
        result.body.data.subscriptionRefundAmountInCents = result.body.data.subscriptionRefundAmountInCents / 100;
        result.body.data.productRefundAmountInCents = result.body.data.productRefundAmountInCents / 100;
        const items = this.state.items;
        items.push(result.body.data);
        success("Created!");
        this.setState({ itemListLoading: false, showCreateModal: false, items });
      }catch(err){
        error("Could not save that item");
        this.setState({ itemListLoading: false });
      }
    })
  }

  private handleExpanded(index: number, isExpanded: boolean){
    const change = isExpanded ? 1 : -1;
    this.setState({ expandedCount: this.state.expandedCount + change})
  }


}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RMAInfoScreen) as any
);
