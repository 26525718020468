import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as UserActions from "src/reducers/userReducer";
import { DeviceAPI } from "src/api";
import { Modal } from "react-bootstrap";
import * as Alert from "src/components/structure/Alert";
import Screen from "src/components/structure/Screen";

import { handleBool } from "src/utils/utilities";
import Card from "src/components/structure/Card";
import { ListGroup } from "react-bootstrap";
import { styles } from "src/styles";

interface IUsageTabProps {
  deviceId: number;
  userId: number;
  match: any;
  location: any;
  userActions: any;
  userState: any;
}

interface IUsageTabState {
  loading: boolean;
  communicationPlan: string;
  iccid: string;
  imei: string;
  imsi: string;
  msisdn: string;
  overageLimitOverride: string;
  overageLimitReached: boolean;
  ratePlan: string;
  status: string;
  ctdDataUsage: number;
  ctdSMSUsage: number;
  ctdSessionCount: number;
  ctdVoiceUsage: number;

  showChangeModal: boolean;
}

class UsageTab extends Component<IUsageTabProps, IUsageTabState> {
  constructor(props: IUsageTabProps) {
    super(props);

    this.state = {
      loading: false,
      communicationPlan: "",
      iccid: "",
      imei: "",
      imsi: "",
      msisdn: "",
      overageLimitOverride: "",
      overageLimitReached: false,
      ratePlan: "",
      status: "",
      ctdDataUsage: 0,
      ctdSMSUsage: 0,
      ctdSessionCount: 0,
      ctdVoiceUsage: 0,

      showChangeModal: false,
    };

    this.updateField = this.updateField.bind(this);
    this.getDeviceUsage = this.getDeviceUsage.bind(this);
    this.editStatus = this.editStatus.bind(this);
  }

  updateField(e: any) {
    let ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  componentDidMount() {
    this.getDeviceUsage();
  }

  getDeviceUsage() {
    this.setState({ loading: true }, async () => {
      try {
        const res = await DeviceAPI.getDeviceUsage(
          "wagz",
          this.props.userId,
          this.props.deviceId
        );
        this.setState({
          ...res.body.data,
          loading: false,
        });
      } catch (err) {
        console.log(err);
        this.setState({ loading: false });
      }
    });
  }

  editStatus() {
    this.setState({ loading: true }, async () => {
      try {
        let newStatus = this.handleStatusText(this.state.status).toLowerCase();
        await DeviceAPI.updateDeviceSIMStatus(
          "wagz",
          this.props.userId,
          this.props.deviceId,
          {
            status: newStatus,
          }
        );
        Alert.success("Status updated successfully");
        this.setState({ loading: false, showChangeModal: false }, () =>
          this.getDeviceUsage()
        );
      } catch (err) {
        this.setState({
          loading: false,
          showChangeModal: false,
        });
        Alert.error(
          "There was an issue updating that status. Please try again later."
        );
      }
    });
  }

  render() {
    return (
      <Screen fileName="UsageTab.tsx" requiredRoles={["support", "product", "firmware", "mobile"]}>
        <div className="row">
          <div className="col-sm-12 col-lg-6">
            <Card title="SIM Usage Information - Jasper">
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <strong>SIM Status: </strong>
                  {this.state.status}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: -40,
                    }}
                  >
                    {this.props.userState.user.accessLevel !== "read_only" && (
                      <button
                        className="btn btn-sm btn-pri"
                        onClick={() => this.showChangeModal()}
                      >
                        Change
                      </button>
                    )}
                    {this.props.userState.user.accessLevel === "read_only" && (
                      <div style={{ marginTop: 40 }}></div>
                    )}
                  </div>
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Overage Limit Override: </strong>
                  {this.state.overageLimitOverride}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Overage Limit Reached: </strong>
                  {handleBool(this.state.overageLimitReached)}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Rate Plan: </strong>
                  {this.state.ratePlan}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Communication Plan: </strong>
                  {this.state.communicationPlan}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>ICCID: </strong>
                  {this.state.iccid}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>IMEI: </strong>
                  {this.state.imei}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>IMSI: </strong>
                  {this.state.imsi}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>MSISDN: </strong>
                  {this.state.msisdn}
                </ListGroup.Item>
              </ListGroup>
            </Card>
          </div>
          <div className="col-sm-12 col-lg-3 offset-1">
            <Card title="Cycle-to-date Usage">
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <strong>Data (bytes): </strong>
                  {this.state.ctdDataUsage}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>SMS (messages): </strong>
                  {this.state.ctdSMSUsage}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Voice (seconds): </strong>
                  {this.state.ctdVoiceUsage}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Session Count: </strong>
                  {this.state.ctdSessionCount}
                </ListGroup.Item>
              </ListGroup>
            </Card>
          </div>
          <div>
            <Modal
              dialogClassName="modal-75"
              show={this.state.showChangeModal}
              onHide={() => {
                this.setState({ showChangeModal: false });
              }}
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  Editing SIM status for {this.state.iccid}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="center">
                  <p style={{ fontSize: 14, textAlign: "center" }}>
                    <strong>
                      CAUTION: This will change the status of this SIM from {this.state.status} to {this.handleStatusText(this.state.status)}. 
                      Be sure that you know the implications of this and have express permission to make the change.
                    </strong>
                  </p>{" "}
                  <br />
                </div>
                <div className="text">
                  <button
                    className="btn btn-block btn-submit"
                    style={styles.button}
                    onClick={this.editStatus}
                  >
                    Change to {this.handleStatusText(this.state.status)}
                  </button>
                </div>
              </Modal.Body>
              <Modal.Footer></Modal.Footer>
            </Modal>
          </div>
        </div>
      </Screen>
    );
  }

  handleStatusText(input: string) {
    switch (input.toLowerCase()) {
      case "activated": {
        return "DEACTIVATED";
      }
      case "deactivated": {
        return "ACTIVATED";
      }
      default: {
        return "unable to detect";
      }
    }
  }

  private showChangeModal() {
    this.setState({ showChangeModal: true });
  }
}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default connect<{}, {}, any>(
  mapStateToProps,
  mapDispatchToProps
)(UsageTab);
