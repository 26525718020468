import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as UserActions from "src/reducers/userReducer";
import { getUrl, LogsAPI } from "src/api"; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileMedicalAlt, faEye, faDownload } from '@fortawesome/free-solid-svg-icons';
import { Modal } from "react-bootstrap";

import { faAws } from "@fortawesome/free-brands-svg-icons";
import { IDevice } from "src/api/devices";

import Card from "src/components/structure/Card";
import { ListGroup } from "react-bootstrap";
import moment from "moment";
import { error } from "src/components/structure/Alert";
import { DatePicker, Table } from "src/components/structure";

interface ILogsTabProps {
  deviceId: number;
  device: IDevice;
  match: any;
  location: any;
  userActions: any;
  userState: any;
}

interface ILogsTabState {
  loading: boolean;
  startTime: string;
  endTime: string;
  awsUrl: string,
  logglyUrl: string,

  uploadedLogsForDevice: any[];
  showViewUploadedLogModal: boolean;
  uploadedLogsStartStartTime: moment.Moment;
  uploadedLogsStartEndTime: moment.Moment;
  uploadedLogsCount: string;
  uploadedLogsOffset: string;

  loadedLogContent: any;
}

class LogsTab extends Component<ILogsTabProps, ILogsTabState> {
  constructor(props: ILogsTabProps) {
    super(props);

    this.state = {
      loading: false,
      startTime: moment().subtract(6, "hours").format("YYYY-MM-DDTHH:mm:ss.000") + "Z",
      endTime: moment().format("YYYY-MM-DDTHH:mm:ss.000") + "Z",
      awsUrl: "",
      logglyUrl: "",

      uploadedLogsForDevice: [],
      showViewUploadedLogModal: false,

      uploadedLogsStartEndTime: moment(),
      uploadedLogsStartStartTime: moment().subtract(2, "days"),
      uploadedLogsCount: "100",
      uploadedLogsOffset: "0",

      loadedLogContent: ""
    };

    this.updateField = this.updateField.bind(this);
    this.updateStartFilter = this.updateStartFilter.bind(this);
    this.updateEndFilter = this.updateEndFilter.bind(this);

    this.fetchUploadedLogs = this.fetchUploadedLogs.bind(this);

    this.toggleShowViewUploadedLogModal = this.toggleShowViewUploadedLogModal.bind(this);
    this.attemptDownloadOfLog = this.attemptDownloadOfLog.bind(this);
    this.attemptViewOfLog = this.attemptViewOfLog.bind(this);
  }

  componentDidMount() {
    let start = moment().subtract(1, "days");
    let end = moment();
    if(moment(this.props.device.heartbeat).isValid()){
      start = moment(this.props.device.heartbeat).subtract(1, "day");
      end = moment(this.props.device.heartbeat);
    }
    this.setState({
      awsUrl: getUrl("aws", "logs", {"deviceId": this.props.deviceId}),
      logglyUrl: getUrl("loggly", "logs", {"productId": this.props.device.productId, "startTime": this.state.startTime, "endTime": this.state.endTime}),
      uploadedLogsStartStartTime: start,
      uploadedLogsStartEndTime: end,
    }, () => this.fetchUploadedLogs())
  }

  render() {
    return (
      <div className="row">
        <div className="col-3">
          <Card title="Offsite Logs">
            <ListGroup variant="flush">
              <ListGroup.Item>
                <FontAwesomeIcon icon={faAws} /> - <a href={this.state.awsUrl} target="_new">AWS Uploads (Requires AWS Access)</a>
              </ListGroup.Item>
              <ListGroup.Item>
                <FontAwesomeIcon icon={faFileMedicalAlt} /> - <a href={this.state.logglyUrl} target="_new">Loggly Search (Requires Loggly Access)</a>
              </ListGroup.Item>
            </ListGroup>
          </Card>
        </div>    

        <div className="col-9">
          <div className="row">
            <div className="col-12">
              <Card title="Filters">
                <p>Note: The device was onboarded on <strong>{this.props.device.onboardedOn}</strong> and the last heartbeat was on: <strong>{this.props.device.heartbeat}</strong>.</p>
                <div className="row">
                  <div className="col-3">
                    <label>Start</label>
                    <DatePicker date={this.state.uploadedLogsStartStartTime} onDateSaved={this.updateStartFilter} />
                  </div>
                  <div className="col-3">
                    <label>End</label>
                    <DatePicker date={this.state.uploadedLogsStartEndTime} onDateSaved={this.updateEndFilter} />
                  </div>
                  <div className="col-2">
                    <label>Count</label>
                    <input type="text" className="form-control" value={this.state.uploadedLogsCount} id="uploadedLogsCount" onChange={this.updateField} />
                  </div>
                  <div className="col-2">
                    <label>Offset</label>
                    <input type="text" className="form-control" value={this.state.uploadedLogsOffset} id="uploadedLogsOffset" onChange={this.updateField} />
                  </div>
                  <div className="col-2">
                    <button className="btn btn-block btn-success" onClick={this.fetchUploadedLogs} style={{marginTop: 28}}>Update</button>
                  </div>
                </div>
              </Card>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Table 
                title="Log Files"
                csvFileName={`device-uploaded-logs-${this.props.deviceId}`}
                showDownload={true}
                columns={[{
                  "label": "ID",
                  "field": "id",
                },{
                  "label": "Start",
                  "field": "startTimeOfLog",
                },{
                  "label": "end",
                  "field": "endTimeOfLog",
                },{
                  "label": "Actions",
                  "field": "actions",
                },]}
                rows={this.state.uploadedLogsForDevice}
              />
            </div>
          </div>
        </div>    

        <Modal
          show={this.state.showViewUploadedLogModal}
          onHide={this.toggleShowViewUploadedLogModal}
          dialogClassName="modal-xl"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Viewing Log
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div dangerouslySetInnerHTML={{__html: this.state.loadedLogContent}} />
          </Modal.Body>
        </Modal>
        
      </div>
    );
  }



  updateField(e: any) {
    let ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  updateStartFilter(newDate: moment.Moment) {
    this.setState({ uploadedLogsStartStartTime: newDate });
  }

  updateEndFilter(newDate: moment.Moment) {
    this.setState({ uploadedLogsStartEndTime: newDate });
  }

  private fetchUploadedLogs(){
    this.setState({ loading: true }, async () => {
      try{
        const result = await LogsAPI.getLogFiles("wagz2", this.props.device.id, {
          count: this.state.uploadedLogsCount,
          offset: this.state.uploadedLogsOffset,
          start: this.state.uploadedLogsStartStartTime.format("YYYY-MM-DDTHH:mm:ss") + "Z",
          end: this.state.uploadedLogsStartEndTime.format("YYYY-MM-DDTHH:mm:ss") + "Z",
        })
        const parsed: any = [];
        for(const d of result.body.data){
          const start = moment(d.startTimeOfLog).format("YYYY-MM-DD HH:mm:ss");
          const end = moment(d.endTimeOfLog).format("YYYY-MM-DD HH:mm:ss");
          parsed.push({
            startTimeOfLog: start,
            endTimeOfLog: end,
            s3Key: d.s3Key,
            id: d.id,
            actions: (
              <div>
                <FontAwesomeIcon icon={faDownload} className="link" onClick={() => this.attemptDownloadOfLog(d.id, start, end)} />
                <FontAwesomeIcon icon={faEye} className="link" style={{marginLeft: 20}} onClick={() => this.attemptViewOfLog(d.id, start, end)}  />
              </div>
            )
          })
        }
        this.setState({ loading: false, uploadedLogsForDevice: parsed})
      }catch(err){
        error("Could not fetch any uploaded logs with that criteria for that device");
        this.setState({ loading: false });
      }
    })
  }

  private attemptDownloadOfLog(logId: number, start: string, end: string){
    this.setState({ loading: true, }, async () => {      
      try{
        const fileName = `${this.props.deviceId}_${start}-${end}.html`
        await LogsAPI.getLogFileBytes("wagz2", this.props.device.id, logId, {}, { asDownload: true, fileName});
      }catch(err){
        error("Could not view that log");
        this.setState({ loading: false })
      }
    })
  }

  private attemptViewOfLog(logId: number, start: string, end: string){
    this.setState({ loading: true, }, async () => {      
      try{
        const result = await LogsAPI.getLogFileBytes("wagz2", this.props.device.id, logId);
        // NOTE: this just gets the bytes into the body, so there's no body.data, for example
        const loadedLogContent = result.body;
        this.setState( {
          loading: false,
          showViewUploadedLogModal: true,
          loadedLogContent,
        })
      }catch(err){
        error("Could not view that log");
        this.setState({ })
      }
    })
  }

  private toggleShowViewUploadedLogModal(){
    this.setState({ showViewUploadedLogModal: !this.state.showViewUploadedLogModal})
  }
}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default connect<{}, {}, any>(mapStateToProps, mapDispatchToProps)(LogsTab);
