import React, { Component } from "react";
import { connect } from "react-redux";
import { PetsAPI } from "src/api";
import { Table } from "react-bootstrap";
import { capitalizeFirstLetter } from "src/utils/utilities";
import * as Alert from "src/components/structure/Alert";
import { IPet } from "src/api/pets";
import { styles } from "src/styles";
import CacheManagementScreen from "src/components/screens/Backend/CacheManagement";

interface IManageTabProps {
  userId: number;
  pet: IPet;
}

interface IManageTabState {
  loading: boolean;
  status: string;
  currentStatus: string;
  name: string;
  outlierAlerts: "all" | "none" | "critical";
  currentOutlierAlerts: "all" | "none" | "critical";
  shieldEnforcement: "on" | "off";
  currentShieldEnforcement: "on" | "off";
}

class PetManageTab extends Component<IManageTabProps, IManageTabState> {
  constructor(props: IManageTabProps) {
    super(props);

    this.state = {
      loading: false,
      status: this.props.pet.status,
      currentStatus: this.props.pet.status,
      name: this.props.pet.name,
      outlierAlerts: this.props.pet.outlierAlerts,
      currentOutlierAlerts: this.props.pet.outlierAlerts,
      shieldEnforcement: this.props.pet.shieldEnforcement,
      currentShieldEnforcement: this.props.pet.shieldEnforcement,
    };

    this.updateField = this.updateField.bind(this);
    this.updatePetStatus = this.updatePetStatus.bind(this);
    this.updateNickname = this.updateNickname.bind(this);
    this.updateOutlierAlerts = this.updateOutlierAlerts.bind(this);
    this.updateShieldEnforcement = this.updateShieldEnforcement.bind(this);
  }

  render() {
    return (
      <div>
        <Table striped bordered hover>
          <thead style={styles.thead}>
            <th style={styles.tableHead}>Update Pet</th>
            <th style={styles.tableHead}>Outlier Alerts</th>
            <th style={styles.tableHead}>Change Pet name</th>
            <th style={styles.tableHead}>Clear Cache</th>
          </thead>
          <tbody>
            <tr>
              <td style={styles.tCellWidth}>
                <div style={styles.borderBottom}>
                  <h3>
                    Current status: {capitalizeFirstLetter(this.state.currentStatus)}
                  </h3>
                </div>
                <div className="form-group">
                  <select
                    className="form-control"
                    id="status"
                    value={this.state.status}
                    onChange={this.updateField}
                  >
                    <option value="active">Active</option>
                    <option value="removed">Removed</option>
                  </select>
                </div>
                <button
                  className="btn btn-block btn-primary"
                  onClick={this.updatePetStatus}
                >
                  Update Status
                </button>
              </td>
              <td style={styles.tCellWidth}>
                <div style={styles.borderBottom}>
                  <h3>
                    Current setting:{" "}
                    {capitalizeFirstLetter(this.state.currentOutlierAlerts)}
                  </h3>
                </div>
                <div className="form-group">
                  <select
                    className="form-control"
                    id="outlierAlerts"
                    value={this.state.outlierAlerts}
                    onChange={this.updateField}
                  >
                    <option value="all">Send All Outlier Alerts</option>
                    <option value="critical">
                      Only Send Critical Outlier Alerts
                    </option>
                    <option value="none">Don't Send Any Outlier Alerts</option>
                  </select>
                </div>
                <button
                  className="btn btn-block btn-primary"
                  onClick={this.updateOutlierAlerts}
                >
                  Update Status
                </button>
              </td>
              <td style={styles.tCellWidth}>
                <div className="form-group">
                  <input
                    id="name"
                    type="text"
                    className="form-control"
                    autoComplete="new-password"
                    placeholder={this.state.name}
                    onChange={this.updateField}
                    value={this.state.name}
                  />
                </div>
                <button
                  className="btn btn-block btn-primary"
                  onClick={this.updateNickname}
                >
                  Update now
                </button>
              </td>
              <td style={styles.tCellWidth}>
                <CacheManagementScreen
                  userId={this.props.pet.userId}
                  otherId={this.props.pet.id}
                  cacheType="pet"
                />
              </td>
            </tr>
            <tr>
              <td style={styles.tCellWidth}>
                <div style={styles.borderBottom}>
                  <h3>
                    Current shield enforcement setting:{" "}
                    {capitalizeFirstLetter(this.state.currentShieldEnforcement)}
                  </h3>
                </div>
                <div className="form-group">
                  <select
                    className="form-control"
                    id="shieldEnforcement"
                    value={this.state.shieldEnforcement}
                    onChange={this.updateField}
                  >
                    <option value="on">ON</option>
                    <option value="off">OFF</option>
                  </select>
                </div>
                <button
                  className="btn btn-block btn-primary"
                  onClick={this.updateShieldEnforcement}
                >
                  Toggle Shield Enforcement
                </button>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    );
  }

  updateField(e: any) {
    let ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  updatePetStatus() {
    this.setState({ loading: true }, async () => {
      const profileInfo = {
        status: this.state.status,
      };
      try {
        await PetsAPI.updatePet(
          "wagz",
          this.props.pet.userId,
          this.props.pet.id,
          profileInfo
        );
        Alert.success("Status has been updated");
        this.setState({ loading: false, status: this.state.status, currentStatus: this.state.status });
      } catch (error) {
        this.setState({ loading: false });
        Alert.error(
          "Hmm, that doesn't seem to be working right now.  Try again."
        );
      }
    });
  }

  updateShieldEnforcement() {
    this.setState({ loading: true }, async () => {
      const profileInfo = {
        shieldEnforcement: this.state.shieldEnforcement,
      };
      try {
        await PetsAPI.updatePet(
          "wagz",
          this.props.pet.userId,
          this.props.pet.id,
          profileInfo
        );
        Alert.success("Shield Enforcement value has been updated");
        this.setState({ loading: false, shieldEnforcement: this.state.shieldEnforcement, currentShieldEnforcement: this.state.shieldEnforcement });
      } catch (error) {
        this.setState({ loading: false });
        Alert.error(
          "Hmm, that doesn't seem to be working right now.  Try again."
        );
      }
    });
  }

  updateNickname() {
    this.setState({ loading: true }, async () => {
      try {
        await PetsAPI.updatePet(
          "wagz",
          this.props.pet.userId,
          this.props.pet.id,
          { name: this.state.name }
        );
        Alert.success("Name has been updated");
        this.setState({ loading: false, name: this.state.name });
      } catch (error) {
        this.setState({ loading: false });
        Alert.error(
          "Hmm, that doesn't seem to be working right now.  Try again."
        );
      }
    });
  }

  updateOutlierAlerts() {
    this.setState({ loading: true }, async () => {
      try {
        await PetsAPI.updatePet(
          "wagz",
          this.props.pet.userId,
          this.props.pet.id,
          { outlierAlerts: this.state.outlierAlerts }
        );
        Alert.success("Outlier Alert Notification Settings have been updated");
        this.setState({ loading: false, outlierAlerts: this.state.outlierAlerts, currentOutlierAlerts: this.state.currentOutlierAlerts });
      } catch (error) {
        this.setState({ loading: false });
        Alert.error(
          "Hmm, that doesn't seem to be working right now.  Try again."
        );
      }
    });
  }
}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps() {
  return {};
}

export default connect<{}, {}, any>(
  mapStateToProps,
  mapDispatchToProps
)(PetManageTab);
