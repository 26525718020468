import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { DeviceAPI } from "src/api";
import { IDeviceConnectivityLog } from "src/api/devices";
import Table from "src/components/structure/Table";
import * as UserActions from "src/reducers/userReducer";
import moment from "moment";
import { styles } from "src/styles";
import { DatePicker } from "src/components/structure";


interface IConnectivityLogsTabProps {
  deviceId: number;
  userId: number;
  location: any;
  userActions: any;
  userState: any;
}

interface IConnectivityLogsTabState {
  loading: boolean;
  start: moment.Moment;
  end: moment.Moment;
  logs: IDeviceConnectivityLog[];
  showEmptyLines: boolean;
}

class ConnectivityLogs extends Component<IConnectivityLogsTabProps, IConnectivityLogsTabState> {
  constructor(props: IConnectivityLogsTabProps) {
    super(props);

    this.state = {
      loading: true,
      start: moment().subtract(2, "weeks"),
      end: moment().add(1, "day"),
      logs: [],
      showEmptyLines: false
    };

    this.updateField = this.updateField.bind(this);
    this.updateStart = this.updateStart.bind(this);
    this.updateEnd = this.updateEnd.bind(this);
    this.getConnectivityLogs = this.getConnectivityLogs.bind(this);
    this.handleEmptyLinesToggle = this.handleEmptyLinesToggle.bind(this);
    this.refreshLogs = this.refreshLogs.bind(this);
  }

  updateField(e: any) {
    let ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  componentDidMount() {
    this.getConnectivityLogs();
  }

  getConnectivityLogs() {
    this.setState({ loading: true }, async () => {
      try {
        const result = await DeviceAPI.getDeviceConnectivityLogs(
          "wagz",
          this.props.userId,
          this.props.deviceId,
          this.state.start,
          this.state.end,
        );
        const logs: IDeviceConnectivityLog[] = [];
        for(const l of result.body.data){
          if (!this.state.showEmptyLines && l.sysmode !== "unknown") {
            l.posted = moment(l.posted).format("MM/DD/YYYY HH:mm:ss");
            l.sysmode = this.translateSysMode(l.sysmode);
            l.registrationStatus = this.translateRegistrationStatus(l.registrationStatus);
            logs.push(l);
          } else if (this.state.showEmptyLines) {
            l.posted = moment(l.posted).format("MM/DD/YYYY HH:mm:ss");
            l.sysmode = this.translateSysMode(l.sysmode);
            l.registrationStatus = this.translateRegistrationStatus(l.registrationStatus);
            logs.push(l);
          }
        }
        logs.sort((a: IDeviceConnectivityLog, b: IDeviceConnectivityLog) => {
          return moment(a.posted).isBefore(moment(b.posted)) ? -1 : 1;
        })
        this.setState({loading: false, logs});
      } catch (err) {
        this.setState({ loading: false });
      }
    });
  }

  render() {
    return (
      <div>
        <div className="col-12 col-lg-2" style={styles.checkboxBox}>
          <div>
            <input
              id="showEmptyLines"
              type="checkbox"
              aria-label="Hide or Show Empty Lines"
              checked={this.state.showEmptyLines}
              onChange={this.handleEmptyLinesToggle}
            />
          </div>
          <div style={{ marginTop: 5, marginLeft: 5, padding: 2 }}>
            <label style={{ marginLeft: 5 }} htmlFor="showPassword">
              Show Empty Lines
            </label>
          </div>
        </div>
        <div>
          <Table
            title="Connectivity Logs"
            csvFileName={`device-connectivity-logs-${this.props.deviceId}`}
            showDownload={true}
            columns={columns}
            rows={this.state.logs}
            loading={this.state.loading}
            preElement={(
              <>
                <div className="row">
                  <div className="col-4">
                    <label>From</label>
                    <DatePicker date={this.state.start} onDateSaved={this.updateStart} />
                  </div>
                  <div className="col-4">
                    <label>To</label>
                    <DatePicker date={this.state.end} onDateSaved={this.updateEnd} />
                  </div>

                  <div className="col-4">
                      <button style={{ marginTop: 30 }} className="btn btn-block btn-primary" onClick={this.refreshLogs}>Refresh</button>
                    </div>
                </div>
              </>
            )}
          />
        </div>
      </div>
    );
  }

  private updateStart(newDate: moment.Moment){
    this.setState({ start: newDate.subtract(12, "hours")});
  }

  private updateEnd(newDate: moment.Moment){
    this.setState({ end: newDate.add(43199, "seconds")});
  }

  private refreshLogs(){
    this.getConnectivityLogs();
  }

  private handleEmptyLinesToggle() {
    this.setState({
      showEmptyLines: !this.state.showEmptyLines,
    });
    this.getConnectivityLogs();
  }

  // 
  private translateRegistrationStatus(input: string): string{
    switch(input){
      case "not_registered_search'": return "Not Registered, Searching";
      case "registered_home": return "Registered";
      case "not_registered_trying": return "Not Registered, Trying to Connect";
      case "registration_denied": return "Registration Denied";
      case "unknown": return "Unknown";
      case "roaming": return "Roaming";
      default: return input;
    }
  }

  private translateSysMode(input: string): string{
    switch(input){
      case "emtc": return "eMTC";
      case "none": return "None";
      case "nbiot": return "NBIoT";
      case "gsm": return "GSM";
      case "unknown": return "Unknown";
      default: return input;
    }
  }
}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default connect<{}, {}, any>(
  mapStateToProps,
  mapDispatchToProps
)(ConnectivityLogs);

const columns = [
  {
    label: "Posted",
    field: "posted",
    sort: "desc",
  },
  {
    label: "Sys. Mode",
    field: "sysmode",
  },
  {
    label: "Reg. Status",
    field: "registrationStatus",
  },
  {
    label: "RSSI",
    field: "rssi",
  },
  {
    label: "RSRP",
    field: "rsrp",
  },
  {
    label: "RSRQ",
    field: "rsrq",
  },
  {
    label: "Sig/ Noise",
    field: "snr",
  },
  {
    label: "Oper.",
    field: "operator",
  },
  {
    label: "Band",
    field: "band",
  },
  {
    label: "550s",
    field: "device550s",
  },
  {
    label: "565s",
    field: "device565s",
  },
  {
    label: "PDP Deacts",
    field: "pdpDeacts",
  },
  {
    label: "Cell Mode",
    field: "cellMode",
  },
  {
    label: "Sec. Since Conn.",
    field: "secondsSinceConnection",
  },
  {
    label: "Bytes Sent",
    field: "bytesSent",
  },
  {
    label: "Bytes Rec",
    field: "bytesReceived",
  },
];