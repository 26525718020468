import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { PetsAPI } from "src/api";
import { IHealthBoxVisit } from "src/api/pets";
import Table from "src/components/structure/Table";
import * as UserActions from "src/reducers/userReducer";
import { secondsToHms } from "src/utils/utilities"
import moment from "moment";

interface IHealthBoxTabProps {
  petId: number;
  userId: number;
  location: any;
  userActions: any;
  userState: any;
}

interface IHealthBoxTabState {
  loading: boolean;
  visits: IHealthBoxVisit[];
}

class HealthBoxTab extends Component<
  IHealthBoxTabProps,
  IHealthBoxTabState
> {
  constructor(props: IHealthBoxTabProps) {
    super(props);

    this.state = {
      loading: false,
      visits: [],
    };

    this.getHealthBoxVisits = this.getHealthBoxVisits.bind(this);
  }

  componentDidMount() {
    this.getHealthBoxVisits();
  }

  getHealthBoxVisits() {
    this.setState({ loading: true }, async () => {
      try {
        const result = await PetsAPI.getHealthBoxVisitsForPet(
          "wagz",
          this.props.userId,
          this.props.petId
        );
        const visits: IHealthBoxVisit[] = [];
        for (const l of result.body.data) {
          l.startTime = moment(l.startTime).format("MM/DD/YYYY HH:mm:ss");
          l.endTime = moment(l.endTime).format("MM/DD/YYYY HH:mm:ss");
          l.visitDurationSeconds = secondsToHms(l.visitDurationSeconds)
          l.secondsSincePreviousVisit = secondsToHms(l.secondsSincePreviousVisit)
          visits.push(l);
        }
        this.setState({ loading: false, visits });
      } catch (err) {
        this.setState({ loading: false });
      }
    });
  }

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-4"></div>
        </div>
        <div>
          <Table
            title={`Health Box Visits for Pet ${this.props.petId}`}
            csvFileName={`healthbox-visits-${this.props.petId}`}
            showDownload={true}
            columns={[
              {
                label: "Start Time",
                field: "startTime",
                sort: "asc",
              },
              {
                label: "End Time",
                field: "endTime",
                sort: "asc",
              },
              {
                label: "Visit Duration",
                field: "visitDurationSeconds",
                sort: "asc",
              },
              {
                label: "Pet Weight",
                field: "petWeight",
                sort: "asc",
              },
              {
                label: "Weight Differential",
                field: "weightDifferential",
                sort: "asc",
              },
              {
                label: "Time Since Previous Visit",
                field: "secondsSincePreviousVisit",
                sort: "asc",
              },
              {
                label: "Device ID",
                field: "deviceId",
                sort: "asc",
              },
              {
                label: "User ID",
                field: "userId",
                sort: "asc",
              },
            ]}
            rows={this.state.visits}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default connect<{}, {}, any>(
  mapStateToProps,
  mapDispatchToProps
)(HealthBoxTab);
