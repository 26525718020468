import React, { Component } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import { PetsAPI } from "src/api";
import { ICorrectionIntensityInfo, BlankIntensity } from "src/api/pets";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getIcon, getColor } from "src/utils/utilities";

interface IIntensityProps {
  loading: boolean;
  userID: any;
  petID: any;
}

interface IIntensityState {
  loading: boolean;
  audible: ICorrectionIntensityInfo;
  ultrasonic: ICorrectionIntensityInfo;
  vibration: ICorrectionIntensityInfo;
}
class Intensity extends Component<IIntensityProps, IIntensityState> {
  constructor(props: any) {
    super(props);
    this.state = {
      loading: this.props.loading,
      audible: BlankIntensity,
      ultrasonic: BlankIntensity,
      vibration: BlankIntensity,
    };

    this.getIntensity = this.getIntensity.bind(this);
  }

  componentDidMount() {
    this.getIntensity();
  }

  getIntensity() {
    this.setState({ loading: true }, async () => {
      try {
        const res = await PetsAPI.getCorrectionIntensityPet("wagz2", this.props.userID, this.props.petID);
        console.log("CIS - ", res)
        this.setState({ loading: false, audible: res.body.data.audible, ultrasonic: res.body.data.ultrasonic, vibration: res.body.data.vibration });
      } catch (err) {
        this.setState({ loading: false });
      }
    });
  }

  render() {
    return (
      <div style={{ backgroundColor: "transparent" }}>
        <div>
          <Accordion allowZeroExpanded={true} allowMultipleExpanded={true}>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>Audible 
                  <div className="fa fa-fw">
                    <FontAwesomeIcon
                      icon={getIcon(this.state.audible.enabled)}
                      className={getColor(this.state.audible.enabled)}
                    />
                  </div>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                  <div>
                    <ul>
                      <li> Corrections Enabled: <strong>{this.state.audible.enabled}</strong></li>
                      <li> Correction Strength: <strong>{this.state.audible.strength}</strong></li>
                      <li> Corrections Enabled (Warning Zones): <strong>{this.state.audible.warningEnabled}</strong></li>
                      <li> Correction Strength (Warning Zones): <strong>{this.state.audible.warningStrength}</strong></li>
                    </ul>
                  </div>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>Ultrasonic
                  <div className="fa fa-fw">
                    <FontAwesomeIcon
                      icon={getIcon(this.state.ultrasonic.enabled)}
                      className={getColor(this.state.ultrasonic.enabled)}
                    />
                  </div>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                  <div>
                    <ul>
                      <li> Corrections Enabled: <strong>{this.state.ultrasonic.enabled}</strong></li>
                      <li> Correction Strength: <strong>{this.state.ultrasonic.strength}</strong></li>
                      <li> Corrections Enabled (Warning Zones): <strong>{this.state.ultrasonic.warningEnabled}</strong></li>
                      <li> Correction Strength (Warning Zones): <strong>{this.state.ultrasonic.warningStrength}</strong></li>
                    </ul>
                  </div>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>Vibration
                  <div className="fa fa-fw">
                    <FontAwesomeIcon
                      icon={getIcon(this.state.vibration.enabled)}
                      className={getColor(this.state.vibration.enabled)}
                    />
                  </div>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                  <div>
                    <ul>
                      <li> Corrections Enabled: <strong>{this.state.vibration.enabled}</strong></li>
                      <li> Correction Strength: <strong>{this.state.vibration.strength}</strong></li>
                      <li> Corrections Enabled (Warning Zones): <strong>{this.state.vibration.warningEnabled}</strong></li>
                      <li> Correction Strength (Warning Zones): <strong>{this.state.vibration.warningStrength}</strong></li>
                    </ul>
                  </div>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
        </div>
      </div>
    );
  }
}

export default Intensity;
