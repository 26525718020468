import { makeCall } from "./client";

export class ReportsAPI {
 
  /**
   * Gets all the devices on the platform in summary format
   * @param app 
   * @param options 
   */
  public getAllDevices = (options: any = {}) => {
    const data = {
      ...options
    };
    return makeCall('GET', `admin/reports/devices/total`, data);
  }

  /**
   * Gets all of the devices counted by activation date
   * @param start 
   * @param end 
   * @param app 
   * @param options 
   */
  public getDevicesByActivationDate = (start: string, end: string, options: any = {}) => {
    const data = {
      start,
      end,
      ...options
    };
    return makeCall('GET', `admin/reports/devices/activation`, data);
  }


  /**
   * Gets all of the users broken down by app/service
   * @param options 
   */
  public getAllUsers = (options: any = {}) => {
    const data = {
      ...options
    };
    return makeCall('GET', `admin/reports/users/total`, data);
  }

  /**
   * Gets all of the users by their signup date
   * @param start 
   * @param end 
   * @param app 
   * @param options 
   */
  public getUsersBySignupDate = (start: string, end: string, options: any = {}) => {
    const data = {
      start,
      end,
      ...options
    };
    return makeCall('GET', `admin/reports/users/signup`, data);
  }

  /**
   * Gets all of the orders by their created date
   * @param start 
   * @param end 
   * @param app 
   * @param options 
   */
  public getOrdersByStatusForTime = (start: string, end: string, status: string, options: any = {}) => {
    const data = {
      start,
      end,
      ...options
    };
    return makeCall('GET', `admin/reports/orders/all/${status}`, data);
  }

  /**
   * Gets all of the orders broken down by origination
   * @param options 
   */
  public getAllOrders = (options: any = {}) => {
    const data = {
      ...options
    };
    return makeCall('GET', `admin/reports/orders/total`, data);
  }

  /**
   * Get all of the data for orders
   * @param options 
   */
  public getAllCustomerOrders = (options: any = {}) => {
    const data = {
      ...options
    };
    return makeCall('GET', `admin/reports/orders`, data);
  }

  /**
   * Get all of the data for a users orders
   * @param userID 
   * @param options 
   */
  public getAllOrdersForUser = (userID: number, options: any = {}) => {
    const data = {
      ...options
    };
    return makeCall('GET', `admin/reports/users/${userID}/orders`, data);
  }

  /**
   * Get all of the data for a users order items
   * @param userID 
   * @param options 
   */
  public getAllOrderItemsForUser = (userID: number, options: any = {}) => {
    const data = {
      ...options
    };
    return makeCall('GET', `admin/reports/users/${userID}/orders/items`, data);
  }

  /**
   * Get all of the order items by order id
   * @param orderID 
   * @param options 
   */
  public getOrderItemsByOrderID = (orderID: number, options: any = {}) => {
    const data = {
      ...options
    };
    return makeCall('GET', `admin/reports/orders/items/${orderID}`, data);
  }

  /**
   * Get all of the data for an order
   * @param orderID 
   * @param options 
   */
  public getOrderByOrderID = (orderID: number, options: any = {}) => {
    const data = {
      ...options
    };
    return makeCall('GET', `admin/reports/orders/${orderID}`, data);
  }
  
  /**
   * Updates a specified order
   * @param orderID 
   * @param options 
   */
  public updateOrder = (orderID: number, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('PATCH', `admin/reports/orders/${orderID}`, data);
  }


}