import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Select from "react-select";
import Table from "src/components/structure/Table";
import { capitalizeFirstLetter } from "src/utils/utilities";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';

// import * as Alert from "../../structure/Alert";
import * as UserActions from "src/reducers/userReducer";
import { UserAPI } from "src/api";
import { IColumns } from "src/utils/customTypes";
import { fetchOptions } from "src/utils/items";

interface IUserManagementProps {
  location: any;
  userActions: any;
  userState: any;
}

interface IUserManagementState {
  loading: boolean;
  rows: any[];
  csvData: any[];
  recent: string;
}

const cols = [
  {
    label: "",
    field: "view",
    sort: "disabled"
  },
  {
    label: "ID",
    field: "id",
    sort: "asc",
    width: 100,
  },
  {
    label: "First Name",
    field: "firstName",
    sort: "asc",
    width: 150,
  },
  {
    label: "Last Name",
    field: "lastName",
    sort: "asc",
    width: 200,
  },
  {
    label: "Email",
    field: "email",
    sort: "asc",
    width: 200,
  },
  {
    label: "Status",
    field: "status",
    sort: "asc",
    width: 150,
  },
  {
    label: "Date Created",
    field: "date",
    sort: "asc",
    width: 110,
  },
  {
    label: "Last Logged In",
    field: "lastLoggedIn",
    sort: "asc",
    width: 110,
  },
  {
    label: "Mobile Phone",
    field: "mobilePhone",
    sort: "asc",
    width: 110,
  },
  {
    label: "Additional Phone Number",
    field: "homePhone",
    sort: "asc",
    width: 110,
  },
];

class UserManagement extends Component<
  IUserManagementProps,
  IUserManagementState
> {
  constructor(props: IUserManagementProps) {
    super(props);

    this.state = {
      loading: false,
      recent: "yes",
      rows: [],
      csvData: [],
    };

    this.updateField = this.updateField.bind(this);
    this.populateUsers = this.populateUsers.bind(this);
    this.processData = this.processData.bind(this);
  }

  updateField(e: any) {
    let ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  componentDidMount() {
    this.populateUsers();
  }

  populateUsers() {
    this.setState({ loading: true }, async () => {
      try {
        const results = await UserAPI.getAllUsers("wagz", { recent: this.state.recent });

        const rows = [];
        const csvData: any = [];
        for (let result of results.body.data) {
          csvData.push(result);
          rows.push({
            id: result.id,
            view: (
              <div style={{textAlign: "center"}}>
                <Link to={`/support/users/${result.id}`} id="user_page" className="link-light">
                  <FontAwesomeIcon icon={faEye} />
                </Link>
              </div>
            ),
            firstName: result.firstName,
            lastName: result.lastName,
            email: result.email,
            status: capitalizeFirstLetter(result.status),
            date: result.created,
            lastLoggedIn: result.lastLoggedIn,
            homePhone: result.homePhone,
            mobilePhone: result.mobilePhone,
          });
        }
        this.setState({
          loading: false,
          rows,
          csvData,
        });
      } catch (err) {
        this.setState({ loading: false });
      }
    });
  }

  processData(columns: IColumns[], rows: any) {
    const data = {
      columns,
      rows,
    };
    return data;
  }

  render() {
    return (
      <div>
        <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: 10 }}>
          <div className="col-sm-12 col-lg-2">
            <label style={{ fontSize: 15, marginLeft: 5 }}>Users to Show</label>
            <Select
              options={fetchOptions}
              onChange={this.updateSelection}
              hideSelectedOptions={true}
            />
          </div>
        </div>
        <div>
          <Table
            title="User Management"
            csvFileName="users"
            showDownload={true}
            columns={cols}
            rows={this.state.rows}
            csvData={this.state.csvData}
          />
        </div>
      </div>
    );
  }

  updateSelection = (input: any) => {
    let ns = this.state;
    ns[input.field] = input.value;
    this.setState(ns);
    this.populateUsers();
  };
}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserManagement) as any
);
