import * as React from "react";
import { Loading } from "./Loading";

interface IButtonProps {
  loading: boolean;
  onClick: any;
  className: string;
  children: any;
  loadingStyles?: any;
}


export const LoadingButton = (props: IButtonProps) => {

  return props.loading ?
    (<Loading style={props.loadingStyles ? props.loadingStyles : { textAlign: "center" }} />) :
    (<button className={props.className === "" ? "btn btn-block btn-secondary" : props.className} onClick={props.onClick}>{props.children}</button>);

}