import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Select from "react-select";

import * as AppActions from "src/reducers/appReducer";
import * as UserActions from "src/reducers/userReducer";

import { Alert, Card } from "src/components/structure";
import { UserAPI } from "src/api";
import { roles, accessLevels } from "src/utils/items";
interface INewUserProps {
  loading: boolean;
  userActions: any;
  userState: any;
  onUserCreated: any;
}

interface INewUserState {
  loading: boolean;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  accessLevel: string;
  roles: string[];
  id: number;
}

class newUserScreen extends Component<INewUserProps, INewUserState> {
  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      accessLevel: "",
      roles: [],
      id: 0,
    };
    this.updateField = this.updateField.bind(this);
    this.addNewUser = this.addNewUser.bind(this);
    this.updateRoles = this.updateRoles.bind(this);
  }

  render() {
    return (
      <div style={{ marginBottom: 20 }}>
        <Card
          title="Add New Admin User"
          loading={this.props.loading || this.state.loading}
        >
          <div className="center">
            <p style={{ fontSize: 14, textAlign: "center" }}>
              <strong>
                Enter the following information about the user you would like to
                add.
              </strong>
            </p>{" "}
            <br />
          </div>
          <div className="form-group" style={{ marginTop: 10 }}>
            <input
              id="firstName"
              type="text"
              className="form-control"
              placeholder="First Name"
              autoComplete="new-password"
              onChange={this.updateField}
              value={this.state.firstName}
            />
          </div>
          <div className="form-group">
            <input
              id="lastName"
              type="text"
              className="form-control"
              placeholder="Last Name"
              autoComplete="new-password"
              onChange={this.updateField}
              value={this.state.lastName}
            />
          </div>
          <div className="form-group">
            <input
              id="email"
              type="text"
              className="form-control"
              placeholder="Email"
              autoComplete="new-password"
              onChange={this.updateField}
              value={this.state.email}
            />
          </div>
          <div className="form-group">
            <input
              id="password"
              type="password"
              className="form-control"
              placeholder="Password"
              autoComplete="new-password"
              onChange={this.updateField}
              value={this.state.password}
            />
          </div>
          <div className="form-group">
            <label style={{ fontSize: 12, marginLeft: 5 }}>
              Access Level
            </label>
            <Select
              options={accessLevels}
              onChange={this.updateSelection}
              hideSelectedOptions={true}
            />
          </div>
          <div className="form-group">
            <label style={{ fontSize: 12, marginLeft: 5 }}>
              Role(s) for user
            </label>
            <Select
              options={roles}
              isMulti={true}
              onChange={this.updateRoles}
              hideSelectedOptions={true}
            />
          </div>
          <div className="form-group" style={{ marginTop: 20 }}>
            <button
              className="btn btn-block btn-primary"
              onClick={this.addNewUser}
            >
              Add New Admin User
            </button>
          </div>
        </Card>
      </div>
    );
  }

  updateField(e: any) {
    let ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  updateSelection = (input: any) => {
    let ns = this.state;
    ns[input.field] = input.value;
    this.setState(ns);
  };

  updateRoles(e: any) {
    if (e === null) {
      return;
    }
    const updatedRoles = [];
    let v: any;
    for (v of e) {
      updatedRoles.push(v.value);
    }
    this.setState({ roles: updatedRoles });
  }

  addNewUser() {
    this.setState({ loading: true }, async () => {
      try {
        let res = await UserAPI.addUser(
          this.state.firstName,
          this.state.lastName,
          this.state.email,
          this.state.password,
          { accessLevel: this.state.accessLevel }
        );
        const newUser = res.body.data;
        if (!newUser.roles) {
          newUser.roles = [];
        }
        for (const role of this.state.roles) {
          await UserAPI.addUserRole(newUser.id, role);
          newUser.roles.push(role);
        }
        return this.setState(
          {
            loading: false,
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            roles: [],
            id: 0,
          },
          () => {
            Alert.success("User added successfully");
            this.props.onUserCreated(newUser);
          }
        );
      } catch (err) {
        return this.setState({ loading: false }, () => {
          Alert.error("We could not add that user");
        });
      }
    });
  }
}

const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(AppActions, dispatch),
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(newUserScreen);
