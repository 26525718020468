import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as UserActions from "src/reducers/userReducer";
import { DeviceAPI } from "src/api";
import { IDevice, BlankDevice } from "src/api/devices";
import Screen from "src/components/structure/Screen";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import InfoTab from "./DeviceTabs/InfoTab";
import BillingTab from "./DeviceTabs/BillingTab";
import StatusTab from "./DeviceTabs/StatusTab";
import ShadowTab from "./DeviceTabs/ShadowTab";
import ActionsTab from "./DeviceTabs/ActionsTab";
import EventsTab from "src/components/structure/EventsTab";
import LogsTab from "./DeviceTabs/LogsTab";
import ManageTab from "./DeviceTabs/ManageTab";
import ConnectivityLogsTab from "./DeviceTabs/ConnectivityLogsTab";
import LocationScreen from "src/components/structure/LocationScreen";
import SettingsTab from "./DeviceTabs/SettingsTab";
import DeviceSettingsTab from "./DeviceTabs/DeviceSettingsTab";
import ConsolidatedTab from "./DeviceTabs/ConsolidatedViewTab";
import BatterCyclesTab from "./DeviceTabs/BatteryCyclesTab";
import UsageTab from "./DeviceTabs/UsageTab";
import LifecycleTab from "./DeviceTabs/LifecycleTab";
import { AnalyticEventsScreen } from "src/components/screens/Reports/Analytics/AnalyticEvents";

interface IDevicePageProps {
  match: any;
  history: any;
  location: any;
  userActions: any;
  userState: any;
}


interface IDevicePageState {
  loading: boolean;
  deviceId: number;
  device: IDevice;
  view: string;
}

class DevicePage extends Component<IDevicePageProps, IDevicePageState> {
  constructor(props: IDevicePageProps) {
    super(props);

    this.state = {
      loading: true,
      deviceId: this.props.match.params.deviceId,
      device: BlankDevice,
      view: "info",
    };

    this.goBack = this.goBack.bind(this);
    this.tabCheck = this.tabCheck.bind(this);
  }

  goBack() {
    this.props.history.goBack();
  }

  componentDidMount() {
    this.getDevice();
  }

  getDevice() {
    this.setState({ loading: true }, async () => {
      try {
        // get the device as well
        const deviceResult = await DeviceAPI.getDeviceById("wagz", this.state.deviceId);
        this.setState({
          loading: false,
          device: deviceResult.body.data,
        });
      } catch (err) {
        this.setState({ loading: false });
      }
    });
  }

  tabCheck(deviceType : any[]) {
    for (let d of deviceType) {
      if (this.state.device.deviceType === d) {
        return true
      }
    }
    return false
  }

  render() {
    if (this.state.loading) {
      return (
        <div style={{ textAlign: "center" }}>
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      );
    }

    // we have the device, so EITHER they need to have the support role OR they need to have the right permission
    return (
      <Screen>
        <div className="col-sm-12 col-lg-12">
          <div>
            <h1>Device Information (ID: {this.state.deviceId}, Serial: {this.state.device.productId ? this.state.device.productId : ""})</h1>
          </div>

          <div className="row">
            <div className="col-12">
              <Tabs>
                <TabList>
                  <Tab>Information</Tab>
                  {this.tabCheck(["collar"]) ? <Tab>Consolidated</Tab> : ""}
                  {this.tabCheck(["collar"]) ? <Tab>Billing</Tab> : ""}
                  {this.tabCheck(["collar", "healthBox", "bowl"]) ? <Tab>Status Bar</Tab> : ""}
                  {this.tabCheck(["collar"]) ? <Tab>Settings</Tab> : ""}
                  {this.tabCheck(["shield", "healthBox", "bowl"]) ? <Tab>Settings</Tab> : ""}
                  {this.tabCheck(["collar"]) ? <Tab>Shadow</Tab> : ""}
                  {this.tabCheck(["collar"]) ? <Tab>Locations</Tab> : ""}
                  <Tab>Actions</Tab>
                  <Tab>Events</Tab>
                  {this.tabCheck(["collar"]) ? <Tab>Battery</Tab> : ""}
                  {this.tabCheck(["collar"]) ? <Tab>Cell</Tab> : ""}
                  <Tab>Lifecycle</Tab>
                  {this.tabCheck(["collar"]) ? <Tab>Connectivity</Tab> : ""}
                  <Tab>General Logs</Tab>
                  <Tab>Analytics</Tab>
                  <Tab>Management</Tab>
                </TabList>
                <TabPanel>
                  <InfoTab deviceId={this.state.deviceId} />
                </TabPanel>
                {this.tabCheck(["collar"]) ? <TabPanel>
                  <ConsolidatedTab userId={this.state.device.registeredBy} deviceId={this.state.device.id} />
                </TabPanel> : ""}
                {this.tabCheck(["collar"]) ? <TabPanel>
                  <BillingTab device={this.state.device} />
                </TabPanel> : ""}
                {this.tabCheck(["collar", "healthBox", "bowl"]) ? <TabPanel>
                  <StatusTab device={this.state.device} />
                </TabPanel> : ""}
                {this.tabCheck(["collar"]) ? <TabPanel>
                  <SettingsTab accessLevel={this.props.userState.user.accessLevel} device={this.state.device} />
                </TabPanel> : ""}
                {this.tabCheck(["shield", "healthBox", "bowl"]) ? <TabPanel>
                  <DeviceSettingsTab userId={this.state.device.registeredBy} device={this.state.device} />
                </TabPanel> : ""}
                {this.tabCheck(["collar"]) ? <TabPanel>
                  <ShadowTab device={this.state.device} />
                </TabPanel> : ""}
                {this.tabCheck(["collar"]) ? <TabPanel>
                  <LocationScreen userId={this.state.device.registeredBy} locationsFor="device" deviceId={this.state.deviceId} />
                </TabPanel> : ""}
                <TabPanel>
                  <ActionsTab deviceId={this.state.deviceId} />
                </TabPanel>
                <TabPanel>
                  <EventsTab accessLevel={this.props.userState.user.accessLevel} loading={this.state.loading} eventsFor="device" deviceId={this.state.deviceId} userId={this.state.device.registeredBy} petId={0} />
                </TabPanel>
                {this.tabCheck(["collar"]) ? <TabPanel>
                  <BatterCyclesTab userId={this.state.device.registeredBy} deviceId={this.state.device.id} />
                </TabPanel> : ""}
                {this.tabCheck(["collar", "healthBox"]) ? <TabPanel>
                  <UsageTab userId={this.state.device.registeredBy} deviceId={this.state.device.id} />
                </TabPanel> : ""}
                <TabPanel>
                  <LifecycleTab userId={this.state.device.registeredBy} deviceId={this.state.device.id} />
                </TabPanel>
                {this.tabCheck(["collar"]) ? <TabPanel>
                  <ConnectivityLogsTab userId={this.state.device.registeredBy} deviceId={this.state.deviceId} />
                </TabPanel> : ""}
                <TabPanel>
                  <LogsTab deviceId={this.state.deviceId} device={this.state.device} />
                </TabPanel>
                <TabPanel>
                  <AnalyticEventsScreen target="device" targetId={this.state.device.id} />
                </TabPanel>
                <TabPanel>
                  <ManageTab deviceId={this.state.deviceId} />
                </TabPanel>
              </Tabs>
            </div>
          </div>
        </div>
      </Screen>
    );
  }
}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DevicePage) as any
);
