import { faSyncAlt, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { DeviceAPI } from "src/api";
import { BlankRMAItem, IRMAItem } from "src/api/billing";
import { DatePicker, Loading } from "src/components/structure";
import { capitalizeFirstLetter } from "src/utils/utilities";

interface IRMAItemEditProps {
  mode: "edit" | "new"; // if edit, we ignore the items in the item row
  item: IRMAItem | null;
  categories: any[];
  codes: any[];
  productCodes: any[];
  isRefreshing?: boolean;
  handleSave: any;
  handleDelete: any;
  handleRefreshRequest: any;
}

interface IRMAItemEditState {
  loading: boolean;
  item: IRMAItem;
  dirty: boolean;
  showDeleteWarningModal: boolean;
  showSaveWarningModal: boolean;
}

export class RMAItemEditScreen extends Component<IRMAItemEditProps, IRMAItemEditState> {
  constructor(props: IRMAItemEditProps) {
    super(props);

    this.state = {
      loading: false,
      item: this.props.item ? this.props.item : BlankRMAItem,
      dirty: false,
      showDeleteWarningModal: false,
      showSaveWarningModal: false,
    };

    this.updateField = this.updateField.bind(this);
    this.updateReceivedOn = this.updateReceivedOn.bind(this);
    this.updateProductCode = this.updateProductCode.bind(this);
    this.toggleShowDeleteWarningModal = this.toggleShowDeleteWarningModal.bind(this);
    this.toggleShowSaveeWarningModal = this.toggleShowSaveeWarningModal.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.requestRefresh = this.requestRefresh.bind(this);
    this.checkBeforeSave = this.checkBeforeSave.bind(this);
  }

  render() {
    return (
      <div>
        <div className="row row-margin-bottom">
          <div className="col-2">
            <label className="rma-item-label">Serial</label>
            <input type="text" className="form-control" id="productId" value={this.state.item.productId} onChange={this.updateField} />
          </div>
          <div className="col-3">
            <label className="rma-item-label">Code/Number</label>
            <select className="form-control" id="productNumber" value={this.state.item.productNumber} onChange={this.updateProductCode}>
              <option value={0}>None</option>
              {this.props.productCodes.map((c: any) => {
                return <option value={c.productNumber} key={c.productNumber}>{c.productNumber} - {c.productCode} - {c.display}</option>
              })}
            </select>
          </div>
          <div className="col-2">
            <label className="rma-item-label">Description</label>
            <input type="text" className="form-control" id="productDescription" value={this.state.item.productDescription} onChange={this.updateField} />
          </div>
          <div className="col-1">
            <label className="rma-item-label">Qty</label>
            <input type="number" className="form-control" id="quantity" value={this.state.item.quantity} onChange={this.updateField} />
          </div>
          <div className="col-2">
            <label className="rma-item-label">Purchased</label>
            <select className="form-control" id="placeOfPurchase" value={this.state.item.placeOfPurchase} onChange={this.updateField}>
              <option value="direct">Direct</option>
              <option value="internal">Internal</option>
              <option value="third_party">Third Party</option>
              <option value="unknown">Unknown</option>
            </select>
          </div>
          {this.state.item.placeOfPurchase === "third_party" && (
            <div className="col-2">
              <label className="rma-item-label">Specify</label>
              <input type="text" className="form-control" id="placeOfPurchaseSpecify" value={this.state.item.placeOfPurchaseSpecify} onChange={this.updateField} />
            </div>
          )}
        </div>

        <div className="row row-margin-bottom">
          <div className="col-3">
            <label className="rma-item-label">Type</label>
            <select className="form-control" id="rmaType" value={this.state.item.rmaType} onChange={this.updateField}>
              <option value="return">Return</option>
              <option value="exchange">Exchange</option>
              <option value="other">Other</option>
            </select>
          </div>
          <div className="col-3">
            <label className="rma-item-label">Order</label>
            <input type="text" className="form-control" id="orderNumber" value={this.state.item.orderNumber} onChange={this.updateField} />
          </div>
          <div className="col-3">
            <label className="rma-item-label">Return To</label>
            <select className="form-control" id="returnTo" value={this.state.item.returnTo} onChange={this.updateField}>
              <option value="portsmouth">Portsmouth</option>
              <option value="warehouse">Warehouse</option>
              <option value="other">Other</option>
            </select>
          </div>
          <div className="col-3">
            <label className="rma-item-label">Label Provided</label>
            <select className="form-control" id="labelProvided" value={this.state.item.labelProvided} onChange={this.updateField}>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div>
        </div>

        <div className="row row-margin-bottom">
          <div className="col-3">
            <label className="rma-item-label">Return Code</label>
            <select className="form-control" id="returnCodeId" value={this.state.item.returnCodeId} onChange={this.updateField}>
              <option value={0}></option>
              {this.props.codes.map((code) => {
                return (<option key={code.id} value={code.id}>{code.display}</option>)
              })}
            </select>
          </div>
          <div className="col-3">
            <label className="rma-item-label">Primary Category</label>
            <select className="form-control" id="primaryCategoryId" value={this.state.item.primaryCategoryId} onChange={this.updateField}>
              <option value={0}></option>
              {this.props.categories.map((code) => {
                return (<option key={code.id} value={code.id}>{code.display}</option>)
              })}
            </select>
          </div>
          <div className="col-3">
            <label className="rma-item-label">Secondary Category</label>
            <select className="form-control" id="secondaryCategoryId" value={this.state.item.secondaryCategoryId} onChange={this.updateField}>
              <option value={0}></option>
              {this.props.categories.map((code) => {
                return (<option key={code.id} value={code.id}>{code.display}</option>)
              })}
            </select>
          </div>
          <div className="col-3">
            <label className="rma-item-label">Tertiary Category</label>
            <select className="form-control" id="tertiaryCategoryId" value={this.state.item.tertiaryCategoryId} onChange={this.updateField}>
              <option value={0}></option>
              {this.props.categories.map((code) => {
                return (<option key={code.id} value={code.id}>{code.display}</option>)
              })}
            </select>
          </div>
        </div>

        <div className="row row-margin-bottom">
          <div className="col-3">
            <label className="rma-item-label">Received</label>
            <select className="form-control" id="received" value={this.state.item.received} onChange={this.updateField}>
              <option value="no">No</option>
              <option value="yes">Yes</option>
            </select>
          </div>
          {this.state.item.received === "yes" && (
            <>            
              <div className="col-3">
                <label className="rma-item-label">On</label>
                <DatePicker date={this.state.item.receivedOn} onDateSaved={this.updateReceivedOn} />
              </div>
              <div className="col-3">
                <label className="rma-item-label">By</label>
                <input type="text" className="form-control" id="receivedByName" value={this.state.item.receivedByName} onChange={this.updateField} />
              </div>
              <div className="col-3">
                <label className="rma-item-label">Qty</label>
                <input type="number" className="form-control" id="quantityReceived" value={this.state.item.quantityReceived} onChange={this.updateField} />
                {this.state.item.quantity === this.state.item.quantityReceived ? (<strong className="text-success">Matches!</strong>) : (<strong className="text-danger">Doesn't Match!</strong>)}
              </div>
            </>
          )}
        </div>

        <div className="row row-margin-bottom">
          <div className="col-3">
            <label className="rma-item-label">Product Refund Due</label>
            <select className="form-control" id="productRefundDue" value={this.state.item.productRefundDue} onChange={this.updateField}>
              <option value="yes_full">Full</option>
              <option value="yes_partial">Partial</option>
              <option value="no">No</option>
              <option value="na">N/A</option>
            </select>
          </div>
          {(this.state.item.productRefundDue === "yes_full" || this.state.item.productRefundDue === "yes_partial") && (
            <>
              <div className="col-3">
                <label className="rma-item-label">Refunded?</label>
                <select className="form-control" id="productRefunded" value={this.state.item.productRefunded} onChange={this.updateField}>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>
              </div>
              <div className="col-3">
                <label className="rma-item-label">Amount</label>
                <input type="number" id="productRefundAmountInCents" className="form-control" value={this.state.item.productRefundAmountInCents} onChange={this.updateField} />
              </div>
              <div className="col-3">
                <label className="rma-item-label">ID</label>
                <input type="text" id="productRefundId" className="form-control" value={this.state.item.productRefundId} onChange={this.updateField} />
              </div>
              </>
          )}
        </div>

        <div className="row row-margin-bottom">
          <div className="col-3">
            <label className="rma-item-label">Subscription Refund Due</label>
            <select className="form-control" id="subscriptionRefundDue" value={this.state.item.subscriptionRefundDue} onChange={this.updateField}>
              <option value="yes_full">Full</option>
              <option value="yes_partial">Partial</option>
              <option value="no">No</option>
              <option value="na">N/A</option>
            </select>
          </div>
          {(this.state.item.subscriptionRefundDue === "yes_full" || this.state.item.subscriptionRefundDue === "yes_partial") && (
            <>
              <div className="col-3">
                <label className="rma-item-label">Refunded?</label>
                <select className="form-control" id="subscriptionRefunded" value={this.state.item.subscriptionRefunded} onChange={this.updateField}>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>
              </div>
              <div className="col-3">
                <label className="rma-item-label">Amount</label>
                <input type="number" id="subscriptionRefundAmountInCents" className="form-control" value={this.state.item.subscriptionRefundAmountInCents} onChange={this.updateField} />
              </div>
              <div className="col-3">
                <label className="rma-item-label">ID</label>
                <input type="text" id="subscriptionRefundId" className="form-control" value={this.state.item.subscriptionRefundId} onChange={this.updateField} />
              </div>
              </>
          )}
        </div>

        {this.props.mode === "edit" && (
          <div className="row row-margin-bottom">
            <div className="col-2">
              <label className="rma-item-label">Item Status</label>
              <input type="text" disabled={true} className="form-control" value={this.state.item.deviceStatus ? capitalizeFirstLetter(this.state.item.deviceStatus) : "Unknown"} />
            </div>
            <div className="col-2">
              <label className="rma-item-label">Billing Status</label>
              <input type="text" disabled={true} className="form-control" value={this.state.item.billingStatus ? capitalizeFirstLetter(this.state.item.billingStatus) : "Unknown"} />
            </div>
            <div className="col-2">
              <label className="rma-item-label">Plan</label>
              <input type="text" disabled={true} className="form-control" value={this.state.item.plan ? this.state.item.plan.display : "Unknown / NA"} />
            </div>
            <div className="col-2">
              <label className="rma-item-label">Should Deactivate</label>
              <select 
                id="shouldDeactivateInAdminPanel" 
                className={`form-control ${this.state.item.shouldDeactivateInAdminPanel === "yes" && this.state.item.deviceStatus === "active" ? "bg-danger" : "bg-success"}`} 
                value={this.state.item.shouldDeactivateInAdminPanel} 
                onChange={this.updateField}>
                <option value="no">No</option>
                <option value="yes">Yes</option>
              </select>
            </div>
            <div className="col-1" style={{paddingTop: 35, fontSize: 24}}>
              {this.props.isRefreshing ? (
                <Loading />
              ): (
                <FontAwesomeIcon icon={faSyncAlt} className="link text-success" onClick={this.requestRefresh} />
              )}
            </div>
            <div className="col-1" style={{paddingTop: 35, fontSize: 24}}>
              <Link to={`/support/devices/${this.state.item.deviceId}`} target={`_new_${this.state.item.deviceId}`}><FontAwesomeIcon icon={faEye} /></Link>
            </div>
          </div>
        )}

        <div className="row row-margin-bottom">
          <div className="col-6">
            <label className="rma-item-label">Notes</label>
            <textarea id="notes" className="form-control" value={this.state.item.notes} onChange={this.updateField} />
          </div>
          <div className="col-6">
            <label className="rma-item-label">Disposition</label>
            <textarea id="disposition" className="form-control" value={this.state.item.disposition} onChange={this.updateField} />
          </div>
        </div>

        {this.props.mode === "edit" ? (
          <div className="row row-margin-bottom">
            <div className="col-6">
              <button className="btn btn-block btn-success" disabled={!this.state.dirty} onClick={this.handleSave}>Save</button>
            </div>
            <div className="col-6">
              <button className="btn btn-block btn-danger" onClick={this.toggleShowDeleteWarningModal}>Delete</button>
            </div>
          </div>
        ) : (
          <div className="row row-margin-bottom">
            <div className="col-12">
              <button className="btn btn-block btn-success" disabled={!this.state.dirty}  onClick={this.checkBeforeSave}>Save</button>
            </div>
          </div>
        )}


        <Modal show={this.state.showDeleteWarningModal} onHide={this.toggleShowDeleteWarningModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              Create New RMA
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row" style={{ marginBottom: 10 }}>
              <div className="col-12">
                <p><strong className="text-danger">Warning:</strong> Are you sure you want to delete this RMA Item? This cannot be undone!</p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-block btn-danger" onClick={this.handleDelete} style={{ marginBottom: 10 }}>Delete</button>
            <button className="btn btn-block btn-primary" onClick={this.toggleShowDeleteWarningModal}>Close</button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.showSaveWarningModal} onHide={this.toggleShowSaveeWarningModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              Warning
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row" style={{ marginBottom: 10 }}>
              <div className="col-12">
                <p><strong className="text-danger">Warning:</strong> You entered a serial, but it could not be validated. We searched the devices for an existing device with that serial and could not find one. If this is pre-activation, this is to be expected. However, if this is a used device, you may want to verify the numbers and confirm with engineering before continuing.</p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-block btn-primary" onClick={this.handleSave} style={{ marginBottom: 10 }}>Save Anyway</button>
            <button className="btn btn-block btn-danger" onClick={this.toggleShowSaveeWarningModal}>Close</button>
          </Modal.Footer>
        </Modal>

      </div>
    )
  }

  updateField(e: any) {
    let ns: any = this.state.item;
    ns[e.target.id] = e.target.value;
    this.setState({ item: ns, dirty: true });
  }

  private updateReceivedOn(newDate: moment.Moment) {
    const item = this.state.item;
    item.receivedOn = newDate;
    this.setState({ item, dirty: true })
  }

  // this one is unique since we need to replace the description
  private updateProductCode(e: any){
    const val = parseInt(e.target.value);
    let description = this.state.item.productDescription;
    for(const c of this.props.productCodes){
      console.log(`${typeof c.productNumber} ${c.productNumber} === ${typeof val} ${val}`)
      if(c.productNumber === val){
        description = c.description;
        break;
      }
    }
    const item = this.state.item;
    item.productNumber = val;
    item.productDescription = description;
    this.setState({ item, dirty: true });
  }

  toggleShowSaveeWarningModal(){
    this.setState({ showSaveWarningModal: !this.state.showSaveWarningModal });
  }

  toggleShowDeleteWarningModal(){
    this.setState({ showDeleteWarningModal: !this.state.showDeleteWarningModal });
  }

  private checkBeforeSave(){
    // if a serial is present, make sure it's valid
    if(this.state.item.productId === ""){
      return this.handleSave()
    }
    this.setState({ loading: true }, async () => {
      try{
        await DeviceAPI.getDeviceByProductId("wagz2", this.state.item.productId);
        // for now we don't care about the product data
        return this.handleSave();
      }catch(err){
        this.setState({ loading: false, showSaveWarningModal: true, })
      }
    })
  }

  handleSave(){
    this.props.handleSave(this.state.item);
  }

  handleDelete(){
    this.props.handleDelete(this.state.item);
  }

  requestRefresh(){
    this.props.handleRefreshRequest(this.state.item);
  }

}