import React, { Component } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus } from '@fortawesome/free-solid-svg-icons';

import { error } from "src/components/structure/Alert";
import { Modal } from "react-bootstrap";

interface IQueryWizardProps {
  startingQuery?: any;
  onComplete: (data: any) => void;
  onRun: (data: any) => void;
  onCancel: () => void;
}

interface IQueryWizardState {
  loading: boolean;

  selectedEntity: "users" | "devices" | "pets";
  selectedDb: "" | "devices" | "metrics" | "pets" | "users";
  selectedAttribute: string;
  selectedComparator: "=" | ">" | ">=" | "<" | "<=";
  selectedValue: string;
  selectedCount: string;
  selectedSortDirection: "ASC" | "DESC";
  includeConnectedUsers: "yes" | "no";
  includeConnectedDevices: "yes" | "no";
  includeConnectedPets: "yes" | "no";
  name: string;

  attributes: any[];
  showNoAttributeWarning: boolean;
  showUnsavedAttributeWarning: boolean;

  desiredAction: "save" | "run";
}


export default class QueryWizard extends Component<
  IQueryWizardProps,
  IQueryWizardState
> {
  constructor(props: IQueryWizardProps) {
    super(props);
    this.state = {
      loading: true,
      selectedEntity: "users",
      selectedDb: "",
      selectedAttribute: "",
      selectedComparator: "=",
      selectedValue: "",
      selectedCount: "100",
      selectedSortDirection: "DESC",
      includeConnectedDevices: "no",
      includeConnectedPets: "no",
      includeConnectedUsers: "no",

      attributes: [],
      name: "My Query",

      showNoAttributeWarning: false,
      showUnsavedAttributeWarning: false,
      desiredAction: "save",
    };

    this.setup = this.setup.bind(this);
    this.updateField = this.updateField.bind(this);
    this.addAttributeToList = this.addAttributeToList.bind(this);
    this.removeFromIndex = this.removeFromIndex.bind(this);
    this.checkQuery = this.checkQuery.bind(this);
    this.formatQuery = this.formatQuery.bind(this);
    this.saveQuery = this.saveQuery.bind(this);
    this.runQuery  = this.runQuery.bind(this);
    this.toggleShowNoAttributeWarning = this.toggleShowNoAttributeWarning.bind(this);
    this.toggleShowUnsavedAttributeWarning = this.toggleShowUnsavedAttributeWarning.bind(this);
  }

  componentDidMount() {
    this.setup();
  }

  render() {
    return (
      <div>
        <div className="row" style={{ marginBottom: 25 }}>
          <div className="col-3">
            <label>Name This Query</label>
            <input type="text" className="form-control" value={this.state.name} id="name" onChange={this.updateField} />
          </div>
          <div className="col-3">
            <label>Get the</label>
            <select className="form-control" value={this.state.selectedEntity} id="selectedEntity" onChange={this.updateField}>
              <option value="devices">Devices</option>
              <option value="users">Users</option>
              <option value="pets">Pets</option>
            </select>
          </div>
          {this.state.selectedEntity !== "pets" && (<div className="col-3">
            <label>Include Connected Pets?</label>
            <select className="form-control" value={this.state.includeConnectedPets} id="includeConnectedPets" onChange={this.updateField}>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div>)}
          {this.state.selectedEntity !== "users" && (<div className="col-3">
            <label>Include Connected Users?</label>
            <select className="form-control" value={this.state.includeConnectedUsers} id="includeConnectedUsers" onChange={this.updateField}>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div>)}
          {this.state.selectedEntity !== "devices" && (<div className="col-3">
            <label>Include Connected Devices?</label>
            <select className="form-control" value={this.state.includeConnectedDevices} id="includeConnectedDevices" onChange={this.updateField}>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div>)}
        </div>

        <div className="row" style={{ marginBottom: 15 }}>
          <div className="col-3">
            <label>Where in the Database</label>
            <select className="form-control" value={this.state.selectedDb} id="selectedDb" onChange={this.updateField}>
              <option value="" disabled={true}>Select a DB</option>
              <option value="devices">Devices</option>
              {/* <option value="metrics">Metrics</option> */}
              <option value="pets">Pets</option>
              <option value="users">Users</option>
            </select>
          </div>
          {this.state.selectedDb !== "" && (
            <>
              <div className="col-3">
                <label>The Attribute</label>
                <select className="form-control" value={this.state.selectedAttribute} id="selectedAttribute" onChange={this.updateField}>
                  <option value="" disabled={true}>Select Attribute</option>
                  {allowedAttributes[this.state.selectedDb].map((a: any, i: number) => {
                    return <option value={a.field} key={i}>{a.display}</option>
                  })}
                </select>
              </div>
              <div className="col-1">
                <label>Is</label>
                <select className="form-control" value={this.state.selectedComparator} id="selectedComparator" onChange={this.updateField}>
                  <option value="=">=</option>
                  <option value=">">&gt;</option>
                  <option value=">=">&gt;=</option>
                  <option value="<">&lt;</option>
                  <option value="<=">&lt;=</option>
                </select>
              </div>
              <div className="col-3">
                <label>The Value</label>
                <input type="text" className="form-control" value={this.state.selectedValue} id="selectedValue" onChange={this.updateField} />
              </div>
              <div className="col-2">
                <button className="btn btn-block btn-primary" style={{ height: "100%" }} onClick={this.addAttributeToList}>Add</button>
              </div>
            </>
          )}

        </div>

        <div className="row">
          <div className="col-12"><hr /></div>
        </div>
        <div className="row">
          <div className="col-12" style={{textAlign: "center"}}><strong>Current Filters</strong></div>
        </div>

        {this.state.attributes.map((i: any, index: number) => {
          return (
            <div className="row" key={index}>
              <div className="col-3">
                {i.display}
              </div>
              <div className="col-3">
                {i.comparator}
              </div>
              <div className="col-3">
                {i.value}
              </div>
              <div className="col-2">
                AND
              </div>
              <div className="col-1">
                <span className="text-danger" onClick={() => this.removeFromIndex(index)}><FontAwesomeIcon icon={faMinus} /></span>
              </div>
            </div>
          )
        })}

        <div className="row">
          <div className="col-12"><hr /></div>
        </div>

        <div className="row" style={{ marginBottom: 15 }}>
          <div className="col-3">
            <label>Limited to</label>
            <input type="text" className="form-control" value={this.state.selectedCount} id="selectedCount" onChange={this.updateField} />
          </div>
          <div className="col-3">
            <label>Ordered By</label>
            <select className="form-control" value={this.state.selectedSortDirection} id="selectedSortDirection" onChange={this.updateField}>
              <option value="ASC">Ascending</option>
              <option value="DESC">Descending</option>
            </select>
          </div>
        </div>


        <div className="row">
          <div className="col-6">
            <button className="btn btn-block btn-primary" onClick={() => this.checkQuery("save")}>Save</button>
          </div>
          <div className="col-3">
            <button className="btn btn-block btn-danger" onClick={() => this.checkQuery("run")}>Run Without Saving</button>
          </div>
          {this.props.startingQuery && this.props.startingQuery.id && (
            <div className="col-3">
              <button className="btn btn-block btn-danger">Delete</button>
            </div>
          )}
        </div>    


        <Modal
            show={this.state.showUnsavedAttributeWarning}
            onHide={this.toggleShowUnsavedAttributeWarning}
          >
          <Modal.Header closeButton>
            <Modal.Title>
              Warning!
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <strong className="text-danger">Warning: </strong> You started entering data on an attribute but did not add it to the query. Was this intentional?
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-block btn-danger" onClick={this.state.desiredAction === "save" ? this.saveQuery : this.runQuery}>Yes, That Is My Intent</button>
          </Modal.Footer>
        </Modal>


        <Modal
            show={this.state.showNoAttributeWarning}
            onHide={this.toggleShowNoAttributeWarning}
          >
          <Modal.Header closeButton>
            <Modal.Title>
              Warning!
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <strong className="text-danger">Warning: </strong> You are saving your query without any attributes. Are you absolutely sure that's intended? That has the potential to
            be very large, the equivalent of a database dump. Please confirm that is your intent.
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-block btn-danger" onClick={this.state.desiredAction === "save" ? this.saveQuery : this.runQuery}>Yes, That Is My Intent</button>
          </Modal.Footer>
        </Modal>


      </div>
    );
  }

  private updateField(e: any) {
    const ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private setup() {
    if(!this.props.startingQuery || !this.props.startingQuery.query){
      return;
    }
    this.setState({ loading: true }, async () => {
      try {
        const data: any = { 
          loading: false,
          name: this.props.startingQuery.name ? this.props.startingQuery.name : "My Query",
          selectedEntity: this.props.startingQuery.entity,
          includeConnectedDevices: this.props.startingQuery.query.includeDevices ? "yes" : "no", 
          includeConnectedPets: this.props.startingQuery.query.includePets ? "yes" : "no", 
          includeConnectedUsers: this.props.startingQuery.query.includeUsers ? "yes" : "no", 
          attributes: this.props.startingQuery.query.attributes,
          selectedCount: this.props.startingQuery.query.count ? this.props.startingQuery.query.count : "1000",
          selectedSortDirection: this.props.startingQuery.query.sortDir ? this.props.startingQuery.query.sortDir : "ASC",
        };
        // loop over the attributes
        for(const at of data.attributes){
          // find the display
          if(at.display && at.display !== ""){
            continue;
          }
          const parts = at.attribute.split(".");
          if(parts.length < 2){
            continue;
          }
          let db = "";
          switch(parts[0]){
            case "d":
              db = "devices";
              break;
            case "m":
              db = "metrics";
              break;
            case "p":
              db = "pets";
              break;
            case "u":
              db = "users";
              break;
          }
          let display = "";
          for(const aa of allowedAttributes[db]){
            if(aa.field === parts[1]){
              display = aa.display;
              break;
            }
          }
          at.display = display;
        }
        this.setState(data);
      } catch (err) {
        this.setState({ loading: false });
      }
    })
  }

  private addAttributeToList() {
    const data: any = {
      attribute: this.state.selectedAttribute,
      comparator: this.state.selectedComparator,
      value: this.state.selectedValue,
      display: this.state.selectedAttribute,
      db: this.state.selectedDb,
    };

    if (data.value === "" || data.attribute === "") {
      return error("You must provide a value and an attribute");
    }

    // we need to prefix the db
    switch(data.db){
      case "devices":
        data.attribute = "d." + data.attribute;
        break;
      case "pets":
        data.attribute = "p." + data.attribute;
        break;
      case "metrics":
        data.attribute = "m." + data.attribute;
        break;
      case "users":
        data.attribute = "u." + data.attribute;
        break;
    }

    for(const a of allowedAttributes[this.state.selectedDb]){
      if(a.field === this.state.selectedAttribute){
        data.display = a.display;
        break;
      }
    }
    const attributes = this.state.attributes;
    attributes.push(data);
    this.setState({ attributes, selectedAttribute: "", selectedComparator: "=", selectedValue: "" });
  }

  private removeFromIndex(index: number){
    const iterators: any[] = [];
    for(let i = 0; i < this.state.attributes.length; i++){
      if(i !== index){
        iterators.push(this.state.attributes[i]);
      }
    }
    this.setState({ attributes: iterators });
  }


  private checkQuery(desiredAction: "save" | "run"){
    if(this.state.attributes.length === 0){
      return this.setState({ desiredAction, showNoAttributeWarning: true });
    }
    if(this.state.selectedAttribute !== "" || this.state.selectedValue !== ""){
      return this.setState({ desiredAction, showUnsavedAttributeWarning: true });
    }
    if(desiredAction === "save"){
      this.saveQuery();
    }else{
      this.runQuery();
    }
  }

  private formatQuery(){
    // build the query and return
    const count = parseInt(this.state.selectedCount);
    const query: any = {
      name: this.state.name,
      entity: this.state.selectedEntity,
      includeDevices: this.state.includeConnectedDevices === "yes",
      includePets: this.state.includeConnectedPets === "yes",
      includeUsers: this.state.includeConnectedUsers === "yes",
      attributes: this.state.attributes,
      count: isNaN(count) ? 100 : count,
      sortDir: this.state.selectedSortDirection
    }
    return query;
  }


  private saveQuery(){
    const query = this.formatQuery();
    this.props.onComplete(query);
  }

  private runQuery(){
    const query = this.formatQuery();
    this.props.onRun(query);
  }

  private toggleShowNoAttributeWarning(){
    this.setState({ showNoAttributeWarning: !this.state.showNoAttributeWarning});
  }

  private toggleShowUnsavedAttributeWarning(){
    this.setState({ showUnsavedAttributeWarning: !this.state.showUnsavedAttributeWarning});
  }
}


const allowedAttributes = {
  "devices": [
    {
      "field": "id",
      "display": "Device Id",
    },
  ],
  "pets": [
    {
      "field": "id",
      "display": "Pet Id",
    },
  ],
  "users": [
    {
      "field": "id",
      "display": "User Id",
    },
  ],
}