import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Card, Screen } from "src/components/structure";

import * as UserActions from "src/reducers/userReducer";
import { JobsAPI } from "src/api";
import { makeUrl } from "src/api/client";
import { BlankJob, IJob } from "src/api/jobs";
import moment from "moment";
import { capitalizeFirstLetter } from "src/utils/utilities";

interface IJobViewScreenProps {
  userState: any;
  match: any;
}

interface IJobViewScreenState {
  loading: boolean;
  job: IJob;
}

const app = "wagz2";

class JobViewScreen extends Component<
  IJobViewScreenProps,
  IJobViewScreenState
> {
  constructor(props: IJobViewScreenProps) {
    super(props);
    this.state = {
      loading: true,
      job: BlankJob,
    };

    this.fetch = this.fetch.bind(this);
  }

  componentDidMount(){
    this.fetch();
  }

  render() {
    return (
      <Screen shouldAuthCheck={true} requiredRoles={["reporting"]}>
        <Card title="Job">
          <div className="row row-margin-bottom">
            <div className="col-4">
              <label className="label-bold">Name</label>
              {JobsAPI.translateKeyToDisplay(this.state.job.name)}
            </div>
            <div className="col-4">
              <label className="label-bold">Requested On</label>
              {this.state.job.requestedOn}
            </div>
            <div className="col-4">
              <label className="label-bold">Last Updated On</label>
              {this.state.job.statusLastUpdatedOn}
            </div>
          </div>

          <div className="row row-margin-bottom">
            <div className="col-4">
              <label className="label-bold">Handling Service</label>
              {capitalizeFirstLetter(this.state.job.targetService.split("-")[0])}
            </div>
            <div className="col-4">
              <label className="label-bold">Status</label>
              {capitalizeFirstLetter(this.state.job.status)}
            </div>
            <div className="col-4">
              <label className="label-bold">Status Message</label>
              {this.state.job.statusMessage}
            </div>
          </div>


          <div className="row row-margin-bottom">
            <div className="col-4">
              <label className="label-bold">Requested By</label>
              {this.state.job.requestingUser}
            </div>
            <div className="col-4">
              <label className="label-bold">Notification Type</label>
              {capitalizeFirstLetter(this.state.job.notificationType)}
            </div>
            <div className="col-4">
              <label className="label-bold">Notification Target</label>
              {this.state.job.notificationTarget}
            </div>
          </div>


          <div className="row row-margin-bottom">
            <div className="col-4">
              <label className="label-bold">Output Type</label>
              {this.state.job.outputType === "inline" ? "Inline" : this.state.job.outputType.toUpperCase()}
            </div>
            {this.state.job.outputType !== "inline" && this.state.job.outputLocation !== "" && (
              <div className="col-8">
                <label className="label-bold">Download Link</label>
                {<a href={this.state.job.downloadLink} target="_new">{this.state.job.downloadLink}</a>}
              </div>
            )}
          </div>

        </Card>
        {this.state.job.outputType === "inline" && this.state.job.outputLocation !== "" && (
          <Card title="Output">
            <textarea className="form-control" disabled={true} rows={20} style={{width: "100%"}} value={this.state.job.outputLocation} />
          </Card>
        )}
      </Screen>
    );
  }

  private fetch(){
    this.setState({ loading: true }, async () => {
      try{
        const result = await JobsAPI.getJobById(app, this.props.match.params.jobId);
        const j: IJob = result.body.data;
        j.requestedOn = moment(j.requestedOn).format("MM/DD/YY h:mm A");
        j.statusLastUpdatedOn = moment(j.statusLastUpdatedOn).format("MM/DD/YY h:mm A");
        j.downloadLink = makeUrl(`admin/wagz2/jobs/${j.id}/download`)
        this.setState({ loading: false, job: j});
      }catch(err){
        this.setState({ loading: false });
      }
    });
  }

}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(JobViewScreen) as any
);
