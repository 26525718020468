import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faEye } from '@fortawesome/free-solid-svg-icons';
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Modal } from "react-bootstrap";

import Card from "src/components/structure/Card";
import Screen from "src/components/structure/Screen";
import { OTAAPI } from "src/api";
import { capitalizeFirstLetter, uniqueStringArray } from "src/utils/utilities";
import { error } from "src/components/structure/Alert";
import { Alert, Loading, Table } from "src/components/structure";
import moment from "moment";

interface IOTACDownloadsProps {
  userState: any;
}

interface IOTADownloadsState {
  loading: boolean;
  channelsList: any[];
  channelsMapping: any;

  knownDeviceTypes: string[];
  knownDeviceFamilies: string[];


  selectedChannel: string;
  selectedDeviceType: string;
  selectedFamily: string;

  showDownloadModal: boolean;

  isDownloading: boolean;
}

class OTADownloads extends Component<IOTACDownloadsProps, IOTADownloadsState> {
  constructor(props: IOTACDownloadsProps) {
    super(props);

    this.state = {
      loading: false,
      channelsList: [],
      channelsMapping: {},
      knownDeviceFamilies: [],
      knownDeviceTypes: [],
      selectedChannel: "",
      selectedDeviceType: "",
      selectedFamily: "",
      showDownloadModal: false,
      isDownloading: false,

    };

    this.updateField = this.updateField.bind(this);
    this.fetchChannels = this.fetchChannels.bind(this);
    this.downloadSelectedFile = this.downloadSelectedFile.bind(this);
    this.selectChannelFromTable = this.selectChannelFromTable.bind(this);
    this.toggleShowDownloadModal = this.toggleShowDownloadModal.bind(this);
    this.clearFirmwareCache = this.clearFirmwareCache.bind(this);
  }

  componentDidMount() {
    this.fetchChannels();
  }

  render() {

    return (
      <Screen id="firmware_otas" loading={this.state.loading} requiredRoles={["qa", "firmware"]}>
        <div className="row" style={{marginBottom: 20}}>
          <div className="col-10 offset-1">
            <Card>
              <div className="row">
                <div className="col-6">
                  <p>
                    Here you can look at all available downloadable images that we have maintained. This is a <strong>different</strong> than what may be available to customers. Starting in July 2022, we started having the CI/CD pipeline generate additional debugging files on build and upload those to S3. Given the nature of these files, they needed to be in a locked down environment in production. Therefore, you cannot edit or delete these, only an AWS S3 admin can.
                  </p>
                </div>
                <div className="col-6">
                  <p style={{marginTop: 20}}>Not seeing the channel? If it is recent, you can hit the button below to clear the cache and update the list. Warning: this can have performance implications if done frequently, so please be carefule!</p>
                  <button className="btn btn-block btn-danger" onClick={this.clearFirmwareCache}>Clear Cache</button>
                </div>
              </div>
            </Card>
          </div>          
        </div>

        <div className="row">
          <div className="col-12">

          <Tabs>
              <TabList>
                <Tab>Drill Down</Tab>
                <Tab>As Table</Tab>
              </TabList>
              <TabPanel>
                <div className="row">
                  <div className="col-4">
                    <Card title="Device Info">
                      <label>Device Type and Family</label>
                      <select id="selectedDeviceType" className="form-control" value={this.state.selectedDeviceType} onChange={this.updateField}>
                        <option value="" disabled={true}>Select</option>
                        {this.state.knownDeviceTypes.map((d) => {
                          return <option value={d} key={d}>{capitalizeFirstLetter(d)}</option>
                        })}
                      </select>
                      {this.state.selectedDeviceType !== "" && (
                        <select id="selectedFamily" className="form-control" value={this.state.selectedFamily} onChange={this.updateField}>
                          <option value="" disabled={true}>Select</option>
                          {Object.keys(this.state.channelsMapping[this.state.selectedDeviceType]).map((family) => {
                            return <option value={family} key={family}>{family}</option>
                          })}
                        </select>
                      )}
                    </Card>            
                  </div>          
                  {this.state.selectedFamily !== "" && (
                    <div className="col-4">
                      <Card title="Available Channels">
                        <label>Channels</label>
                        <select id="selectedChannel" className="form-control" value={this.state.selectedChannel} onChange={this.updateField}>
                          <option value="" disabled={true}>Select</option>
                          {Object.keys(this.state.channelsMapping[this.state.selectedDeviceType][this.state.selectedFamily]).map((branch, i) => {
                            const info = this.state.channelsMapping[this.state.selectedDeviceType][this.state.selectedFamily][branch];
                            return <option key={i} value={branch}>{branch} ({info.lastUpdated}) ({info.files.length} files)</option>
                          })}
                        </select>
                      </Card>
                    </div>
                  )}
                  {this.state.selectedFamily !== "" && this.state.selectedChannel !== "" && (
                    <div className="col-4">
                      <Card title="Available Files">
                        <p>Downloading any of these files will be logged!</p>
                        {this.state.channelsMapping[this.state.selectedDeviceType][this.state.selectedFamily][this.state.selectedChannel]["files"].map((f: any) => {
                          return (<p key={f} className="link" onClick={() => this.downloadSelectedFile(f)} ><FontAwesomeIcon icon={faDownload} /> {f}</p>)
                        })}
                        {this.state.isDownloading && (
                          <div>
                            <Loading />
                          </div>
                        )}
                      </Card>
                    </div>
                  )}
                </div>
              </TabPanel>
              
              <TabPanel>
                <div className="row">
                  <div className="col-12">
                    <Table
                      title="OTA Files"
                      csvFileName={`ota-files-list-as-of-${moment().format("YYYY-MM-DDTHH:mm:ss")}`}
                      showDownload={true}
                      columns={[
                        {
                          "label": "Device Type",
                          "field": "deviceType",
                        },
                        {
                          "label": "Family",
                          "field": "family",
                        },
                        {
                          "label": "Channel",
                          "field": "branch",
                        },
                        {
                          "label": "Last Updated",
                          "field": "lastUpdated",
                        },
                        {
                          "label": "Files",
                          "field": "view",
                        },
                      ]}
                      rows={this.state.channelsList}
                    />
                  </div>
                </div>

                <Modal
                  show={this.state.showDownloadModal}
                  onHide={this.toggleShowDownloadModal}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>
                      Download Files
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <p>Downloading any of these files will be logged!</p>
                    {this.state.selectedChannel !== "" && this.state.channelsMapping[this.state.selectedDeviceType][this.state.selectedFamily][this.state.selectedChannel]["files"].map((f: any) => {
                      return (<p key={f} className="link" onClick={() => this.downloadSelectedFile(f)} ><FontAwesomeIcon icon={faDownload} /> {f}</p>)
                    })}
                    {this.state.isDownloading && (
                      <div>
                        <Loading />
                      </div>
                    )}
                  </Modal.Body>
                </Modal>

              </TabPanel>
            </Tabs>
          </div>
        </div>


        
      </Screen>
    )
  }


  updateField(e: any) {
    let ns: any = this.state;
    const id = e.target.id;
    const val = e.target.value;
    ns[id] = val;
    this.setState(ns);
  }

  private fetchChannels() {
    this.setState({ loading: true }, async () => {
      try {
        const result = await OTAAPI.getDownloadableOTAFiles({});
        const channelsList: any[] = [];
        const allDeviceFamilies: string[] = [];
        const allDeviceTypes: string[] = [];

        const d = result.body.data;
        for(const deviceType of Object.keys(d)){
          allDeviceTypes.push(deviceType);
          for(const family of Object.keys(d[deviceType])){
            allDeviceFamilies.push(family);
            for(const branch of Object.keys(d[deviceType][family])){
              const entry = {
                deviceType,
                family,
                branch,
                files: d[deviceType][family][branch]["files"],
                lastUpdated: d[deviceType][family][branch]["lastUpdated"],
                view: (<FontAwesomeIcon icon={faEye} className="link" onClick={() => this.selectChannelFromTable(
                  deviceType, family, branch
                )} />)
              };
              channelsList.push(entry);
            }
          }
        }
        const knownDeviceTypes = uniqueStringArray(allDeviceTypes);
        const knownDeviceFamilies = uniqueStringArray(allDeviceFamilies);
        this.setState({loading: false, channelsMapping: d, channelsList, knownDeviceFamilies, knownDeviceTypes });
      } catch (err) {
        this.setState({ loading: false });
      }
    })
  }

  private async downloadSelectedFile(file: string){
    // build the call with the selected data; note the extra options to trigger a download
    this.setState({ isDownloading: true }, async () => {
      try{
        await OTAAPI.downloadOTAFile(this.state.selectedDeviceType, this.state.selectedFamily, this.state.selectedChannel, file, {}, { asDownload: true, fileName: file });
      } catch(err) {
        error("File could not be downloaded, please contact an admin.")
      } finally {
        this.setState({ isDownloading: false });
      }
    })
  }

  private selectChannelFromTable(deviceType: string, family: string, channel: string){
    this.setState({ 
      selectedDeviceType: deviceType,
      selectedFamily: family,
      selectedChannel: channel,
      showDownloadModal: true,
    })
  }

  private toggleShowDownloadModal(){
    this.setState({ showDownloadModal: !this.toggleShowDownloadModal});
  }

  private clearFirmwareCache(){
    this.setState({ loading: true }, async () => {
      try {
        await OTAAPI.clearOTACacheForAuthenticated();
      } catch (err) {
        Alert.error(`Could not reset auth key`);
      }
      this.fetchChannels();
    });
  }

}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps() {
  return {};
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OTADownloads) as any
);
