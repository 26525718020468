import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Screen from "src/components/structure/Screen";
import * as AppActions from "src/reducers/appReducer";
import * as Alert from "src/components/structure/Alert";
import { Modal, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan } from "@fortawesome/free-solid-svg-icons";
import { styles } from "src/styles";
import { UserAPI } from "src/api";
import { capitalizeFirstLetter } from "src/utils/utilities";

interface IUserOTPScreenProps {
  userId: number;
}

interface IUserOTPScreenState {
  loading: boolean;
  otpFound: boolean;
  selectedMethod: string;
  methods: any;
  showModalForDelete: boolean;
}

class UserOTPScreen extends Component<
  IUserOTPScreenProps,
  IUserOTPScreenState
> {
  constructor(props: IUserOTPScreenProps) {
    super(props);
    this.state = {
      loading: false,
      otpFound: false,
      selectedMethod: "",
      methods: [],
      showModalForDelete: false,
    };

    this.getOTP = this.getOTP.bind(this);
    this.deleteOTP = this.deleteOTP.bind(this);
  }

  componentDidMount() {
    this.getOTP();
  }

  getOTP() {
    this.setState({ loading: true }, async () => {
      try {
        const result = await UserAPI.getUserOTP("wagz2", this.props.userId);
        this.setState({
          loading: false,
          methods: result.body.data,
          otpFound: true,
        });
      } catch (err) {
        console.log("Could not fetch any otp");
        this.setState({ loading: false });
      }
    });
  }

  deleteOTP() {
    this.setState({ loading: true }, async () => {
      try {
        await UserAPI.deleteOTPMethodForUser(
          "wagz2",
          this.props.userId,
          this.state.selectedMethod
        );
        return this.setState(
          { loading: false, selectedMethod: "", showModalForDelete: false },
          () => {
            Alert.success("OTP Method removed successfully");
            this.getOTP();
          }
        );
      } catch (err) {
        this.setState({
          loading: false,
          showModalForDelete: false,
        });
      }
    });
  }

  showDeleteModal(input: string) {
    this.setState({
      showModalForDelete: true,
      selectedMethod: input,
    });
  }

  public render() {
    return (
      <Screen fileName="UserOTP.tsx" requiredRoles={["support"]}>
        {this.state.methods.length === 0 && (
          <div>
            <p>User has not enabled any OTP methods</p>
          </div>
        )}
        {this.state.methods.length > 0 && (
          <div>
            <Table striped bordered hover>
              <thead style={{ textAlign: "center" }}>
                <th>Method</th>
                <th>Status</th>
                <th>Actions</th>
              </thead>
              <tbody>
                {this.state.methods.map((e: any) => {
                  return (
                    <tr>
                      <td>{capitalizeFirstLetter(e.method)}</td>
                      <td>{capitalizeFirstLetter(e.status)}</td>
                      <td style={{ textAlign: "center" }}>
                        <FontAwesomeIcon
                          icon={faBan}
                          onClick={() => this.showDeleteModal(e.method)}
                          className="text-danger"
                        />
                      </td>
                      <div style={styles.divider} />
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        )}
        <div>
          <Modal
            show={this.state.showModalForDelete}
            onHide={() => {
              this.setState({ showModalForDelete: false });
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                Deleting <strong>{this.state.selectedMethod}</strong> OTP method
                for user {this.props.userId}...
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                Are you sure you want to permanently delete{" "}
                {this.state.selectedMethod} OTP for this user?
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn btn-block btn-submit"
                style={styles.button}
                onClick={this.deleteOTP}
              >
                Delete {this.state.selectedMethod} OTP method for user
              </button>
            </Modal.Footer>
          </Modal>
        </div>
      </Screen>
    );
  }
}

const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserOTPScreen);
