export const styles: any = {
  actionWord: {
    backgroundColor: "#dee2e6",
    color: "#32325d",
    borderRadius: 8,
    border: "1px solid #ffffff",
    padding: 8,
  },
  adminButton: {
    margin: 5,
  },
  adminRow: {
    backgroundColor: "white",
    borderRadius: 12,
    border: "1px solid #cfd1d2",
    margin: 15,
    opacity: 1,
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 5,
    boxShadow: "0px 2px 4px 4px #cfd1d2",
  },
  borderBottom: {
    borderBottom: "thick double #11cdef",
    marginBottom: 8,
  },
  changePW: {
    backgroundColor: "#f6f9fc",
    color: "#32325d",
    borderRadius: 8,
    border: "1px solid #e9ecef",
    padding: 8,
  },
  checkboxBox: {
    display: "flex",
    marginBottom: 15,
    marginTop: 15,
    fontSize: 18,
    borderRadius: 8,
    border: "1px solid #e9ecef",
    boxShadow: "0px 1px 2px 2px #e0e0e0",
    justifyContent: "center",
    alignItems: "center",
  },
  cluster: {
    width: "16px",
    height: "16px",
    backgroundColor: "#11cdef",
    borderRadius: "50%",
    textAlign: "center",
    paddingTop: 2,
  },
  divider: {
    margin: "8px 0",
    height: 1,
    backgroundColor: "#e0e0e0",
  },
  geofenceName: {
    fontSize: 20,
    textAlign: "center",
    fontWeight: "bolder",
    border: "1px solid #e0e0e0",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    backgroundColor: "#e0e0e0",
  },
  itemAlign: {
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loginStyles: {
    marginTop: 80,
    width: 460,
    marginBottom: 100,
  },
  manageBoxes: {
    height: 280,
    borderRadius: 12,
    border: "1px solid #000000",
    margin: 20,
  },
  marker: {
    width: "40px",
    height: "40px",
    backgroundColor: "#172b4d",
    borderRadius: "50%",
    textAlign: "center",
    paddingTop: 6,
  },
  permission: {
    color: "#ffffff",
  },
  profileSpan: {
    color: "#32325d",
    fontSize: 18,
    fontWeight: "bold",
    paddingLeft: 5,
  },
  profileSpanCaps: {
    color: "#32325d",
    fontSize: 18,
    fontWeight: "bold",
    paddingLeft: 5,
    textTransform: "capitalize",
  },
  pwHeaderText: {
    color: "#32325d",
    fontWeight: "bold",
    fontSize: 16,
    textAlign: "center",
    marginTop: 4,
  },
  shadowBox: {
    borderRadius: 3,
    border: "1px solid #000000",
    paddingTop: 5,
    paddingLeft: 5,
    boxShadow: "0px 2px 4px 4px #cfd1d2",
  },
  tables: {
    color: "#000000",
    fontWeight: "400",
    fontSize: 22,
    fontFamily: "Arial, Helvetica, sans-serif",
  },
  tableHead: {
    fontSize: 15,
    fontWeight: "bolder",
    backgroundColor: "#172b4d",
    fontColor: "#ffffff",
  },
  tCellWidth: {
    width: "25%",
  },
  thead: {
    textAlign: "center",
    color: "#ffffff",
  },
};
