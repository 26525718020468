import React, { Component } from "react";
import { Table, Screen, DatePicker, PieChart } from "src/components/structure";
import moment from "moment";
import Card from "src/components/structure/Card";
import { AnalyticsAPI } from "src/api";
import { LineGraph } from "src/components/structure/LineGraph";

interface IAnalyticGithubEventsScreenProps {
}

interface IAnalyticGithubEventsScreenState {
  loading: boolean;
  events: any[];
  csvEvents: any[];
  start: moment.Moment;
  end: moment.Moment;
  count: string;
  offset: string;
  selectedFilter: string;
  filteredRepository: string;
  filteredUser: string;
  

  repoPieData: any[];
  userPieData: any[];
  eventPieData: any[];
  repoLineData: any[];
  userLineData: any[];
  eventLineData: any[];

  groupedPieData: any[];
  groupedLineData: any[];

  format: "individual" | "group";
  unit: "day" | "hour";
  formatChanged: boolean;

  allUsers: string[];
  allRepos: string[];
}

const app = "wagz2";
const individualCols: any[] = [
  {
    label: "Posted",
    field: "posted"
  },
  {
    label: "User",
    field: "user"
  },
  {
    label: "Repo",
    field: "repository"
  },
  {
    label: "Event",
    field: "event"
  },
  {
    label: "Action",
    field: "action"
  },

];
const groupCols: any[] = [
  {
    label: "Time",
    field: "posted"
  },
  {
    label: "Event",
    field: "value"
  },
  {
    label: "Count",
    field: "count"
  },
];

export class AnalyticGithubEventsScreen extends Component<
  IAnalyticGithubEventsScreenProps,
  IAnalyticGithubEventsScreenState
> {
  constructor(props: IAnalyticGithubEventsScreenProps) {
    super(props);

    this.state = {
      loading: true,
      events: [],
      csvEvents: [],
      start: moment().subtract(7, "days"),
      end: moment(),
      count: "1000",
      offset: "0",
      selectedFilter: "none",
      filteredRepository: "all",
      filteredUser: "all",
      format: "individual",
      unit: "hour",
      formatChanged: false,

      allRepos: [],
      allUsers: [],


      repoPieData: [],
      repoLineData: [],
      userLineData: [],
      userPieData: [],
      eventPieData: [],
      eventLineData: [],

      groupedLineData: [],
      groupedPieData: [],
    };

    this.updateField = this.updateField.bind(this);
    this.updateStart = this.updateStart.bind(this);
    this.updateEnd = this.updateEnd.bind(this);
    this.setup = this.setup.bind(this);
    this.getEvents = this.getEvents.bind(this);
  }


  componentDidMount() {
    this.setup();
  }

  // so, unlike the generic events, the graphs ARE dependent on the grouping

  render() {
    return (
      <Screen shouldAuthCheck={true} requiredRoles={["reporting", "product", "support"]} loading={this.state.loading}>
        <Card title="Filters">
          <div className="row">
            <div className="col-2">
              <label>Start</label>
              <DatePicker date={this.state.start} onDateSaved={this.updateStart} />
            </div>
            <div className="col-2">
              <label>End</label>
              <DatePicker date={this.state.end} onDateSaved={this.updateEnd} />
            </div>
            <div className="col-2">
              <label>Format</label>
              <select className="form-control" value={this.state.format} id="format" onChange={this.updateField}>
                <option value="individual">Individual</option>
                <option value="group">Group</option>
              </select>
            </div>
            {this.state.format === "individual" && (
              <>
                <div className="col-1">
                  <label>Count</label>
                  <input type="text" className="form-control" id="count" value={this.state.count} onChange={this.updateField} />
                </div>
                <div className="col-1">
                  <label>Offset</label>
                  <input type="text" className="form-control" id="offset" value={this.state.offset} onChange={this.updateField} />
                </div>
                <div className="col-2">
                  <label>Filter</label>
                  <select className="form-control" value={this.state.selectedFilter} id="selectedFilter" onChange={this.updateField}>
                    <option value="none">None</option>
                    <option value="user">User</option>
                    <option value="repository">Repository</option>
                  </select>
                </div>
                {this.state.selectedFilter === "user" && (
                  <div className="col-2">
                    <label>User</label>
                    <select className="form-control" value={this.state.filteredUser} id="filteredUser" onChange={this.updateField}>
                      <option value="all">All</option>
                      {this.state.allUsers.map((e) => {
                        return <option key={e} value={e}>{e}</option>
                      })}
                    </select>
                  </div>
                )}
                {this.state.selectedFilter === "repository" && (
                  <div className="col-2">
                    <label>Repo</label>
                    <select className="form-control" value={this.state.filteredRepository} id="filteredRepository" onChange={this.updateField}>
                      <option value="all">All</option>
                      {this.state.allRepos.map((e) => {
                        return <option key={e} value={e}>{e}</option>
                      })}
                    </select>
                  </div>
                )}
              </>
            )}
            {this.state.format === "group" && (
              <>
                <div className="col-2">
                  <label>Group By</label>
                  <select className="form-control" value={this.state.unit} id="unit" onChange={this.updateField}>
                    <option value="day">Day</option>
                    <option value="hour">Day and Hour</option>
                  </select>
                </div>
              </>
            )}
          </div>
          <div className="row" style={{ marginTop: 20 }}>
            <div className="col-12">
              <button className="btn btn-block btn-primary" onClick={this.getEvents}>Update</button>
            </div>
          </div>


        </Card>
        <div style={{ marginTop: 10 }}>
          {this.state.formatChanged && (
            <div className="col-6 offset-3">
              <Card title="Parameters Changed">
                The parameters have changed in a way that you need to hit the Update button above.
              </Card>
            </div>
          )}
          {this.state.events.length !== 0 && !this.state.loading && !this.state.formatChanged && this.state.format === "individual" && (
            <>
              <div className="row">
                <div className="col-12">
                  <Table
                    title="Events"
                    csvFileName={`analytic-github-events-${this.state.start.format("YYYY-MM-DD")}-${this.state.end.format("YYYY-MM-DD")}-events-${this.state.filteredRepository}-${this.state.format}`}
                    showDownload={true}
                    columns={individualCols}
                    rows={this.state.events}
                  />
                </div>
              </div>
              <div className="row" style={{ marginTop: 10 }}>
                <div className="col-4">
                  <Card title="Repos">
                    <PieChart loading={this.state.loading} data={this.state.repoPieData} minHeight={500} height={500} />
                  </Card>
                </div>
                <div className="col-8">
                  <Card title="Repos">
                    <LineGraph loading={this.state.loading} data={this.state.repoLineData} minHeight={500} height={500} />
                  </Card>
                </div>
              </div>

              <div className="row" style={{ marginTop: 10 }}>
                <div className="col-4">
                  <Card title="Users">
                    <PieChart loading={this.state.loading} data={this.state.userPieData} minHeight={500} height={500} />
                  </Card>
                </div>
                <div className="col-8">
                  <Card title="Users">
                    <LineGraph loading={this.state.loading} data={this.state.userLineData} minHeight={500} height={500} />
                  </Card>
                </div>
              </div>

              <div className="row" style={{ marginTop: 10 }}>
                <div className="col-4">
                  <Card title="Events">
                    <PieChart loading={this.state.loading} data={this.state.eventPieData} minHeight={500} height={500} />
                  </Card>
                </div>
                <div className="col-8">
                  <Card title="Events">
                    <LineGraph loading={this.state.loading} data={this.state.eventLineData} minHeight={500} height={500} />
                  </Card>
                </div>
              </div>
            </>
          )}
          {this.state.events.length !== 0 && !this.state.loading && !this.state.formatChanged && this.state.format === "group" && (
            <>
              <div className="row">
                <div className="col-12">
                  <Table
                    title="Events"
                    csvFileName={`analytic-github-events-${this.state.start.format("YYYY-MM-DD")}-${this.state.end.format("YYYY-MM-DD")}-events-${this.state.filteredRepository}-${this.state.format}`}
                    showDownload={true}
                    columns={groupCols}
                    rows={this.state.events}
                  />
                </div>
              </div>
              <div className="row" style={{ marginTop: 10 }}>
                <div className="col-4">
                  <Card title="Ratio">
                    <PieChart loading={this.state.loading} data={this.state.groupedPieData} minHeight={500} height={500} />
                  </Card>
                </div>
                <div className="col-8">
                  <Card title="Time">
                    <LineGraph loading={this.state.loading} data={this.state.groupedLineData} minHeight={500} height={500} />
                  </Card>
                </div>
              </div>
            </>
          )}
        </div>
      </Screen>
    );
  }


  updateField(e: any) {
    let ns: any = this.state;
    const id = e.target.id;
    const val = e.target.value;
    ns[id] = val;
    if (id === "format") {
      ns.formatChanged = true;
    }
    if (id === "selectedFilter") {
      if (val === "user") {
        ns["filteredRepository"] = "all";
      } else if (val === "repository") {
        ns["filteredUser"] = "all";
      }
    }
    this.setState(ns);
  }

  updateStart(newDay: moment.Moment) {
    this.setState({ start: newDay })
  }

  updateEnd(newDay: moment.Moment) {
    this.setState({ end: newDay })
  }

  private setup() {
    // unlike with normal events, we arne't doing any known events, so just pass on
    this.getEvents()
  }

  private getEvents() {
    this.setState({ loading: true }, async () => {
      try {
        let events: any[] = [];
        let csvEvents: any[] = [];

        const params: any = {
          start: this.state.start.format("YYYY-MM-DDT") + "00:00:00Z",
          end: this.state.end.format("YYYY-MM-DDT") + "23:59:59Z",
          count: 1000,
          offset: 0,
        }
        const count = parseInt(this.state.count);
        if (!isNaN(count)) {
          params.count = count;
        }
        const offset = parseInt(this.state.offset);
        if (!isNaN(offset)) {
          params.offset = offset;
        }

        if (this.state.format === "group") {
          params.format = "group";
          params.unit = this.state.unit;
        }

        // priority is repo then user
        if(this.state.format === "group"){
          // for now, we only allow grouping on events since we would
          // need a way to get all the repos and users otherwise
          const result = await AnalyticsAPI.getAnalyticGithubEvents(app, params);
          events = result.body.data;
        } else {
          if (this.state.filteredRepository !== "all") {
            const result = await AnalyticsAPI.getAnalyticGithubEventsForRepository(app, this.state.filteredRepository, params);
            events = result.body.data;
          } else if (this.state.filteredUser !== "all") {
            const result = await AnalyticsAPI.getAnalyticGithubEventsForUser(app, this.state.filteredUser, params);
            events = result.body.data;
          } else {
            const result = await AnalyticsAPI.getAnalyticGithubEvents(app, params);
            events = result.body.data;
          }
        }

        for(const e of events){
          if(!e.posted && e.timePeriod){
            e.posted = moment(e.timePeriod).format("YYYY-MM-DDTHH:mm:ss") + "Z";
          }
          csvEvents.push(e);
          e.posted = this.state.format === "group" && this.state.unit === "day" ?  moment(e.posted).local().format("MM/DD/YY") : moment(e.posted).local().format("MM/DD/YY HH:mm");
        }

        // sort the events, just to be safe
        events.sort((a, b) => {
          return moment(a.posted, "MM/DD/YY HH:mm").isBefore(moment(b.posted, "MM/DD/YY HH:mm")) ? -1 : 1;
        })


        const repoPieData: any[] = [];
        const repoLineData: any[] = [];
        const userLineData: any[] = [];
        const userPieData: any[] = [];
        const eventPieData: any[] = [];
        const eventLineData: any[] = [];
        const groupedPieData: any[] = [];
        const groupedLineData: any[] = [];
        const allRepos: string[] = [];
        const allUsers: string[] = [];

        if (this.state.format === "individual") {
          // all the graphs
          const eventDataLineHolder: any = {};
          const repoDataLineHolder: any = {};
          const userDataLineHolder: any = {};
          const eventDataPieHolder: any = {};
          const repoDataPieHolder: any = {};
          const userDataPieHolder: any = {};
          const allUserData: any = {};
          const allRepoData: any = {};

          for (const e of events) {
            csvEvents.push(e);
            e.timePeriod = moment(e.posted, "MM/DD/YY HH:mm").local().format("MM/DD/YY HH:00");
            e.posted = moment(e.posted, "MM/DD/YY HH:mm").local().format("MM/DD/YY HH:mm");


            // now the data holders
            if (!allUserData[e.user]) {
              allUserData[e.user] = true;
            }
            if (!allRepoData[e.repository]) {
              allRepoData[e.repository] = true;
            }


            if (!eventDataLineHolder[e.timePeriod]) {
              eventDataLineHolder[e.timePeriod] = {};
            }
            if (!eventDataLineHolder[e.timePeriod][e.event]) {
              eventDataLineHolder[e.timePeriod][e.event] = 0;
            }
            eventDataLineHolder[e.timePeriod][e.event]++;

            if (!repoDataLineHolder[e.timePeriod]) {
              repoDataLineHolder[e.timePeriod] = {};
            }
            if (!repoDataLineHolder[e.timePeriod][e.repository]) {
              repoDataLineHolder[e.timePeriod][e.repository] = 0;
            }
            repoDataLineHolder[e.timePeriod][e.repository]++;


            if (!userDataLineHolder[e.timePeriod]) {
              userDataLineHolder[e.timePeriod] = {};
            }
            if (!userDataLineHolder[e.timePeriod][e.user]) {
              userDataLineHolder[e.timePeriod][e.user] = 0;
            }
            userDataLineHolder[e.timePeriod][e.user]++;

            // pies

            if (!eventDataPieHolder[e.event]) {
              eventDataPieHolder[e.event] = 0;
            }
            eventDataPieHolder[e.event]++;

            if (!repoDataPieHolder[e.repository]) {
              repoDataPieHolder[e.repository] = 0;
            }
            repoDataPieHolder[e.repository]++;

            if (!userDataPieHolder[e.user]) {
              userDataPieHolder[e.user] = 0;
            }
            userDataPieHolder[e.user]++;

          }

          // now build the graphs
          for (const key of Object.keys(allRepoData)) {
            allRepos.push(key);
          }
          for (const key of Object.keys(allUserData)) {
            allUsers.push(key);
          }

          // lines
          for (const day of Object.keys(userDataLineHolder)) {
            userLineData.push({ key: day, ...userDataLineHolder[day] });
          }
          for (const day of Object.keys(repoDataLineHolder)) {
            repoLineData.push({ key: day, ...repoDataLineHolder[day] });
          }
          for (const day of Object.keys(eventDataLineHolder)) {
            eventLineData.push({ key: day, ...eventDataLineHolder[day] });
          }


          // pies
          for (const key of Object.keys(userDataPieHolder)) {
            userPieData.push({ key, count: userDataPieHolder[key] });
          }
          for (const key of Object.keys(repoDataPieHolder)) {
            repoPieData.push({ key, count: repoDataPieHolder[key] });
          }
          for (const key of Object.keys(eventDataPieHolder)) {
            eventPieData.push({ key, count: eventDataPieHolder[key] });
          }


        } else {
          // just ratio and timeline?
          // since this is a grouped, it's genericized
          const pieHolder: any = {};
          const lineHolder: any = {};
          for(const e of events){
            csvEvents.push(e);

            if(!pieHolder[e.value]){
              pieHolder[e.value] = 0;
            }
            pieHolder[e.value] += e.count;

            if(!lineHolder[e.posted]){
              lineHolder[e.posted] = {};
            }
            if(!lineHolder[e.posted][e.value]){
              lineHolder[e.posted][e.value] = 0;
            }
            lineHolder[e.posted][e.value] += e.count;
          }


          for (const key of Object.keys(pieHolder)) {
            groupedPieData.push({ key, count: pieHolder[key] });
          }
          for (const day of Object.keys(lineHolder)) {
            groupedLineData.push({ key: day, ...lineHolder[day] });
          }
        }


        this.setState({ loading: false, events, csvEvents, 
          repoLineData, repoPieData, 
          userLineData, userPieData, 
          eventLineData, eventPieData, 
          allRepos, allUsers,
          groupedLineData, groupedPieData, 
          formatChanged: false });
      } catch (err) {
        this.setState({ loading: false });
      }
    })
  }


}