import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Screen } from "src/components/structure";

import * as UserActions from "src/reducers/userReducer";
import BreedActivityReport from "./BreedActivityReportTabs/BreedActivityReport";
import BreedActivityGraphs from "./BreedActivityReportTabs/BreedActivityReportsGraph";

interface IBreedActivityReportScreenProps {
  location: any;
  userActions: any;
  userState: any;
}

interface IBreedActivityReportScreenState {
  loading: boolean;
}


class BreedActivityReportScreen extends Component<
  IBreedActivityReportScreenProps,
  IBreedActivityReportScreenState
> {
  constructor(props: IBreedActivityReportScreenProps) {
    super(props);

    this.state = {
      loading: false,
    };

  }

  componentDidMount() {
  }


  render() {
    return (
      <Screen shouldAuthCheck={true} requiredRoles={["reporting"]} loading={this.state.loading}>
        <Tabs>
            <TabList>
              <Tab>Overview</Tab>
              <Tab>Graphs</Tab>
            </TabList>
            <TabPanel>
              <BreedActivityReport />
            </TabPanel>
            <TabPanel>
              <BreedActivityGraphs />
            </TabPanel>
          </Tabs>
      </Screen>
    );
  }

}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BreedActivityReportScreen) as any
);
