import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Select from "react-select";

import * as AppActions from "src/reducers/appReducer";
import * as UserActions from "src/reducers/userReducer";

import { Alert, Card, MarkdownEditor } from "src/components/structure";
import { SupportAPI } from "src/api";
import { ticketDepartment, ticketCritical, userOS } from "src/utils/items";

interface ISupportTicketScreenProps {
  loading: boolean;
  userActions: any;
  userState: any;
  onTicketCreated: any;
}

interface ISupportTicketScreenState {
  loading: boolean;
  title: string;
  reportedBy: number;
  device: any;
  department: string;
  user: any;
  userOS: string;
  critical: string;
  notes: string;
}

class SupportTicketScreen extends Component<
  ISupportTicketScreenProps,
  ISupportTicketScreenState
> {
  constructor(props: ISupportTicketScreenProps) {
    super(props);
    this.state = {
      loading: false,
      title: "",
      reportedBy: this.props.userState.user.id,
      device:"",
      department: "",
      user: "",
      userOS: "",
      critical: "",
      notes: "",
    };
    this.updateField = this.updateField.bind(this);
    this.updateSelection = this.updateSelection.bind(this);
    this.updateNotes = this.updateNotes.bind(this);
    this.createNewTicket = this.createNewTicket.bind(this);
  }

  render() {
    return (
      <div style={{ marginBottom: 20 }}>
        <Card
          title="Submit New Support Ticket"
          loading={this.props.loading || this.state.loading}
        >
          <div className="center">
            <p style={{ fontSize: 14, textAlign: "center" }}>
              <strong>
                Enter the following information about the support request
              </strong>
            </p>
          </div>
          <div className="form-group">
            <label style={{ fontSize: 12, marginLeft: 5 }}>Title *</label>
            <input
              id="title"
              type="text"
              className="form-control"
              placeholder="Title"
              autoComplete="new-password"
              onChange={this.updateField}
              value={this.state.title}
            />
          </div>
          <div style={{ marginLeft: 15, marginTop: 10 }}>
            <div className="row">
              <div style={{ width: "25%" }}>
                <label style={{ fontSize: 14 }}>Associated User ID *</label>
              </div>
              <div style={{ width: "25%", marginLeft: 15 }}>
                <label style={{ fontSize: 14 }}>User OS *</label>
              </div>
              <div style={{ width: "25%", marginLeft: 15 }}>
                <label style={{ fontSize: 14 }}>
                  Associated Device ID (if applicable){" "}
                </label>
              </div>
            </div>
            <div className="row">
              <div style={{ width: "25%" }}>
                <input
                  id="user"
                  type="number"
                  className="form-control"
                  placeholder="User ID"
                  autoComplete="new-password"
                  onChange={this.updateField}
                  value={this.state.user}
                />
              </div>
              <div style={{ width: "25%", marginLeft: 15 }}>
                <Select
                  options={userOS}
                  onChange={this.updateSelection}
                  hideSelectedOptions={true}
                />
              </div>
              <div style={{ width: "25%", marginLeft: 15 }}>
                <input
                  id="device"
                  type="number"
                  className="form-control"
                  placeholder="Device ID"
                  autoComplete="new-password"
                  onChange={this.updateField}
                  value={this.state.device}
                />
              </div>
            </div>
          </div>
          <div style={{ marginLeft: 15, marginTop: 10 }}>
            <div className="row">
              <div style={{ width: "40%" }}>
                <label style={{ fontSize: 14 }}>Department Focus *</label>
              </div>
              <div style={{ width: "40%", marginLeft: 15 }}>
                <label style={{ fontSize: 14 }}>
                  Does this need immediate attention? *
                </label>
              </div>
            </div>
            <div className="row">
              <div style={{ width: "40%" }}>
                <Select
                  options={ticketDepartment}
                  onChange={this.updateSelection}
                  hideSelectedOptions={true}
                />
              </div>
              <div style={{ width: "40%", marginLeft: 15 }}>
                <Select
                  options={ticketCritical}
                  onChange={this.updateSelection}
                  hideSelectedOptions={true}
                />
              </div>
            </div>
          </div>
          <div className="form-group">
            <div style={{ width: "25%" }}>
              <label style={{ fontSize: 14, marginTop: 20 }}>
                Notes (please be as DETAILED as possible!) *
              </label>
            </div>
            <MarkdownEditor
              content={this.state.notes}
              showToggle={false}
              showHelp={true}
              onChange={this.updateNotes}
              mode="edit"
              rows={10}
            />
          </div>
          <div className="col-sm-12" style={{ marginTop: 20, display: "flex", alignItems: "center", justifyContent: "center" }}>
            <div className="col-sm-12 col-lg-4">
              <button
                className="btn btn-block btn-primary"
                onClick={this.createNewTicket}
              >
                Submit Ticket
              </button>
            </div>
          </div>
        </Card>
      </div>
    );
  }

  updateSelection = (input: any) => {
    let ns = this.state;
    ns[input.field] = input.value;
    this.setState(ns);
  };

  updateField(e: any) {
    let ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private updateNotes(content: any) {
    this.setState({ notes: content });
  }

  createNewTicket() {
    if (this.state.title === "" || this.state.userOS === "" || this.state.department === "" || this.state.critical === "" || this.state.notes === "") {
      return this.setState({ loading: false }, () => {
        Alert.error("All fields with an asterisk (*) must be filled in!");
      });
    } else if (this.state.user === 0 || this.state.user === "") {
      return this.setState({ loading: false }, () => {
        Alert.error("'Associated User ID' cannot be zero or blank!");
      });
    }

    const userID = parseInt(this.state.user + "", 10);
    const deviceID = parseInt(this.state.device + "", 10);
    this.setState({ loading: true }, async () => {
      try {
        let res = await SupportAPI.createTicket({
          title: this.state.title,
          reportedBy: this.state.reportedBy,
          device: deviceID,
          status: "open",
          department: this.state.department,
          user: userID,
          userOS: this.state.userOS,
          critical: this.state.critical,
          notes: this.state.notes,
        });
        const newTicket = res.body.data;
        return this.setState(
          {
            loading: false,
            title: "",
            device: "",
            department: "",
            user: "",
            userOS: "",
            critical: "",
            notes: "",
          },
          () => {
            Alert.success("Ticket created successfully");
            this.props.onTicketCreated(newTicket);
          }
        );
      } catch (err) {
        return this.setState({ loading: false }, () => {
          Alert.error("We could not create that ticket");
        });
      }
    });
  }
}

const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(AppActions, dispatch),
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SupportTicketScreen);
