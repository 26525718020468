import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as UserActions from "src/reducers/userReducer";
import Card from "src/components/structure/Card";
import { IPet } from "src/api/pets";
import { PetsAPI } from "src/api";
import Bubble from "src/components/structure/Bubble";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExchangeAlt } from '@fortawesome/free-solid-svg-icons';

interface ILinkTabProps {
  userId: number;
  location: any;
  userActions: any;
  userState: any;
  pet: IPet;
}

interface ILinkTabState {
  loading: boolean;
  links: any[];
}

class LinkTab extends Component<ILinkTabProps, ILinkTabState> {
  constructor(props: ILinkTabProps) {
    super(props);

    this.state = {
      loading: false,
      links: []
    };

    this.updateField = this.updateField.bind(this);
  }

  updateField(e: any) {
    let ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  componentDidMount() {
    this.getLinks();
  }

  getLinks() {
    this.setState({ loading: true }, async () => {
      try {
        const results = await PetsAPI.getPetDeviceLinksForPet("wagz", this.props.pet.id);
        const formatted = [];
        for (const res of results.body.data) {
          if (res.deviceId !== 0) {
            formatted.push(
              <div className="row">
                <div style={{ padding: 30 }}><Bubble id={res.deviceId} idType={"device"} userId={this.props.userId}/></div>
                <div style={{ marginTop: 100 }}><FontAwesomeIcon icon={faExchangeAlt} size={"2x"} color={"#172b4d"}/></div>
                <div style={{ padding: 30 }}><Bubble id={res.petId} idType={"pet"} userId={this.props.userId}/></div>
              </div>)
          }
        }
        this.setState({
          loading: false,
          links: formatted
        });
      } catch (err) {
        this.setState({ loading: false });
      }
    });
  }

  render() {
    return (
      <div className="row">
        {this.state.links.length === 0 && (
          <div>
            <text>No links exist for this pet</text>
          </div>
        )}
        {this.state.links.length > 0 && (
          <div className="col-10 offset-1">
            <Card title="Links">{this.state.links}
            </Card>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default connect<{}, {}, any>(
  mapStateToProps,
  mapDispatchToProps
)(LinkTab);
