import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as UserActions from "src/reducers/userReducer";
import { DeviceAPI, getUrl, NotificationsAPI, PetsAPI } from "src/api";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faEye, faPencilAlt } from '@fortawesome/free-solid-svg-icons';


import Card from "src/components/structure/Card";
import { IUser } from "src/api/users";
import moment from "moment";
import { Modal } from "react-bootstrap";
import { error, success } from "src/components/structure/Alert";
import { EmailTabPreferences } from "./EmailTabPreferences";

interface IEmailTabProps {
  user: IUser;
  match: any;
  location: any;
  userActions: any;
  userState: any;
}

interface IEmailTabState {
  loading: boolean;
  startTime: string;
  endTime: string;
  mgUrl: string;
  history: any[];
  templates: any[];
  pets: any[];
  devices: any[];

  selectedTemplate: any;
  selectedPetId: number;
  selectedDeviceId: number;
  additionalFields: any;
  newAdditionalFieldKey: string;
  newAdditionalFieldValue: string;
  editFieldKeyOriginal: string
  editFieldKey: string
  editFieldValue: string
  showNewFieldModal: boolean;
  showEditFieldModal: boolean;

  showPreviewModal: boolean;
}

class EmailTab extends Component<IEmailTabProps, IEmailTabState> {
  constructor(props: IEmailTabProps) {
    super(props);

    this.state = {
      loading: true,
      startTime: moment().subtract(3, "days").format("YYYY-MM-DDTHH:mm:ss.000") + "Z",
      endTime: moment().format("YYYY-MM-DDTHH:mm:ss.000") + "Z",
      mgUrl: "",
      history: [],
      templates: [],
      devices: [],
      pets: [],
      selectedTemplate: { id: 0 },
      selectedPetId: -1,
      selectedDeviceId: -1,
      additionalFields: {},
      newAdditionalFieldKey: "",
      newAdditionalFieldValue: "",
      editFieldKeyOriginal: "",
      editFieldKey: "",
      editFieldValue: "",
      showNewFieldModal: false,
      showEditFieldModal: false,
      showPreviewModal: false,
    };

    this.updateField = this.updateField.bind(this);
    this.setup = this.setup.bind(this);
    this.selectTemplate = this.selectTemplate.bind(this);
    this.toggleShowPreviewModal = this.toggleShowPreviewModal.bind(this);
    this.toggleShowNewFieldModal = this.toggleShowNewFieldModal.bind(this);
    this.toggleShowEditFieldModal = this.toggleShowEditFieldModal.bind(this);
    this.addAdditionalField = this.addAdditionalField.bind(this);
    this.deleteAdditionalField = this.deleteAdditionalField.bind(this);
    this.updateAdditionalField = this.updateAdditionalField.bind(this);
    this.sendEmail = this.sendEmail.bind(this);
  }

  updateField(e: any) {
    let ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  componentDidMount() {
    this.setup();
  }

  render() {
    return (
      <div className="row">
        <div className="col-6">
          <Card title="History" loading={this.state.loading}>
            {this.state.history.length === 0 && (
              <div className="row">
                <div className="col-12">
                  Could not load any history for that user
                </div>
              </div>
            )}
            {this.state.history.length > 0 && (

              <div className="row">
                <div className="col-4">
                  Sent On
                </div>
                <div className="col-3">
                  Template
                </div>
                <div className="col-2">
                  Status
                </div>
                <div className="col-2">
                  Mailgun Log
                </div>
              </div>

            )}
            {this.state.history.map((h) => {
              return (
                <div className="row" key={h.mailgunId}>
                  <div className="col-4">
                    {h.sendTime.format("MM/DD/YY hh:mm A")}
                  </div>
                  <div className="col-3">
                    {h.display}
                  </div>
                  <div className="col-2">
                    {h.status}
                  </div>
                  <div className="col-2">
                    <a href={h.url} target="_new"><FontAwesomeIcon icon={faEye} /></a>
                  </div>
                </div>
              );
            })}
            <div className="row">
              <div className="col-12">
                <FontAwesomeIcon icon={faEnvelope} /> - <a href={this.state.mgUrl} target="_new">More Mailgun Logs (Requires Mailgun Access)</a>
              </div>
            </div>
          </Card>
        </div>
        <div className="col-6">
          <div className="row" style={{ marginBottom: 15 }}>
            <div className="col-12">
              <EmailTabPreferences user={this.props.user} />
            </div>
          </div>
          <div className="row" style={{ marginBottom: 15 }}>
            <div className="col-12">


              {this.props.userState.user.accessLevel !== "read_only" && (
                <Card title="Send Email">
                  <p>Use the form below to send a new template to a user. Some templates require a device and/or a pet, so make sure you select the appropriate information.</p>
                  <strong>Warning: Make sure you know what additional fields YOU need to enter. If you aren't sure, check the template. Missing fields will show up as variables in the sent email.</strong>
                  <div className="form-group">
                    <label htmlFor="">Template</label>
                    <select className="form-control" value={this.state.selectedTemplate.id} onChange={this.selectTemplate}>
                      <option value={0}>None</option>
                      {this.state.templates.map((t) => {
                        return (<option key={t.id} value={t.id}>{t.display}</option>)
                      })}
                    </select>
                  </div>
                  {this.state.selectedTemplate.id !== 0 && this.state.selectedTemplate.needsPet === "yes" && (
                    <div className="form-group">
                      <label htmlFor="selectedPetId">Requires a Pet:</label>
                      <select className="form-control" id="selectedPetId" value={this.state.selectedPetId} onChange={this.updateField}>
                        <option value={-1}>Select...</option>
                        {this.state.pets.map((p) => {
                          return (<option key={p.id} value={p.id}>{p.name}</option>)
                        })}
                      </select>
                    </div>
                  )}
                  {this.state.selectedTemplate.id !== 0 && this.state.selectedTemplate.needsDevice === "yes" && (
                    <div className="form-group">
                      <label htmlFor="selectedDeviceId">Requires a Device:</label>
                      <select className="form-control" id="selectedDeviceId" value={this.state.selectedDeviceId} onChange={this.updateField}>
                        <option value={-1}>Select...</option>
                        {this.state.devices.map((d) => {
                          return (<option key={d.id} value={d.id}>{d.productId}</option>)
                        })}
                      </select>
                    </div>
                  )}
                  {this.state.selectedTemplate.id !== 0 && (
                    <div className="form-group">
                      {Object.keys(this.state.additionalFields).map((key) => {
                        return (
                          <div className="row" key={key}>
                            <div className="col-5">
                              {key}
                            </div>
                            <div className="col-5">
                              {this.state.additionalFields[key]}
                            </div>
                            <div className="col-2">
                              <FontAwesomeIcon icon={faPencilAlt} onClick={() => {
                                this.setState({
                                  editFieldKey: key,
                                  editFieldKeyOriginal: key,
                                  editFieldValue: this.state.additionalFields[key],
                                }, () => { this.toggleShowEditFieldModal(); });
                              }} />
                            </div>
                          </div>
                        )
                      })}
                      <div className="form-group" style={{ marginTop: 10 }}>
                        <button className="btn btn-info btn-block" onClick={this.toggleShowNewFieldModal}>Add Field</button>
                        <button className="btn btn-info btn-block" onClick={this.toggleShowPreviewModal}>Preview Data</button>
                      </div>
                    </div>
                  )}

                  <Modal show={this.state.showPreviewModal} onHide={this.toggleShowPreviewModal}>
                    <Modal.Header closeButton>
                      <Modal.Title>
                        Confirm Email Data
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      {this.state.selectedTemplate.id !== 0 && (
                        <div>
                          <strong>Please confirm the information below. If you are satisifed with the request, hit Send to send the data to the notifications service for processing.</strong>
                          <div className="row">
                            <div className="col-4">
                              User
                            </div>
                            <div className="col-8">
                              {this.props.user.firstName} {this.props.user.lastName} - {this.props.user.email}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-4">
                              Template
                            </div>
                            <div className="col-8">
                              {this.state.selectedTemplate.display}
                            </div>
                          </div>
                          {this.state.selectedPetId !== -1 && (
                            <div className="row">
                              <div className="col-4">
                                Pet ID
                              </div>
                              <div className="col-8">
                                {this.state.selectedPetId}
                              </div>
                            </div>
                          )}
                          {this.state.selectedDeviceId !== -1 && (
                            <div className="row">
                              <div className="col-4">
                                Device ID
                              </div>
                              <div className="col-8">
                                {this.state.selectedDeviceId}
                              </div>
                            </div>
                          )}
                          <div className="row">
                            <div className="col-4">
                              Additional Fields
                            </div>
                            <div className="col-8">

                            </div>
                          </div>
                          {Object.keys(this.state.additionalFields).map((key) => {
                            return (
                              <div className="row" key={key}>
                                <div className="col-4 offset-2">
                                  {key}
                                </div>
                                <div className="col-6">
                                  {this.state.additionalFields[key]}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </Modal.Body>
                    <Modal.Footer>
                      <button className="btn btn-primary btn-block" onClick={this.sendEmail}>Confirm and Send</button>
                      <button className="btn btn-primary btn-block" onClick={this.toggleShowPreviewModal}>Nevermind</button>
                    </Modal.Footer>
                  </Modal>

                  <Modal show={this.state.showNewFieldModal} onHide={this.toggleShowNewFieldModal}>
                    <Modal.Header closeButton>
                      <Modal.Title>
                        Add New Additional Field
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="form-group">
                        <label htmlFor="newAdditionalFieldKey">Field Name</label>
                        <input type="text" className="form-control" value={this.state.newAdditionalFieldKey} id="newAdditionalFieldKey" onChange={this.updateField} />
                      </div>
                      <div className="form-group">
                        <label htmlFor="newAdditionalFieldValue">Field Value</label>
                        <input type="text" className="form-control" value={this.state.newAdditionalFieldValue} id="newAdditionalFieldValue" onChange={this.updateField} />
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <div className="form-group">
                        <button className="btn btn-primary btn-block" onClick={this.addAdditionalField}>Add</button>
                      </div>
                      <div className="form-group">
                        <button className="btn btn-primary btn-block" onClick={this.toggleShowNewFieldModal}>Nevermind</button>
                      </div>
                    </Modal.Footer>
                  </Modal>

                  <Modal show={this.state.showEditFieldModal} onHide={this.toggleShowEditFieldModal}>
                    <Modal.Header closeButton>
                      <Modal.Title>
                        Edit Field
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="form-group">
                        <label htmlFor="editFieldKey">Field Name</label>
                        <input type="text" className="form-control" value={this.state.editFieldKey} id="editFieldKey" onChange={this.updateField} />
                      </div>
                      <div className="form-group">
                        <label htmlFor="editFieldValue">Field Value</label>
                        <input type="text" className="form-control" value={this.state.editFieldValue} id="editFieldValue" onChange={this.updateField} />
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <div className="form-group">
                        <button className="btn btn-primary btn-block" onClick={this.updateAdditionalField}>Save</button>
                      </div>
                      <div className="form-group">
                        <button className="btn btn-danger btn-block" onClick={this.deleteAdditionalField}>Delete</button>
                      </div>
                      <div className="form-group">
                        <button className="btn btn-primary btn-block" onClick={this.toggleShowEditFieldModal}>Nevermind</button>
                      </div>
                    </Modal.Footer>
                  </Modal>
                </Card>)}

            </div>
          </div>
        </div>
      </div>
    );
  }

  private selectTemplate(e: any) {
    const id = parseInt(e.target.value, 10);
    if (id === 0) {
      return this.setState({ selectedTemplate: { id: 0 }, selectedDeviceId: -1, selectedPetId: -1 })
    }
    let template: any = { id: 0 };
    for (const t of this.state.templates) {
      if (t.id === id) {
        template = t;
        break;
      }
    }
    this.setState({ selectedTemplate: template });
  }

  private toggleShowPreviewModal() {
    this.setState({ showPreviewModal: !this.state.showPreviewModal })
  }

  private toggleShowNewFieldModal() {
    this.setState({ showNewFieldModal: !this.state.showNewFieldModal })
  }

  private toggleShowEditFieldModal() {
    this.setState({ showEditFieldModal: !this.state.showEditFieldModal })
  }

  private addAdditionalField() {
    const additionalFields = this.state.additionalFields;
    additionalFields[this.state.newAdditionalFieldKey] = this.state.newAdditionalFieldValue;
    this.setState({ additionalFields, showNewFieldModal: false })
  }

  private deleteAdditionalField() {
    const additionalFields: any = [];
    for (const f of Object.keys(this.state.additionalFields)) {
      if (f !== this.state.editFieldKeyOriginal) {
        additionalFields.push(this.state.additionalFields[f])
      }
    }
    this.setState({ additionalFields, editFieldKey: "", editFieldKeyOriginal: "", editFieldValue: "", showEditFieldModal: false });
  }

  private updateAdditionalField() {
    const additionalFields = this.state.additionalFields;
    if (this.state.editFieldKey !== this.state.editFieldKeyOriginal) {
      delete additionalFields[this.state.editFieldKeyOriginal];
    }
    additionalFields[this.state.editFieldKey] = this.state.editFieldValue;
    this.setState({ additionalFields, editFieldKey: "", editFieldKeyOriginal: "", editFieldValue: "", showEditFieldModal: false });
  }

  private setup() {
    // we need to get the following:
    // - history
    // - templates
    // - pets
    // - devices
    this.setState({ loading: true }, async () => {
      const emailEncoded = encodeURIComponent(this.props.user.email);
      let history = [];
      let templates = [];
      let pets = [];
      let devices = [];
      // note the multiple try/catches; we want to swallow errors and still allow the other calls to occur
      try {
        const templatesResult = await NotificationsAPI.getEmailTemplates("wagz2");
        templates = templatesResult.body.data;
      } catch (err) {

      }
      try {
        const historyResult = await NotificationsAPI.getUserEmailHistory("wagz2", this.props.user.id);
        for (const h of historyResult.body.data) {
          h.sendTime = moment(h.sendTime);
          let parsedMailgunId: string = h.mailgunId;
          parsedMailgunId = parsedMailgunId.replace("<", "")
          parsedMailgunId = parsedMailgunId.replace(">", "")
          h.url = getUrl("mailgun", "log", { mailgunId: parsedMailgunId });
          // find the display in the template result
          h.display = h.templateShortName;
          for (const t of templates) {
            if (t.shortName === h.templateShortName) {
              h.display = t.display;
              break;
            }
          }
          history.push(h);
        }
        history.sort((a, b) => {
          return a.sendTime.isBefore(b.sendTime) ? 1 : -1;
        })
      } catch (err) {

      }
      try {
        const petsResult = await PetsAPI.getPetsForUser("wagz2", this.props.user.id);
        pets = petsResult.body.data;
      } catch (err) {

      }
      try {
        const devicesResult = await DeviceAPI.getDevicesForUser("wagz2", this.props.user.id, { status: "active" })
        devices = devicesResult.body.data;
      } catch (err) {

      }
      this.setState({
        mgUrl: getUrl("mailgun", "logs", { "email": emailEncoded, "startTime": this.state.startTime, "endTime": this.state.endTime }),
        templates,
        history,
        pets,
        devices,
        loading: false,
      });
    });
  }

  private sendEmail() {
    this.setState({ loading: true }, async () => {
      const data: any = {
        userId: this.props.user.id,
        toEmail: this.props.user.email,
        fields: this.state.additionalFields,
      };
      if (this.state.selectedDeviceId > 0) {
        // find the device so we can plug in the family
        for (const d of this.state.devices) {
          if (d.id === this.state.selectedDeviceId) {
            data.selectedDeviceId = parseInt(this.state.selectedDeviceId + "", 10);
            data.productFamilyId = d.productFamilyId;
            break;
          }
        }
      }
      if (this.state.selectedPetId > 0) {
        data.petId = parseInt(this.state.selectedPetId + "", 10);
      }

      // some quick error checking on the template
      if (this.state.selectedTemplate.needsDevice === "yes" && this.state.selectedDeviceId <= 0) {
        return error("That template requires a device to be selected");
      }
      if (this.state.selectedTemplate.needsPet === "yes" && this.state.selectedPetId <= 0) {
        return error("That template requires a pet to be selected");
      }

      try {
        await NotificationsAPI.sendEmailToUser("wagz2", this.state.selectedTemplate.shortName, this.props.user.id, data);
        success("Email queued!");
        this.setState({ loading: false, additionalFields: {}, selectedDeviceId: -1, selectedPetId: -1, selectedTemplate: { id: 0 }, showPreviewModal: false })
      } catch (err) {
        error("Could not send that email. Check your information and try again or contact backend engineering");
        this.setState({ loading: false });
      }
    })
  }
}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default connect<{}, {}, any>(mapStateToProps, mapDispatchToProps)(EmailTab);
