import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Select from "react-select";

import { Card, Screen, MarkdownEditor, Alert } from "src/components/structure";
import { DeviceAPI } from "src/api";
import { success, error } from "src/components/structure/Alert";
import { eligibleDevices } from "src/utils/items";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faEdit } from "@fortawesome/free-solid-svg-icons";
import { styles } from "src/styles";

interface IManageActionsProps {
  loading: boolean;
  userState: any;
}

interface IManageActionsState {
  loading: boolean;
  newAction: string;
  description: string;
  actions: any;
  actionsList: string[];
  forDevice: string;
  timeout: any;
  display: string;
  editDescription: string;
  editTimeout: any;
  editDisplay: string;
  selectedAction: string;
  showModalForDelete: boolean;
  showModalForEdit: boolean;
}
class ManageActions extends Component<
  IManageActionsProps,
  IManageActionsState
> {
  constructor(props: IManageActionsProps) {
    super(props);

    this.state = {
      loading: false,
      newAction: "",
      description: "",
      actions: [],
      actionsList: [],
      forDevice: "",
      timeout: "",
      display: "",
      editDescription: "",
      editTimeout: "",
      editDisplay: "",
      selectedAction: "",
      showModalForDelete: false,
      showModalForEdit: false,
    };

    this.updateField = this.updateField.bind(this);
    this.fetchKnownActions = this.fetchKnownActions.bind(this);
    this.createNewAction = this.createNewAction.bind(this);
    this.showEditModal = this.showEditModal.bind(this);
    this.updateDescription = this.updateDescription.bind(this);
    this.updateEditDescription = this.updateEditDescription.bind(this);
    this.editKnownAction = this.editKnownAction.bind(this);
    this.deleteSelectedAction = this.deleteSelectedAction.bind(this);
  }

  updateField(e: any) {
    let ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  updateSelection = (input: any) => {
    let ns = this.state;
    ns[input.field] = input.value;
    this.setState(ns);
  };

  componentDidMount() {
    this.fetchKnownActions();
  }

  fetchKnownActions() {
    this.setState({ loading: true }, async () => {
      let actions: any[] = [];
      const knownActions: any = [];
      try {
        const res = await DeviceAPI.getKnownActions();
        actions = res.body.data;
        for (const r of actions) {
          knownActions.push(r.action);
        }
        this.setState({ loading: false, actions, actionsList: knownActions });
      } catch (err) {
        this.setState({ loading: false });
      }
    });
  }

  private createNewAction() {
    this.setState({ loading: true }, async () => {
      try {
        const timeout = parseInt(this.state.timeout + "", 10);
        await DeviceAPI.saveNewKnownAction({
          deviceType: this.state.forDevice,
          app: "wagz2",
          action: this.state.newAction,
          description: this.state.description,
          timeout: timeout,
          display: this.state.display
        });
        success("Known Action created!");
        this.setState({ newAction: "", description: "", forDevice: "", timeout: "" }, () => {
          this.fetchKnownActions();
        });
      } catch (err) {
        error(
          "Could not create that known action. Please contact an administrator."
        );
        this.setState({ loading: false });
      }
    });
  }

  showEditModal(input: any) {
    this.setState({ loading: true }, async () => {
      try {
        let res = await DeviceAPI.getKnownAction(input);

        this.setState({
          editDescription: res.body.data.description,
          editTimeout: res.body.data.timeout,
          editDisplay: res.body.data.display,
          forDevice: res.body.data.deviceType,
          showModalForEdit: true,
          selectedAction: input,
          loading: false,
        });
      } catch (err) {
        this.setState({ loading: false, showModalForEdit: false });
      }
    });
  }

  showDeleteModal(input: string) {
    this.setState({
      showModalForDelete: true,
      selectedAction: input,
    });
  }

  editKnownAction() {
    this.setState({ loading: true }, async () => {
      try {
        const timeout = parseInt(this.state.editTimeout + "", 10);
        await DeviceAPI.updateKnownAction(this.state.selectedAction, {
          description: this.state.editDescription,
          deviceType: this.state.forDevice,
          timeout: timeout,
          display: this.state.editDisplay
        });
        return this.setState(
          { loading: false, showModalForEdit: false },
          () => {
            Alert.success("Action edited successfully");
            this.fetchKnownActions();
          }
        );
      } catch (err) {
        Alert.error("Could not save that edit");
        this.setState({
          loading: false,
          showModalForEdit: false,
        });
      }
    });
  }

  deleteSelectedAction() {
    this.setState({ loading: true }, async () => {
      try {
        await DeviceAPI.deleteKnownAction(this.state.selectedAction);
        return this.setState(
          { loading: false, showModalForDelete: false },
          () => {
            Alert.success("Action deleted successfully");
            this.fetchKnownActions();
          }
        );
      } catch (err) {
        this.setState({
          loading: false,
          showModalForDelete: false,
        });
      }
    });
  }

  render() {
    return (
      <Screen id="manage_events" requiredRoles={["backend", "product"]}>
        <div className="row">
          <div className="col-4">
            <Card title="Info">
              <p>
                This is a page where members of the team can manage the actions
                that we send out to the devices.
              </p>
              <p>
                <strong>***PLEASE READ***</strong> It is very important that
                when naming a new known action, the action is in camel case
                (thisIsCamelCase).
              </p>
            </Card>
          </div>
          <div className="col-4">
            <Card
              title="Manage Actions"
              loading={this.props.loading || this.state.loading}
            >
              {this.state.actionsList.map((e: string) => {
                return (
                  <div>
                    <div className="row" style={{ marginBottom: 5 }} key={e}>
                      <div className="col-8">{e}</div>
                      <div className="row" style={{ paddingLeft: 35 }}>
                        <div>
                          <FontAwesomeIcon
                            icon={faEdit}
                            onClick={() => {
                              this.showEditModal(e);
                            }}
                            className="text-secondary"
                          />
                        </div>
                        <div style={{ paddingLeft: 5, marginLeft: 5 }}>
                          <FontAwesomeIcon
                            icon={faBan}
                            onClick={() => this.showDeleteModal(e)}
                            className="text-danger"
                          />
                        </div>
                      </div>
                    </div>
                    <div style={styles.divider} />
                  </div>
                );
              })}
            </Card>
          </div>
          <div className="col-4">
            <Card title="Create New Known Action">
              <div className="form-group">
                <label>New Known Action Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="newAction"
                  value={this.state.newAction}
                  onChange={this.updateField}
                />
                <label style={{ marginTop: 10 }}>Action Display Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="display"
                  value={this.state.display}
                  onChange={this.updateField}
                />
                <label style={{ marginTop: 10 }}>Description</label>
                <MarkdownEditor
                  content={this.state.description}
                  showToggle={true}
                  showHelp={true}
                  onChange={this.updateDescription}
                  mode="edit"
                  rows={5}
                />
                <label style={{ marginTop: 10 }}>
                  Eligible devices for this Action
                </label>
                  <Select
                    options={eligibleDevices}
                    onChange={this.updateSelection}
                    hideSelectedOptions={true}
                  />
                  <label style={{ marginTop: 10 }}>
                    Timeout (in seconds)
                  </label>
                    <input
                      id="timeout"
                      type="number"
                      className="form-control"
                      placeholder="Timeout"
                      autoComplete="new-password"
                      onChange={this.updateField}
                      value={this.state.timeout}
                    />
              </div>
              <div className="form-group">
                <button
                  className="btn btn-block btn-success"
                  onClick={this.createNewAction}
                >
                  Create New Known Action
                </button>
              </div>
            </Card>
          </div>
          <div>
          <Modal
            dialogClassName="modal-75"
            show={this.state.showModalForEdit}
            onHide={() => {
              this.setState({ showModalForEdit: false });
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                Update Info for {this.state.selectedAction}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="center">
                <p style={{ fontSize: 14, textAlign: "center" }}>
                  <strong>
                    Update the fields you wish to change, then hit Submit.
                  </strong>
                </p>
                <br />
              </div>
                  <label style={{ marginTop: 10 }}>
                    Action Display Name
                  </label>
                    <input
                      id="editDisplay"
                      type="text"
                      className="form-control"
                      onChange={this.updateField}
                      value={this.state.editDisplay}
                    />
                <label style={{ marginTop: 10 }}>Description</label>
                <MarkdownEditor
                  content={this.state.editDescription}
                  showToggle={true}
                  showHelp={true}
                  onChange={this.updateEditDescription}
                  mode="edit"
                  rows={5}
                />
                <label style={{ marginTop: 10 }}>
                  Eligible devices for this Action
                </label>
                  <Select
                    options={eligibleDevices}
                    onChange={this.updateSelection}
                    hideSelectedOptions={true}
                  />
                  <label style={{ marginTop: 10 }}>
                    Timeout (in seconds)
                  </label>
                    <input
                      id="editTimeout"
                      type="number"
                      className="form-control"
                      placeholder="Timeout"
                      autoComplete="new-password"
                      onChange={this.updateField}
                      value={this.state.editTimeout}
                    />
              <div className="form-group" style={{ marginTop: 20 }}>
                <button
                  className="btn btn-block btn-submit"
                  onClick={this.editKnownAction}
                >
                  Update Known Action
                </button>
              </div>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>
          </div>
          <div>
            <Modal
              show={this.state.showModalForDelete}
              onHide={() => {
                this.setState({ showModalForDelete: false });
              }}
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  Deleting Known Action "{this.state.selectedAction}"...
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                  Are you sure you want to permanently delete{" "}
                  {this.state.selectedAction}?
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  className="btn btn-block btn-submit"
                  style={styles.button}
                  onClick={this.deleteSelectedAction}
                >
                  Delete Known Action
                </button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </Screen>
    );
  }

  private updateDescription(content: any) {
    this.setState({ description: content });
  }

  private updateEditDescription(content: any) {
    this.setState({ editDescription: content });
  }
}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps() {
  return {};
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ManageActions) as any
);
