import { makeCall } from "./client";



export class GPSTestsAPI {  

  /**
   * Get the GPS Tools started in a date range
   * @param app 
   * @param start
   * @param end
   * @param options 
   * @returns 
   */
  public getGPSTestsInRange = (app: string, start: string, end: string, options: any = {}) => {
    const data = {
      ...options,
      start,
      end
    };
    return makeCall('GET', `/admin/${app}/gpstests`, data);
  }

  /**
   * Get tests completes for a device
   * @param app 
   * @param deviceId 
   * @param options 
   * @returns 
   */
  public getGPSTestsForDevice = (app: string, deviceId: number, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('GET', `/admin/${app}/gpstests/devices/${deviceId}`, data);
  }

  /**
   * Get tests completed for a user
   * @param app 
   * @param userId 
   * @param options 
   * @returns 
   */
  public getGPSTestsForUser = (app: string, userId: number, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('GET', `/admin/${app}/gpstests/users/${userId}`, data);
  }

  /**
   * Get a single test
   * @param app 
   * @param testId 
   * @param options 
   * @returns 
   */
  public getGPSTest = (app: string, testId: number, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('GET', `/admin/${app}/gpstests/${testId}`, data);
  }

  /**
   * Deletes a GPS test
   * @param app 
   * @param testId 
   * @param options 
   * @returns 
   */
  public deleteGPSTest = (app: string, testId: number, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('DELETE', `/admin/${app}/gpstests/${testId}`, data);
  }

  /**
   * Create a GPS test
   * @param app 
   * @param options 
   * @returns 
   */
  public createGPSTest = (app: string, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('POST', `/admin/${app}/gpstests`, data);
  }

  /**
   * Update a GPS test
   * @param app 
   * @param testId 
   * @param options 
   * @returns 
   */
  public updateGPSTest = (app: string, testId: number, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('PATCH', `/admin/${app}/gpstests/${testId}`, data);
  }

}

export interface IGPSTest {
  averageAccuracy: number;
  averageDistance: number;
  deviceId: number;
  end: string;
  firmwareVersion: string;
  id: number;
  locations: any[];
  medianDistance: number;
  modemFirmwareVersion: string;
  observerNotes: string;
  productId: string;
  smallerAccuracyPercent: number;
  standardDeviation: number;
  start: string;
  status: "complete" | "pending" | "processing";
  targetLat: number;
  targetLng: number;
  userId: number;
};

export const BlankGPSTest: IGPSTest = {
  averageAccuracy: 0,
  averageDistance: 0,
  deviceId: 0,
  end: "",
  firmwareVersion: "",
  id: 0,
  locations: [],
  medianDistance: 0,
  modemFirmwareVersion: "",
  observerNotes: "",
  productId: "",
  smallerAccuracyPercent: 0,
  standardDeviation: 0,
  start: "",
  status: "pending",
  targetLat: 0,
  targetLng: 0,
  userId: 0,
};