import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Table, MarkdownEditor, Screen } from "src/components/structure";
import { capitalizeFirstLetter } from "src/utils/utilities";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';

import { Modal } from "react-bootstrap";
import * as Alert from "../../structure/Alert";
import * as UserActions from "src/reducers/userReducer";
import { styles } from "src/styles";
import { IColumns } from "src/utils/customTypes";
import { ReportsAPI } from "src/api";

interface IOrderManagementProps {
  location: any;
  userActions: any;
  userState: any;
}

interface IOrderManagementState {
  loading: boolean;
  orderID: number;
  notes: string;
  showModalForNotes: boolean;
  columns: IColumns[];
  rows: any[];
}

class OrderManagement extends Component<
  IOrderManagementProps,
  IOrderManagementState
> {
  constructor(props: IOrderManagementProps) {
    super(props);

    this.state = {
      loading: false,
      orderID: 0,
      notes: "",
      showModalForNotes: false,
      columns: [
        {
          label: "",
          field: "view",
          sort: "disabled"
        },
        {
          label: "Order ID",
          field: "orderId",
          sort: "asc",
          width: 100,
        },
        {
          label: "Customer Email",
          field: "email",
          sort: "asc",
          width: 150,
        },
        {
          label: "Ordered From",
          field: "orderOrigination",
          sort: "asc",
          width: 200,
        },
        {
          label: "Status",
          field: "status",
          sort: "asc",
          width: 150,
        },
        {
          label: "Created",
          field: "created",
          sort: "asc",
          width: 150,
        },
        {
          label: "Last Updated",
          field: "lastUpdated",
          sort: "asc",
          width: 150,
        },
        {
          label: "Notes",
          field: "notes",
          sort: "asc",
          width: 100,
        },
      ],
      rows: [],
    };

    this.updateField = this.updateField.bind(this);
    this.populateOrders = this.populateOrders.bind(this);
    this.editNotes = this.editNotes.bind(this);
    this.updateNotes = this.updateNotes.bind(this);
    this.processData = this.processData.bind(this);
  }

  updateField(e: any) {
    let ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }
  showNotesModal(input: any) {
    this.setState({
      showModalForNotes: true,
      orderID: input.orderId,
      notes: input.notes,
    });
  }

  componentDidMount() {
    this.populateOrders();
  }

  populateOrders() {
    this.setState({ loading: true }, async () => {
      try {
        const results = await ReportsAPI.getAllCustomerOrders();

        const customerOrders = [];
        for (let result of results.body.data) {
          customerOrders.push({
            ...result,
            orderOrigination: capitalizeFirstLetter(result.orderOrigination),
            status: capitalizeFirstLetter(result.status),
            notes: (
              <button
                className="btn btn-sm btn-pri col-sm-10"
                onClick={() => this.showNotesModal(result)}
              >
                View/Edit
              </button>
            ),
            view: (
              <div style={{textAlign: "center"}}>
                <Link to={`/reports/orders/${result.orderId}`} id="order_page" className="link-light">
                  <FontAwesomeIcon icon={faEye} />
                </Link>
              </div>
            ),
          });
        }
        this.setState({
          loading: false,
          rows: customerOrders,
        });
      } catch (err) {
        this.setState({ loading: false });
      }
    });
  }

  editNotes() {
    this.setState({ loading: true }, async () => {
      try {
        await ReportsAPI.updateOrder(this.state.orderID, {
          notes: this.state.notes,
        });
        return this.setState(
          { loading: false, showModalForNotes: false },
          () => {
            Alert.success("Notes edited successfully");
            this.populateOrders();
          }
        );
      } catch (err) {
        Alert.error("Error editing notes.  Try again later.");
        this.setState({
          loading: false,
          showModalForNotes: false,
        });
      }
    });
  }

  private updateNotes(content: any) {
    this.setState({ notes: content });
  }

  processData(columns: IColumns[], rows: any) {
    const data = {
      columns,
      rows,
    };
    return data;
  }

  render() {
    return (
      <Screen shouldAuthCheck={true} requiredRoles={["reporting"]}>
        <div>
          <Table
            title="Customer Orders"
            csvFileName="orders"
            showDownload={true}
            columns={this.state.columns}
            rows={this.state.rows}
          />
        </div>

        <div>
          <Modal
            dialogClassName="modal-75"
            show={this.state.showModalForNotes}
            onHide={() => {
              this.setState({ showModalForNotes: false });
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                Viewing notes for order #{this.state.orderID}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="center">
                <p style={{ fontSize: 14, textAlign: "center" }}>
                  <strong>
                    You are now viewing the notes for this order. If you'd like
                    to make edits, you may do so, then hit Submit.
                  </strong>
                </p>
                <br />
              </div>
              <div style={styles.borderBottom}></div>
              <div className="form-group">
                <label style={{ marginLeft: 5, textDecoration: "underline" }}>
                  Notes:
                </label>
                <MarkdownEditor
                  content={this.state.notes}
                  showToggle={true}
                  showHelp={true}
                  onChange={this.updateNotes}
                  mode="view"
                  rows={5}
                />
              </div>
              <div className="text">
                <button
                  className="btn btn-block btn-submit"
                  onClick={this.editNotes}
                >
                  Submit
                </button>
              </div>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>
        </div>
      </Screen>
    );
  }
}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OrderManagement) as any
);
