import * as React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

import * as AppActions from "../../reducers/appReducer";
import * as UserActions from "../../reducers/userReducer";
import wagzLogo from "src/img/wagz_logo.png";
import moment from "moment";

const dateFormat = "MM/DD/YY HH:mm:ss A";

interface INavProps {
  appActions: any;
  userActions: any;

  appState: any;
  userState: any;
  history: any;
  location: any;
}

interface INavState {
  open: boolean;
  name: string;
  users: any;
  currentTime: moment.Moment;
}

class NavBar extends React.Component<INavProps, INavState> {

  constructor(props: any) {
    super(props);
    this.state = {
      open: true,
      name: `${this.props.userState.user.firstName} ${this.props.userState.user.lastName}`,
      users: [],
      currentTime: moment().utc(),
    };

    this.logout = this.logout.bind(this);
    this.toggleHelp = this.toggleHelp.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  componentDidMount(){
    setInterval(() => {
      this.setState({ currentTime: moment().utc() });
    }, 1000)
  }

  public logout() {
    this.props.userActions.logoutUser();
    window.localStorage.clear();
    this.props.history.push("/");
  }

  public toggleMenu(e: any) {
    e.preventDefault();
    if(this.props.userState.loggedIn){
      this.props.appActions.toggleMenu();
    }
  }

  public toggleHelp(e: any) {
    e.preventDefault();
    this.props.appActions.toggleHelp();
  }


  get links() {

    if (this.props.userState.loggedIn) {
      // we need to show the major headers for the permission
      const elements = [];
      elements.push(
        <ul className="navbar-nav mr-auto" key="nav-left">
          <li className="nav-item"><Link to={`/me`} className="nav-link">Logged In As {this.props.userState.user.firstName}</Link></li>
        </ul>);
      elements.push(
        <ul className="navbar-nav" key="nav-right">
          <li className="nav-item"><button className="btn btn-link nav-link">Current time UTC: {this.state.currentTime.format(dateFormat)}</button></li>
          <li className="nav-item"><button className="btn btn-link nav-link" onClick={this.logout}>Logout</button></li>
        </ul>);
      return elements;
    } else {
      const elements = [];
      elements.push(
        <ul className="navbar-nav mr-auto" key="nav-left">
        </ul>);
      elements.push(
        <ul className="navbar-nav" key="nav-right">
          <li className="nav-item"><Link to="/login" id="nav-login" className="nav-link">Login &nbsp;</Link></li>
        </ul>);
      return elements;
    }
  }

  public render() {
    return (
      <div id="navbar">
        <nav className="navbar navbar-expand-lg navbar-dark" style={{ borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px" }}>
        <button className="btn btn-link navbar-brand" onClick={this.toggleMenu}><img src={wagzLogo} alt="logo" height="50%" width="50%" /></button>
      
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="navbar-collapse collapse" id="navbarSupportedContent">
            {this.links}
          </div>
        </nav>
      </div>);
  }
}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch),
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavBar) as any);