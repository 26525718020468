import React, { Component } from "react";
import { Table, Screen, DatePicker, PieChart } from "src/components/structure";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from '@fortawesome/free-solid-svg-icons';
import moment from "moment";
import Card from "src/components/structure/Card";
import { AnalyticsAPI } from "src/api";
import GoogleMapReact from "google-map-react";
import { Modal } from "react-bootstrap";

interface IAnalyticLocationsScreenProps {
  target: "all" | "user" | "pet" | "device";
  targetId: number;
}

interface IAnalyticLocationsScreenState {
  loading: boolean;
  locations: any[];
  csvLocations: any[];
  start: moment.Moment;
  end: moment.Moment;
  showMoreModal: boolean;
  selectedLocation: any;
  heatmapData: any;
  maxLat: number;
  minLat: number;
  maxLng: number;
  minLng: number;

  pcStateData: any[];
}

const app = "wagz2";
const mapKey = process.env.REACT_APP_GOOGLE_MAP_KEY || "";

const cols: any[] = [
  {
    "label": "Posted",
    "field": "posted",
  },
  {
    "label": "User",
    "field": "userIdLink",
  },
  {
    "label": "City",
    "field": "city",
  },
  {
    "label": "State",
    "field": "state",
  },
  {
    "label": "Country",
    "field": "country",
  },
  {
    "label": "Zip",
    "field": "zip",
  },
  {
    "label": "Lat/Lng",
    "field": "latlng",
  },
  {
    "label": "",
    "field": "view",
  },
];

export class AnalyticLocationsScreen extends Component<
  IAnalyticLocationsScreenProps,
  IAnalyticLocationsScreenState
> {
  constructor(props: IAnalyticLocationsScreenProps) {
    super(props);

    this.state = {
      loading: true,
      locations: [],
      csvLocations: [],
      start: moment().subtract(7, "days"),
      end: moment(),
      pcStateData: [],
      showMoreModal: false,
      selectedLocation: false,

      heatmapData: { positions: [], options: { radius: 20, opacity: 0.6}},
      maxLat: 0,
      minLat: 0,
      maxLng: 0,
      minLng: 0,
    };

    this.updateField = this.updateField.bind(this);
    this.updateStart = this.updateStart.bind(this);
    this.updateEnd = this.updateEnd.bind(this);
    this.setup = this.setup.bind(this);
    this.getLocations = this.getLocations.bind(this);
    this.selectLocation = this.selectLocation.bind(this);
    this.toggleShowMoreModal = this.toggleShowMoreModal.bind(this);
  }


  componentDidMount() {
    this.setup();
  }  

  render() {
    return (
      <Screen shouldAuthCheck={true} requiredRoles={["reporting", "product", "support"]} loading={this.state.loading}>
        <Card title="Filters">
          <div className="row">
            <div className="col-4">
              <label>Start</label>
              <DatePicker date={this.state.start} onDateSaved={this.updateStart} />
            </div>
            <div className="col-4">
              <label>End</label>
              <DatePicker date={this.state.end} onDateSaved={this.updateEnd} />
            </div>
            <div className="col-4">
              <label>&nbsp;</label>
              <button className="btn btn-block btn-primary" onClick={this.getLocations}>Update</button>
            </div>
          </div>
        </Card>
        <div style={{marginTop: 10}}>
          {this.state.locations.length !== 0 && !this.state.loading && (
            <>
              <div className="row">
                <div className="col-8">
                  <Table
                    title="Devices"
                    csvFileName={`analytic-locations-${this.state.start.format("YYYY-MM-DD")}-${this.state.end.format("YYYY-MM-DD")}-${this.props.target}`}
                    showDownload={true}
                    columns={cols}
                    rows={this.state.locations}
                  />
                </div>
                <div className="col-4">
                  <Card title="States">
                    <PieChart loading={this.state.loading} data={this.state.pcStateData} hideLegend={false} minHeight={400} />
                  </Card>
                </div>
              </div>
              <div className="row" style={{marginTop: 10}}>
                <div className="col-12">
                  <Card title="Heatmap">

                  <div className="center" style={{ height: "60vh", width: "100%" }}>
                    <GoogleMapReact
                      yesIWantToUseGoogleMapApiInternals
                      bootstrapURLKeys={{
                        key: mapKey,
                        libraries: ['visualization'],
                      }}
                      defaultCenter={{
                        lat: (this.state.maxLat + this.state.minLat )/ 2,
                        lng: (this.state.maxLng + this.state.minLng )/ 2,
                      }}
                      defaultZoom={5}
                      heatmap={this.state.heatmapData}
                      options={{
                        keyboardShortcuts: false,
                        tilt: 0,
                        mapTypeId: "roadmap",
                      }}
                    >
                    </GoogleMapReact>
                    </div>
                  </Card>
                </div>
              </div>
            </>
          )}
        </div>

        <Modal
            show={this.state.showMoreModal}
            onHide={this.toggleShowMoreModal}
            dialogClassName="modal-third"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                Viewing Location
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row analytic-more-info-row">
                <div className="col-4">
                  Location: 
                </div>
                <div className="col-8">
                {this.state.selectedLocation.city}, {this.state.selectedLocation.state} {this.state.selectedLocation.country} 
                </div>
              </div>
              <div className="row analytic-more-info-row">
                <div className="col-4">
                  Lat/Lng: 
                </div>
                <div className="col-8">
                {this.state.selectedLocation.lat}, {this.state.selectedLocation.lng} 
                </div>
              </div>
              
              <div className="row analytic-more-info-row">
                <div className="col-4">
                  Additional Params: 
                </div>
                <div className="col-8">
                  <pre>{JSON.stringify(this.state.selectedLocation.params, null, 2)}</pre>
                </div>
              </div>
              
            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-primary btn-block" onClick={this.toggleShowMoreModal}>Done</button>
            </Modal.Footer>
          </Modal>
      </Screen>
    );
  }


  updateField(e: any) {
    let ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  updateStart(newDay: moment.Moment){
    this.setState({ start: newDay })
  }

  updateEnd(newDay: moment.Moment){
    this.setState({ end: newDay})
  }

  selectLocation(location: any){
    this.setState({ selectedLocation: location, showMoreModal: true });
  }

  toggleShowMoreModal(){
    this.setState({showMoreModal: !this.state.showMoreModal});
  }

  private setup(){
    this.setState({loading: true }, this.getLocations)
  }

  private getLocations(){
    this.setState({loading: true }, async () => {
      try{
        const params = {
          start: this.state.start.format("YYYY-MM-DDT") + "00:00:00Z",
          end: this.state.end.format("YYYY-MM-DDT") + "23:59:59Z",
        }
        let locations: any[] = [];
        if(this.props.target === "all"){
          const result = await AnalyticsAPI.getAnalyticLocations(app, params);
          locations = result.body.data;
        } else {
          const result = await AnalyticsAPI.getAnalyticLocationsForUser(app, this.props.targetId, params);
          locations = result.body.data;
        }

        // now we need to format them
        const csvDevices: any[] = [];
        const pcStateData: any[] = [];
        const heatmapPositions: any[] = [];

        let pcStateDataHolder: any = {};
        let { maxLat, maxLng, minLat, minLng } = this.state;
        let foundLatLng = false;

        for(const l of locations){
          
          csvDevices.push(l);
          l.posted = moment(l.posted).local().format("MM/DD/YY HH:mm");
          l.view = (<button className="btn-link-bare" onClick={() => this.selectLocation(l)}><FontAwesomeIcon icon={faEye} /></button>)

          l.userIdLink = (<a href={`/support/users/${l.userId}`} target="_new">{l.userId}</a>)
          if(l.lat && l.lat !== 0 && l.lng && l.lng !== 0){
            if(!foundLatLng){
              maxLat = l.lat;
              minLat = l.lat;
              maxLng = l.lng;
              minLng = l.lng;
              foundLatLng = true;
            }
            l.latlng = `${l.lat}/${l.lng}`;
  
            if(l.lat && l.lat > maxLat){
              maxLat = l.lat;
            }
            if(l.lat && l.lat < minLat){
              minLat = l.lat;
            }
            if(l.lng && l.lng > maxLng){
              maxLng = l.lng;
            }
            if(l.lng && l.lng < minLng){
              minLng = l.lat;
            }
            heatmapPositions.push({ lat: l.lat, lng: l.lng });
          }
          if(l.state !== ""){
            if(!pcStateDataHolder[l.state]){
              pcStateDataHolder[l.state] = 0;
            }
            pcStateDataHolder[l.state] += 1;
          }

        }
        const heatmapData = this.state.heatmapData;
        heatmapData.positions = heatmapPositions;

        for(const key of Object.keys(pcStateDataHolder)){
          pcStateData.push({ key, count: pcStateDataHolder[key]});
        }


        this.setState({ loading: false, locations: locations, csvLocations: csvDevices, pcStateData, maxLat, maxLng, minLat, minLng, heatmapData });
      }catch(err){
        this.setState({ loading: false });
      }
    })
  }

}