import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as UserActions from "src/reducers/userReducer";
import { IPet } from "src/api/pets";
import Card from "src/components/structure/Card";
import { PetsAPI } from "src/api";
import Geofence from "src/components/structure/Geofence";
import Intensity from "src/components/structure/Intensity";
import GeofenceSettings from "src/components/structure/GeofenceSettings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getIcon, getColor } from "src/utils/utilities";
import { styles } from "src/styles";

interface IGeofenceSettingsTabProps {
  userId: number;
  location: any;
  userActions: any;
  userState: any;
  pet: IPet;
}

interface IGeofenceSettingsTabState {
  loading: boolean;
  petId: number;
  correctionTypes: any;
  active: string;
  corrections: string;
  disableGeofencesOnWifi: string;
  userFences: any;
  petFenceTotal: number;
}

class GeofenceSettingsTab extends Component<
  IGeofenceSettingsTabProps,
  IGeofenceSettingsTabState
> {
  constructor(props: IGeofenceSettingsTabProps) {
    super(props);

    this.state = {
      loading: false,
      petId: 0,
      correctionTypes: [],
      active: "",
      corrections: "",
      disableGeofencesOnWifi: "",
      userFences: [],
      petFenceTotal: 0
    };

    this.fetchFences = this.fetchFences.bind(this);
    this.parseTypes = this.parseTypes.bind(this);
  }

  componentDidMount() {
    this.fetchFences();
  }

  fetchFences() {
    this.setState({ loading: true }, async () => {
      try {
        const res = await PetsAPI.getGeofenceInfoForPet(
          "wagz",
          this.props.userId,
          this.props.pet.id,
          { "includeInset": "yes"}
        );
        let petFences = 0
        for (let userFence of res.body.data.userFences) {
          for (let pets of userFence.pets) {
            if (pets.id === this.props.pet.id) {
              petFences += 1
            }}}
        this.setState({
          loading: false,
          ...res.body.data.petFenceSettings,
          correctionTypes: res.body.data.deviceGeofenceData.shadow.state.reported.settings.correctionType,
          userFences: res.body.data.userFences,
          petFenceTotal: petFences
        });
      } catch (err) {
        this.setState({ loading: false });
      }
    });
  }

  setFences() {
    const userFences = [];
    if (this.state.userFences.length === 0) {
      userFences.push(
        <strong key={1}>No geofences available for this pet</strong>
      );
    } else {
      let index = 0;
      for (let userFence of this.state.userFences) {
        for (let pets of userFence.pets) {
          if (pets.id === this.props.pet.id) {
            index += 1;
            userFences.push(
              <div style={{ marginBottom: 80 }} key={index}>
                <Geofence
                  key={index}
                  fence={userFence}
                  loading={false}
                  userId={this.props.userId}
                  simple={true}
                />
              </div>
            );
          }
        }
      }
      return userFences;
    }
  }

  parseTypes(types: any) {
    if (!types || types.length === 0) {
      return <span>None</span>;
    }
    return (
      <span style={{ textTransform: "capitalize", marginLeft: 10, fontWeight: "bolder" }}>{types.join(", ")}</span>
    );
  }

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-sm-12 col-lg-4" style={{ marginBottom: 30 }}>
            <Card title="Geofence Settings">
              <ul>
                <li style={{ padding: 2 }}>
                  Active:
                  <div className="fa fa-fw">
                    <FontAwesomeIcon
                      icon={getIcon(this.state.active)}
                      className={getColor(this.state.active)}
                    />
                  </div>
                </li>
                <li>
                  Corrections:
                  <div className="fa fa-fw">
                    <FontAwesomeIcon
                      icon={getIcon(this.state.corrections)}
                      className={getColor(this.state.corrections)}
                    />
                  </div>
                </li>
                <li>
                  Disable Geofences On WiFi:
                  <div className="fa fa-fw">
                    <FontAwesomeIcon
                      icon={getIcon(this.state.disableGeofencesOnWifi)}
                      className={getColor(this.state.disableGeofencesOnWifi)}
                    />
                  </div>
                </li>
                <li>
                  Correction Types (deprecating soon):
                  <div>{this.parseTypes(this.state.correctionTypes)}</div>
                </li>
              </ul>
            </Card>
            <Card>
              <p>The user has <strong>{this.state.userFences.length}</strong> total geofences on their account, with this pet, <strong>{this.props.pet.name}</strong>, assigned to <strong>{this.state.petFenceTotal}</strong> of them. </p>
            </Card>
            <GeofenceSettings userId={this.props.userId} pet={this.props.pet} />
            <Card title="Correction Intensity Settings">
              <Intensity loading={this.state.loading} userID={this.props.userId} petID={this.props.pet.id}/>
            </Card>
          </div>
          <div className="col-sm-12 col-lg-1"></div>
          <div className="col-sm-12 col-lg-7">
            <Card title="Geofences for Pet">
              <div>
                <div>{this.setFences()}</div>
                <div style={styles.borderBottom}></div>
              </div>
              </Card>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default connect<{}, {}, any>(
  mapStateToProps,
  mapDispatchToProps
)(GeofenceSettingsTab);
