import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Card from "src/components/structure/Card";
import Screen from "src/components/structure/Screen";
import * as AppActions from "src/reducers/appReducer";
import { error } from "src/components/structure/Alert";
import { UserAPI } from "src/api";

interface IGenerateJWTScreenProps {
  appActions: any;
}

interface IGenerateJWTScreenState {
  loading: boolean;
  jwt: string;
  userId: string;
  email: string;
}

class GenerateJWTScreen extends React.Component<IGenerateJWTScreenProps, IGenerateJWTScreenState> {

  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      jwt: "",
      userId: "",
      email: "",
    };

    this.updateField = this.updateField.bind(this);
    this.generateJWT = this.generateJWT.bind(this);
    this.reset = this.reset.bind(this);
  }

  public render() {
    return (
      <Screen fileName="GenerateJWTScreen.tsx" requiredRoles={["backend"]}>
        <div className="row" style={{ marginBottom: 20 }}>
          <div className="col-4 offset-4">
            <Card loading={this.state.loading} title="Generate a JWT">
              {this.state.jwt === "" ? 
              (
                <div>
                  <p>You can use this to generate a JWT for a user for use in calls against the API. <strong>Warning:</strong> use of this functionality is monitored closely. Abuse can lead to consequences. Only use if you have permission and know what you are doing. If in doubt, ask the admin team.</p>
                  <div className="form-group">
                    <label htmlFor="userId">User ID:</label>
                    <input type="text" id="userId" className="form-control" value={this.state.userId} onChange={this.updateField} />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email:</label>
                    <input type="text" id="email" className="form-control" value={this.state.email} onChange={this.updateField} />
                  </div>
                  <button className="btn btn-block btn-primary" onClick={this.generateJWT}>Generate JWT</button>
                </div>
              ) :
              (
                <div>
                  <p>We have generated the following JWT for {this.state.email} with an id of {this.state.userId}. Copy it now as you won't be able to retrieve it again.</p>
                  <textarea className="form-control" rows={8} value={this.state.jwt} disabled={true} />

                  <button className="btn btn-block btn-primary" onClick={this.reset}>Reset</button>
                </div>
              )}
            </Card>
          </div>
        </div>

      </Screen>
    );
  }

  private updateField(e: any) {
    const ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private reset(){
    this.setState({
      email: "",
      userId: "",
      jwt: "",
    });
  }

  private generateJWT(){
    const email = this.state.email.trim();
    if(this.state.userId.trim() === "" || email === ""){
      return error("User ID and Email are required.");
    }
    const userIdNumber = parseInt(this.state.userId, 10);
    this.setState({ loading: true }, async () => {
      try{
        const result = await UserAPI.generateJWT("wagz2", userIdNumber, email);
        this.setState({ loading: false, jwt: result.body.data.jwt });
      }catch(err){
        error("Cannot generate a JWT for that user.");
        this.setState({ loading: false, });
      }
    })
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GenerateJWTScreen) as any);