import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { AppsAPI } from "src/api";
import NewVersion from "./NewVersion";
import List from "./ReleaseList";
import { Loading, Screen } from "src/components/structure";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Card } from "src/components/structure";

interface IMobileReleasesProps {
  userState: any;
}

interface IMobileReleasesState {
  loading: boolean;
  iosVersions: any[];
  androidVersions: any[];
}
class MobileReleases extends Component<
  IMobileReleasesProps,
  IMobileReleasesState
> {
  constructor(props: IMobileReleasesProps) {
    super(props);

    this.state = {
      loading: false,
      iosVersions: [],
      androidVersions: [],
    };

    this.updateField = this.updateField.bind(this);
    this.getVersions = this.getVersions.bind(this);
    this.handleNewVersionCreated = this.handleNewVersionCreated.bind(this);
  }

  componentDidMount() {
    this.getVersions();
  }
 
  updateField(e: any) {
    let ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private handleNewVersionCreated() {
    this.getVersions();
  }

  private getVersions() {
    this.setState({ loading: true }, async () => {
      try {
        const iosVersions = await AppsAPI.getAppVersionForOS("wagz", "ios");
        const androidVersions = await AppsAPI.getAppVersionForOS("wagz", "android");
        this.setState({ loading: false, iosVersions, androidVersions });
      } catch (err) {
        this.setState({ loading: false });
      }
    });
  }

  render() {
    return (
      <Screen id="mobile_releases" requiredRoles={["mobile"]}>
        <div className="row">
          <div className="col-sm-12 col-lg-7">
            <Card title="App Versions">
              <Tabs>
                <TabList>
                  <Tab>iOS</Tab>
                  <Tab>Android</Tab>
                </TabList>
                <TabPanel>
                  {this.state.loading ? (
                    <Loading />
                  ) : (
                    <List
                      versions={this.state.iosVersions}
                      loading={this.state.loading}
                      onVersionsCreated={this.handleNewVersionCreated}
                    />
                  )}
                </TabPanel>
                <TabPanel>
                  {this.state.loading ? (
                    <Loading />
                  ) : (
                    <List
                      versions={this.state.androidVersions}
                      loading={this.state.loading}
                      onVersionsCreated={this.handleNewVersionCreated}
                    />
                  )}
                </TabPanel>
              </Tabs>
            </Card>
          </div>
          <div className="col-sm-12 col-lg-5">
            <NewVersion
              loading={this.state.loading}
              onVersionsCreated={this.handleNewVersionCreated}
            />
          </div>
        </div>
      </Screen>
    );
  }
}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps() {
  return {};
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MobileReleases) as any
);
