import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Card from "src/components/structure/Card";
import Screen from "src/components/structure/Screen";
import * as AppActions from "src/reducers/appReducer";
import { DeviceGroupsAPI } from "src/api";
import { Modal } from "react-bootstrap";
import { DatePicker, LoadingButton } from "src/components/structure";
import { error, success } from "src/components/structure/Alert";

import DeviceGroupRow from "./BackendDeviceGroupComponents/DeviceGroupRow";
import moment from "moment";
import Table from "src/components/structure/Table";
import { familyToDisplay } from "src/utils/utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faTrash } from "@fortawesome/free-solid-svg-icons";

interface IDeviceGroupsScreenProps {
  appActions: any;
}

interface IDeviceGroupsScreenState {
  loading: boolean;
  allGroups: any[];
  selectedGroup: any;

  newGroupName: string;
  newGroupType: "general" | "firmware";
  newGroupProductFamily: string;

  showNewGroupModal: boolean;
  showEditGroupModal: boolean;
  showAssignParametersModal: boolean;
  showSendActionModal: boolean;
  showAssignDeviceModal: boolean;

  paramsCount: string;
  paramsHeartbeatSince: moment.Moment;
  paramsProductFamilyId: string;
  paramsStatus: "active"| "pending" | "all";
  paramsOTAChannel: "",

  sendAction: "ota";
  sendActionOTATarget: "";

  linkDeviceId: string;

  selectedDeviceForUnlink: any;
  showUnlinkModal: boolean;
}

class DeviceGroupsScreen extends React.Component<IDeviceGroupsScreenProps, IDeviceGroupsScreenState> {

  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      allGroups: [],
      selectedGroup: {},
    
      newGroupName: "",
      newGroupType: "general",
      newGroupProductFamily: "",
    
      showNewGroupModal: false,
      showEditGroupModal: false,
      showAssignParametersModal: false,
      showSendActionModal: false,
      showAssignDeviceModal: false,

      paramsCount: "100",
      paramsHeartbeatSince: moment().subtract(14, "day"),
      paramsProductFamilyId: "all",
      paramsStatus: "active",
      paramsOTAChannel: "",
    
      sendAction: "ota",
      sendActionOTATarget: "",

      linkDeviceId: "",
      
      selectedDeviceForUnlink: {},
      showUnlinkModal: false,
    };

    this.updateField = this.updateField.bind(this);
    this.updateHeartbeatSince = this.updateHeartbeatSince.bind(this);

    this.toggelEditModal = this.toggelEditModal.bind(this);
    this.toggleActionModal = this.toggleActionModal.bind(this);
    this.toggleRandomAssignModal = this.toggleRandomAssignModal.bind(this);
    this.toggleCreateModal = this.toggleCreateModal.bind(this);
    this.toggleAssignDeviceModal = this.toggleAssignDeviceModal.bind(this);

    this.fetchDeviceGroups = this.fetchDeviceGroups.bind(this);
    this.createGroup = this.createGroup.bind(this);
    this.selectGroup = this.selectGroup.bind(this);
    this.deleteGroup = this.deleteGroup.bind(this);
    this.editGroup = this.editGroup.bind(this);
    this.assignRandomDevices = this.assignRandomDevices.bind(this);

    this.linkDevice = this.linkDevice.bind(this);
    this.unlinkDevice = this.unlinkDevice.bind(this);
    this.sendAction = this.sendAction.bind(this);
    this.refresh = this.refresh.bind(this);

    this.toggleUnlinkModal = this.toggleUnlinkModal.bind(this);
    this.unlinkDevice = this.unlinkDevice.bind(this);
  }

  public componentDidMount(){
    this.fetchDeviceGroups();
  }

  public render() {
    return (
      <Screen fileName="BackendDeviceGroups.tsx" requiredRoles={["backend"]}>
        <div className="row" style={{ marginBottom: 20 }}>
          <div className="col-3">
            <Card loading={this.state.loading} title="Device Groups">
              {this.state.allGroups.length === 0 && (
                <div className="row">
                  <div className="col-12">
                    <strong>No groups match that criteria.</strong>
                  </div>
                </div>
              )}
              {this.state.allGroups.map((group) => {
                return <DeviceGroupRow 
                          key={group.id} 
                          group={group} 
                          onDeleteGroup={this.deleteGroup}
                          onEditGroup={this.editGroup}
                          onSelectGroup={this.selectGroup}                          
                        />
              })}
              <div className="row">
                <div className="col-12">
                  <button className="btn btn-block btn-primary" onClick={this.toggleCreateModal}>Create New Group</button>
                </div>
              </div>
            </Card>
          </div>
          {this.state.selectedGroup.id && this.state.selectedGroup.id !== 0 && (
            <div className="col-9">
              <Table
                title={`Devices in ${this.state.selectedGroup.name}`}
                loading={this.state.loading}
                rows={this.state.selectedGroup.tableDevices}
                columns={deviceColumns}
                postElement={(
                  <div>
                    <div className="row" style={{marginBottom: 10}}>
                      <div className="col-12">
                        <button className="btn btn-block btn-primary" onClick={this.refresh}>Refresh List</button>
                      </div>
                    </div>
                    <div className="row" style={{marginBottom: 10}}>
                      <div className="col-12">
                        <button className="btn btn-block btn-primary" onClick={this.toggleAssignDeviceModal}>Add Device</button>
                      </div>
                    </div>
                    <div className="row" style={{marginBottom: 10}}>
                      <div className="col-12">
                        <button className="btn btn-block btn-primary" onClick={this.toggleRandomAssignModal}>Add Random Devices</button>
                      </div>
                    </div>
                    <div className="row" style={{marginBottom: 10}}>
                      <div className="col-12">
                        <button className="btn btn-block btn-primary" onClick={this.toggleActionModal}>Send Action to Devices</button>
                      </div>
                    </div>
                  </div>
                )}
              />
            </div>
          )}
        </div>

        <Modal show={this.state.showNewGroupModal} onHide={this.toggleCreateModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              Create New Group
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              This will create a new logical grouping for devices that can be used for things like mass setting OTA channels.
            </p>
            <div className="form-group">
              <label>Group Name</label>
              <input type="text" className="form-control" id="newGroupName" value={this.state.newGroupName} onChange={this.updateField} />
            </div>
            <div className="form-group">
              <label>Product Family</label>
              <select className="form-control" id="newGroupProductFamily" value={this.state.newGroupProductFamily} onChange={this.updateField}>
                <option value="all">All - No Restrictions</option>
                <option value="23">Freedom Collar</option>
                <option value="24">Small Pet Collar</option>
              </select>
            </div>
            <div className="form-group">
              <label>Group Type</label>
              <select className="form-control" id="newGroupType" value={this.state.newGroupType} onChange={this.updateField}>
                <option value="firmware">Primarily for OTA and Firmware</option>
                <option value="general">General / Other</option>
              </select>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <LoadingButton
              loading={this.state.loading}
              className="btn btn-primary btn-block"
              loadingStyles={{ textAlign: "center", width: "100%" }}
              onClick={this.createGroup}
            >
              Create Group
            </LoadingButton>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.showAssignDeviceModal} onHide={this.toggleAssignDeviceModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              Assign a Device to this Group
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              To link a device, please add the device id (NOT SERIAL) here. Eventually, we will add the ability to enter a serial into this search as well.
            </p>
            <div className="form-group">
              <label>Device ID (NOT SERIAL)</label>
              <input type="text" className="form-control" id="linkDeviceId" value={this.state.linkDeviceId} onChange={this.updateField} />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <LoadingButton
              loading={this.state.loading}
              className="btn btn-primary btn-block"
              loadingStyles={{ textAlign: "center", width: "100%" }}
              onClick={this.linkDevice}
            >
              Link Device Group
            </LoadingButton>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.showAssignParametersModal} onHide={this.toggleRandomAssignModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              Assign Random Devices
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              You can set any of the following parameters to narrow down the assignment. By hitting "Assign", a random subset of devices matching these parameters will be added if possible. 
            </p>
            <p><strong>Warning: </strong>Assigning new random devices <em>will remove all existing linked devices from the group!</em> Please be sure you know what you are doing here as this cannot be undone!</p>
            <div className="form-group">
              <label>Number of Devices</label>
              <input type="text" className="form-control" id="paramsCount" value={this.state.paramsCount} onChange={this.updateField} />
            </div>
            <div className="form-group">
              <label>Product Family</label>
              <select className="form-control" id="paramsProductFamilyId" value={this.state.paramsProductFamilyId} onChange={this.updateField}>
                <option value="all">Any</option>
                <option value="23">Freedom Collar</option>
                <option value="24">Small Pet Collar</option>
              </select>
            </div>
            <div className="form-group">
              <label>Status</label>
              <select className="form-control" id="paramsStatus" value={this.state.paramsStatus} onChange={this.updateField}>
                <option value="">Any</option>
                <option value="active">Only Active</option>
                <option value="pending">Only Pending</option>
              </select>
            </div>
            <div className="form-group">
              <label>Heartbeat Since</label>
              <DatePicker date={this.state.paramsHeartbeatSince} onDateSaved={this.updateHeartbeatSince} />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <LoadingButton
              loading={this.state.loading}
              className="btn btn-primary btn-block"
              loadingStyles={{ textAlign: "center", width: "100%" }}
              onClick={this.assignRandomDevices}
            >
              Assign Random Devices
            </LoadingButton>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.showSendActionModal} onHide={this.toggleActionModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              Send Action to Devices in Group
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              You can perform an operation on all of the devices in the group. Currently, only changing the OTA channel is allowed.
            </p>
            <div className="form-group">
              <label>Selected Operation</label>
              <select className="form-control" id="sendAction" value={this.state.sendAction} onChange={this.updateField}>
                <option value="ota">Set OTA Channel</option>
              </select>
            </div>

            {this.state.sendAction === "ota" && (
              <div className="form-group">
                <label>Target OTA Channel</label>
                <input type="text" className="form-control" id="sendActionOTATarget" value={this.state.sendActionOTATarget} onChange={this.updateField} />
              </div>
            )}
            
          </Modal.Body>
          <Modal.Footer>
            <LoadingButton
              loading={this.state.loading}
              className="btn btn-primary btn-block"
              loadingStyles={{ textAlign: "center", width: "100%" }}
              onClick={this.sendAction}
            >
              Begin Rollout
            </LoadingButton>
          </Modal.Footer>
        </Modal>


        <Modal show={this.state.showUnlinkModal} onHide={this.toggleUnlinkModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              Unlink {this.state.selectedDeviceForUnlink.id} ({this.state.selectedDeviceForUnlink.productId})
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              This will remove the device from the group. Are you sure?
            </p>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-block btn-primary" onClick={this.unlinkDevice}>Yes, Remove From Group</button>
          </Modal.Footer>
        </Modal>
      </Screen>
    );
  }

  private updateField(e: any) {
    const ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private updateHeartbeatSince(newDate: moment.Moment){
    const ns: any = this.state;
    ns["paramsHeartbeatSince"] = newDate;
    this.setState(ns);
  }

  private toggleCreateModal(){
    this.setState({ showNewGroupModal: !this.state.showNewGroupModal});
  }

  private toggelEditModal(){
    this.setState({ showEditGroupModal: !this.state.showEditGroupModal});
  }

  private toggleRandomAssignModal(){
    this.setState({ showAssignParametersModal: !this.state.showAssignParametersModal});
  }

  private toggleAssignDeviceModal(){
    this.setState({ showAssignDeviceModal: !this.state.showAssignDeviceModal});
  }

  private toggleActionModal(){
    this.setState({ showSendActionModal: !this.state.showSendActionModal});
  }

  private toggleUnlinkModal(){
    this.setState({ showUnlinkModal: !this.state.showUnlinkModal});
  }

  private async fetchDeviceGroups(){
    this.setState({ loading: true }, async () => {
      try{
        const result = await DeviceGroupsAPI.getDeviceGroups("wagz2");
        this.setState({ loading: false, allGroups: result.body.data});
      }catch(err){
        this.setState({ loading: false });
      }
    });
  }

  private createGroup(){
    if(this.state.newGroupName.trim() === ""){
      return error("Group name cannot be blank.");
    }
    this.setState({ loading: true}, async () => {
      try{
        const data = {
          name: this.state.newGroupName.trim(),
          productFamily: this.state.newGroupProductFamily,
          groupType: this.state.newGroupType,
        };
        const result = await DeviceGroupsAPI.createDeviceGroup("wagz2", data);
        const allGroups = [result.body.data, ...this.state.allGroups];
        this.setState({ loading: false, allGroups, showNewGroupModal: false, newGroupName: "" }, () => {
          this.selectGroup(result.body.data.id);
        })
      }catch(err){
        error("Could not create that group.");
        this.setState({ loading: false });
      }
    });
  }

  private selectGroup(groupId: number){
    this.setState({ loading: true }, async () => {
      try{
        const result = await DeviceGroupsAPI.getDeviceGroup("wagz2", groupId);
        // we need to process the rows
        const group = result.body.data;
        group.tableDevices = this.processRawRowsForTable(result.body.data.devices);
        this.setState({ loading: false, selectedGroup: group});
      }catch(err){
        // ???
        console.log(err);
      }
    });
  }

  private editGroup(groupId: number, data: any){
    this.setState({ loading: true }, async () => {
      try{
        await DeviceGroupsAPI.updateDeviceGroup("wagz2", groupId, data);
        const allGroups: any[] = [];
        data.id = groupId;
        for(const g of this.state.allGroups){
          if(g.id !== groupId){
            allGroups.push(g);
          } else {
            allGroups.push(data);
          }
        }
        this.setState({loading: false, allGroups});
      }catch(err){
        error("Could not save those changes.");
        this.setState({ loading: false });
      }
    })
  }

  private deleteGroup(groupId: number, data: any){
    this.setState({ loading: true }, async () => {
      try{
        await DeviceGroupsAPI.deleteDeviceGroup("wagz2", groupId);
        const allGroups: any[] = [];
        for(const g of this.state.allGroups){
          if(g.id !== groupId){
            allGroups.push(g);
          }
        }
        this.setState({loading: false, allGroups});
      }catch(err){
        error("Could not delete that group.");
        this.setState({ loading: false });
      }
    })
  }

  private assignRandomDevices(){
    this.setState({ loading: true }, async () => {
      try{
        const data = {
          count: parseInt(this.state.paramsCount, 10),
          productFamilyId: this.state.paramsProductFamilyId,
          status: this.state.paramsStatus,
          otaChannel: this.state.paramsOTAChannel,
          heartbeatSince: this.state.paramsHeartbeatSince.format("YYYY-MM-DDTHH:mm:ss") + "Z",
        };
        const result = await DeviceGroupsAPI.assignRandomDevicesToGroup("wagz2", this.state.selectedGroup.id, data);
        const len = result.body.data.devices.length;
        success(`${len} devices successfully added!`);
        this.setState({ loading: false, showAssignParametersModal: false }, () => {
          this.selectGroup(this.state.selectedGroup.id);
        });
      }catch(err){
        error("Could not assign random devices with those parameters.");
        this.setState({ loading: false });
      }
    })
  }

  private unlinkDevice(){
    this.setState({ loading: true }, async () => {
      const deviceId = this.state.selectedDeviceForUnlink.id;
      try{
        await DeviceGroupsAPI.unlinkDeviceFromGroup("wagz2", this.state.selectedGroup.id, deviceId);
        const selectedGroup = this.state.selectedGroup;
        const currentDevices: any[] = selectedGroup.devices ? selectedGroup.devices : [];
        const devices: any[] = [];
        for(const d of currentDevices){
          if(d.id !== deviceId){
            devices.push(d);
          }
        }
        selectedGroup.devices = devices;
        success("Device unlinked!");
        this.setState({ loading: false, selectedGroup, showUnlinkModal: false }, () => {this.refresh(); });
      }catch(err){
        error("Could not unlink that device.");
        this.setState({ loading: false });
      }
    })
  }

  private sendAction(){
    this.setState({ loading: true }, async () => {
      try{
        // for now, we only allow the ota operation, so we 
        // just support that
        const data = {
          target: this.state.sendActionOTATarget,
        }
        await DeviceGroupsAPI.sendActionToGroup("wagz2", this.state.selectedGroup.id, this.state.sendAction, data);
        success("Operation is being rolled out. It may take a few minutes to propagate so you may need to check back in a bit.");
        this.setState({ loading: false, showSendActionModal: false, sendActionOTATarget: ""}, () => {
          this.refresh();
        });
      }catch(err){
        error("Could not send that action to those devices");
        this.setState({ loading: false, showSendActionModal: false });
      }
    });
  }

  private linkDevice(){
    if(this.state.linkDeviceId.trim() === ""){
      return error("You must specify a valid device id.");
    }
    this.setState({ loading: true }, async () => {
      try{
        const deviceId = parseInt(this.state.linkDeviceId, 10);
        await DeviceGroupsAPI.linkDeviceToGroup("wagz2", this.state.selectedGroup.id, deviceId);
        this.setState({ linkDeviceId: "", showAssignDeviceModal: false }, () => {
          this.refresh();
        });
      }catch(err){
        error("Could not add that device. Make sure it is the proper product family and status.");
        this.setState({ loading: false, showAssignDeviceModal: false });
      }
    })
  }

  private refresh(){
    this.selectGroup(this.state.selectedGroup.id);
  }

  private processRawRowsForTable(rawRows: any[]): any[] {
    const rows: any[] = [];
    if(!rawRows || rawRows.length === 0){
      return [];
    }
    for(const r of rawRows){
      r.familyDisplay = familyToDisplay(r.productFamilyId);
      r.remove = (<FontAwesomeIcon icon={faTrash} onClick={() => {
        this.setState({
          selectedDeviceForUnlink: r,
          showUnlinkModal: true,
        })
      }} />)
      r.view = (<a href={`/support/devices/${r.id}`} target="_new"><FontAwesomeIcon icon={faEye} /></a>)
      rows.push(r);
    }
    return rows;
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DeviceGroupsScreen) as any);

const deviceColumns = [
  {
    "label": "",
    "field": "view",
  },
  {
    "label": "ID",
    "field": "id",
  },
  {
    "label": "Type",
    "field": "deviceType",
  },
  {
    "label": "Serial",
    "field": "productId",
  },
  {
    "label": "Family",
    "field": "familyDisplay",
  },
  {
    "label": "Status",
    "field": "status",
  },
  {
    "label": "Heartbeat",
    "field": "heartbeat",
  },
  {
    "label": "OTA",
    "field": "otaChannel",
  },
  {
    "label": "FW",
    "field": "firmwareVersion",
  },
  {
    "label": "",
    "field": "remove",
  },
];