import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Popover, OverlayTrigger } from "react-bootstrap";
import { MetricsAPI } from "src/api";
import Table from "src/components/structure/Table";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { Modal } from "react-bootstrap";

import moment from "moment";
import { DatePicker } from "src/components/structure";

interface IAllEventsScreenProps {
  loading: boolean;
}

interface IAllEventsScreenState {
  loading: boolean;

  allEvents: any[];
  allKnownEvents: any[];
  filteredEvents: any[],
  knownEvents: string[];
  start: moment.Moment;
  end: moment.Moment;
  domain: string;
  filter: string;
  count: number;
  offset: number;
  selectedEvent: any;
  showSelectedEventModal: boolean;
}


const hoverMessage = (
  <p>
    Hover over the event name to see the message for that event.
  </p>
);

export default class AllEventsScreen extends Component<
  IAllEventsScreenProps,
  IAllEventsScreenState
> {
  constructor(props: IAllEventsScreenProps) {
    super(props);

    this.state = {
      loading: false,

      allEvents: [],
      allKnownEvents: [],
      filteredEvents: [],
      start: moment().subtract(1, "days"),
      end: moment(),
      count: 150,
      offset: 0,
      filter: "",
      knownEvents: [],
      domain: "all",

      selectedEvent: {},
      showSelectedEventModal: false,
    };

    this.updateField = this.updateField.bind(this);
    this.getEvents = this.getEvents.bind(this);
    this.setEnd = this.setEnd.bind(this);
    this.setStart = this.setStart.bind(this);
    this.refreshEvents = this.refreshEvents.bind(this);
    this.updateFilter = this.updateFilter.bind(this);
    this.filter = this.filter.bind(this);
    this.toggleShowSelectedEventModal = this.toggleShowSelectedEventModal.bind(this);
  }

  componentDidMount() {
    this.getEvents();
  }

  render() {
    return (
      <div className="row">
        <div className="col-12">
            <Table
              title="Shown Events"
              loading={this.props.loading || this.state.loading}
              showDownload={true}
              csvFileName={`eventsFor-platform-${this.state.start}-${this.state.end}-${this.state.domain}`}
              columns={eventCols}
              rows={this.state.filteredEvents}
              preElement={(
                <>
                  <div className="row">
                    <div className="col-12">
                      For now, we only return at most 150 events since they could be quite large. We will tweak that number as we see the performance implications. To retrieve more events, try to narrow your search (the first three filters) and hit Refresh. {hoverMessage}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-3">
                      <label>Fetch This Domain</label>
                      <select className="form-control" id="domain" onChange={this.updateField} value={this.state.domain} >
                        <option value="all">All</option>
                        {this.state.allKnownEvents.map((event: any) => {
                          return(
                            <option key={event.domain} value={event.domain}>{event.domain}</option>
                          )
                        })}
                      </select>
                    </div>
                    <div className="col-3">
                      <label>From</label>
                      <DatePicker date={this.state.start} onDateSaved={this.setStart} />
                    </div>
                    <div className="col-3">
                      <label>To</label>
                      <DatePicker date={this.state.end} onDateSaved={this.setEnd} />
                    </div>
                    <div className="col-3">
                      <label>Filter This List</label>
                      <select className="form-control" id="eventsFilter" onChange={this.updateFilter} value={this.state.filter} >
                        <option value="">None</option>
                        {this.state.knownEvents.map((event: string) => {
                          return(
                            <option key={event} value={event}>{event}</option>
                          )
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="row" style={{marginTop: 5, marginBottom: 15}}>
                    <div className="col-12">
                      <button className="btn btn-block btn-primary" onClick={this.refreshEvents}>Refresh</button>
                    </div>
                  </div>
                </>
              )}
            />
        </div>

        <Modal
          show={this.state.showSelectedEventModal}
          onHide={this.toggleShowSelectedEventModal}
          dialogClassName="modal-xl"
        >
          <Modal.Header closeButton>
            <Modal.Title>Event Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row row-margin-bottom">
              <div className="col-2">
                <label>Id</label>
                <input type="text" disabled={true} className="form-control" value={this.state.selectedEvent.id} />
              </div>
              <div className="col-3">
                <label>Domain</label>
                <input type="text" disabled={true} className="form-control" value={this.state.selectedEvent.domain} />
              </div>
              <div className="col-2">
                <label>User</label>
                <input type="text" disabled={true} className="form-control" value={this.state.selectedEvent.userId} />
              </div>
              <div className="col-2">
                <label>Pet</label>
                <input type="text" disabled={true} className="form-control" value={this.state.selectedEvent.petId} />
              </div>
              <div className="col-2">
                <label>Device</label>
                <input type="text" disabled={true} className="form-control" value={this.state.selectedEvent.deviceId} />
              </div>
            </div>

            <div className="row row-margin-bottom">
              <div className="col-2">
                <label>Visibility</label>
                <input type="text" disabled={true} className="form-control" value={this.state.selectedEvent.visibility} />
              </div>
              <div className="col-3">
                <label>Occurred</label>
                <input type="text" disabled={true} className="form-control" value={this.state.selectedEvent.time} />
              </div>
              <div className="col-3">
                <label>Received</label>
                <input type="text" disabled={true} className="form-control" value={this.state.selectedEvent.receivedOn} />
              </div>
              <div className="col-2">
                <label>Category</label>
                <input type="text" disabled={true} className="form-control" value={this.state.selectedEvent.category} />
              </div>
              <div className="col-2">
                <label>Dismissed</label>
                <input type="text" disabled={true} className="form-control" value={this.state.selectedEvent.dismissed} />
              </div>
            </div>

            <div className="row row-margin-bottom">
              <div className="col-6">
                <label>Message</label>
                <textarea disabled={true} className="form-control" value={this.state.selectedEvent.message} />
              </div>
              <div className="col-6">
                <label>Additional Data</label>
                <pre>{JSON.stringify(this.state.selectedEvent.additionalData, null, 4)} </pre>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-primary"
              onClick={this.toggleShowSelectedEventModal}
            >
              Done
            </button>
          </Modal.Footer>
        </Modal>
      </div>      
    );
  }

  updateField(e: any) {
    let ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  toggleShowSelectedEventModal(){
    this.setState({ showSelectedEventModal: !this.state.showSelectedEventModal });
  }

  getEvents() {
    this.setState({ loading: true }, async () => {
      try {
        let rawEvents: any[] = [];
        const start = this.state.start.format("YYYY-MM-DDTHH:mm:ss") + "Z";
        const end = this.state.end.format("YYYY-MM-DDTHH:mm:ss") + "Z";

        const eventsResult = await MetricsAPI.getAllEventsOnPlatform(
          "wagz2",
          start,
          end,
          this.state.domain,
          parseInt(this.state.count + ""),
          parseInt(this.state.offset + ""),
          {}
        );
        rawEvents = eventsResult.body.data;

        let allKnownEvents = this.state.allKnownEvents;
        if(this.state.allKnownEvents.length === 0){
          // only do this the first time
          const allKnownEventsResult = await MetricsAPI.getKnownEvents();
          allKnownEvents = allKnownEventsResult.body.data;
        }

        const processedEvents = this.processEvents(rawEvents);
        const filteredEvents = this.filter(processedEvents.events, this.state.filter);
        this.setState({
          loading: false,
          allEvents: processedEvents.events,
          knownEvents: processedEvents.foundEvents,
          filteredEvents,
          allKnownEvents,
        });
      } catch (err) {
        this.setState({ loading: false });
      }
    });
  }

  private processEvents(events: any[]): any {
    const eventsList = [];
    let eventsMap: any = {};

    for (let event of events) {
      eventsMap[event.domain] = true;
      const pop = (
        <Popover id="popover-trigger-click" title="Message">
          <div style={{ padding: 5 }}>{event.message}</div>
        </Popover>
      );

      // set up any links
      let petLink: any = null;
      let deviceLink: any = null;
      let userLink: any = (
        <Link
          to={`/support/users/${event.userId}`}
          style={{ color: "blue", textDecoration: "underline" }}
        >
          {event.userId}
        </Link>
      );

      if (event.petId !== 0) {
        petLink = (
          <Link
            to={`/support/users/${event.userId}`}
            style={{ color: "blue", textDecoration: "underline" }}
          >
            {event.petId}
          </Link>
        );
      }
      if (event.deviceId !== 0) {
        deviceLink = (
          <Link
            to={`/support/devices/${event.deviceId}`}
            style={{ color: "blue", textDecoration: "underline" }}
          >
            {event.deviceId}
          </Link>
        );
      }
      const domain = event.domain;

      // push
      eventsList.push({
        domain,
        popoverDomain: (
          <OverlayTrigger trigger={["hover", "focus"]} overlay={pop}>
            <span style={{ fontSize: 12 }}>{domain}</span>
          </OverlayTrigger>
        ),
        time: moment.utc(event.time).format("MM/DD/YYYY HH:mm"),
        receivedOn: moment.utc(event.receivedOn).format("MM/DD/YYYY HH:mm"),
        petLink,
        deviceLink,
        userLink,
        category: event.category,
        visibility: event.visibility,
        view: (
          <FontAwesomeIcon icon={faEye} className="link link-light" onClick={() => {
            this.setState({
              selectedEvent: event,
              showSelectedEventModal: true,
            })
          }} />
        ),
      });
    }
    
    const foundEvents = Object.keys(eventsMap);
    foundEvents.sort((a, b) => {
      return a > b ? 1 : -1;
    })
    return {
      events: eventsList,
      foundEvents,
    };
  }

  private filter(events: any[],  filter: string){
    if(filter === ""){
      return events;
    }
    const filteredEvents = [];
    for(const e of events){
      if(e.domain === filter){
        filteredEvents.push(e);
      }
    }
    return filteredEvents;
  }

  private updateFilter(e: any){
    const filter = e.target.value;
    let events = [];
    events = this.filter(this.state.allEvents, filter);
    this.setState({ filteredEvents: events, filter });
    
  }

  private setStart(newDate: moment.Moment) {
    this.setState({ start: newDate });
  }

  private setEnd(newDate: moment.Moment) {
    this.setState({ end: newDate });
  }

  private refreshEvents(){
    this.getEvents(); // this was originally larger, but leaving this as a call out in case we need more logic on refreshing
  }
}


const eventCols = [
  {
    label: "Time",
    field: "time",
  },
  {
    label: "Event",
    field: "popoverDomain",
  },
  {
    label: "Received On",
    field: "receivedOn",
  },
  {
    label: "User",
    field: "userLink",
  },
  {
    label: "Pet",
    field: "petLink",
  },
  {
    label: "Device",
    field: "deviceLink",
  },
  {
    label: "Category",
    field: "category",
  },
  {
    label: "",
    field: "view",
  },
];
