import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Select from "react-select";
import { Card, Screen } from "src/components/structure";
import { BillingAPI } from "src/api";
import BillingPlan from "src/components/structure/BillingPlan";

import { bindActionCreators } from "redux";
import * as UserActions from "src/reducers/userReducer";

import "react-accessible-accordion/dist/fancy-example.css";
import { productFamilies } from "src/utils/items";

interface IManagePlansProps {
  loading: boolean;
  userState: any;
}

interface IManagePlansState {
  loading: boolean;
  plans: any[];
  productFamily: string;
}
class ManagePlans extends Component<IManagePlansProps, IManagePlansState> {
  constructor(props: IManagePlansProps) {
    super(props);

    this.state = {
      loading: false,
      plans: [],
      productFamily: "",
    };

    this.updateField = this.updateField.bind(this);
    this.setPlanDisplay = this.setPlanDisplay.bind(this);
    this.fetchPlans = this.fetchPlans.bind(this);
    this.handlePlanUpdate = this.handlePlanUpdate.bind(this);
  }

  componentDidMount() {
    this.fetchPlans();
  }

  updateField(e: any) {
    let ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  setPlanDisplay() {
    const availablePlans = [];
    if (this.state.plans.length === 0) {
      availablePlans.push(
        <strong key={1}>Please use the drop down to the left to select a device and view its plans</strong>
      );
    } else {
      let index = 0;
      for (let plan of this.state.plans) {
        if (plan.showPlan) {
          index += 1;
          availablePlans.push(
            <BillingPlan
              key={index}
              plan={plan}
              loading={false}
              onPlanUpdated={this.handlePlanUpdate}
            />
          );
        }
      }
    }
    return availablePlans;
  }

  fetchPlans() {
    this.setState({ loading: true }, async () => {
      try {
        const res = await BillingAPI.getPlansForFamily(this.state.productFamily);
        this.setState({
          loading: false,
          plans: res.body.data.data,
        });
      } catch (err) {
        this.setState({ loading: false });
      }
    });
  }

  render() {
    return (
      <Screen id="manage_plans" requiredRoles={["product"]}>
        <div className="row">
          <div className="col-5">
            <Card title="Info">
              <p>
                This is a page where members of the Product team can view and manage the billing plans that are available to the users. When editing, the "Plan Description" is displayed in raw html format. Please ask the Admin team before editing if you have any questions.
              </p>
              <p>
                <strong>Editing these will modify the plan info in the Wagz database and impact what the customer sees when selecting a plan during pre-registration.  It will NOT make the change in Chargify.</strong>
              </p>
              <p>
                Use the dropdown menu below to select the product you wish to view the plans for.
              </p>
          <div className="form-group" style={{ width: "50%" }}>
            <label style={{ fontSize: 16, marginLeft: 5 }}>
              Product:
            </label>
            <Select
              options={productFamilies}
              onChange={this.updateSelection}
              hideSelectedOptions={true}
            />
          </div>
            </Card>
          </div>
          <div className="col-6">
            <Card
              title="Manage Plans"
              loading={this.props.loading || this.state.loading}
            >{this.setPlanDisplay()}
            </Card>
          </div>
        </div>
      </Screen>
    );
  }

  updateSelection = (input: any) => {
    let ns = this.state;
    ns[input.field] = input.value;
    this.setState(ns);
    this.fetchPlans();
  };

  private handlePlanUpdate() {
    this.fetchPlans();
  }
}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ManagePlans) as any
);
