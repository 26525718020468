import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as UserActions from "src/reducers/userReducer";
import Screen from "src/components/structure/Screen";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import DeviceTab from "./ReportsTabs/DeviceReports";
import UserTab from "./ReportsTabs/UserReports";
import OrderTab from "./ReportsTabs/OrderReports";

interface IReportsPageProps {
  match: any;
  history: any;
  location: any;
  userActions: any;
  userState: any;
}

interface IReportsPageState {
  loading: boolean;
}

class ReportsPage extends Component<IReportsPageProps, IReportsPageState> {
  constructor(props: IReportsPageProps) {
    super(props);

    this.state = {
      loading: false,
    };

  }

  render() {
    return (
      <Screen shouldAuthCheck={true} requiredRoles={["reporting"]}> 
        <div className="row">
          <div className="col-sm-12 col-lg-12">
            <div>
              <h1>Reports</h1>
            </div>
            <div>
              <Tabs>
                <TabList>
                  <Tab>Users</Tab>
                  <Tab>Devices</Tab>
                  <Tab>Orders</Tab>
                </TabList>
                <TabPanel>
                  <UserTab />
                </TabPanel>
                <TabPanel>
                  <DeviceTab />
                </TabPanel>
                <TabPanel>
                  <OrderTab />
                </TabPanel>
              </Tabs>
            </div>
          </div>
        </div>
      </Screen>
    );
  }

}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ReportsPage) as any
);
