import React, { Component } from "react";
import {  withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Card from "src/components/structure/Card";
import Screen from "src/components/structure/Screen";
import * as Alert from "src/components/structure/Alert";
import * as UserActions from "src/reducers/userReducer";
import { UserAPI } from "src/api";
import { styles } from "src/styles";

interface ILoginProps {
  location: any;
  userActions: any;
  userState: any;
}

interface ILoginState {
  id: number;
  email: string;
  password: string;
  loading: boolean;
  errorText: string;
  needsOTP: boolean;
  passcode: string;
}

const errorMessageBadLogin = "Error: We could not log you in. There are several reasons for this. Often, it's an invalid email or password. Your account may have been locked due to too many failed attempts. Or your account was disabled. You may try again or contact the Server team for more information.";
class Login extends Component<ILoginProps, ILoginState> {
  constructor(props: ILoginProps) {
    super(props);

    this.state = {
      loading: false,
      id: 0,
      email: "",
      password: "",
      errorText: "",
      needsOTP: false,
      passcode: "",
    };

    this.updateField = this.updateField.bind(this);
    this.checkEnter = this.checkEnter.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount(){
    // we want to check localstorage here; the client
    // will redirect here if it couldn't renew the token and
    // we want to log them out if needed
    if(this.props.userState.loggedIn && window.localStorage.getItem("refresh_token") === null){
      this.props.userActions.logoutUser();
    }
  }

  updateField(e: any) {
    let ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private checkEnter(e: any){
    if(e.keyCode === 13) {
      this.handleSubmit();
    }
  }

  private handleSubmit() {
    //check validation
    const email = this.state.email.trim();
    const password = this.state.password.trim();
    const passcode = this.state.passcode.trim();
    if (email === "" || password === "") {
      return Alert.error("Email and password are required");
    }
    if(passcode === "" && this.state.needsOTP){
      return Alert.error("The Authenticator Passcode is required");
    }

    this.setState({ loading: true, errorText: "" }, async () => {
      try {
        const result = await UserAPI.login(this.state.email, this.state.password, {passcode: this.state.passcode});
        
        // we need to handle otp checks here
        if(result.body.data.otpRequired){
          return this.setState({loading: false, needsOTP: true});
        }

        let user = result.body.data;
        for(const r of user.roles){
          if(r === "administrator"){
            user.isAdministrator = true;
          }
          if(r === "support"){
            user.isSupport = true;
          }
          if(r === "product"){
            user.isProduct = true;
          }
          if(r === "reporting"){
            user.isReporting = true;
          }
          if(r === "backend"){
            user.isBackend = true;
          }
          if(r === "qa"){
            user.isQA = true;
          }
          if(r === "mobile"){
            user.isMobile = true;
          }
          if(r === "firmware"){
            user.isFirmware = true;
          }
        }
        // store the initial tokens in localstorage
        window.localStorage.setItem("access_token", user.accessToken);
        window.localStorage.setItem("refresh_token", user.refreshToken);
        window.localStorage.setItem("expires", user.expires);
        window.localStorage.setItem("userId", user.id);
        this.props.userActions.loginUser({ loggedIn: true, user});
      } catch (error) {
        this.setState({ loading: false, passcode: "", needsOTP: false, errorText: errorMessageBadLogin });
      }
    });
  }

  render() {
    if(this.props.userState.loggedIn){
      return(
        <Screen id="login">
          <div className="row">
          <div style={styles.itemAlign}>
          <div style={styles.loginStyles}>
              <Card title="Login">
                Welcome back, {this.props.userState.user.firstName}!
              </Card>
              </div>
            </div>
          </div>
        </Screen>
      );
    }
    if(this.state.needsOTP){
      return (
      <Screen id="login">
        <div className="row">
          <div style={styles.itemAlign}>
          <div style={styles.loginStyles}>
            <Card title="Login">
              <div className="form-group">
                <p>Please enter the Time-based One Time Password that is shown on your authenticator app.</p>
              </div>
              <div className="form-group">
                <label>Passcode</label>
                <input type="text" id="passcode" value={this.state.passcode} onChange={this.updateField} className="form-control" onKeyUp={this.checkEnter} />
              </div>
              <div className="form-group">
                <button className="btn btn-block btn-send" onClick={this.handleSubmit}>Enter OTP</button>
              </div>
              {this.state.errorText !== "" && (
                <div className="form-group alert alert-danger" role="alert">
                  <p>{this.state.errorText}</p>
                </div>
              )}
            </Card>
            </div>
          </div>
        </div>
      </Screen>
      )
    }
    return (
      <Screen id="login">
        <div className="row">
          <div style={styles.itemAlign}>
          <div style={styles.loginStyles}>
            <Card title="Login">
              <div className="form-group">
                <label>Email</label>
                <input type="text" id="email" value={this.state.email} onChange={this.updateField} className="form-control" onKeyUp={this.checkEnter} />
              </div>
              <div className="form-group">
                <label>Password</label>
                <input type="password" id="password" value={this.state.password} onChange={this.updateField} className="form-control" onKeyUp={this.checkEnter} />
              </div>
              <div className="form-group">
                <button className="btn btn-block btn-send" onClick={this.handleSubmit}>Login</button>
              </div>
              {this.state.errorText !== "" && (
                <div className="form-group alert alert-danger" role="alert">
                  <p>{this.state.errorText}</p>
                </div>
              )}
            </Card>
            </div>
          </div>
        </div>
      </Screen>
    )
  }
}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Login) as any
);
