/// <reference path="../../../declaration.d.ts" />
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Table from "src/components/structure/Table";
import { Popover, OverlayTrigger } from "react-bootstrap";
import { capitalizeFirstLetter, formatFWVersion } from "src/utils/utilities";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';

import * as UserActions from "src/reducers/userReducer";
import { IDevice } from "src/api/devices";
import { styles } from "src/styles";

const columns = [
  {
    label: "",
    field: "view",
    sort: "disabled"
  },
  {
    label: "ID",
    field: "id",
    sort: "asc",
  },
  {
    label: "Serial Number",
    field: "productId",
    sort: "asc",
  },
  {
    label: "Nickname",
    field: "nickname",
    sort: "asc",
  },
  {
    label: "Type",
    field: "deviceType",
    sort: "asc",
  },
  {
    label: "Status",
    field: "status",
    sort: "asc",
  },
  {
    label: "Registered On",
    field: "registeredOn",
    sort: "asc",
  },
  {
    label: "Registered By",
    field: "registeredBy",
    sort: "asc",
  },
  {
    label: "OTA Channel",
    field: "otaChannel",
    sort: "asc",
  },
  {
    label: "Last Heartbeat",
    field: "heartbeat",
    sort: "asc",
  },
  {
    label: "Onboarded On",
    field: "onboardedOn",
    sort: "asc",
  },
  {
    label: "FW Version",
    field: "firmwareVersion",
    sort: "asc",
  },
  {
    label: "Modem FW Version",
    field: "modemFirmwareVersion",
    sort: "asc",
  },
  {
    label: "ICCID",
    field: "simId",
    sort: "asc",
  },
];

interface IDeviceListProps {
  devices: IDevice[];
  title?: string;
}

interface IDeviceListState {
  loading: boolean;
  processedDevices: any;
  csvData: any[];
  showUnfinishedActivations: boolean;
}


const hoverMessage = (
  <p>
    Checking this filter will show only devices with a “pending” status and where "Registered On" is {">"}= 14 days ago.
  </p>
);
const pop = (
  <Popover id="popover-trigger-click" title="Message">
    <div style={{ margin: 10 }}>{hoverMessage}</div>
  </Popover>
);

class DeviceList extends Component<IDeviceListProps, IDeviceListState> {
  constructor(props: IDeviceListProps) {
    super(props);

    this.state = {
      loading: false,
      processedDevices: [],
      csvData: [],
      showUnfinishedActivations: false,
    };

    this.updateField = this.updateField.bind(this);
    this.populateDevices = this.populateDevices.bind(this);
    this.handleUnfinishedActivationsToggle =
    this.handleUnfinishedActivationsToggle.bind(this);
  }

  updateField(e: any) {
    let ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  componentDidMount() {
    this.populateDevices();
  }

  populateDevices() {
    this.setState({ loading: true }, async () => {
      try {
        const wagzDevices = [];
        const csvData: any = [];
        for (let result of this.props.devices) {
          let onboarded = result.onboardedOn;
          if (onboarded.includes("1970")) {
            onboarded = "";
          }

          let registered = result.registeredOn.substring(0, 10);
          const pastTime = new Date(registered);
          const now = new Date();
          const fourteenDaysInMs = 14 * 24 * 60 * 60 * 1000;
          const timeDiffInMs = now.getTime() - pastTime.getTime();

          // simplifying this to a double conditional to avoid duplicated logic

          if (!this.state.showUnfinishedActivations || (this.state.showUnfinishedActivations && result.status === "pending" && timeDiffInMs >= fourteenDaysInMs)) {
            result.deviceType = capitalizeFirstLetter(result.deviceType);
            result.status = capitalizeFirstLetter(result.status);
            result.brand = capitalizeFirstLetter(result.brand);
            result.firmwareVersion = formatFWVersion(result.firmwareVersion);
            result.modemFirmwareVersion = result.modemFirmwareVersion.replace( "_", " ");
            result.onboardedOn = onboarded.substring(0, 10);

            csvData.push(result);
            wagzDevices.push({
              id: result.id,
              view: (
                <div style={{textAlign: "center"}}>
                  <Link to={`/support/devices/${result.id}`} className="link-light">
                    <FontAwesomeIcon icon={faEye} />
                  </Link>
                </div>
              ),
              productId: result.productId,
              nickname: result.nickname,
              simId: result.simId,
              deviceType: result.deviceType,
              status: result.status,
              registeredOn: registered,
              registeredBy: (
                <Link to={`/support/users/${result.registeredBy}`} className="link-light">
                  {result.registeredBy}
                </Link>
              ),
              brand: result.brand,
              firmwareVersion: result.firmwareVersion,
              modemFirmwareVersion: result.modemFirmwareVersion,
              otaChannel: result.otaChannel,
              onboardedOn: result.onboardedOn,
              heartbeat: result.heartbeat
            });
          }
        }
        this.setState({
          loading: false,
          processedDevices: wagzDevices,
          csvData,
        });
      } catch (err) {
        this.setState({ loading: false });
      }
    });
  }

  render() {
    return (
      <div>
        <OverlayTrigger trigger="hover" overlay={pop}>
          <div className="col-12 col-lg-3" style={styles.checkboxBox}>
            <div>
              <input
                id="showUnfinishedActivations"
                type="checkbox"
                aria-label="Only Show Unfinished Activations"
                checked={this.state.showUnfinishedActivations}
                onChange={this.handleUnfinishedActivationsToggle}
              />
            </div>
            <div style={{ marginTop: 5, marginLeft: 5, padding: 2 }}>
              <label style={{ marginLeft: 5 }} htmlFor="showPassword">
                Only Show Unfinished Activations
              </label>
            </div>
          </div>
        </OverlayTrigger>
        <div>
          <Table
            title={this.props.title ? this.props.title : "Devices"}
            csvFileName="devices"
            showDownload={true}
            columns={columns}
            rows={this.state.processedDevices}
            csvData={this.state.csvData}
          />
        </div>
      </div>
    );
  }

  private handleUnfinishedActivationsToggle() {
    this.setState({
      showUnfinishedActivations: !this.state.showUnfinishedActivations,
    });
    this.populateDevices();
  }
}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DeviceList);
