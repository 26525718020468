import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Card from "src/components/structure/Card";
import * as AppActions from "src/reducers/appReducer";
import { UserAPI } from "src/api";
import moment from "moment";
import DatePicker from "src/components/structure/DatePicker";
import Table from "src/components/structure/Table";
import { Loading } from "src/components/structure/Loading";
import { lightBlue, blue } from "@material-ui/core/colors";
import { withStyles } from "@material-ui/styles";
import Switch from "@material-ui/core/Switch";
import { Modal } from "react-bootstrap";
import GoogleMapReact from "google-map-react";

interface IWalksTabProps {
  userId: number;
}

interface IWalksTabState {
  loading: boolean;
  walks: any[];
  locations: any[];
  showWalksModal: boolean;
  start: moment.Moment;
  end: moment.Moment;
  filterToggle: boolean;

  mapType: string;
  mapTypeChecked: boolean;
  lat: any;
  lng: any;
}

const cols = [
    {
      label: "Nickname",
      field: "nickname",
    },
    {
      label: "Pet",
      field: "petName",
    },
    {
      label: "Start Time",
      field: "startTime",
    },
    {
      label: "End Time",
      field: "endTime",
    },
    {
      label: "Duration",
      field: "duration",
    },
    {
      label: "Steps",
      field: "steps",
    },
    {
      label: "Miles",
      field: "distanceInMiles",
    },
    {
      label: "Actions",
      field: "actions",
    },
  ];

const mapKey = process.env.REACT_APP_GOOGLE_MAP_KEY || "";

const MapTypeSwitch = withStyles({
  switchBase: {
    color: lightBlue[300],
    "&$checked": {
      color: blue[500],
    },
    "&$checked + $track": {
      backgroundColor: blue[400],
    },
  },
  checked: {},
  track: {},
})(Switch);

class WalksTab extends React.Component<
  IWalksTabProps,
  IWalksTabState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      walks: [],
      locations: [],
      showWalksModal: false,
      start: moment().subtract(28, "days"),
      end: moment(),
      filterToggle: false,

      mapType: "satellite",
      mapTypeChecked: true,
      lat: 0,
      lng: 0,
    };

    this.fetchWalks = this.fetchWalks.bind(this);
    this.updateStart = this.updateStart.bind(this);
    this.updateEnd = this.updateEnd.bind(this);
    this.handleSwitch = this.handleSwitch.bind(this);
    this.toggleShowWalksModal = this.toggleShowWalksModal.bind(this);
  }

  componentDidMount() {
    this.fetchWalks();
  }

  handleGoogleMapApi = (google: {
    maps: {
      Polyline: new (arg0: {
        path: { lat: number; lng: number }[];
        geodesic: boolean;
        strokeColor: string;
        strokeOpacity: number;
        strokeWeight: number;
      }) => any;
    };
    map: any;
  }) => {
    var flightPath = new google.maps.Polyline({
      path: this.state.locations,
      geodesic: true,
      strokeColor: "#11cdef",
      strokeOpacity: 1,
      strokeWeight: 5,
    });

    flightPath.setMap(google.map);
  };

  public render() {
    if (this.state.loading) {
      return <Loading />;
    }

    return (
      <div>
        <div className="row" style={{ marginTop: 30 }}>
          <div className="col-12">
            <Card title="Location Filters">
              <div className="row">
                <div className="col-4">
                  <label>From</label>
                  <DatePicker
                    date={this.state.start}
                    onDateSaved={this.updateStart}
                  />
                </div>
                <div className="col-4">
                  <label>To</label>
                  <DatePicker
                    date={this.state.end}
                    onDateSaved={this.updateEnd}
                  />
                </div>
                <div className="col-4">
                  <button
                    className="btn btn-block btn-primary"
                    onClick={this.fetchWalks}
                    style={{ marginTop: 27 }}
                  >
                    Refresh
                  </button>
                </div>
              </div>
            </Card>
          </div>
        </div>

        {this.state.walks.length === 0 ? (
          <div className="row" style={{ marginTop: 10 }}>
            <div className="col-12">
              <Card title="Locations">
                <strong>
                  <p>No walks to show for this user</p>
                </strong>
              </Card>
            </div>
          </div>
        ) : (
          <div className="row" style={{ marginTop: 10 }}>
            <div className="col-12">
              <Table
                title="Walks"
                csvFileName={`walksFor-userID-${this.props.userId}`}
                showDownload={true}
                columns={cols}
                rows={this.state.walks}
              />

              <Modal
                dialogClassName="modal-100"
                show={this.state.showWalksModal}
                onHide={this.toggleShowWalksModal}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Viewing Walk</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div
                    className="center"
                    style={{ height: "60vh", width: "100%" }}
                  >
                    <div className="row" style={{ marginLeft: 5 }}>
                      <p style={{ fontSize: 20 }}>Map</p>
                      <MapTypeSwitch
                        checked={this.state.mapTypeChecked}
                        onChange={this.handleSwitch}
                      ></MapTypeSwitch>
                      <p style={{ fontSize: 20 }}>Satellite</p>
                    </div>
                    <GoogleMapReact
                      yesIWantToUseGoogleMapApiInternals
                      onGoogleApiLoaded={this.handleGoogleMapApi}
                      bootstrapURLKeys={{
                        key: mapKey,
                      }}
                      defaultCenter={{
                        lat: this.state.lat,
                        lng: this.state.lng,
                      }}
                      defaultZoom={15}
                      options={{
                        keyboardShortcuts: false,
                        tilt: 0,
                        mapTypeId: this.state.mapType,
                      }}
                    >
                    </GoogleMapReact>
                  </div>
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
              </Modal>
            </div>
          </div>
        )}
      </div>
    );
  }

  private toggleShowWalksModal() {
    this.setState({ showWalksModal: !this.state.showWalksModal });
  }

  private handleSwitch() {
    if (this.state.mapType === "satellite") {
      this.setState({
        mapType: "roadmap",
        mapTypeChecked: !this.state.mapTypeChecked,
      });
    }
    if (this.state.mapType === "roadmap") {
      this.setState({
        mapType: "satellite",
        mapTypeChecked: !this.state.mapTypeChecked,
      });
    }
  }

  private updateStart(newDate: moment.Moment) {
    this.setState({ start: newDate });
  }

  private updateEnd(newDate: moment.Moment) {
    this.setState({ end: newDate });
  }

  private fetchWalks() {
    this.setState({ loading: true }, async () => {
        let limit = "";
        let filter = "";
        const options = {
          start: this.state.start.format("YYYY-MM-DD") + "T00:00:00Z",
          end: this.state.end.format("YYYY-MM-DD") + "T23:59:59Z",
          limit,
          filter,
        };
        try {
          const results = await UserAPI.getWalksForUser(
            "wagz",
            this.props.userId,
            options
          );  
          const userWalks = [];
          for (let result of results.body.data) {
            const userLocations: { lat: any; lng: any; }[] = [];
              for (let location of result.locations) {
                  const newLocation = {
                      "lat": location.lat,
                      "lng": location.lng
                  }
                  userLocations.push(newLocation)
              }
            let duration = moment(result.endTime).diff(moment(result.startTime), "minutes") + " minutes";
            userWalks.push(
              { 
                nickname: result.nickname,
                petName: result.petName,
                startTime: moment(result.startTime).format("MM/DD/YY hh:mm A"),
                endTime: moment(result.endTime).format("MM/DD/YY hh:mm A"),
                locations: userLocations,
                distanceInMiles: result.distanceInMiles,
                duration,
                steps: result.steps,
                actions: 
                <button
                  style={{ color: "white", width: "80%" }}
                  className="btn btn-secondary btn-block"
                  onClick={() => {
                    this.setState({
                      lat: result.locations[0].lat,
                      lng: result.locations[0].lng,
                      locations: userLocations,
                      showWalksModal: true,
                    });
                  }}
                >
                  View Walk on Map
                </button>,
              },);
          }
          this.setState({
            loading: false,
            walks: userWalks,
          });
        } catch (err) {
          this.setState({ loading: false });
        }
      });
  }
}

const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WalksTab);
