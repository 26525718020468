import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { OTAAPI } from "src/api";

import { Alert, Card, LoadingButton, Screen } from "src/components/structure";
import * as AppActions from "src/reducers/appReducer";

interface IOTACopyProps {
  appActions: any;
  deviceType: string;
  productFamily: string;
  channels: string[];
}

interface IOTACopyState {
  loading: boolean;
  selectedSourceChannel: string;
  selectedTargetChannel: string;
}

class OTACopy extends React.Component<IOTACopyProps, IOTACopyState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      selectedSourceChannel: "",
      selectedTargetChannel: "",
    };

    this.updateField = this.updateField.bind(this);
    this.copyOTAFiles = this.copyOTAFiles.bind(this);
  }

  componentDidUpdate(prevProps: IOTACopyProps){
    // if the device type or product family changed in the props, reset
    if(prevProps.deviceType !== this.props.deviceType || prevProps.productFamily !== this.props.productFamily){
      this.setState({ selectedSourceChannel: "", selectedTargetChannel: "" });
    }
  }

  public render() {
    return (
      <Screen fileName="OTACopy.tsx" requiredRoles={["backend"]}>
        <div className="row">
          <div className="col-4 offset-2">
            <Card title="Select a Channel" loading={this.state.loading} help="">
              <div className="form-group">
                <label>Select a Channel to Copy From</label>
                <select className="form-control" id="selectedSourceChannel" value={this.state.selectedSourceChannel} onChange={this.updateField}>
                  <option key="none" id="">Please select a channel</option>
                  {this.props.channels.map((channel: string) => {
                    return (<option key={channel} id={channel}>{channel}</option>)
                  })}
                </select>
              </div>
            </Card>
          </div>
          <div className="col-4">
            {this.state.selectedSourceChannel !== "" && (
              <Card title="Select a Target Channel" loading={this.state.loading} help="">
                <div className="form-group">
                  <label>Select a Channel to Copy To</label>
                  <select className="form-control" id="selectedTargetChannel" value={this.state.selectedTargetChannel} onChange={this.updateField}>
                    <option key="none" id="">Please select a channel</option>
                    {this.props.channels.map((channel: string) => {
                      return (<option key={channel} id={channel}>{channel}</option>)
                    })}
                  </select>
                </div>
              </Card>
            )}
          </div>
        </div>
        <div className="row" style={{marginBottom: 10}}>
          <div className="col-8 offset-2">
            {this.state.selectedSourceChannel !== "" && this.state.selectedTargetChannel !== "" && (
              <>
                <Card>
                  <p style={{fontWeight: "bold"}}>WARNING: This will replace the files in the {this.state.selectedTargetChannel} with the files in the {this.state.selectedSourceChannel} and CANNOT be undone!</p>
                  <LoadingButton className="btn btn-block btn-success" loading={this.state.loading} onClick={this.copyOTAFiles}>Copy!</LoadingButton>
                </Card>
              </>

            )}
          </div>
        </div>
      </Screen>
    );
  }

  private updateField(e: any){
    const ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private copyOTAFiles(){
    this.setState({ loading: true }, async () => {
      try{
        await OTAAPI.copyOTABinaries(this.props.deviceType, this.props.productFamily, this.state.selectedSourceChannel, this.state.selectedTargetChannel);
        Alert.success("Those files have been copied!");
        this.setState({ loading: false });
      }catch(err){
        Alert.error("We could not copy those file. You should tell a backend administrator. Kevin or Andy would be a good choice!");
        this.setState({ loading: false });
      }
    });
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OTACopy);