import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as UserActions from "src/reducers/userReducer";
import Screen from "src/components/structure/Screen";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import CollarSecretsTab from "./Uploads/CollarSecrets";
import PendantShieldSecretsTab from "./Uploads/PendantShieldSecrets";
import HealthBoxSecretsTab from "./Uploads/HealthBoxSecrets";
import BowlSecretsTab from "./Uploads/BowlSecrets";

interface IUploadDeviceSecretsScreenProps {
  match: any;
  history: any;
  location: any;
  userActions: any;
  userState: any;
}


interface IUploadDeviceSecretsScreenState {
  loading: boolean;
}

class UploadDeviceSecretsScreen extends Component<IUploadDeviceSecretsScreenProps, IUploadDeviceSecretsScreenState> {
  constructor(props: IUploadDeviceSecretsScreenProps) {
    super(props);

    this.state = {
      loading: false,
    };

    this.goBack = this.goBack.bind(this);
  }


  goBack() {
    this.props.history.goBack();
  }

  render() {
    return (
      <Screen requiredRoles={[]}>
        <div className="col-sm-12 col-lg-12">
          <div className="row view-select-row">
            <div className="col-12">
              <Tabs>
                <TabList>
                  <Tab>Collar</Tab>
                  <Tab>Pendant/Shield</Tab>
                  <Tab>Health Box</Tab>
                  <Tab>Bowl</Tab>
                </TabList>
                <TabPanel>
                  <CollarSecretsTab/>
                </TabPanel>
                <TabPanel>
                  <PendantShieldSecretsTab/>
                </TabPanel>
                <TabPanel>
                  <HealthBoxSecretsTab/>
                </TabPanel>
                <TabPanel>
                  <BowlSecretsTab/>
                </TabPanel>
              </Tabs>
            </div>
          </div>
        </div>
      </Screen>
    );
  }

}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UploadDeviceSecretsScreen) as any
);
