import { makeCall } from "./client";



export class JobsAPI {  

  /**
   * Get a list of jobs
   * @param app 
   * @param options 
   * @returns 
   */
  public getJobs = (app: string, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('GET', `/admin/${app}/jobs`, data);
  }

  /**
   * Get the known jobs
   * @param app 
   * @param options 
   * @returns 
   */
  public getKnownJobs = (app: string, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('GET', `/admin/${app}/jobs/known`, data);
  }

  /**
   * Create a new job
   * @param app 
   * @param options 
   * @returns 
   */
  public createJob = (app: string, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('POST', `/admin/${app}/jobs`, data);
  }

  /**
   * Get a single job by its id
   * @param app 
   * @param jobId 
   * @param options 
   * @returns 
   */
  public getJobById = (app: string, jobId: number, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('GET', `/admin/${app}/jobs/${jobId}`, data);
  }

  /**
   * Updates a job. Putting this here but not sure we should allow users to do this
   * @param app 
   * @param jobId 
   * @param options 
   * @returns 
   */
  public updateJob = (app: string, jobId: number, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('GET', `/admin/${app}/jobs/${jobId}`, data);
  }

  public translateKeyToDisplay = (input: string): string => {
    // we do this here because we didn't put a display on the server, and not sure
    // if we want to or not
    switch(input){
      case "breed_activity_reset":
        return "Breed Activity Report Reset"
      default:
        return input;
    }
  } 
}

export interface IJob {
  id: number;
  name: string;
  notificationTarget: string;
  notificationType: string;
  outputLocation: string;
  outputType: string;
  parameters: any;
  requestedOn: string;
  requestingUserId: number;
  requestingUserType: "admin" | "user";
  requestingUser: string;
  status: "completed" | "pending" | "error" | "processing";
  statusLastUpdatedOn: string;
  statusMessage: string;
  targetService:string;

  downloadLink?: string;
  view?: any;
}

export const BlankJob: IJob = {
  id: 0,
  name: "",
  notificationTarget: "",
  notificationType: "",
  outputLocation: "",
  outputType: "",
  parameters: {},
  requestedOn: "1970-01-01T00:00:00Z",
  requestingUserId: 0,
  requestingUserType: "admin",
  requestingUser: "",
  status: "completed",
  statusLastUpdatedOn:  "1970-01-01T00:00:00Z",
  statusMessage: "",
  targetService:"",

  downloadLink: "",
}