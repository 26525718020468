import moment from "moment";
import { makeCall } from "./client";
import { IEvent } from "./metrics";
import { IPetLocation } from "./pets";

export class DeviceAPI {

  /**
   * Get all of the devices on the platform
   * @param app 
   * @param options 
   */
  public getAllDevices = (app: string, options: any = {}) => {
    const data = {
      ...options
    };
    return makeCall('GET', `/admin/${app}/devices`, data);
  }

  /**
   * Get the devices by ID
   * @param app 
   * @param deviceId 
   * @param options 
   */
  public getDeviceById = (app: string, deviceId: number, options: any = {}) => {
    const data = {
      ...options
    };
    return makeCall('GET', `/admin/${app}/devices/${deviceId}`, data);
  }

  /**
   * Get the lifecycle info for the device
   * @param app 
   * @param deviceId 
   * @param options 
   */
  public getDeviceLifecycleData = (app: string, deviceId: number, options: any = {}) => {
    const data = {
      ...options
    };
    return makeCall('GET', `/admin/${app}/devices/${deviceId}/lifecycle`, data);
  }

  /**
   * Get a device by the product id
   * @param app 
   * @param productId 
   * @param options 
   * @returns 
   */
  public getDeviceByProductId = (app: string, productId: string, options: any = {}) => {
    const data = {
      ...options
    };
    return makeCall('GET', `/admin/${app}/devices/productId/${productId}`, data);
  }

  /**
   * Get the status bar for a device
   * @param app 
   * @param deviceId 
   * @param options 
   */
  public getDeviceStatus = (app: string, deviceId: number, options: any = {}) => {
    const data = {
      ...options
    };
    return makeCall('GET', `/admin/${app}/devices/${deviceId}/status`, data);
  }

  /**
   * Get the archived status bars for a device
   * @param app 
   * @param deviceId 
   * @param options 
   */
  public getDeviceStatusArchives = (app: string, deviceId: number, options: any = {}) => {
    const data = {
      ...options
    };
    return makeCall('GET', `/admin/${app}/devices/${deviceId}/status/archives`, data);
  }

  /**
   * Delete the archived status bars for a device, optionally by days older than
   * @param app 
   * @param deviceId 
   * @param options 
   */
  public deleteDeviceStatusArchives = (app: string, deviceId: number, options: any = {}) => {
    const data = {
      ...options
    };
    return makeCall('DELETE', `/admin/${app}/devices/${deviceId}/status/archives`, data);
  }

  /**
   * Gets the devices for a single user
   * @param app 
   * @param userId 
   * @param options 
   */
  public getDevicesForUser = (app: string, userId: number, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall("GET", `/admin/${app}/users/${userId}/devices`, data);
  };

  /**
   * Gets the consolidated data for the device
   * @param app 
   * @param userId 
   * @param deviceId 
   * @param options 
   * @returns 
   */
  public getConsolidatedDeviceData = (app: string, userId: number, deviceId: number, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall("GET", `/admin/${app}/users/${userId}/devices/${deviceId}/consolidated`, data);
  };
  
  /**
   * Gets the locations for a device
   * @param app 
   * @param userId 
   * @param deviceId 
   * @param options 
   */
  public getLocationsForDevice = (app: string, userId: number, deviceId: number, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall("GET", `/admin/${app}/users/${userId}/devices/${deviceId}/locations`, data);
  };
  
  /**
   * Get a device's shadow from the server (not from AWS directly!)
   * @param app 
   * @param userId 
   * @param deviceId 
   * @param options 
   */
  public getDeviceShadow = (app: string, userId: number, deviceId: number, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall("GET", `/admin/${app}/users/${userId}/devices/${deviceId}/shadow`, data);
  };
  
  /**
   * Get a device's usage from the server
   * @param app 
   * @param userId 
   * @param deviceId 
   * @param options 
   */
  public getDeviceUsage = (app: string, userId: number, deviceId: number, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall("GET", `/admin/${app}/users/${userId}/devices/${deviceId}/usage`, data);
  };
  
  /**
   * Get a device's usage from the server
   * @param app 
   * @param userId 
   * @param deviceId 
   * @param options 
   */
  public updateDeviceSIMStatus = (app: string, userId: number, deviceId: number, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall("PATCH", `/admin/${app}/users/${userId}/devices/${deviceId}/sim`, data);
  };

  /**
   * Gets the battery cycles for the device
   * @param app 
   * @param userId 
   * @param deviceId 
   * @param options 
   * @returns 
   */
  public getBatteryCyclesForDevice = (app: string, userId: number, deviceId: number, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall("GET", `/admin/${app}/users/${userId}/devices/${deviceId}/battery/cycles`, data);
  };

  /**
   * Gets the battery drain estimate for the device
   * @param app 
   * @param userId 
   * @param deviceId 
   * @param options 
   * @returns 
   */
  public getBatteryDrainForDevice = (app: string, userId: number, deviceId: number, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall("GET", `/admin/${app}/users/${userId}/devices/${deviceId}/battery/drain`, data);
  };
  
  /**
   * Update some of the fields for the device
   * @param app 
   * @param userId 
   * @param deviceId 
   * @param options 
   */
  public updateDevice = (app: string, userId: number, deviceId: number, options: any = {}) => {
    const data = {
      ...options
    };
    return makeCall('PATCH', `/admin/${app}/users/${userId}/devices/${deviceId}`, data);
  }

  /**
   * Update just the log interval. This is separate to ensure that the log interval logic is isolated and managed separately for specific users
   * @param app 
   * @param userId 
   * @param deviceId 
   * @param logInterval 
   * @param options 
   */
  public updateDeviceLogInterval = (app: string, userId: number, deviceId: number, logInterval: number, options: any = {}) => {
    const data = {
      ...options,
      logInterval,
    };
    return makeCall('PATCH', `/admin/${app}/users/${userId}/devices/${deviceId}/logInterval`, data);
  }
  
  /**
   * Get the actions for the device
   * @param app 
   * @param userId 
   * @param deviceId 
   * @param options 
   */
  public getActionsForDevice = (app: string, userId: number, deviceId: string, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall("GET", `/admin/${app}/users/${userId}/devices/${deviceId}/actions`, data);
  };

  /**
   * Deletes all actions for a device
   * @param app 
   * @param userId 
   * @param deviceId 
   * @param options 
   * @returns 
   */
  public deleteActionsForDevice = (app: string, userId: number, deviceId: number, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall("DELETE", `/admin/${app}/users/${userId}/devices/${deviceId}/actions`, data);
  };

  /**
   * Get the linked pets for a device by ID
   * @param app 
   * @param deviceId 
   * @param options 
   */
  public getPetDeviceLinksForDevice = (app: string, deviceId: number, options: any = {}) => {
    const data = {
      ...options
    };
    return makeCall('GET', `/admin/${app}/devices/${deviceId}/links`, data);
  };
  
  /**
   * Get the available OTA channels from AWS
   * @param deviceType 
   * @param productFamily 
   * @param options 
   */
  public getAvailableOTAChannels = (deviceType: string, productFamily: string, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall("GET", `/admin/backend/ota/${deviceType}-${productFamily}`, data);
  };

  /**
   * Gets the stats about an OTA channel
   * @param deviceType 
   * @param productFamily 
   * @param channel 
   * @param options 
   */
  public getOTAChannelInfo = (deviceType: string, productFamily: string, channel: string, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall("GET", `/admin/backend/ota/${deviceType}-${productFamily}/${channel}`, data);
  };

  /**
   * Deletes an OTA Channel. This should fail if there are devices on that channel.
   * @param deviceType 
   * @param productFamily 
   * @param channel 
   * @param options 
   */
  public deleteOTAChannel = (deviceType: string, productFamily: string, channel: string, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall("DELETE", `/admin/backend/ota/${deviceType}-${productFamily}/${channel}`, data);
  };

  /**
   * Creates a new OTA channel bucket.
   * @param deviceType 
   * @param productFamily 
   * @param channel 
   * @param options 
   */
  public createOTAChannel = (deviceType: string, productFamily: string, channel: string, options: any = {}) => {
    const data = {
      ...options,
      channel,
    };
    return makeCall("POST", `/admin/backend/ota/${deviceType}-${productFamily}/${channel}`, data);
  };

  /**
   * Update the OTA channel the device is pointing to
   * @param deviceType 
   * @param productFamily 
   * @param otaChannel 
   * @param options 
   */
  public updateOTAChannelForDevice = (app: string, userId: number, deviceId: number, otaChannel: string, options: any = {}) => {
    const data = {
      ...options,
      otaChannel,
    };
    return makeCall('PATCH', `/admin/${app}/users/${userId}/devices/${deviceId}/channel`, data);
  }
  
  /**
   * Queue a new action for the device
   * @param app 
   * @param userId 
   * @param deviceId 
   * @param options 
   */
  public queueActionForDevice = (app: string, userId: number, deviceId: string, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall("POST", `/admin/${app}/users/${userId}/devices/${deviceId}/actions`, data);
  };
  
  /**
   * Process a device as being returned by the customer 
   * @param app 
   * @param userId 
   * @param deviceId 
   * @param options 
   */
  public removeDeviceFromAccount = (app: string, userId: number, deviceId: number, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('DELETE', `admin/${app}/users/${userId}/devices/${deviceId}/return`, data);
  }

  /**
   * Gets the list of secrets. This is a very large list, so we may want to cull it or filter it
   * @param options 
   * @returns 
   */
  public getDeviceSecrets = (options: any = {}) => {
    return makeCall('GET', `admin/devices/secrets`, options);
  }

  /**
   * Tales in an array of secrets and saves them to the server.
   * @param secrets 
   * @param options 
   * @returns 
   */
  public saveDeviceSecrets = (secrets: any[], options: any = {}) => {
    const data = {
      ...options,
      secrets,
    }
    return makeCall('POST', `admin/devices/secrets`, data);
  }

  /**
   * Generates a new device secret with optional fields set
   * @param familyId 
   * @param secret 
   * @param options 
   * @returns 
   */
  public generateDeviceSecret = (familyId: string, secret: IDeviceSecret = BlankDeviceSecret, options: any = {}) => {
    const data = {
      ...options,
      ...secret,
      familyId,
    }
    return makeCall('POST', `admin/devices/secrets/generate`, data);
  }

  /**
   * Updates limited fields for a secret
   * @param productId 
   * @param data 
   * @param options 
   * @returns 
   */
  public updateDeviceSecret = (productId: string, data: any, options: any = {}) => {
    const send = {
      ...options,
      ...data,
    }
    return makeCall('PATCH', `admin/devices/secrets/${productId}`, send);
  }

  /**
   * Gets the list of secrets. This is a very large list, so we may want to cull it or filter it
   * @param options 
   * @returns 
   */
  public getDeviceInformation = (options: any = {}) => {
    return makeCall('GET', `admin/devices/secrets/general`, options);
  }

  /**
   * Get the connectivity logs for a device
   * @param app 
   * @param userId 
   * @param deviceId 
   * @param start 
   * @param end 
   * @param options 
   * @returns 
   */
  public getDeviceConnectivityLogs = (app: string, userId: number, deviceId: number, start: moment.Moment, end: moment.Moment, options: any = {}) => {
    const data = {
      ...options,
      start: start.format("YYYY-MM-DDTHH:mm:ss") + "Z",
      end: end.format("YYYY-MM-DDTHH:mm:ss") + "Z",
    };
    return makeCall("GET", `/admin/${app}/users/${userId}/devices/${deviceId}/logs/connectivity`, data);
  };

  /**
   * Gets all available settings for a family
   * @param app 
   * @param family 
   * @param options 
   * @returns 
   */
  public getAllPerformanceSettingsFieldsForFamily = (app: string, family: string, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall("GET", `/admin/devices/performance/${family}`, data);
  };

  /**
   * Gets the device settings for a device
   * @param app 
   * @param userId 
   * @param deviceId 
   * @param options 
   * @returns 
   */
  public getSettingsForDevice = (app: string, userId: number, deviceId: number, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall("GET", `/admin/${app}/users/${userId}/devices/${deviceId}/settings`, data);
  };

  /**
   * Gets the performance settings for a single device
   * @param app 
   * @param userId 
   * @param deviceId 
   * @param options 
   * @returns 
   */
  public getPerformanceSettingsForDevice = (app: string, userId: number, deviceId: number, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall("GET", `/admin/${app}/users/${userId}/devices/${deviceId}/performance`, data);
  };

  /**
   * Sets a new value for a setting
   * @param app 
   * @param userId 
   * @param deviceId 
   * @param settingName 
   * @param settingValue 
   * @param options 
   * @returns 
   */
  public setPerformanceSettingForDevice = (app: string, userId: number, deviceId: number, settingName: string, settingValue: any, options: any = {}) => {
    const data = {
      ...options,
      settingValue,
    };
    return makeCall("PUT", `/admin/${app}/users/${userId}/devices/${deviceId}/performance/${settingName}`, data);
  };
  
  /**
   * Get the current Known Actions
   * @param app
   * @param options 
   */
  public getKnownActions = (options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('GET', `admin/knownActions`, data);
  }
  
  /**
   * Get a single Known Action
   * @param app
   * @param options 
   */
  public getKnownAction = (action: string, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('GET', `admin/knownActions/${action}`, data);
  }
  
  /**
   * Saves a new Known Action
   * @param app 
   * @param options 
   */
  public saveNewKnownAction = (options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('POST', `admin/knownActions`, data);
  }
  
  /**
   * Updates an existing Known Action
   * @param app 
   * @param options 
   */
  public updateKnownAction = (action: string, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('PATCH', `admin/knownActions/${action}`, data);
  }
  
  /**
   * Deletes an existing Known Action
   * @param app 
   * @param options 
   */
  public deleteKnownAction = (action: string, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('DELETE', `admin/knownActions/${action}`, data);
  }

}

export interface IDevice {
  brand: string;
  certificateArn?: string;
  certificateId?: string;
  certificatePem?: string;
  deviceType: string;
  endpoint: string;
  firmwareVersion: string;
  modemFirmwareVersion: string;
  heartbeat: string;
  heartbeatStatus: "up" | "down";
  id: number;
  imei: string;
  jwt: string;
  leashRange: number;
  logInterval: number | string;
  mac: string;
  managedByApp: "wagz";
  nickname: string;
  otaChannel: string;
  petId: number;
  policyArn: string;
  privateKey?: string;
  productFamilyId: string;
  productId: string;
  publicKey?: string;
  registeredBy: number;
  registeredOn: string;
  service: "wagz2";
  simId: string;
  status: string;
  thingArn: string;
  thingName: string;
  onboardedOn: string;
};

export const BlankDevice: IDevice = {
  brand: "",
  certificateArn: "",
  certificateId: "",
  certificatePem: "",
  deviceType: "",
  endpoint: "",
  firmwareVersion: "",
  modemFirmwareVersion: "",
  heartbeat: "",
  heartbeatStatus: "up",
  id: 0,
  imei: "",
  jwt: "",
  leashRange: 0,
  logInterval: 0,
  mac: "",
  managedByApp: "wagz",
  nickname: "",
  otaChannel: "",
  petId: 0,
  policyArn: "",
  privateKey: "",
  productFamilyId: "",
  productId: "",
  publicKey: "",
  registeredBy: 0,
  registeredOn: "",
  service: "wagz2",
  simId: "",
  status: "",
  thingArn: "",
  thingName: "",
  onboardedOn: "",
};

export interface IDeviceSecret {
  app: string;
  secretKey: string;
  productId: string;
  inserted: moment.Moment;
  som: string;
  imei: string;
  iccid: string;
  mac: string;
  otaChannel: string;
  familyId: string;
  hardwareId?: string;
};

export interface IDeviceInfo {
  app: string;
  productId: string;
  inserted: moment.Moment;
  imei: string;
  iccid: string;
};

export const BlankDeviceSecret: IDeviceSecret = {
  app: "wagz2",
  secretKey: "",
  productId: "",
  inserted: moment(),
  som: "",
  imei: "",
  iccid: "",
  mac: "",
  otaChannel: "default",
  familyId: "",
  hardwareId: "",
}
export interface IDeviceConnectivityLog {
  deviceId: string,
  posted: moment.Moment,
  sysmode: string,
  rssi: number,
  rsrp: number, 
  snr: number, 
  rsrq: number, 
  registrationStatus: string
  operator: number;
  band: number;
  device565s: number;
  device550s: number;
  pdpDeacts: number;
  cellMode: string;
}
export interface IStatusBarArchive {
  id: number,
  deviceId: number,
  currentSSID: string;
  wifiLevel: number, 
  cellLevel: number, 
  batteryLevel: number, 
  inserted: moment.Moment,
  rawLocationAccuracy: number;
  walkModeActive: string;
  lowPowerActive: string;
  bluetooth: string;
  idleSeconds: number;
  endOfCycle: string;
}
export interface IStatusBarBowlArchive {
  deviceId: number,
  wifiLevel: number, 
  currentWeight: number, 
  powerMode: string, 
  configuration: string, 
  lastUpdated: moment.Moment,
}
export interface IStatusBarHealthBoxArchive {
  deviceId: number,
  wifiLevel: number, 
  currentWeight: number, 
  currentVisitingPet: number, 
  lastUpdated: moment.Moment,
}
export interface IBatteryCycle {
  startTime: moment.Moment,
  endTime: moment.Moment,
  duration: string,
  batteryStartLevel: number,
  batteryEndLevel: number, 
  restarts: number, 
  statusReports: number,
  wifiUsage: number,
  cellUsage: number,
}

export interface IDevicePerformanceSetting {
  deviceId: number
  productFamily: string;
  settingName: string;
  settingValue: any;
  settingValueType: "int" | "float" | "string";
  settingDescription: string;
}

export interface IAction {
  action: string;
  additionalData: any;
  arguments: any;
  deviceId: number;
  startTime: string;
  endTime: string;
  id: number;
  petId: number;
  sourceId: any;
  sourceType: string;
  status: "success" | "pending" | "error";
}

export interface IConsolidatedDeviceViewReturn {
  device: IDevice;
  actions: IAction[];
  events: IEvent[];
  locations: IPetLocation[];
  statusBars: IStatusBarArchive[];
  connectivityLogs: IDeviceConnectivityLog[];
}