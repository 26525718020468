import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";

import Card from "src/components/structure/Card";
import * as AppActions from "src/reducers/appReducer";
import moment from "moment";
import { DeviceAPI, PetsAPI, UserAPI } from "src/api";
import { error } from "./Alert";
import Table from "./Table";
import { Loading } from "./Loading";
import geoTz from "geo-tz"; // TODO: look for a replacement
import DatePicker from "./DatePicker";
import { faPaw } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { styles } from "src/styles";
import { lightBlue, blue } from "@material-ui/core/colors";
import { withStyles } from "@material-ui/styles";
import Switch from "@material-ui/core/Switch";
import { Modal } from "react-bootstrap";
import GoogleMapReact from "google-map-react";

//
// This will take in whatever data is available and then do the appropriate call
// I wanted to consolidate the UI elements and the date picker so it can be used
// for pets, devices, and the user locations
//

interface ILocationScreenProps {
  userId: number;
  deviceId?: number;
  petId?: number;
  locationsFor: "user" | "device" | "pet";
}

interface ILocationScreenState {
  loading: boolean;
  csvName: string;
  csvData: any[];
  locations: any[];
  start: moment.Moment;
  end: moment.Moment;
  filterToggle: boolean;

  showFullPointModal: boolean;
  timezone: string;

  selectedLat: number;
  selectedLong: number;
  selectedSource: string;

  showPointModal: boolean;

  mapType: string;
  mapTypeChecked: boolean;

  fences: any[];
  showFences: boolean;
  showOnlyActiveFences: boolean;
}

const cols = [
  {
    label: "Posted",
    field: "posted",
  },
  {
    label: "Ago",
    field: "postedAgo",
  },
  {
    label: "Latitude",
    field: "lat",
  },
  {
    label: "Longitude",
    field: "lng",
  },
  {
    label: "Accuracy",
    field: "locationAccuracy",
  },
  {
    label: "Source",
    field: "source",
  },
  {
    label: "Associated Device ID",
    field: "deviceId",
  },
  {
    label: "Associated Pet ID",
    field: "petId",
  },
  {
    label: "Actions",
    field: "actions",
  },
];

const Marker = (props: any) => {
  return (
    <div style={{
      width: "16px",
      height: "16px",
      backgroundColor: props.color,
      borderRadius: "50%",
      textAlign: "center",
      paddingTop: 2 }}>
      <FontAwesomeIcon icon={faPaw} size={"1x"} color={"#f4f5f7"} />{" "}
    </div>
  );
};

const mapKey = process.env.REACT_APP_GOOGLE_MAP_KEY || "";

const MapTypeSwitch = withStyles({
  switchBase: {
    color: lightBlue[300],
    "&$checked": {
      color: blue[500],
    },
    "&$checked + $track": {
      backgroundColor: blue[400],
    },
  },
  checked: {},
  track: {},
})(Switch);

class LocationScreen extends React.Component<
  ILocationScreenProps,
  ILocationScreenState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      csvName: "",
      csvData: [],
      locations: [],
      start: moment().subtract(7, "days"),
      end: moment(),
      filterToggle: false,

      showFullPointModal: false,
      timezone: "Unknown",

      showPointModal: false,
      selectedLat: -0,
      selectedLong: -0,
      selectedSource: "",

      mapType: "satellite",
      mapTypeChecked: true,

      fences: [],
      showFences: false,
      showOnlyActiveFences: false,
    };

    this.fetchLocations = this.fetchLocations.bind(this);
    this.updateStart = this.updateStart.bind(this);
    this.updateEnd = this.updateEnd.bind(this);
    this.toggleShowFullPointModal = this.toggleShowFullPointModal.bind(this);
    this.toggleShowPointModal = this.toggleShowPointModal.bind(this);
    this.handleSwitch = this.handleSwitch.bind(this);
    this.handleFenceToggle = this.handleFenceToggle.bind(this);
    this.handleFenceActiveToggle = this.handleFenceActiveToggle.bind(this);
    this.handleFilterToggle = this.handleFilterToggle.bind(this);
  }

  componentDidMount() {
    this.fetchLocations();
  }

  handleGoogleMapApi = (google: {
    maps: {
      Polyline: new (arg0: {
        path: { lat: number; lng: number }[];
        geodesic: boolean;
        strokeColor: string;
        strokeOpacity: number;
        strokeWeight: number;
      }) => any;
    };
    map: any;
  }) => {
    if (this.state.showFences) {
      for (let f of this.state.fences) {
        if(!this.state.showOnlyActiveFences || (this.state.showOnlyActiveFences && f.active)){
          var amendedArea = f.area;
          amendedArea.push(f.area[0]);
          var flightPath = new google.maps.Polyline({
            path: amendedArea,
            geodesic: true,
            strokeColor: this.checkFenceColor(f.type),
            strokeOpacity: 1,
            strokeWeight: 5,
          });

          flightPath.setMap(google.map);
        }
      }
    } else {
      for (let f of this.state.fences) {
        var amendedAreaN = f.area;
        amendedAreaN.push(f.area[0]);
        var flightPathN = new google.maps.Polyline({
          path: amendedAreaN,
          geodesic: true,
          strokeColor: this.checkFenceColor(f.type),
          strokeOpacity: 0,
          strokeWeight: 5,
        });

        flightPathN.setMap(google.map);
      }
    }
  };

  public render() {
    if (this.state.loading) {
      return <Loading />;
    }

    return (
      <div>       

        <div className="row" style={{ marginTop: 30 }}>
          <div className="col-5">
            <div className="row">
              <div className="col-12">
                <Card title="Most Recent Location Timezone" loading={this.state.loading} help="" >
                    {this.state.timezone}
                </Card>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Card title="Location Filters">
                  <div className="row">
                    <div className="col-4">
                      <label>From</label>
                      <DatePicker
                        date={this.state.start}
                        onDateSaved={this.updateStart}
                      />
                    </div>
                    <div className="col-4">
                      <label>To</label>
                      <DatePicker
                        date={this.state.end}
                        onDateSaved={this.updateEnd}
                      />
                    </div>
                    <div className="col-4">
                      <button
                        className="btn btn-block btn-primary"
                        onClick={this.fetchLocations}
                        style={{ marginTop: 27 }}
                      >
                        Refresh
                      </button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <input
                        id="filterToggle"
                        type="checkbox"
                        aria-label="Use Path Trace Filter"
                        checked={this.state.filterToggle}
                        onChange={this.handleFilterToggle}
                      />
                      <label style={{ marginTop: 7, marginLeft: 10, padding: 2 }} htmlFor="filterToggle">
                        Show with Path Trace Filter (customer view)
                      </label>
                    </div>
                    <div className="col-6">
                      <input
                        id="showFences"
                        type="checkbox"
                        aria-label="Hide or Show Geofences"
                        checked={this.state.showFences}
                        onChange={this.handleFenceToggle}
                        />
                      <label style={{ marginTop: 7, marginLeft: 10, padding: 2 }} htmlFor="showFences">
                      Show Geofences with Locations
                      </label>
                      {this.state.showFences && (
                        <div className="row">
                          <div className="col-12">
                            <input
                              id="showActiveFences"
                              type="checkbox"
                              aria-label="Hide or Show Geofences"
                              checked={this.state.showOnlyActiveFences}
                              onChange={this.handleFenceActiveToggle}
                              />
                            <label style={{ marginTop: 7, marginLeft: 10, padding: 2 }} htmlFor="showActiveFences">
                              Only Show Active Fences
                            </label>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </Card>
              </div>
            </div>
          </div>

          <div className="col-7">
            <Card title="Source Guide" loading={this.state.loading} help="" >
              <div className="row" style={{marginBottom: 7}}>
                <div className="col-3">
                  gps
                </div>
                <div className="col-3">
                  Collar's GPS module
                </div>
                <div className="col-6">
                  Direct from the collar to the server via Wi-Fi or Cellular
                </div>
              </div>
              <div className="row" style={{marginBottom: 7}}>
                <div className="col-3">
                  gpsViaPhone
                </div>
                <div className="col-3">
                  Collar's GPS module
                </div>
                <div className="col-6">
                  Collar transmitted to the mobile app via Bluetooth; mobile app uploaded to the server
                </div>
              </div>
              <div className="row" style={{marginBottom: 7}}>
                <div className="col-3">
                  gpsViaViolationEvent
                </div>
                <div className="col-3">
                  Collar's GPS module
                </div>
                <div className="col-6">
                  Transmitted via Wi-Fi or Cellular as part of a Geofence Violation
                </div>
              </div>
              <div className="row" style={{marginBottom: 7}}>
                <div className="col-3">
                  gpsViaRecoveryEvent
                </div>
                <div className="col-3">
                  Collar's GPS module
                </div>
                <div className="col-6">
                  Transmitted via Wi-Fi or Cellular as part of a Geofence Recovery
                </div>
              </div>
              <div className="row">
                <div className="col-3">
                  phone
                </div>
                <div className="col-3">
                  Mobile App Location
                </div>
                <div className="col-6">
                  Substituting phone's location because it sees the collar over Bluetooth AND the collar isn't able to get its own location for some reason; about +/- 40m accuracy
                </div>
              </div>
            </Card>
          </div>
        </div>

        {this.state.locations.length === 0 ? (
          <div className="row" style={{ marginTop: 10 }}>
            <div className="col-12">
              <Card title="Locations">
                <strong>
                  <p>No locations match that criteria</p>
                </strong>
              </Card>
            </div>
          </div>
        ) : (
          <div className="row" style={{ marginTop: 10 }}>
            <div className="col-12">
              <Table
                title="Locations"
                csvFileName={this.state.csvName}
                csvData={this.state.csvData}
                showDownload={true}
                columns={cols}
                rows={this.state.locations}
                preElement={(
                  <div className="row">
                    <div className="col-12">
                      <button
                        className="btn btn-secondary"
                        onClick={this.toggleShowFullPointModal}
                      >
                        View All Points On Map
                      </button>
                    </div>
                  </div>
                )}
              />

              <Modal
                dialogClassName="modal90"
                show={this.state.showPointModal}
                onHide={this.toggleShowPointModal}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Location</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div
                    className="center"
                    style={{ height: "60vh", width: "100%" }}
                  >
                    <div className="row" style={{ marginLeft: 5 }}>
                      <div className="col-2">
                        <span>Map</span>
                        <MapTypeSwitch
                          checked={this.state.mapTypeChecked}
                          onChange={this.handleSwitch}
                        ></MapTypeSwitch>
                        <span>Satellite</span>
                      </div>
                      <div className="col-2">
                        <div className="row">
                          <div className="col-2">
                            <div style={{ width: 20, height: 20, backgroundColor: "#EF3311"}}></div>
                          </div>
                          <div className="col-10">
                            <span>Violation Event </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-2">
                        <div className="row">
                          <div className="col-2">
                            <div style={{ width: 20, height: 20, backgroundColor: "#11EFA2"}}></div>
                          </div>
                          <div className="col-10">
                            <span>Recovery Event </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-2">
                        <div className="row">
                          <div className="col-2">
                            <div style={{ width: 20, height: 20, backgroundColor: "#11cdef"}}></div>
                          </div>
                          <div className="col-10">
                            <span>Other Locations</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-2">
                        <div className="row">
                          <div className="col-2">
                            <div style={{ width: 20, height: 20, backgroundColor: "#11cdef"}}></div>
                          </div>
                          <div className="col-10">
                            <span>Allowed Zone</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-2">
                        <div className="row">
                          <div className="col-2">
                            <div style={{ width: 20, height: 20, backgroundColor: "#EF3311"}}></div>
                          </div>
                          <div className="col-10">
                            <span>Forbidden Zone</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <GoogleMapReact
                      yesIWantToUseGoogleMapApiInternals
                      onGoogleApiLoaded={this.handleGoogleMapApi}
                      bootstrapURLKeys={{
                        key: mapKey,
                      }}
                      defaultCenter={{
                        lat: this.state.selectedLat,
                        lng: this.state.selectedLong,
                      }}
                      defaultZoom={17}
                      options={{
                        keyboardShortcuts: false,
                        tilt: 0,
                        mapTypeId: this.state.mapType,
                      }}
                    ><Marker lat={this.state.selectedLat} lng={this.state.selectedLong} color={this.renderPointColor(this.state.selectedSource)} />
                    </GoogleMapReact>
                  </div>
                  <div style={{ marginTop: 50 }}>
                    <button
                      className="btn btn-block btn-primary"
                      style={styles.button}
                      onClick={this.toggleShowPointModal}
                    >
                      Close
                    </button>
                  </div>
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
              </Modal>

              <Modal
                dialogClassName="modal90"
                show={this.state.showFullPointModal}
                onHide={this.toggleShowFullPointModal}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Viewing All Location Points</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div
                    className="center"
                    style={{ height: "60vh", width: "100%" }}
                  >
                    <div className="row" style={{ marginLeft: 5 }}>
                      <div className="col-2">
                        <span>Map</span>
                        <MapTypeSwitch
                          checked={this.state.mapTypeChecked}
                          onChange={this.handleSwitch}
                        ></MapTypeSwitch>
                        <span>Satellite</span>
                      </div>
                      <div className="col-2">
                        <div className="row">
                          <div className="col-2">
                            <div style={{ width: 20, height: 20, backgroundColor: "#EF3311"}}></div>
                          </div>
                          <div className="col-10">
                            <span>Violation Event </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-2">
                        <div className="row">
                          <div className="col-2">
                            <div style={{ width: 20, height: 20, backgroundColor: "#11EFA2"}}></div>
                          </div>
                          <div className="col-10">
                            <span>Recovery Event </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-2">
                        <div className="row">
                          <div className="col-2">
                            <div style={{ width: 20, height: 20, backgroundColor: "#11cdef"}}></div>
                          </div>
                          <div className="col-10">
                            <span>Other Locations</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-2">
                        <div className="row">
                          <div className="col-2">
                            <div style={{ width: 20, height: 20, backgroundColor: "#11cdef"}}></div>
                          </div>
                          <div className="col-10">
                            <span>Allowed Zone</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-2">
                        <div className="row">
                          <div className="col-2">
                            <div style={{ width: 20, height: 20, backgroundColor: "#EF3311"}}></div>
                          </div>
                          <div className="col-10">
                            <span>Forbidden Zone</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <GoogleMapReact
                      yesIWantToUseGoogleMapApiInternals
                      onGoogleApiLoaded={this.handleGoogleMapApi}
                      bootstrapURLKeys={{
                        key: mapKey,
                      }}
                      defaultCenter={{
                        lat: this.state.locations[0].lat,
                        lng: this.state.locations[0].lng,
                      }}
                      defaultZoom={17}
                      options={{
                        keyboardShortcuts: false,
                        tilt: 0,
                        mapTypeId: this.state.mapType,
                      }}
                    >
                      {this.state.locations.map((l, index) => {
                        return <Marker lat={l.lat} lng={l.lng} key={index} color={this.renderPointColor(l.source)} />;
                      })}
                    </GoogleMapReact>
                  </div>
                  <div className="text" style={{ marginTop: 50 }}>
                    Oldest Point:{" "}
                    {this.state.locations[this.state.locations.length - 1].lat},{" "}
                    {this.state.locations[this.state.locations.length - 1].lng}
                  </div>
                  <div className="text" style={{ marginTop: 10 }}>
                    Newest Point: {this.state.locations[0].lat},{" "}
                    {this.state.locations[0].lng}
                  </div>
                  <div className="text" style={{ marginTop: 15 }}>
                    <button
                      className="btn btn-block btn-primary"
                      style={styles.button}
                      onClick={this.toggleShowFullPointModal}
                    >
                      Close
                    </button>
                  </div>
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
              </Modal>
            </div>
          </div>
        )}
      </div>
    );
  }

  private updateStart(newDate: moment.Moment) {
    this.setState({ start: newDate });
  }

  private updateEnd(newDate: moment.Moment) {
    this.setState({ end: newDate });
  }

  private toggleShowFullPointModal() {
    this.setState({ showFullPointModal: !this.state.showFullPointModal });
  }

  private toggleShowPointModal() {
    this.setState({ showPointModal: !this.state.showPointModal });
  }

  private handleFenceToggle() {
    this.setState({
      showFences: !this.state.showFences,
    });
  }

  private handleFenceActiveToggle() {
    this.setState({ showOnlyActiveFences: !this.state.showOnlyActiveFences });
  }

  private handleFilterToggle() {
    this.setState({
      filterToggle: !this.state.filterToggle,
    });
  }

  private checkFenceColor(type: string) {
    switch (type) {
      case "allowed": {
        return "#11cdef";
      }
      case "notAllowed": {
        return "#EF3311";
      }
      default: {
        return "#ffffff";
      }
    }
  }

  private renderPointColor(source: string) {
    switch (source) {
      case "gpsViaViolationEvent": {
        return "#EF3311";
      }
      case "gpsViaRecoveryEvent": {
        return "#11EFA2";
      }
      default: {
        return "#11cdef";
      }
    }
  }

  private handleSwitch() {
    if (this.state.mapType === "satellite") {
      this.setState({
        mapType: "roadmap",
        mapTypeChecked: !this.state.mapTypeChecked,
      });
    }
    if (this.state.mapType === "roadmap") {
      this.setState({
        mapType: "satellite",
        mapTypeChecked: !this.state.mapTypeChecked,
      });
    }
  }

  private fetchLocations() {
    this.setState({ loading: true }, async () => {
      let locations: any[] = [];
      let csvData: any[] = [];
      let csvName: string = "";
      let limit = "";
      let filter = "";
      if (this.state.filterToggle) {
        filter = "pathTrace"
        // We set 1440 as the limit for accurate construction of the filter return.  This is normally done on the api layer, but admin does not use that, so we set it here.
        limit = "1440"
      };
      const options = {
        start: this.state.start.format("YYYY-MM-DD") + "T00:00:00Z",
        end: this.state.end.format("YYYY-MM-DD") + "T23:59:59Z",
        limit,
        filter,
      };
      const fences = await UserAPI.getGeofencesForUser(
        "wagz",
        this.props.userId
      );
      try {
        if (this.props.locationsFor === "user") {
          const result = await UserAPI.getLocationsForUser(
            "wagz2",
            this.props.userId,
            options
          );
          csvData = JSON.parse(JSON.stringify(result.body.data))
          locations = result.body.data;
          csvName = `locationsFor-user-${this.props.userId}`;
        } else if (this.props.locationsFor === "pet" && this.props.petId) {
          const result = await PetsAPI.getLocationsFoPet(
            "wagz2",
            this.props.userId,
            this.props.petId,
            options
          );
          csvData = JSON.parse(JSON.stringify(result.body.data))
          locations = result.body.data;
          csvName = `locationsFor-pet-${this.props.petId}`;
        } else if (
          this.props.locationsFor === "device" &&
          this.props.deviceId
        ) {
          const result = await DeviceAPI.getLocationsForDevice(
            "wagz2",
            this.props.userId,
            this.props.deviceId,
            options
          );
          csvData = JSON.parse(JSON.stringify(result.body.data))
          locations = result.body.data;
          csvName = `locationsFor-device-${this.props.deviceId}`;
        } else {
          error(
            "You must provide a valid combination of properties to fetch locations"
          );
          return this.setState({ loading: false });
        }
        for (const l of locations) {
          l.deviceId = (
            <Link to={`/support/devices/${l.deviceId}`} id="device_page" style={{ color: "blue", textDecoration: "underline" }}>
              {l.deviceId}
            </Link>);
          l.petId = (
            <Link to={`/support/users/${this.props.userId}/pets/${l.petId}`} id="pet_page" style={{ color: "blue", textDecoration: "underline" }}>
              {l.petId}
            </Link>);
          l.actions = (
            <button
              style={{ color: "white", width: "80%" }}
              className="btn btn-secondary btn-block"
              onClick={() => {
                this.setState({
                  selectedLat: l.lat,
                  selectedLong: l.lng,
                  selectedSource: l.source,
                  showPointModal: true,
                });
              }}
            >
              View on Map
            </button>
          );
          const postedMoment = moment(l.posted).utc();
          l.posted = postedMoment.format("MM/DD/YY HH:mm:ss");
          l.postedAgo = postedMoment.fromNow();
        };

        // sort
        locations.sort((a, b) => {
          return moment(a.posted, "MM/DD/YY HH:mm:ss").isBefore(moment(b.posted, "MM/DD/YY HH:mm:ss")) ? -1 : 1;
        })

        // try the timezone
        let timezone: string = "Unknown";
        try {
          // if there are no locations, the catch will swallow the error for us
          timezone = geoTz(locations[0].lat, locations[0].lng);
        } catch (err) {}

        this.setState({
          loading: false,
          fences: fences.body.data,
          csvName,
          csvData,
          locations,
          timezone,
        });
      } catch (err) {
        error("Cannot load those locations");
        this.setState({ loading: false });
      }
    });
  }
}

const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationScreen);
