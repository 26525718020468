import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as UserActions from "src/reducers/userReducer";
import { ListGroup } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClipboard, faSignInAlt, faUserPlus, faUserAstronaut, faScroll, faNewspaper, faDog, faUserCog, faAmbulance } from '@fortawesome/free-solid-svg-icons'
import { SupportAPI, UserAPI } from "src/api";
import { ITicket, BlankTicket } from "src/api/support";
import Card from "src/components/structure/Card";
import { capitalizeFirstLetter } from "src/utils/utilities"
import { faBuilding } from "@fortawesome/free-regular-svg-icons";

interface IPetPageProps {
  match: any;
  history: any;
  location: any;
  userActions: any;
  userState: any;
}


interface ITicketPageState {
  loading: boolean;
  ticketId: number;
  ticket: ITicket;
  reportedEmail: string;
  resolvedEmail: string;
  disabled: boolean;
}

class TicketPage extends Component<IPetPageProps, ITicketPageState> {
  constructor(props: IPetPageProps) {
    super(props);

    this.state = {
      loading: false,
      ticketId: this.props.match.params.ticketId,
      ticket: BlankTicket,
      reportedEmail: "",
      resolvedEmail: "",
      disabled: true
    };

    this.updateField = this.updateField.bind(this);
    this.goBack = this.goBack.bind(this);
    this.getAdminUserInteractions = this.getAdminUserInteractions.bind(this);
    this.resolveTicket = this.resolveTicket.bind(this);
    this.closeTicket = this.closeTicket.bind(this);
  }

  updateField(e: any) {
    let ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  goBack() {
    this.props.history.goBack();
  }

  componentDidMount() {
    this.getTicket();
  }

  getTicket() {
    this.setState({ loading: true }, async () => {
      try {
        const ticketResult = await SupportAPI.getTicketByID(this.state.ticketId);
        this.setState({
          loading: false,
          ticket: ticketResult.body.data,
        });
        this.getAdminUserInteractions();
      } catch (err) {
        this.setState({ loading: false });
      }
    });
  }

  getAdminUserInteractions() {
    this.setState({ loading: true }, async () => {
      try {
        if (this.state.ticket.resolvedBy !== 0) {
            const email = await UserAPI.getAdminUserById(this.state.ticket.reportedBy)
            const emailRes = await UserAPI.getAdminUserById(this.state.ticket.resolvedBy)
            this.setState({
              loading: false,
              reportedEmail: email.body.data.email,
              resolvedEmail: emailRes.body.data.email,
            });
        } else {
            let dis = false;
            if (this.state.ticket.status !== "open") {
                dis = true;
            }
            const email = await UserAPI.getAdminUserById(this.state.ticket.reportedBy)
            this.setState({
              loading: false,
              reportedEmail: email.body.data.email,
              disabled: dis
            });
        }
      } catch (err) {
        this.setState({ loading: false });
      }
    });
  }

  resolveTicket() {
    this.setState({ loading: true }, async () => {
      try {
        await SupportAPI.updateTicket(this.state.ticketId, { resolvedBy: this.props.userState.user.id, status: "resolved" });
        this.setState({
          loading: false,
          disabled: true
        });
        this.getTicket();
      } catch (err) {
        this.setState({ loading: false });
      }
    });
  }

  closeTicket() {
    this.setState({ loading: true }, async () => {
      try {
        await SupportAPI.updateTicket(this.state.ticketId, { status: "closed" });
        this.setState({
          loading: false,
          disabled: true
        });
        this.getTicket();
      } catch (err) {
        this.setState({ loading: false });
      }
    });
  }

  render() {
    if(this.state.loading){
      return (
        <div style={{textAlign: "center"}}>
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      );
    }
    return (
        <div className="col-sm-12 col-lg-12">
          <div>
            <h1>Ticket Details (ID: {this.state.ticketId})</h1>
          </div>
            <div className="row">
                <div className="col-10 offset-1">
                    <Card title="">
                        <div className="row">
                            <div className="col-6">
                            <ListGroup variant="flush">
                                <ListGroup.Item><FontAwesomeIcon icon={faNewspaper} /> - {this.state.ticket.title}</ListGroup.Item>
                                <ListGroup.Item><FontAwesomeIcon icon={faBuilding} /> - {capitalizeFirstLetter(this.state.ticket.department)}</ListGroup.Item>
                                <ListGroup.Item><FontAwesomeIcon icon={faClipboard} /> - Status: {capitalizeFirstLetter(this.state.ticket.status)}</ListGroup.Item>
                                <ListGroup.Item><FontAwesomeIcon icon={faUserPlus} /> - Reported By: {this.state.reportedEmail}</ListGroup.Item>
                                <ListGroup.Item><FontAwesomeIcon icon={faUserAstronaut} /> - Resolved By: {this.state.resolvedEmail}</ListGroup.Item>
                            </ListGroup>
                            </div>
                            <div className="col-6">
                            <ListGroup variant="flush">
                                <ListGroup.Item><FontAwesomeIcon icon={faDog} /> - Device: {this.state.ticket.device}</ListGroup.Item>
                                <ListGroup.Item><FontAwesomeIcon icon={faAmbulance} /> - Critical? - {capitalizeFirstLetter(this.state.ticket.critical)}</ListGroup.Item>
                                <ListGroup.Item><FontAwesomeIcon icon={faUserCog} /> - User {this.state.ticket.user} on: {this.state.ticket.userOS}</ListGroup.Item>
                                <ListGroup.Item><FontAwesomeIcon icon={faSignInAlt} /> - Created on: {this.state.ticket.created}</ListGroup.Item>
                                <ListGroup.Item><FontAwesomeIcon icon={faScroll} /> - {this.state.ticket.notes}</ListGroup.Item>
                            </ListGroup>
                            </div>
                        </div>
                    </Card>
                </div>
                <div className="col-9 offset-1" style={{ marginTop: 20 }}>
                    <div className="row">
                        <div>
                            <button
                                className="btn btn-block btn-primary"
                                disabled={this.state.disabled}
                                onClick={this.resolveTicket}
                                >
                                I have resolved this ticket!
                            </button>
                        </div>
                        <div style={{ marginLeft: 20 }}>
                            <button
                                className="btn btn-block btn-danger"
                                disabled={this.state.disabled}
                                onClick={this.closeTicket}
                                >
                                Close Ticket
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
  }
}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TicketPage) as any
);
