import { makeCall } from "./client";

export class AdminLogsAPI {

  /**
   * Get the admin logs
   * @param options 
   * @returns 
   */
  public getAdminLogs = (options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('GET', `admin/logs`, data);
  }

  /**
   * Get the distinct actions for the admin logs
   * @param options 
   * @returns 
   */
  public getAdminLogsKnownActions = (options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('GET', `admin/logs/known/actions`, data);
  }
}