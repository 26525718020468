//
// I really don't like this layout. Originally it was a grid but the table kept rejecting state updates.
// I think this can be cleaned up significantly, including breaking it out by device;
// for example, just because the DB has the fields doesn't mean we need to expose the data
// to the support reps
//
// I also don't like the modal, but I need to move on to other tasks, especially
// since this isn't finalized throughout the platform
//

import React, { Component } from "react";

import Screen from "src/components/structure/Screen";
import { DeviceAPI, NotificationsAPI, PetsAPI } from "src/api";
import {  BlankPushPreference, IPushPreference } from "src/api/notifications";
import { Table } from "src/components/structure";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { error, success } from "src/components/structure/Alert";
import { Modal } from "react-bootstrap";

const app = "wagz2";

interface IPushPreferencesProps {
  userId: number;
}

interface IPushPreferencesState {
  loading: boolean;
  pets: any[];
  devices: any[];
  petPreferences: IPushPreference[];
  devicePreferences: IPushPreference[];
  showEditModal: boolean;
  selectedPreference: IPushPreference;
}

export default class PushPreferencesScreen extends Component<IPushPreferencesProps, IPushPreferencesState> {
  constructor(props: IPushPreferencesProps) {
    super(props);

    this.state = {
      loading: false,
      pets: [],
      devices: [],

      petPreferences: [],
      devicePreferences: [],
      showEditModal: false,
      selectedPreference: BlankPushPreference,
    };

    this.setup = this.setup.bind(this);
    this.updatePreference = this.updatePreference.bind(this);
    this.savePreferences = this.savePreferences.bind(this);
    this.selectPreference = this.selectPreference.bind(this);
    this.toggleShowEditModal = this.toggleShowEditModal.bind(this);
  }

  componentDidMount(){
    this.setup();
  }

  render() {
    return (
      <Screen id="PushPreferences" requiredRoles={["support", "product", "qa"]} loading={this.state.loading}>
      <div className="row" style={{marginBottom: 20}}>
        <div className="col-12">
        <Table 
          title="Pet Push Settings" 
          columns={petCols}
          rows={this.state.petPreferences}
          showDownload={true}
          csvFileName={`petPushPreferences-userID-${this.props.userId}`}
        />
        </div>
      </div>
        <div className="row" style={{marginBottom: 20}}>
          <div className="col-12">
          <Table 
            title="Device Push Settings" 
            columns={deviceCols}
            rows={this.state.devicePreferences}
            showDownload={true}
            csvFileName={`devicePushPreferences-userID-${this.props.userId}`}
          />     
          </div>
        </div> 

        <Modal
          show={this.state.showEditModal}
          onHide={this.toggleShowEditModal}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Saving {this.state.selectedPreference.settingType} Preferences for {this.state.selectedPreference.display}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.selectedPreference.settingType === "pet" ? (
              <>
              <div className="row row-margin-bottom">
                <div className="col-3">
                  <label>Activity</label>
                  <select className="form-control" id="activity" value={this.state.selectedPreference.activity} onChange={this.updatePreference}>
                    <option value="all">All</option>
                    <option value="app_only">No Push</option>
                  </select>
                </div>
                <div className="col-3">
                  <label>Fences</label>
                  <select className="form-control" id="fences" value={this.state.selectedPreference.fences} onChange={this.updatePreference}>
                    <option value="all">All</option>
                    <option value="app_only">No Push</option>
                  </select>
                </div>
                <div className="col-3">
                  <label>Tagz</label>
                  <select className="form-control" id="tagz" value={this.state.selectedPreference.tagz} onChange={this.updatePreference}>
                    <option value="all">All</option>
                    <option value="app_only">No Push</option>
                  </select>
                </div>
                <div className="col-3">
                  <label>Temperature</label>
                  <select className="form-control" id="temperature" value={this.state.selectedPreference.temperature} onChange={this.updatePreference}>
                    <option value="all">All</option>
                    <option value="app_only">No Push</option>
                  </select>
                </div>
              </div>
                <div className="row row-margin-bottom">
                  <div className="col-3">
                    <label>Power</label>
                    <select className="form-control" id="power" value={this.state.selectedPreference.power} onChange={this.updatePreference}>
                      <option value="all">All</option>
                      <option value="app_only">No Push</option>
                    </select>
                  </div>
                  <div className="col-3">
                    <label>OTA</label>
                    <select className="form-control" id="ota" value={this.state.selectedPreference.ota} onChange={this.updatePreference}>
                      <option value="all">All</option>
                      <option value="app_only">No Push</option>
                    </select>
                  </div>
                  <div className="col-3">
                    <label>System</label>
                    <select className="form-control" id="system" value={this.state.selectedPreference.system} onChange={this.updatePreference}>
                      <option value="all">All</option>
                      <option value="app_only">No Push</option>
                    </select>
                  </div>
                  <div className="col-3">
                    <label>Other</label>
                    <select className="form-control" id="other" value={this.state.selectedPreference.other} onChange={this.updatePreference}>
                      <option value="all">All</option>
                      <option value="app_only">No Push</option>
                    </select>
                  </div>
                </div>
                <div className="row row-margin-bottom">
                  <div className="col-3">
                    <label>Food Recommendations</label>
                    <select className="form-control" id="foodRecommendation" value={this.state.selectedPreference.foodRecommendation} onChange={this.updatePreference}>
                      <option value="all">All</option>
                      <option value="app_only">No Push</option>
                    </select>
                  </div>
                  <div className="col-3">
                    <label>Consumption Trends</label>
                    <select className="form-control" id="consumptionTrend" value={this.state.selectedPreference.consumptionTrend} onChange={this.updatePreference}>
                      <option value="all">All</option>
                      <option value="app_only">No Push</option>
                    </select>
                  </div>
                  <div className="col-3">
                    <label>Consumption Reports</label>
                    <select className="form-control" id="consumptionReport" value={this.state.selectedPreference.consumptionReport} onChange={this.updatePreference}>
                      <option value="all">All</option>
                      <option value="app_only">No Push</option>
                    </select>
                  </div>
                </div>
              </>
            )
            : 
            (<>
              <div className="row row-margin-bottom">
                <div className="col-3">
                  <label>Power</label>
                  <select className="form-control" id="power" value={this.state.selectedPreference.power} onChange={this.updatePreference}>
                    <option value="all">All</option>
                    <option value="app_only">No Push</option>
                  </select>
                </div>
                <div className="col-3">
                  <label>OTA</label>
                  <select className="form-control" id="ota" value={this.state.selectedPreference.ota} onChange={this.updatePreference}>
                    <option value="all">All</option>
                    <option value="app_only">No Push</option>
                  </select>
                </div>
                <div className="col-3">
                  <label>System</label>
                  <select className="form-control" id="system" value={this.state.selectedPreference.system} onChange={this.updatePreference}>
                    <option value="all">All</option>
                    <option value="app_only">No Push</option>
                  </select>
                </div>
                <div className="col-3">
                  <label>Other</label>
                  <select className="form-control" id="other" value={this.state.selectedPreference.other} onChange={this.updatePreference}>
                    <option value="all">All</option>
                    <option value="app_only">No Push</option>
                  </select>
                </div>
              </div>
              <div className="row row-margin-bottom">
                <div className="col-3">
                  <label>Tray Changed</label>
                  <select className="form-control" id="trayChanged" value={this.state.selectedPreference.trayChanged} onChange={this.updatePreference}>
                    <option value="all">All</option>
                    <option value="app_only">No Push</option>
                  </select>
                </div>
                <div className="col-3">
                  <label>Tray Reminders</label>
                  <select className="form-control" id="trayReminders" value={this.state.selectedPreference.trayReminders} onChange={this.updatePreference}>
                    <option value="all">All</option>
                    <option value="app_only">No Push</option>
                  </select>
                </div>
                <div className="col-3">
                  <label>Consumption Trends</label>
                  <select className="form-control" id="consumptionTrend" value={this.state.selectedPreference.consumptionTrend} onChange={this.updatePreference}>
                    <option value="all">All</option>
                    <option value="app_only">No Push</option>
                  </select>
                </div>
                <div className="col-3">
                  <label>Consumption Reports</label>
                  <select className="form-control" id="consumptionReport" value={this.state.selectedPreference.consumptionReport} onChange={this.updatePreference}>
                    <option value="all">All</option>
                    <option value="app_only">No Push</option>
                  </select>
                </div>
              </div>
              <div className="row row-margin-bottom">
                <div className="col-3">
                  <label>Cleaning</label>
                  <select className="form-control" id="cleaning" value={this.state.selectedPreference.cleaning} onChange={this.updatePreference}>
                    <option value="all">All</option>
                    <option value="app_only">No Push</option>
                  </select>
                </div>
                <div className="col-3">
                  <label>Auto-Replenishment</label>
                  <select className="form-control" id="foodAutoReplenishment" value={this.state.selectedPreference.foodAutoReplenishment} onChange={this.updatePreference}>
                    <option value="all">All</option>
                    <option value="app_only">No Push</option>
                  </select>
                </div>
              </div>
            </>)}
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-block btn-primary" onClick={this.savePreferences}>Save</button>
          </Modal.Footer>
        </Modal>
      </Screen>
    )
  }

  private updatePreference(e: any){
    const id = e.target.id;
    const value = e.target.value;
    const selectedPreference = this.state.selectedPreference;
    selectedPreference[id] = value;
    this.setState( {selectedPreference} );
  }

  private setup() {
    this.setState({ loading: true }, async () => {
      try{
        // get all the pets and devices for the user so we can do all the joins we need
        const petsResult = await PetsAPI.getPetsForUser(app, this.props.userId)
        const devicesResult = await DeviceAPI.getDevicesForUser(app, this.props.userId, {});
        const pets = petsResult.body.data;
        const devices = devicesResult.body.data;
        // loop over each to build out the data
        const devicePreferences: IPushPreference[] = [];
        const petPreferences: IPushPreference[] = [];
        for(const d of pets){
          if(d.status === "active"){
            const pushResult = await NotificationsAPI.getUserPushPetPreferences(app, this.props.userId, d.id);
            const p: IPushPreference = pushResult.body.data;
            p.display = d.name;
            p.settingType = "pet";
            const pref = this.processPreferenceRow(p);
            petPreferences.push(pref);
          }
        }
        for(const d of devices){
          // only active healthboxes and bowls; collars are for pets!
          if(d.status === "active" && (d.deviceType === "healthBox" || d.deviceType === "bowl")){
            const pushResult = await NotificationsAPI.getUserPushDevicePreferences(app, this.props.userId, d.id);
            const p: IPushPreference = pushResult.body.data;
            p.display = d.nickname;
            p.settingType = "device";
            // TODO: do we want to go get the pet name?
            const pref = this.processPreferenceRow(p);
            devicePreferences.push(pref);
          }
        }
        this.setState({ loading: false, petPreferences, devicePreferences, pets, devices, })
      }catch(err){
        this.setState({ loading: false });
      }
    })
  }

  private processPreferenceRow(row: IPushPreference): IPushPreference{
    const processed = {...row};
    const id = row.settingType === "device" ? row.deviceId : row.petId;
    processed.activityDisplay = this.convertDisplay(processed.activity);
    processed.cleaningDisplay = this.convertDisplay(processed.cleaning);
    processed.consumptionReportDisplay = this.convertDisplay(processed.consumptionReport);
    processed.consumptionTrendDisplay = this.convertDisplay(processed.consumptionTrend);
    processed.fencesDisplay = this.convertDisplay(processed.fences);
    processed.foodAutoReplenishmentDisplay = this.convertDisplay(processed.foodAutoReplenishment);
    processed.foodRecommendationDisplay = this.convertDisplay(processed.foodRecommendation);
    processed.otaDisplay = this.convertDisplay(processed.ota);
    processed.otherDisplay = this.convertDisplay(processed.other);
    processed.powerDisplay = this.convertDisplay(processed.power);
    processed.systemDisplay = this.convertDisplay(processed.system);
    processed.tagzDisplay = this.convertDisplay(processed.tagz);
    processed.temperatureDisplay = this.convertDisplay(processed.temperature);
    processed.trayChangedDisplay = this.convertDisplay(processed.trayChanged);
    processed.trayRemindersDisplay = this.convertDisplay(processed.trayReminders);
    processed.actions = (<FontAwesomeIcon icon={faPencilAlt} className="text-success" style={{fontWeight: "bold"}} id={`${row.settingType}-${id}`} onClick={() => this.selectPreference(row)} />);
    return processed;
  }

  private selectPreference(row: IPushPreference){
    this.setState({ selectedPreference: row, showEditModal: true});
  }

  private toggleShowEditModal(){
    this.setState({ showEditModal: !this.state.showEditModal });
  }

  private savePreferences(){
    this.setState({ loading: false }, async () => {
      const settingType = this.state.selectedPreference.settingType;
      const targetId = settingType === "pet" ? this.state.selectedPreference.petId : this.state.selectedPreference.deviceId;
      let currentState = {...this.state};
      try{
        if(settingType === "pet"){
          await NotificationsAPI.saveUserPushPetPreferences(app, this.props.userId, targetId, this.state.selectedPreference)
          const petPreferences: IPushPreference[] = [];
          for(let p of currentState.petPreferences){
            if(p.petId === targetId){
              petPreferences.push(this.processPreferenceRow(this.state.selectedPreference));
            } else {
              petPreferences.push(p);
            }
          }
          currentState.petPreferences = petPreferences;
        }else {
          await NotificationsAPI.saveUserPushDevicePreferences(app, this.props.userId, targetId, this.state.selectedPreference)
          const devicePreferences: IPushPreference[] = [];
          for(let p of currentState.devicePreferences){
            if(p.deviceId === targetId){
              devicePreferences.push(this.processPreferenceRow(this.state.selectedPreference));
            } else {
              devicePreferences.push(p);
            }
          }
          currentState.devicePreferences = devicePreferences;
        }
        success("Saved!");
        this.setState({ ...currentState, showEditModal: false, loading: false });
      }catch(err){
        error("Could not save that preference");
        this.setState({ loading: false });
      }
    })
  }

  private convertDisplay(input: string): string {
    if(input === "app_only"){
      return "No Push";
    } else if(input === "none"){
      return "None";
    }
    return "All";
  }
}

const petCols = [
  {
    label: "For",
    field: "display",
  },
  {
    label: "Activity",
    field: "activityDisplay",
  },
  {
    label: "Consumption Reports",
    field: "consumptionReportDisplay",
  },
  {
    label: "Consumption Trends",
    field: "consumptionTrendDisplay",
  },
  {
    label: "Fences",
    field: "fencesDisplay",
  },
  {
    label: "Food Recommendation",
    field: "foodRecommendationDisplay",
  },
  {
    label: "OTA",
    field: "otaDisplay",
  },
  {
    label: "Other",
    field: "otherDisplay",
  },
  {
    label: "Power",
    field: "powerDisplay",
  },
  {
    label: "System",
    field: "systemDisplay",
  },
  {
    label: "Tagz",
    field: "tagzDisplay",
  },
  {
    label: "Temperature",
    field: "temperatureDisplay",
  },
  {
    label: "",
    field: "actions",
  },
];


const deviceCols = [
  {
    label: "For",
    field: "display",
  },
  {
    label: "Cleaning",
    field: "cleaningDisplay",
  },
  {
    label: "Consumption Reports",
    field: "consumptionReportDisplay",
  },
  {
    label: "Consumption Trends",
    field: "consumptionTrendDisplay",
  },
  {
    label: "Auto Replenishment",
    field: "foodAutoReplenishmentDisplay",
  },
  {
    label: "OTA",
    field: "otaDisplay",
  },
  {
    label: "Other",
    field: "otherDisplay",
  },
  {
    label: "Power",
    field: "powerDisplay",
  },
  {
    label: "System",
    field: "systemDisplay",
  },
  {
    label: "Tray Changed",
    field: "trayChangedDisplay",
  },
  {
    label: "Tray Reminders",
    field: "trayRemindersDisplay",
  },
  {
    label: "",
    field: "actions",
  },
];