import {
  faCheckCircle,
  faTimesCircle,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";

export const handlePhone = (value: string) => {
  const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  return value.replace(phoneRegex, "($1) $2-$3");
};

export function capitalizeFirstLetter(word: string) {
  if(!word || word === ""){
    return "";
  }
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export function uniqueStringArray(original: string[]): string[] {
  return original.filter((v, i, self) => {
    return self.indexOf(v) === i;
  })
}

export function formatFWVersion(v: string) {
  if (v.indexOf('+') > -1) {
    return v.substr(0, v.indexOf('+'))
  }
  return v.substr(v.indexOf('Z')+2)
}

export function getIcon(input: string) {
  switch (input.toLowerCase()) {
    case "on": {
      return faCheckCircle;
    }
    case "yes": {
      return faCheckCircle;
    }
    case "no": {
      return faTimesCircle;
    }
    case "off": {
      return faTimesCircle;
    }
    case "": {
      return faQuestionCircle;
    }
    default: {
      return faQuestionCircle;
    }
  }
}

export function getColor(input: string) {
  switch (input.toLowerCase()) {
    case "on": {
      return "text-success";
    }
    case "yes": {
      return "text-success";
    }
    case "no": {
      return "text-danger";
    }
    case "off": {
      return "text-danger";
    }
    default: {
      return "text-primary";
    }
  }
}

export function handleBool(value: boolean) {
  switch (value) {
    case true: {
      return "True";
    }
    case false: {
      return "False";
    }
    default: {
      return "";
    }
  }
}

export function unixToDate(u: number) {
  var utcSeconds = u;
  var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
  d.setUTCSeconds(utcSeconds);
  const dateString = d.toString()

  return dateString.substring(0, 15);
}

export function secondsToHms(d: number) {
  var h = Math.floor(d / 3600);
  var m = Math.floor(d % 3600 / 60);
  var s = Math.floor(d % 3600 % 60);

  var hDisplay = h > 0 ? h + (h === 1 ? "h" : "h") : "";
  var mDisplay = m > 0 ? m + (m === 1 ? "m" : "m") : "";
  var sDisplay = s > 0 ? s + (s === 1 ? "s" : "s") : "";
  return hDisplay + mDisplay + sDisplay; 
}

export function getDistanceFromLatLngInKm(lat1: number, lon1: number, lat2: number, lon2: number) {
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(lat2-lat1);  // deg2rad below
  var dLon = deg2rad(lon2-lon1); 
  var a = 
    Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
    Math.sin(dLon/2) * Math.sin(dLon/2)
    ; 
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
  var d = R * c; // Distance in km
  return d;
}

function deg2rad(deg: number) {
  return deg * (Math.PI/180)
}

export function familyToDisplay(family: string):string {
  switch(family){
    case "18":
      return "Tagz";
    case "19":
      return "Explore";
    case "23":
      return "Freedom";
    case "24":
      return "Small Pet 24";
    case "25":
      return "Small Pet 25";
    case "26":
      return "Health Box";
    case "27":
      return "Tagz";
    case "30":
      return "Freedom 23";
      case "31":
        return "Bowl";
    default:
      return family;
  }
}