import * as React from "react";

interface ILoadingProps {
  style?: any;
}

export const Loading = (props: ILoadingProps) => {
  return (
    <div style={props.style ? props.style : {textAlign: "center"}}>
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
}