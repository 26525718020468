import { makeCall } from "./client";

export class DeviceGroupsAPI {

  /**
   * Get all of the device groups filtered by a set of parameters
   * @param app 
   * @param data 
   * @param options 
   * @returns 
   */
  public getDeviceGroups = (app: string, data: any = {}, options: any = {}) => {
    const allData = {
      ...data,
    };
    return makeCall('GET', `admin/${app}/devices/groups`, allData, options);
  }

  /**
   * Create a new device group
   * @param app 
   * @param data 
   * @param options 
   * @returns 
   */
  public createDeviceGroup = (app: string, data: any = {}, options: any = {}) => {
    const allData = {
      ...data,
    };
    return makeCall('POST', `admin/${app}/devices/groups`, allData, options);
  }

  /**
   * Get supported actions for groups
   * @param app 
   * @param data 
   * @param options 
   * @returns 
   */
  public getDeviceGroupActions = (app: string, data: any = {}, options: any = {}) => {
    const allData = {
      ...data,
    };
    return makeCall('GET', `admin/${app}/devices/groups/actions`, allData, options);
  }

  /**
   * Get a device group and its devices
   * @param app 
   * @param groupId 
   * @param data 
   * @param options 
   * @returns 
   */
  public getDeviceGroup = (app: string, groupId: number, data: any = {}, options: any = {}) => {
    const allData = {
      ...data,
    };
    return makeCall('GET', `admin/${app}/devices/groups/${groupId}`, allData, options);
  }

  /**
   * Delete a device group
   * @param app 
   * @param groupId 
   * @param data 
   * @param options 
   * @returns 
   */
  public deleteDeviceGroup = (app: string, groupId: number, data: any = {}, options: any = {}) => {
    const allData = {
      ...data,
    };
    return makeCall('DELETE', `admin/${app}/devices/groups/${groupId}`, allData, options);
  }

  /**
   * Update a device group
   * @param app 
   * @param groupId 
   * @param data 
   * @param options 
   * @returns 
   */
  public updateDeviceGroup = (app: string, groupId: number, data: any = {}, options: any = {}) => {
    const allData = {
      ...data,
    };
    return makeCall('PATCH', `admin/${app}/devices/groups/${groupId}`, allData, options);
  }

  /**
   * Link a device to the group
   * @param app 
   * @param groupId 
   * @param deviceId 
   * @param data 
   * @param options 
   * @returns 
   */
  public linkDeviceToGroup = (app: string, groupId: number, deviceId: number, data: any = {}, options: any = {}) => {
    const allData = {
      ...data,
    };
    return makeCall('PUT', `admin/${app}/devices/groups/${groupId}/devices/${deviceId}`, allData, options);
  }

  /**
   * Unlink a device from the group
   * @param app 
   * @param groupId 
   * @param deviceId 
   * @param data 
   * @param options 
   * @returns 
   */
  public unlinkDeviceFromGroup = (app: string, groupId: number, deviceId: number, data: any = {}, options: any = {}) => {
    const allData = {
      ...data,
    };
    return makeCall('DELETE', `admin/${app}/devices/groups/${groupId}/devices/${deviceId}`, allData, options);
  }

  /**
   * Send an action to all devices in a group
   * @param app 
   * @param groupId 
   * @param action 
   * @param data 
   * @param options 
   * @returns 
   */
  public sendActionToGroup = (app: string, groupId: number, action: string, data: any = {}, options: any = {}) => {
    const allData = {
      ...data,
    };
    return makeCall('POST', `admin/${app}/devices/groups/${groupId}/actions/${action}`, allData, options);
  }

  /**
   * Assign a random set of devices to a group
   * @param app 
   * @param groupId 
   * @param data 
   * @param options 
   * @returns 
   */
  public assignRandomDevicesToGroup = (app: string, groupId: number, data: any = {}, options: any = {}) => {
    const allData = {
      ...data,
    };
    return makeCall('POST', `admin/${app}/devices/groups/${groupId}/assign/random`, allData, options);
  }
}