import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as UserActions from "src/reducers/userReducer";
import { UserAPI } from "src/api";
import Card from "src/components/structure/Card";
import { styles } from "src/styles";

import * as Alert from "src/components/structure/Alert";
import Geofence from "src/components/structure/Inset";

interface IGeofenceInsetProps {
  location: any;
  userActions: any;
  userState: any;
}

interface IGeofenceInsetState {
  loading: boolean;
  userId: string;
  userEmail: string;
  showAllFencesModal: boolean;
  fences: any[];
  userFences: any[];
  lat: any;
  lng: any;
}

class GeofenceInset extends Component<
  IGeofenceInsetProps,
  IGeofenceInsetState
> {
  constructor(props: IGeofenceInsetProps) {
    super(props);

    this.state = {
      loading: false,
      userId: "",
      userEmail: "",
      showAllFencesModal: false,
      fences: [],
      userFences: [],
      lat: 0,
      lng: 0,
    };

    this.updateField = this.updateField.bind(this);
    this.getUserFences = this.getUserFences.bind(this);
    this.getUser = this.getUser.bind(this);
    this.clear = this.clear.bind(this);
  }

  updateField(e: any) {
    let ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  clear() {
    this.setState({
      loading: false,
      userId: "",
      userEmail: "",
      fences: [],
      userFences: [],
      lat: 0,
      lng: 0,
    });
  }

  getUser() {
    if (this.state.userId === "" && this.state.userEmail === "") {
      Alert.error("You must provide either a user ID or user email.");
    }
    if (this.state.userId !== "") {
      this.getUserFences();
      return;
    }
    this.setState({ loading: true }, async () => {
      try {
        const res = await UserAPI.getUserByEmail("wagz", this.state.userEmail);
        this.setState({
          loading: false,
          userId: res.body.data.id.toString(),
          userEmail: res.body.data.email,
        });
        this.getUserFences();
      } catch (err) {
        this.setState({ loading: false });
      }
    });
  }

  setFences() {
    const userFences = [];
    if (this.state.fences.length === 0) {
      userFences.push(
        <strong key={1}>No geofences exist for this user</strong>
      );
    } else {
      let index = 0;
      for (let userFence of this.state.fences) {
        index += 1;
        userFences.push(
          <Geofence
            key={index}
            fence={userFence}
            loading={false}
            userId={parseInt(this.state.userId)}
            simple={false}
          />
        );
      }
    }
    return userFences;
  }

  getUserFences() {
    this.setState({ loading: true }, async () => {
      try {
        const res = await UserAPI.getGeofencesForUser(
          "wagz",
          parseInt(this.state.userId)
        );

        this.setState({
          loading: false,
          fences: res.body.data,
          lat: res.body.data[0].area[0].lat,
          lng: res.body.data[0].area[0].lng,
        });
        this.setFences();
      } catch (err) {
        this.setState({ loading: false });
      }
    });
  }

  render() {
    return (
      <div>
        <div className="col-6">
          <Card style={{ marginLeft: 15 }}>
            <label>Please choose one of the following</label>
            <div style={styles.borderBottom}></div>
            <div className="row">
              <div className="col-6" style={{ marginTop: 4 }}>
                <p> Search for a user's geofences by their user ID:</p>
              </div>
              <div className="col-6">
                <input
                  type="text"
                  id="userId"
                  className="form-control"
                  autoComplete="off"
                  value={this.state.userId}
                  onChange={this.updateField}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-6" style={{ marginTop: 4 }}>
                <p>Search for a user's geofences by their email address:</p>
              </div>
              <div className="col-6">
                <input
                  type="text"
                  id="userEmail"
                  className="form-control"
                  autoComplete="off"
                  value={this.state.userEmail}
                  onChange={this.updateField}
                />
              </div>
            </div>
            <div className="row" style={{ marginTop: 15 }}>
              <div className="col-5">
                <button
                  className="btn btn-block btn-danger"
                  onClick={this.clear}
                >
                  Clear
                </button>
              </div>
              <div className="col-5 offset-1">
                <button
                  className="btn btn-block btn-primary"
                  onClick={this.getUser}
                >
                  Get Geofences
                </button>
              </div>
            </div>
          </Card>
        </div>
        <Card title="Geofences for User">{this.setFences()}</Card>
      </div>
    );
  }
}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default connect<{}, {}, any>(
  mapStateToProps,
  mapDispatchToProps
)(GeofenceInset);
