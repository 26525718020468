import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as UserActions from "src/reducers/userReducer";
import { DeviceAPI } from "src/api";
import { styles } from "src/styles";
import { IDevice } from "src/api/devices";
import { Alert, Card, Table } from "src/components/structure";
import moment from "moment";
import { Modal } from "react-bootstrap";

const dateFormat = "MM/DD/YYYY HH:mm:ss A";

interface IStatusTabProps {
  device: IDevice;
  location: any;
  userActions: any;
  userState: any;
}

interface IStatusTabState {
  loading: boolean;
  statusBar: any;
  bars: any[];
  archiveDelete: boolean;
  deleteTotal: string;
}

class StatusTab extends Component<IStatusTabProps, IStatusTabState> {
  constructor(props: IStatusTabProps) {
    super(props);

    this.state = {
      loading: true,
      statusBar: "No status bar found for this device",
      bars: [],
      archiveDelete: false,
      deleteTotal: "",
    };

    this.updateField = this.updateField.bind(this);
    this.getStatus = this.getStatus.bind(this);
    this.toggleArchiveDelete = this.toggleArchiveDelete.bind(this);
    this.deleteStatusBars = this.deleteStatusBars.bind(this);
  }

  updateField(e: any) {
    let ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  componentDidMount() {
    this.getStatus();
  }

  getStatus() {
    this.setState({ loading: true }, async () => {
      try {
        const res = await DeviceAPI.getDeviceStatus(
          "wagz",
          this.props.device.id
        );
        this.setState({
          statusBar: res.body.data,
        }, () => this.getStatusBars());
      } catch (err) {
        this.setState({ loading: false });
      }
    });
  }

  getStatusBars() {
    this.setState({ loading: true }, async () => {
      try {
        const result = await DeviceAPI.getDeviceStatusArchives(
          "wagz",
          this.props.device.id
        );

        const bars: any[] = [];
        for(const b of result.body.data){
          // check if it's lastUpdated or inserted
          if(!b.lastUpdated){
            b.lastUpdated = b.inserted;
          }
          b.lastUpdated = moment(b.lastUpdated).format("MM/DD/YYYY HH:mm:ss A");
          bars.push(b);
        }

        bars.sort((a: any, b: any) => {
          return moment.utc(a.lastUpdated, dateFormat).isBefore(moment.utc(b.lastUpdated, dateFormat)) ? -1 : 1;
        });

        this.setState({
          loading: false,
          bars,
        })

      } catch (err) {
        this.setState({ loading: false });
      }
    });
  }

  private toggleArchiveDelete() {
    this.setState({ archiveDelete: !this.state.archiveDelete });
  }

  deleteStatusBars() {
    this.setState({ loading: true }, async () => {
      try {
        const days = {
          deleteEarlierThan: this.state.deleteTotal,
        };
        await DeviceAPI.deleteDeviceStatusArchives(
          "wagz",
          this.props.device.id,
          days
        );
        this.setState({
          loading: false,
          deleteTotal: "",
          archiveDelete: false,
        });
        Alert.success("Delete request has been completed");
        this.getStatusBars();
      } catch (err) {
        this.setState({ loading: false, archiveDelete: false });
        Alert.error(
          "Hmm, that doesn't seem to be working right now.  Try again."
        );
      }
    });
  }

  render() {
    return (
      <div className="row">
        <div className="col-3">
          <Card title="Current Raw" loading={this.state.loading}>
            <span>Last Updated {moment.utc(this.state.statusBar.lastUpdated).fromNow()}</span>
            <div style={styles.shadowBox}>
              <div style={{ margin: 10 }}>
                <h4>Status Bar Object</h4>
                <pre>{JSON.stringify(this.state.statusBar, null, 2)}</pre>
              </div>
            </div>
          </Card>
        </div>
        <div className="col-9">
          <div className="row" style={{marginBottom: 10}}>
            <div className="col-12">
              <Table
                title="Current Status Bar"
                loading={this.state.loading}
                csvFileName={`status-bars-${this.props.device.id}`}
                showDownload={true}
                columns={this.props.device.deviceType === "collar" ? collarCols : this.props.device.deviceType === "bowl" ? bowlCols : healthBoxCols}
                rows={[this.state.statusBar]} />
            </div>
          </div>
          <div className="row" style={{marginBottom: 10}}>
            <div className="col-12">
              <Table
                title="Archived Status Bars"
                loading={this.state.loading}
                csvFileName={`archived-status-bars-${this.props.device.id}`}
                showDownload={true}
                columns={this.props.device.deviceType === "collar" ? collarCols : this.props.device.deviceType === "bowl" ? bowlCols : healthBoxCols}
                rows={this.state.bars}
                postElement={(
                  <div className="row">
                    <div className="col-4"></div>
                    <div className="col-3 offset-5" style={{ textAlign: "right" }}>
                      <button
                        className="btn btn-danger"
                        onClick={this.toggleArchiveDelete}
                      >
                        Delete Entries from Archives
                      </button>
                    </div>
                  </div>
                )} />
            </div>
          </div>
        </div>
        <Modal
            dialogClassName="modal-100"
            show={this.state.archiveDelete}
            onHide={this.toggleArchiveDelete}
          >
            <Modal.Header closeButton>
              <Modal.Title>Delete Entries from Archives</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div className="row">
                  <div style={{ marginLeft: 10, marginRight: 10, marginTop: 10 }}>
                  <p style={{ fontSize: 20 }}>
                    Delete archives older than
                  </p>
                  </div>
                  <div style={{ width: "8%" }}>
                    <input
                      type="text"
                      className="form-control"
                      id="deleteTotal"
                      placeholder="x"
                      value={this.state.deleteTotal}
                      onChange={this.updateField}
                    />
                  </div>
                  <div style={{ marginLeft: 10, marginRight: 10, marginTop: 10 }}>
                  <span style={{ fontSize: 20 }}>days.</span>
                  </div>
                </div>
                <div className="text" style={{ marginTop: 15 }}>
                  <button
                    className="btn btn-block btn-danger"
                    style={styles.button}
                    onClick={this.deleteStatusBars}
                  >
                    Delete now
                  </button>
                </div>
                <div className="text" style={{ marginTop: 15 }}>
                  <button
                    className="btn btn-block btn-primary"
                    style={styles.button}
                    onClick={this.toggleArchiveDelete}
                  >
                    Close
                  </button>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>
      </div>
    );
  }
}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default connect<{}, {}, any>(
  mapStateToProps,
  mapDispatchToProps
)(StatusTab);

const bowlCols = [
  {
    label: "Inserted",
    field: "lastUpdated",
    sort: "desc",
  },
  {
    label: "Wifi Level",
    field: "wifiLevel",
  },
  {
    label: "Bowl Weight",
    field: "currentWeight",
  },
  {
    label: "Power Mode",
    field: "powerMode",
  },
  {
    label: "Configuration",
    field: "configuration",
  },
];

const collarCols = [
  {
    label: "Inserted",
    field: "lastUpdated",
    sort: "desc",
  },
  {
    label: "Wifi",
    field: "wifiLevel",
  },
  {
    label: "Cell",
    field: "cellLevel",
  },
  {
    label: "Battery",
    field: "batteryLevel",
  },
  {
    label: "Raw Loc. Acc.",
    field: "rawLocationAccuracy",
  },
  {
    label: "Idle Sec",
    field: "idleSeconds",
  },
  {
    label: "Bluetooth",
    field: "bluetooth",
  },
  {
    label: "Walk Mode",
    field: "walkModeActive",
  },
  {
    label: "Low Power",
    field: "lowPowerActive",
  },
  {
    label: "Live Tracking",
    field: "liveTrackingEnabled"
  },
  {
    label: "SSID",
    field: "currentSSID",
  },
  {
    label: "End of Cycle?",
    field: "endOfCycle",
  },
];

const healthBoxCols = [
  {
    label: "Inserted",
    field: "lastUpdated",
    sort: "desc",
  },
  {
    label: "Wifi Level",
    field: "wifiLevel",
  },
  {
    label: "Weight",
    field: "currentWeight",
  },
  {
    label: "Visiting Pet",
    field: "currentVisitingPet",
  },
];