import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Select from "react-select";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faEdit, faLink } from "@fortawesome/free-solid-svg-icons";
import * as Alert from "src/components/structure/Alert";

import * as AppActions from "src/reducers/appReducer";
import * as UserActions from "src/reducers/userReducer";

import { capitalizeFirstLetter } from "src/utils/utilities";
import Table from "src/components/structure/Table";
import { styles } from "src/styles";
import { UserAPI } from "src/api";
import { roles, parseUserRoles } from "src/utils/items";
import { Card } from "src/components/structure";

interface IAdminUserListProps {
  users: any;
  loading: boolean;
  userActions: any;
  userState: any;
  onUserUpdated: any;
}

interface IAdminUserListState {
  loading: boolean;
  showModal: boolean;
  showLinkModal: boolean;
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  status: string;
  roles: any[];
  links: any[];
  wagzId: string;
  wagzEmail: string;
  filteredAdmins: any[];
  filteredStatus: "all" | "active" | "pending" | "deactivated" | "locked";
  filteredMFA: "all" | "none" | "authenticator" | "sms";
}

class AdminUserList extends Component<
  IAdminUserListProps,
  IAdminUserListState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      showModal: false,
      showLinkModal: false,
      id: 0,
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      status: "",
      roles: [],
      links: [],
      wagzId: "",
      wagzEmail: "",
      filteredAdmins: [],
      filteredMFA: "all",
      filteredStatus: "active",
    };

    this.updateField = this.updateField.bind(this);
    this.updateFilter = this.updateFilter.bind(this);
    this.formatAdminUsers = this.formatAdminUsers.bind(this);
    this.editAdminUser = this.editAdminUser.bind(this);
    this.updateRoles = this.updateRoles.bind(this);
    this.linkWagzID = this.linkWagzID.bind(this);
    this.unlinkWagzID = this.unlinkWagzID.bind(this);
  }

  updateField(e: any) {
    let ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  componentDidMount() {
    this.formatAdminUsers();
  }

  updateRoles(newRoles: any) {
    this.setState({ roles: newRoles });
  }

  formatAdminUsers() {
    if (this.props.users.length === 0) {
      return;
    }
    this.setState({ loading: true }, async () => {
      try {
        const filteredAdmins: any = [];
        const mfaFilter = this.state.filteredMFA;
        const statusFilter = this.state.filteredStatus;

        for (const userObj of this.props.users) {
          if (mfaFilter !== "all" && mfaFilter !== userObj.otpStatus) {
            continue;
          }
          if (statusFilter !== "all" && statusFilter !== userObj.status) {
            continue;
          }
          const user = { ...userObj };

          user.roles = this.parseRolesForTable(userObj);
          user.statusDisplay = capitalizeFirstLetter(user.status);
          user.otpStatusDisplay = capitalizeFirstLetter(user.otpStatus);
          user.actions = (
            <div
              className="row"
              style={{ justifyContent: "center", marginTop: 4 }}
            >
              <div style={{ paddingRight: 5 }}>
                <FontAwesomeIcon
                  icon={faEdit}
                  onClick={() => this.showEditModal(user.id)}
                  className="text-secondary"
                />
              </div>
              <div style={{ paddingLeft: 5 }}>
                <FontAwesomeIcon
                  icon={faLink}
                  onClick={() => this.showLinkModal(user.id)}
                  className="text-success"
                />
              </div>
            </div>
          );
          filteredAdmins.push(user);
        }
        this.setState({
          filteredAdmins,
          loading: false,
        });
      } catch (err) {
        this.setState({ loading: false });
      }
    });
  }

  showEditModal(id: any) {
    this.setState({ loading: true }, async () => {
      try {
        let res = await UserAPI.getAdminUserById(id);
        const roles = parseUserRoles(res.body.data.roles);

        this.setState({
          id: id,
          firstName: res.body.data.firstName,
          lastName: res.body.data.lastName,
          email: res.body.data.email,
          status: res.body.data.status,
          roles: roles,
          showModal: true,
          loading: false,
        });
      } catch (err) {
        this.setState({ loading: false, showModal: false });
      }
    });
  }

  showLinkModal(id: any) {
    this.setState({ loading: true }, async () => {
      try {
        let res = await UserAPI.getWagzUserLinksForAdminId(id);
        this.setState({
          id: id,
          links: res.body.data,
          showLinkModal: true,
          loading: false,
        });
      } catch (err) {
        this.setState({ loading: false, showModal: false });
      }
    });
  }

  editAdminUser() {
    this.setState({ loading: true }, async () => {
      try {
        await UserAPI.editUser(this.state.id, {
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          email: this.state.email,
          password: this.state.password,
          status: this.state.status,
        });

        // now handle the roles
        UserAPI.deleteAllUserRoles(this.state.id);
        for (const r of this.state.roles) {
          UserAPI.addUserRole(this.state.id, r.value);
        }
        Alert.success("User updated successfully");
        this.props.onUserUpdated();
        this.setState({ loading: false, showModal: false }, () =>
          this.formatAdminUsers()
        );
      } catch (err) {
        this.setState({
          loading: false,
          showModal: false,
          roles: this.state.roles,
        });
      }
      this.formatAdminUsers();
    });
  }

  parseRolesForTable(user: any) {
    if (!user.roles || user.roles.length === 0) {
      return <span>None</span>;
    }
    return (
      <span style={{ textTransform: "capitalize" }}>
        {user.roles.join(", ")}
      </span>
    );
  }

  parseWagzLinks(links: any) {
    if (!links || links.length === 0) {
      return <span>None</span>;
    }
    return <span>{links.join(", ")}</span>;
  }

  render() {
    return (
      <div>
        <Card title="Admin Filters" style={{ marginBottom: 10 }}>
          <div className="row">
            <div className="col-3">
              <label>Show Users With Status:</label>
              <select className="form-control" value={this.state.filteredStatus} id="filteredStatus" onChange={this.updateFilter}>
                <option value="all">All</option>
                <option value="active">Active</option>
                <option value="pending">Pending</option>
                <option value="deactivated">Deactivated</option>
                <option value="locked">Locked</option>
              </select>
            </div>
            <div className="col-3">
              <label>Show Users With MFA Status:</label>
              <select className="form-control" value={this.state.filteredMFA} id="filteredMFA" onChange={this.updateFilter}>
                <option value="all">All</option>
                <option value="authenticator">Authenticator</option>
                <option value="sms">SMS</option>
                <option value="none">None</option>
              </select>
            </div>
          </div>
        </Card>

        <Table
          title="Current Admin Users"
          csvFileName="currentAdminUsers"
          showDownload={true}
          columns={cols}
          rows={this.state.filteredAdmins}
        />
        <div>
          <Modal
            dialogClassName="modal-75"
            show={this.state.showModal}
            onHide={() => {
              this.setState({ showModal: false });
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                Update Information for Admin {this.state.id}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="center">
                <p style={{ fontSize: 14, textAlign: "center" }}>
                  <strong>
                    Update the fields you wish to change, then hit Submit.
                  </strong>
                </p>{" "}
                <br />
              </div>
              <div className="form-group" style={{ marginTop: 10 }}>
                <input
                  id="firstName"
                  type="text"
                  className="form-control"
                  placeholder="First Name"
                  autoComplete="new-password"
                  onChange={this.updateField}
                  value={this.state.firstName}
                />
              </div>
              <div className="form-group">
                <input
                  id="lastName"
                  type="text"
                  className="form-control"
                  placeholder="Last Name"
                  autoComplete="new-password"
                  onChange={this.updateField}
                  value={this.state.lastName}
                />
              </div>
              <div className="form-group">
                <input
                  id="email"
                  type="text"
                  className="form-control"
                  placeholder="Email"
                  autoComplete="new-password"
                  onChange={this.updateField}
                  value={this.state.email}
                />
              </div>
              <div className="form-group">
                <input
                  id="password"
                  type="password"
                  className="form-control"
                  placeholder="Password"
                  autoComplete="new-password"
                  onChange={this.updateField}
                  value={this.state.password}
                />
              </div>
              <div className="form-group">
                <label style={{ fontSize: 12, marginLeft: 5 }}>
                  Role(s) for user
                </label>
                <Select
                  options={roles}
                  isMulti={true}
                  onChange={this.updateRoles}
                  hideSelectedOptions={true}
                  value={this.state.roles}
                />
              </div>
              <p>
                You can toggle the Admin User's account status. If you set it to
                deactivated, their roles will be removed and they will no longer
                have access to the admin panel.
              </p>
              <div className="form-group">
                <select
                  className="form-control"
                  id="status"
                  value={this.state.status}
                  onChange={this.updateField}
                >
                  <option value="active">Active</option>
                  <option value="deactivated">Deactivated</option>
                  <option value="pending">Pending</option>
                  <option value="locked">Locked</option>
                </select>
              </div>
              <div className="text">
                <button
                  className="btn btn-block btn-submit"
                  style={styles.button}
                  onClick={this.editAdminUser}
                >
                  Submit
                </button>
              </div>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>
        </div>
        <div>
          <Modal
            dialogClassName="modal-7"
            show={this.state.showLinkModal}
            onHide={() => {
              this.setState({ showLinkModal: false });
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                Wagz users linked to Admin User {this.state.id}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="center">
                <p style={{ fontSize: 18 }}>
                  <strong>Currently linked Wagz user IDs:</strong>
                </p>{" "}
                {this.state.links.map((e: string) => {
                  return (
                    <div>
                      <div className="row" style={{ marginBottom: 5 }} key={e}>
                        <div className="col-4">{e}</div>
                        <div className="row" style={{ paddingLeft: 35 }}>
                          <div style={{ paddingLeft: 5, marginLeft: 5 }}>
                            <FontAwesomeIcon
                              icon={faBan}
                              onClick={() => this.unlinkWagzID(e)}
                              className="text-danger"
                            />
                          </div>
                        </div>
                      </div>
                      <div style={styles.divider} />
                    </div>
                  );
                })}
                <br />
                <div className="form-group">
                  <label style={{ fontSize: 16 }}>
                    Enter the Wagz user email to link:
                  </label>
                  <input
                    type="string"
                    className="form-control"
                    id="wagzEmail"
                    value={this.state.wagzEmail}
                    onChange={this.updateField}
                  />
                </div>
                <div>
                  <button
                    className="btn btn-block btn-success"
                    style={styles.button}
                    onClick={this.linkWagzID}
                  >
                    Link Wagz Account
                  </button>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>
        </div>
      </div>
    );
  }

  private updateFilter(e: any) {
    const ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns, () => {
      this.formatAdminUsers();
    });
  }

  private linkWagzID() {
    this.setState({ loading: true }, async () => {
      try {
        const email = {
          email: this.state.wagzEmail,
        };
        await UserAPI.linkWagzUserAndAdminId(this.state.id, email);
        return this.setState(
          { loading: false, id: 0, showLinkModal: false, wagzEmail: "" },
          () => {
            Alert.success("Linked successfully");
            this.formatAdminUsers();
          }
        );
      } catch (err) {
        Alert.error("Had some trouble with linking");
        this.setState({
          loading: false,
          id: 0,
          showLinkModal: false,
          wagzEmail: "",
        });
      }
    });
  }

  private unlinkWagzID(idToUnlink: any) {
    this.setState({ loading: true }, async () => {
      // const wagzID = parseInt(this.state.wagzId);
      try {
        await UserAPI.unlinkWagzUserAndAdminId(this.state.id, idToUnlink);
        return this.setState(
          { loading: false, id: 0, showLinkModal: false, wagzId: "" },
          () => {
            Alert.success("Unlinked successfully");
            this.formatAdminUsers();
          }
        );
      } catch (err) {
        Alert.error("Had some trouble with unlinking");
        this.setState({
          loading: false,
          id: 0,
          showLinkModal: false,
          wagzId: "",
        });
      }
    });
  }
}

const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(AppActions, dispatch),
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminUserList);

const cols = [
  {
    label: "ID",
    field: "id",
    sort: "asc",
    width: 100,
  },
  {
    label: "First",
    field: "firstName",
  },
  {
    label: "Last",
    field: "lastName",
  },
  {
    label: "Email",
    field: "email",
  },
  {
    label: "Level",
    field: "accessLevel",
  },
  {
    label: "Roles",
    field: "roles",
  },
  {
    label: "Status",
    field: "statusDisplay",
  },
  {
    label: "Bad Logins",
    field: "badLoginAttemptsSinceLogin",
  },
  {
    label: "MFA",
    field: "otpStatusDisplay",
  },
  {
    label: "Actions",
    field: "actions",
  },
];