import React, { Component } from "react";
import { Link } from "react-router-dom";
import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";
import { blue, lightBlue } from "@material-ui/core/colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getDistanceFromLatLngInKm,
  getIcon,
  getColor,
} from "src/utils/utilities";
import GoogleMapReact from "google-map-react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import { styles } from "src/styles";

const mapKey = process.env.REACT_APP_GOOGLE_MAP_KEY || "";

const MapTypeSwitch = withStyles({
  switchBase: {
    color: lightBlue[300],
    "&$checked": {
      color: blue[500],
    },
    "&$checked + $track": {
      backgroundColor: blue[400],
    },
  },
  checked: {},
  track: {},
})(Switch);

interface IGeofenceProps {
  key: number;
  loading: boolean;
  fence: any;
  userId: number;
  simple: boolean;
}

interface IGeofenceState {
  active: string;
  area: any[];
  areaClosed: any[];
  pets: any[];
  name: string;
  type: string;
  fenceColor: string;
  id: number;
  mapType: string;
  mapTypeChecked: boolean;
  perimeter: number;
  areaSizeMeters: number;
  insetEnabled: string;
  insetArea: any[];
  insetAreaClosed: any[];
  insetDistance: number;
  insetColor: string;
}
class Geofence extends Component<IGeofenceProps, IGeofenceState> {
  constructor(props: any) {
    super(props);
    this.state = {
      active: "",
      area: this.props.fence.area,
      areaClosed: [],
      pets: [],
      name: this.props.fence.name,
      type: "",
      fenceColor: "#FFFFFF",
      id: this.props.fence.id,
      mapType: "satellite",
      mapTypeChecked: true,
      perimeter: 0,
      areaSizeMeters: this.props.fence.areaSizeMeters,
      insetEnabled: "",
      insetArea: this.props.fence.insetPoints,
      insetAreaClosed: [],
      insetDistance: this.props.fence.insetDistance,
      insetColor: "#FFFFFF"
    };

    this.updateField = this.updateField.bind(this);
    this.setDisplay = this.setDisplay.bind(this);
    this.amendArea = this.amendArea.bind(this);
    this.handleSwitch = this.handleSwitch.bind(this);
    this.calculatePerimeter = this.calculatePerimeter.bind(this);
  }

  componentDidMount() {
    this.setDisplay();
  }

  updateField(e: any) {
    let ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  // need this to close off the geofence for viewing
  amendArea() {
    var amendedArea = this.state.area;
    amendedArea.push(this.state.area[0]);

    var amendedInsetArea = this.state.insetArea;
    amendedInsetArea.push(this.state.insetArea[0]);

    this.calculatePerimeter(amendedArea);

    this.setState({ areaClosed: amendedArea, insetAreaClosed: amendedInsetArea });
  }

  calculatePerimeter(a: any) {
    let perimeter: number = 0;
    let pointCount: number = 0;
    while (pointCount < a.length - 1) {
      const distance = getDistanceFromLatLngInKm(
        a[pointCount].lat,
        a[pointCount].lng,
        a[pointCount + 1].lat,
        a[pointCount + 1].lng
      );
      perimeter += distance;
      pointCount++;
    }

    // convert to meters
    const perimeterInMeters = perimeter * 1000;
    this.setState({ perimeter: Math.round(perimeterInMeters) });
  }

  // we have a few bools & camel case parts of the props that need to be dealt with here
  setDisplay() {
    this.amendArea();
    const geofence = this.props.fence;

    var insetColor = "";
    var active = "";
    var fenceColor = "";
    var type = "";

    if (geofence.active) {
      active = "Yes"
    } else {
      active = "No"
    }
    switch (geofence.type) {
      case "allowed": {
        type = "Allowed";
        fenceColor = "#11cdef";
        break;
      }
      case "notAllowed": {
        type = "Not Allowed";
        fenceColor = "#FF4433";
        break;
      }
      default: {
        type = "unknown";
        break;
      }
    }

    if (this.props.fence.insetDistance === 0) {
      insetColor = "#11cdef"
    } else {
      insetColor = "#FFD700"
    }
    this.setState({ active, fenceColor, insetColor, type });

    const assignedPets = [];

    for (let pets of geofence.pets) {
      if (pets.fenceActive) {
        assignedPets.push(
          <div key={pets.id}>
            <Link
              to={`/support/users/${this.props.userId}/pets/${pets.id}`}
              id="pet_page"
              style={{ color: "blue", textDecoration: "underline" }}
            >
              {pets.name}
            </Link>
            <br />
          </div>
        );
      }
      this.setState({ pets: assignedPets, insetEnabled: geofence.insetEnabled });
    }
  }

  handleGoogleMapApi = (google: {
    maps: {
      Polyline: new (arg0: {
        path: { lat: number; lng: number }[];
        geodesic: boolean;
        strokeColor: string;
        strokeOpacity: number;
        strokeWeight: number;
      }) => any;
    };
    map: any;
  }) => {
    var flightPath = new google.maps.Polyline({
      path: this.state.areaClosed,
      geodesic: true,
      strokeColor: this.state.fenceColor,
      strokeOpacity: 1,
      strokeWeight: 5,
    });

    flightPath.setMap(google.map);
      var insetPath = new google.maps.Polyline({
        path: this.state.insetAreaClosed,
        geodesic: true,
        strokeColor: this.state.insetColor,
        strokeOpacity: 1,
        strokeWeight: 5,
      });

      insetPath.setMap(google.map);
  };

  render() {
    return (
      <div style={{ backgroundColor: "transparent" }}>
        <div key={this.state.id}>
          {!this.props.simple && (
            <Accordion allowZeroExpanded={true} allowMultipleExpanded={true}>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>{this.state.name} - {this.state.active === "Yes" ? "Active" : "Inactive"}</AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <div className="row">
                    <div style={{ height: "60vh", width: "50%" }}>
                      <div style={{ marginLeft: 20, marginTop: 20 }}>
                        <div>
                          <strong>Fence ID: </strong>
                          {this.state.id}
                        </div>
                        <div>
                          <strong>Active: </strong>
                          {this.state.active}
                        </div>
                        <div>
                          <strong>Type: </strong>
                          {this.state.type}
                        </div>
                        <div>
                          <strong>Inset Distance: </strong>
                          {this.state.insetDistance} feet
                        </div>
                        <div>
                          <strong>Assigned Pets: </strong>
                          {this.state.pets}
                        </div>
                        <div>
                          <strong>Perimeter: </strong>
                          {this.state.perimeter} meters (
                          {this.state.perimeter * 3.2808} feet)
                        </div>
                        <div>
                          <strong>Area: </strong>
                          {this.state.areaSizeMeters === 0
                            ? "unknown"
                            : this.state.areaSizeMeters +
                              " meters squared"}{" "}
                          (
                          {this.state.areaSizeMeters === 0
                            ? "unknown"
                            : "~" +
                              Math.round(
                                this.state.areaSizeMeters * 10.76389379999997864
                              ) +
                              " square feet"}
                          )
                        </div>
                      </div>
                    </div>
                    <div style={{ height: "56vh", width: "50%" }}>
                      <div className="row" style={{ marginLeft: 5 }}>
                        <p style={{ fontSize: 20 }}>Map</p>
                        <MapTypeSwitch
                          checked={this.state.mapTypeChecked}
                          onChange={this.handleSwitch}
                        ></MapTypeSwitch>
                        <p style={{ fontSize: 20 }}>Satellite</p>
                      </div>
                      <GoogleMapReact
                        yesIWantToUseGoogleMapApiInternals
                        onGoogleApiLoaded={this.handleGoogleMapApi}
                        bootstrapURLKeys={{
                          key: mapKey,
                        }}
                        defaultCenter={{
                          lat: this.state.area[0].lat,
                          lng: this.state.area[0].lng,
                        }}
                        defaultZoom={18}
                        options={{
                          tilt: 0,
                          keyboardShortcuts: false,
                          mapTypeId: this.state.mapType,
                          scaleControl: true,
                        }}
                      ></GoogleMapReact>
                    </div>
                  </div>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          )}
        </div>
          {this.props.simple && (
            <div>
            <div style={styles.borderBottom}></div>
            <div className="col-sm-12 col-lg-12" style={{ height: "56vh" }}>
              <div className="row">
                <div className="col-sm-12 col-lg-4" style={{ fontSize: 14, textAlign: "center", paddingTop: 10 }}>
                  <label>
                    Active:
                    <div className="fa fa-fw">
                      <FontAwesomeIcon
                        icon={getIcon(this.state.active)}
                        className={getColor(this.state.active)}
                      />
                    </div>
                  </label>
                </div>
                <div className="col-sm-12 col-lg-4" style={styles.geofenceName}>
                  <label style={{ marginTop: 5 }}>{this.state.name}</label>
                </div>
                <div className="col-sm-12 col-lg-4" >
                  <div className="row" style={{ marginLeft: "auto", marginRight: 0 }}>
                    <p style={{ fontSize: 14, paddingTop: 10, display: "block", marginLeft: "auto", marginRight: 0 }}>Map</p>
                    <MapTypeSwitch
                      checked={this.state.mapTypeChecked}
                      onChange={this.handleSwitch}
                    ></MapTypeSwitch>
                    <p style={{ fontSize: 14, paddingTop: 10 }}>Satellite</p>
                  </div>
                </div>
              </div>
              <GoogleMapReact
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={this.handleGoogleMapApi}
                bootstrapURLKeys={{
                  key: mapKey,
                }}
                defaultCenter={{
                  lat: this.state.area[0].lat,
                  lng: this.state.area[0].lng,
                }}
                defaultZoom={18}
                options={{
                  tilt: 0,
                  keyboardShortcuts: false,
                  mapTypeId: this.state.mapType,
                  scaleControl: true,
                }}
              ></GoogleMapReact>
            </div>
            </div>
          )}
      </div>
    );
  }

  private handleSwitch() {
    if (this.state.mapType === "satellite") {
      this.setState({
        mapType: "roadmap",
        mapTypeChecked: !this.state.mapTypeChecked,
      });
    }
    if (this.state.mapType === "roadmap") {
      this.setState({
        mapType: "satellite",
        mapTypeChecked: !this.state.mapTypeChecked,
      });
    }
  }
}

export default Geofence;
