import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal } from "react-bootstrap";

import * as AppActions from "src/reducers/appReducer";
import * as UserActions from "src/reducers/userReducer";

import { Table, MarkdownEditor } from "src/components/structure";
import { capitalizeFirstLetter } from "src/utils/utilities";
import { styles } from "src/styles";
import { IColumns } from "src/utils/customTypes";

interface IReleaseListSupportProps {
  versions: any;
  loading: boolean;
  userActions: any;
  userState: any;
}

interface IReleaseListSupportState {
  loading: boolean;
  showModalForNotes: boolean;
  id: number;
  build: string;
  os: string;
  platform: string;
  releaseDate: string;
  status: string;
  updateRule: string;
  semver: string;
  publicNotes: string;
  privateNotes: string;
  columns: IColumns[];
  rows: any[];
}

class MobileReleaseListSupport extends Component<
  IReleaseListSupportProps,
  IReleaseListSupportState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      showModalForNotes: false,
      id: 0,
      build: "",
      os: "",
      platform: "",
      releaseDate: "",
      status: "",
      updateRule: "",
      semver: "",
      publicNotes: "",
      privateNotes: "",
      columns: [
        {
          label: "Semver",
          field: "semver",
          sort: "asc",
          width: 200,
        },
        {
          label: "OS",
          field: "os",
          sort: "asc",
          width: 150,
        },
        {
          label: "Platform",
          field: "platform",
          sort: "asc",
          width: 200,
        },
        {
          label: "Release Date",
          field: "releaseDate",
          sort: "asc",
          width: 110,
        },
        {
          label: "Status",
          field: "status",
          sort: "asc",
          width: 150,
        },
        {
          label: "Update Rule",
          field: "updateRule",
          sort: "asc",
          width: 150,
        },
        {
          label: "Notes",
          field: "notes",
          sort: "asc",
          width: 110,
        },
      ],
      rows: [],
    };

    this.updateField = this.updateField.bind(this);
    this.updateSelection = this.updateSelection.bind(this);
    this.formatVersions = this.formatVersions.bind(this);
  }

  updateField(e: any) {
    let ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  updateSelection = (input: any) => {
    let ns = this.state;
    ns[input.field] = input.value;
    this.setState(ns);
  };

  componentDidMount() {
    this.formatVersions();
  }

  formatVersions() {
    this.setState({ loading: true }, async () => {
      try {
        const appVersions = [];
        for (const verObj of this.props.versions.body.data) {
          const version = { ...verObj };

          version.releaseDate = version.releaseDate.substring(0, 10);
          version.status = capitalizeFirstLetter(version.status);
          version.platform = capitalizeFirstLetter(version.platform);
          version.updateRule = capitalizeFirstLetter(version.updateRule);
          version.notes = (
            <button
              className="btn btn-sm btn-pri"
              onClick={() => this.showNotesModal(version)}
            >
              View
            </button>
          );
          appVersions.push(version);
        }
        this.setState({
          rows: appVersions,
          loading: false,
        });
      } catch (err) {
        this.setState({ loading: false });
      }
    });
  }

  showNotesModal(input: any) {
    this.setState({
      showModalForNotes: true,
      id: input.id,
      os: input.os,
      semver: input.semver,
      publicNotes: input.publicNotes,
      privateNotes: input.privateNotes,
    });
  }

  render() {
    return (
      <div style={{ height: "100%" }}>
        <Table
          csvFileName="currentAppVersions"
          showDownload={true}
          columns={this.state.columns}
          rows={this.state.rows}
        />
        <div>
          <Modal
            dialogClassName="modal-75"
            show={this.state.showModalForNotes}
            onHide={() => {
              this.setState({ showModalForNotes: false });
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                Viewing notes for {this.state.os} Release {this.state.semver}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-group">
                <label style={{ marginLeft: 5, textDecoration: "underline" }}>
                  Public Notes:
                </label>
                <div style={styles.borderBottom}></div>
                <div style={{ marginLeft: 10 }}>
                  <MarkdownEditor
                    content={this.state.publicNotes}
                    showToggle={false}
                    showHelp={true}
                    mode="view"
                    rows={5}
                  />
                </div>
              </div>
              <div style={styles.borderBottom}></div>
              <div className="form-group">
                <label style={{ marginLeft: 5, textDecoration: "underline" }}>
                  Private Notes:
                </label>
                <div style={styles.borderBottom}></div>
                <div style={{ marginLeft: 10 }}>
                  <MarkdownEditor
                    content={this.state.privateNotes}
                    showToggle={false}
                    showHelp={true}
                    mode="view"
                    rows={5}
                  />
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>
        </div>
      </div>
    );
  }
}

const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(AppActions, dispatch),
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MobileReleaseListSupport);
