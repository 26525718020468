import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as UserActions from "src/reducers/userReducer";
import { IPet } from "src/api/pets";
import Card from "src/components/structure/Card";
import { PetsAPI } from "src/api";

interface IGeofenceSettingsProps {
  userId: number;
  location: any;
  userActions: any;
  userState: any;
  pet: IPet;
}

interface IGeofenceSettingsState {
  loading: boolean;
  petId: number;
  settings: any;
}

class GeofenceSettings extends Component<
  IGeofenceSettingsProps,
  IGeofenceSettingsState
> {
  constructor(props: IGeofenceSettingsProps) {
    super(props);

    this.state = {
      loading: false,
      petId: 0,
      settings: {},
    };

    this.fetchSettings = this.fetchSettings.bind(this);
  }

  componentDidMount() {
    this.fetchSettings();
  }

  fetchSettings() {
    this.setState({ loading: true }, async () => {
      try {
        const res = await PetsAPI.getGeofenceSettingsForPet(
          "wagz",
          this.props.userId,
          this.props.pet.id
        );

        this.setState({
          loading: false,
          settings: res.body.data,
        });
      } catch (err) {
        this.setState({ loading: false });
      }
    });
  }

  render() {
    return (
      <div>
          <Card>
            <pre>{JSON.stringify(this.state.settings, null, 2)}</pre>
          </Card>
      </div>
    );
  }
}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default connect<{}, {}, any>(
  mapStateToProps,
  mapDispatchToProps
)(GeofenceSettings);
