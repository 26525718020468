import React, { Component } from "react";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { IRMA, IRMAItem } from "src/api/billing";
import { Collapse } from "react-bootstrap";
import { RMAItemEditScreen } from "./RMAItemEdit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { familyToDisplay } from "src/utils/utilities";
import { Loading } from "src/components/structure";
import { BillingAPI, DeviceAPI } from "src/api";

const app = "wagz2";

interface IRMAItemProps {
  index: number;
  rma: IRMA;
  item: IRMAItem;
  codes: any[];
  productCodes: any[];
  categories: any[];

  handleExpanded: any;
  deleteRMAItem: any;
  saveRMAItem: any;
}

interface IRMAItemState {
  loading: boolean;
  refreshing: boolean;
  expanded: boolean;
  itemDirty: boolean;
}

export class RMAItemScreen extends Component<IRMAItemProps, IRMAItemState> {
  constructor(props: IRMAItemProps) {
    super(props);

    this.state = {
      loading: false,
      refreshing: false,
      expanded: false,
      itemDirty: false,
    };

    this.updateField = this.updateField.bind(this);
    this.toggleExpanded = this.toggleExpanded.bind(this);
    this.setup = this.setup.bind(this);
    this.getDeviceInformation = this.getDeviceInformation.bind(this);
    this.saveRMAItem = this.saveRMAItem.bind(this);
    this.deleteRMAItem = this.deleteRMAItem.bind(this);
    this.refresh = this.refresh.bind(this);
  }

  componentDidMount(){
    this.setup();
  }

  render() {
    if(this.state.loading){
      return <Loading />
    }
    
    return (
      <div className="item rma-item">
        <div className="row">
          {!this.state.expanded && (
            <>
              <div className="col-2">
                <p>{this.props.item.productId === "" ? "None" : this.props.item.productId}</p>
              </div>
              <div className="col-3">
                <p>{this.props.item.productDescription === "" ? "None" : this.props.item.productDescription}</p>
              </div>
              <div className="col-1">
                <p>{this.props.item.quantity}</p>
              </div>
              <div className="col-2">
                <p>{this.props.item.returnCodeDisplay === "" ? "None" : this.props.item.returnCodeDisplay}</p>
              </div>
              <div className="col-2">
                <p>{this.props.item.primaryCategoryDisplay === "" ? "None" : this.props.item.primaryCategoryDisplay}</p>
              </div>
            </>
          )}
          <div className={this.state.expanded ? "col-2 offset-10" : "col-2"} style={{fontSize: 18}}>
            <span onClick={this.toggleExpanded}>
              {this.state.expanded ? (
                <FontAwesomeIcon icon={faMinus} className="text-danger" />
              ) : (
                <FontAwesomeIcon icon={faPlus} className="text-primary" />
              )}
            </span>
          </div>
        </div>
        <Collapse in={this.state.expanded}>
          <div className="row row-margin-bottom" style={{padding: 5, border: "thin solid gray"}}>
            <div className="col-12">
            <RMAItemEditScreen
              mode="edit" 
              item={this.props.item}
              categories={this.props.categories}
              codes={this.props.codes} 
              productCodes={this.props.productCodes}
              isRefreshing={this.state.refreshing}
              handleRefreshRequest={this.refresh}
              handleSave={this.saveRMAItem}
              handleDelete={this.deleteRMAItem} />
              </div>
          </div>
        </Collapse>
      </div>
    )
  }

  private toggleExpanded(){
    this.setState({ expanded: !this.state.expanded }, () => {
      this.props.handleExpanded(this.props.index, this.state.expanded);
    });
  }

  private updateField(e: any) {
    let item: any = this.props.item;
    const field = e.target.id +"";
    const val = e.target.value;
    item[field] = val;
  }

  private setup() {
    this.setState({ loading: true }, async () => {
      await this.getDeviceInformation();
      this.setState({ loading: false });
    })
  }

  private async getDeviceInformation(){
    if(this.props.item.productId !== "" && this.props.item.productId.length >= 10){
      const productFamilyId = this.props.item.productFamilyId ? this.props.item.productFamilyId : this.props.item.productId.substring(4, 6);
      const productFamilyDisplay = familyToDisplay(productFamilyId);
      const item = this.props.item;
      item.productFamilyId = productFamilyId;
      item.productFamilyDisplay = productFamilyDisplay;
      try{
        const deviceResult = await DeviceAPI.getDeviceByProductId(app, this.props.item.productId);
        item.deviceStatus = deviceResult.body.data.status;
        item.deviceId = deviceResult.body.data.id;
        const billingResult = await BillingAPI.getBillingDevice(app, deviceResult.body.data.registeredBy, deviceResult.body.data.id);
        const billing = billingResult.body.data;
        item.billingStatus = billing.status;
        item.plan = billing.plan;
      }catch(err){
      }
    }
  }

  private saveRMAItem(itemData: IRMAItem){
    this.props.saveRMAItem(this.props.index, itemData);
  }

  private deleteRMAItem(){
    this.props.deleteRMAItem(this.props.index, this.props.item);
  }

  private async refresh(){
    this.setState({ refreshing: true }, async () => {
      await this.getDeviceInformation();
      this.setState({ refreshing: false });
    })
  }
}