import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Select from "react-select";
import { DeviceAPI } from "src/api";
import Table from "src/components/structure/Table";
import * as UserActions from "src/reducers/userReducer";
import * as Alert from "src/components/structure/Alert";

import Card from "src/components/structure/Card";
import { styles } from "src/styles";
import { IColumns } from "src/utils/customTypes";

interface IActionsTabProps {
  loading: boolean;
  deviceId: number;
  location: any;
  userActions: any;
  userState: any;
}

interface IActionsTabState {
  loading: boolean;
  action: string;
  actionChosen: boolean;
  knownActions: any[];
  userId: number;
  rows: any[];
}

class ActionsTab extends Component<IActionsTabProps, IActionsTabState> {
  constructor(props: IActionsTabProps) {
    super(props);

    this.state = {
      loading: false,
      action: "",
      actionChosen: false,
      knownActions: [],
      userId: 0,

      rows: [],
    };

    this.updateField = this.updateField.bind(this);
    this.getActions = this.getActions.bind(this);
    this.queueNewAction = this.queueNewAction.bind(this);
    this.deleteAllActions = this.deleteAllActions.bind(this);
  }

  updateField(e: any) {
    let ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  handleChange = (action: any) => {
    const selected = action.value;
    this.setState({ action: selected, actionChosen: true });
  };

  componentDidMount() {
    this.getActions();
  }

  sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  queueNewAction() {
    this.setState({ loading: true }, async () => {
      try {
        await DeviceAPI.queueActionForDevice(
          "wagz",
          this.state.userId,
          this.props.deviceId.toString(),
          { action: this.state.action }
        );
        await this.sleep(2000);
        this.getActions();
        this.setState({
          loading: false,
          action: "",
          actionChosen: false,
        });
      } catch (err) {
        return this.setState({ loading: false }, () => {
          Alert.error("We could not queue that action");
        });
      }
    });
  }

  getActions() {
    this.setState({ loading: true }, async () => {
      try {
        const result = await DeviceAPI.getDeviceById(
          "wagz",
          this.props.deviceId
        );
        this.setState({
          loading: false,
          userId: result.body.data.registeredBy,
        });
        const actionsList = [];
        const knownActionsList = [];
        try {
          const actions = await DeviceAPI.getActionsForDevice(
            "wagz",
            this.state.userId,
            this.props.deviceId.toString(),
            { start: "2019-01-01", end: "2030-01-01", count: 100000000 }
          );
          for (let result of actions.body.data) {
            actionsList.push({
              action: result.action,
              status: result.status,
              userId: result.userId,
              startTime: result.startTime,
            });
          }
          const knownActions = await DeviceAPI.getKnownActions();
          for (let result of knownActions.body.data) {
            knownActionsList.push({
              value: result.action,
              label: result.display,
            });
          }
          this.setState({
            loading: false,
            rows: actionsList,
            knownActions: knownActionsList
          });
        } catch (err) {
          this.setState({ loading: false });
        }
      } catch (err) {
        this.setState({ loading: false });
      }
    });
  }

  processData(columns: IColumns[], rows: any) {
    const data = {
      columns,
      rows,
    };
    return data;
  }

  render() {
    return (
      <div className="row">
        <div className="col-sm-12 col-lg-4" style={{ marginBottom: 15 }}>
          <div className="row">
            <div className="col-sm-12 col-lg-12">
              <Card
                title="Queue New Action For Device"
                loading={this.props.loading || this.state.loading}
              >
                <div className="center">
                  <p style={{ fontSize: 14, textAlign: "center" }}>
                    <strong>Select the Action you would like to queue:</strong>
                  </p>
                  <div style={{ textAlign: "center" }}>
                    <p style={{ fontSize: 16 }}>
                      {this.state.actionChosen && (
                        <span style={styles.actionWord}>{this.state.action}</span>
                      )}
                    </p>
                  </div>
                </div>
                <div className="form-group">
                  <Select
                    options={this.state.knownActions}
                    isDisabled={
                      this.props.userState.user.accessLevel === "read_only"
                    }
                    isMulti={false}
                    onChange={this.handleChange}
                    value={this.state.action}
                  />
                </div>
                <div className="form-group" style={{ marginTop: 20 }}>

                </div>
                <div className="form-group" style={{ marginTop: 20 }}>
                  {this.props.userState.user.accessLevel === "read_only" ? (
                    <button className="btn btn-block btn-primary" disabled={true}>
                      You do not have permission to do this
                    </button>
                  ) : (
                    <button
                      className="btn btn-block btn-primary"
                      onClick={this.queueNewAction}
                    >
                      Queue Action
                    </button>
                  )}
                </div>
              </Card>
            </div>
          </div>
          <div className="row" style={{marginTop: 20}}>
            <div className="col-sm-12 col-lg-12">
              <Card
                title="Delete All Actions for Device"
                loading={this.props.loading || this.state.loading}
              >
              <div className="form-group" style={{ marginTop: 20 }}>
                    <strong className="text-danger">Warning: </strong> This will delete ALL actions for this device, queue a <em>checkForActions</em> action to clear the cache, clear Thing Shadow, then update this screen. This may take several seconds, especially if there are a lot of actions. Only do this if you know what you are doing!
                </div>
                
                <div className="form-group" style={{ marginTop: 20 }}>
                  {this.props.userState.user.accessLevel === "read_only" ? (
                    <button className="btn btn-block btn-delete" disabled={true}>
                      You do not have permission to do this
                    </button>
                  ) : (
                    <button
                      className="btn btn-block btn-delete"
                      style={{color: "white"}}
                      onClick={this.deleteAllActions}
                    >
                      Delete All Actions
                    </button>
                  )}
                </div>
              </Card>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-lg-7">
          <Table
            title="Current Actions For Device"
            loading={this.props.loading || this.state.loading}
            showDownload={true}
            csvFileName={`actionsFor-deviceId-${this.props.deviceId}`}
            columns={actionColumns}
            rows={this.state.rows}
          />
        </div>
      </div>
    );
  }



  deleteAllActions() {
    this.setState({ loading: true }, async () => {
      try {
        await DeviceAPI.deleteActionsForDevice(
          "wagz",
          this.state.userId,
          this.props.deviceId,
          { action: this.state.action }
        );
        await DeviceAPI.queueActionForDevice("wagz", this.state.userId, this.props.deviceId.toString(), { action: "checkForActions" })
        await this.sleep(2000);
        this.getActions();
        this.setState({
          loading: false,
          action: "",
          actionChosen: false,
        });
      } catch (err) {
        return this.setState({ loading: false }, () => {
          Alert.error("We could not queue that action");
        });
      }
    });
  }

}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default connect<{}, {}, any>(
  mapStateToProps,
  mapDispatchToProps
)(ActionsTab);

const actionColumns = [
  {
    label: "Action",
    field: "action",
    sort: "asc",
    width: 100,
  },
  {
    label: "Status",
    field: "status",
    sort: "asc",
    width: 200,
  },
  {
    label: "User",
    field: "userId",
    sort: "asc",
    width: 200,
  },
  {
    label: "Time",
    field: "startTime",
    sort: "asc",
    width: 200,
  },
];