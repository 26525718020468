import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { ListGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPaw,
  faCloudSun,
  faClipboard,
  faVenusMars,
  faUser,
  faFileSignature,
  faWeight,
  faRulerVertical,
  faDog,
  faHandHoldingWater
} from "@fortawesome/free-solid-svg-icons";
import * as UserActions from "src/reducers/userReducer";
import Card from "src/components/structure/Card";
import { IPet } from "src/api/pets";

interface IInfoTabProps {
  userId: number;
  location: any;
  userActions: any;
  userState: any;
  pet: IPet;
}

interface IInfoTabState {
  loading: boolean;
  userId: any;
  breedNames: any[] | undefined;
}

class PetInfoTab extends Component<IInfoTabProps, IInfoTabState> {
  constructor(props: IInfoTabProps) {
    super(props);

    this.state = {
      loading: false,
      userId: "",
      breedNames: []
    };

    this.updateField = this.updateField.bind(this);
    this.formatGender = this.formatGender.bind(this);
  }

  updateField(e: any) {
    let ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  componentDidMount() {
    const breedList = [];
    if (this.props.pet.breeds) {
      for (const b of this.props.pet.breeds) {
        breedList.push(b.breed)
      };
  }
    this.setState({ userId:  (
      <Link to={`/support/users/${this.props.pet.userId}`}>
        User - {this.props.pet.userId}
      </Link>
    ), breedNames: breedList })
  }

  formatGender(gender: string) {
    switch (gender) {
      case "maleNotNeutered":
        return "Male";
      case "maleNeutered":
        return "Male";
      case "male":
        return "Male";
      case "femaleSpayed":
        return "Female";
      case "femaleNotSpayed":
        return "Female";
      case "female":
        return "Female";
      default:
        return "Unknown";
    }
  }

  processBreeds(breeds: any[]) {
    if (breeds !== undefined) {
      const breedList = breeds.join(", ")
      return breedList;
    } else {
      return <text>This pet has no assigned breeds</text>;
    };
  }

  render() {
    return (
      <div className="row">
        <div className="col-10 offset-1">
          <Card title="">
            <div className="row">
              <div className="col-6">
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    <FontAwesomeIcon icon={faFileSignature} /> -{" "}
                    {this.props.pet.name}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <FontAwesomeIcon icon={faPaw} /> - {this.props.pet.species}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <FontAwesomeIcon icon={faVenusMars} /> -{" "}
                    {this.formatGender(this.props.pet.gender)}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <FontAwesomeIcon icon={faClipboard} /> - Status:{" "}
                    {this.props.pet.status}
                  </ListGroup.Item>
                  <ListGroup.Item><FontAwesomeIcon icon={faDog} /> - {this.state.breedNames} </ListGroup.Item>
                </ListGroup>
              </div>
              <div className="col-6">
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    <FontAwesomeIcon icon={faCloudSun} /> -{" "}
                    {this.props.pet.lifestyle}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <FontAwesomeIcon icon={faHandHoldingWater} /> -{" "}
                    Daily water goal: {this.props.pet.dailyWaterGoal}
                  </ListGroup.Item>
                  <ListGroup.Item><FontAwesomeIcon icon={faWeight} /> - {this.props.pet.weight} {this.props.pet.weightUnits}</ListGroup.Item>
                  <ListGroup.Item><FontAwesomeIcon icon={faRulerVertical} /> - {this.props.pet.height} {this.props.pet.heightUnits}</ListGroup.Item>
                  <ListGroup.Item><FontAwesomeIcon icon={faUser} /> - Created By User: {this.state.userId}</ListGroup.Item>
                </ListGroup>
              </div>
            </div>
          </Card>
        </div>
      </div>
    );
  }
}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default connect<{}, {}, any>(
  mapStateToProps,
  mapDispatchToProps
)(PetInfoTab);
