import moment from "moment";
import { makeCall } from "./client";

export interface IOTPEnrollReturn {
  secret: string;
  image64: string;
}

export interface IProfile {
  badLoginAttemptsSinceLogin: number;
  created: string;
  email: string;
  firstName: string;
  id: number;
  lastLoggedIn: string;
  lastName: string;
  otpStatus: "none" | "authenticator" | "sms";
  roles: string[];
  smsNumber: string;
  status: "active" | "pending" | "deactivated" | "locked";
  updated: string;
  wagzUserId: number;
}

export const BlankProfile: IProfile = {
  badLoginAttemptsSinceLogin: 0,
  created: moment().format("YYYY-MM-DDTHH:mm:SSZ"),
  email: "",
  firstName: "",
  id: 1,
  lastLoggedIn: moment().format("YYYY-MM-DDTHH:mm:SSZ"),
  lastName: "",
  otpStatus: "none",
  roles: [],
  smsNumber: "",
  status: "active",
  updated: moment().format("YYYY-MM-DDTHH:mm:SSZ"),
  wagzUserId: 0,
}

export class ProfileAPI {

  public getProfile = (options: any = {}) => {
    const data = {
      ...options
    };
    return makeCall('GET', 'admin/me', data);
  }

  public startOTPEnrollment = (options: any = {}) => {
    const data = {
      ...options
    };
    return makeCall('POST', 'admin/me/otp/enroll', data);
  }

  public verifyOTP = (passcode: string, options: any = {}) => {
    const data = {
      ...options,
      passcode
    };
    return makeCall('POST', 'admin/me/otp/verify', data);
  }

  public removeOTP = (options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('delete', 'admin/me/otp', data);
  }
}