import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Card, Screen, Alert } from "src/components/structure";
import { MetricsAPI } from "src/api";
import { Modal } from "react-bootstrap";
import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";
import { green, red } from "@material-ui/core/colors";
import { success, error } from "src/components/structure/Alert";
import { defaultIcons, messageVariables } from "src/utils/items";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faBan } from "@fortawesome/free-solid-svg-icons";
import { styles } from "src/styles";

const MetricSwitch = withStyles({
  switchBase: {
    color: red[300],
    "&$checked": {
      color: green[500],
    },
    "&$checked + $track": {
      backgroundColor: green[400],
    },
  },
  checked: {},
  track: {},
})(Switch);

interface IManageEventsProps {
  loading: boolean;
  userState: any;
}

interface IManageEventsState {
  loading: boolean;
  newEvent: string;
  defaultMessage: string;
  defaultIcon: string;
  icons: string[];
  events: any;
  eventsList: string[];
  variables: string[];
  shouldNotify: boolean;
  selectedEvent: string;
  showModalForDelete: boolean;
  showModalForEdit: boolean;
  updatedDefaultMessage: string;
  updatedDomain: string;
  updatedIcon: string;
  updatedShouldNotify: boolean;
}
class ManageEvents extends Component<IManageEventsProps, IManageEventsState> {
  constructor(props: IManageEventsProps) {
    super(props);

    this.state = {
      loading: false,
      newEvent: "",
      defaultMessage: "",
      defaultIcon: "",
      icons: defaultIcons,
      events: [],
      eventsList: [],
      variables: messageVariables,
      shouldNotify: false,
      selectedEvent: "",
      showModalForDelete: false,
      showModalForEdit: false,
      updatedDefaultMessage: "",
      updatedDomain: "",
      updatedIcon: "",
      updatedShouldNotify: false,
    };

    this.updateField = this.updateField.bind(this);
    this.fetchKnownEvents = this.fetchKnownEvents.bind(this);
    this.createNewEvent = this.createNewEvent.bind(this);
    this.editEvent = this.editEvent.bind(this);
    this.setProperties = this.setProperties.bind(this);
    this.setModalProperties = this.setModalProperties.bind(this);
    this.handleSwitch = this.handleSwitch.bind(this);
    this.handleModalSwitch = this.handleModalSwitch.bind(this);
    this.showEditModal = this.showEditModal.bind(this);
    this.deleteSelectedEvent = this.deleteSelectedEvent.bind(this);
    this.clearCache = this.clearCache.bind(this);
  }

  updateField(e: any) {
    let ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  componentDidMount() {
    this.fetchKnownEvents();
  }

  fetchKnownEvents() {
    this.setState({ loading: true }, async () => {
      let events: any[] = [];
      const knownEvents: any = [];
      try {
        const res = await MetricsAPI.getKnownEvents();
        events = res.body.data;
        for (const r of events) {
          knownEvents.push(r.domain);
        }
        this.setState({ loading: false, events, eventsList: knownEvents });
      } catch (err) {
        this.setState({ loading: false });
      }
    });
  }

  private createNewEvent() {
    this.setState({ loading: true }, async () => {
      try {
        await MetricsAPI.saveNewKnownEvent({
          domain: this.state.newEvent,
          shouldNotify: this.state.shouldNotify,
          defaultMessage: this.state.defaultMessage,
          defaultIcon: this.state.defaultIcon,
        });
        success("Known Event created!");
        this.setState({ 
          loading: false,
          newEvent: "",
          defaultMessage: "",
          defaultIcon: "",
          icons: defaultIcons,
          events: [],
          eventsList: [],
          variables: messageVariables,
          shouldNotify: false,
          selectedEvent: "",
          showModalForDelete: false,
          showModalForEdit: false,
          updatedDefaultMessage: "",
          updatedDomain: "",
          updatedIcon: "",
          updatedShouldNotify: false
        }, () => {
          this.fetchKnownEvents();
        });
      } catch (err) {
        error(
          "Could not create that known event. Please contact an administrator."
        );
        this.setState({ loading: false });
        console.log(err);
      }
    });
  }

  showEditModal(input: any) {
    this.setState({ loading: true }, async () => {
      try {
        let res = await MetricsAPI.getKnownEvent(input);
        this.setState({
          updatedDefaultMessage: res.body.data.defaultMessage,
          updatedDomain: res.body.data.domain,
          updatedIcon: res.body.data.defaultIcon,
          updatedShouldNotify: res.body.data.shouldNotify,
          showModalForEdit: true,
          selectedEvent: input,
          loading: false,
        });
      } catch (err) {
        this.setState({ loading: false, showModalForEdit: false });
      }
    });
  }

  showDeleteModal(input: string) {
    this.setState({
      showModalForDelete: true,
      selectedEvent: input,
    });
  }

  editEvent() {
    this.setState({ loading: true }, async () => {
      try {
        await MetricsAPI.updateKnownEvent(this.state.selectedEvent, {
          domain: this.state.updatedDomain,
          shouldNotify: this.state.updatedShouldNotify,
          defaultMessage: this.state.updatedDefaultMessage,
          defaultIcon: this.state.updatedIcon,
        });
        return this.setState(
          {
            loading: false,
            showModalForEdit: false,
            newEvent: "",
            updatedDefaultMessage: "",
            updatedIcon: "",
            updatedDomain: "",
            updatedShouldNotify: false,
          },
          () => {
            Alert.success("Event edited successfully");
            this.fetchKnownEvents();
          }
        );
      } catch (err) {
        Alert.error("Could not save that edit");
        this.setState({
          loading: false,
          showModalForEdit: false,
        });
      }
    });
  }

  deleteSelectedEvent() {
    this.setState({ loading: true }, async () => {
      try {
        await MetricsAPI.deleteKnownEvent(this.state.selectedEvent);
        return this.setState(
          { loading: false, showModalForDelete: false },
          () => {
            Alert.success("Event deleted successfully");
            this.fetchKnownEvents();
          }
        );
      } catch (err) {
        this.setState({
          loading: false,
          showModalForDelete: false,
        });
      }
    });
  }

  private setProperties(e: string) {
    if (e === "{petName}" || e === "{genderPossessive}") {
      return this.setState({
        defaultMessage: this.state.defaultMessage + e,
      });
    }
    if (e === "{deviceName}") {
      return this.setState({
        defaultMessage: this.state.defaultMessage + e,
      });
    }
    if (e === "{geofenceName}") {
      return this.setState({
        defaultMessage: this.state.defaultMessage + e,
      });
    }
  }

  private setModalProperties(e: string) {
    if (e === "{petName}" || e === "{genderPossessive}") {
      return this.setState({
        updatedDefaultMessage: this.state.updatedDefaultMessage + e,
      });
    }
    if (e === "{deviceName}") {
      return this.setState({
        updatedDefaultMessage: this.state.updatedDefaultMessage + e,
      });
    }
    if (e === "{geofenceName}") {
      return this.setState({
        updatedDefaultMessage: this.state.updatedDefaultMessage + e,
      });
    }
  }

  private handleSwitch() {
    this.setState({ shouldNotify: !this.state.shouldNotify });
  }

  private handleModalSwitch() {
    this.setState({ updatedShouldNotify: !this.state.updatedShouldNotify });
  }

  private async clearCache(){
    try{
      await MetricsAPI.clearKnownEventCache();
      this.fetchKnownEvents()
    }catch(err){
      console.log(err);
    }
  }

  render() {
    return (
      <Screen id="manage_events" requiredRoles={["product"]}>
        <div className="row">
          <div className="col-4">
            <Card title="Info">
              <p>
                This is a page where members of the Product team can manage the
                events that we send out to users. These events will result in an
                event card (and perhaps also a push notification) on the users
                dashboard within the mobile platform they are using.
              </p>
              <p>
                <strong>***PLEASE READ***</strong> It is very important that
                when naming a new known event, the domain is in snake case
                (this_is_snake_case).
              </p>
              <div className="row">
                <div className="col-12">
                  <button className="btn btn-block btn-primary" onClick={this.clearCache}>Clear Cache</button>
                </div>
              </div>
            </Card>
          </div>
          <div className="col-4">
            <Card
              title="Manage Events"
              loading={this.props.loading || this.state.loading}
            >
              {this.state.eventsList.map((e: string) => {
                return (
                  <div>
                    <div className="row" style={{ marginBottom: 5 }} key={e}>
                      <div className="col-8">{e}</div>
                      <div className="row" style={{ paddingLeft: 35 }}>
                        <div>
                          <FontAwesomeIcon
                            icon={faEdit}
                            onClick={() => {
                              this.showEditModal(e);
                            }}
                            className="text-secondary"
                          />
                        </div>
                        <div style={{ paddingLeft: 5, marginLeft: 5 }}>
                          <FontAwesomeIcon
                            icon={faBan}
                            onClick={() => this.showDeleteModal(e)}
                            className="text-danger"
                          />
                        </div>
                      </div>
                    </div>
                    <div style={styles.divider} />
                  </div>
                );
              })}
            </Card>
          </div>
          <div className="col-4">
            <Card title="Create New Known Event">
              <div className="form-group">
                <label>New Known Event Name (domain)</label>
                <input
                  type="text"
                  className="form-control"
                  id="newEvent"
                  value={this.state.newEvent}
                  onChange={this.updateField}
                />
                <label style={{ marginTop: 10 }}>Default Message</label>
                <input
                  type="text"
                  className="form-control"
                  id="defaultMessage"
                  value={this.state.defaultMessage}
                  onChange={this.updateField}
                />
                <label style={{ marginTop: 10 }}>
                  Available Variables (click the variable you'd like to use to
                  add it to the Default Message)
                </label>
                {this.state.variables.map((e: string) => {
                  return (
                    <div style={{ marginTop: 5 }}>
                      <div key={e}>
                        <button
                          className=" btn-var"
                          onClick={() => {
                            this.setProperties(e);
                          }}
                        >
                          {e}
                        </button>
                      </div>
                    </div>
                  );
                })}
                <div className="form-group">
                  <label style={{ marginTop: 10 }}>Default Icon</label>
                  <select
                    className="form-control"
                    id="defaultIcon"
                    value={this.state.defaultIcon}
                    onChange={this.updateField}
                  >
                    <option key="none" id="">
                      Please select an icon
                    </option>
                    {this.state.icons.map((defaultIcon: string) => {
                      return (
                        <option key={defaultIcon} id={defaultIcon}>
                          {defaultIcon}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div>
                  <label style={{ marginTop: 10 }}>
                    Should the user be notified when this event occurs?
                  </label>
                  <div className="row" style={{ marginLeft: 10 }}>
                    <p style={{ paddingTop: 10, fontSize: 16 }}>No</p>
                    <MetricSwitch
                      checked={this.state.shouldNotify}
                      onChange={this.handleSwitch}
                    ></MetricSwitch>
                    <p style={{ paddingTop: 10, fontSize: 16 }}>Yes</p>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <button
                  className="btn btn-block btn-success"
                  onClick={this.createNewEvent}
                >
                  Create New Known Event
                </button>
              </div>
            </Card>
          </div>
          <div>
            <Modal
              dialogClassName="modal-75"
              show={this.state.showModalForEdit}
              onHide={() => {
                this.setState({ showModalForEdit: false });
              }}
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  Update Info for {this.state.selectedEvent}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="center">
                  <p style={{ fontSize: 14, textAlign: "center" }}>
                    <strong>
                      Update the fields you wish to change, then hit Submit.
                    </strong>
                  </p>
                  <br />
                </div>
                <div title="Update Known Event">
                  <div className="form-group">
                    <label>Known Event Name (domain)</label>
                    <input
                      type="text"
                      className="form-control"
                      id="updatedDomain"
                      value={this.state.updatedDomain}
                      onChange={this.updateField}
                    />
                    <label style={{ marginTop: 10 }}>Default Message</label>
                    <input
                      type="text"
                      className="form-control"
                      id="updatedDefaultMessage"
                      value={this.state.updatedDefaultMessage}
                      onChange={this.updateField}
                    />
                    <label style={{ marginTop: 10 }}>
                      Available Variables (click the variable you'd like to use
                      to add it to the Default Message)
                    </label>
                    {this.state.variables.map((e: string) => {
                      return (
                        <div style={{ marginTop: 5 }}>
                          <div key={e}>
                            <button
                              className=" btn-var"
                              onClick={() => {
                                this.setModalProperties(e);
                              }}
                            >
                              {e}
                            </button>
                          </div>
                        </div>
                      );
                    })}
                    <div className="form-group">
                      <label style={{ marginTop: 10 }}>Default Icon</label>
                      <select
                        className="form-control"
                        id="updatedIcon"
                        value={this.state.updatedIcon}
                        onChange={this.updateField}
                      >
                        <option key="none" id="">
                          Please select an icon
                        </option>
                        {this.state.icons.map((defaultIcon: string) => {
                          return (
                            <option key={defaultIcon} id={defaultIcon}>
                              {defaultIcon}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div>
                      <label style={{ marginTop: 10 }}>
                        Should the user be notified when this event occurs?
                      </label>
                      <div className="row" style={{ marginLeft: 10 }}>
                        <p style={{ paddingTop: 10, fontSize: 16 }}>No</p>
                        <MetricSwitch
                          checked={this.state.updatedShouldNotify}
                          onChange={this.handleModalSwitch}
                        ></MetricSwitch>
                        <p style={{ paddingTop: 10, fontSize: 16 }}>Yes</p>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <button
                      className="btn btn-block btn-success"
                      onClick={this.editEvent}
                    >
                      Update This Event
                    </button>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer></Modal.Footer>
            </Modal>
          </div>
          <div>
            <Modal
              show={this.state.showModalForDelete}
              onHide={() => {
                this.setState({ showModalForDelete: false });
              }}
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  Deleting Known Action "{this.state.selectedEvent}"...
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                  Are you sure you want to permanently delete{" "}
                  {this.state.selectedEvent}?
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  className="btn btn-block btn-submit"
                  style={styles.button}
                  onClick={this.deleteSelectedEvent}
                >
                  Delete Known Action
                </button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </Screen>
    );
  }
}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps() {
  return {};
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ManageEvents) as any
);
