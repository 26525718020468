import { makeCall } from "./client";


export interface ITicket {
  created: string;
  critical: string;
  department: string;
  device: number;
  id: number;
  notes: string;
  reportedBy: number;
  resolvedBy: number;
  status: string;
  title: string;
  user: number;
  userOS: string;
}

export const BlankTicket: ITicket = {
  created: "",
  critical: "",
  department: "",
  device: 0,
  id: 0,
  notes: "",
  reportedBy: 0,
  resolvedBy: 0,
  status: "",
  title: "",
  user: 0,
  userOS: "",
};

export class SupportAPI {
 
  /**
   * Gets all the devices on the platform in summary format
   * @param app 
   * @param options 
   */
  public createTicket = (options: any = {}) => {
      const data = {
      ...options
      };
      return makeCall('POST', `admin/support/tickets`, data);
  }
 
  /**
   * Gets all the devices on the platform in summary format
   * @param app 
   * @param options 
   */
  public getAllTickets = (options: any = {}) => {
    const data = {
      ...options
    };
    return makeCall('GET', `admin/support/tickets`, data);
  }
  
  /**
   * Updates a specified App Version
   * @param ticketID 
   * @param options 
   */
  public getTicketByID = (ticketID: number, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('GET', `admin/support/tickets/${ticketID}`, data);
  }
  
  /**
   * Updates a specified ticket
   * @param ticketID 
   * @param options 
   */
  public updateTicket = (ticketID: number, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('PATCH', `admin/support/tickets/${ticketID}`, data);
  }
}