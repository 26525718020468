import React, { Component } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';

// note that we take the receives as well, since we need to trigger re-renders on their changes in the higher
// component
interface IUploadEmailPreferencesRowProps {
  user: any;
  receiveAccountUpdates: "yes" | "no";
  receiveMarketing: "yes" | "no";
  receiveOther: "yes" | "no";
  receiveTips: "yes" | "no";
  receiveTransactional: "yes" | "no";
  onFieldChanged: (userId: string, field: string, value: string) => void;
  onSave: (user: any) => void;
}

interface IUploadEmailPreferencesRowState {
  loading: boolean;
  // receiveAccountUpdates: "yes" | "no";
  // receiveMarketing: "yes" | "no";
  // receiveOther: "yes" | "no";
  // receiveTips: "yes" | "no";
  // receiveTransactional: "yes" | "no";
}

export class UploadEmailPreferencesRow extends Component<IUploadEmailPreferencesRowProps, IUploadEmailPreferencesRowState> {
  constructor(props: IUploadEmailPreferencesRowProps) {
    super(props);

    this.state = {
      loading: false,
      // receiveAccountUpdates: "yes", 
      // receiveMarketing: "yes",
      // receiveOther: "yes",
      // receiveTips: "yes", 
      // receiveTransactional: "yes",
    };

    this.updateField = this.updateField.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  componentDidMount(){
    this.setState({
      ...this.props.user,
      receiveAccountUpdates: this.props.receiveAccountUpdates,
      receiveMarketing: this.props.receiveMarketing,
      receiveOther: this.props.receiveOther,
      receiveTips: this.props.receiveTips,
      receiveTransactional: this.props.receiveTransactional
    })
  }

  render() {
    return (
      <div className="row" style={{marginBottom: 5}}>
        <div className="col-3">{this.props.user.firstName} {this.props.user.lastName} {this.props.user.email}</div>
        <div className="col-2">
          <select className="form-control" id="receiveMarketing" value={this.props.receiveMarketing} onChange={this.updateField}>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </div>
        <div className="col-2">
          <select className="form-control" id="receiveTips" value={this.props.receiveTips} onChange={this.updateField}>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </div>
        <div className="col-2">
          <select className="form-control" id="receiveAccountUpdates" value={this.props.receiveAccountUpdates} onChange={this.updateField}>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </div>
        <div className="col-2">
          <select className="form-control" id="receiveOther" value={this.props.receiveOther} onChange={this.updateField}>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </div>
        <div className="col-1" onClick={this.handleSave}>
          <FontAwesomeIcon icon={faSave} style={{height: '100%'}} className="text-primary" />
        </div>
      </div>
    )
  }

  updateField(e: any) {
    let ns: any = this.state;
    const field = e.target.id;
    const val = e.target.value;
    ns[e.target.id] = e.target.value;
    this.setState(ns, () => {
      this.props.onFieldChanged(this.props.user.id, field, val);
    });
  }

  private handleSave(){
    this.props.onSave(this.props.user.id);
  }

}
