import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Screen from "src/components/structure/Screen";
import Select from "react-select";
import * as Alert from "src/components/structure/Alert";
import * as UserActions from "src/reducers/userReducer";
import Table from "src/components/structure/Table";
import { BillingAPI } from "src/api";
import CreateCoupon from "src/components/screens/Product/CreateCoupon";
import GenerateCoupons from "src/components/screens/Product/GenerateCoupons";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faEdit } from "@fortawesome/free-solid-svg-icons";
import { couponStatus } from "src/utils/items";
import { styles } from "src/styles";

interface ICouponsScreenScreenProps {
  location: any;
  userActions: any;
  userState: any;
}

interface ICouponsScreenScreenState {
  loading: boolean;
  rows: any[];
  showModalForEdit: boolean;
  showModalForDelete: boolean;
  couponCode: string;
  couponName: string;
  status: string;
  userId: string;
  deviceId: string;
}

class CouponsScreen extends Component<
  ICouponsScreenScreenProps,
  ICouponsScreenScreenState
> {
  constructor(props: ICouponsScreenScreenProps) {
    super(props);

    this.state = {
      loading: false,
      rows: [],
      showModalForEdit: false,
      showModalForDelete: false,

      couponCode: "",
      couponName: "",
      status: "",
      userId: "",
      deviceId: "",
    };

    this.updateSelection = this.updateSelection.bind(this);
    this.deleteSelectedCode = this.deleteSelectedCode.bind(this);
    this.editCoupon = this.editCoupon.bind(this);
    this.fetch = this.fetch.bind(this);
    this.updateField = this.updateField.bind(this);
    this.updateSelection = this.updateSelection.bind(this);
  }

  private columns = [
    {
      label: "Coupon Code",
      field: "couponCode",
      sort: "asc",
      width: 100,
    },
    {
      label: "Coupon Name",
      field: "couponName",
      sort: "asc",
      width: 100,
    },
    {
      label: "Status",
      field: "status",
      sort: "asc",
      width: 100,
    },
    {
      label: "Coupon Value",
      field: "couponValue",
      sort: "asc",
      width: 100,
    },
    {
      label: "Single-use or Multiple-use code?",
      field: "couponUseType",
      sort: "asc",
      width: 100,
    },
    {
      label: "Eligible Plans",
      field: "eligiblePlans",
      sort: "asc",
    },
    {
      label: "Used?",
      field: "used",
      sort: "asc",
    },
    {
      label: "Coupon Duration",
      field: "couponDuration",
      sort: "asc",
      width: 100,
    },
    {
      label: "Actions",
      field: "actions",
      sort: "asc",
      width: 100,
    },
  ];

  componentDidMount() {
    this.fetch();
  }

  render() {
    return (
      <Screen id="couponsScreen" requiredRoles={[]}>
        <div> 
          {this.props.userState.user.accessLevel !== "read_only" && (<div className="row" style={{ marginBottom: 20 }}>
            <div className="col-6">
              <CreateCoupon
                loading={this.state.loading}
                onGeneration={this.fetch}
              />
            </div>
            <div className="col-6">
              <GenerateCoupons
                loading={this.state.loading}
                onGeneration={this.fetch}
              />
            </div>
          </div>)}
          <div className="col-12">
            <Table
              loading={this.state.loading}
              title="List of Coupons"
              csvFileName="couponsList"
              showDownload={true}
              columns={this.columns}
              rows={this.state.rows}
            />
          </div>
          <div>
            <Modal
              dialogClassName="modal-75"
              show={this.state.showModalForEdit}
              onHide={() => {
                this.setState({ showModalForEdit: false });
              }}
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  Update Info for Coupon Code: {this.state.couponCode}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="center">
                  <p style={{ fontSize: 14, textAlign: "center" }}>
                    <strong>
                      Update the fields you wish to change, then hit Submit.
                    </strong>
                  </p>
                  <br />
                </div>
                <div className="form-group">
                  <label style={{ fontSize: 12, marginLeft: 5 }}>
                    Coupon Name
                  </label>
                  <input
                    id="couponName"
                    type="text"
                    className="form-control"
                    placeholder="Coupon Name"
                    autoComplete="new-password"
                    onChange={this.updateField}
                    value={this.state.couponName}
                  />
                </div>
                <div className="form-group">
                  <label style={{ fontSize: 12, marginLeft: 5 }}>Status</label>
                  <Select
                    options={couponStatus}
                    onChange={this.updateSelection}
                    hideSelectedOptions={true}
                  />
                </div>
                <div className="form-group">
                  <label style={{ fontSize: 12, marginLeft: 5 }}>
                    Assign to User ID:
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="userId"
                    value={this.state.userId}
                    onChange={this.updateField}
                  />
                </div>
                <label style={{ fontSize: 12, marginLeft: 5 }}>
                  Assign to Device ID:
                </label>
                <div className="form-group">
                  <input
                    type="number"
                    className="form-control"
                    id="deviceId"
                    value={this.state.deviceId}
                    onChange={this.updateField}
                  />
                </div>
                <div className="form-group" style={{ marginTop: 20 }}>
                  <button
                    className="btn btn-block btn-submit"
                    onClick={this.editCoupon}
                  >
                    Update Coupon
                  </button>
                </div>
              </Modal.Body>
              <Modal.Footer></Modal.Footer>
            </Modal>
            <Modal
              show={this.state.showModalForDelete}
              onHide={() => {
                this.setState({ showModalForDelete: false });
              }}
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  Deleting Coupon Code: {this.state.couponCode}...
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                  Are you sure you want to permanently delete Coupon Code:{" "}
                  {this.state.couponCode}?
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  className="btn btn-block btn-submit"
                  style={styles.button}
                  onClick={this.deleteSelectedCode}
                >
                  Delete Coupon
                </button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </Screen>
    );
  }

  updateField(e: any) {
    let ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  updateSelection = (input: any) => {
    let ns = this.state;
    ns[input.field] = input.value;
    this.setState(ns);
  };

  processValue(value: number, type: string) {
    if (type === "flat") {
      var flat = value / 100;
      return `$${flat.toFixed(2)}`;
    }
    return `${value}%`;
  }

  processUsed(dateUsed: string) {
    if (dateUsed.indexOf("1970") > -1) {
      return "No";
    }
    return `YES - (${dateUsed})`;
  }

  processArray(p: any) {
    const plans: any = [];
    if (p === null) {
      return "None";
    }
    for (let id of p) {
      plans.push(id.planId);
    }
    return plans.join(", ");
  }

  private showEditModal(input: any) {
    this.setState({
      showModalForEdit: true,
      couponCode: input.couponCode,
      couponName: input.couponName,
      status: input.status,
      userId: input.userId,
      deviceId: input.deviceId,
    });
  }

  private showDeleteModal(input: any) {
    this.setState({
      showModalForDelete: true,
      couponCode: input.couponCode,
    });
  }

  private editCoupon() {
    this.setState({ loading: true }, async () => {
      try {
        await BillingAPI.updateCoupon("wagz2", this.state.couponCode, {
          couponCode: this.state.couponCode,
          status: this.state.status,
          userId: parseInt(this.state.userId),
          deviceId: parseInt(this.state.deviceId),
          couponName: this.state.couponName,
        });
        return this.setState(
          { loading: false, showModalForEdit: false },
          () => {
            Alert.success("Coupon edited successfully");
            this.fetch();
          }
        );
      } catch (err) {
        Alert.error("Could not update. Please try again later");
        this.setState({
          loading: false,
          showModalForEdit: false,
        });
      }
    });
  }

  private deleteSelectedCode() {
    this.setState({ loading: true }, async () => {
      try {
        await BillingAPI.deleteCoupon("wagz2", this.state.couponCode);
        return this.setState(
          { loading: false, showModalForDelete: false },
          () => {
            Alert.success("Coupon deleted successfully");
            this.fetch();
          }
        );
      } catch (err) {
        Alert.error("Had some trouble deleting that one");
        this.setState({
          loading: false,
          showModalForDelete: false,
        });
      }
    });
  }

  private fetch() {
    this.setState({ loading: true }, async () => {
      try {
        const result = await BillingAPI.getAllCoupons("wagz2");
        const allCoupons = this.processAllCoupons(result.body.data);
        this.setState({ loading: false, rows: allCoupons });
      } catch (err) {
        Alert.error("Could not fetch any coupon info");
        this.setState({ loading: false });
      }
    });
  }

  private processAllCoupons(coupons: any[]): any[] {
    const allCoupons: any = [];
    for (const c of coupons) {
      c.couponValue = this.processValue(c.couponValue, c.couponType);
      c.used = this.processUsed(c.dateUsed);
      c.eligiblePlans = this.processArray(c.eligiblePlans);
      c.actions = (
        <div className="row" style={{ justifyContent: "center", marginTop: 4 }}>
          {this.props.userState.user.accessLevel !== "read_only" && (
            <div>
              <div style={{ paddingRight: 5 }}>
                <FontAwesomeIcon
                  icon={faEdit}
                  onClick={() => this.showEditModal(c)}
                  className="text-secondary"
                />
              </div>
              <div style={{ paddingLeft: 5 }}>
                <FontAwesomeIcon
                  icon={faBan}
                  onClick={() => this.showDeleteModal(c)}
                  className="text-danger"
                />
              </div>
            </div>
          )}
        </div>
      );
      allCoupons.push(c);
    }
    return allCoupons;
  }
}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CouponsScreen) as any
);
