import { makeCall } from "./client";

export class OTAAPI {

  /**
   * Uploads a new OTA binary WHICH WILL BE DOWNLOADABLE BY CUSTOMERS
   * @param deviceType 
   * @param productFamily 
   * @param channel 
   * @param fileData 
   * @returns 
   */
  public uploadOTABinary(deviceType: string, productFamily: string, channel: string, fileData: any): Promise<any> {
    return makeCall("post", `admin/backend/ota/${deviceType}-${productFamily}/${channel}/upload`, fileData, {asMultipartFile: true});
  }

  /**
   * Copies OTA files for CUSTOMERS from one bucket to another
   * @param deviceType 
   * @param productFamily 
   * @param sourceChannel 
   * @param targetChannel 
   * @returns 
   */
  public copyOTABinaries(deviceType: string, productFamily: string, sourceChannel: string, targetChannel: string): Promise<any> {
    return makeCall("post", `admin/backend/ota/${deviceType}-${productFamily}/${sourceChannel}/copy/${targetChannel}`);
  }

  /**
   * Gets a list of downloadable OTA files
   * @param queryParams 
   * @param options 
   * @returns 
   */
  public getDownloadableOTAFiles(queryParams: any = {}, options: any = {}): Promise<any> {
    return makeCall("get", `admin/firmware/ota`, queryParams, options);
  }

  /**
   * Downloads the file as a blob and saves it for the user
   * @param deviceType 
   * @param family 
   * @param channel 
   * @param file 
   * @param queryParams 
   * @param options 
   * @returns 
   */
  public downloadOTAFile(deviceType: string, family: string, channel: string, file: string, queryParams: any = {}, options: any = {}): Promise<any> {
    return makeCall("get", `admin/firmware/ota/${deviceType}/${family}/${channel}/${file}`, queryParams, options);
  }

  /**
   * Clears the cache for a type/family combination
   * @param deviceType 
   * @param family 
   * @param queryParams 
   * @param options 
   * @returns 
   */
  public clearOTACacheForFamily(deviceType: string, family: string, queryParams: any = {}, options: any = {}): Promise<any> {
    return makeCall("delete", `admin/backend/ota/${deviceType}-${family}/cache`, queryParams, options);
  }

  /**
   * Clears the cache for screens that require downloads and larger lists
   * @param queryParams 
   * @param options 
   * @returns 
   */
  public clearOTACacheForAuthenticated(queryParams: any = {}, options: any = {}): Promise<any> {
    return makeCall("delete", `admin/firmware/ota/cache`, queryParams, options);
  }

}