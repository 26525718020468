import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { AppsAPI } from "src/api";
import List from "./MobileReleasesSupport";
import { Loading, Screen } from "src/components/structure";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Card } from "src/components/structure";

interface IAppVersionsProps {
  userState: any;
}

interface IAppVersionsState {
  loading: boolean;
  iosVersions: any[];
  androidVersions: any[];
  fwosVersions: any[];
}
class AppVersions extends Component<
  IAppVersionsProps,
  IAppVersionsState
> {
  constructor(props: IAppVersionsProps) {
    super(props);

    this.state = {
      loading: false,
      iosVersions: [],
      androidVersions: [],
      fwosVersions: [],
    };

    this.updateField = this.updateField.bind(this);
    this.getVersions = this.getVersions.bind(this);
  }

  componentDidMount() {
    this.getVersions();
  }

  private getVersions() {
    this.setState({ loading: true }, async () => {
      try {
        const iosVersions = await AppsAPI.getAppVersionForOS("wagz", "ios");
        const androidVersions = await AppsAPI.getAppVersionForOS("wagz", "android");
        const fwosVersions = await AppsAPI.getAppVersionForOS("wagz", "fwos");
        this.setState({ loading: false, iosVersions, androidVersions, fwosVersions });
      } catch (err) {
        this.setState({ loading: false });
      }
    });
  }
 
  updateField(e: any) {
    let ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  render() {
    return (
      <Screen id="app_versions" requiredRoles={["support"]}>
        <div className="row">
          <div className="col-sm-12 col-lg-12">
            <Card title="Releases">
              <Tabs>
                <TabList>
                  <Tab>iOS</Tab>
                  <Tab>Android</Tab>
                  <Tab>FWOS</Tab>
                </TabList>
                <TabPanel>
                  {this.state.loading ? (
                    <Loading />
                  ) : (
                    <List
                      versions={this.state.iosVersions}
                      loading={this.state.loading}
                    />
                  )}
                </TabPanel>
                <TabPanel>
                  {this.state.loading ? (
                    <Loading />
                  ) : (
                    <List
                      versions={this.state.androidVersions}
                      loading={this.state.loading}
                    />
                  )}
                </TabPanel>
                <TabPanel>
                  {this.state.loading ? (
                    <Loading />
                  ) : (
                    <List
                      versions={this.state.fwosVersions}
                      loading={this.state.loading}
                    />
                  )}
                </TabPanel>
              </Tabs>
            </Card>
          </div>
        </div>
      </Screen>
    );
  }
}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps() {
  return {};
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AppVersions) as any
);
