import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { DatePicker, Screen, Table } from "src/components/structure";
import { Modal } from "react-bootstrap";
import GoogleMapReact from "google-map-react";
import { faPaw } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Datetime from "react-datetime";

import * as UserActions from "src/reducers/userReducer";
import moment from "moment";
import { GPSTestsAPI } from "src/api";
import { IGPSTest, BlankGPSTest } from "src/api/gpsTests";
import { error } from "src/components/structure/Alert";
import { capitalizeFirstLetter } from "src/utils/utilities";


interface IGPSTestsScreenProps {
  location: any;
  userActions: any;
  userState: any;
  mode?: string;
  deviceId?: number;
  userId?: number;
}

interface IGPSTestsScreenState {
  loading: boolean;
  start: moment.Moment;
  end: moment.Moment;
  tests: IGPSTest[];
  csvRows: any[];

  newStartDate: moment.Moment;
  newIsCompleted: string;
  newEndDate: moment.Moment;
  newProductId: string;
  newDeviceId: string;
  newLookupBy: string;
  newTargetLat: string;
  newTargetLng: string;
  newObserverNotes: string;

  editStartDate: moment.Moment;
  editEndDate: moment.Moment;
  editStatus: "complete" | "processing" | "pending";
  editObserverNotes: string;

  showNewModal: boolean;
  showViewModal: boolean;
  showDeleteModal: boolean;
  showEditModal: boolean;
  selectedTest: IGPSTest;
}

const cols = [
  {
    label: "ID",
    field: "id",
  },
  {
    label: "Device",
    field: "deviceId",
  },
  {
    label: "User",
    field: "userId",
  },
  {
    label: "Start",
    field: "startFormatted",
  },
  {
    label: "Status",
    field: "status",
  },
  {
    label: "Locations",
    field: "locationCount",
  },
  {
    label: "Target",
    field: "target",
  },
  {
    label: "Avg Distance",
    field: "averageDistance",
  },
  {
    label: "Med Distance",
    field: "medianDistance",
  },
  {
    label: "SDV",
    field: "standardDeviation",
  },
  {
    label: "View",
    field: "view",
  },
];

const selectedLocationCols = [
  {
    label: "Lat",
    field: "lat",
  },
  {
    label: "Lng",
    field: "lng",
  },
  {
    label: "Reported Accuracy",
    field: "reportedAccuracy",
  },
  {
    label: "Distance",
    field: "distanceFromOrigination",
  },
];

const Marker = (props: any) => {
  return (
    <div style={{
      width: "18px",
      height: "18px",
      backgroundColor: props.color,
      borderRadius: "50%",
      textAlign: "center",
      paddingTop: 2
    }}>
      <FontAwesomeIcon icon={faPaw} size={"1x"} color={"#f4f5f7"} />
    </div>
  );
};

const mapKey = process.env.REACT_APP_GOOGLE_MAP_KEY || "";

class GPSTestsScreen extends Component<
  IGPSTestsScreenProps,
  IGPSTestsScreenState
> {
  constructor(props: IGPSTestsScreenProps) {
    super(props);

    this.state = {
      loading: false,
      start: moment().subtract(3, "months"),
      end: moment(),
      tests: [],
      csvRows: [],

      newStartDate: moment(),
      newEndDate: moment(),
      newIsCompleted: "no",
      newProductId: "",
      newLookupBy: "productId",
      newDeviceId: "",
      newTargetLat: "0.0",
      newTargetLng: "0.0",
      newObserverNotes: "",

      editStartDate: moment(),
      editEndDate: moment(),
      editStatus: "pending",
      editObserverNotes: "",

      showNewModal: false,
      showViewModal: false,
      showEditModal: false,
      showDeleteModal: false,
      selectedTest: BlankGPSTest,
    };

    this.updateField = this.updateField.bind(this);
    this.onEndFilterChanged = this.onEndFilterChanged.bind(this);
    this.onStartFilterChanged = this.onStartFilterChanged.bind(this);
    this.onNewStartChanged = this.onNewStartChanged.bind(this);
    this.onNewEndChanged = this.onNewEndChanged.bind(this);

    this.onEditStartChanged = this.onEditStartChanged.bind(this);
    this.onEditEndChanged = this.onEditEndChanged.bind(this);

    this.toggleNewModal = this.toggleNewModal.bind(this);
    this.toggleViewModal = this.toggleViewModal.bind(this);
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.toggleEditModal = this.toggleEditModal.bind(this);
    this.fetch = this.fetch.bind(this);
    this.createNewTest = this.createNewTest.bind(this);
    this.processRow = this.processRow.bind(this);
    this.viewTest = this.viewTest.bind(this);
    this.deleteTest = this.deleteTest.bind(this);
    this.editTest = this.editTest.bind(this);
  }

  componentDidMount() {
    this.fetch();
    navigator.geolocation.getCurrentPosition((position) => {
      this.setState({
        newTargetLat: position.coords.latitude + "",
        newTargetLng: position.coords.longitude + "",
      })
    })
  }


  render() {
    return (
      <Screen shouldAuthCheck={true} requiredRoles={["qa", "reporting", "product"]} loading={this.state.loading}>
        <Table
          loading={this.state.loading}
          title="GPS Tests"
          columns={cols}
          rows={this.state.tests}
          csvData={this.state.csvRows}
          showDownload={true}
          csvFileName={`gpstests-${this.state.start.format('YYYY-MM-DD')}.csv`}
          preElement={(
            <div className="row">
              {(!this.props.mode || this.props.mode === "all") && (
                <>
                  <div className="col-3">
                    <DatePicker date={this.state.start} onDateSaved={this.onStartFilterChanged} />
                  </div>
                  <div className="col-3">
                    <DatePicker date={this.state.end} onDateSaved={this.onEndFilterChanged} />
                  </div>
                  <div className="col-3">
                    <button className="btn btn-block btn-primary" onClick={this.fetch}>Refresh</button>
                  </div>
                </>
              )}
              <div className="col-3">
                <button className="btn btn-block btn-success" onClick={this.toggleNewModal}>New Test</button>
              </div>
            </div>
          )}
        >
        </Table>

        <Modal
          show={this.state.showNewModal}
          onHide={this.toggleNewModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Enter Details About New Test
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label>Start</label>
              <Datetime value={this.state.newStartDate} inputProps={{ className: "form-control"}} onChange={this.onNewStartChanged} />
            </div>

            <div className="form-group">
              <label>Is This Test Complete?</label>
              <select className="form-control" id="newIsCompleted" value={this.state.newIsCompleted} onChange={this.updateField}>
                <option value="no">No</option>
                <option value="yes">Yes</option>
              </select>
            </div>

            {this.state.newIsCompleted === "yes" && (            
              <div className="form-group">
                <label>End</label>
                <Datetime value={this.state.newEndDate} inputProps={{ className: "form-control"}} onChange={this.onNewEndChanged} />
              </div>
            )}

            <div className="form-group">
              <div className="row">
                <div className="col-6">
                  <label>Target Lat</label>
                  <input type="text" id="newTargetLat" className="form-control" value={this.state.newTargetLat} onChange={this.updateField} />
                </div>
                <div className="col-6">
                  <label>Target Long</label>
                  <input type="text" id="newTargetLng" className="form-control" value={this.state.newTargetLng} onChange={this.updateField} />
                </div>
              </div>
            </div>

            <div className="form-group">
              <label>Lookup Device By</label>
              <select className="form-control" id="newLookupBy" value={this.state.newLookupBy} onChange={this.updateField}>
                <option value="productId">Serial / Product Id</option>
                <option value="deviceId">Device DB Identifier</option>
              </select>
            </div>

            {this.state.newLookupBy === "productId" ?
              (
                <div className="form-group">
                  <label>Serial / Product Id</label>
                  <input type="text" className="form-control" id="newProductId" value={this.state.newProductId} onChange={this.updateField} />
                </div>
              ) :
              (
                <div className="form-group">
                  <label>Device ID</label>
                  <input type="text" className="form-control" id="newDeviceId" value={this.state.newDeviceId} onChange={this.updateField} />
                </div>
              )
            }

            <div className="form-group">
              <label>Notes</label>
              <textarea className="form-control" id="newObserverNotes" value={this.state.newObserverNotes} onChange={this.updateField} />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-primary btn-block" onClick={this.createNewTest}>Save Details</button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.showEditModal}
          onHide={this.toggleEditModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Edit Test
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            
            <div className="form-group">
              <label>Start</label>
              <Datetime value={this.state.editStartDate} inputProps={{ className: "form-control"}} onChange={this.onEditStartChanged} />
            </div>

            <div className="form-group">
              <label>Is This Test Complete?</label>
              <select className="form-control" id="editStatus" value={this.state.editStatus} onChange={this.updateField}>
                <option value="pending">No</option>
                <option value="complete">Yes</option>
              </select>
            </div>

            {this.state.editStatus === "complete" && (
              <div className="form-group">
                <label>End</label>
                <Datetime value={this.state.editEndDate} inputProps={{ className: "form-control"}} onChange={this.onEditEndChanged} />
              </div>
            )}

            <div className="form-group">
              <label>Notes</label>
              <textarea className="form-control" id="editObserverNotes" value={this.state.editObserverNotes} onChange={this.updateField} />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-primary btn-block" onClick={this.editTest}>Save Details</button>
            <button className="btn btn-danger btn-block" onClick={this.toggleDeleteModal}>Delete</button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.showViewModal}
          onHide={this.toggleViewModal}
          dialogClassName="modal90"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Viewing {capitalizeFirstLetter(this.state.selectedTest.status)} Test Starting {moment(this.state.selectedTest.start).format("MM/DD/YY h:mm A")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-5">
                <div className="row">
                  <div className="col-6">
                    <div className="row gpstest-data-row">
                      <div className="col-12">
                        <span className="gpstest-data-row-label">Start </span><br />
                        {moment(this.state.selectedTest.start).format("MM/DD/YY h:mm A")}
                      </div>
                    </div>

                    <div className="row gpstest-data-row">
                      <div className="col-12">
                        <span className="gpstest-data-row-label">End </span><br />
                        {moment(this.state.selectedTest.end).format("MM/DD/YY h:mm A")}
                      </div>
                    </div>

                    <div className="row gpstest-data-row">
                      <div className="col-12">
                        <span className="gpstest-data-row-label">Length </span><br />
                        {moment(this.state.selectedTest.end).diff(this.state.selectedTest.start, "hour")} hours
                      </div>
                    </div>

                    <div className="row gpstest-data-row">
                      <div className="col-12">
                        <span className="gpstest-data-row-label">Observer Notes </span><br />
                        {this.state.selectedTest.observerNotes}
                      </div>
                    </div>
                  </div>

                  <div className="col-6">
                    <div className="row gpstest-data-row">
                      <div className="col-12">
                        <span className="gpstest-data-row-label">Avg Accuracy </span><br />
                        {this.state.selectedTest.averageAccuracy}
                      </div>
                    </div>

                    <div className="row gpstest-data-row">
                      <div className="col-12">
                        <span className="gpstest-data-row-label">Avg Distance </span><br />
                        {this.state.selectedTest.averageDistance}
                      </div>
                    </div>

                    <div className="row gpstest-data-row">
                      <div className="col-12">
                        <span className="gpstest-data-row-label">Med Distance </span><br />
                        {this.state.selectedTest.medianDistance}
                      </div>
                    </div>

                    <div className="row gpstest-data-row">
                      <div className="col-12">
                        <span className="gpstest-data-row-label">Standard Deviation </span><br />
                        {this.state.selectedTest.standardDeviation}
                      </div>
                    </div>

                    <div className="row gpstest-data-row">
                      <div className="col-12">
                        <span className="gpstest-data-row-label">Smaller Accuracy Percent </span><br />
                        {this.state.selectedTest.smallerAccuracyPercent}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <Table columns={selectedLocationCols} rows={this.state.selectedTest.locations} />
                  </div>
                </div>
              </div>

              <div className="col-7">
                <div className="row gpstest-data-row">
                  <div className="col-4">
                    <span className="gpstest-data-row-label">User ID </span><br />
                    {this.state.selectedTest.userId}
                  </div>
                  <div className="col-4">
                    <span className="gpstest-data-row-label">Device ID </span><br />
                    {this.state.selectedTest.deviceId}
                  </div>
                  <div className="col-4">
                    <span className="gpstest-data-row-label">Serial </span><br />
                    {this.state.selectedTest.productId}
                  </div>
                </div>
                <div className="row gpstest-data-row">
                  <div className="col-4">
                    <span className="gpstest-data-row-label">Firmware </span><br />
                    {this.state.selectedTest.firmwareVersion}
                  </div>
                  <div className="col-4">
                    <span className="gpstest-data-row-label">Modem </span><br />
                    {this.state.selectedTest.modemFirmwareVersion}
                  </div>
                  <div className="col-4">
                    <span className="gpstest-data-row-label">Target </span><br />
                    {this.state.selectedTest.targetLat} / {this.state.selectedTest.targetLng}
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <div className="center" style={{ height: "60vh", width: "100%" }}>
                      <GoogleMapReact
                        yesIWantToUseGoogleMapApiInternals
                        bootstrapURLKeys={{
                          key: mapKey,
                        }}
                        defaultCenter={{
                          lat: this.state.selectedTest.targetLat,
                          lng: this.state.selectedTest.targetLng,
                        }}
                        defaultZoom={20}
                        options={{
                          keyboardShortcuts: false,
                          tilt: 0,
                          mapTypeId: "satellite",
                        }}
                      >
                        {this.state.selectedTest.locations.map((l, index) => {
                          return <Marker lat={l.lat} lng={l.lng} key={index} color={this.renderPointColor("point")} />;
                        })}
                        <Marker lat={this.state.selectedTest.targetLat} lng={this.state.selectedTest.targetLng} key="target" color={this.renderPointColor("target")} />
                      </GoogleMapReact>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-primary btn-block" onClick={this.toggleEditModal}>Edit</button>
            <button className="btn btn-danger btn-block" onClick={this.toggleDeleteModal}>Delete</button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.showDeleteModal}
          onHide={this.toggleDeleteModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Delete {capitalizeFirstLetter(this.state.selectedTest.status)} Test Starting {moment(this.state.selectedTest.start).format("MM/DD/YY H:mm A")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <strong className="text-danger">Warning: </strong> You are about to permanently delete this test. The GPS points will remain, so you can reset this test, but the
            firmware, modem firmware, and other point-in-time data may not be accurate. Are you sure you want to do this?
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-danger btn-block" onClick={this.deleteTest}>Confirm Delete</button>
          </Modal.Footer>
        </Modal>
      </Screen>
    );
  }

  private fetch() {
    this.setState({ loading: true }, async () => {
      try {
        let rawTests: any[] = [];
        if (this.props.mode && this.props.mode === "device" && this.props.deviceId) {
          const result = await GPSTestsAPI.getGPSTestsForDevice("wagz2", this.props.deviceId);
          rawTests = result.body.data;
        } else if (this.props.mode && this.props.mode === "user" && this.props.userId) {
          const result = await GPSTestsAPI.getGPSTestsForUser("wagz2", this.props.userId);
          rawTests = result.body.data;
        } else {
          const result = await GPSTestsAPI.getGPSTestsInRange("wagz2", this.state.start.format("YYYY-MM-DDT") + "00:00:00", this.state.end.format("YYYY-MM-DDT") + "23:59:59")
          rawTests = result.body.data;
        }
        // process
        let tests: any[] = [];
        let csvRows: any[] = [];
        for (const t of rawTests) {
          const proc = this.processRow(t);
          tests.push(proc.tableRow);
          csvRows.push(proc.csvRow);
        }
        this.setState({
          loading: false,
          csvRows,
          tests,
        })
      } catch (err) {
        this.setState({ loading: false });
      }
    })
  }

  private updateField(e: any) {
    const ns = this.state;
    const id = e.target.id;
    const val = e.target.value;
    ns[id] = val;
    this.setState(ns, () => {
      if (id === "newLookupBy") {
        if (val === "productId") {
          this.setState({ newDeviceId: "" });
        } else {
          this.setState({ newProductId: "" });
        }
      }
    });
  }

  // change handlers

  // filters
  private onStartFilterChanged(newDate: moment.Moment) {
    this.setState({ start: newDate });
  }

  private onEndFilterChanged(newDate: moment.Moment) {
    this.setState({ end: newDate });
  }

  // new test change handlers 
  private onNewStartChanged(newDate: moment.Moment | string) {
    const nd = moment(newDate);
    this.setState({ newStartDate: nd });
  }

  private onNewEndChanged(newDate: moment.Moment | string) {
    const nd = moment(newDate);
    this.setState({ newEndDate: nd });
  }


  // edit test change handlers
  private onEditStartChanged(newDate: moment.Moment | string) {
    const nd = moment(newDate);
    this.setState({ editStartDate: nd });
  }

  private onEditEndChanged(newDate: moment.Moment | string) {
    const nd = moment(newDate);
    this.setState({ editEndDate: nd });
  }



  // modal toggles

  private toggleNewModal() {
    this.setState({ showNewModal: !this.state.showNewModal });
  }

  private toggleViewModal() {
    this.setState({ showViewModal: !this.state.showViewModal });
  }

  private toggleEditModal() {
    this.setState({ showViewModal: false, showEditModal: !this.state.showEditModal });
  }

  private toggleDeleteModal() {
    this.setState({ showViewModal: false, showEditModal: false, showDeleteModal: !this.state.showDeleteModal });
  }

  // selectors

  private viewTest(row: any) {
    this.setState({ 
      selectedTest: row,
      editStartDate: moment(row.start),
      editEndDate: row.end && row.end !== "" && moment(row.end).isAfter(moment(row.start)) ? moment(row.end): moment(row.start).add(1, "hour"),
      editObserverNotes: row.observerNotes,
      editStatus: row.status, 
    }, () => {
      if(this.state.selectedTest.status === "complete"){
        this.toggleViewModal();
      } else {
        this.toggleEditModal()      
      }
    });
  }

  // api calls

  private createNewTest() {
    const data: any = {
      start: this.state.newStartDate.clone().utc().format("YYYY-MM-DDTHH:mm:ss") + "Z",
      targetLat: parseFloat(this.state.newTargetLat),
      targetLng: parseFloat(this.state.newTargetLng),
      productId: "",
      deviceId: 0,
      userId: this.props.userState.user.id,
      observerNotes: this.state.newObserverNotes,
    };
    if (this.state.newProductId !== "") {
      data.productId = this.state.newProductId
    }
    if (this.state.newDeviceId !== "") {
      data.deviceId = parseInt(this.state.newDeviceId);
    }
    if (this.state.newIsCompleted !== "no") {
      data.end = this.state.newEndDate.clone().utc().format("YYYY-MM-DDTHH:mm:ss") + "Z";
    }

    // check errors
    if (data.targetLat === 0 || data.targetLng === 0) {
      return error("You must provide valid target lat and long")
    }
    if (data.productId === "" && (isNaN(data.deviceId) || data.deviceId === 0)) {
      return error("You must provide either a serial / product id OR a device DB id");
    }
    if (data.productId !== "" && !isNaN(data.deviceId) && data.deviceId !== 0) {
      return error("You can only provide EITHER a serial / product id OR a device DB id");
    }
    this.setState({ loading: true }, async () => {
      try {
        await GPSTestsAPI.createGPSTest("wagz2", data);
        // for now, just trigger a refetch, but we can eventually push it in
        this.setState({ showNewModal: false }, () => {
          this.fetch();
        })
      } catch (err) {
        this.setState({ loading: false });
        error("Could not save that test. Check your device identifiers to make sure they are valid.");
      }
    })
  }


  private deleteTest(){
    this.setState({ loading: true }, async () => {
      try{
        await GPSTestsAPI.deleteGPSTest("wagz2", this.state.selectedTest.id);
        this.setState({ showDeleteModal: false }, this.fetch);
      }catch(err){
        this.setState({ loading: false, showDeleteModal: false });
        error("Could not delete that test. Please contact an administrator.");
      }
    })
  }

  private editTest(){
    // we only allow start, end, status, and notes
    const data: any = {
      start: this.state.editStartDate.clone().utc().format("YYYY-MM-DDTHH:mm:ss") + "Z",
      observerNotes: this.state.editObserverNotes,
    };
    if(this.state.editStatus !== this.state.selectedTest.status){
      data.status = this.state.editStatus;
    }
    if(this.state.editStatus === "complete"){
      data.end = this.state.editEndDate.clone().utc().format("YYYY-MM-DDTHH:mm:ss") + "Z"
    }
    this.setState({ loading: true }, async () => {
      try{
        await GPSTestsAPI.updateGPSTest("wagz2", this.state.selectedTest.id, data);
        this.setState({ showEditModal: false }, this.fetch);
      }catch(err){
        this.setState({ loading: false, showEditModal: false });
        error("Could not save that test. Please contact an administrator.");
      }
    })
  }

  


  // processors
  private processRow(row: any): any {
    const tableRow = {
      ...row,
      startFormatted: moment(row.start).format("MM/DD/YY h:mm A"),
      locationCount: row.locations.length,
      target: `${row.targetLat} / ${row.targetLng}`,
      view: (
        <div>
          <button className="btn btn-block btn-primary" onClick={() => { this.viewTest(row) }}>View</button>
        </div>),
    };
    const csvRow = {
      ...row,
      locations: JSON.stringify(row.locations),
    };

    return {
      tableRow,
      csvRow,
    };
  }

  private renderPointColor(source: string) {
    switch (source) {
      case "target": {
        return "#731505";
      }
      default: {
        return "#11cdef";
      }
    }
  }


}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GPSTestsScreen) as any
);
