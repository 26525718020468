import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as UserActions from "src/reducers/userReducer";
import Table from "src/components/structure/Table";
import { capitalizeFirstLetter } from "src/utils/utilities";
import { DeviceAPI } from "src/api";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import moment from "moment";

interface IDevicesTabProps {
  userId: number;
  location: any;
  userActions: any;
  userState: any;
}

interface IDevicesTabState {
  loading: boolean;
  allDevices: any[];
  filteredDevices: any[];
  csvData: any[];
  showStatus: "all" | "active" | "cancel" | "grace" | "deactivated";
}

class DevicesTab extends Component<IDevicesTabProps, IDevicesTabState> {
  constructor(props: IDevicesTabProps) {
    super(props);

    this.state = {
      loading: false,
      allDevices: [],
      filteredDevices: [],
      csvData: [],
      showStatus: "active",
    };


    this.updateField = this.updateField.bind(this);
    this.updateFilter = this.updateFilter.bind(this);
    this.getUserDevices = this.getUserDevices.bind(this);
  }

  updateField(e: any) {
    let ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  componentDidMount() {
    this.getUserDevices();
  }

  getUserDevices() {
    this.setState({ loading: true }, async () => {
      try {
        const results = await DeviceAPI.getDevicesForUser(
          "wagz",
          this.props.userId
        );

        const userDevices = [];
        const csvData: any = [];

        const rawDevices = results.body.data;

        // we sort twice, once by status and once by onboarded; since it is overridden, we do date first
        rawDevices.sort((a: any, b: any) => {
          return moment(a.registeredOn).isBefore(b.registeredOn) ? 1 : -1;
        })
        rawDevices.sort((a: any, b: any) => {
          return a.status === "active" ? -1 : 1;
        })


        for (let result of rawDevices) {
          csvData.push(result);
          userDevices.push(
            {
              view: (
                <div style={{textAlign: "center"}}>
                  <Link to={`/support/devices/${result.id}`} className="link-light">
                    <FontAwesomeIcon icon={faEye} />
                  </Link>
                </div>
              ),
              id: result.id,
              productId: result.productId,
              nickname: result.nickname,
              deviceType: capitalizeFirstLetter(result.deviceType),
              status: capitalizeFirstLetter(result.status),
              registeredOn: result.registeredOn.substring(0,10),
              heartbeat: result.heartbeat,
              firmwareVersion: result.firmwareVersion,
              modemFirmwareVersion: result.modemFirmwareVersion,
              simId: result.simId,
              logInterval: result.logInterval,
              otaChannel: result.otaChannel,
            },);
        }
        this.setState({
          allDevices: userDevices,
          csvData,
        }, () => this.filterDevices());
      } catch (err) {
        this.setState({ loading: false });
      }
    });
  }

  filterDevices(){
    const allDevices = this.state.allDevices;
    this.setState({ loading: true }, () => {
      if(this.state.showStatus === "all") {
        return this.setState({
          filteredDevices: allDevices,
          loading: false,
        })
      }

      const filteredDevices: any[] = [];
      for(const d of allDevices){
        if(d.status.toLowerCase() === this.state.showStatus){
          filteredDevices.push(d);
        }
      }

      this.setState({
        filteredDevices,
        loading: false,
      })

    })
  }

  updateFilter(e: any){
    const val = e.target.value;
    this.setState({
      showStatus: val,
      loading: true,
    }, () => {
      this.filterDevices()
    })
  }

  render() {
    return (
      <div>
      <Table
        loading={this.state.loading}
        title="Devices"
        csvFileName={`devicesFor-userID-${this.props.userId}`}
        showDownload={true}
        columns={cols}
        rows={this.state.filteredDevices}
        csvData={this.state.csvData}
        preElement={(
          <div className="row">
            <div className="col-4 offset-8">
              <select className="form-control" value={this.state.showStatus} onChange={this.updateFilter}>
                <option value="all">Show All</option>
                <option value="active">Show Active</option>
                <option value="cancel">Show Cancel</option>
                <option value="deactivated">Show Deactivated</option>
                <option value="grace">Show Grace</option>
              </select>
            </div>
          </div>
        )}
      />
      </div>
    );
  }
}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default connect<{}, {}, any>(mapStateToProps, mapDispatchToProps)(DevicesTab);


const cols = [
  {
    label: "",
    field: "view",
    sort: "disabled"
  },
    {
      label: "ID",
      field: "id",
      sort: "asc",
    },
    {
      label: "Serial Number",
      field: "productId",
      sort: "asc",
    },
    {
      label: "Nickname",
      field: "nickname",
      sort: "asc",
    },
    {
      label: "Type",
      field: "deviceType",
      sort: "asc",
    },
    {
      label: "Status",
      field: "status",
      sort: "asc",
    },
    {
      label: "Registered On",
      field: "registeredOn",
      sort: "asc",
    },
    {
      label: "Last Heartbeat",
      field: "heartbeat",
      sort: "asc",
    },
    {
      label: "OTA Channel",
      field: "otaChannel",
      sort: "asc",
      width: 120,
    },
    {
      label: "Modem FW Version",
      field: "modemFirmwareVersion",
      sort: "asc",
    },
    {
      label: "Firmware Version",
      field: "firmwareVersion",
      sort: "asc",
    },
    {
      label: "ICCID",
      field: "simId",
      sort: "asc",
    },
    {
      label: "Log Int.",
      field: "logInterval",
      sort: "asc",
    },
  ];