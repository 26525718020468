// This is built so it can be embedded in a modal, in another screen, or in a tab,
// so a lot of the UI is left to the parent

import React, { Component } from "react";
import { JobsAPI } from "src/api";
import { IJob } from "src/api/jobs";
import { error } from "src/components/structure/Alert";


interface IJobCreateScreenProps {
  adminUserId: number;
  onCreate: (createdJob: IJob) => any;
}

interface IJobCreateScreenState {
  loading: boolean;

  targetMicroservice: string;
  jobToRun: string;
  cronToRun: string;
  outputType: "inline" | "csv";
  notificationType: "none" | "email";
  notificationTarget: string;

  sendParams: "no" | "raw" | "guided";
  stringParams: string;
  params: any;
}

const app = "wagz2";

export class JobCreateScreen extends Component<
  IJobCreateScreenProps,
  IJobCreateScreenState
> {
  constructor(props: IJobCreateScreenProps) {
    super(props);
    this.state = {
      loading: true,

      targetMicroservice: "",
      jobToRun: "",
      cronToRun: "",
      outputType: "inline",
      notificationType: "none",
      notificationTarget: "",
      stringParams: "{}",
      params: {},
      
      sendParams: "no",
    };

    this.updateField = this.updateField.bind(this);
    this.updateParamField = this.updateParamField.bind(this);
    this.createJob = this.createJob.bind(this);
  }

  componentDidMount() {
  }

  render() {
    return (
      <div>
        <div className="row row-margin-bottom">
          <div className="col-3">
            <label className="label-bold">Target Microservice</label>
            <select id="targetMicroservice" className="form-control" value={this.state.targetMicroservice} onChange={this.updateField}>
              <option value="">Select a Microservice</option>
              <option value="analytics-service">Analytics</option>
              <option value="billing-service">Billing</option>
              <option value="devices-service">Devices</option>
              <option value="metrics-service">Metrics</option>
              <option value="pets-service">Pets</option>
              <option value="users-service">Users</option>
            </select>
          </div>
          <div className="col-3">
            {this.state.targetMicroservice !== "" && (
              <>
                <label className="label-bold">Job to Run</label>
                <select className="form-control" value={this.state.jobToRun} id="jobToRun" onChange={this.updateField}>
                  <option value="" disabled={true}>Select</option>
                  {jobsAndCrons[this.state.targetMicroservice].jobs.map((job: any) => {
                    return <option key={job.name} value={job.name}>{job.display}</option>
                  })}
                </select>
              </>
            )}
          </div>

          {
            this.state.jobToRun === "run_cron" ?
              (
                <>
                  <div className="col-3">
                    <label className="label-bold">Cron</label>
                    <select className="form-control" value={this.state.cronToRun} id="cronToRun" onChange={this.updateField}>
                      <option value="" disabled={true}>Select</option>
                      {jobsAndCrons[this.state.targetMicroservice].crons.map((cron: any) => {
                        return <option key={cron.name} value={cron.name}>{cron.display}</option>
                      })}
                    </select>
                  </div>
                  <div className="col-3">
                    {this.state.cronToRun !== "" && (
                      <div>
                       <label className="label-bold">Description</label>
                        {jobsAndCrons[this.state.targetMicroservice].crons.map((j: any) =>{
                          if(j.name === this.state.cronToRun){
                            return <div>{j.description}</div>
                          }
                          return null;
                        })}
                      </div>
                    )}
                  </div>
                </>
              ) :
              (

                <div className="col-6">
                {this.state.jobToRun !== "" && (
                  <div>
                    <label className="label-bold">Description</label>
                    {jobsAndCrons[this.state.targetMicroservice].jobs.map((j: any) =>{
                      if(j.name === this.state.jobToRun){
                        return <div key={j.name}>{j.description}</div>
                      }
                      return null;
                    })}
                  </div>
                )}
                </div>
              )
          }
        </div>

        <hr />

        <div className="row row-margin-bottom">
          <div className="col-4">
            <label className="label-bold">Output Type</label>
            <select id="outputType" className="form-control" value={this.state.outputType} onChange={this.updateField}>
              <option value="inline">None</option>
              <option value="csv">CSV File</option>
            </select>
            <br /><span className="small">May not be supported by all jobs, so ask an administrator if you have questions.</span>
          </div>
          <div className="col-4">
            <label className="label-bold">Notification Type</label>
            <select id="notificationType" className="form-control" value={this.state.notificationType} onChange={this.updateField}>
              <option value="none">None</option>
              <option value="email">Send an Email</option>
            </select>
          </div>
          <div className="col-4">
            {this.state.notificationType !== "none" && (
              <>
              <label className="label-bold">Notification Target</label>
              <input type="text" className="form-control" id="notificationTarget" value={this.state.notificationTarget} onChange={this.updateField} />
              </>
            )}
          </div>
        </div>

        <hr />
        {this.state.jobToRun !== "run_cron" && (
          <div className="row row-margin-bottom">
            <div className="col-4">
              <label className="label-bold">Send Extra Parameters</label>
              <select className="form-control" value={this.state.sendParams} onChange={this.updateField} id="sendParams">
                <option value="no">No</option>
                <option value="guided">Yes, Show Me Options</option>
                <option value="raw">Yes, I Will Enter Raw Params</option>
              </select>
            </div>
            <div className="col-8">
              {this.state.sendParams === "raw" && this.state.jobToRun !== "" && (
                <textarea id="rawParams" className="form-control" value={this.state.stringParams} onChange={this.updateField} />
              )}
              {this.state.sendParams === "guided" && this.state.jobToRun !== "" && jobsAndCrons[this.state.targetMicroservice].jobs.map((j: any) => {
                if(j.name !== this.state.jobToRun) {
                  return null;
                }
                if(!j.optionalParams){
                  return (<strong>Job does not take additional parameters</strong>)
                }
                let dom: any[] = [];
                for(const param of Object.keys(j.optionalParams)){
                  dom.push(
                    <div className="row" key={param} style={{marginBottom: 10}}>
                      <div className="col-3">
                        {param}
                      </div>
                      <div className="col-5">
                        {j.optionalParams[param]}
                      </div>
                      <div className="col-4">
                        <input type="text" className="form-control" value={this.state.params[param] ? this.state.params[param]: ""} id={param} onChange={this.updateParamField} />
                      </div>
                    </div>
                  );
                };
                return dom;
              })}
            </div>
          </div>
        )}
        

        <div className="row row-margin-bottom">
          <div className="col-12">
            <button className="btn btn-primary btn-block" onClick={this.createJob}>Queue Job</button>
          </div>
        </div>
      </div>
    );
  }

  private updateField(e: any) {
    const ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private updateParamField(e: any){
    const ns = this.state.params;
    const param = e.target.id;
    const val = e.target.value;
    if(!ns[param]){
      ns[param] = "";
    }
    ns[param] = val;
    this.setState({params: ns});
  }

  private createJob(){
    // check the data before sending
    if(this.state.jobToRun === ""){
      return error("You must specifiy a job to run!")
    }
    
    let parameters: any = {};
    // if there is guided params, those take preference
    let hasParams = false;
    if(this.state.params){
      for(const key of Object.keys(this.state.params)){
        hasParams = true;
        parameters[key] = this.state.params[key];
      }
    }
    if(!hasParams && this.state.stringParams !== ""){
      try{
        parameters = JSON.parse(this.state.stringParams);
      }catch(err){
        return error("Invalid JSON in the parameters!")
      }
    }
    
    if(this.state.jobToRun === "run_cron" && this.state.cronToRun !== ""){
      parameters["cron_name"] = this.state.cronToRun;
    } 
    this.setState({ loading: true }, async () => {
      try{
        const data: any = {
          targetService: this.state.targetMicroservice,
          name: this.state.jobToRun,
          notificationType: this.state.notificationType,
          notificationTarget: this.state.notificationTarget,
          outputType: this.state.outputType,
          parameters,
        };
        if(this.state.notificationType !== "none"){
          data["notificationType"] = this.state.notificationType;
        }
        const result = await JobsAPI.createJob(app, data);
        const job = result.body.data;
        this.props.onCreate(job);
        this.setState({
          loading: false,
          targetMicroservice: "",
          jobToRun: "",
          cronToRun: "",
          outputType: "inline",
          notificationType: "none",
          notificationTarget: "",
          stringParams: "{}",          
          params: {}
        })
      }catch(err){
        error("Could not create that job. Check your inputs, check the console, and try again or contact an administrator.");
        this.setState({ loading: false });
      }
    })
  }

}

const dbAnalyticDumpInfo = {
  name: "db_analytic_dump",
  display: "Analytic Dump",
  description: "Asks the service to provide lite-versions of some of their database tables and then uploads the CSVs. The Analytics Service will then need to process them through a separate job.",
};

const runCronInfo = {
  name: "run_cron",
  display: "Run a Cron Function",
  description: "Tells the service to immediately run one of it's cron functions.",
};

// const b = 
// {
//   name: "",
//   display: "",
//   description: "",
//   optionalParams: {},
// };

// eventually, maybe we move these to the server?
const jobsAndCrons = {
  "analytics-service": {
    jobs: [
      dbAnalyticDumpInfo,
      runCronInfo,
    ],
    crons: [
      {
        name: "cronrequestdbdumps",
        display: "Request DB Dumps",
        description: "Asks the analytics service to generate the DB Analytic Dumps for the services.",
      },
      {
        name: "cronprocessdbdumps",
        display: "Process DB Dumps",
        description: "Asks the analytics service to process the DB Analytic Dumps for the services. This will delete the existing data and replace it with processed dumps in the last 2 hours. Note that if there are no dumps, the data is lost!",
      },
    ],
  },
  "billing-service": {
    jobs: [
      runCronInfo,
    ],
    crons: [
      {
        name: "cronplandiscountchecks",
        display: "Check Plans for Discounts",
        description: "Checks users with multiple active devices ot make sure they get a discounted plan in case they chose incorrectly.",
      },
      {
        name: "croncleanupdevchargify",
        display: "Clean Dev Chargify",
        description: "Deletes cancelled subscriptions to avoid the cap. This will only work on dev.",
      },
    ]
  },
  "devices-service": {
    jobs: [
      dbAnalyticDumpInfo,
      runCronInfo,
      {
        name: "generate_users_pending_devices",
        display: "Generate Pending Devices Report",
        description: "Generates a report of pending devices. Either set the output to be CSV OR pass in a 'sendEmailTo' param with an @wagz.com address. Otherwise, you will not see any output!",
        optionalParams: {
          "productFamilyID": "The product family id to examine, defaults to 23",
          "sendEmailTo": "Who to send the email to, if desired."
        }
      },
    ],
    crons: [
      {
        name: "removetimeoutactionscron",
        display: "Remove Timeout Actions",
        description: "Removes all actions that are pending and their timeout window has passed.",
      },
      {
        name: "cleanupdevdevicescron",
        display: "Clean Up Orphaned Dev Devices",
        description: "Cleans up any deactivated or orphaned devices on dev. Will not work on production.",
      },
      {
        name: "cronhandleoldconnectivitylogs",
        display: "Delete Old Connectivity Logs",
        description: "Deletes all activity logs older than a server-controlled threshold.",
      },
      {
        name: "cronhandleoldstatusbararchives",
        display: "Delete Old Status Bars",
        description: "Deletes all status bars older than a server-controlled threshold.",
      },
      {
        name: "cronanomaliesdeviceactivenoplan",
        display: "Detect Devices Without Plans",
        description: "Checks for devices that are active and should have a plan but do not. Sets them in the anomalies report.",
      },
      {
        name: "cronsendgeofenceemail",
        display: "Nurture - Send Geofence Email",
        description: "Sends the selected email from the nurture campaign to those who would have received it today anyway. Be careful with this, as it has the potential to trigger a bunch of emails to a bunch of people who may not be expecting it!",
      },
      {
        name: "cronsendactivitytrackingemail",
        display: "Nurture - Send Activity Email",
        description: "Sends the selected email from the nurture campaign to those who would have received it today anyway. Be careful with this, as it has the potential to trigger a bunch of emails to a bunch of people who may not be expecting it!",
      },
      {
        name: "cronsendbatteryemail",
        display: "Nurture - Send Battery Email",
        description: "Sends the selected email from the nurture campaign to those who would have received it today anyway. Be careful with this, as it has the potential to trigger a bunch of emails to a bunch of people who may not be expecting it!",
      },
      {
        name: "cronsendsurveyemail",
        display: "Nurture - Send Survey Email",
        description: "Sends the selected email from the nurture campaign to those who would have received it today anyway. Be careful with this, as it has the potential to trigger a bunch of emails to a bunch of people who may not be expecting it!",
      },
      {
        name: "cronsendinactiveemail",
        display: "Nurture - Send Inactive Email",
        description: "Sends the selected email from the nurture campaign to those who would have received it today anyway. Be careful with this, as it has the potential to trigger a bunch of emails to a bunch of people who may not be expecting it!",
      },
      {
        name: "cronsendcheckinginemail",
        display: "Nurture - Send Checking-In Email",
        description: "Sends the selected email from the nurture campaign to those who would have received it today anyway. Be careful with this, as it has the potential to trigger a bunch of emails to a bunch of people who may not be expecting it!",
      },
    ],
  },
  "metrics-service": {
    jobs: [
      dbAnalyticDumpInfo,
      runCronInfo,
      {
        name: "breed_activity_reset",
        display: "Reset Breed Activity Reports",
        description: "Resets the breed activity reports and optionally uploads them as a CSV",
        optionalParams: {
          "month": "The month to reset, in YYYY-MM format",
        }
      },
      {
        name: "calculate_pet_rankings",
        display: "Re-Calculate Pet Rankings",
        description: `DELETES and re-calculates the pet rankings for a day, specified as a day in the parameters: { "day": "2006-01-01" }`,
        optionalParams: {
          "day": "The day for the rankings, in YYYY-MM-DD format"
        }
      },
    ],
    crons: [
      {
        name: "cronconvertoldmetricstodaily",
        display: "Convert Metrics to Daily",
        description: "Consolidates the hourly metrics into daily metrics for easier use in a variety of tools.",
      },
      {
        name: "crondeleteoldmetricscron",
        display: "Delete Old Metrics",
        description: "Deletes metrics older than a server-controlled threshold. Should only be run if all consolidation is completed.",
      },
      {
        name: "cronremoveoldevents",
        display: "Remove Old Events",
        description: "Removes events olders than a server-controlled threshold.",
      },
      {
        name: "croncalculaterankings",
        display: "Calculate Rankings",
        description: "Calculate Pet Comparison rankings. Can be pretty intensive, so please ensure you understand why you are running this!",
      },
      {
        name: "cronremoverankings",
        display: "Remove Old Rankings",
        description: "Removes pet rankings older than a server-controlled threshold.",
      },
      {
        name: "cronmetricmissingdeviceid",
        display: "Correct Missing Device ID",
        description: "Attempts to correct any metrics missing a device identifier.",
      },
      {
        name: "cronoutlierdetection",
        display: "Detect Outliers",
        description: "Detects specified outliers and inserts them into the outliers report.",
      },
      {
        name: "croncalculatescores",
        display: "Calculate HHS Scores",
        description: "Calculates the HHS and related scores.",
      },
      {
        name: "cronsendmonthinreviewemail",
        display: "Nurture - Send Month In Review Email",
        description: "Sends the selected email from the nurture campaign to those who would have received it today anyway. Be careful with this, as it has the potential to trigger a bunch of emails to a bunch of people who may not be expecting it!",
      },
      {
        name: "cronbreedactivity",
        display: "Reset Breed Activity Reports",
        description: "Resets the breed activity reports. This will NOT generate a CSV to set.",
      },
    ],
  },
  "pets-service": {
    jobs: [
      dbAnalyticDumpInfo,
      runCronInfo,
    ],
    crons: [
      {
        name: "cronconsolidatepetlocations",
        display: "Consolidate Pet Locations",
        description: "Consolidates pet locations into daily pet locations. This should happen automatically, so please be careful.",
      },
      {
        name: "cronlocationpostedanomalies",
        display: "Detect Posted Anomalies",
        description: "Attempts to find any locations with odd posted entries and adds them to the outliers table.",
      },
      {
        name: "cronpetid0locationhelper",
        display: "Locations Missing Pet IDs",
        description: "Attempts to identify and correct any locations that are missing pet ids.",
      },
    ],
  },
  "users-service": {
    jobs: [
      dbAnalyticDumpInfo,
      runCronInfo,
    ],
    crons: [
      {
        name: "crondeleteoldotptokens",
        display: "Delete Old OTP Tokens",
        description: "Deletes OTP tokens that have exceeded the server's threshold.",
      },
    ],
  },
}