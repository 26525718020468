import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as UserActions from "src/reducers/userReducer";
import { UserAPI } from "src/api";
import { IUser, BlankUser } from "src/api/users";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import InfoTab from "./UserTabs/InfoTab";
import BillingTab from "./UserTabs/BillingTab";
import DevicesTab from "./UserTabs/DevicesTab";
import PetsTab from "./UserTabs/PetsTab";
import LinkTab from "./UserTabs/LinkTab";
import GeofenceTab from "./UserTabs/GeofenceTab";
import ManageTab from "./UserTabs/ManageTab";
import EmailTab from "./UserTabs/EmailTab";
import PushTab from "./UserTabs/PushPreferencesTab";
import WalksTab from "./UserTabs/WalksTab";
import MobileDevicesTab from "./UserTabs/MobileDevicesTab";
import LocationScreen from "src/components/structure/LocationScreen";
import EventsTab from "src/components/structure/EventsTab";

import { AnalyticEventsScreen } from "src/components/screens/Reports/Analytics/AnalyticEvents";
import { AnalyticDevicesScreen } from "src/components/screens/Reports/Analytics/AnalyticDevices";
import { AnalyticLocationsScreen } from "src/components/screens/Reports/Analytics/AnalyticLocations";

interface IUserPageProps {
  match: any;
  history: any;
  location: any;
  userActions: any;
  userState: any;
}

interface IUserPageState {
  loading: boolean;
  userId: number;
  user: IUser;
  firstName: string;
  lastName: string;
  view: string;
}

class UserPage extends Component<IUserPageProps, IUserPageState> {
  constructor(props: IUserPageProps) {
    super(props);

    this.state = {
      loading: false,
      userId: this.props.match.params.userId,
      user: BlankUser,
      firstName: "",
      lastName: "",
      view: "info",
    };

    this.handleUpdatedProfile = this.handleUpdatedProfile.bind(this);
  }

  componentDidMount() {
    this.getUserProfile();
  }

  getUserProfile() {
    this.setState({ loading: true }, async () => {
      try {
        const res = await UserAPI.getUserById(
          "wagz",
          this.state.userId,
        );
        this.setState({
          loading: false,
          user: res.body.data,
          firstName: res.body.data.firstName,
          lastName: res.body.data.lastName,
        });
      } catch (err) {
        this.setState({ loading: false });
      }
    });
  }

  render() {
    return (
      <div className="col-sm-12 col-lg-12">
        <div>
          <h1>{this.state.firstName} {this.state.lastName} (ID: {this.state.userId})</h1>
        </div>
        <div className="row view-select-row">
          <div className="col-12">
            <Tabs>
              <TabList>
                <Tab>Information</Tab>
                <Tab>Billing</Tab>
                <Tab>Devices</Tab>
                <Tab>Pets</Tab>
                <Tab>Walks</Tab>
                <Tab>Links</Tab>
                <Tab>Locations</Tab>
                <Tab>Geofences</Tab>
                <Tab>Events</Tab>
                <Tab>Emails</Tab>
                <Tab>Push</Tab>
                <Tab>Mobile Devices</Tab>
                <Tab>Analytic Events</Tab>
                <Tab>Analytic Devices</Tab>
                <Tab>Analytic Locations</Tab>
                <Tab>Management</Tab>
              </TabList>
              <TabPanel>
                <InfoTab userId={this.state.userId} />
              </TabPanel>
              <TabPanel>
                <BillingTab user={this.state.user} />
              </TabPanel>
              <TabPanel>
                <DevicesTab userId={this.state.userId} />
              </TabPanel>
              <TabPanel>
                <PetsTab userId={this.state.userId} />
              </TabPanel>
              <TabPanel>
                <WalksTab userId={this.state.userId} />
              </TabPanel>
              <TabPanel>
                <LinkTab userId={this.state.userId} />
              </TabPanel>
              <TabPanel>
                <LocationScreen userId={this.state.userId} locationsFor="user" />
              </TabPanel>
              <TabPanel>
                <GeofenceTab userId={this.state.userId} />
              </TabPanel>
              <TabPanel>
                <EventsTab accessLevel={this.props.userState.user.accessLevel} loading={this.state.loading} eventsFor="user" deviceId={0} userId={this.state.userId} petId={0} />
              </TabPanel>
              <TabPanel>
                <EmailTab user={this.state.user} />
              </TabPanel>
              <TabPanel>
                <PushTab userId={this.state.userId} />
              </TabPanel>
              <TabPanel>
                <MobileDevicesTab userId={this.state.userId} />
              </TabPanel>
              <TabPanel>
                <AnalyticEventsScreen target="user" targetId={this.state.userId} />
              </TabPanel>
              <TabPanel>
                <AnalyticDevicesScreen target="user" targetId={this.state.userId} />
              </TabPanel>
              <TabPanel>
                <AnalyticLocationsScreen target="user" targetId={this.state.userId} />
              </TabPanel>
              <TabPanel>
                <ManageTab userId={this.state.userId} user={this.state.user} onProfileUpdated={this.handleUpdatedProfile} />
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </div>
    );
  }

  private handleUpdatedProfile(profileInfo: any) {
    const firstName = profileInfo.firstName ? profileInfo.firstName : this.state.firstName;
    const lastName = profileInfo.lastName ? profileInfo.lastName : this.state.lastName;
    const user = this.state.user;
    user.firstName = firstName; // we honestly could just call get profile again....
    user.lastName = lastName;
    this.setState({
      firstName: firstName,
      lastName: lastName,
      user,
    })
  }

}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserPage) as any
);
