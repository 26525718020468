import { makeCall } from "./client";


export interface IAnomaly {
  id: number;
  created: string;
  lastUpdated: string;
  description: string;
  notes: string;
  category: string;
  sourceId: string;
  sourceName: string;
  status: "entered" | "identified" | "fixed" | "not_anomaly" | "other";
  level: "info" | "warning" | "catastrophic";
}

export const BlankAnomaly: IAnomaly = {
  id: 0,
  created: "",
  lastUpdated: "",
  description: "",
  notes: "",
  category: "",
  sourceId: "",
  sourceName: "",
  status: "entered",
  level: "info",
};

export class LogsAPI {  

  /**
   * Gets a list of anomalies. You can optionally pass in a category, start, and end date
   * @param app 
   * @param options 
   * @returns 
   */
  public getAnomalies = (app: string, options: any = {}) => {
    const data = {
      ...options
    };
    return makeCall('GET', `/admin/${app}/anomalies`, data);
  }

  /**
   * Delete an anomaly
   * @param app 
   * @param anomalyId 
   * @param options 
   * @returns 
   */
  public deleteAnomaly = (app: string, anomalyId: number, options: any = {}) => {
    const data = {
      ...options
    };
    return makeCall('DELETE', `/admin/${app}/anomalies/${anomalyId}`, data);
  }

  /**
   * Update an anomaly
   * @param app 
   * @param anomalyId 
   * @param options 
   * @returns 
   */
  public updateAnomaly = (app: string, anomalyId: number, options: any = {}) => {
    const data = {
      ...options
    };
    return makeCall('PATCH', `/admin/${app}/anomalies/${anomalyId}`, data);
  }

  /**
   * Gets the log files list
   * @param app 
   * @param deviceId 
   * @param query 
   * @param options 
   * @returns 
   */
  public getLogFiles = (app: string, deviceId: number, query: any = {}, options: any = {}) => {
    const data = {
      ...query
    };
    return makeCall('GET', `/admin/${app}/devices/${deviceId}/logs/uploads`, data, options);
  }

  /**
   * Gets the bytes for a file
   * @param app 
   * @param deviceId 
   * @param logId 
   * @param query 
   * @param options 
   * @returns 
   */
  public getLogFileBytes = (app: string, deviceId: number, logId: number, query: any = {}, options: any = {}) => {
    const data = {
      ...query
    };
    return makeCall('GET', `/admin/${app}/devices/${deviceId}/logs/uploads/${logId}`, data, options);
  }

  /**
   * Deletes a file
   * @param app 
   * @param deviceId 
   * @param logId 
   * @param query 
   * @param options 
   * @returns 
   */
  public deleteLogFile = (app: string, deviceId: number, logId: number, query: any = {}, options: any = {}) => {
    const data = {
      ...options
    };
    return makeCall('DELETE', `/admin/${app}/devices/${deviceId}/logs/uploads/${logId}`, data);
  }

}
