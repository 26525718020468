import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as UserActions from "src/reducers/userReducer";
import { BlankProfile, IProfile } from "src/api/profile";
import {Alert, Card} from "src/components/structure";
import { ProfileAPI } from "src/api";

interface IOTPManagementProps {
  profile: IProfile;
  userState: any;
}

interface IOTPManagementState {
  loading: boolean;
  profile: IProfile;
  step: string; // "none" | "pendingAuthenticator" | "pendingSMS" | "active";
  recoveryCodes: string[];
  passcode: string;
  secret: string;
  image64: string;
}

class IOTPManagement extends Component<IOTPManagementProps, IOTPManagementState> {
  constructor(props: IOTPManagementProps) {
    super(props);

    this.state = {
      loading: false,
      profile: BlankProfile,
      step: "none",
      recoveryCodes: [],
      passcode: "",
      secret: "",
      image64: "",

    };

    this.updateField = this.updateField.bind(this);
    this.fetchProfile = this.fetchProfile.bind(this);
    this.beginAuthenticatorSetup = this.beginAuthenticatorSetup.bind(this);
    this.validateAuthenticator = this.validateAuthenticator.bind(this);
    this.removeAuthenticator = this.removeAuthenticator.bind(this);

  }

  componentDidMount() {
    this.fetchProfile();
  }

  render() {
    return (
      <Card title="Manage OTP / 2FA" loading={this.state.loading}>
        {this.getStep()}
      </Card>
    );
  }

  getStep() {
    if (this.state.step === "none") {
      return (
        <div>
          <p>You do not have an authenticator set up on your account. This will soon become mandatory for all accounts to have access to this tool.</p>
          <p>We currently only support 2FA using the industry standard Time-based One Time Password. You will need to use a supported mobile application, such as Google Authenticator (available on both the <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_US&gl=US">Android</a> and <a href="https://apps.apple.com/us/app/google-authenticator/id388497605">iOS</a> app stores.</p>
          <button className="btn btn-block btn-primary" onClick={this.beginAuthenticatorSetup}>Begin Authenticator Set Up</button>
        </div>);
    }
    if (this.state.step === "pendingAuthenticator") {
      return (
        <div>
          <div className="row">
            <div className="col-12">
              <p>Using your selected authenticator, either enter the secret key or scan the QR code. This will add the configuration on your device. When done, enter the displayed passcode and hit the Verify button to confirm you properly set up your authenticator.</p>
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <strong>Secret:</strong>
            </div>
            <div className="col-9">
              <strong>{this.state.secret}</strong>
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <strong>QR:</strong>
            </div>
            <div className="col-9">
              <strong><img src={`data:image/png;base64, ${this.state.image64}`} alt="QR Code for OTP validation" /></strong>
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <strong>Passcode:</strong>
            </div>
            <div className="col-9">
              <input type="text" className="form-control" id="passcode" value={this.state.passcode} onChange={this.updateField} />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <button className="btn btn-block btn-primary" onClick={this.validateAuthenticator}>Verify</button>
          </div>
          </div>
        </div>
      );
    }
    if (this.state.step === "success") {
      return (
        <div>
          <div className="row">
            <div className="col-12">
              <p>You have successfully added an authenticator onto your account. Below are 3 recovery codes you can use if your authenticator is lost or stolen. This will allow you to gain access to your account. Each is a one-time-use code and will be deleted if used. If you use a code, you should delete the authenticator and re-enable your 2FA with a different device.</p>
              <p><strong>Copy these now! You will NOT be able to see them again!</strong></p>
            </div>
          </div>
          {this.state.recoveryCodes.map((code: string) => {
            return (
              <div className="row" key={code}>
                <div className="col-12">
                  <strong>{code}</strong>
                </div>
              </div>
            );
          })}
        </div>
      );
    }
    if (this.state.step === "active") {
      return (
        <div>
          <div className="row">
            <div className="col-12">
              <p>You have an active authenticator on your account. If you want to change devices, you can delete your authenticator and go through the set up again.</p>
              <p><strong>Please note: All users of this tool are expected to have an active authenticator set up. Failure to maintain 2FA is grounds for account deactivation at any time.</strong></p>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <button className="btn btn-danger btn-block" onClick={this.removeAuthenticator}>Remove Authenticator</button>
            </div>
          </div>

        </div>
      );
    }
  }

  updateField(e: any) {
    let ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private fetchProfile() {
    this.setState({ loading: true }, async () => {
      try {
        const profileResult = await ProfileAPI.getProfile();
        const profile = profileResult.body.data;
        let step = "none";
        if (profile.otpStatus === "authenticator") {
          step = "active";
        }

        this.setState({ loading: false, profile, step });
      } catch (err) {
        this.setState({ loading: true });
      }
    })
  }

  private beginAuthenticatorSetup() {
    this.setState({ loading: true }, async () => {
      try {
        const result = await ProfileAPI.startOTPEnrollment();
        this.setState({
          loading: false,
          secret: result.body.data.secret,
          image64: result.body.data.image64,
          step: "pendingAuthenticator",
        })
      } catch (err) {
        Alert.error("We could not set up an authenticator for you. Please contact an administrator.");
      }
    });
  }

  private validateAuthenticator() {
    this.setState({loading: true}, async () => {
      try{
        const validateResult = await ProfileAPI.verifyOTP(this.state.passcode);
        const recoveryCodes = validateResult.body.data.recoveryCodes;
        this.setState({ loading: false, passcode: "", image64: "", secret: "", step: "success", recoveryCodes});
      }catch(err){
        Alert.error("That was not correct. Please try again. If it continues to fail, please contact an administrator.");
        this.setState({ loading: false });
      }
    })
  }
  
  private removeAuthenticator() {
    this.setState({loading: true}, async () => {
      try{
        await ProfileAPI.removeOTP();
        this.setState({ loading: false, step: "none"});
      }catch(err){
        Alert.error("We could not remove that authenticator from your account.");
        this.setState({ loading: false });
      }
    })
  }
}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(IOTPManagement);
