import * as React from "react";
import * as Converter from "react-showdown";


interface IMarkdownTextareaProps {
  content: string;
  showToggle?: boolean;
  showHelp?: boolean;
  onChange?: any;
  mode: "edit" | "view" | "help";
  rows?: number;
}

interface IMarkdownTextareaState {
  loading: boolean;
  mode: "edit" | "view" | "help";
  previousMode: "edit" | "view" | "help";
}

export default class MarkdownTextarea extends React.Component<IMarkdownTextareaProps, IMarkdownTextareaState> {

  private converter: any;

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      mode: "view",
      previousMode: "view"
    };
    this.converter = new Converter.Converter({
      tables: true,
      simplifiedAutoLink: true,
      strikethrough: true,
      tasklists: true
    });
    this.updateText = this.updateText.bind(this);
    this.toggleMode = this.toggleMode.bind(this);
    this.toggleHelp = this.toggleHelp.bind(this);
  }

  public componentDidMount(){
    this.setState({
      mode: this.props.mode ? this.props.mode : "view",
      previousMode: this.props.mode ? this.props.mode : "view",
    });
  }

  public render(){

    return (
      <div>
        <div>
          <div className="row">
            <div className="col-md-12 pull-right" style={{textAlign: "right"}}>
              {this.props.showToggle && (<span style={{color: "darkblue", cursor: "pointer"}} onClick={this.toggleMode}>{this.state.mode === "edit" ? "Preview" : "Edit"}</span>)}
              {this.props.showHelp && this.state.mode === "edit" && (<i className="oi oi-question-mark icon-help" onClick={this.toggleHelp}/>)}
            </div>
          </div>
        </div>
        {this.getContent()}
      </div>
    );
  }

  public getContent() {
    if(this.state.mode === "view"){
      const html = this.converter.convert(this.props.content);
      return html;
    } else if (this.state.mode === "help"){
      return (
        <div className="markdown-help-container">
          <div>
            This editor uses <a target="_new" href="https://commonmark.org/help/">Markdown</a> for entering text.
          </div>
          <div>
            <span style={{color: "darkblue", cursor: "pointer"}} onClick={this.toggleHelp}>Close</span>
          </div>
        </div>
      );
    }
    return (
      <textarea className="form-control" value={this.props.content} onChange={this.updateText} rows={this.props.rows ? this.props.rows : 16} />
    );
  }

  private toggleMode(){
    this.setState({
      mode: this.state.mode === "edit" ? "view" : "edit"
    });
  }

  private toggleHelp(){
    this.setState({
      mode: this.state.mode === "help" ? this.state.previousMode : "help",
      previousMode: this.state.mode,
    });
  }

  private updateText(e: any){
    this.props.onChange(e.target.value);
  }

}