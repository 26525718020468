
const graphColors: string[] = [
  "#FFCDD2",
  "#D1C4E9",
  "#03A9F4",
  "#4CAF50",
  "#009688",
  "#33691E",
  "#B71C1C",
  "#AFB42B",
  "#C5CAE9",
  "#FBC02D",
  "#00BCD4",
  "#FF6D00",
  "#880E4F",
  "#F8BBD0",
  "#BF360C",
  "#AEEA00",
  "#2196F3",
  "#FF6F00",
  "#F57F17",
  "#1B5E20",
  "#E1BEE7",
  "#5D4037",
  "#212121",
  "#0097A7",
  "#00796B",
];

export const getGraphColorByIndex = (index: number): string => {
  if(index > graphColors.length){
    index = index - graphColors.length;
  }
  return graphColors[index]
}