import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import Card from "src/components/structure/Card";
import Screen from "src/components/structure/Screen";
import * as AppActions from "src/reducers/appReducer";
import { DeviceAPI } from "src/api";

import OTAUpload from "./OTATabs/OTAUpload";
import OTACopy from "./OTATabs/OTACopy";

interface IOTAManagementProps {
  appActions: any;
}

interface IOTAManagementState {
  loading: boolean;
  otaChannels: string[];
  selectedDeviceType: "collar" | "healthBox" | "bowl";
  selectedProductFamily: "23" | "24" | "25" | "26" | "30" | "31";
}

class OTAManagement extends React.Component<
  IOTAManagementProps,
  IOTAManagementState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      otaChannels: [],
      selectedDeviceType: "collar",
      selectedProductFamily: "23",
    };

    this.updateField = this.updateField.bind(this);
    this.fetchChannels = this.fetchChannels.bind(this);
  }

  componentDidMount() {
    this.fetchChannels();
  }

  public render() {
    return (
      <Screen fileName="OTAManagement.tsx" requiredRoles={["backend"]}>
        <Card style={{ marginBottom: 20 }}>
          <div className="row">
            <div className="col-4">
              <div className="form-group">
                <label>Device Type</label>
                <select
                  className="form-control"
                  id="selectedDeviceType"
                  value={this.state.selectedDeviceType}
                  onChange={this.updateField}
                >
                  <option value="collar">Collar</option>
                  <option value="healthBox">Health Box</option>
                  <option value="bowl">Bowl</option>
                </select>
              </div>
            </div>
            <div className="col-4">
              <div className="form-group">
                <label>Product Family</label>
                {this.state.selectedDeviceType === "collar" && (
                  <select
                    className="form-control"
                    id="selectedProductFamily"
                    value={this.state.selectedProductFamily}
                    onChange={this.updateField}
                  >
                    <option value="23">Freedom</option>
                    <option value="24">Collar 24</option>
                    <option value="25">Tracking</option>
                    <option value="30">Connect</option>
                  </select>
                )}
                {this.state.selectedDeviceType === "healthBox" && (
                  <select
                    className="form-control"
                    id="selectedProductFamily"
                    value={this.state.selectedProductFamily}
                    onChange={this.updateField}
                  >
                    <option value=""></option>
                    <option value="26">Health Box</option>
                  </select>
                )}
                {this.state.selectedDeviceType === "bowl" && (
                  <select
                    className="form-control"
                    id="selectedProductFamily"
                    value={this.state.selectedProductFamily}
                    onChange={this.updateField}
                  >
                    <option value=""></option>
                    <option value="31">Bowl</option>
                  </select>
                )}
              </div>
            </div>
          </div>
        </Card>
        <div className="row">
          <div className="col-12">
            <Tabs>
              <TabList>
                <Tab>Upload to Channel</Tab>
                <Tab>Copy from Channel</Tab>
              </TabList>
              <TabPanel>
                <OTAUpload
                  deviceType={this.state.selectedDeviceType}
                  productFamily={this.state.selectedProductFamily}
                  channels={this.state.otaChannels}
                />
              </TabPanel>
              <TabPanel>
                <OTACopy
                  deviceType={this.state.selectedDeviceType}
                  productFamily={this.state.selectedProductFamily}
                  channels={this.state.otaChannels}
                />
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </Screen>
    );
  }

  private updateField(e: any) {
    const ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns, () => this.fetchChannels());
  }

  private fetchChannels() {
    this.setState({ loading: false }, async () => {
      try {
        const result = await DeviceAPI.getAvailableOTAChannels(
          this.state.selectedDeviceType,
          this.state.selectedProductFamily
        );
        this.setState({ loading: false, otaChannels: result.body.data });
      } catch (err) {
        this.setState({ loading: true });
      }
    });
  }
}

const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OTAManagement) as any
);
