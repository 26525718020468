import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import { Modal } from "react-bootstrap";

import Card from "src/components/structure/Card";
import Screen from "src/components/structure/Screen";
import DatePicker from "src/components/structure/DatePicker";
import Table from "src/components/structure/Table";
import * as AppActions from "src/reducers/appReducer";
import { error } from "src/components/structure/Alert";
import { LogsAPI } from "src/api";
import { BlankAnomaly, IAnomaly } from "src/api/logs";

interface IAnomaliesProps {
  appActions: any;
}

interface IAnomaliesState {
  loading: boolean;
  selectedCategory: string;
  selectedStatus: "all" | "entered" | "identified" | "fixed" | "not_anomaly" | "other";
  selectedLevel: "all" | "info" | "warning" | "catastropic";
  availableCategories: string[];
  allAnomalies: IAnomaly[];
  filteredAnomalies: IAnomaly[];
  selectedAnomaly: IAnomaly;
  showManageModal: boolean;
  showDeleteModal: boolean;
  start: moment.Moment;
  end: moment.Moment;
}

const columns = [
  {
    label: "Level",
    field: "level",
  },
  {
    label: "Category",
    field: "category",
  },
  {
    label: "Created",
    field: "created",
  },
  {
    label: "Updated",
    field: "lastUpdated",
  },
  {
    label: "Source",
    field: "sourceName",
  },
  {
    label: "Source ID",
    field: "sourceId",
  },
  {
    label: "Status",
    field: "status",
  },
  {
    label: "Actions",
    field: "actions",
  },
];

class Anomalies extends React.Component<IAnomaliesProps, IAnomaliesState> {

  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      selectedCategory: "all",
      selectedStatus: "all",
      selectedLevel: "all",
      availableCategories: ["all"],
      allAnomalies: [],
      filteredAnomalies: [],
      selectedAnomaly: BlankAnomaly,
      showManageModal: false,
      showDeleteModal: false,
      start: moment().subtract(14, "days"),
      end: moment().add(1, 'day'),
    };

    this.updateField = this.updateField.bind(this);
    this.updateAnomalyField = this.updateAnomalyField.bind(this);
    this.updateStart = this.updateStart.bind(this);
    this.updateEnd = this.updateEnd.bind(this);
    this.toggleManageModal = this.toggleManageModal.bind(this);
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.fetch = this.fetch.bind(this);
    this.processAnomalies = this.processAnomalies.bind(this);
    this.updateAnomaly = this.updateAnomaly.bind(this);
    this.deleteAnomaly = this.deleteAnomaly.bind(this);
  }

  componentDidMount() {
    this.fetch();
  }

  public render() {
    return (
      <Screen fileName="Anomalies.tsx" requiredRoles={["backend"]}>
        <div className="row" style={{ marginBottom: 20 }}>
          <div className="col-12">
            <Card>
              <div className="row">
                <div className="col-3">
                  <label htmlFor="selectedCategory">Show Category</label>
                  <select id="selectedCategory" className="form-control" value={this.state.selectedCategory} onChange={this.updateField}>
                    <option value="all">Show All</option>
                    {this.state.availableCategories.map((cat: string) => {
                      return (<option value={cat} key={cat}>{cat}</option>)
                    })}
                  </select>
                </div>
                <div className="col-3">
                  <label htmlFor="selectedStatus">Show Status</label>
                  <select id="selectedStatus" className="form-control" value={this.state.selectedStatus} onChange={this.updateField}>
                    <option value="all">Show All</option>
                    <option value="entered">Entered</option>
                    <option value="identified">Identified</option>
                    <option value="fixed">Fixed</option>
                    <option value="not_anomaly">Not Anomaly</option>
                    <option value="other">Other</option>
                  </select>
                </div>
                <div className="col-2">
                  <label htmlFor="selectedLevel">Show Level</label>
                  <select id="selectedLevel" className="form-control" value={this.state.selectedLevel} onChange={this.updateField}>
                    <option value="all">Show All</option>
                    <option value="info">Info</option>
                    <option value="warning">Warning</option>
                    <option value="catastrophic">Catastrophic</option>
                  </select>
                </div>
                <div className="col-2">
                  <label htmlFor="start">Start</label>
                  <DatePicker date={this.state.start} onDateSaved={this.updateStart} />
                </div>
                <div className="col-2">
                  <label htmlFor="End">End</label>
                  <DatePicker date={this.state.end} onDateSaved={this.updateEnd} />
                </div>
              </div>
            </Card>
          </div>
        </div>

        <div className="row" style={{ marginBottom: 20 }}>
          <div className="col-12">
            <Table
              title="Anomalies"
              csvFileName={`anomalies-${this.state.selectedCategory}-${this.state.start.format("YYYY-MM-DDTHH:mm:ss")}-${this.state.end.format("YYYY-MM-DDTHH:mm:ss")}`}
              showDownload={true}
              columns={columns}
              rows={this.state.filteredAnomalies}
            />
          </div>
        </div>


        <Modal show={this.state.showManageModal} onHide={this.toggleManageModal} dialogClassName="modal-large">
          <Modal.Header closeButton>
            <Modal.Title>
              Viewing Anomaly
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row" style={{ marginBottom: 10 }}>
              <div className="col-4">
                <label style={{ fontWeight: "bold" }}>Category</label>
                <p>{this.state.selectedAnomaly.category}</p>
              </div>
              <div className="col-4">
                <label style={{ fontWeight: "bold" }}>Created</label>
                <p>{this.state.selectedAnomaly.created}</p>
              </div>
              <div className="col-4">
                <label style={{ fontWeight: "bold" }}>Last Updated</label>
                <p>{this.state.selectedAnomaly.lastUpdated}</p>
              </div>
            </div>

            <div className="row" style={{ marginBottom: 10 }}>
              <div className="col-4">
                <label style={{ fontWeight: "bold" }}>Source</label>
                <p>{this.state.selectedAnomaly.sourceName}</p>
              </div>
              <div className="col-4">
                <label style={{ fontWeight: "bold" }}>Source ID</label>
                <p>{this.state.selectedAnomaly.sourceId}</p>
              </div>
              <div className="col-4">
                <label style={{ fontWeight: "bold" }}>Status</label>
                <select className="form-control" id="status" value={this.state.selectedAnomaly.status} onChange={this.updateAnomalyField}>
                  <option value="entered">Entered</option>
                  <option value="identified">Identified</option>
                  <option value="fixed">Fixed</option>
                  <option value="not_anomaly">Not Anomaly</option>
                  <option value="other">Other</option>
                </select>
              </div>
            </div>

            <div className="row" style={{ marginBottom: 10 }}>
              <div className="col-6">
                <label style={{ fontWeight: "bold" }}>Provided Description</label>
                <textarea className="form-control" id="description" disabled={true}>{this.state.selectedAnomaly.description}</textarea>
              </div>
              <div className="col-6">
                <label style={{ fontWeight: "bold" }}>Notes</label>
                <textarea className="form-control" id="notes" value={this.state.selectedAnomaly.notes} onChange={this.updateAnomalyField} />
              </div>
            </div>

          </Modal.Body>
          <Modal.Footer>
            <div className="row">
              <div className="col-4">
                <button className="btn btn-primary" onClick={this.updateAnomaly}>Update</button>
              </div>
              <div className="col-4">
                <button className="btn btn-danger" onClick={this.toggleDeleteModal}>Delete</button>
              </div>
              <div className="col-4">
                <button className="btn btn-default" onClick={this.toggleManageModal}>Close</button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>


        <Modal show={this.state.showDeleteModal} onHide={this.toggleDeleteModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              Deleting Anomaly
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <strong>Warning:</strong> You are about to delete the anomaly created on {moment(this.state.selectedAnomaly.created).format("YYYY-MM-DDTHH:mm:ss")} with the category of {this.state.selectedAnomaly.category}. This cannot be undone. Consider changing the status instead.
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-danger" onClick={this.deleteAnomaly}>Delete</button>
            <button className="btn btn-default" onClick={this.toggleDeleteModal}>Nevermind</button>
          </Modal.Footer>
        </Modal>

      </Screen>
    );
  }

  private updateField(e: any) {
    const ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns, this.processAnomalies);
  }

  private updateAnomalyField(e: any) {
    const ns = this.state.selectedAnomaly;
    ns[e.target.id] = e.target.value;
    this.setState({ selectedAnomaly: ns });
  }

  private updateStart(newDate: moment.Moment) {
    const ns: IAnomaliesState = this.state;
    ns.start = newDate;
    this.setState(ns, () => this.fetch());
  }

  private updateEnd(newDate: moment.Moment) {
    const ns: IAnomaliesState = this.state;
    ns.end = newDate;
    this.setState(ns, () => this.fetch());
  }

  private toggleManageModal() {
    this.setState({ showManageModal: !this.state.showManageModal });
  }

  private toggleDeleteModal() {
    this.setState({ showDeleteModal: !this.state.showDeleteModal });
  }

  private fetch() {
    this.setState({ loading: false }, async () => {
      const data: any = {
        start: this.state.start.format("YYYY-MM-DDTHH:mm:ss") + "Z",
        end: this.state.end.format("YYYY-MM-DDTHH:mm:ss") + "Z",
      };
      try {
        const result = await LogsAPI.getAnomalies("wagz2", data);
        this.setState({ allAnomalies: result.body.data }, () => { this.processAnomalies() })
      } catch (err) {
        error("Could not fetch anomalies in that range");
        this.setState({ loading: false });
      }
    })
  }

  private processAnomalies() {
    // we need ot get all of the anomalies, then filter out things that don't match the selected category
    // we also need to rebuild the available categories in the list of all
    this.setState({ loading: true }, () => {
      const availableCategoriesMap: any = {};
      const filteredAnomalies: IAnomaly[] = [];

      for (const a of this.state.allAnomalies) {
        availableCategoriesMap[a.category] = true;
        const categoryGood = this.state.selectedCategory === "all" || a.category === this.state.selectedCategory;
        const statusGood = this.state.selectedStatus === "all" || a.status === this.state.selectedStatus;
        const levelGood = this.state.selectedLevel === "all" || a.level === this.state.selectedLevel;
        if (statusGood && categoryGood && levelGood) {
          const data = {
            ...a,
            actions: (
              <button className="btn btn-primary" onClick={() => {
                this.setState({ selectedAnomaly: a, showManageModal: true })
              }}>Manage</button>
            )
          }
          filteredAnomalies.push(data);
        }
      }
      const availableCategories: string[] = Object.keys(availableCategoriesMap);
      this.setState({ loading: false, availableCategories, filteredAnomalies })
    })
  }

  private deleteAnomaly() {
    this.setState({ loading: true }, async () => {
      try {
        await LogsAPI.deleteAnomaly("wagz2", this.state.selectedAnomaly.id);
        this.setState({
          showDeleteModal: false,
          showManageModal: false
        }, this.fetch);
      } catch (err) {
        error("Could not delete that anomaly. Contact the admins.")
      }
    });
  }

  private updateAnomaly() {
    this.setState({ loading: true }, async () => {
      try {
        await LogsAPI.updateAnomaly("wagz2", this.state.selectedAnomaly.id, this.state.selectedAnomaly);
        this.setState({
          showManageModal: false
        }, this.fetch);
      } catch (err) {
        error("Could not update that anomaly. Contact the admins.")
      }
    });
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Anomalies) as any);