import * as React from "react";
import { Cell, Legend, Pie, PieChart as PC, ResponsiveContainer, Tooltip } from "recharts";
import { getGraphColorByIndex } from "./Colors";

export interface IPieChartDataPoint {
  count: number;
  key: string;
}

interface IPieChartProps {
  data: IPieChartDataPoint[];
  hideLegend?: boolean;
  loading: boolean;
  minHeight?: number;
  height?: number;
}

interface IPieChartState {
  total: number;
}

export class PieChart extends React.Component<IPieChartProps, IPieChartState> {

  constructor(props: IPieChartProps){
    super(props);

    this.state = {
      total: 0,
    };
  }

  componentDidMount(){
    let total = 0;
    for(const d of this.props.data){
      total += d.count;
    }
    this.setState({ total });
  }

  public render() {
    if(this.props.loading){
      return (
        <div style={{textAlign: "center"}}>
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )
    }
    return (
      <div>
        <div className="row">
          <div className="col-12">
            <ResponsiveContainer width="100%" height="100%" minHeight={this.props.minHeight ? this.props.minHeight : 300}>
              <PC>
                <Pie data={this.props.data} dataKey="count" nameKey="key" cx="50%" cy="50%" innerRadius={0} outerRadius={90} fill="#82ca9d" label={true}>
                  {this.props.data.map((d, i) => {
                    return <Cell key={i} fill={getGraphColorByIndex(i)} />
                  })}
                </Pie>
                <Tooltip />
                {!this.props.hideLegend  && (<Legend />)}
              </PC>
            </ResponsiveContainer>
          </div>
        </div>

        <div className="row">
          <div className="col-3 offset-9">
            <strong>Total: </strong>{this.state.total}
          </div>
        </div>
      </div>
    );
  }

}