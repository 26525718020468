import React, { Component } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { Popover, OverlayTrigger } from "react-bootstrap";
import { MetricsAPI } from "src/api";
import Table from "src/components/structure/Table";
import * as Alert from "src/components/structure/Alert";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { Modal } from "react-bootstrap";

import { events } from "src/utils/items";
import Card from "src/components/structure/Card";
import { styles } from "src/styles";
import moment from "moment";
import { DatePicker } from ".";

interface IEventsTabProps {
  accessLevel: any;
  loading: boolean;
  deviceId: number;
  petId: number;
  userId: number;
  eventsFor: "pet" | "user" | "device";
}

interface IEventsTabState {
  loading: boolean;
  event: string;
  eventChosen: boolean;

  allEvents: any[];
  filteredEvents: any[],
  knownEvents: string[];
  start: moment.Moment;
  end: moment.Moment;
  filter: string;

  allSystemEvents: any[];
  filteredSystemEvents: any[];
  knownSystemEvents: string[];
  systemStart: moment.Moment;
  systemEnd: moment.Moment;
  systemFilter: string;

  selectedEvent: any;
  showSelectedEventModal: boolean;
}


const hoverMessage = (
  <p>
    Hover over the event name to see the message for that event.
  </p>
);

export default class EventsTab extends Component<
  IEventsTabProps,
  IEventsTabState
> {
  constructor(props: IEventsTabProps) {
    super(props);

    this.state = {
      loading: false,
      event: "",
      eventChosen: false,

      allEvents: [],
      filteredEvents: [],
      start: moment().subtract(7, "days"),
      end: moment(),
      filter: "",
      knownEvents: [],
      
      allSystemEvents: [],
      filteredSystemEvents: [],
      systemStart: moment().subtract(7, "days"),
      systemEnd: moment(),
      systemFilter: "",
      knownSystemEvents: [],

      selectedEvent: {},
      showSelectedEventModal: false,
    };

    this.updateField = this.updateField.bind(this);
    this.getEvents = this.getEvents.bind(this);
    this.queueNewEvent = this.queueNewEvent.bind(this);
    this.setEnd = this.setEnd.bind(this);
    this.setStart = this.setStart.bind(this);
    this.setSystemEnd = this.setSystemEnd.bind(this);
    this.setSystemStart = this.setSystemStart.bind(this);
    this.refreshEvents = this.refreshEvents.bind(this);
    this.updateFilter = this.updateFilter.bind(this);
    this.filter = this.filter.bind(this);
    this.toggleShowSelectedEventModal = this.toggleShowSelectedEventModal.bind(this);
  }

  componentDidMount() {
    this.getEvents();
  }

  render() {
    return (
      <div className="row">
        <div className="col-sm-12 col-lg-3" style={{ marginBottom: 15 }}>
          <Card
            title="Queue Event"
            loading={this.props.loading || this.state.loading}
          >
            <div className="center">
              <p style={{ fontSize: 14, textAlign: "center" }}>
                <strong>Select the Event you would like to queue:</strong>
              </p>
              <div style={{ textAlign: "center" }}>
                <p style={{ fontSize: 16 }}>
                  {this.state.eventChosen && (
                    <span style={styles.actionWord}>{this.state.event}</span>
                  )}
                </p>
              </div>
            </div>
            <div className="form-group">
              <Select
                options={events}
                isDisabled={this.props.accessLevel === "read_only"}
                isMulti={false}
                onChange={this.handleChange}
                value={this.state.event}
              />
            </div>
            <div className="form-group" style={{ marginTop: 20 }}>
              {this.props.accessLevel === "read_only" ? (
                <button className="btn btn-block btn-primary" disabled={true}>
                  You do not have permission to do this
                </button>
              ) : (
                <button
                  className="btn btn-block btn-primary"
                  onClick={this.queueNewEvent}
                >
                  Queue Event
                </button>
              )}
            </div>
          </Card>
        </div>
        <div className="col-sm-12 col-lg-5">
            <Table
              title="Shown Events"
              loading={this.props.loading || this.state.loading}
              showDownload={true}
              csvFileName={`eventsFor-deviceId-${this.props.deviceId}`}
              columns={
                this.props.eventsFor === "user"
                  ? userEventCols
                  : this.props.eventsFor === "pet"
                  ? petEventCols
                  : deviceEventsCols
              }
              rows={this.state.filteredEvents}
              preElement={(
                <>
                  <div className="row">
                    <div className="col-12">
                      {hoverMessage}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <label>From</label>
                      <DatePicker date={this.state.start} onDateSaved={this.setStart} />
                    </div>
                    <div className="col-4">
                      <label>To</label>
                      <DatePicker date={this.state.end} onDateSaved={this.setEnd} />
                    </div>
                    <div className="col-4">
                      <label>Filter</label>
                      <select className="form-control" id="eventsFilter" onChange={this.updateFilter} value={this.state.filter} >
                        <option value="">None</option>
                        {this.state.knownEvents.map((event: string) => {
                          return(
                            <option key={event} value={event}>{event}</option>
                          )
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="row" style={{marginTop: 5, marginBottom: 15}}>
                    <div className="col-12">
                      <button className="btn btn-block btn-primary" onClick={this.refreshEvents}>Refresh</button>
                    </div>
                  </div>
                </>
              )}
            />
        </div>
        <div className="col-sm-12 col-lg-4">
            <Table
              title="System Events"
              loading={this.props.loading || this.state.loading}
              showDownload={true}
              csvFileName={`systemEventsFor-deviceId-${this.props.deviceId}`}
              columns={systemEventsCols}
              rows={this.state.filteredSystemEvents}
              preElement={(
                <>
                  <div className="row">
                    <div className="col-12">
                      {hoverMessage}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <label>From</label>
                      <DatePicker date={this.state.systemStart} onDateSaved={this.setSystemStart} />
                    </div>
                    <div className="col-4">
                      <label>To</label>
                      <DatePicker date={this.state.systemEnd} onDateSaved={this.setSystemEnd} />
                    </div>
                    <div className="col-4">
                      <label>Filter</label>
                      <select className="form-control" id="systemFilter" onChange={this.updateFilter} value={this.state.systemFilter} >
                        <option value="">None</option>
                        {this.state.knownSystemEvents.map((event: string) => {
                          return(
                            <option key={event} value={event}>{event}</option>
                          )
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="row" style={{marginTop: 5, marginBottom: 15}}>
                    <div className="col-12">
                      <button className="btn btn-block btn-primary" onClick={this.refreshEvents}>Refresh</button>
                    </div>
                  </div>
                </>
              )}
            />
        </div>

        <Modal
          show={this.state.showSelectedEventModal}
          onHide={this.toggleShowSelectedEventModal}
          dialogClassName="modal-xl"
        >
          <Modal.Header closeButton>
            <Modal.Title>Event Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row row-margin-bottom">
              <div className="col-2">
                <label>Id</label>
                <input type="text" disabled={true} className="form-control" value={this.state.selectedEvent.id} />
              </div>
              <div className="col-3">
                <label>Domain</label>
                <input type="text" disabled={true} className="form-control" value={this.state.selectedEvent.domain} />
              </div>
              <div className="col-2">
                <label>User</label>
                <input type="text" disabled={true} className="form-control" value={this.state.selectedEvent.userId} />
              </div>
              <div className="col-2">
                <label>Pet</label>
                <input type="text" disabled={true} className="form-control" value={this.state.selectedEvent.petId} />
              </div>
              <div className="col-2">
                <label>Device</label>
                <input type="text" disabled={true} className="form-control" value={this.state.selectedEvent.deviceId} />
              </div>
            </div>

            <div className="row row-margin-bottom">
              <div className="col-2">
                <label>Visibility</label>
                <input type="text" disabled={true} className="form-control" value={this.state.selectedEvent.visibility} />
              </div>
              <div className="col-3">
                <label>Occurred</label>
                <input type="text" disabled={true} className="form-control" value={this.state.selectedEvent.time} />
              </div>
              <div className="col-3">
                <label>Received</label>
                <input type="text" disabled={true} className="form-control" value={this.state.selectedEvent.receivedOn} />
              </div>
              <div className="col-2">
                <label>Category</label>
                <input type="text" disabled={true} className="form-control" value={this.state.selectedEvent.category} />
              </div>
              <div className="col-2">
                <label>Dismissed</label>
                <input type="text" disabled={true} className="form-control" value={this.state.selectedEvent.dismissed} />
              </div>
            </div>

            <div className="row row-margin-bottom">
              <div className="col-6">
                <label>Message</label>
                <textarea disabled={true} className="form-control" value={this.state.selectedEvent.message} />
              </div>
              <div className="col-6">
                <label>Additional Data</label>
                <pre>{JSON.stringify(this.state.selectedEvent.additionalData, null, 4)} </pre>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-primary"
              onClick={this.toggleShowSelectedEventModal}
            >
              Done
            </button>
          </Modal.Footer>
        </Modal>
      </div>      
    );
  }

  updateField(e: any) {
    let ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  handleChange = (e: any) => {
    const selected = e.value;
    this.setState({ event: selected, eventChosen: true });
  };

  toggleShowSelectedEventModal(){
    this.setState({ showSelectedEventModal: !this.state.showSelectedEventModal });
  }

  queueNewEvent() {
    this.setState({ loading: true }, async () => {
      const data = {
        domain: this.state.event,
      };
      try {
        if (this.props.eventsFor === "user") {
          await MetricsAPI.queueEventForUser("wagz", this.props.userId, data);
        } else if (this.props.eventsFor === "device") {
          await MetricsAPI.queueEventForDevice(
            "wagz",
            this.props.userId,
            this.props.deviceId,
            data
          );
        } else if (this.props.eventsFor === "pet") {
          await MetricsAPI.queueEventForPet(
            "wagz",
            this.props.userId,
            this.props.petId,
            data
          );
        }
        this.setState(
          {
            loading: false,
            event: "",
            eventChosen: false,
          },
          () => {
            Alert.success("Event queued! It could take several seconds to minutes in order to appear in the list.");
            this.getEvents();
          }
        );
      } catch (err) {
        return this.setState({ loading: false }, () => {
          Alert.error("We could not queue that event.");
        });
      }
    });
  }

  getEvents() {
    this.setState({ loading: true }, async () => {
      try {
        let rawEvents: any[] = [];
        let rawSystemEvents: any[] = [];
        const options = {
          start: this.state.start.utc().format("YYYY-MM-DDTHH:mm:ss") + "Z",
          end: this.state.end.utc().format("YYYY-MM-DD") + "T23:59:59Z",
        };
        const systemOptions = {
          start: this.state.systemStart.utc().format("YYYY-MM-DDTHH:mm:ss") + "Z",
          end: this.state.systemEnd.utc().format("YYYY-MM-DD") + "T23:59:59Z",
        }
        if (this.props.eventsFor === "user") {
          const eventsResult = await MetricsAPI.getEventsForUser(
            "wagz",
            this.props.userId,
            options,
          );
          rawEvents = eventsResult.body.data.events;
          const systemEventsResult = await MetricsAPI.getSystemEventsForUser(
            "wagz",
            this.props.userId,
            systemOptions,
          );
          rawSystemEvents = systemEventsResult.body.data;
        } else if (this.props.eventsFor === "device") {
          const eventsResult = await MetricsAPI.getEventsForDevice(
            "wagz",
            this.props.userId,
            this.props.deviceId,
            options,
          );
          rawEvents = eventsResult.body.data.events;
          const systemEventsResult = await MetricsAPI.getSystemEventsForDevice(
            "wagz",
            this.props.userId,
            this.props.deviceId,
            systemOptions,
          );
          rawSystemEvents = systemEventsResult.body.data;
        } else if (this.props.eventsFor === "pet") {
          const eventsResult = await MetricsAPI.getEventsForPet(
            "wagz",
            this.props.userId,
            this.props.petId,
            options,
          );
          rawEvents = eventsResult.body.data.events;
          const systemEventsResult = await MetricsAPI.getSystemEventsForPet(
            "wagz",
            this.props.userId,
            this.props.petId,
            systemOptions,
          );
          rawSystemEvents = systemEventsResult.body.data;
        }

        rawEvents.sort((a: any, b: any) => {
          return moment(a.time).isBefore(moment(b.time)) ? -1 : 1;
        })
        rawSystemEvents.sort((a: any, b: any) => {
          return moment(a.time).isBefore(moment(b.time)) ? -1 : 1;
        })

        const processedEvents = this.processEvents(rawEvents);
        const processedSystemEvents = this.processEvents(rawSystemEvents);

        const filteredEvents = this.filter(processedEvents.events, this.state.filter);
        const filteredSystemEvents = this.filter(processedSystemEvents.events, this.state.systemFilter);

        this.setState({
          loading: false,
          allEvents: processedEvents.events,
          knownEvents: processedEvents.foundEvents,
          filteredEvents,
          allSystemEvents: processedSystemEvents.events,
          knownSystemEvents: processedSystemEvents.foundEvents,
          filteredSystemEvents,
        });
      } catch (err) {
        console.log(err);
        this.setState({ loading: false });
      }
    });
  }

  private processEvents(events: any[]): any {
    const eventsList = [];
    let eventsMap: any = {};

    for (let event of events) {
      eventsMap[event.domain] = true;
      const pop = (
        <Popover id="popover-trigger-click" title="Message">
          <div style={{ padding: 5 }}>{event.message}</div>
        </Popover>
      );

      // set up any links
      let petLink: any = null;
      let deviceLink: any = null;
      if (event.petId !== 0) {
        petLink = (
          <Link
            to={`/support/users/${this.props.userId}/pets/${event.petId}`}
            style={{ color: "blue", textDecoration: "underline" }}
          >
            {event.petId}
          </Link>
        );
      }
      if (event.deviceId !== 0) {
        deviceLink = (
          <Link
            to={`/support/users/${this.props.userId}/devices/${event.deviceId}`}
            style={{ color: "blue", textDecoration: "underline" }}
          >
            {event.deviceId}
          </Link>
        );
      }
      const domain = event.domain;

      // push
      eventsList.push({
        domain,
        popoverDomain: (
          <OverlayTrigger trigger={["hover", "focus"]} overlay={pop}>
            <span style={{ fontSize: 12 }}>{domain}</span>
          </OverlayTrigger>
        ),
        time: moment.utc(event.time).format("MM/DD/YYYY HH:mm"),
        receivedOn: moment.utc(event.receivedOn).format("MM/DD/YYYY HH:mm"),
        petLink,
        deviceLink,
        view: (
          <FontAwesomeIcon icon={faEye} className="link link-light" onClick={() => {
            this.setState({
              selectedEvent: event,
              showSelectedEventModal: true,
            })
          }} />
        ),
      });
    }
    
    const foundEvents = Object.keys(eventsMap);
    foundEvents.sort((a, b) => {
      return a > b ? 1 : -1;
    })
    return {
      events: eventsList,
      foundEvents,
    };
  }

  private filter(events: any[],  filter: string){
    if(filter === ""){
      return events;
    }
    const filteredEvents = [];
    for(const e of events){
      if(e.domain === filter){
        filteredEvents.push(e);
      }
    }
    return filteredEvents;
  }

  private updateFilter(e: any){
    const source = e.target.id;
    const filter = e.target.value;
    let events = [];
    if(source === "eventsFilter"){
      events = this.filter(this.state.allEvents, filter);
      this.setState({ filteredEvents: events, filter });
    } else {
      events = this.filter(this.state.allSystemEvents, filter);
      this.setState({ filteredSystemEvents: events, systemFilter: filter });
    }
  }


  private setSystemStart(newDate: moment.Moment) {
    this.setState({ systemStart: newDate });
  }

  private setSystemEnd(newDate: moment.Moment) {
    this.setState({ systemEnd: newDate });
  }

  private setStart(newDate: moment.Moment) {
    this.setState({ start: newDate });
  }

  private setEnd(newDate: moment.Moment) {
    this.setState({ end: newDate });
  }

  private refreshEvents(){
    this.getEvents(); // this was originally larger, but leaving this as a call out in case we need more logic on refreshing
  }
}


const deviceEventsCols = [
  {
    label: "Event",
    field: "popoverDomain",
  },
  {
    label: "Time",
    field: "time",
    sort: "desc",
  },
  {
    label: "Received On",
    field: "receivedOn",
  },
  {
    label: "Pet",
    field: "petLink",
  },
  {
    label: "",
    field: "view",
  },
];

const petEventCols = [
  {
    label: "Event",
    field: "popoverDomain",
  },
  {
    label: "Time",
    field: "time",
    sort: "desc",
  },
  {
    label: "Received On",
    field: "receivedOn",
  },
  {
    label: "Device",
    field: "deviceLink",
  },
  {
    label: "",
    field: "view",
  },
];

const userEventCols = [
  {
    label: "Event",
    field: "popoverDomain",
  },
  {
    label: "Time",
    field: "time",
    sort: "desc",
  },
  {
    label: "Received On",
    field: "receivedOn",
  },
  {
    label: "Pet",
    field: "petLink",
  },
  {
    label: "Device",
    field: "deviceLink",
  },
  {
    label: "",
    field: "view",
  },
];

const systemEventsCols = [
  {
    label: "Event",
    field: "popoverDomain",
  },
  {
    label: "Time",
    field: "time",
    sort: "desc",
  },
  {
    label: "",
    field: "view",
  },
];