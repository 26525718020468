import React, { Component } from "react";
import {  withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Screen from "src/components/structure/Screen";
import * as UserActions from "src/reducers/userReducer";

import RefundCalculator from "src/components/structure/RefundCalculator";
import Card from "src/components/structure/Card";

interface IRefundCalculatorScreenProps {
  location: any;
  userActions: any;
  userState: any;
}

interface IRefundCalculatorScreenState {
  loading: boolean;
}

class RefundCalculatorScreen extends Component<IRefundCalculatorScreenProps, IRefundCalculatorScreenState> {
  constructor(props: IRefundCalculatorScreenProps) {
    super(props);

    this.state = {
      loading: false,
    };

    this.updateField = this.updateField.bind(this);
    this.setup = this.setup.bind(this);
  }

  componentDidMount(){
    this.setup();
  }

  render() {
    return (
      <Screen id="RefundCalculatorScreen" requiredRoles={["support", "product", "reporting"]}>
        <div className="row">
          <div className="col-8 offset-2">
            <Card title="Refund Calculator">
              <p>This is a refund calculator available for generating refund amounts with given parameters. If you do not specify a device or plan, it will use the values you provide.</p>
              <RefundCalculator />
            </Card>
          </div>
        </div>
      </Screen>
    )
  }

  updateField(e: any) {
    let ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private setup() {
    this.setState({ loading: true }, async () => {
      try{
        
      }catch(err){
        this.setState({ loading: false });
      }
    })
  }


}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RefundCalculatorScreen) as any
);
