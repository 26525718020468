import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { DeviceAPI } from "src/api";
import { IBatteryCycle } from "src/api/devices";
import Table from "src/components/structure/Table";
import { Modal } from "react-bootstrap";
import * as UserActions from "src/reducers/userReducer";
import { styles } from "src/styles";
import { secondsToHms } from "src/utils/utilities"

interface IBatteryCyclesTabProps {
  deviceId: number;
  userId: number;
  location: any;
  userActions: any;
  userState: any;
}

interface IBatteryCyclesTabState {
  loading: boolean;
  bars: IBatteryCycle[];
  showDrainModal: boolean;
  drainEstimate: any;
}

class BatteryCyclesTab extends Component<
  IBatteryCyclesTabProps,
  IBatteryCyclesTabState
> {
  constructor(props: IBatteryCyclesTabProps) {
    super(props);

    this.state = {
      loading: false,
      bars: [],
      showDrainModal: false,
      drainEstimate: ""
    };

    this.getBatteryCycles = this.getBatteryCycles.bind(this);
    this.getBatteryDrain = this.getBatteryDrain.bind(this);
  }

  componentDidMount() {
    this.getBatteryCycles();
  }

  getBatteryCycles() {
    this.setState({ loading: true }, async () => {
      try {
        const result = await DeviceAPI.getBatteryCyclesForDevice(
          "wagz",
          this.props.userId,
          this.props.deviceId
        );
        const bars: IBatteryCycle[] = [];
        for (const l of result.body.data) {
          const wifiToPercent = l.wifiUsage * 100
          const cellToPercent = l.cellUsage * 100
          l.duration = secondsToHms(l.duration)
          l.wifiUsage = wifiToPercent.toFixed(2)
          l.cellUsage = cellToPercent.toFixed(2)
          l.statusReports = l.statusReports.toFixed(0)
          bars.push(l);
        }
        this.setState({ loading: false, bars });
      } catch (err) {
        this.setState({ loading: false });
      }
    });
  }

  getBatteryDrain() {
    this.setState({ loading: true }, async () => {
      try {
        const result = await DeviceAPI.getBatteryDrainForDevice(
          "wagz",
          this.props.userId,
          this.props.deviceId
        );
        this.setState({ loading: false, drainEstimate: result.body.data, showDrainModal: true });
      } catch (err) {
        this.setState({ loading: false });
      }
    });
  }

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-4" style={{ margin: 10 }}>
            <button
              style={{ color: "white" }}
              className="btn btn-delete btn-block"
              onClick={this.getBatteryDrain}
            >
              Calculate Battery Drain Estimate
            </button>
          </div>
        </div>
        <div>
          <Table
            title={`Battery Cycles for Device ${this.props.deviceId}`}
            csvFileName={`battery-cycles-${this.props.deviceId}`}
            showDownload={true}
            columns={[
              {
                label: "Start Time",
                field: "startTime",
                sort: "asc",
              },
              {
                label: "End Time",
                field: "endTime",
                sort: "asc",
              },
              {
                label: "Duration",
                field: "duration",
                sort: "asc",
              },
              {
                label: "Battery Start Level",
                field: "batteryStartLevel",
                sort: "asc",
              },
              {
                label: "Battery End Level",
                field: "batteryEndLevel",
                sort: "asc",
              },
              {
                label: "Restarts",
                field: "restarts",
                sort: "asc",
              },
              {
                label: "Status Reports",
                field: "statusReports",
                sort: "asc",
              },
              {
                label: "Wifi Usage (%)",
                field: "wifiUsage",
                sort: "asc",
              },
              {
                label: "Cell Usage (%)",
                field: "cellUsage",
                sort: "asc",
              },
            ]}
            rows={this.state.bars}
          />
        </div>
        <div>
          <Modal
            dialogClassName="modal-75"
            centered
            show={this.state.showDrainModal}
            onHide={() => {
              this.setState({ showDrainModal: false });
            }}
          >
            <Modal.Body>
              <div>
                <div style={styles.shadowBox}>
                  <div style={{ margin: 10 }}>
                    <h4>Battery Drain Estimate</h4>
                    <pre>{JSON.stringify(this.state.drainEstimate, null, 2)}</pre>
                  </div>
                </div>
                <div>
                  <button
                    style={{ color: "white", marginTop: 10 }}
                    className="btn btn-warning btn-block"
                    onClick={() => {
                      this.setState({ showDrainModal: false });
                    }}
                  >
                    Close
                  </button>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>
        </div>
      </div>
    );
  }
}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default connect<{}, {}, any>(
  mapStateToProps,
  mapDispatchToProps
)(BatteryCyclesTab);
