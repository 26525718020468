import React, { Component } from "react";

import { capitalizeFirstLetter } from "src/utils/utilities";
import { formatCurrency } from "src/utils/currency";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import { BillingAPI } from "src/api";
import { Alert, MarkdownEditor } from "src/components/structure";

interface IBillingPlanProps {
  key: number;
  loading: boolean;
  plan: any;
  onPlanUpdated: any;
}

interface IBillingPlanState {
  loading: boolean;
  amount: number;
  currency: string;
  description: string;
  display: string;
  id: number;
  term: string;

  disabled: boolean;
}
class BillingPlan extends Component<IBillingPlanProps, IBillingPlanState> {
  constructor(props: any) {
    super(props);
    this.state = {
      loading: this.props.loading,
      amount: this.props.plan.amount,
      currency: this.props.plan.currency,
      description: this.props.plan.description,
      display: this.props.plan.display,
      id: this.props.plan.id,
      term: this.props.plan.term,

      disabled: true
    };

    this.updateDescription = this.updateDescription.bind(this);
    this.updateField = this.updateField.bind(this);
    this.editPlan = this.editPlan.bind(this);
  }

  updateField(e: any) {
    let ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  editPlan() {
    this.setState({ loading: true }, async () => {
      try {
        await BillingAPI.updatePlanByID(this.state.id, {
          display: this.state.display,
          description: this.state.description,
        });
        return this.setState({ loading: false }, () => {
            Alert.success("Notes edited successfully");
            this.props.onPlanUpdated();
          }
        );
      } catch (err) {
        Alert.error("Could not save that edit");
        this.setState({ loading: false });
      }
    });
  }

  private updateDescription(content: any){
    this.setState({ description: content, disabled: false} );
  }

  render() {
    return (
      <div style={{ backgroundColor: "transparent" }}>
        <div key={this.state.id}>
          <Accordion allowZeroExpanded={true} allowMultipleExpanded={true}>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>{this.state.display}</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                  <div>
                    <div style={{ marginLeft: 20, marginTop: 20 }}>
                      <div><strong>Plan ID: </strong>{this.state.id}</div>
                      <div><strong>Amount: </strong>{`${formatCurrency(this.state.amount)} ${this.state.currency} ${capitalizeFirstLetter(this.state.term)}`}</div>
                      <div className="form-group">
                        <label style={{ fontWeight: "bold" }}>Description:</label>
                        <MarkdownEditor
                            content={this.state.description}
                            showToggle={true}
                            showHelp={true}
                            onChange={this.updateDescription}
                            mode="view"
                            rows={5}
                        />
                      </div>
                      <div className="form-group">
                          <button
                          className="btn btn-block btn-success"
                          onClick={this.editPlan}
                          disabled={this.state.disabled}
                          >
                          Update Plan
                          </button>
                      </div>
                    </div>
                  </div>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
        </div>
      </div>
    );
  }
}

export default BillingPlan;
