import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as UserActions from "src/reducers/userReducer";
import { TicketList, Loading } from "src/components/structure";
interface IBackendTicketsProps {
  location: any;
  userActions: any;
  userState: any;
}

interface IBackendTicketsState {
  loading: boolean;
}

class BackendTickets extends Component<
  IBackendTicketsProps,
  IBackendTicketsState
> {
  constructor(props: IBackendTicketsProps) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  render() {
    return this.state.loading ? <Loading /> : <TicketList department="backend" />;
  }
}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BackendTickets) as any
);
