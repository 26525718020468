import React, { Component } from "react";
import {  withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal } from "react-bootstrap";

import Card from "src/components/structure/Card";
import Screen from "src/components/structure/Screen";
import * as Alert from "src/components/structure/Alert";
import * as UserActions from "src/reducers/userReducer";
import { BlankDeviceSecret, IDeviceSecret } from "src/api/devices";
import { DeviceAPI } from "src/api";
import moment from "moment";

interface IGenerateDeviceTokenScreenProps {
  location: any;
  userActions: any;
  userState: any;
}

interface IGenerateDeviceTokenScreenState {
  loading: boolean;
  familyId: "23" | "24";
  productId: string;
  som: string;
  imei: string;
  iccid: string;
  mac: string;
  hardwareId: string;
  otaChannel: string;

  showGeneratedModal: boolean;
  generated: IDeviceSecret;
}

class GenerateDeviceTokenScreen extends Component<IGenerateDeviceTokenScreenProps, IGenerateDeviceTokenScreenState> {
  constructor(props: IGenerateDeviceTokenScreenProps) {
    super(props);

    this.state = {
      loading: false,
      familyId: "23",
      productId: "",
      som: "",
      imei: "",
      iccid: "",
      mac: "",
      hardwareId: "",
      otaChannel: "default",
      showGeneratedModal: false,
      generated: BlankDeviceSecret,
    };

    this.updateField = this.updateField.bind(this);
    this.generateDeviceSecret = this.generateDeviceSecret.bind(this);
    this.toggleShowGeneratedModal = this.toggleShowGeneratedModal.bind(this);
  }


  render() {
    return (
      <Screen id="generateDeviceSecret" requiredRoles={["mobile", "qa", "backend"]}>
        <div className="row">
          <div className="col-8 offset-2">
            <Card title="Generate a New Secret/Fake Device">
              <div className="row">
                <div className="col-6">
                  <p>TThis tool will generate a secret key entry. This is useful in two cases. In the first, you can generate a fake device so you use it when testing or developing. You can also use this to generate a secret key for a one-off device that did not go through the server team for importing. Either way, the secret will be inserted into the database. BE CAREFUL! If you override something, it will be saved. This is bad for customers, so this is closely watched!</p>
                  <p><strong><span className="text-danger">WARNING: </span></strong>If you provide a serial that is in the future, there is a chance it will get overwritten if a legitimate device is manufactured for that serial. We recommend leaving the serial blank and letting the backend generate one.</p>
                  
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label htmlFor="familyId">Device Type</label>
                    <select className="form-control" id="familyId" onChange={this.updateField} value={this.state.familyId}>
                      <option value="23">Freedom Collar</option>
                      <option value="24">Cat Collar</option>
                      <option value="25">Tracking Collar</option>
                      <option value="26">Health Box</option>
                      <option value="30">Connect Collar</option>
                      <option value="31">Bowl</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="familyId">Serial (if provided, must include above family id correctly)</label>
                    <input type="text" className="form-control" id="productId" onChange={this.updateField} value={this.state.productId} />
                  </div>
                  <div className="form-group">
                    <label htmlFor="som">SOM</label>
                    <input type="text" className="form-control" id="som" onChange={this.updateField} value={this.state.som} />
                  </div>
                  <div className="form-group">
                    <label htmlFor="imei">IMEI</label>
                    <input type="text" className="form-control" id="imei" onChange={this.updateField} value={this.state.imei} />
                  </div>
                  <div className="form-group">
                    <label htmlFor="iccid">ICCID</label>
                    <input type="text" className="form-control" id="iccid" onChange={this.updateField} value={this.state.iccid} />
                  </div>
                  <div className="form-group">
                    <label htmlFor="mac">MAC</label>
                    <input type="text" className="form-control" id="mac" onChange={this.updateField} value={this.state.mac} />
                  </div>
                  <div className="form-group">
                    <label htmlFor="hardwareId">Hardware ID</label>
                    <input type="text" className="form-control" id="hardwareId" onChange={this.updateField} value={this.state.hardwareId} />
                  </div>
                  <div className="form-group">
                    <label htmlFor="otaChannel">Starting OTA Channel</label>
                    <input type="text" className="form-control" id="otaChannel" onChange={this.updateField} value={this.state.otaChannel} />
                  </div>
                  <div className="form-group">
                    <button className="btn btn-primary btn-block" onClick={this.generateDeviceSecret}>Generate</button>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
        <Modal show={this.state.showGeneratedModal} onHide={this.toggleShowGeneratedModal} dialogClassName="modal-large">
            <Modal.Header closeButton>
              <Modal.Title>
                Generated Device Secret
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Below is the generated device secret. <strong>IMPORTANT: </strong>Copy the secret information, <strong>especially</strong> the Secret Key as you will not be able to see the secret key again. To get it, you will need to contact a database administrator. Once you close this modal, it is not viewable in the web interface.</p>
              <div className="row">
                <div className="col-6">
                  <div className="row">
                    <div className="col-12" style={{fontWeight: "bold", textAlign: "center"}}>Generated Data</div>
                  </div>
                  <div className="row">
                    <div className="col-3">Serial: </div>
                    <div className="col-9">{this.state.generated.productId}</div>
                  </div>
                  <div className="row">
                    <div className="col-3">OTA Channel: </div>
                    <div className="col-9">{this.state.generated.otaChannel}</div>
                  </div>
                  <div className="row">
                    <div className="col-3">IMEI: </div>
                    <div className="col-9">{this.state.generated.imei}</div>
                  </div>
                  <div className="row">
                    <div className="col-3">ICCID: </div>
                    <div className="col-9">{this.state.generated.iccid}</div>
                  </div>
                  <div className="row">
                    <div className="col-3">SOM: </div>
                    <div className="col-9">{this.state.generated.som}</div>
                  </div>
                  <div className="row">
                    <div className="col-3">MAC: </div>
                    <div className="col-9">{this.state.generated.mac}</div>
                  </div>
                  <div className="row">
                    <div className="col-3">Hardware ID: </div>
                    <div className="col-9">{this.state.generated.hardwareId}</div>
                  </div>
                  <div className="row">
                    <div className="col-3">Secret Key: </div>
                    <div className="col-9">{this.state.generated.secretKey}</div>
                  </div>
                </div>

                <div className="col-6">
                  <div className="row">
                    <div className="col-12" style={{fontWeight: "bold", textAlign: "center"}}>Sample JSON Body</div>
                  </div>
                  <div className="row">
                    <div className="col-12"><span className="small">Note: Depending on the product family, not all of the fields in the JSON body are required. Refer to the API Docs for more information. We simply took all of the generated relevant fields and put them in the body EXCEPT for the product id / serial. This is more for your ease of use.</span></div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <textarea className="form-control" value={this.generateJSONStringFromGenerated()} rows={8} />
                    </div>
                  </div>
                </div>
              </div>
              
            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-block btn-danger" onClick={this.toggleShowGeneratedModal}>Close</button>
            </Modal.Footer>
          </Modal>
      </Screen>
    )
  }

  updateField(e: any) {
    let ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private toggleShowGeneratedModal(){
    this.setState({ showGeneratedModal: !this.state.showGeneratedModal});
  }

  private generateDeviceSecret() {
    const data: IDeviceSecret = {
      app: "wagz2",
      secretKey: "",
      inserted: moment(),
      productId: this.state.productId.trim(),
      som: this.state.som.trim(),
      imei: this.state.imei.trim(),
      iccid: this.state.iccid.trim(),
      mac: this.state.mac.trim().replaceAll(":", ""),
      otaChannel: this.state.otaChannel.trim(),
      familyId: this.state.familyId,
      hardwareId: this.state.hardwareId,
    }
    this.setState({ loading: true }, async () => {
      try{
        const result = await DeviceAPI.generateDeviceSecret(this.state.familyId, data);
        this.setState({ loading: false, showGeneratedModal: true, generated: result.body.data});
      }catch(err){
        Alert.error("Could not generate that secret. You may try again or contact an administrator for support.");
      }
    });
  }

  private generateJSONStringFromGenerated(): string {
    // TODO: eventually, we could probably just make sure this matches what is required for a product family?
    const data: any = {
      familyId: this.state.generated.familyId,
      secretKey: this.state.generated.secretKey,
      imei: this.state.generated.imei,
      iccid: this.state.generated.iccid,
      som: this.state.generated.som,
      mac: this.state.generated.mac,
      hardwareId: this.state.generated.hardwareId,
    };
    return JSON.stringify(data, null, 4)
  }
}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GenerateDeviceTokenScreen) as any
);
