import { makeCall } from "./client";

export class MetricsAPI {
  
  /**
   * Get the current Known Events
   * @param app
   * @param options 
   */
  public getKnownEvents = (options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('GET', `admin/knownEvents`, data);
  }
  
  /**
   * Saves a new Known Event
   * @param app 
   * @param options 
   */
  public saveNewKnownEvent = (options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('POST', `admin/knownEvents`, data);
  }
  
  /**
   * Get a single Known Event
   * @param app
   * @param domain 
   * @param options 
   */
  public getKnownEvent = (domain: string, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('GET', `admin/knownEvents/${domain}`, data);
  }
  
  /**
   * Updates a Known Event by domain
   * @param app 
   * @param domain 
   * @param options 
   */
  public updateKnownEvent = (domain: string, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('PATCH', `admin/knownEvents/${domain}`, data);
  }
  
  /**
   * Deletes a Known Event by domain
   * @param app 
   * @param domain 
   * @param options 
   */
  public deleteKnownEvent = (domain: string, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('DELETE', `admin/knownEvents/${domain}`, data);
  }

  /**
   * Overrides the Known Event cache TTL and flushes it
   * @param options 
   * @returns 
   */
  public clearKnownEventCache = (options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('DELETE', `admin/knownEvents/cache`, data);
  }
  
  /**
   * Get the events for the device
   * @param app 
   * @param userId 
   * @param deviceId 
   * @param options 
   */
  public getEventsForDevice = (app: string, userId: number, deviceId: number, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall("GET", `/admin/${app}/users/${userId}/devices/${deviceId}/events`, data);
  };

  public getEventsForPet = (app: string, userId: number, petId: number, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall("GET", `/admin/${app}/users/${userId}/pets/${petId}/events`, data);
  };

  public getEventsForUser = (app: string, userId: number, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall("GET", `/admin/${app}/users/${userId}/events`, data);
  };
  
  /**
   * Queue a new event for the device
   * @param app 
   * @param userId 
   * @param deviceId 
   * @param options 
   */
  public queueEventForDevice = (app: string, userId: number, deviceId: number, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall("POST", `/admin/${app}/users/${userId}/devices/${deviceId}/events`, data);
  };

  public queueEventForPet = (app: string, userId: number, petId: number, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall("POST", `/admin/${app}/users/${userId}/pets/${petId}/events`, data);
  };

  public queueEventForUser = (app: string, userId: number, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall("POST", `/admin/${app}/users/${userId}/events`, data);
  };

  /**
   * 
   * @param app 
   * @param userId 
   * @param deviceId 
   * @param options 
   * @returns 
   */
  public getSystemEventsForDevice = (app: string, userId: number, deviceId: number, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall("GET", `/admin/${app}/users/${userId}/devices/${deviceId}/events/system`, data);
  };

  /**
   * Get the system-level events for a pet
   * @param app 
   * @param userId 
   * @param petId 
   * @param options 
   * @returns 
   */
  public getSystemEventsForPet = (app: string, userId: number, petId: number, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall("GET", `/admin/${app}/users/${userId}/pets/${petId}/events/system`, data);
  };

  /**
   * Get the system-level events for a user
   * @param app 
   * @param userId 
   * @param options 
   * @returns 
   */
  public getSystemEventsForUser = (app: string, userId: number, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall("GET", `/admin/${app}/users/${userId}/events/system`, data);
  };

  /**
   * Gets all of the events on a platform
   * @param app 
   * @param start 
   * @param end 
   * @param domain 
   * @param count 
   * @param offset 
   * @param options 
   * @returns 
   */
  public getAllEventsOnPlatform = (app: string, start: string, end: string, domain: string, count: number, offset: number, options: any = {}) => {
    const data = {
      ...options,
      start,
      end,
      domain,
      count,
      offset
    };
    return makeCall("GET", `/admin/${app}/events`, data);
  };

  /**
   * Gets the actitivty reports for a breed by the month
   * @param app 
   * @param month 
   * @param options 
   * @returns 
   */
  public getBreedActivityReports = (app: string, month: string, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall("GET", `/admin/${app}/breeds/activities/${month}`, data);
  };

  /**
   * Resets the report. Is very painful in line and should be used cautiously!
   * @param app 
   * @param month 
   * @param options 
   * @returns 
   */
  public resetBreedActivityReports = (app: string, month: string, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall("DELETE", `/admin/${app}/breeds/activities/${month}`, data);
  };

  /**
   * Gets the rankings for a pet for a day
   * @param app 
   * @param userId 
   * @param petId 
   * @param data 
   * @param options 
   * @returns 
   */
  public getRankingsForPet = (app: string, userId: number, petId: number, day: string, data: any = {}, options = {}) => {
    const allData = {
      ...data,
      day,
    }
    return makeCall("GET", `/admin/${app}/users/${userId}/pets/${petId}/rankings`, allData, options);
  };

  /**
   * Gets the platform rankings for a metric on a day
   * @param app 
   * @param metric 
   * @param day 
   * @param data 
   * @param options 
   * @returns 
   */
  public getRankingsForPlatformOnDayByMetric = (app: string, metric: string, day: string, data: any = {}, options = {}) => {
    const allData = {
      ...data,
      day,
    }
    return makeCall("GET", `/admin/${app}/pets/rankings/${metric}`, allData, options);
  };
  
}

export interface IEvent {
  action: string[];
  additionalData: any;
  alert: boolean;
  deviceId: number;
  dismissed: boolean;
  domain: string;
  icon: string;
  id: number;
  media: string;
  mediaType: string;
  message: string;
  persistent: boolean;
  petId: number;
  receivedOn: string;
  time: string;
  type: string;
  userId: number;
  visibility: "user" | "system";
}