import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as AppActions from "src/reducers/appReducer";
import * as UserActions from "src/reducers/userReducer";
import moment from "moment";
import { PetsAPI } from "src/api";
import { IBowlStatistics, BlankBowlStatistics } from "src/api/pets";

interface IBowlStatisticsProps {
  loading: boolean;
  pet: number;
  user: number;
}

interface IDailyMetricsState {
  loading: boolean;
  day: any;
  stats: IBowlStatistics;
}

class BowlStatisticsScreen extends Component<
  IBowlStatisticsProps,
  IDailyMetricsState
> {
  constructor(props: any) {
    super(props);

    this.state = {
      loading: false,
      day: moment(),
      stats: BlankBowlStatistics,
    };

    this.getBowlStats = this.getBowlStats.bind(this);
  }

  componentDidMount() {
    this.getBowlStats();
  }

  getBowlStats() {
    this.setState({ loading: true }, async () => {
      try {
        let petBowlStats = BlankBowlStatistics
        const start = moment(this.state.day).startOf("day").utc().format("YYYY-MM-DDTHH:mm:ss") + "Z";
        const end = moment(this.state.day).endOf("day").utc().format("YYYY-MM-DDTHH:mm:ss") + "Z";
        const res = await PetsAPI.getBowlStatisticsForPet(
          "wagz",
          this.props.user,
          this.props.pet,
          {
            start,
            end
          }
        );
        petBowlStats = res.body.data
        this.setState({
          loading: false,
          stats: petBowlStats,
        });
      } catch (err) {
        this.setState({ loading: false });
      }
    });
  }

  render() {
    return (
      <div style={{ marginBottom: 20 }}>
        
      </div>
    );
  }

}

const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(AppActions, dispatch),
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BowlStatisticsScreen);
