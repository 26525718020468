import React, { Component } from "react";
import { NotificationsAPI } from "src/api";


import Card from "src/components/structure/Card";
import { IUser } from "src/api/users";
import { error, success } from "src/components/structure/Alert";

interface IEmailSubscriptionPreferences {
  email: string;
  receiveAccountUpdates: "yes" | "no";
  receiveMarketing: "yes" | "no";
  receiveOther: "yes" | "no";
  receiveTips: "yes" | "no";
  receiveTransactional: "yes" | "no";
  userId: number;
}

interface IEmailTabPreferencesProps {
  user: IUser;
}

interface IEmailTabPreferencesState {
  loading: boolean;
  preferences: IEmailSubscriptionPreferences;
}

export class EmailTabPreferences extends Component<IEmailTabPreferencesProps, IEmailTabPreferencesState> {
  constructor(props: IEmailTabPreferencesProps) {
    super(props);

    this.state = {
      loading: false,
      preferences: defaultEmailSubscriptionPreferences,
    };

    this.updateField = this.updateField.bind(this);
    this.setup = this.setup.bind(this);
    this.savePreferences = this.savePreferences.bind(this);
  }

  updateField(e: any) {
    let ns: any = this.state.preferences;
    ns[e.target.id] = e.target.value;
    this.setState({ preferences: ns });
  }

  componentDidMount() {
    this.setup();
  }

  render() {
    return (
      <div>
        <Card title="Preferences" loading={this.state.loading}>
          <div className="row" style={{ marginBottom: 15 }}>
            <div className="col-6">
              <p>Receive Marketing Emails?</p>
              <select className="form-control" id="receiveMarketing" value={this.state.preferences.receiveMarketing} onChange={this.updateField}>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
            </div>
            <div className="col-6">
              <p>Receive Tips Emails?</p>
              <select className="form-control" id="receiveTips" value={this.state.preferences.receiveTips} onChange={this.updateField}>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
            </div>
          </div>
          <div className="row" style={{ marginBottom: 15 }}>
            <div className="col-6">
              <p>Receive Account Updates Emails?</p>
              <select className="form-control" id="receiveAccountUpdates" value={this.state.preferences.receiveAccountUpdates} onChange={this.updateField}>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
            </div>
            <div className="col-6">
              <p>Receive Other Emails?</p>
              <select className="form-control" id="receiveOther" value={this.state.preferences.receiveOther} onChange={this.updateField}>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <button className="btn btn-primary btn-block" onClick={this.savePreferences}>Save</button>
            </div>
          </div>
        </Card>
      </div>
    );
  }


  private setup() {
    // we need to get the preferences with a description
    this.setState({ loading: true }, async () => {
      try {
        const result = await NotificationsAPI.getUserEmailPreferences("wagz2", this.props.user.id, "", {});
        this.setState({ loading: false, preferences: result.body.data });
      } catch (err) {
        this.setState({ loading: false });
      }
    });
  }

  private savePreferences() {
    this.setState({ loading: true }, async () => {
      try {
        await NotificationsAPI.saveUserEmailPreferences("wagz2", this.props.user.id, "", this.state.preferences);
        success("Preferences saved!")
        this.setState({ loading: false });
      } catch (err) {
        error("We could not save those preferences.")
        this.setState({ loading: false });
      }
    });

  }

}

const defaultEmailSubscriptionPreferences: IEmailSubscriptionPreferences = {
  email: "",
  receiveAccountUpdates: "yes",
  receiveMarketing: "yes",
  receiveOther: "yes",
  receiveTips: "yes",
  receiveTransactional: "yes",
  userId: 0,
};