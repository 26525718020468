import axios from "axios";
import * as Alert from "src/components/structure/Alert";

/**
 * The main method for calling the Wagz API. 
 * @param method one of get, put, patch, post, delete
 * @param endpoint the endpoint of the server to hit, such as users/login
 * @param data 
 */
export function makeCall(method: string, endpoint: string, data: any = {}, options: any = {}): Promise<any> {
  const url = makeUrl(endpoint);
  if(data && !data.count && method === "get"){
    data.count = 10000;
  }
  const meth = method.toLowerCase();
  const config: any = {
    method: meth,
    url,
    timeout: 15000,
    headers: {},
    params: {},
    data: {},
    withCredentials: true,
  };

  // TODO: look at when access_token expires, if it does, and preemptively renew it

  if(data && data.access_token){
    // they overrode it
    config.headers["Authorization"] = "Bearer " + data.access_token;
    delete(data.access_token);
  }else if(window.localStorage && window.localStorage.access_token){
    config.headers["Authorization"] = "Bearer " + window.localStorage.access_token;
  }


  if(options.asMultipartFile){
    const formData = new FormData();
    formData.append("file", data);
    config.data = formData;
  } else {
    if (meth === "get" || meth === "delete") {
      config.params = data;
    } else {
      config.data = data;
    }
  }
  if(options.accept){
    config.headers.Accept = options.accept;
  }
  if(options.etag){
    config.headers["If-None-Match"] = options.etag;
  }
  if(options.asDownload){
    config.responseType = "blob";
  }
  return axios(config)
  .then((res: any) => {
    // if they asked for a download, we have to do this One Dumb Trick
    if(options.asDownload){
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      const fileName = options.fileName ? options.fileName : "file";
      link.setAttribute('download', fileName); //or any other extension
      document.body.appendChild(link);
      link.click();
      link.remove();
      res.data = { downloaded: true};
    }
    return Promise.resolve({
      code: 200,
      body: res.data
    });
  })
  .catch((err: any) => { 
    const ret = {
      code: 500,
      body: err
    };

    if(err.response && err.response.status) {
      ret.code = err.response.status;
      ret.body = err.response.data;
    }

    // we need to check for expired token here
    // this isn't working, unfortunately, due to race conditions
    // so.... not sure, will need to revist prior to launch
    if(ret.code === 401 || ret.code === 419){
      // see if we have a refresh token we can try
      if(window.localStorage && window.localStorage.refresh_token){
        const refreshData = {
          "grant_type": "refresh_token",
          "refresh_token": window.localStorage.refresh_token
        };
        const refreshConfig: any = {
          method: "POST",
          url: makeUrl(`/admin/users/${window.localStorage.userId}/oauth/token`),
          timeout: 1000,
          headers: {},
          params: {},
          data: refreshData,
        };
        return axios(refreshConfig)
        .then((res: any) => {
          // set the new values, remake the call
          const user = res.data.data;
          window.localStorage.setItem("access_token", user.accessToken);
          window.localStorage.setItem("refresh_token", user.refreshToken);
          window.localStorage.setItem("expires", user.expires);
          window.localStorage.setItem("userId", user.id);
          return makeCall(method, endpoint, data, options);
        })
        .catch((err) => {
          Alert.error("Your session has expired and you need to log back in");
          // redirect
          window.localStorage.removeItem("access_token");
          window.localStorage.removeItem("refresh_token");
          window.localStorage.removeItem("expires");
          window.localStorage.removeItem("userId");
          window.localStorage.removeItem("user");
          window.localStorage.removeItem("loggedIn");
          window.location.href = "/login";
          Promise.reject(ret);
        });

      }else{
        // do nothing if they are already on the login
        if(window.location.pathname !== "/login" && window.location.pathname !== "/"){
          window.localStorage.removeItem("access_token");
          window.localStorage.removeItem("refresh_token");
          window.localStorage.removeItem("expires");
          window.localStorage.removeItem("userId");
          window.localStorage.removeItem("user");
          window.localStorage.removeItem("loggedIn");
          window.location.href = "/login";
        }
      }
    } else {
      return Promise.reject(ret);
    }    
  });
}

/**
 * A helper function to generate a url
 * @param endpoint 
 */
export function makeUrl(endpoint: string): string {
  let api = process.env.REACT_APP_API || "http://127.0.0.1:3000";
  // normalize
  if(api.charAt(api.length -1) === "/"){
    api = api.substr(0, api.length - 1);
  }
  let resource = endpoint;
  if(resource.charAt(resource.length -1) === "/"){
    resource = resource.substr(0, resource.length - 1);
  }
  if(resource.charAt(0) === "/"){
    resource = resource.substr(1, resource.length);
  }
  return `${api}/${resource}`;
}