import React, { Component } from "react";
import {  withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CSVReader from "react-csv-reader";
import moment from "moment";

import Card from "src/components/structure/Card";
import * as Alert from "src/components/structure/Alert";
import * as UserActions from "src/reducers/userReducer";
import { IDeviceSecret } from "src/api/devices";
import { DeviceAPI } from "src/api";

interface IUploadPendantShieldSecretsScreenProps {
  location: any;
  userActions: any;
  userState: any;
}

interface IUploadPendantShieldSecretsScreenState {
  loading: boolean;
  toImport: IDeviceSecret[];
  parsedFilename: string;
}

class UploadPendantShieldSecretsTabScreen extends Component<IUploadPendantShieldSecretsScreenProps, IUploadPendantShieldSecretsScreenState> {
  constructor(props: IUploadPendantShieldSecretsScreenProps) {
    super(props);

    this.state = {
      loading: false,
      toImport: [],
      parsedFilename: "",
    };

    this.updateField = this.updateField.bind(this);
    this.upload = this.upload.bind(this);
    this.handleParse = this.handleParse.bind(this);
  }

  private parserOptions = {
    header: true,
    trimHeaders: true,
    skipEmptyLines: true,
    dynamicTyping: false,
    transformHeader: (header: string) => header.toLowerCase()
  };


  render() {
    return (
      <div>
        <div className="row">
          <div className="col-4">
            <Card title="Upload Pendant/Shield Secrets">
              <p>Choose a CSV file that matches the requirements generated by the factory tool. Upon successful parsing, a preview will be generated for confirmation prior to upload. <br /><strong>Note: </strong> The server will replace any secret in the database if that product id already exists. In other words, if you save a new secret for a product id, it will replace whatever is already in the database.</p>
              <div className="form-group">
                <CSVReader 
                  onFileLoaded={this.handleParse}
                  parserOptions={this.parserOptions}
                />
              </div>
              <div className="form-group" style={{marginTop: 15}}>
                {this.state.toImport.length > 0 && (
                  <button className="btn btn-block btn-primary" onClick={this.upload}>Upload to Database</button>
                )}
              </div>
            </Card>
          </div>
          {this.state.toImport.length > 0 && (
            <div className="col-8">
              <Card title={`Parsed ${this.state.parsedFilename}`}>
                <div className="row shield-row-header">
                  <div className="col-3">
                    Serial / Product ID
                  </div>
                  <div className="col-3">
                    Secret Key
                  </div>
                </div>
                {this.state.toImport.map((s, i) => {
                  return (
                    <div className="row" key={i}>
                      <div className="col-3">
                        {s.productId}
                      </div>
                      <div className="col-3">
                        {s.secretKey}
                      </div>
                    </div>
                  )
                })}
              </Card>
            </div>
          )}
        </div>
      </div>
    )
  }

  updateField(e: any) {
    let ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private handleParse(data: any, fileInfo: any){
    this.setState({ loading: true, toImport: [] }, () => {
      // grab the file name
      const fileName = fileInfo.name ? fileInfo.name : "unknown";
      // the data will have the parsed rows
      const toImport: IDeviceSecret[] = [];
      for(const s of data){
        const familyId = s.serial && s.serial.length > 0 ? s.serial.substr(4, 2) : "";
        const d: IDeviceSecret = {
          productId: s.serial,
          som: s.som ? s.som : "",
          imei: s.imei ? s.imei : "",
          iccid: s.iccid ? s.iccid : "",
          mac: s.mac ? s.mac : "",
          otaChannel: s.otachannel ? s.otachannel : "",
          app: s.app ? s.app : "",
          secretKey: s.secret,
          inserted: moment(),
          familyId,
        };
        toImport.push(d);
      }
      if(toImport.length === 0){
        Alert.error("Could not parse that CSV");
        return this.setState({ loading: false });
      }
      this.setState({loading: false, toImport, parsedFilename: fileName});
    })
  }

  private upload() {
    this.setState({ loading: true }, async () => {
      try{
        await DeviceAPI.saveDeviceSecrets(this.state.toImport);
        Alert.success("Secrets uploaded!");
        this.setState({ toImport: [], loading: false, parsedFilename: ""});
      }catch(err){
        Alert.error("Could not save those secrets.");
        this.setState({ loading: false });
      }
    });
  }
}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UploadPendantShieldSecretsTabScreen) as any
);
