import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as UserActions from "src/reducers/userReducer";
import UserAvatar from "react-user-avatar";
import { UserAPI, ProfileAPI } from "src/api";
import { ListGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClipboard,
  faEnvelope,
  faUserTag,
} from "@fortawesome/free-solid-svg-icons";
import * as Alert from "../../structure/Alert";
import Card from "src/components/structure/Card";
import { styles } from "src/styles";
import { BlankProfile, IProfile } from "src/api/profile";

import OTPManagement from "./OTPManagement";

interface IProfileProps {
  match: any;
  history: any;
  location: any;
  userActions: any;
  userState: any;
}

interface IProfileState {
  loading: boolean;
  name: string;
  email: string;
  newPassword: string;
  confirmPassword: string;
  status: string;
  roles: string[];
  profile: IProfile;
}

class Profile extends Component<IProfileProps, IProfileState> {
  constructor(props: IProfileProps) {
    super(props);

    this.state = {
      loading: false,
      name: `${this.props.userState.user.firstName} ${this.props.userState.user.lastName}`,
      email: this.props.userState.user.email,
      newPassword: "",
      confirmPassword: "",
      status: this.props.userState.user.status,
      roles: this.props.userState.user.roles,
      profile: BlankProfile,
    };

    this.updateField = this.updateField.bind(this);
    this.updatePassword = this.updatePassword.bind(this);
    this.fetchProfile = this.fetchProfile.bind(this);
  }

  componentDidMount() {
    this.fetchProfile();
  }

  render() {
    return (
      <div className="row">
        <div className="col-sm-12 col-lg-5" style={{ marginBottom: 30 }}>
          <Card title="My Profile">
            <div className="row">
              <div style={{ color: "#f6f9fc", fontSize: 48, marginLeft: 20 }}>
                <UserAvatar
                  size="104"
                  name={this.state.name}
                  colors={[
                    "#11cdef",
                    "#2dce89",
                    "#f5365c",
                    "#5e72e4",
                    "#fb6340",
                  ]}
                />
              </div>
              <div
                style={{
                  color: "#525f7f",
                  fontSize: 48,
                  marginLeft: 40,
                  marginTop: 20,
                }}
              >
                <p>{this.state.name}</p>
              </div>
            </div>
            <div style={{ color: "#525f7f", fontSize: 24 }}>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <FontAwesomeIcon icon={faEnvelope} /> - Email:{" "}
                  <span style={styles.profileSpan}>{this.state.email}</span>
                </ListGroup.Item>
                <ListGroup.Item>
                  <FontAwesomeIcon icon={faClipboard} /> - Status:{" "}
                  <span style={styles.profileSpanCaps}>
                    {this.state.status}
                  </span>
                </ListGroup.Item>
                <ListGroup.Item>
                  <FontAwesomeIcon icon={faUserTag} /> - Roles:{" "}
                  <span style={styles.profileSpanCaps}>
                    {this.state.roles.join(", ")}
                  </span>
                </ListGroup.Item>
              </ListGroup>
            </div>
            <div style={styles.changePW}>
              <p style={styles.pwHeaderText}>Change Your Password</p>
              <div className="form-group">
                <input
                  id="newPassword"
                  type="text"
                  className="form-control"
                  placeholder="New Password"
                  onChange={this.updateField}
                  autoComplete="new-password"
                  value={this.state.newPassword}
                  style={{ marginBottom: 10 }}
                />
                <input
                  id="confirmPassword"
                  type="text"
                  className="form-control"
                  placeholder="Confirm New Password"
                  autoComplete="new-password"
                  onChange={this.updateField}
                  value={this.state.confirmPassword}
                />
              </div>
              <button
                className="btn btn-block btn-primary"
                onClick={this.updatePassword}
              >
                Update password
              </button>
            </div>
          </Card>
        </div>
        <div className="col-lg-5 offset-1">
          <OTPManagement profile={this.state.profile} />
        </div>
      </div>
    );
  }

  updateField(e: any) {
    let ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  updatePassword() {
    if (
      this.state.newPassword !== this.state.confirmPassword ||
      this.state.newPassword === ""
    ) {
      return Alert.error("Passwords must match and cannot be blank!");
    }
    this.setState({ loading: true }, async () => {
      try {
        await UserAPI.editUser(this.props.userState.user.id, {
          password: this.state.newPassword,
        });
        Alert.success("Password has been reset");
        this.setState({ loading: false, newPassword: "", confirmPassword: "" });
      } catch (error) {
        this.setState({ loading: false });
        Alert.error(
          "Hmm, that password doesn't seem to be strong enough for us.  Try again."
        );
      }
    });
  }

  private fetchProfile() {
    this.setState({ loading: true }, async () => {
      try {
        const result = await ProfileAPI.getProfile();
        this.setState({
          loading: false,
          profile: result.body.data,
        });
      } catch (err) {
        this.setState({ loading: false });
      }
    });
  }
}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Profile) as any
);
