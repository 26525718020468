import React, { Component } from "react";
import Card from "src/components/structure/Card";

interface IAllOrdersDisplayProps {
  data: any;
  loading: boolean;
}

interface IAllOrdersDisplayState {
  selectedApp: string;
  selectedAppStats: any;
}

const blankStats = {
  "ordered": 0,
  "cancelled": 0,
  "returned": 0,
  "fulfilled": 0,
  "other": 0,
  "isMissing": true,
}

export class AllOrdersDisplay extends Component<IAllOrdersDisplayProps, IAllOrdersDisplayState> {
  constructor(props: IAllOrdersDisplayProps) {
    super(props);

    this.state = {
      selectedApp: "",
      selectedAppStats: blankStats,
    };

    this.handleUpdate = this.handleUpdate.bind(this);
    this.filterStats = this.filterStats.bind(this);
  }

  handleUpdate(e: any){
    const id = e.target.id;
    const val = e.target.value;
    const ns = this.state;
    ns[id] = val;
    this.setState(ns, () => { this.filterStats(); });
  }

  render() {
    return (
      <Card loading={this.props.loading} title="Order Statistics">
        <div className="row">
          <div className="col-12">
            <select id="selectedApp" value={this.state.selectedApp} onChange={this.handleUpdate} className="form-control">
              <option value="">Please Select an Order Origination</option>
              <option value="shopify">Shopify</option>
            </select>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {this.state.selectedAppStats.isMissing ? (<strong>No data available</strong>): 
            (
              <div>
              <div className="row">
                <div className="col-12">
                  Open Orders: {this.state.selectedAppStats.ordered}
                </div>
              </div>
                <div className="row">
                  <div className="col-12">
                    Fulfilled: {this.state.selectedAppStats.fulfilled}
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    Returned: {this.state.selectedAppStats.returned}
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    Cancelled: {this.state.selectedAppStats.cancelled}
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    Other: {this.state.selectedAppStats.other}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </Card>
    );
  }


  private filterStats(){
    // search for the stats
    for(const app in this.props.data){
      if(app === this.state.selectedApp){
        const stats = this.props.data[app];
        stats.isMissing = false;
        return this.setState({ selectedAppStats: stats });
      }
    }
    this.setState({ selectedAppStats: blankStats});
  }
}