import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as AppActions from "src/reducers/appReducer";
import * as UserActions from "src/reducers/userReducer";

import { Card, Screen } from "src/components/structure";
import { BillingAPI } from "src/api";
import { success, error } from "src/components/structure/Alert";
import Select from "react-select";
import { couponTypes, couponUseTypes, couponDurations, plans } from "src/utils/items";

interface ICreateCouponProps {
  loading: boolean;
  userState: any;
  onGeneration: any;
}

interface ICreateCouponState {
  loading: boolean;
  couponCode: string;
  couponName: string;
  couponUseType: string;
  couponType: string;
  couponDuration: string;
  couponValue: string;
  plans: any[];
}
class CreateCoupon extends Component<ICreateCouponProps, ICreateCouponState> {
  constructor(props: ICreateCouponProps) {
    super(props);

    this.state = {
      loading: false,
      couponCode: "",
      couponName: "",
      couponUseType: "",
      couponType: "",
      couponDuration: "",
      couponValue: "",
      plans: [],
    };

    this.updateField = this.updateField.bind(this);
    this.createCoupon = this.createCoupon.bind(this);
    this.updateSelection = this.updateSelection.bind(this);
    this.updatePlans = this.updatePlans.bind(this);
  }

  render() {
    return (
      <Screen id="create_coupon">
        <div className="col-11 offset-1">
          <Card
            title="Create New Coupon"
            loading={this.props.loading || this.state.loading}
          >
            <div className="form-group">
              <label>Coupon Code *</label>
              <label style={{ marginTop: 4, marginLeft: 6, fontSize: 11 }}>
                Alpha-numeric characters only - 20 character limit{" "}
              </label>
              <input
                type="text"
                className="form-control"
                id="couponCode"
                value={this.state.couponCode}
                onChange={this.updateField}
              />
              <label style={{ marginTop: 10 }}>Coupon Name</label>
              <input
                type="text"
                className="form-control"
                id="couponName"
                value={this.state.couponName}
                onChange={this.updateField}
              />
              <div style={{ marginLeft: 15, marginTop: 10 }}>
                <div className="row">
                  <div style={{ width: "25%" }}>
                    <label style={{ fontSize: 14 }}>Coupon Value Type *</label>
                  </div>
                  <div style={{ width: "25%", marginLeft: 15 }}>
                    <label style={{ fontSize: 14 }}>Number of Uses *</label>
                  </div>
                  <div style={{ width: "25%", marginLeft: 15 }}>
                    <label style={{ fontSize: 14 }}>Coupon Duration *</label>
                  </div>
                </div>
                <div className="row">
                  <div style={{ width: "25%" }}>
                    <Select
                      options={couponTypes}
                      onChange={this.updateSelection}
                      hideSelectedOptions={true}
                    />
                  </div>
                  <div style={{ width: "25%", marginLeft: 15 }}>
                    <Select
                      options={couponUseTypes}
                      onChange={this.updateSelection}
                      hideSelectedOptions={true}
                    />
                  </div>
                  <div style={{ width: "25%", marginLeft: 15 }}>
                    <Select
                      options={couponDurations}
                      onChange={this.updateSelection}
                      hideSelectedOptions={true}
                    />
                  </div>
                </div>
              </div>
              <label style={{ marginTop: 10 }}>Coupon Value *</label>
              <label style={{ marginTop: 4, marginLeft: 6, fontSize: 11 }}>
                Whole number for percent type, in cents for flat dollar discount
                (25 for 25%, 500 for $5, etc){" "}
              </label>
              <input
                type="number"
                className="form-control"
                id="couponValue"
                value={this.state.couponValue}
                onChange={this.updateField}
              />
            </div>
          <div className="form-group">
            <label style={{ marginLeft: 5 }}>
              Eligible Plan(s) *
            </label>
          <Select options={plans} isMulti={true} onChange={this.updatePlans} hideSelectedOptions={true} isClearable={false} />
          </div>
            <div className="form-group">
              <button
                className="btn btn-block btn-success"
                onClick={this.createCoupon}
              >
                Create Coupon
              </button>
            </div>
          </Card>
        </div>
      </Screen>
    );
  }

  updateField(e: any) {
    let ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  updateSelection = (input: any) => {
    let ns = this.state;
    ns[input.field] = input.value;
    this.setState(ns);
  };

  updatePlans(e: any) {
    if (e === null) {
      return
    }
    const plans = [];
    let v: any
    for (v of e) {
      plans.push(v.value)
    }
    this.setState({ plans: plans });
  }

  private createCoupon() {
    // Check for values
    if (this.state.couponCode === "" || this.state.couponUseType === "" || this.state.couponType === "" || this.state.couponDuration === "" || this.state.couponValue === "" ) {
      return error("You must provide values for all required * fields.");
    }
    if (this.state.plans.length < 1) {
      return error("Please provide at least 1 (one) Eligible Plan for this coupon.");
    }

    this.setState({ loading: true }, async () => {
      try {
        await BillingAPI.createCoupon("wagz2", {
          couponCode: this.state.couponCode,
          couponName: this.state.couponName,
          couponUseType: this.state.couponUseType,
          couponType: this.state.couponType,
          couponDuration: this.state.couponDuration,
          couponValue: parseInt(this.state.couponValue),
        });
        for (let p of this.state.plans) {
          await BillingAPI.linkCouponToPlan("wagz2", {
            couponCode: this.state.couponCode,
            planId: p
          })
        };
        return this.setState({
          loading: false,
          couponCode: "",
          couponName: "",
          couponUseType: "",
          couponType: "",
          couponDuration: "",
          couponValue: "",
          plans: []
        }, () =>
          {
            success("Coupon Created!");
            this.props.onGeneration();
          }
        );
      } catch (err) {
        error("Could not create that coupon. Please contact an administrator.");
        this.setState({ loading: false });
      }
    });
  }
}

const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(AppActions, dispatch),
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateCoupon);
