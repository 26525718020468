import moment from "moment";
import React, { Component } from "react";
import { Table } from "src/components/structure";

interface IQueryEngineResultsViewerProps {
  query: any;
}

interface IQueryEngineResultsViewerState {
  loading: boolean;
  cols: any[];
  rows: any[];
}


export default class QueryEngineResultsViewer extends Component<
  IQueryEngineResultsViewerProps,
  IQueryEngineResultsViewerState
> {
  constructor(props: IQueryEngineResultsViewerProps) {
    super(props);
    this.state = {
      loading: true,
      cols: [],
      rows: [],
    };

    this.setup = this.setup.bind(this);
    this.processData = this.processData.bind(this);
  }

  componentDidMount(){
    this.setup()
  }

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-3">
            {this.props.query && this.props.query.name ? this.props.query.name : "Run Query"}
          </div>
          <div className="col-3">
            {this.props.query && this.props.query.lastRunOn ? moment(this.props.query.name.lastRunOn).format("MM/DD/YY HH:mm") : "Just now"}
          </div>
          <div className="col-3">
            {this.props.query && this.props.query.lastRunStatus ? this.props.query.lastRunStatus : "Unknown"}
          </div>
          <div className="col-3">
            {this.state.rows.length} rows
          </div>
        </div>


        <Table
            title=""
            showDownload={true}
            csvFileName={`query_results_${this.props.query.name ? this.props.query.name : "unknown"}_${moment().format("YYYY-MM-DDTHH:mm:ss")}`}
            columns={this.state.cols}
            rows={this.state.rows}
          />

      </div>
    );
  }

  private setup(){

    const result = this.processData()
    this.setState({ loading: false, cols: result.cols, rows: result.rows });
  }

  private processData(): any {
    const ret: any = {
      cols: [],
      rows: []
    }
    if(this.props.query && this.props.query.query.output && this.props.query.query.output.length > 0){
      const data = this.props.query.query.output;
      for(let i = 0; i < data.length; i++){
        const row = data[i];
        if(i === 0){
          // this is the header
          for(const item of row){
            ret.cols.push({
              "label": item,
              "field": item
            });
          }
        } else {
          const entry: any = {}
          for(let j = 0; j < row.length; j++){
            entry[ret.cols[j].field] = row[j];
          }
          ret.rows.push(entry)
        }
      }
    }
    return ret;
  }
}
