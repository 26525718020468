import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { IDevice } from "src/api/devices";
import { DeviceAPI } from "src/api";
import { DeviceList } from "src/components/structure";
import { Loading, Screen } from "src/components/structure";

interface IMyDevicesProps {
  userState: any;
}

interface IMyDevicesState {
  loading: boolean;
  devices: IDevice[];
}

class MyDevices extends Component<IMyDevicesProps, IMyDevicesState> {
  constructor(props: IMyDevicesProps) {
    super(props);

    this.state = {
      loading: false,
      devices: [],
    };
    this.fetchDevices = this.fetchDevices.bind(this);
  }

  componentDidMount() {
    this.fetchDevices();
  }

  render() {
    return (
      <Screen shouldAuthCheck={true}>
        <div>
          <div className="col-sm-12 col-lg-12" style={{ marginBottom: 30 }}>
            {this.state.loading ? (
              <Loading />
            ) : (
              <DeviceList devices={this.state.devices} title="My Devices" />
            )}
          </div>
        </div>
      </Screen>
    );
  }

  private fetchDevices() {
    this.setState({ loading: true }, async () => {
      // I don't like this, but it's what we have to do for now
      // we could probably create a new endpoint on the server, but multiple requests here should be fine
      // for now
      const promises = [];
      for (const i of this.props.userState.user.wagzUserIds) {
        promises.push(DeviceAPI.getDevicesForUser("wagz2", i));
      }
      try {
        const results = await Promise.all(promises);
        const devices: IDevice[] = [];
        for (const r of results) {
          for (const d of r.body.data) {
            devices.push(d);
          }
        }
        this.setState({ devices, loading: false });
      } catch (err) {
        this.setState({ loading: false });
      }
    });
  }
}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps() {
  return {};
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MyDevices) as any
);
