import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Table, Screen, DatePicker } from "src/components/structure";

import * as UserActions from "src/reducers/userReducer";
import moment from "moment";
import Card from "src/components/structure/Card";
import { MetricsAPI, PetsAPI } from "src/api";
import { error } from "src/components/structure/Alert";

interface IPetRankingsScreenProps {
  location: any;
}

interface IPetRankingsScreenState {
  loading: boolean;
  age: "all" | "young" | "adult";
  breedId: string;
  breedGroupId: string;
  day: moment.Moment;
  rankings: any[];
  metric: string;

  breeds: any[];
  breedGroups: any[];
}

/**
 * age: "all"
breedGroupId: 0
breedId: 0
day: "2022-04-28"
exerciseMinutes: 0
exerciseMinutesRanking: 16
exerciseMinutesTotalPets: 17
hhs: 3.35
hhsRanking: 14
hhsTotalPets: 17
id: 1445
petId: 3848
sleepMinutes: 1219
sleepMinutesRanking: 4
sleepMinutesTotalPets: 17
species: "dog"
steps: 0
stepsRanking: 16
stepsTotalPets: 17
 */
const cols = [
  {
    label: "Pet Id",
    field: "petId",
  },
  {
    label: "Comp. Breed",
    field: "breed",
  },
  {
    label: "Comp. Group",
    field: "breedGroup",
  },
  {
    label: "Comp. Age",
    field: "age",
  },
  {
    label: "Exercise Rank",
    field: "exerciseMinutesRanking",
  },
  {
    label: "Exercise Total",
    field: "exerciseMinutesTotalPets",
  },
  {
    label: "Exercise Minutes",
    field: "exerciseMinutes",
  },
  {
    label: "HHS Rank",
    field: "hhsRanking",
  },
  {
    label: "HHS Total",
    field: "hhsTotalPets",
  },
  {
    label: "HHS",
    field: "hhs",
  },
  {
    label: "Sleep Ranking",
    field: "sleepMinutesRanking",
  },
  {
    label: "Sleep Total",
    field: "sleepMinutesTotalPets",
  },
  {
    label: "Sleep",
    field: "sleepMinutes",
  },
  {
    label: "Steps Rank",
    field: "stepsRanking",
  },
  {
    label: "Steps Total",
    field: "stepsTotalPets",
  },
  {
    label: "Steps",
    field: "steps",
  },
];

class PetRankingsScreen extends Component<
  IPetRankingsScreenProps,
  IPetRankingsScreenState
> {
  constructor(props: IPetRankingsScreenProps) {
    super(props);

    this.state = {
      loading: false,
      rankings: [],
      breedGroupId: "0",
      breedId: "0", 
      age: "all",
      day: moment().subtract(1, "day"),
      metric: "steps",

      breeds: [],
      breedGroups: [],
    };

    this.updateField = this.updateField.bind(this);
    this.updateDay = this.updateDay.bind(this);
    this.setup = this.setup.bind(this);
    this.getRankings = this.getRankings.bind(this);
  }

  updateField(e: any) {
    let ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  updateDay(newDay: moment.Moment){
    this.setState({ day: newDay})
  }

  componentDidMount() {
    this.setup();
  }

  

  render() {
    return (
      <Screen shouldAuthCheck={true} requiredRoles={["reporting,product,support"]}>
        <Card title="Filters">
          <div className="row">
            <div className="col-2">
              <label>Day</label>
              <DatePicker date={this.state.day} onDateSaved={this.updateDay} />
            </div>
            <div className="col-2">
              <label>Metric</label>
              <select className="form-control" value={this.state.metric} id="metric" onChange={this.updateField}>
                <option value="exerciseMinutes">Exercise Minutes</option>
                <option value="hhs">Health Happiness</option>
                <option value="sleepMinutes">Sleep Minutes</option>
                <option value="steps">Steps</option>
              </select>
            </div>
            <div className="col-2">
              <label>Breed Group</label>
              <select className="form-control" value={this.state.breedGroupId} id="breedGroupId" onChange={this.updateField}>
                <option value="0">All</option>
                {this.state.breedGroups.map((group) => {
                  return <option value={group.id} key={group.id}>{group.groupName}</option>
                })}
              </select>
            </div>
            <div className="col-2">
              <label>Breed</label>
              <select className="form-control" value={this.state.breedId} id="breedId" onChange={this.updateField}>
                <option value="0">All</option>
                {this.state.breeds.map((breed) => {
                  return <option value={breed.id} key={breed.id}>{breed.breed}</option>
                })}
              </select>
            </div>
            <div className="col-2">
              <label>Age</label>
              <select className="form-control" value={this.state.age} id="age" onChange={this.updateField}>
                <option value="all">All</option>
                <option value="adult">Age</option>
                <option value="young">Young</option>
              </select>
            </div>
            <div className="col-2">
              <button className="btn btn-primary btn-block" onClick={this.getRankings} style={{marginTop: "10%"}}>Get Rankings</button>
            </div>
          </div>
        </Card>
        <div>
          {this.state.rankings.length !== 0 && !this.state.loading && (
            <Table
              title="Rankings"
              csvFileName={`rankings-${this.state.day}-${this.state.age}-${this.state.breedGroupId}-${this.state.breedId}`}
              showDownload={true}
              columns={cols}
              rows={this.state.rankings}
            />
          )}
        </div>

      </Screen>
    );
  }

  private setup(){
    this.setState({loading: true }, async () => {
      try{
        const breedsResult = await PetsAPI.getBreeds("wagz2", {species: "dog"});
        const breedGroupsResult = await PetsAPI.getBreedGroups("wagz2", {species: "dog"})

        this.setState({ loading: false, breeds: breedsResult.body.data, breedGroups: breedGroupsResult.body.data});

      }catch(err){
        this.setState({ loading: false });
      }
    })
  }

  private getRankings(){
    this.setState({ loading: true }, async () => {
      try{
        const params = {
          breedId: this.state.breedId,
          breedGroupId: this.state.breedGroupId,
          age: this.state.age,
        }
        const result = await MetricsAPI.getRankingsForPlatformOnDayByMetric("wagz2", this.state.metric, this.state.day.format("YYYY-MM-DD"), params);
        const rankings = result.body.data;
        if(rankings.length === 0){
          error("No rankings match those filters");
        }
        for(const r of rankings){
          r.breed = "All";
          r.breedGroup = "All";
          if(r.breedId !== 0){
            for(const b of this.state.breeds){
              if(b.id === r.breedId){
                r.breed = b.breed;
                break
              }
            }
          }
          if(r.breedGroupId !== 0){
            for(const b of this.state.breedGroups){
              if(b.id === r.breedGroupId){
                r.breedGroup = b.groupName;
                break
              }
            }
          }
        }
        this.setState({ loading: false, rankings})
      }catch(err){
        error("No rankings match those filters");
        this.setState({ loading: false, rankings: [] });
      }
    })
  }
}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PetRankingsScreen) as any
);
