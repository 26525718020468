import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Select from "react-select";
import DatePicker from "react-date-picker";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faEdit } from "@fortawesome/free-solid-svg-icons";

import * as AppActions from "src/reducers/appReducer";
import * as UserActions from "src/reducers/userReducer";

import { Alert, Table, MarkdownEditor } from "src/components/structure";
import { capitalizeFirstLetter } from "src/utils/utilities";
import { mobileAppStatus, mobileAppUpdateRule } from "src/utils/items";
import { styles } from "src/styles";
import { AppsAPI } from "src/api";
import { IColumns } from "src/utils/customTypes";

interface IReleaseListProps {
  versions: any;
  loading: boolean;
  userActions: any;
  userState: any;
  onVersionsCreated: any;
}

interface IReleaseListState {
  loading: boolean;
  showModalForEdit: boolean;
  showModalForNotes: boolean;
  showModalForDelete: boolean;
  id: number;
  build: string;
  os: string;
  platform: string;
  releaseDate: string;
  status: string;
  updateRule: string;
  semver: string;
  privateNotes: string;
  publicNotes: string;
  columns: IColumns[];
  rows: any[];
}

class MobileReleaseList extends Component<
  IReleaseListProps,
  IReleaseListState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      showModalForEdit: false,
      showModalForNotes: false,
      showModalForDelete: false,
      id: 0,
      build: "",
      os: "",
      platform: "",
      releaseDate: "",
      status: "",
      updateRule: "",
      semver: "",
      privateNotes: "",
      publicNotes: "",
      columns: [
        {
          label: "Semver",
          field: "semver",
          sort: "asc",
          width: 200,
        },
        {
          label: "OS",
          field: "os",
          sort: "asc",
          width: 150,
        },
        {
          label: "Platform",
          field: "platform",
          sort: "asc",
          width: 200,
        },
        {
          label: "Release Date",
          field: "releaseDate",
          sort: "asc",
          width: 110,
        },
        {
          label: "Status",
          field: "status",
          sort: "asc",
          width: 150,
        },
        {
          label: "Notes",
          field: "notes",
          sort: "asc",
          width: 110,
        },
        {
          label: "Actions",
          field: "actions",
          sort: "asc",
          width: 100,
        },
      ],
      rows: [],
    };

    this.updateField = this.updateField.bind(this);
    this.updateSelection = this.updateSelection.bind(this);
    this.formatVersions = this.formatVersions.bind(this);
    this.editAppVersion = this.editAppVersion.bind(this);
    this.editNotes = this.editNotes.bind(this);
    this.updatePublicNotes = this.updatePublicNotes.bind(this);
    this.updatePrivateNotes = this.updatePrivateNotes.bind(this);
    this.deleteSelectedVersion = this.deleteSelectedVersion.bind(this);
  }

  updateField(e: any) {
    let ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  updateSelection = (input: any) => {
    let ns = this.state;
    ns[input.field] = input.value;
    this.setState(ns);
  };

  componentDidMount() {
    this.formatVersions();
  }

  formatVersions() {
    this.setState({ loading: true }, async () => {
      try {
        const appVersions = [];
        for (const verObj of this.props.versions.body.data) {
          const version = { ...verObj };

          version.releaseDate = version.releaseDate.substring(0,10)
          version.status = capitalizeFirstLetter(version.status);
          version.notes = (
            <button
              className="btn btn-sm btn-pri"
              onClick={() => this.showNotesModal(version)}
            >
              View
            </button>
          );
          version.actions = (
            <div
              className="row"
              style={{ justifyContent: "center", marginTop: 4 }}
            >
              <div style={{ paddingRight: 5 }}>
                <FontAwesomeIcon
                  icon={faEdit}
                  onClick={() => this.showEditModal(version)}
                  className="text-secondary"
                />
              </div>
              <div style={{ paddingLeft: 5 }}>
                <FontAwesomeIcon
                  icon={faBan}
                  onClick={() => this.showDeleteModal(version)}
                  className="text-danger"
                />
              </div>
            </div>
          );
          appVersions.push(version);
        }
        this.setState({
          rows: appVersions,
          loading: false,
        });
      } catch (err) {
        this.setState({ loading: false });
      }
    });
  }

  showEditModal(input: any) {
    this.setState({
      showModalForEdit: true,
      id: input.id,
      build: input.build,
      os: input.os,
      platform: input.platform,
      releaseDate: "",
      status: input.status,
      updateRule: input.updateRule,
      semver: input.semver,
      privateNotes: input.privateNotes,
      publicNotes: input.publicNotes,
    });
  }
  showNotesModal(input: any) {
    this.setState({
      showModalForNotes: true,
      id: input.id,
      os: input.os,
      semver: input.semver,
      privateNotes: input.privateNotes,
      publicNotes: input.publicNotes,
    });
  }
  showDeleteModal(input: any) {
    this.setState({
      showModalForDelete: true,
      id: input.id,
      platform: input.platform,
      semver: input.semver,
      os: input.os,
    });
  }

  editAppVersion() {
    this.setState({ loading: true }, async () => {
      try {
        await AppsAPI.updateAppVersion(this.state.platform, this.state.id, {
          semver: this.state.semver,
          build: this.state.build,
          status: this.state.status,
          updateRule: this.state.updateRule,
          releaseDate: this.state.releaseDate,
        });
        return this.setState(
          { loading: false, showModalForEdit: false },
          () => {
            Alert.success("Version edited successfully");
            this.props.onVersionsCreated();
          }
        );
      } catch (err) {
        Alert.error("Could not save that edit");
        this.setState({
          loading: false,
          showModalForEdit: false,
        });
      }
    });
  }

  editNotes() {
    this.setState({ loading: true }, async () => {
      try {
        await AppsAPI.updateAppVersion(this.state.platform, this.state.id, {
          publicNotes: this.state.publicNotes,
          privateNotes: this.state.privateNotes,
        });
        return this.setState(
          { loading: false, showModalForEdit: false },
          () => {
            Alert.success("Notes edited successfully");
            this.props.onVersionsCreated();
          }
        );
      } catch (err) {
        Alert.error("Could not save that edit");
        this.setState({
          loading: false,
          showModalForEdit: false,
        });
      }
    });
  }

  private updatePublicNotes(content: any){
    this.setState({ publicNotes: content} );
  }

  private updatePrivateNotes(content: any){
    this.setState({ privateNotes: content} );
  }

  deleteSelectedVersion() {
    this.setState({ loading: true }, async () => {
      try {
        await AppsAPI.deleteAppVersion(this.state.platform, this.state.id);
        return this.setState(
          { loading: false, showModalForDelete: false },
          () => {
            Alert.success("Version deleted successfully");
            this.props.onVersionsCreated();
          }
        );
      } catch (err) {
        this.setState({
          loading: false,
          showModalForDelete: false,
        });
      }
    });
  }

  render() {
    return (
      <div style={{ height: "100%" }}>
        <Table
          csvFileName="currentAppVersions"
          showDownload={true}
          columns={this.state.columns}
          rows={this.state.rows}
        />
        <div>
          <Modal
            dialogClassName="modal-75"
            show={this.state.showModalForEdit}
            onHide={() => {
              this.setState({ showModalForEdit: false });
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                Update Info for {this.state.os} Release {this.state.semver}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="center">
                <p style={{ fontSize: 14, textAlign: "center" }}>
                  <strong>
                    Update the fields you wish to change, then hit Submit.
                  </strong>
                </p>
                <br />
              </div>
              <div className="form-group">
                <label style={{ fontSize: 12, marginLeft: 5 }}>Semver</label>
                <input
                  id="semver"
                  type="text"
                  className="form-control"
                  placeholder="Semver"
                  autoComplete="new-password"
                  onChange={this.updateField}
                  value={this.state.semver}
                />
              </div>
              <div className="form-group">
                <label style={{ fontSize: 12, marginLeft: 5 }}>Build</label>
                <input
                  id="build"
                  type="text"
                  className="form-control"
                  placeholder="Build"
                  autoComplete="new-password"
                  onChange={this.updateField}
                  value={this.state.build}
                />
              </div>
              <div className="form-group">
                <label style={{ fontSize: 12, marginLeft: 5 }}>Status</label>
                <Select
                  options={mobileAppStatus}
                  onChange={this.updateSelection}
                  hideSelectedOptions={true}
                />
              </div>
              <div className="form-group">
                <label style={{ fontSize: 12, marginLeft: 5 }}>
                  Update Rule
                </label>
                <Select
                  options={mobileAppUpdateRule}
                  onChange={this.updateSelection}
                  hideSelectedOptions={true}
                />
              </div>
              <div>
                <label style={{ fontSize: 12, marginLeft: 5 }}>Release Date</label>
                <div></div>
                <div style={{ marginLeft: 5, marginBottom: 10 }}>
                  <DatePicker id="releaseDate" onChange={(value: any) => this.setState({releaseDate: value})} value={this.state.releaseDate} />
                </div>
              </div>
              <div className="form-group" style={{ marginTop: 20 }}>
                <button
                  className="btn btn-block btn-submit"
                  onClick={this.editAppVersion}
                >
                  Update App Version
                </button>
              </div>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>
        </div>
        <div>
          <Modal
            dialogClassName="modal-75"
            show={this.state.showModalForNotes}
            onHide={() => {
              this.setState({ showModalForNotes: false });
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                Viewing notes for {this.state.os} Release {this.state.semver}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="center">
                <p style={{ fontSize: 14, textAlign: "center" }}>
                  <strong>
                    You are now viewing the notes for this release.  If you'd like to make edits, you may do so, then hit Submit.
                  </strong>
                </p>
                <br />
              </div>
            <div style={styles.borderBottom}></div>
            <div className="form-group">
              <label style={{ marginLeft: 5, textDecoration: "underline" }}>Public Notes:</label>
              <MarkdownEditor
                content={this.state.publicNotes}
                showToggle={true}
                showHelp={true}
                onChange={this.updatePublicNotes}
                mode="view"
                rows={5}
              />
            </div>
            <div style={styles.borderBottom}></div>
            <div className="form-group">
              <label style={{ marginLeft: 5, textDecoration: "underline" }}>Private Notes:</label>
              <MarkdownEditor
                content={this.state.privateNotes}
                showToggle={true}
                showHelp={true}
                onChange={this.updatePrivateNotes}
                mode="view"
                rows={5}
              />
            </div>
              <div className="text">
                <button
                  className="btn btn-block btn-submit"
                  style={styles.button}
                  onClick={this.editNotes}
                >
                  Submit
                </button>
              </div>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>

          <Modal
            show={this.state.showModalForDelete}
            onHide={() => {
              this.setState({ showModalForDelete: false });
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Deleting {this.state.os} App Version {this.state.semver}...</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>Are you sure you want to permanently delete {this.state.os} {this.state.semver}?</div>
            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn btn-block btn-submit"
                style={styles.button}
                onClick={this.deleteSelectedVersion}
              >
                Delete App Version
              </button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    );
  }
}

const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(AppActions, dispatch),
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MobileReleaseList);
