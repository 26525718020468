import * as React from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import Sidebar from "react-sidebar";
import { bindActionCreators } from "redux";

import * as AppActions from "../../reducers/appReducer";
import * as UserActions from "../../reducers/userReducer";

import logo from "src/img/wagz_logo.png";

interface IMenuProps {
  appState: any;
  appActions: any;
  userState: any;
  userActions: any;
  history: any;
}

interface IMenuState {
  open: boolean;
}

class Menu extends React.Component<IMenuProps, IMenuState> {
  constructor(props: any) {
    super(props);
    this.state = {
      open: props.open ? props.open : true,
    };

    this.toggleOpen = this.toggleOpen.bind(this);
    this.onTransition = this.onTransition.bind(this);
    this.logout = this.logout.bind(this);
  }

  public toggleOpen() {
    this.setState({
      open: !this.state.open
    });
  }

  public onTransition() {
    this.props.appActions.toggleMenu();
  }

  public logout(e: any) {
    e.preventDefault();
    this.props.userActions.logoutUser();
    window.localStorage.clear();
    this.props.history.push("/login");
    this.props.appActions.toggleMenu();
  }

  get header() {
    return (
      <div className="menu-sidebar">
        <div className="menu-header"><img src={logo} alt="logo" /></div>
        {this.props.children}
      </div>
    );
  }
  get links() {
    // we need to show links depending on roles
    return (
      <div className="menu-sidebar">
        <div className="menu-header"><img src={logo} alt="logo" style={{width: "100%"}}/></div>
        <div className="menu-content">
          <div className="menu-divider" />

          {this.props.userState.user.isAdministrator && (
            <div>
              <span className="menu-link-header">Administration</span>
              <Link to="/admin/users" className="menu-sidebar-link" onClick={this.onTransition} id="menu_admin_users">Manage Access</Link>
              <Link to="/admin/logs" className="menu-sidebar-link" onClick={this.onTransition} id="menu_admin_logs">Admin Logs</Link>
              <div className="menu-divider" />
            </div>
          )}

          {(this.props.userState.user.isAdministrator || this.props.userState.user.isBackend) && (
            <div>
              <span className="menu-link-header">Backend Engineering</span>
              <Link to="/backend/ota" className="menu-sidebar-link" onClick={this.onTransition} id="menu_backend_ota">Manage OTAs</Link>
              <Link to="/backend/plans" className="menu-sidebar-link" onClick={this.onTransition} id="menu_backend_product_id_plans">Product ID Plans</Link>
              <Link to="/backend/devices/logInterval" className="menu-sidebar-link" onClick={this.onTransition} id="menu_backend_log_interval">Manage Log Intervals</Link>
              <Link to="/devices/secrets" className="menu-sidebar-link" onClick={this.onTransition} id="menu_backend_device_secrets">Manage Device Secrets</Link>
              <Link to="/devices/secrets/upload" className="menu-sidebar-link" onClick={this.onTransition} id="menu_backend_upload_device_secrets">Upload Device Secrets</Link>
              <Link to="/devices/secrets/generate" className="menu-sidebar-link" onClick={this.onTransition} id="menu_backend_generate_device_secret">Generate Device Secret</Link>
              <Link to="/backend/users/jwt" className="menu-sidebar-link" onClick={this.onTransition} id="menu_backend_generate_jwt">Generate JWT</Link>
              <Link to="/backend/anomalies" className="menu-sidebar-link" onClick={this.onTransition} id="menu_backend_anomalies">Manage Anomalies</Link>
              <Link to="/backend/actions" className="menu-sidebar-link" onClick={this.onTransition} id="menu_backend_actions">Manage Actions</Link>
              <Link to="/backend/tickets" className="menu-sidebar-link" onClick={this.onTransition} id="menu_backend_tickets">Backend Support Tickets</Link>
              <Link to="/backend/devices/groups" className="menu-sidebar-link" onClick={this.onTransition} id="menu_device_groups">Manage Device Groups</Link>
              <div className="menu-divider" />
            </div>
          )}

          {(this.props.userState.user.isAdministrator || this.props.userState.user.isMobile) && (
            <div>
              <span className="menu-link-header">Mobile Engineering</span>
              <Link to="/mobile/releases" className="menu-sidebar-link" onClick={this.onTransition} id="menu_mobile_releases">Mobile Releases</Link>
              <Link to="/mobile/tickets" className="menu-sidebar-link" onClick={this.onTransition} id="menu_mobile_tickets">Mobile Support Tickets</Link>
              <Link to="/devices/secrets/generate" className="menu-sidebar-link" onClick={this.onTransition} id="menu_backend_generate_device_secret">Generate Fake Device</Link>
              <div className="menu-divider" />
            </div>
          )}

          {(this.props.userState.user.isAdministrator || this.props.userState.user.isSupport) && (
            <div>
              <span className="menu-link-header">Customer Support</span>
              <Link to="/support/users" className="menu-sidebar-link" onClick={this.onTransition} id="menu_support_users">Users</Link>
              <Link to="/support/devices" className="menu-sidebar-link" onClick={this.onTransition} id="menu_support_devices">Devices</Link>
              <Link to="/support/pets" className="menu-sidebar-link" onClick={this.onTransition} id="menu_support_pets">Pets</Link>
              <Link to="/support/freeUsers" className="menu-sidebar-link" onClick={this.onTransition} id="menu_support_free_users">Free Users</Link>
              <Link to="/support/tickets" className="menu-sidebar-link" onClick={this.onTransition} id="menu_support_tickets">Support Tickets</Link>
              <Link to="/support/info/devices" className="menu-sidebar-link" onClick={this.onTransition} id="menu_support_device_info">General Device Information</Link>
              <Link to="/support/mobile" className="menu-sidebar-link" onClick={this.onTransition} id="menu_support_mobile">Mobile/Firmware Releases</Link>
              <Link to="/product/coupons" className="menu-sidebar-link" onClick={this.onTransition} id="menu_support_coupons">Coupons</Link>
              <Link to="/rmas" className="menu-sidebar-link" onClick={this.onTransition} id="menu_support_rmas">RMAs</Link>
              <Link to="/refunds/calculator" className="menu-sidebar-link" onClick={this.onTransition} id="menu_support_refund_cal">Refund Calculator</Link>
              <div className="menu-divider" />
            </div>
          )}

          {(this.props.userState.user.isAdministrator || this.props.userState.user.isProduct) && (
            <div>
              <span className="menu-link-header">Product Management</span>
              <Link to="/product/coupons" className="menu-sidebar-link" onClick={this.onTransition} id="menu_product_coupons">Coupons</Link>
              <Link to="/product/coupons/upload" className="menu-sidebar-link" onClick={this.onTransition} id="menu_product_coupons_upload">Upload Coupon CSV</Link>
              <Link to="/product/actions" className="menu-sidebar-link" onClick={this.onTransition} id="menu_product_actions">Manage Actions</Link>
              <Link to="/product/events" className="menu-sidebar-link" onClick={this.onTransition} id="menu_product_events">Manage Events</Link>
              <Link to="/product/plans" className="menu-sidebar-link" onClick={this.onTransition} id="menu_product_plans">Manage Plans</Link>
              <Link to="/product/tickets" className="menu-sidebar-link" onClick={this.onTransition} id="menu_product_tickets">Product Support Tickets</Link>
              <Link to="/devices/secrets" className="menu-sidebar-link" onClick={this.onTransition} id="menu_product_device_secrets">Manage Device Secrets</Link>
              <Link to="/gpstests" className="menu-sidebar-link" onClick={this.onTransition} id="menu_product_gps_tests">GPS Tests</Link>
              <Link to="/inset" className="menu-sidebar-link" onClick={this.onTransition} id="menu_product_geofence_inset">Geofence Inset</Link>
              <Link to="/product/emails/preferences/upload" className="menu-sidebar-link" onClick={this.onTransition} id="menu_product_preferences_upload">Upload Email Preferences</Link>
              <Link to="/reports/queries" className="menu-sidebar-link" onClick={this.onTransition} id="menu_product_queries">Query Engine</Link>
              <div className="menu-divider" />
            </div>
          )}

          {(this.props.userState.user.isAdministrator || this.props.userState.user.isQA) && (
            <div>
              <span className="menu-link-header">Quality Assurance</span>
              <Link to="/qa/tickets" className="menu-sidebar-link" onClick={this.onTransition} id="menu_qa_tickets">QA Support Tickets</Link>
              <Link to="/qa/ota/channels" className="menu-sidebar-link" onClick={this.onTransition} id="menu_qa_ota">Manage OTA Channels</Link>
              <Link to="/devices/secrets/generate" className="menu-sidebar-link" onClick={this.onTransition} id="menu_qa_generate_device_secret">Generate Fake Device</Link>
              <Link to="/gpstests" className="menu-sidebar-link" onClick={this.onTransition} id="menu_qa_gps_tests">GPS Tests</Link>
              <div className="menu-divider" />
            </div>
          )}

          {(this.props.userState.user.isAdministrator || this.props.userState.user.isReporting) && (
            <div>
              <span className="menu-link-header">Reports</span>
              <Link to="/reports/dashboard" className="menu-sidebar-link" onClick={this.onTransition} id="menu_reports_dashboard">Dashboard</Link>
              <Link to="/reports/breeds/activities" className="menu-sidebar-link" onClick={this.onTransition} id="menu_reports_breed_actitivies">Breed Activity Reports</Link>
              <Link to="/reports/pets/rankings" className="menu-sidebar-link" onClick={this.onTransition} id="menu_reports_rankings">Rankings</Link>
              <Link to="/reports/orders" className="menu-sidebar-link" onClick={this.onTransition} id="menu_reports_orders">Customer Orders</Link>
              <Link to="/gpstests" className="menu-sidebar-link" onClick={this.onTransition} id="menu_reports_gps_tests">GPS Tests</Link>
              <Link to="/reports/analytics" className="menu-sidebar-link" onClick={this.onTransition} id="menu_reports_analytics">Analytics</Link>
              <Link to="/reports/jobs" className="menu-sidebar-link" onClick={this.onTransition} id="menu_reports_jobs">Jobs</Link>
              <Link to="/reports/queries" className="menu-sidebar-link" onClick={this.onTransition} id="menu_product_queries">Query Engine</Link>
              <div className="menu-divider" />
            </div>
          )}


          {(this.props.userState.user.isAdministrator || this.props.userState.user.isFirmware) && (
            <div>
              <span className="menu-link-header">Firmware</span>
              <Link to="/firmware/releases" className="menu-sidebar-link" onClick={this.onTransition} id="menu_firmware_releasess">FWOS Releases</Link>
              <Link to="/firmware/ota/downloads" className="menu-sidebar-link" onClick={this.onTransition} id="menu_firmware_ota_downloads">OTA Downloads</Link>
              <div className="menu-divider" />
            </div>
          )}

          <span className="menu-link-header">Other</span>
          <Link to="/errors/codes" className="menu-sidebar-link" onClick={this.onTransition} id="menu_error_codes">Error Codes</Link>
          <Link to="/events" className="menu-sidebar-link" onClick={this.onTransition} id="menu_all_events">All Events</Link>


          <span className="menu-link-header">Account</span>
          <Link to="/me" className="menu-sidebar-link" onClick={this.onTransition} id="menu_profile">My Profile</Link>
          <Link to="/me/devices" className="menu-sidebar-link" onClick={this.onTransition} id="menu_my_devices">My Devices</Link>
          <Link to="/me/mobile" className="menu-sidebar-link" onClick={this.onTransition} id="menu_my_mobile_devices">My Mobile Devices</Link>
          <Link to="#" className="menu-sidebar-link" onClick={this.logout} id="menu_logout">Logout</Link>
        </div>
      </div>
    );
  }

  public render() {
    return (
      <Sidebar
        sidebar={this.links}
        open={this.props.appState.showMenu}
        onSetOpen={this.onTransition}
        rootClassName="menu-root"
        sidebarClassName="menu-sidebar"
        contentClassName="menu-content-holder"
        overlayClassName="menu-overlay"
        >
        {this.props.children}
      </Sidebar>
    );
  }

}

const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    userState: s.userState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch),
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default withRouter(connect<{}, {}, any>(mapStateToProps, mapDispatchToProps)(Menu));
