import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ListGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDollarSign } from "@fortawesome/free-solid-svg-icons";
import { faStripe } from "@fortawesome/free-brands-svg-icons";
import moment from "moment";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

import * as UserActions from "src/reducers/userReducer";
import { IUser } from "src/api/users";
import { BillingAPI, getUrl, UserAPI } from "src/api";
import Card from "src/components/structure/Card";
import * as Alert from "src/components/structure/Alert";
import { CurrentPaymentMethod, IPaymentMethod } from "src/components/structure/CurrentPaymentMethod";
import StripeLogs from "src/components/structure/StripeLogs";

interface IBillingTabProps {
  user: IUser;
  match: any;
  location: any;
  userActions: any;
  userState: any;
}

interface IBillingTabState {
  userBillingLoading: boolean;
  changeBillingLoading: boolean;

  stripeCustomerUrl: string;
  chargifyCustomerUrl: string;

  addresses: any[];
  selectedAddress: number;

  newPaymentNumber: string;
  newPaymentFirstNameOnCard: string;
  newPaymentLastNameOnCard: string;
  newPaymentExpMonth: string;
  newPaymentExpYear: string;
  newPaymentCVC: string;

  hasPaymentInfo: boolean;
  paymentInfo: IPaymentMethod;
  coupons: any[];
}

class BillingTab extends Component<IBillingTabProps, IBillingTabState> {
  constructor(props: IBillingTabProps) {
    super(props);

    this.state = {
      userBillingLoading: true,
      changeBillingLoading: false,
      coupons: [],
      addresses: [],
      selectedAddress: 0,

      stripeCustomerUrl: "",
      chargifyCustomerUrl: "",

      newPaymentNumber: "",
      newPaymentFirstNameOnCard: "",
      newPaymentLastNameOnCard: "",
      newPaymentExpMonth: moment().format("MM"),
      newPaymentExpYear: moment().format("YYYY"),
      newPaymentCVC: "",

      hasPaymentInfo: false,
      paymentInfo: {
        brand: "",
        firstName: "",
        lastName: "",
        cvv: "",
        expMonth: "",
        expYear: "",
        lastFour: "",
      },
    };

    this.updateField = this.updateField.bind(this);
    this.updatePaymentMethod = this.updatePaymentMethod.bind(this);
    this.fetchBillingInfo = this.fetchBillingInfo.bind(this);
  }

  updateField(e: any) {
    let ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  componentDidMount() {
    this.fetchBillingInfo();
  }

  render() {
    return (
      <div className="row">
        <div className="col-4">
          <Card
            title="Current Billing Info"
            loading={this.state.userBillingLoading}
          >
            {this.state.hasPaymentInfo ? (
              <CurrentPaymentMethod
                brand={this.state.paymentInfo.brand}
                lastFour={this.state.paymentInfo.lastFour}
                expMonth={this.state.paymentInfo.expMonth}
                expYear={this.state.paymentInfo.expYear}
                firstName={this.props.user.firstName}
                lastName={this.props.user.lastName}
              />
            ) : (
              <div>
                <p>
                  User does not have any payment information on their account.
                </p>
              </div>
            )}
          </Card>
        </div>
        <div className="col-4">
          <Card
            title="Change Billing Info"
            loading={this.state.changeBillingLoading}
          >
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <label>Select A Billing Address</label>
                  <select
                    id="selectedAddress"
                    disabled={
                      this.props.userState.user.accessLevel === "read_only"
                    }
                    className="form-control"
                    autoComplete="new-password"
                    value={this.state.selectedAddress}
                    onChange={this.updateField}
                  >
                    <option value={0}>Select an address for the card</option>
                    {this.state.addresses.map((a) => {
                      return (
                        <option
                          key={a.id}
                          value={a.id}
                        >{`(${a.addressType}) - ${a.street1}, ${a.city}, ${a.state} ${a.zip}`}</option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
            {this.state.addresses.length === 0 ? (
              <div className="row">
                <div className="col-12">
                  <p>
                    A user must have an address in order to change their billing
                    information. Please go to the Manage Tab and choose to
                    manage their addresses. Once an address is saved, please
                    return here to continue.
                  </p>
                </div>
              </div>
            ) : (
              <div>
                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <label>First Name on Card</label>
                      <input
                        type="text"
                        id="newPaymentFirstNameOnCard"
                        disabled={
                          this.props.userState.user.accessLevel === "read_only"
                        }
                        className="form-control"
                        autoComplete="new-password"
                        value={this.state.newPaymentFirstNameOnCard}
                        onChange={this.updateField}
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label>Last Name on Card</label>
                      <input
                        type="text"
                        id="newPaymentLastNameOnCard"
                        disabled={
                          this.props.userState.user.accessLevel === "read_only"
                        }
                        className="form-control"
                        autoComplete="new-password"
                        value={this.state.newPaymentLastNameOnCard}
                        onChange={this.updateField}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <label>Number</label>
                      <input
                        type="text"
                        id="newPaymentNumber"
                        disabled={
                          this.props.userState.user.accessLevel === "read_only"
                        }
                        className="form-control"
                        autoComplete="new-password"
                        value={this.state.newPaymentNumber}
                        onChange={this.updateField}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <div className="form-group">
                      <label>Expiration Month</label>
                      <select
                        id="newPaymentExpMonth"
                        disabled={
                          this.props.userState.user.accessLevel === "read_only"
                        }
                        className="form-control"
                        value={this.state.newPaymentExpMonth}
                        onChange={this.updateField}
                      >
                        <option value="01">01</option>
                        <option value="02">02</option>
                        <option value="03">03</option>
                        <option value="04">04</option>
                        <option value="05">05</option>
                        <option value="06">06</option>
                        <option value="07">07</option>
                        <option value="08">08</option>
                        <option value="09">09</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="form-group">
                      <label>Expiration Year</label>
                      <select
                        id="newPaymentExpYear"
                        disabled={
                          this.props.userState.user.accessLevel === "read_only"
                        }
                        className="form-control"
                        value={this.state.newPaymentExpYear}
                        onChange={this.updateField}
                      >
                        <option value="2021">2021</option>
                        <option value="2022">2022</option>
                        <option value="2023">2023</option>
                        <option value="2024">2024</option>
                        <option value="2025">2025</option>
                        <option value="2026">2026</option>
                        <option value="2027">2027</option>
                        <option value="2028">2028</option>
                        <option value="2029">2029</option>
                        <option value="2030">2030</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="form-group">
                      <label>CVC</label>
                      <input
                        type="text"
                        id="newPaymentCVC"
                        disabled={
                          this.props.userState.user.accessLevel === "read_only"
                        }
                        className="form-control"
                        autoComplete="new-password"
                        value={this.state.newPaymentCVC}
                        onChange={this.updateField}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  {this.props.userState.user.accessLevel === "read_only" ? (
                    <button
                      className="btn btn-block btn-primary"
                      disabled={true}
                    >
                      You do not have permission to change this
                    </button>
                  ) : (
                    <button
                      className="btn btn-block btn-primary"
                      onClick={this.updatePaymentMethod}
                    >
                      Update Payment Method
                    </button>
                  )}
                </div>
              </div>
            )}
          </Card>
        </div>
        <div className="col-4">
          <Card
            title="External Billing Info"
            loading={this.state.changeBillingLoading}
          >
            <ListGroup variant="flush">
              <ListGroup.Item>
                <div className="row">
                  <div className="col-1">
                    <FontAwesomeIcon icon={faStripe} />
                  </div>
                  <div className="col-11">
                    {this.state.stripeCustomerUrl !== "" ? (
                      <a href={this.state.stripeCustomerUrl} target="_new">
                        View on Stripe
                      </a>
                    ) : (
                      "None Found"
                    )}
                  </div>
                </div>
              </ListGroup.Item>
              <ListGroup.Item>
                <div className="row">
                  <div className="col-1">
                    <FontAwesomeIcon icon={faDollarSign} />
                  </div>
                  <div className="col-11">
                    {this.state.chargifyCustomerUrl !== "" ? (
                      <a href={this.state.chargifyCustomerUrl} target="_new">
                        View on Chargify
                      </a>
                    ) : (
                      "None Found"
                    )}
                  </div>
                </div>
              </ListGroup.Item>
            </ListGroup>
          </Card>
          <div style={{ marginTop: 10 }}>
            <Card title="Coupons for User">{this.showCoupons()}</Card>
          </div>
          <div style={{ marginTop: 10 }}>
          <Card
            title="Recent Payment Logs"
            loading={this.state.userBillingLoading}
          >
            <StripeLogs userId={this.props.user.id}/>
            </Card>
          </div>
        </div>
      </div>
    );
  }

  private fetchBillingInfo() {
    this.setState({ userBillingLoading: true }, async () => {
      // first, get the addresses; we want these regardless
      let addresses: any[] = [];
      try {
        const addressesResult = await UserAPI.getUserAddresses(
          "wagz2",
          this.props.user.id
        );
        addresses = addressesResult.body.data;
      } catch (err) {
        // we can fall through, they just won't be able to change the billing
        console.log(err);
      }
      try {
        const billingResult = await BillingAPI.getBillingInformation(
          "wagz2",
          this.props.user.id
        );
        const billingData = billingResult.body.data;
        const stripeCustomerUrl =
          billingData.stripeCustomerToken &&
          billingData.stripeCustomerToken !== ""
            ? getUrl("stripe", "customer", {
                customerId: billingData.stripeCustomerToken,
              })
            : "";
        const chargifyCustomerUrl =
          billingData.chargifyCustomerId &&
          billingData.chargifyCustomerId !== ""
            ? getUrl("chargify", "customer", {
                customerId: billingData.chargifyCustomerId,
              })
            : "";
        let paymentInfo = this.state.paymentInfo;
        let hasPaymentInfo = false;
        if (billingData.paymentMethod && billingData.paymentMethod.brand) {
          paymentInfo = billingData.paymentMethod;
          hasPaymentInfo = true;
        }
        const coupons = await BillingAPI.getCouponsForUserId(
          "wagz2",
          this.props.user.id
        );
        this.setState({
          coupons: coupons.body.data,
          userBillingLoading: false,
          stripeCustomerUrl,
          chargifyCustomerUrl,
          hasPaymentInfo,
          paymentInfo,
          addresses,
        });
      } catch (err) {
        this.setState({ userBillingLoading: false, addresses });
      }
    });
  }

  private updatePaymentMethod() {
    if (this.state.selectedAddress === 0) {
      return Alert.error(
        "You must select an address for that payment information."
      );
    }
    const paymentInfo = {
      number: this.state.newPaymentNumber,
      cvc: this.state.newPaymentCVC,
      exp_month: this.state.newPaymentExpMonth,
      exp_year: this.state.newPaymentExpYear,
    };
    if (paymentInfo.number === "" || paymentInfo.cvc === "") {
      return Alert.error("Number and CVC are required");
    }
    this.setState({ changeBillingLoading: true }, async () => {
      try {
        // try to save the card and update the server
        (window as any).Stripe.card.createToken(
          paymentInfo,
          async (status: any, response: any) => {
            if (status !== 200) {
              return this.setState({ changeBillingLoading: false }, () => {
                Alert.error(response.error.message);
              });
            } else {
              let address: any = {};
              const selectedAddressIdInt = parseInt(
                this.state.selectedAddress + ""
              );
              for (const a of this.state.addresses) {
                if (a.id === selectedAddressIdInt) {
                  address = a;
                  break;
                }
              }
              const extra = {
                paymentToken: response.id,
                name: `${this.state.newPaymentFirstNameOnCard} ${this.state.newPaymentLastNameOnCard}`,
                expMonth: response.card.exp_month,
                expYear: response.card.exp_year,
                type: response.card.brand,
                lastFour: response.card.last4,
                email: this.props.user.email,
                paymentMethod: {
                  addressLine1: address.street1,
                  addressLine2: address.street2,
                  addressCity: address.city,
                  addressState: address.state,
                  addressZip: address.zip,
                },
              };

              try {
                await BillingAPI.saveBillingInformation(
                  "wagz2",
                  this.props.user.id,
                  extra.paymentToken,
                  extra
                );
                Alert.success("Payment information saved!");
                this.setState(
                  {
                    newPaymentCVC: "",
                    newPaymentExpMonth: moment().format("MM"),
                    newPaymentExpYear: moment().format("YYYY"),
                    newPaymentFirstNameOnCard: "",
                    newPaymentLastNameOnCard: "",
                    newPaymentNumber: "",
                    changeBillingLoading: false,
                  },
                  () => this.fetchBillingInfo()
                );
              } catch (err) {
                return this.setState({ changeBillingLoading: false }, () => {
                  Alert.error(
                    "We could not update that payment information. Please verify the information or contact Engineering."
                  );
                });
              }
            }
          }
        );
      } catch (err) {}
    });
  }

  processValue(value: number, type: string) {
    if (type === "flat") {
      var flat = value / 100;
      return `$${flat.toFixed(2)}`;
    }
    return `${value}%`;
  }

  showCoupons() {
    const userCoupons = [];
    if (this.state.coupons.length === 0) {
      userCoupons.push(<strong key={1}>No coupons exist for this user</strong>);
    } else {
      let index = 0;
      for (let coups of this.state.coupons) {
        index += 1;
        userCoupons.push(
          <div key={index}>
            <Accordion allowZeroExpanded={true} allowMultipleExpanded={true}>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>{coups.couponCode}</AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <div className="row">
                    <div style={{ width: "80%" }}>
                      <div style={{ marginLeft: 20, marginTop: 20 }}>
                        <div>
                          <strong>Coupon Code: </strong>
                          {coups.couponCode}
                        </div>
                        <div>
                          <strong>Status: </strong>
                          {coups.status}
                        </div>
                        <div>
                          <strong>Value:</strong>{" "}
                          {this.processValue(
                            coups.couponValue,
                            coups.couponType
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </div>
        );
      }
    }
    return userCoupons;
  }
}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default connect<{}, {}, any>(
  mapStateToProps,
  mapDispatchToProps
)(BillingTab);
