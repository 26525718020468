import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as AppActions from "src/reducers/appReducer";

interface IResponseSizeProps {
  size: number;
}

interface IResponseSizeState {
  color: string;
}

class ResponseSize extends Component<
  IResponseSizeProps,
  IResponseSizeState
> {
  constructor(props: IResponseSizeProps) {
    super(props);
    this.state = {
      color: ""
    };

    this.decideColor = this.decideColor.bind(this);
  }

  componentDidMount() {
    this.decideColor();
  }

  decideColor() {
    let color = "#008000"
    const z = this.props.size
    if (z>=8192 && z<14336 ) {
      color = "#FFD700"
    } else if (z>=14336 && z<16384 ) {
      color = "#F87431"
    } else if (z >= 16384) {
      color = " #DA2232"
    }
    this.setState({ color });
  }

  public render() {
    return (
      <div style={{ fontWeight: 500, marginLeft: 20, marginTop: 20 }}><p>Response size: <strong style={{ color: this.state.color }}>{this.props.size}</strong> bytes</p></div>
    )
  }
}

const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResponseSize);

