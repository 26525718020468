import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as AppActions from "src/reducers/appReducer";
import * as UserActions from "src/reducers/userReducer";
import Table from "src/components/structure/Table";
import DatePicker from "src/components/structure/DatePicker";
import moment from "moment";
import { IColumns } from "src/utils/customTypes";
import { PetsAPI } from "src/api";

interface IDailyMetricsProps {
  loading: boolean;
  pet: number;
  user: number;
}

interface IDailyMetricsState {
  loading: boolean;
  userId: any;
  showDaily: boolean;
  day: any;
  columns: IColumns[];
  dailyRows: any[];
}

class DailyMetricsScreen extends Component<
  IDailyMetricsProps,
  IDailyMetricsState
> {
  constructor(props: any) {
    super(props);

    this.state = {
      loading: false,
      userId: "",
      showDaily: false,
      day: moment(),
      dailyRows: [],
      columns: [
        {
          label: "Date",
          field: "date",
          sort: "asc",
        },
        {
          label: "Fit Units",
          field: "fitUnits",
          sort: "asc",
        },
        {
          label: "Temp High",
          field: "tempHigh",
          sort: "asc",
        },
        {
          label: "Temp Low",
          field: "tempLow",
          sort: "asc",
        },
        {
          label: "HH Score",
          field: "healthHappinessScore",
          sort: "asc",
        },
        {
          label: "Steps",
          field: "steps",
          sort: "asc",
        },
        {
          label: "Cell Mins",
          field: "cellMinutes",
          sort: "asc",
        },
        {
          label: "Wi-fi Mins",
          field: "wifiMinutes",
          sort: "asc",
        },
        {
          label: "Companion Mins",
          field: "companionMinutes",
          sort: "asc",
        },
        {
          label: "Exercise Mins",
          field: "exerciseMinutes",
          sort: "asc",
        },
        {
          label: "Sleep Mins",
          field: "sleepMinutes",
          sort: "asc",
        },
        {
          label: "Manual Corr.",
          field: "manualCorrections",
          sort: "asc",
        },
        {
          label: "Geofence Corr.",
          field: "geofenceCorrections",
          sort: "asc",
        },
        {
          label: "Leash Corr.",
          field: "leashCorrections",
          sort: "asc",
        },
        {
          label: "Tagz Corr.",
          field: "shieldViolations",
          sort: "asc",
        },
      ],
    };

    this.handleDateChanged = this.handleDateChanged.bind(this);
    this.getDailyMetrics = this.getDailyMetrics.bind(this);
  }

  componentDidMount() {
    this.getDailyMetrics();
  }

  getDailyMetrics() {
    this.setState({ loading: true }, async () => {
      try {
        const start = moment(this.state.day).startOf("day").utc().format("YYYY-MM-DDTHH:mm:ss") + "Z";
        const end = moment(this.state.day).endOf("day").utc().format("YYYY-MM-DDTHH:mm:ss") + "Z";
        const results = await PetsAPI.getPetMetrics(
          "wagz",
          this.props.user,
          this.props.pet,
          {
            start,
            end
          }
        );

        let fitUnits = 0, tempHigh = 0, tempLow = 120, hh = 0, hhCount = 0, steps = 0, cellMins = 0, wifiMins = 0, companionMins = 0, exerciseMins = 0, sleepMins = 0, manual = 0, geofence = 0, leash = 0;

        for (let result of results.body.data) {
          if (result.temperature.high > tempHigh) {
            tempHigh = result.temperature.high;
          }
          if (result.temperature.low < tempLow) {
            tempLow = result.temperature.low;
          }
          fitUnits += result.fitUnits;
          hh += result.healthHappinessScore.total;
          steps += result.steps;
          cellMins += result.exerciseMinutes;
          wifiMins += result.wifiMinutes;
          companionMins += result.companionMinutes;
          exerciseMins += result.exerciseMinutes;
          sleepMins += result.sleepMinutes;
          manual += result.manualCorrections;
          geofence += result.geofenceCorrections;
          leash += result.leashCorrections;
          hhCount++;
        }
        const dailyPetMetrics = [{
          date: this.state.day.format("MM-DD-YYYY"),
          fitUnits: Math.round(fitUnits),
          tempHigh: tempHigh,
          tempLow: tempLow,
          healthHappinessScore: Math.round(hh / hhCount),
          steps: steps,
          cellMinutes: cellMins,
          wifiMinutes: wifiMins,
          companionMinutes: companionMins,
          exerciseMinutes: exerciseMins,
          sleepMinutes: sleepMins,
          manualCorrections: manual,
          geofenceCorrections: geofence,
          leashCorrections: leash
        }];
        this.setState({
          loading: false,
          dailyRows: dailyPetMetrics,
        });
      } catch (err) {
        this.setState({ loading: false });
      }
    });
  }

  render() {
    return (
      <div style={{ marginBottom: 20 }}><div>
        <div
          className="col 12 col-lg-12"
          style={{ marginBottom: 30, marginTop: 15 }}
        >
          <div className="row">
            <div className="col-12">
              <div className="row">
                <label>Day Select:</label>
                <div className="col-3">
                  <DatePicker
                    date={this.state.day}
                    onDateSaved={this.handleDateChanged}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <p>* Note: Date is local time</p>
        <div className="row">
          <div className="col-12">
            <Table
              title="Daily Metrics"
              csvFileName={`metricsFor-petID-${this.props.pet}`}
              showDownload={false}
              columns={this.state.columns}
              rows={this.state.dailyRows}
            />
          </div>
        </div>
      </div>
      </div>
    );
  }

  private handleDateChanged(newDate: moment.Moment) {
    this.setState({ day: newDate }, this.getDailyMetrics);
  }

}

const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(AppActions, dispatch),
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DailyMetricsScreen);
