import React, { Component } from "react";
import {  withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Screen from "src/components/structure/Screen";
import * as Alert from "src/components/structure/Alert";
import * as UserActions from "src/reducers/userReducer";
import Table from "src/components/structure/Table";
import { DeviceAPI } from "src/api";
import { BlankDeviceSecret, IDeviceSecret } from "src/api/devices";
import moment from "moment";
import { Modal } from "react-bootstrap";
import { Card } from "src/components/structure";

interface IManageDeviceSecretsScreenProps {
  location: any;
  userActions: any;
  userState: any;
}

interface IManageDeviceSecretsScreenState {
  loading: boolean;
  secrets: IDeviceSecret[],

  showEditModal: boolean;
  selectedSecret: IDeviceSecret;
  otaChannel: string;
  selectedFamilyId: string;
}

class ManageDeviceSecretsScreen extends Component<IManageDeviceSecretsScreenProps, IManageDeviceSecretsScreenState> {
  constructor(props: IManageDeviceSecretsScreenProps) {
    super(props);

    this.state = {
      loading: false,
      secrets: [],
      showEditModal: false,
      selectedSecret: BlankDeviceSecret,
      otaChannel: "",
      selectedFamilyId: "23",
    };

    this.updateField = this.updateField.bind(this);
    this.fetch = this.fetch.bind(this);
    this.updateSecret = this.updateSecret.bind(this);
    this.toggleShowEditModal = this.toggleShowEditModal.bind(this);
  }

  private columns = [
    {
      label: "Family",
      field: "productFamilyId",
    },
    {
      label: "Serial",
      field: "productId",
    },
    {
      label: "IMEI",
      field: "imei",
    },
    {
      label: "ICCID",
      field: "iccid",
    },
    {
      label: "SOM",
      field: "som",
    },
    {
      label: "Hardware ID",
      field: "hardwareId",
    },
    {
      label: "OTA",
      field: "otaChannel",
    },
    {
      label: "Inserted",
      field: "inserted",
    },
    {
      label: "Activated",
      field: "lastActivatedOn",
    },
    {
      label: "Actions",
      field: "actions",
    },
  ];

  componentDidMount(){
    this.fetch();
  }

  render() {
    return (
      <Screen id="manageDeviceSecretsScreen" requiredRoles={[]}>
        <div className="row" style={{ marginBottom: 10}}>
            <div className="col-12">
              <Card title="Secret Filters">
                <div className="row">
                  <div className="col-4">
                    <label>Show Family:</label>
                    <select className="form-control" value={this.state.selectedFamilyId} id="selectedFamilyId" onChange={this.updateField}>
                      <option value="all">All</option>
                      <option value="13">Go Door (13)</option>
                      <option value="14">Serve Feeder (14)</option>
                      <option value="17">Pendant (17)</option>
                      <option value="18">Tagz 2019 (18)</option>
                      <option value="19">Explore Collar (19)</option>
                      <option value="23">Freedom Collar (23)</option>
                      <option value="24">Cat Collar (24)</option>
                      <option value="25">Tracking Collar (25)</option>
                      <option value="26">HealthBox (26)</option>
                      <option value="27">Tagz - 2022 (27)</option>
                      <option value="30">Connect Collar (30)</option>
                      <option value="31">Bowl (31)</option>              
                    </select>
                  </div>
                  <div className="col-4">
                    <button className="btn btn-block btn-primary" onClick={this.fetch} style={{marginTop: 23}}>Update Filters</button>
                  </div>
                </div>
              </Card>
            </div>         
        </div>
        <div className="row">
          <div className="col-12">
            <Table 
              loading={this.state.loading}
              title="Secrets"
              csvFileName="deviceSecrets"
              showDownload={false}
              columns={this.columns}
              rows={this.state.secrets}
            />
          </div>
        </div>

        <Modal show={this.state.showEditModal} onHide={this.toggleShowEditModal}>
            <Modal.Header closeButton>
              <Modal.Title>
                Generated Device Secret
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Currently, we only allow you to set an OTA channel. This is a text field so that you can edit the field to an OTA channel that may not exist yet.</p>
              <div className="form-group">
                <label htmlFor="otaChannel">OTA Channel:</label>
                <input type="text" id="otaChannel" className="form-control" onChange={this.updateField} value={this.state.otaChannel} />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-block btn-success" onClick={this.updateSecret}>Update</button>
              <button className="btn btn-block btn-primary" onClick={this.toggleShowEditModal}>Nevermind</button>
            </Modal.Footer>
          </Modal>
      </Screen>
    )
  }

  updateField(e: any) {
    let ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private fetch() {
    this.setState({ loading: true }, async () => {
      try{
        const data: any = {};
        if(this.state.selectedFamilyId !== "all"){
          data.productFamilyId = this.state.selectedFamilyId;
        }

        const result = await DeviceAPI.getDeviceSecrets(data);
        const secrets: IDeviceSecret[] = [];
        let hasEditPermission = false;
        for(const r of this.props.userState.user.roles){
          if(r === "administrator" || r === "backend"){
            hasEditPermission = true;
          }
        }

        for(const s of result.body.data){
          const secret = {
            ...s,
            inserted: moment(s.inserted).format("YYYY-MM-DD"),
            lastActivatedOn: s.lastActivatedOn === "1970-01-01T00:00:00Z" ? "--" : moment(s.lastActivatedOn).format("YYYY-MM-DD"),
            actions: hasEditPermission ? (<button className="btn btn-pri" onClick={() => {this.setState({ selectedSecret: secret, showEditModal: true})}}>Edit</button>) : null,
          };
          secrets.push(secret);
        }
        this.setState({ loading: false, secrets });
      }catch(err){
        Alert.error("Could not fetch any secrets");
        this.setState({ loading: false });
      }
    })
  }

  private updateSecret(){
    const data = {
      otaChannel: this.state.otaChannel && this.state.otaChannel.trim() !== "" ? this.state.otaChannel.trim() : "default"
    };
    this.setState({ loading: true }, async () => {
      try{ 
        await DeviceAPI.updateDeviceSecret(this.state.selectedSecret.productId, data);
        // find it and update it in the table
        const secrets: IDeviceSecret[] = [];
        for(const s of this.state.secrets){
          if(s.productId === this.state.selectedSecret.productId){
            s.otaChannel = this.state.otaChannel;
          }
          secrets.push(s);
        }
        this.setState({ secrets, showEditModal: false, loading: false });
      }catch(err){
        Alert.error("Could not update that secret");
        this.setState({ loading: false });
      }
    })
  }

  private toggleShowEditModal(){
    this.setState({ showEditModal: !this.state.showEditModal});
  }

}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ManageDeviceSecretsScreen) as any
);
