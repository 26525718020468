import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as UserActions from "src/reducers/userReducer";
import { UserAPI } from "src/api";
import { Address, BlankAddress } from "src/api/users";
import { capitalizeFirstLetter } from "src/utils/utilities";
import { Modal, Table } from "react-bootstrap";
import * as Alert from "src/components/structure/Alert";
import { ManageTabAddress } from "./ManageTabAddress";
import { styles } from "src/styles";
import { IUser } from "src/api/users";
import CacheManagementScreen from "src/components/screens/Backend/CacheManagement";
import UserOTPScreen from "src/components/structure/UserOTP";

interface IManageTabProps {
  userId: number;
  user: IUser;
  location: any;
  userActions: any;
  userState: any;
  onProfileUpdated: any;
}

interface IManageTabState {
  loading: boolean;
  firstName: string;
  lastName: string;
  email: string;
  homePhone: string;
  mobilePhone: string;
  status: string;
  newStatus: string;
  newPassword: string;
  showInfoModal: boolean;
  showAddressModal: boolean;
  addressMailing: Address;
  addressBilling: Address;
  addressEmergency: Address;
  initialFirstNameValue: string;
  initialLastNameValue: string;
  initialMobilePhoneValue: string;
  initialHomePhoneValue: string;

  hasJWTPermission: boolean;
  showJWTModal: boolean;
  jwt: string;
  showDeleteModal: boolean;
  showRestoreModal: boolean;
}

class ManageTab extends Component<IManageTabProps, IManageTabState> {
  constructor(props: IManageTabProps) {
    super(props);

    this.state = {
      loading: false,
      firstName: "",
      lastName: "",
      email: "",
      homePhone: "",
      mobilePhone: "",
      status: "",
      newStatus: "",
      newPassword: "",
      showInfoModal: false,
      showAddressModal: false,
      addressMailing: { ...BlankAddress },
      addressBilling: { ...BlankAddress },
      addressEmergency: { ...BlankAddress },
      initialFirstNameValue: "",
      initialLastNameValue: "",
      initialMobilePhoneValue: "",
      initialHomePhoneValue: "",

      hasJWTPermission: false,
      showJWTModal: false,
      jwt: "",
      showDeleteModal: false,
      showRestoreModal: false,
    };

    this.updateField = this.updateField.bind(this);
    this.getUserInfo = this.getUserInfo.bind(this);
    this.updateAccountStatus = this.updateAccountStatus.bind(this);
    this.startReset = this.startReset.bind(this);
    this.manualReset = this.manualReset.bind(this);
    this.updateUserInfo = this.updateUserInfo.bind(this);
    this.updateUserAddress = this.updateUserAddress.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
    this.restoreUser = this.restoreUser.bind(this);
    this.phone = this.phone.bind(this);
    this.handleAddressSaved = this.handleAddressSaved.bind(this);

    this.toggleShowJWTModal = this.toggleShowJWTModal.bind(this);
    this.generateJWT = this.generateJWT.bind(this);
    this.toggleShowDeleteModal = this.toggleShowDeleteModal.bind(this);
    this.toggleShowRestoreModal = this.toggleShowRestoreModal.bind(this);
    this.delay = this.delay.bind(this);
  }

  componentDidMount() {
    this.getUserInfo();
  }

  updateField(e: any) {
    let ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  getUserInfo() {
    this.setState({ loading: true }, async () => {
      try {
        const userResult = await UserAPI.getUserById("wagz", this.props.userId);
        const addressResult = await UserAPI.getUserAddresses(
          "wagz",
          this.props.userId
        );
        const state: any = {
          loading: false,
          firstName: userResult.body.data.firstName,
          lastName: userResult.body.data.lastName,
          homePhone: userResult.body.data.homePhone,
          mobilePhone: userResult.body.data.mobilePhone,
          email: userResult.body.data.email,
          status: userResult.body.data.status,
          addressMailing: { ...BlankAddress },
          addressBilling: { ...BlankAddress },
          addressEmergency: { ...BlankAddress },
          initialFirstNameValue: userResult.body.data.firstName,
          initialLastNameValue: userResult.body.data.lastName,
          initialMobilePhoneValue: userResult.body.data.mobilePhone,
          initialHomePhoneValue: userResult.body.data.homePhone,
        };
        for (const a of addressResult.body.data) {
          if (a.addressType === "mailing") {
            state.addressMailing = a;
          } else if (a.addressType === "billing") {
            state.addressBilling = a;
          } else if (a.addressType === "emergency") {
            state.addressEmergency = a;
          }
        }
        let hasJWTPermission = false;
        for (const r of this.props.userState.user.roles) {
          if (r === "administrator" || r === "backend") {
            hasJWTPermission = true;
            break;
          }
        }
        state.hasJWTPermision = hasJWTPermission;
        this.setState(state);
      } catch (err) {
        this.setState({ loading: false });
      }
    });
  }

  updateAccountStatus() {
    this.setState({ loading: true }, async () => {
      const profileInfo = {
        status: this.state.newStatus,
      };
      try {
        await UserAPI.updateUserProfile("wagz", this.props.userId, profileInfo);
        Alert.success("Status has been updated");
        this.setState({ loading: false, status: this.state.newStatus });
      } catch (error) {
        this.setState({ loading: false });
        Alert.error(
          "Hmm, that doesn't seem to be working right now.  Try again."
        );
      }
    });
  }

  startReset() {
    this.setState({ loading: true }, async () => {
      try {
        await UserAPI.startPasswordReset("wagz", this.state.email);
        Alert.success("Reset password email has been sent!");
        this.setState({ loading: false });
      } catch (error) {
        Alert.error("We couldn't start the process.");
        this.setState({ loading: false });
      }
    });
  }

  manualReset() {
    this.setState({ loading: true }, async () => {
      try {
        await UserAPI.manualPasswordReset("wagz", this.props.userId, {
          password: this.state.newPassword,
        });
        Alert.success("Password has been reset");
        this.setState({ loading: false, newPassword: "" });
      } catch (error) {
        this.setState({ loading: false });
        Alert.error(
          "Hmm, that password doesn't seem to be strong enough for us.  Try again."
        );
      }
    });
  }

  phone(phone: string) {
    if (phone.length === 10) {
      return `+1${phone}`;
    } else if (phone.length === 11 && phone.charAt(0) === "1") {
      return `+${phone}`;
    } else return phone;
  }

  updateUserInfo() {
    this.setState({ loading: true }, async () => {
      const profileInfo = {
        firstName: this.state.firstName.trim(),
        lastName: this.state.lastName.trim(),
        homePhone: this.phone(this.state.homePhone.trim()),
        mobilePhone: this.phone(this.state.mobilePhone.trim()),
      };
      if (profileInfo.firstName === "" || profileInfo.lastName === "") {
        Alert.error("First name and last name cannot be blank");
        return;
      }
      if (
        profileInfo.mobilePhone.length < 10 &&
        this.state.initialMobilePhoneValue !== ""
      ) {
        Alert.error(
          "That phone number is too short.  Please be sure to include the area code!"
        );
        return;
      }
      if (
        profileInfo.homePhone === "" &&
        this.state.initialHomePhoneValue !== ""
      ) {
        Alert.error(
          "Phone numbers can not be blank and thus were reverted to their original values on update"
        );
      }

      try {
        await UserAPI.updateUserProfile("wagz", this.props.userId, profileInfo);
        // call the props to update everything all the way up
        this.props.onProfileUpdated({
          firstName: profileInfo.firstName,
          lastName: profileInfo.lastName,
        });
        if (
          profileInfo.firstName !== this.state.initialFirstNameValue ||
          profileInfo.lastName !== this.state.initialLastNameValue
        ) {
          if (
            profileInfo.homePhone === "" &&
            this.state.initialHomePhoneValue !== ""
          ) {
            return;
          }
          Alert.success("Profile has been updated!");
        }
        if (
          profileInfo.firstName === this.state.initialFirstNameValue &&
          profileInfo.lastName === this.state.initialLastNameValue &&
          profileInfo.mobilePhone === this.state.initialMobilePhoneValue &&
          profileInfo.homePhone === this.state.initialHomePhoneValue
        ) {
          Alert.error("No changes to update");
        }
      } catch (err) {
        Alert.error(
          "We could not update that information. Please check and try again"
        );
      } finally {
        this.setState({ loading: false, showInfoModal: false });
      }
    });
  }

  updateUserAddress() {
    Alert.error("501: Not yet implemented");
    this.setState({ showAddressModal: false });
  }

  private toggleShowJWTModal() {
    this.setState({ showJWTModal: !this.state.showJWTModal });
  }

  private generateJWT() {
    this.setState({ loading: true }, async () => {
      try {
        const result = await UserAPI.generateJWT(
          "wagz2",
          this.props.user.id,
          this.props.user.email
        );
        this.setState({
          loading: false,
          jwt: result.body.data.jwt,
          showJWTModal: true,
        });
      } catch (err) {
        Alert.error("Could not generate a JWT for that user.");
        this.setState({ loading: false });
      }
    });
  }

  private delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  private toggleShowDeleteModal() {
    this.setState({ showDeleteModal: !this.state.showDeleteModal });
  }

  private toggleShowRestoreModal() {
    this.setState({ showRestoreModal: !this.state.showRestoreModal });
  }

  private deleteUser() {
    this.setState({ loading: true }, async () => {
      try {
        await UserAPI.deleteUser("wagz2", this.props.user.id);
        this.setState({
          loading: false,
          showDeleteModal: false,
        });
        Alert.success("User has been deleted");
        await this.delay(2000);
        window.location.reload();
      } catch (err) {
        Alert.error(
          "Could not delete that user. Please check to make sure all devices and subscriptions have been deactivated."
        );
        this.setState({ loading: false });
      }
    });
  }

  private restoreUser() {
    this.setState({ loading: true }, async () => {
      try {
        await UserAPI.restoreUser("wagz2", this.props.user.id);
        this.setState({
          loading: false,
          showRestoreModal: false,
        });
        Alert.success("User has been restored!");
        await this.delay(2000);
        window.location.reload();
      } catch (err) {
        Alert.error(
          "Could not restore that user. Their old email may have been reused."
        );
        this.setState({ loading: false });
      }
    });
  }

  render() {
    return (
      <div>
        <Table striped bordered hover>
          <thead style={styles.thead}>
            <th style={styles.tableHead}>Update Profile</th>
            <th style={styles.tableHead}>Begin Password Reset</th>
            <th style={styles.tableHead}>Set User's Password</th>
            <th style={styles.tableHead}>Clear Cache</th>
          </thead>
          <tbody>
            <tr>
              <td style={styles.tCellWidth}>
                <p>
                  You can choose to manually edit the user's profile (such as
                  name and phone) or their addresses.
                </p>
                <button
                  className="btn btn-block btn-primary"
                  onClick={() => this.setState({ showInfoModal: true })}
                >
                  View/Edit User Profile
                </button>
                <button
                  className="btn btn-block btn-primary"
                  style={{ marginTop: 20 }}
                  onClick={() => this.setState({ showAddressModal: true })}
                >
                  View/Edit User Addresses
                </button>
              </td>
              <td style={styles.tCellWidth}>
                <p>
                  Begin password reset process for user. This will send them an
                  email with a reset token.{" "}
                  <strong>You will not be prompted to confirm sending!</strong>
                </p>
                <button
                  style={{ color: "white" }}
                  className="btn btn-send btn-block"
                  onClick={this.startReset}
                >
                  Send email
                </button>
              </td>
              <td style={styles.tCellWidth}>
                <p>
                  This will change the user's password. This is considered a Bad
                  Idea, since no one should know a user's password. This is a
                  last resort.
                </p>
                <div className="form-group">
                  <input
                    id="newPassword"
                    type="text"
                    className="form-control"
                    placeholder="New Password"
                    autoComplete="new-password"
                    onChange={this.updateField}
                    value={this.state.newPassword}
                  />
                </div>
                <button
                  className="btn btn-block btn-primary"
                  onClick={this.manualReset}
                >
                  Update password
                </button>
              </td>
              <td style={styles.tCellWidth}>
                <CacheManagementScreen
                  userId={this.props.userId}
                  otherId={0}
                  cacheType="user_pets"
                />
              </td>
            </tr>
          </tbody>
        </Table>
        <Table striped bordered hover>
          <thead style={styles.thead}>
            <th style={styles.tableHead}>Change Status</th>
            {this.props.userState.user.accessLevel !== "read_only" && (
              <th style={styles.tableHead}>Generate JWT</th>
            )}
            <th style={styles.tableHead}>Manage OTP</th>
            <th style={styles.tableHead}>Delete/Restore User</th>
          </thead>
          <tbody>
            <tr>
              <td style={styles.tCellWidth}>
                <p>
                  You can toggle the user's account status. If you go from
                  pending to active, they will bypass email validation! Changing
                  a status to pending will NOT send a new email validation
                  request.
                </p>
                <div style={styles.borderBottom}>
                  <h3>
                    Current status: {capitalizeFirstLetter(this.state.status)}
                  </h3>
                </div>
                <div className="form-group">
                  <select
                    className="form-control"
                    id="newStatus"
                    value={this.state.newStatus}
                    onChange={this.updateField}
                  >
                    <option value={this.state.status}> </option>
                    {this.state.status !== "active" && (
                      <option value="active">Active</option>
                    )}
                    {this.state.status !== "inactive" && (
                      <option value="inactive">Inactive</option>
                    )}
                    {this.state.status !== "pending" && (
                      <option value="pending">Pending</option>
                    )}
                  </select>
                </div>
                <button
                  className="btn btn-block btn-primary"
                  onClick={this.updateAccountStatus}
                >
                  Update Status
                </button>
              </td>
              {this.props.userState.user.accessLevel !== "read_only" && (
                <td style={styles.tCellWidth}>
                  <p>
                    You can use this to generate a JWT for a user for use in
                    calls against the API. <strong>Warning:</strong> use of this
                    functionality is monitored closely. Abuse can lead to
                    consequences. Only use if you have permission and know what
                    you are doing. If in doubt, ask the admin team.
                  </p>
                  <button
                    className="btn btn-block btn-primary"
                    onClick={this.generateJWT}
                  >
                    Generate JWT
                  </button>
                </td>
              )}
              <td style={styles.tCellWidth}>
                <UserOTPScreen userId={this.props.userId} />
              </td>
              <td style={styles.tCellWidth}>
                <div>
                  <p>
                    Please only use this button if the user has specifically
                    requested full deletion of their account and it's data.
                  </p>
                  <button
                    className="btn btn-block btn-primary"
                    onClick={this.toggleShowDeleteModal}
                  >
                    Delete User
                  </button>
                </div>
                <div style={{ marginTop: 8 }}>
                  <p>
                    This button can only be used if the user account is
                    currently in the "delete_requested" status. Restoring their
                    account will set their email back to it's original value and
                    will also reactivate the account.
                  </p>
                  <button
                    className="btn btn-block btn-primary"
                    onClick={this.toggleShowRestoreModal}
                    disabled={this.state.status !== "delete_requested"}
                  >
                    Restore User
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </Table>
        <div className="row">
          <Modal
            dialogClassName="modal-large"
            show={this.state.showAddressModal}
            onHide={() => {
              this.setState({ showAddressModal: false });
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                Update Addresses for {this.state.firstName}{" "}
                {this.state.lastName}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-4">
                  <p>
                    <strong>Mailing Address</strong>
                  </p>
                  <ManageTabAddress
                    accessLevel={this.props.userState.user.accessLevel}
                    user={this.props.user}
                    address={this.state.addressMailing}
                    addressType="mailing"
                    onAddressSaved={this.handleAddressSaved}
                  />
                </div>
                <div className="col-4">
                  <p>
                    <strong>Billing Address</strong>
                  </p>
                  <ManageTabAddress
                    accessLevel={this.props.userState.user.accessLevel}
                    user={this.props.user}
                    address={this.state.addressBilling}
                    addressType="billing"
                    onAddressSaved={this.handleAddressSaved}
                  />
                </div>
                <div className="col-4">
                  <p>
                    <strong>Emergency Address</strong>
                  </p>
                  <ManageTabAddress
                    accessLevel={this.props.userState.user.accessLevel}
                    user={this.props.user}
                    address={this.state.addressEmergency}
                    addressType="emergency"
                    onAddressSaved={this.handleAddressSaved}
                  />
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn btn-block btn-primary"
                onClick={() => this.setState({ showAddressModal: false })}
              >
                Done
              </button>
            </Modal.Footer>
          </Modal>

          <Modal
            dialogClassName="modal-75"
            show={this.state.showInfoModal}
            onHide={() => {
              this.setState({ showInfoModal: false });
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                Update Information for {this.state.firstName}{" "}
                {this.state.lastName}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-group" style={{ marginTop: 10 }}>
                <label style={{ fontSize: 12, marginLeft: 5 }}>
                  First Name:
                </label>
                <input
                  id="firstName"
                  disabled={
                    this.props.userState.user.accessLevel === "read_only"
                  }
                  type="text"
                  className="form-control"
                  placeholder="First Name"
                  autoComplete="new-password"
                  onChange={this.updateField}
                  value={this.state.firstName}
                />
              </div>
              <div className="form-group">
                <label style={{ fontSize: 12, marginLeft: 5 }}>
                  Last Name:
                </label>
                <input
                  id="lastName"
                  disabled={
                    this.props.userState.user.accessLevel === "read_only"
                  }
                  type="text"
                  className="form-control"
                  placeholder="Last Name"
                  autoComplete="new-password"
                  onChange={this.updateField}
                  value={this.state.lastName}
                />
              </div>
              <div className="form-group">
                <label style={{ fontSize: 12, marginLeft: 5 }}>
                  Mobile Phone:
                </label>
                <input
                  id="mobilePhone"
                  disabled={
                    this.props.userState.user.accessLevel === "read_only"
                  }
                  type="text"
                  className="form-control"
                  placeholder="Mobile Phone"
                  autoComplete="new-password"
                  onChange={this.updateField}
                  value={this.state.mobilePhone}
                />
              </div>
              <div className="form-group">
                <label style={{ fontSize: 12, marginLeft: 5 }}>
                  Additional Phone Number:
                </label>
                <input
                  id="homePhone"
                  disabled={
                    this.props.userState.user.accessLevel === "read_only"
                  }
                  type="text"
                  className="form-control"
                  placeholder="Additional Phone Number"
                  autoComplete="new-password"
                  onChange={this.updateField}
                  value={this.state.homePhone}
                />
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                {this.props.userState.user.accessLevel === "read_only" ? (
                  <button className="btn btn-block btn-primary" disabled={true}>
                    You do not have permission to do this
                  </button>
                ) : (
                  <button
                    className="btn btn-block btn-send"
                    style={{ marginTop: 15, width: "50%", color: "white" }}
                    onClick={this.updateUserInfo}
                  >
                    Submit
                  </button>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>

          <Modal
            show={this.state.showJWTModal}
            onHide={this.toggleShowJWTModal}
          >
            <Modal.Header closeButton={true}>
              <Modal.Title>
                Generated JWT for {this.props.user.firstName}{" "}
                {this.props.user.lastName}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                We have generated a JWT for user {this.props.user.firstName}{" "}
                {this.props.user.lastName} ({this.props.userId}). Copy this as
                it will not be shown again and a new JWT will have to be
                created.
              </p>
              <textarea
                className="form-control"
                rows={8}
                value={this.state.jwt}
                disabled={true}
              />
            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn btn-block btn-primary"
                onClick={this.toggleShowJWTModal}
              >
                Close
              </button>
            </Modal.Footer>
          </Modal>

          <Modal
            show={this.state.showDeleteModal}
            onHide={this.toggleShowDeleteModal}
          >
            <Modal.Header closeButton={true}>
              <Modal.Title>
                Delete account for {this.props.user.email}?
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                Are you <strong>absolutely</strong> sure you want to do this?
              </p>
            </Modal.Body>
            <Modal.Footer>
              <button
                style={{ marginBottom: 5 }}
                className="btn btn-block btn-danger"
                onClick={this.deleteUser}
              >
                Yes, delete the user
              </button>
              <button
                className="btn btn-block btn-warning"
                onClick={this.toggleShowDeleteModal}
              >
                Cancel
              </button>
            </Modal.Footer>
          </Modal>

          <Modal
            show={this.state.showRestoreModal}
            onHide={this.toggleShowRestoreModal}
          >
            <Modal.Header closeButton={true}>
              <Modal.Title>
                Restore account for {this.props.user.email}?
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Are you sure?</p>
            </Modal.Body>
            <Modal.Footer>
              <button
                style={{ marginBottom: 5 }}
                className="btn btn-block btn-danger"
                onClick={this.restoreUser}
              >
                Yes, restore the user
              </button>
              <button
                className="btn btn-block btn-warning"
                onClick={this.toggleShowRestoreModal}
              >
                Cancel
              </button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    );
  }

  private handleAddressSaved(addressType: string, address: Address) {
    const state: any = this.state;
    if (addressType === "mailing") {
      state.addressMailing = address;
    } else if (addressType === "billing") {
      state.addressBilling = address;
    } else if (addressType === "emergency") {
      state.addressEmergency = address;
    }
    this.setState({ ...state });
  }
}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default connect<{}, {}, any>(
  mapStateToProps,
  mapDispatchToProps
)(ManageTab);
