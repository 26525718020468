import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { NotificationsAPI, UserAPI } from "src/api";
import * as UserActions from "src/reducers/userReducer";
import moment from "moment";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons'
import { Modal } from "react-bootstrap";
import Table from "src/components/structure/Table";
import { error, success } from "src/components/structure/Alert";

interface IMobileDeviceTabProps {
  userId?: number;
  userIds?: number[];
  userActions: any;
  userState: any;
}

interface IMobileDeviceTabState {
  loading: boolean;
  rows: any[];
  showPushSendModal: boolean;
  pushTitle: string;
  pushText: string;
  selectedDevice: any;
  sendMode: "all" | "single";
}

const mobileDevicesTableColumns = [
  {
    label: "OS",
    field: "os",
    sort: "asc",
    width: 200,
  },
  {
    label: "OS Version",
    field: "osVersion",
    sort: "asc",
    width: 200,
  },
  {
    label: "App Version",
    field: "appVersion",
    sort: "asc",
    width: 200,
  },
  {
    label: "Last Used",
    field: "lastUsed",
    sort: "asc",
    width: 200,
  },
  {
    label: "Send Push",
    field: "send",
  },
];

class MobileDevicesTab extends Component<IMobileDeviceTabProps, IMobileDeviceTabState> {
  constructor(props: IMobileDeviceTabProps) {
    super(props);

    this.state = {
      loading: false,
      rows: [],
      showPushSendModal: false,
      pushText: "",
      pushTitle: "Wagz Notification",
      selectedDevice: {},
      sendMode: "all",
    };

    this.updateField = this.updateField.bind(this);
    this.getMobileDevices = this.getMobileDevices.bind(this);
    this.selectDevice = this.selectDevice.bind(this);
    this.toggleShowPushSendModal = this.toggleShowPushSendModal.bind(this);
    this.sendPushNotification = this.sendPushNotification.bind(this);
    this.selectSendAll = this.selectSendAll.bind(this);
  }

  componentDidMount() {
    this.getMobileDevices();
  }

  render() {
    return (
      <div>
        <Table 
          title="Mobile Devices" 
          columns={mobileDevicesTableColumns}
          rows={this.state.rows}
          showDownload={true}
          csvFileName={`mobileDevices-userID-${this.props.userId}`}
          postElement={(<div className="row" style={{marginTop: 15}}>
            <div className="col-12">
              <button className="btn btn-block btn-primary" onClick={this.selectSendAll}>Send Push Notification To All Devices</button>
            </div>
          </div>)}
        />
        <Modal
          show={this.state.showPushSendModal}
          onHide={this.toggleShowPushSendModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Send Push Notification</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p><strong className="text-danger">Warning: </strong>This will be sent directly to the user. Make sure you know what you are doing and you only send 
            appropriate information to devices you have permission to target.</p>

            <div className="form-group">
              <label>Title</label>
              <input type="text" id="pushTitle" className="form-control" value={this.state.pushTitle} onChange={this.updateField} />
            </div>
            <div className="form-group">
              <label>Text</label>
              <input type="text" id="pushText" className="form-control" value={this.state.pushText} onChange={this.updateField} />
            </div>

          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-block btn-primary" onClick={this.sendPushNotification}>Send Push Notification</button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }



  updateField(e: any) {
    let ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  getMobileDevices() {
    // we now allow specifying multiple user ids so we can reuse this on the
    // My Mobile Devices page
    this.setState({ loading: true }, async () => {
      try {
        const rows: any = [];
        let raw: any[] = [];
        if(this.props.userId && this.props.userId !== 0){
          const results = await UserAPI.getMobileDevices("wagz", this.props.userId);
          raw = results.body.data;
        } else if(this.props.userIds && this.props.userIds.length > 0){
          for(const id of this.props.userIds){
            const results = await UserAPI.getMobileDevices("wagz", id);
            raw.push(...results.body.data);
          }
        }
        for (const r of raw) {
          r.lastUsed = moment(r.lastUsed).format("ddd MMM DD YYYY h:mm A");
          r.created = moment(r.created).format("ddd MMM DD YYYY h:mm A");
          r.send = r.token === "" ? null : (<span onClick={() => this.selectDevice(r)}><FontAwesomeIcon icon={faEnvelopeOpenText} /> </span>)
          rows.push(r);
        }
        this.setState({ loading: false, rows });
      } catch (err) {
        this.setState({ loading: false });
      }
    });
  }

  private selectDevice(device: any) {
    this.setState({ selectedDevice: device, showPushSendModal: true, sendMode: "single" });
  }

  private selectSendAll() {
    this.setState({ selectedDevice: {}, showPushSendModal: true, sendMode: "all" });
  }

  private toggleShowPushSendModal() {
    this.setState({ showPushSendModal: !this.state.showPushSendModal });
  }

  private sendPushNotification(){
    const data: any = {
      title: this.state.pushTitle.trim(),
      text: this.state.pushText.trim(),
      pushType: "text",
    };
    if(data.title === "" || data.text === ""){
      return error("Title and Text are both required");
    }
    this.setState({ loading: false }, async () => {
      try{
        if(this.state.sendMode === "all"){
          if(this.props.userId){
            await NotificationsAPI.sendPushToUser("wagz2", this.props.userId, data);
          }
          if(this.props.userIds){
            for(const id of this.props.userIds){
              await NotificationsAPI.sendPushToUser("wagz2", id, data);
            }
          }
        } else {
          await NotificationsAPI.sendPushToToken("wagz2", this.state.selectedDevice.os, this.state.selectedDevice.token, data.text, data);
        }
        success("Notifications queued!");
        this.setState({ loading: false, showPushSendModal: false });
      }catch(err){
        error("Could not send that notification");
        this.setState({ loading: false });
      }
    }); 
  }
}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default connect<{}, {}, any>(
  mapStateToProps,
  mapDispatchToProps
)(MobileDevicesTab);
