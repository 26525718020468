import React, { Component } from "react";
import { Table, Screen, DatePicker, PieChart } from "src/components/structure";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from '@fortawesome/free-solid-svg-icons';
import moment from "moment";
import { Modal } from "react-bootstrap";
import Card from "src/components/structure/Card";
import { AnalyticsAPI } from "src/api";
import { analyticDeviceTypeDisplay, analyticOSDisplay, IAnalyticDevice } from "src/api/analytics";

interface IAnalyticDevicesScreenProps {
  target: "all" | "user" | "pet" | "device";
  targetId: number;
}

interface IAnalyticDevicesScreenState {
  loading: boolean;
  devices: any[];
  csvDevices: any[];
  start: moment.Moment;
  end: moment.Moment;
  selectedDevice: any;
  showMoreModal: boolean;

  pcOSData: any[];
  pcDeviceTypeData: any[];
  pcBrowserData: any[];
}

const app = "wagz2";
const cols: any[] = [
  {
    "label": "Posted",
    "field": "posted",
  },
  {
    "label": "Type",
    "field": "deviceType",
  },
  {
    "label": "User",
    "field": "userIdLink",
  },
  {
    "label": "OS",
    "field": "os",
  },
  {
    "label": "OS V.",
    "field": "osVersion",
  },
  {
    "label": "Browser",
    "field": "browser",
  },
  {
    "label": "Browser V.",
    "field": "browserVersion",
  },
  {
    "label": "",
    "field": "view",
  },
];

export class AnalyticDevicesScreen extends Component<
  IAnalyticDevicesScreenProps,
  IAnalyticDevicesScreenState
> {
  constructor(props: IAnalyticDevicesScreenProps) {
    super(props);

    this.state = {
      loading: true,
      devices: [],
      csvDevices: [],
      start: moment().subtract(7, "days"),
      end: moment(),
      selectedDevice: {},
      showMoreModal: false,
      pcOSData: [],
      pcDeviceTypeData: [],
      pcBrowserData: [],
    };

    this.updateField = this.updateField.bind(this);
    this.updateStart = this.updateStart.bind(this);
    this.updateEnd = this.updateEnd.bind(this);
    this.setup = this.setup.bind(this);
    this.getDevices = this.getDevices.bind(this);
    this.selectDevice = this.selectDevice.bind(this);
    this.toggleShowMoreModal = this.toggleShowMoreModal.bind(this);
  }


  componentDidMount() {
    this.setup();
  }  

  render() {
    return (
      <Screen shouldAuthCheck={true} requiredRoles={["reporting", "product", "support"]} loading={this.state.loading}>
        <Card title="Filters">
          <div className="row">
            <div className="col-4">
              <label>Start</label>
              <DatePicker date={this.state.start} onDateSaved={this.updateStart} />
            </div>
            <div className="col-4">
              <label>End</label>
              <DatePicker date={this.state.end} onDateSaved={this.updateEnd} />
            </div>
            <div className="col-4">
              <label>&nbsp;</label>
              <button className="btn btn-block btn-primary" onClick={this.getDevices}>Update</button>
            </div>
          </div>
        </Card>
        <div style={{marginTop: 10}}>
          {this.state.devices.length !== 0 && !this.state.loading && (
            <>
              <div className="row">
                <div className="col-12">
                  <Table
                    title="Devices"
                    csvFileName={`analytic-devices-${this.state.start.format("YYYY-MM-DD")}-${this.state.end.format("YYYY-MM-DD")}-${this.props.target}`}
                    showDownload={true}
                    columns={cols}
                    rows={this.state.devices}
                  />
                </div>
              </div>
              <div className="row" style={{marginTop: 15}}>
                <div className="col-4">
                  <Card title="Type">
                    <PieChart loading={this.state.loading} data={this.state.pcDeviceTypeData} hideLegend={false} />
                  </Card>
                </div>
                <div className="col-4">
                  <Card title="OS">
                    <PieChart loading={this.state.loading} data={this.state.pcOSData} hideLegend={false} />
                  </Card>
                </div>
                <div className="col-4">
                  <Card title="Browser">
                    <PieChart loading={this.state.loading} data={this.state.pcBrowserData} hideLegend={false} />
                  </Card>
                </div>
              </div>


              <Modal
                show={this.state.showMoreModal}
                onHide={this.toggleShowMoreModal}
                dialogClassName="modal-third"
              >
                <Modal.Header closeButton>
                  <Modal.Title>
                    Viewing Device
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row analytic-more-info-row">
                    <div className="col-4">
                      Type: 
                    </div>
                    <div className="col-8">
                      {this.state.selectedDevice.deviceType}
                    </div>
                  </div>
                  <div className="row analytic-more-info-row">
                    <div className="col-4">
                      OS: 
                    </div>
                    <div className="col-8">
                      {this.state.selectedDevice.os} {this.state.selectedDevice.osVersion}
                    </div>
                  </div>
                  <div className="row analytic-more-info-row">
                    <div className="col-4">
                      Browser: 
                    </div>
                    <div className="col-8">
                      {this.state.selectedDevice.browser} {this.state.selectedDevice.browserVersion}
                    </div>
                  </div>
                  <div className="row analytic-more-info-row">
                    <div className="col-4">
                      Make: 
                    </div>
                    <div className="col-8">
                      {this.state.selectedDevice.manufacturer} {this.state.selectedDevice.model}
                    </div>
                  </div>
                  <div className="row analytic-more-info-row">
                    <div className="col-4">
                      Screen Size: 
                    </div>
                    <div className="col-8">
                      {this.state.selectedDevice.screenWidth} x {this.state.selectedDevice.screenHeight}
                    </div>
                  </div>
                  <div className="row analytic-more-info-row">
                    <div className="col-4">
                      Additional Params: 
                    </div>
                    <div className="col-8">
                      <pre>{JSON.stringify(this.state.selectedDevice.params, null, 2)}</pre>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button className="btn btn-primary btn-block" onClick={this.toggleShowMoreModal}>Done</button>
                </Modal.Footer>
              </Modal>
            </>
          )}
        </div>

      </Screen>
    );
  }


  updateField(e: any) {
    let ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  updateStart(newDay: moment.Moment){
    this.setState({ start: newDay })
  }

  updateEnd(newDay: moment.Moment){
    this.setState({ end: newDay})
  }

  selectDevice(device: any){
    this.setState({ selectedDevice: device, showMoreModal: true });
  }

  toggleShowMoreModal(){
    this.setState({showMoreModal: !this.state.showMoreModal});
  }

  private setup(){
    this.setState({loading: true }, this.getDevices)
  }

  private getDevices(){
    this.setState({loading: true }, async () => {
      try{
        const params = {
          start: this.state.start.format("YYYY-MM-DDT") + "00:00:00Z",
          end: this.state.end.format("YYYY-MM-DDT") + "23:59:59Z",
        }
        let devices: IAnalyticDevice[] = [];
        if(this.props.target === "all"){
          const result = await AnalyticsAPI.getAnalyticDevices(app, params);
          devices = result.body.data;
        } else {
          const result = await AnalyticsAPI.getAnalyticsDevicesForUser(app, this.props.targetId, params);
          devices = result.body.data;
        }

        // now we need to format them
        const csvDevices: any[] = [];
        const pcOSData: any[] = [];
        const pcDeviceTypeData: any[] = [];
        const pcBrowserData: any[] = [];

        let pcOSDataHolder: any = {};
        let pcDeviceTypeDataHolder: any = {};
        let pcBrowserDataHolder: any = {};

        for(const d of devices){
          d.os = analyticOSDisplay(d.os);
          d.deviceType = analyticDeviceTypeDisplay(d.deviceType)
          csvDevices.push(d);

          d.posted = moment(d.posted).format("MM/DD/YY HH:mm");
          if(d.manufacturer === ""){
            d.manufacturer = "Unknown"
          }
          if(d.model === ""){
            d.model = "Unknown";
          }
          if(d.browser === "" || d.browser === "unknown"){
            d.browser = "Unknown"
          }

          d.view = (<button className="btn-link-bare" onClick={() => {this.selectDevice(d)}}><FontAwesomeIcon icon={faEye} /></button>)
          d.userIdLink = (<a href={`/support/users/${d.userId}`} target="_new">{d.userId}</a>)

          if(d.os !== "unknown" && d.os !== ""){
            if(!pcOSDataHolder[d.os]){
              pcOSDataHolder[d.os] = 0;
            }
            pcOSDataHolder[d.os] += 1;
          }

          if(d.browser !== "unknown" && d.browser !== ""){
            if(!pcBrowserDataHolder[d.browser]){
              pcBrowserDataHolder[d.browser] = 0;
            }
            pcBrowserDataHolder[d.browser] += 1;
          }

          if(!pcDeviceTypeDataHolder[d.deviceType]){
            pcDeviceTypeDataHolder[d.deviceType] = 0;
          }
          pcDeviceTypeDataHolder[d.deviceType] += 1;

        }

        for(const key of Object.keys(pcOSDataHolder)){
          pcOSData.push({ key, count: pcOSDataHolder[key]});
        }
        for(const key of Object.keys(pcBrowserDataHolder)){
          pcBrowserData.push({ key, count: pcBrowserDataHolder[key]});
        }
        for(const key of Object.keys(pcDeviceTypeDataHolder)){
          pcDeviceTypeData.push({ key, count: pcDeviceTypeDataHolder[key]});
        }

        this.setState({ loading: false, devices, csvDevices: csvDevices, pcOSData, pcBrowserData, pcDeviceTypeData });
      }catch(err){
        this.setState({ loading: false });
      }
    })
  }

}