import * as adminLogs from "./adminLogs";
import * as analytics from "./analytics";
import * as apps from "./apps";
import * as billing from "./billing";
import * as device from "./devices";
import * as deviceGroups from "./deviceGroups";
import * as errors from "./errors";
import * as gps from "./gpsTests";
import * as jobs from "./jobs";
import * as logs from "./logs";
import * as metrics from "./metrics";
import * as notifications from "./notifications";
import * as ota from "./ota";
import * as pets from "./pets";
import * as profile from "./profile";
import * as reports from "./reports";
import * as support from "./support";
import * as user from "./users";

export const AdminLogsAPI = new adminLogs.AdminLogsAPI();
export const AnalyticsAPI = new analytics.AnalyticsAPI();
export const AppsAPI = new apps.AppsAPI();
export const BillingAPI = new billing.BillingAPI();
export const DeviceAPI = new device.DeviceAPI();
export const DeviceGroupsAPI = new deviceGroups.DeviceGroupsAPI();
export const ErrorsAPI = new errors.ErrorsAPI();
export const GPSTestsAPI = new gps.GPSTestsAPI()
export const JobsAPI = new jobs.JobsAPI();
export const LogsAPI = new logs.LogsAPI();
export const MetricsAPI = new metrics.MetricsAPI();
export const NotificationsAPI = new notifications.NotificationsAPI();
export const OTAAPI = new ota.OTAAPI();
export const PetsAPI = new pets.PetsAPI();
export const ProfileAPI = new profile.ProfileAPI();
export const ReportsAPI = new reports.ReportsAPI();
export const SupportAPI = new support.SupportAPI();
export const UserAPI = new user.UserAPI();

const urls = {
  // try to keep the develop and production sets identical other than the values
  develop: {
    aws: {
      logs: "https://s3.console.aws.amazon.com/s3/buckets/dev-device-logs-upload?region=us-east-2&prefix=devices/{deviceId}/&showversions=false",
      shadow: "https://us-east-2.console.aws.amazon.com/iot/home?region=us-east-2#/thing/{thingName}/namedShadow/Classic%20Shadow",
    },
    loggly: {
      logs: "https://wagz.loggly.com/search#terms={productId}&from={startTime}&until={endTime}&source_group=",
    },
    mailgun: {
      logs: "https://app.mailgun.com/app/sending/domains/wagz.com/logs?date_from={startTime}&{endTime}&skip=0&search={email}",
      log: "https://app.mailgun.com/app/sending/domains/wagz.com/logs/{mailgunId}",
      templateEdit: "https://app.mailgun.com/app/sending/domains/wagz.com/templates/edit/{templateShortName}/initial",
    },
    chargify: {
      customer: "https://wagz-inc-dev.chargify.com/customers/{customerId}",
    },
    stripe: {
      customer: "https://dashboard.stripe.com/customers/{customerId}",
    },
    jasper: {
      sim: "https://t-mobile.jasper.com/provision/ui/terminals/sims/sims.html",
    }
  },
  production: {
    aws: {
      logs: "https://s3.console.aws.amazon.com/s3/buckets/device-logs-upload?region=us-east-2&prefix=devices/{deviceId}/&showversions=false",
      shadow: "https://us-east-2.console.aws.amazon.com/iot/home?region=us-east-2#/thing/{thingName}/namedShadow/Classic%20Shadow",
    },
    loggly: {
      logs: "https://wagz.loggly.com/search#terms={productId}&from={startTime}&until={endTime}&source_group=",
    },
    mailgun: {
      logs: "https://app.mailgun.com/app/sending/domains/wagz.com/logs?date_from={startTime}&{endTime}&skip=0&search={email}",
    },
    chargify: {
      customer: "https://wagz.chargify.com/customers/{customerId}",
    },
    stripe: {
      customer: "https://dashboard.stripe.com/customers/{customerId}",
    },
    jasper: {
      sim: "https://t-mobile.jasper.com/provision/ui/terminals/sims/sims.html",
    }
  }
}

export const getUrl = (provider: string, service: string, keys: object): string => {
  let url = "";
  const env = process.env.REACT_APP_ENV === "prod" || process.env.REACT_APP_ENV === "production" ? "production" : "develop";
  // we get the environment, then the provider, then the service
  if(urls[env][provider] && urls[env][provider][service]){
    // grab and interpolate
    url = urls[env][provider][service];
    // now loop over the keys and replace whatever comes in
    Object.keys(keys).forEach((key) => {
      const k = "{" + key + "}";
      url = url.replace(k, keys[key]);
    });    
  }
  return url;
}