import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as UserActions from "src/reducers/userReducer";
import { IPet } from "src/api/pets";
import Card from "src/components/structure/Card";

interface IBreedsTabProps {
  userId: number;
  location: any;
  userActions: any;
  userState: any;
  pet: IPet;
}

interface IBreedsTabState {
  loading: boolean;
  deviceId: number;
  petId: number;
}

class BreedsTab extends Component<IBreedsTabProps, IBreedsTabState> {
  constructor(props: IBreedsTabProps) {
    super(props);

    this.state = {
      loading: false,
      deviceId: 0,
      petId: 0,
    };

    this.updateField = this.updateField.bind(this);
    this.processBreeds = this.processBreeds.bind(this);
  }

  updateField(e: any) {
    let ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  processBreeds(p: IPet) {
    if (p.breeds !== undefined) {
      const breedList = [];
      for (const b of p.breeds) {
        breedList.push(<li>{b.breed}</li>)
      };
      return breedList;
    } else {
      return <text>This pet has no assigned breeds</text>;
    };
  }

  render() {
    return (
      <div className="row">
        <div className="col-sm-12 col-lg-5" style={{ marginBottom: 30 }}>
          <Card title="Breed(s) for Pet">
            <ul>
                {this.processBreeds(this.props.pet)}
            </ul>
          </Card>
        </div>
        <div className="col-sm-12 col-lg-6 offset-1" style={{ marginBottom: 30 }}>
          <Card title="Known Breed Data">
            <pre>{JSON.stringify(this.props.pet.breeds, null, 2)}</pre>
          </Card>
        </div>
      </div>
    );
  }
}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default connect<{}, {}, any>(
  mapStateToProps,
  mapDispatchToProps
)(BreedsTab);
