import * as React from "react";
import Dropzone from "react-dropzone";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { OTAAPI } from "src/api";
import { Alert } from "src/components/structure";

import Card from "src/components/structure/Card";
import Screen from "src/components/structure/Screen";
import * as AppActions from "src/reducers/appReducer";

interface IOTAManagementProps {
  appActions: any;
  deviceType: string;
  productFamily: string;
  channels: string[];
}

interface IOTAManagementState {
  loading: boolean;
  selectedChannel: string;
  filesUploading: boolean;
  filesToUpload: any[];
}

class OTAManagement extends React.Component<IOTAManagementProps, IOTAManagementState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      selectedChannel: "",
      filesUploading: false,
      filesToUpload: [],
    };

    this.updateField = this.updateField.bind(this);
  }

  componentDidUpdate(prevProps: IOTAManagementProps){
    // if the device type or product family changed in the props, reset
    if(prevProps.deviceType !== this.props.deviceType || prevProps.productFamily !== this.props.productFamily){
      this.setState({ selectedChannel: "" });
    }
  }

  public render() {
    return (
      <Screen fileName="OTAUpload.tsx" requiredRoles={["backend"]}>
        <div className="row">
          <div className="col-4 offset-2">
            <Card title="Select a Channel" loading={this.state.loading} help="">
              <div className="form-group">
                <label>Select a Channel to Upload To</label>
                <select className="form-control" id="selectedChannel" value={this.state.selectedChannel} onChange={this.updateField}>
                  <option key="none" id="">Please select a channel</option>
                  {this.props.channels.map((channel: string) => {
                    return (<option key={channel} id={channel}>{channel}</option>)
                  })}
                </select>
              </div>
            </Card>
          </div>
          <div className="col-4">
            {this.state.selectedChannel !== "" && (
              <Card title="Upload" loading={this.state.loading} help="">
                <div className="row">
                  <div className="col-12">
                    <p>You can drag and drop or click on the fields below to upload your binaries. The files will be uploaded with their name on your computer.</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <label>Files</label>
                    <div className="dropzoneContainer">
                      <Dropzone  onDrop={acceptedFiles => this.setState({ filesToUpload: acceptedFiles, filesUploading: true}, () => this.uploadFiles())}>
                        {({getRootProps, getInputProps}) => (
                          <section className="dropzone">
                            <div {...getRootProps()}>
                              <input {...getInputProps()} className="form-control"/>
                              <p>Click or Drop the files here. They will replace the file in the target channel.</p>
                            </div>
                          </section>
                        )}
                      </Dropzone>
                    </div>
                  </div>
                </div>
              </Card>
            )}
          </div>
        </div>
      </Screen>
    );
  }

  private updateField(e: any){
    const ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private uploadFiles(){
    this.setState({ loading: true, filesUploading: true }, async () => {
      try{
        for(const file of this.state.filesToUpload){
          await OTAAPI.uploadOTABinary(this.props.deviceType, this.props.productFamily, this.state.selectedChannel, file);
        }
        Alert.success("Files uploaded!");
        this.setState({ loading: false, filesUploading: false, filesToUpload: []});
      }catch(err){
        Alert.error("Could not upload those files");
        this.setState({ loading: false, filesUploading: false, filesToUpload: []});
      }
    })
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OTAManagement);