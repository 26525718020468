import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Table from "src/components/structure/Table";
import { capitalizeFirstLetter } from "src/utils/utilities";
import SupportTicket from "./CreateTicket";

// import * as Alert from "../../structure/Alert";
import * as UserActions from "src/reducers/userReducer";
import { SupportAPI } from "src/api";
import { IColumns } from "src/utils/customTypes";

interface ITicketManagementProps {
  location: any;
  userActions: any;
  userState: any;
}

interface ITicketManagementState {
  loading: boolean;
  columns: IColumns[];
  rows: any[];
}

class TicketManagement extends Component<
  ITicketManagementProps,
  ITicketManagementState
> {
  constructor(props: ITicketManagementProps) {
    super(props);

    this.state = {
      loading: false,
      columns: [
        {
          label: "ID",
          field: "id",
          sort: "asc",
          width: 100,
        },
        {
          label: "Title",
          field: "title",
          sort: "asc",
          width: 150,
        },
        {
          label: "Department",
          field: "department",
          sort: "asc",
          width: 200,
        },
        {
          label: "Status",
          field: "status",
          sort: "asc",
          width: 150,
        },
        {
          label: "Date Created",
          field: "created",
          sort: "asc",
          width: 110,
        },
        {
          label: "User",
          field: "user",
          sort: "asc",
          width: 110,
        },
        {
          label: "User OS",
          field: "userOS",
          sort: "asc",
          width: 110,
        },
        {
          label: "Critical",
          field: "critical",
          sort: "asc",
          width: 110,
        },
        {
          label: "Device",
          field: "device",
          sort: "asc",
          width: 110,
        },
        {
          label: "Actions",
          field: "actions",
          sort: "asc",
          width: 100,
        },
      ],
      rows: [],
    };

    this.updateField = this.updateField.bind(this);
    this.populateTickets = this.populateTickets.bind(this);
    this.processData = this.processData.bind(this);
  }

  updateField(e: any) {
    let ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  componentDidMount() {
    this.populateTickets();
  }

  populateTickets() {
    this.setState({ loading: true }, async () => {
      try {
        const results = await SupportAPI.getAllTickets();

        const supportTickets = [];
        for (let result of results.body.data) {
          supportTickets.push({
            id: result.id,
            title: result.title,
            department: capitalizeFirstLetter(result.department),
            status: capitalizeFirstLetter(result.status),
            created: result.created,
            user: result.user,
            userOS: result.userOS,
            device: result.device,
            critical: capitalizeFirstLetter(result.critical),
            actions: (
              <Link
                to={`/support/tickets/${result.id}`}
                id="ticket_page"
                style={{ color: "blue", textDecoration: "underline" }}
              >
                View
              </Link>
            ),
          });
        }
        this.setState({
          loading: false,
          rows: supportTickets,
        });
      } catch (err) {
        this.setState({ loading: false });
      }
    });
  }

  processData(columns: IColumns[], rows: any) {
    const data = {
      columns,
      rows,
    };
    return data;
  }

  render() {
    return (
      <div>
        <div className="col-sm-12 col-lg-12">
          <SupportTicket
            loading={this.state.loading}
            onTicketCreated={this.populateTickets}
          />
        </div>
        <div>
          <Table
            title="Support Tickets"
            csvFileName="tickets"
            showDownload={true}
            columns={this.state.columns}
            rows={this.state.rows}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TicketManagement) as any
);
