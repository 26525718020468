import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Card, DatePicker, Screen, Table } from "src/components/structure";
import { Modal } from "react-bootstrap";

import * as UserActions from "src/reducers/userReducer";
import { AdminLogsAPI, UserAPI } from "src/api";
import moment from "moment";
import { IAdminUser } from "src/api/users";

interface IAdminLogsScreenProps {
  userState: any;
}

interface IAdminLogsScreenState {
  loading: boolean;
  logs: any[];
  knownActions: string[];
  selectedLog: any;

  // filters
  adminUsers: IAdminUser[];
  action: string;
  logLevel: string;
  adminUserId: string;
  start: moment.Moment;
  end: moment.Moment;

  showMoreInformationModal: boolean;
}


const cols: any[] = [
  {
    "label": "When",
    "field": "logTime",
  },
  {
    "label": "Level",
    "field": "logLevel",
  },
  {
    "label": "Action",
    "field": "action",
  },
  {
    "label": "User",
    "field": "adminUser",
  },
  {
    "label": "",
    "field": "view",
  },
];


class AdminLogsScreen extends Component<
  IAdminLogsScreenProps,
  IAdminLogsScreenState
> {
  constructor(props: IAdminLogsScreenProps) {
    super(props);
    this.state = {
      loading: true,
      logs: [],
      knownActions: [],
      selectedLog: {},

      adminUsers: [],
      action: "",
      logLevel: "notInfo",
      adminUserId: "",
      start: moment().subtract(5, "days"),
      end: moment(),

      showMoreInformationModal: false,
    };

    this.updateFilter = this.updateFilter.bind(this);
    this.updateStartTime = this.updateStartTime.bind(this);
    this.updateEndTime = this.updateEndTime.bind(this);
    this.setup = this.setup.bind(this);
    this.getLogs = this.getLogs.bind(this);
    this.selectLog = this.selectLog.bind(this);
    this.toggleShowMoreModal = this.toggleShowMoreModal.bind(this);
  }

  componentDidMount(){
    this.setup();
  }

  render() {
    return (
      <Screen shouldAuthCheck={true} requiredRoles={["reporting"]}>
        <div className="row">
          <div className="col-12">
            <Card title="Job Filters">
              <div className="row">
                <div className="col-10">

                  <div className="row row-margin-bottom">
                    <div className="col-4">
                      <label>Log Level</label>
                      <select className="form-control" value={this.state.logLevel} id="logLevel" onChange={this.updateFilter}>
                        <option value="">All</option>
                        <option value="notInfo">Not Info</option>
                        <option value="info">Info</option>
                        <option value="security">Security Info</option>
                        <option value="warning">Warning</option>
                        <option value="error">Error</option>
                      </select>
                    </div>
                    <div className="col-4">
                      <label>Logged Between</label>
                      <DatePicker date={this.state.start} onDateSaved={this.updateEndTime} />
                    </div>
                    <div className="col-4">
                      <label>And</label>
                      <DatePicker date={this.state.end} onDateSaved={this.updateStartTime} />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-4">
                      <label>Action</label>
                      <select className="form-control" value={this.state.action} id="action" onChange={this.updateFilter}>
                        <option value="">All</option>
                        {this.state.knownActions.map((j) => {
                          return <option value={j} key={j}>{j}</option>
                        })}
                      </select>
                    </div>
                    <div className="col-4">
                      <label>User</label>
                      <select className="form-control" value={this.state.adminUserId} id="adminUserId" onChange={this.updateFilter}>
                        <option value="">All</option>
                        {this.state.adminUsers.map((j) => {
                          return <option value={j.id} key={j.id}>{j.firstName} {j.lastName}</option>
                        })}
                      </select>                      
                    </div>
                  </div>


                </div>
                <div className="col-2">
                  <button className="btn btn-block btn-primary" onClick={this.getLogs} style={{marginTop: 27}}>Update</button>
                </div>

              </div>
            </Card>
          </div>
        </div>

        <div className="row" style={{marginTop: 20}}>
          <div className="col-12">
            <Table
              title="Administrative Logs"
              csvFileName={`logs-as-of-${moment().format("YYYY-MM-DDTHH:mm:ss")}`}
              showDownload={true}
              columns={cols}
              rows={this.state.logs}
            />
          </div>
        </div>

        <Modal
          show={this.state.showMoreInformationModal}
          onHide={this.toggleShowMoreModal}
          dialogClassName="modal-half"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Viewing Log
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-3">
                <label className="label-bold">Logged</label>
                <span>{moment(this.state.selectedLog.logTime, "MM/DD/YY h:mm A").format("MM/DD/YY h:mm A")}</span>
              </div>
              <div className="col-3">
                <label className="label-bold">Level</label>
                {this.processLevelDisplay(this.state.selectedLog.logLevel)}
              </div>
              <div className="col-3">
                <label className="label-bold">Action</label>
                <span>{this.state.selectedLog.action}</span>
              </div>
              <div className="col-3">
                <label className="label-bold">By</label>
                <span>{this.state.selectedLog.adminUser}</span>
              </div>
            </div>
            <div className="row" style={{marginTop: 15}}>
              <div className="col-6">
                <label className="label-bold">Notes</label>
                <textarea disabled={true} rows={10} style={{width: "100%"}} value={this.state.selectedLog.notes} />
              </div>
              <div className="col-6">
                <label className="label-bold">Extra Data</label>
                <textarea disabled={true} rows={10} style={{width: "100%"}} value={this.state.selectedLog.data} />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-primary btn-block" onClick={this.toggleShowMoreModal}>Done</button>
          </Modal.Footer>
        </Modal>

      </Screen>
    );
  }

  private updateFilter(e: any){
    const ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private updateEndTime(newDate: moment.Moment){
    this.setState({ start: newDate});
  }

  private updateStartTime(newDate: moment.Moment){
    this.setState({ end: newDate});
  }

  private setup(){
    this.setState({ loading: true }, async () => {
      try{
        // egt all of the admin users for filtering
        const usersResult = await UserAPI.getUsers({ status: "active"});
        const actionsResult = await AdminLogsAPI.getAdminLogsKnownActions();

        // forward to the job get
        this.setState({ adminUsers: usersResult.body.data, knownActions: actionsResult.body.data }, () => this.getLogs());
      }catch(err){

      }
    });
  }
  
  private getLogs(){
    this.setState({ loading: true }, async () => {
      try{
        const query: any = {
          start: this.state.start.startOf("day").format("YYYY-MM-DDTHH:mm:ss") + "Z",
          end: this.state.end.endOf("day").format("YYYY-MM-DDTHH:mm:ss") + "Z",
        }
        if(this.state.logLevel !== ""){
          query.logLevel = this.state.logLevel;
        }
        if(this.state.action !== ""){
          query.action = this.state.action;
        }
        if(this.state.adminUserId !== ""){
          query.adminUserId = parseInt(this.state.adminUserId, 10);
        }


        const result = await AdminLogsAPI.getAdminLogs(query);
        const logs: any[] = [];
        for(const l of result.body.data){
          l.logTime = moment(l.logTime).format("MM/DD/YY h:mm A");
          l.adminUser = `${l.adminUserFirstName} ${l.adminUserLastName}`
          l.view = (<button className="btn btn-block btn-primary" style={{padding: 5}} onClick={() => this.selectLog(l)}>View</button>);
          logs.push(l);
        }
        this.setState({ loading: false, logs});
      }catch(err){
        this.setState({ loading: false });
      }
    })
  }

  private selectLog(log: any){
    this.setState({ selectedLog: log, showMoreInformationModal: true})
  }

  private toggleShowMoreModal(){
    this.setState({ showMoreInformationModal: !this.state.showMoreInformationModal });
  }

  private processLevelDisplay(level: string): any {
    switch(level){
      case "error":
        return <span className="log-level-error">Error</span>
      case "warning":
        return <span className="log-level-warning">Warning</span>
      case "security":
        return <span className="log-level-security">Security Info</span>
      case "info":
      default:
        return <span className="log-level-info">Info</span>
    }
  }
}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AdminLogsScreen) as any
);
