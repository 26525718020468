import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Select from "react-select";

import * as UserActions from "src/reducers/userReducer";
import { DeviceAPI } from "src/api";
import { IDevice } from "src/api/devices";
import { DeviceList, Loading } from "src/components/structure";
import { fetchOptions, productOptions, typeOptions } from "src/utils/items";
interface IDeviceManagementProps {
  location: any;
  userActions: any;
  userState: any;
}

interface IDeviceManagementState {
  loading: boolean;
  devices: IDevice[];
  filteredDevices: IDevice[];
  recent: string;
  type: string;
  product: string;
}

class DeviceManagement extends Component<
  IDeviceManagementProps,
  IDeviceManagementState
> {
  constructor(props: IDeviceManagementProps) {
    super(props);

    this.state = {
      loading: false,
      devices: [],
      filteredDevices: [],
      recent: "yes",
      type: "All",
      product: "All"
    };

    this.populateDevices = this.populateDevices.bind(this);
    this.processDevices = this.processDevices.bind(this);
  }

  componentDidMount() {
    this.populateDevices();
  }

  populateDevices() {
    this.setState({ loading: true }, async () => {
      try {
        const results = await DeviceAPI.getAllDevices("wagz", {
          count: 999999999,
          size: "slim",
          recent: this.state.recent,
        });

        this.setState({
          loading: false,
          devices: results.body.data,
          filteredDevices: results.body.data,
        });
      } catch (err) {
        this.setState({ loading: false });
      }
    });
  }

  processDevices() {
    this.setState({ loading: true }, async () => {
      try {
        let list = [];
        if (this.state.type === "") {
          list = this.state.devices;
        } else {
          for (let item of this.state.devices) {
            if (item.deviceType === this.state.type) {
              list.push(item);
            }
          }
        }
        let productList = []
        if (this.state.product === "All") {
          productList = list
        } else {
          for (let item of list) {
            if (item.productFamilyId === this.state.product) {
              productList.push(item);
            }
          }
        }

        this.setState({
          loading: false,
          filteredDevices: productList,
        });
      } catch (err) {
        this.setState({ loading: false });
      }
    });
  }

  render() {
    return this.state.loading ? (
      <Loading />
    ) : (
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: 25,
            marginBottom: -55,
          }}
        > { this.state.type === "Collar" && (
          <div className="col-sm-12 col-lg-2">
            <label style={{ fontSize: 15, marginLeft: 5 }}>Product</label>
            <Select
              options={productOptions}
              onChange={this.updateProduct}
              hideSelectedOptions={true}
              placeholder={this.state.product}
            />
          </div>)}
          <div className="col-sm-12 col-lg-2">
            <label style={{ fontSize: 15, marginLeft: 5 }}>Device Type</label>
            <Select
              options={typeOptions}
              onChange={this.updateTypeFilter}
              hideSelectedOptions={true}
              placeholder={this.state.type}
            />
          </div>
          <div className="col-sm-12 col-lg-2">
            <label style={{ fontSize: 15, marginLeft: 5 }}>
              Devices to Show
            </label>
            <Select
              options={fetchOptions}
              onChange={this.updateSelection}
              hideSelectedOptions={true}
            />
          </div>
        </div>
        <div>
          <DeviceList devices={this.state.filteredDevices} />
        </div>
      </div>
    );
  }

  updateSelection = (input: any) => {
    let ns = this.state;
    ns[input.field] = input.value;
    this.setState(ns, () => this.populateDevices());
  };

  updateTypeFilter = (input: any) => {
    let ns = this.state;
    ns[input.field] = input.value;
    this.setState(ns, () => this.processDevices());
  };

  updateProduct = (input: any) => {
    let ns = this.state;
    ns[input.field] = input.value;
    this.setState(ns, () => this.processDevices());
  };
}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DeviceManagement) as any
);
