import React, { Component } from "react";
import moment from "moment-timezone";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ListGroup } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faMobileAlt, faUser, faCalendarPlus, faClipboard, faPhoneAlt, faSignInAlt, faClock } from '@fortawesome/free-solid-svg-icons'
import * as UserActions from "src/reducers/userReducer";
import { capitalizeFirstLetter } from "src/utils/utilities"
import { UserAPI } from "src/api";
import Card from "src/components/structure/Card";

interface IInfoTabProps {
  userId: number;
  location: any;
  userActions: any;
  userState: any;
}

interface IInfoTabState {
  loading: boolean;
  firstName: string;
  lastName: string;
  email: string;
  homePhone: string;
  mobilePhone: string;
  status: string;
  created: string;
  lastLoggedIn: string;
  settings: any;
}

class InfoTab extends Component<IInfoTabProps, IInfoTabState> {
  constructor(props: IInfoTabProps) {
    super(props);

    this.state = {
      loading: true,
      firstName: "",
      lastName: "",
      email: "",
      homePhone: "",
      mobilePhone: "",
      status: "",
      created: "",
      lastLoggedIn: "",
      settings: {timezone: "Unknown"}
    };

    this.updateField = this.updateField.bind(this);
    this.getUserProfile = this.getUserProfile.bind(this);
  }

  updateField(e: any) {
    let ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  componentDidMount() {
    this.getUserProfile();
  }

  getUserProfile() {
    this.setState({ loading: true }, async () => {
      try {
        const userProfileResult = await UserAPI.getUserById(
          "wagz",
          this.props.userId
        );
        const user = userProfileResult.body.data;
        this.setState({
          loading: false,
          ...user
        });
      } catch (err) {
        this.setState({ loading: false });
      }
    });
  }

  render() {
    return (
      <div className="row">
        <div className="col-10 offset-1">
          <Card title="" loading={this.state.loading}>
            <div className="row">
              <div className="col-6">
                <ListGroup variant="flush">
                  <ListGroup.Item><FontAwesomeIcon icon={faUser} /> - {this.state.firstName} {this.state.lastName}</ListGroup.Item>
                  <ListGroup.Item><FontAwesomeIcon icon={faEnvelope} /> - {this.state.email}</ListGroup.Item>
                  <ListGroup.Item><FontAwesomeIcon icon={faClipboard} /> - Status: {capitalizeFirstLetter(this.state.status)}</ListGroup.Item>
                  <ListGroup.Item><FontAwesomeIcon icon={faClock} /> - Timezone: {this.state.settings.timezone} {moment().tz(this.state.settings.timezone).format("Z")}</ListGroup.Item>
                </ListGroup>
              </div>
              <div className="col-6">
                <ListGroup variant="flush">
                  <ListGroup.Item><FontAwesomeIcon icon={faMobileAlt} /> - {this.state.mobilePhone}</ListGroup.Item>
                  <ListGroup.Item><FontAwesomeIcon icon={faPhoneAlt} /> - {this.state.homePhone}</ListGroup.Item>
                  <ListGroup.Item><FontAwesomeIcon icon={faCalendarPlus} /> - Created on: {this.state.created}</ListGroup.Item>
                  <ListGroup.Item><FontAwesomeIcon icon={faSignInAlt} /> - Last logged in: {this.state.lastLoggedIn}</ListGroup.Item>
                </ListGroup>
              </div>
            </div>
          </Card>
        </div>
      </div>
    );
  }
}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default connect<{}, {}, any>(
  mapStateToProps,
  mapDispatchToProps
)(InfoTab);
