import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const alertOptions = {
  position: "top-center",
  autoClose: 7000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

export const error = (message: string, otherOptions: any = {}) => {
  const data = {
    ...alertOptions,
    ...otherOptions,
  };
  return toast.error(message, data);
};

export const success = (message: string, otherOptions: any = {}) => {
  const data = {
    ...alertOptions,
    ...otherOptions,
  };
  return toast.success(message, data);
};
