import React, { Component } from "react";
import {  withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CSVReader from "react-csv-reader";

import Card from "src/components/structure/Card";
import Screen from "src/components/structure/Screen";
import * as Alert from "src/components/structure/Alert";
import * as UserActions from "src/reducers/userReducer";
import { ICoupon } from "src/api/billing";
import { BillingAPI } from "src/api";

interface IUploadCouponsScreenProps {
  location: any;
  userActions: any;
  userState: any;
}

interface IUploadCouponsScreenState {
  loading: boolean;
  toImport: ICoupon[];
  parsedFilename: string;
}

class UploadCouponsScreen extends Component<IUploadCouponsScreenProps, IUploadCouponsScreenState> {
  constructor(props: IUploadCouponsScreenProps) {
    super(props);

    this.state = {
      loading: false,
      toImport: [],
      parsedFilename: "",
    };

    this.updateField = this.updateField.bind(this);
    this.upload = this.upload.bind(this);
    this.handleParse = this.handleParse.bind(this);
  }

  private parserOptions = {
    header: true,
    trimHeaders: true,
    skipEmptyLines: true,
    dynamicTyping: false,
    transformHeader: (header: string) => header.toLowerCase()
  };


  render() {
    return (
      <Screen id="UploadCouponsScreen" requiredRoles={[]}>
        <div className="row">
          <div className="col-4">
            <Card title="Upload">
              <p>Choose a CSV file that matches the requirements of coupon entry. Upon successful parsing, a preview will be generated for confirmation prior to upload.</p>
              <div className="form-group">
                <CSVReader 
                  onFileLoaded={this.handleParse}
                  parserOptions={this.parserOptions}
                />
              </div>
              <div className="form-group" style={{marginTop: 15}}>
                {this.state.toImport.length > 0 && (
                  <button className="btn btn-block btn-primary" onClick={this.upload}>Upload to Database</button>
                )}
              </div>
            </Card>
          </div>
          {this.state.toImport.length > 0 && (
            <div className="col-8">
              <Card title={`Parsed ${this.state.parsedFilename}`}>
                <div className="row row-header">
                  <div className="col-2">
                    Coupon Code
                  </div>
                  <div className="col-2">
                    Coupon Type
                  </div>
                  <div className="col-2">
                    Value
                  </div>
                  <div className="col-2">
                    Coupon Use Type
                  </div>
                  <div className="col-2">
                    Coupon Duration
                  </div>
                  <div className="col-2">
                    Eligible Plan(s)
                  </div>
                </div>
                {this.state.toImport.map((s, i) => {
                  return (
                    <div className="row" key={i}>
                      <div className="col-2">
                        {s.couponCode}
                      </div>
                      <div className="col-2">
                        {s.couponType}
                      </div>
                      <div className="col-2">
                        {s.couponValue}
                      </div>
                      <div className="col-2">
                        {s.couponUseType}
                      </div>
                      <div className="col-2">
                        {s.couponDuration}
                      </div>
                      <div className="col-2">
                        {s.eligiblePlans}
                      </div>
                    </div>
                  )
                })}
              </Card>
            </div>
          )}
        </div>
      </Screen>
    )
  }

  updateField(e: any) {
    let ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private handleParse(data: any, fileInfo: any){
    this.setState({ loading: true, toImport: [] }, () => {
      // grab the file name
      const fileName = fileInfo.name ? fileInfo.name : "unknown";
      // the data will have the parsed rows
      const toImport: ICoupon[] = [];
      for(const s of data){
        const d: ICoupon = {
          couponCode: s.couponcode,
          couponName: s.couponcode,
          couponUseType: s.couponusetype ? s.couponusetype : "",
          couponType: s.coupontype ? s.coupontype : "",
          couponDuration: s.couponduration ? s.couponduration : "",
          couponValue: parseInt(s.couponvalue),
          eligiblePlans: s.eligibleplans,
        };
        toImport.push(d);
      }
      if(toImport.length === 0){
        Alert.error("Could not parse that CSV");
        return this.setState({ loading: false });
      }
      this.setState({loading: false, toImport, parsedFilename: fileName});
    })
  }

  private upload() {
    this.setState({ loading: true }, async () => {
      try{
        for (let i of this.state.toImport) {
            await BillingAPI.createCoupon("wagz2", i);
            const plansToArray = i.eligiblePlans.split(",");
            for (let p of plansToArray) {
                await BillingAPI.linkCouponToPlan("wagz2", {
                  couponCode: i.couponCode,
                  planId: parseInt(p)
                })
            }
        }
        Alert.success("Coupons uploaded!");
        this.setState({ toImport: [], loading: false, parsedFilename: ""});
      }catch(err){
        Alert.error("Could not save those coupons. Please make sure all fields are accounted for.");
        this.setState({ loading: false });
      }
    });
  }
}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UploadCouponsScreen) as any
);
