import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as UserActions from "src/reducers/userReducer";
import { PetsAPI } from "src/api";

import Card from "src/components/structure/Card";
import { error, success } from "src/components/structure/Alert";

interface ISettingsTabProps {
  userId: number;
  petId: number;
}

interface ISettingsTabState {
  loading: boolean;
  dashboardSettings: any;
}

class SettingsTab extends Component<ISettingsTabProps, ISettingsTabState> {
  constructor(props: ISettingsTabProps) {
    super(props);

    this.state = {
      loading: false,
      dashboardSettings: [],
    };

    this.resetSettings = this.resetSettings.bind(this);
    this.formatList = this.formatList.bind(this);
    this.getPetDashboardSettings = this.getPetDashboardSettings.bind(this);
  }

  componentDidMount() {
    this.getPetDashboardSettings();
  }

  render() {
    return (
      <div className="row">
        <div className="col-4">
          <Card title="Dashboard Settings" loading={this.state.loading}>
            <div className="row">
              <div className="col-6">
                <ol>{this.formatList()}</ol>
              </div>
              <div className="col-6" style={{ marginTop: 20 }}>
                <button
                  className="btn btn-block btn-danger"
                  onClick={this.resetSettings}
                >
                  Reset to Default
                </button>
              </div>
            </div>
          </Card>
        </div>
      </div>
    );
  }

  formatList() {
    const d = [];
    if (this.state.dashboardSettings.length === 0) {
      d.push(<strong key={1}>Unable to find settings</strong>);
    } else {
      for (let s of this.state.dashboardSettings) {
        if (s[1] !== -1 && s[0] !== "userId" && s[0] !== "petId") {
          d.push(<li>{s[0]}</li>);
        }
      }
    }
    return d;
  }

  private resetSettings() {
    this.setState({ loading: true }, async () => {
      try {
        await PetsAPI.resetPetDashboardSettings(
          "wagz2",
          this.props.userId,
          this.props.petId
        );
        this.setState({ loading: false }, () => {
          this.getPetDashboardSettings();
          success(`We successfully reset to default`);
        });
      } catch (err) {
        error("Could not complete the reset. Try again or contact an admin.");
        this.setState({ loading: false });
      }
    });
  }

  private getPetDashboardSettings() {
    this.setState({ loading: true }, async () => {
      try {
        const res = await PetsAPI.getPetDashboardSettings(
          "wag2",
          this.props.userId,
          this.props.petId
        );
        const sort = Object.entries(res.body.data);
        sort.sort(function (a: any, b: any) {
          return a[1] - b[1];
        });

        this.setState({ loading: false, dashboardSettings: sort }, () => {});
      } catch (err) {
        error("Could not load the settings");
        this.setState({ loading: false });
      }
    });
  }
}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default connect<{}, {}, any>(
  mapStateToProps,
  mapDispatchToProps
)(SettingsTab);
