import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as UserActions from "src/reducers/userReducer";
import { ReportsAPI } from "src/api";
import { Card, Screen } from "src/components/structure";

interface IPetPageProps {
  match: any;
  history: any;
  location: any;
  userActions: any;
  userState: any;
}

interface IPetPageState {
  loading: boolean;
  orderId: number;
  order: any;
  items: any[];
}

class PetPage extends Component<IPetPageProps, IPetPageState> {
  constructor(props: IPetPageProps) {
    super(props);

    this.state = {
      loading: false,
      orderId: this.props.match.params.orderId,
      order: {},
      items: [],
    };

    this.goBack = this.goBack.bind(this);
    this.getOrder = this.getOrder.bind(this);
  }

  goBack() {
    this.props.history.goBack();
  }

  componentDidMount() {
    this.getOrder();
  }

  getOrder() {
    this.setState({ loading: true }, async () => {
      try {
        const res = await ReportsAPI.getOrderByOrderID(this.state.orderId);
        this.setState({
          loading: false,
          order: res.body.data,
        });
        this.getItems();
      } catch (err) {
        this.setState({ loading: false });
      }
    });
  }

  getItems() {
    this.setState({ loading: true }, async () => {
      try {
        const res = await ReportsAPI.getOrderItemsByOrderID(this.state.orderId);
        this.setState({
          loading: false,
          items: res.body.data,
        });
      } catch (err) {
        this.setState({ loading: false });
      }
    });
  }

  render() {
    if (this.state.loading) {
      return (
        <div style={{ textAlign: "center" }}>
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      );
    }
    return (
      <Screen requiredRoles={["support"]}>
        <div className="col-sm-12 col-lg-12">
          <div>
            <h1>Order ID: {this.state.orderId}</h1>
          </div>
          <Card title="Order">
            <div className="row row-margin-bottom">
              <div className="col-4">
                <label className="label-bold">Customer Email</label>
                {this.state.order.email}
              </div>
              <div className="col-4">
                <label className="label-bold">Order Status</label>
                {this.state.order.status}
              </div>
              <div className="col-4">
                <label className="label-bold">User ID</label>
                {this.state.order.userId}
              </div>
            </div>

            <div className="row row-margin-bottom">
              <div className="col-4">
                <label className="label-bold">Created</label>
                {this.state.order.created}
              </div>
              <div className="col-4">
                <label className="label-bold">Last Updated</label>
                {this.state.order.lastUpdated}
              </div>
              <div className="col-4">
                <label className="label-bold">Notes</label>
                {this.state.order.notes}
              </div>
            </div>
          </Card>
          <div>
            <h1>Shipment ID: {this.state.order.shipmentId}</h1>
          </div>
          <Card title="Shipping Info">
            <div className="row row-margin-bottom">
              <div className="col-6">
                <label className="label-bold">Ship Date</label>
                {this.state.order.shipDate}
              </div>
              <div className="col-6">
                <label className="label-bold">Carrier</label>
                {this.state.order.carrierCode}
              </div>
            </div>

            <div className="row row-margin-bottom">
              <div className="col-6">
                <label className="label-bold">Ship To</label>
                <ul>
                  <li>{this.state.order.shipToName}</li>
                  <li>{this.state.order.shipToStreet1}{" "}{this.state.order.shipToStreet2}</li>
                  <li>{this.state.order.shipToCity}{", "}{this.state.order.shipToState}</li>
                </ul>
              </div>
              <div className="col-6">
                <label className="label-bold">Tracking Number</label>
                {this.state.order.trackingNumber}
              </div>
            </div>
          </Card>
        </div>
      </Screen>
    );
  }
}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PetPage) as any
);
