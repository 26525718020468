import * as React from "react";
import { Modal } from "react-bootstrap";
import { LoadingButton } from "src/components/structure";
import { error } from "src/components/structure/Alert";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons';


interface IDeviceGroupRowProps {
  group: any;
  onSelectGroup: any;
  onEditGroup: any;
  onDeleteGroup: any;
}

interface IDeviceGroupRowState {
  loading: boolean;
  editGroupName: string;
  editGroupType: "general" | "firmware";
  editGroupProductFamily: string;
  editGroupStatus: "active" | "hidden";

  showEditGroupModal: boolean;
  showDeleteGroupModal: boolean;

}

export default class DeviceGroupRow extends React.Component<IDeviceGroupRowProps, IDeviceGroupRowState> {

  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
    
      editGroupName: this.props.group.name,
      editGroupType: this.props.group.groupType,
      editGroupProductFamily: this.props.group.productFamily,
      editGroupStatus: this.props.group.status,
    
      showEditGroupModal: false,
      showDeleteGroupModal: false,
    };

    this.updateField = this.updateField.bind(this);
    this.selectGroup = this.selectGroup.bind(this);
    this.editGroup = this.editGroup.bind(this);
    this.deleteGroup = this.deleteGroup.bind(this);
    this.toggleEditModal = this.toggleEditModal.bind(this);
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
  }


  public render() {
    return (
      <div>
        <div className="row" style={{ marginBottom: 20 }}>
          <div className="col-5"><div className="link" onClick={this.selectGroup}>{this.props.group.name}</div></div>
          <div className="col-3">{this.props.group.groupType}</div>
          <div className="col-2">{this.props.group.status}</div>
          <div className="col-1"><FontAwesomeIcon icon={faPencilAlt} onClick={this.toggleEditModal} /></div>
          <div className="col-1"><FontAwesomeIcon icon={faTrash} onClick={this.toggleDeleteModal} /></div>
        </div>

        <Modal show={this.state.showEditGroupModal} onHide={this.toggleEditModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              Editing {this.props.group.name}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              This will create a new logical grouping for devices that can be used for things like mass setting OTA channels.
            </p>
            <div className="form-group">
              <label>Group Name</label>
              <input type="text" className="form-control" id="editGroupName" value={this.state.editGroupName} onChange={this.updateField} />
            </div>
            <div className="form-group">
              <label>Product Family</label>
              <select className="form-control" id="editGroupProductFamily" value={this.state.editGroupProductFamily} onChange={this.updateField} disabled={true}>
                <option value="all">All - No Restrictions</option>
                <option value="23">Freedom Collar</option>
                <option value="24">Small Pet Collar</option>
              </select>
              <p className="small">Cannot be changed!</p>
            </div>
            <div className="form-group">
              <label>Group Type</label>
              <select className="form-control" id="editGroupType" value={this.state.editGroupType} onChange={this.updateField}>
                <option value="firmware">Primarily for OTA and Firmware</option>
                <option value="general">General / Other</option>
              </select>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <LoadingButton
              loading={this.state.loading}
              className="btn btn-primary btn-block"
              loadingStyles={{ textAlign: "center", width: "100%" }}
              onClick={this.editGroup}
            >
              Update Group
            </LoadingButton>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.showDeleteGroupModal} onHide={this.toggleDeleteModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              Delete Group {this.props.group.name}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              <strong>Warning:</strong> This will remove all devices from the group and delete the group. This cannot be undone.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <LoadingButton
              loading={this.state.loading}
              className="btn btn-primary btn-danger"
              loadingStyles={{ textAlign: "center", width: "100%" }}
              onClick={this.deleteGroup}
            >
              Delete Group
            </LoadingButton>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  private updateField(e: any) {
    const ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private toggleEditModal(){
    this.setState({ showEditGroupModal: !this.state.showEditGroupModal});
  }

  private toggleDeleteModal(){
    this.setState({ showDeleteGroupModal: !this.state.showDeleteGroupModal});
  }

  private editGroup(){
    if(this.state.editGroupName.trim() === ""){
      return error("Group name cannot be blank.");
    }
    const data = {
      name: this.state.editGroupName.trim(),
      productFamily: this.state.editGroupProductFamily,
      status: this.state.editGroupStatus,
      groupType: this.state.editGroupType
    }
    this.setState({ showEditGroupModal: false });
    this.props.onEditGroup(this.props.group.id, data);
  }

  private deleteGroup(){
    this.setState({ showEditGroupModal: false });
    this.props.onDeleteGroup(this.props.group.id);
  }

  private selectGroup(){
    this.props.onSelectGroup(this.props.group.id);
  }

}