import moment from "moment";
import { createAction, createReducer } from "redux-act";

export const loginUser: any = createAction("login");
export const logoutUser: any = createAction("logout");

export interface ILoggedInUser {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  roles: string[];
  refresh_token: string;
  access_token: string;
  expires: moment.Moment;
  isAdministrator: boolean;
  isSupport: boolean;
  isReporting: boolean; 
  isProduct: boolean;
  isBackend: boolean;
}

export interface IUserLoginPayload {
  loggedIn: boolean;
  user: ILoggedInUser;
}

export default createReducer({
    [loginUser]: (state: any, payload: IUserLoginPayload) => {
      return {
        ...state, 
        loggedIn: payload.loggedIn,
        user: payload.user,
      };
    },
    [logoutUser]: (state) => {
      return {
        ...state, 
        loggedIn: false,
        user: {},
      };
    },
  }, {
    loggedIn: false,
    user: {},
});