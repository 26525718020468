import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";

import { ReportsAPI } from "src/api";
import DatePicker from "src/components/structure/DatePicker";
import Card from "src/components/structure/Card";
import { error } from "src/components/structure/Alert";

import { AllDevicesDisplay } from "./AllDevicesDisplay";

import { BarGraph, IBarGraphDataPoint } from "src/components/structure/BarGraph";

interface IDeviceReportsProps {
  match: any;
  history: any;
  location: any;
}

interface IDeviceReportsState {
  loading: boolean;
  allDevicesStats: any;
  start: moment.Moment;
  end: moment.Moment;
  allByActivationGraphData: any[];
  freedomByActivationGraphData: any[];
}

class DeviceReports extends Component<IDeviceReportsProps, IDeviceReportsState> {
  constructor(props: IDeviceReportsProps) {
    super(props);

    this.state = {
      loading: true,
      start: moment().subtract(14, "days"),
      end: moment().add(1, "day"),
      allDevicesStats: {},
      allByActivationGraphData: [],
      freedomByActivationGraphData: [],
    };

    this.fetchReports = this.fetchReports.bind(this);
    this.handleEndDateChanged = this.handleEndDateChanged.bind(this);
    this.handleStartDateChanged = this.handleStartDateChanged.bind(this);
  }

  componentDidMount() {
    this.fetchReports();
  }

  render() {
    return (
      <div className="row">
        <div className="col-12 col-lg-3">
          <AllDevicesDisplay loading={this.state.loading} data={this.state.allDevicesStats} />
        </div>
        <div className="col 12 col-lg-9">
          <div className="row">
            <div className="col-12">
              <Card title="">
                <div className="row">
                  <div className="col-4">
                    <label>From</label>
                    <DatePicker date={this.state.start} onDateSaved={this.handleStartDateChanged} />
                  </div>
                  <div className="col-4">
                    <label>To</label>
                    <DatePicker date={this.state.end} onDateSaved={this.handleEndDateChanged} />
                  </div>
                </div>
              </Card>
            </div>
          </div>

          <div className="row">
            <div className="col-6">
              <Card title="All Activations By Date" loading={this.state.loading}>
                <BarGraph loading={this.state.loading} data={this.state.allByActivationGraphData} />
              </Card>
            </div>
            <div className="col-6">
              <Card title="Freedom Activations By Date" loading={this.state.loading}>
                <BarGraph loading={this.state.loading} data={this.state.freedomByActivationGraphData} />
              </Card>
            </div>
          </div>
        </div>
      </div>
    );
  }

  private async fetchReports(){
    try{
      const reportsResult = await ReportsAPI.getAllDevices();
      this.setState({ allDevicesStats: reportsResult.body.data, loading: false}, this.fetchGraphData);
    }catch(err){

    }
  }

  // this is broken out to handle date changes
  private fetchGraphData(){
    // just the devices by activation for now
    if(this.state.start.isAfter(this.state.end)){
      return error("Start must be after the end");
    }
    this.setState({ loading: true }, async () => {
      try{
        const allByActivationResult = await ReportsAPI.getDevicesByActivationDate(this.state.start.format("YYYY-MM-DD"), this.state.end.format("YYYY-MM-DD"));
        const allByActivationGraphData = this.convertToGraphFormat(allByActivationResult.body.data);
        // now, we also want freedom

        const freedomByActivationResult = await ReportsAPI.getDevicesByActivationDate(this.state.start.format("YYYY-MM-DD"), this.state.end.format("YYYY-MM-DD"), {productFamilyId: "23"});
        const freedomByActivationGraphData = this.convertToGraphFormat(freedomByActivationResult.body.data);

        this.setState({ loading: false, allByActivationGraphData, freedomByActivationGraphData});
      }catch(err){
        this.setState({ loading: false});
      }
    })
  }

  private handleStartDateChanged(newDate: moment.Moment){
    this.setState({start: newDate}, this.fetchGraphData)
  }

  private handleEndDateChanged(newDate: moment.Moment){
    this.setState({end: newDate}, this.fetchGraphData)
  }

  private convertToGraphFormat(input: {date : string, count: number}[]): IBarGraphDataPoint[] {
    // 
    const parsed: IBarGraphDataPoint[] = [];
    for(const day of input){
      parsed.push({key: day.date, count: day.count});
    }
    return parsed;
  }

}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {};
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DeviceReports) as any
);
