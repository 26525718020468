import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { PetsAPI } from "src/api";
import { IBowlConsumption } from "src/api/pets";
import Table from "src/components/structure/Table";
import * as UserActions from "src/reducers/userReducer";
import { secondsToHms } from "src/utils/utilities"
import moment from "moment";

interface IConsumptionTabProps {
  petId: number;
  userId: number;
  location: any;
  userActions: any;
  userState: any;
}

interface IConsumptionTabState {
  loading: boolean;
  consumptions: IBowlConsumption[];
}

class ConsumptionTab extends Component<
  IConsumptionTabProps,
  IConsumptionTabState
> {
  constructor(props: IConsumptionTabProps) {
    super(props);

    this.state = {
      loading: false,
      consumptions: [],
    };

    this.getBowlConsumptions = this.getBowlConsumptions.bind(this);
  }

  componentDidMount() {
    this.getBowlConsumptions();
  }

  getBowlConsumptions() {
    this.setState({ loading: true }, async () => {
      try {
        const result = await PetsAPI.getBowlConsumptionsForPet(
          "wagz",
          this.props.userId,
          this.props.petId
        );
        const consumptions: IBowlConsumption[] = [];
        for (const l of result.body.data) {
          l.startTime = moment(l.startTime).format("MM/DD/YYYY HH:mm:ss");
          l.endTime = moment(l.endTime).format("MM/DD/YYYY HH:mm:ss");
          l.consumptionDurationSeconds = secondsToHms(l.consumptionDurationSeconds)
          consumptions.push(l);
        }
        this.setState({ loading: false, consumptions });
      } catch (err) {
        this.setState({ loading: false });
      }
    });
  }

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-4"></div>
        </div>
        <div>
          <Table
            title={`Bowl Consumptions for Pet ${this.props.petId}`}
            csvFileName={`bowl-consumptions-${this.props.petId}`}
            showDownload={true}
            columns={[
              {
                label: "Start Time",
                field: "startTime",
                sort: "asc",
              },
              {
                label: "End Time",
                field: "endTime",
                sort: "asc",
              },
              {
                label: "Consumption Duration",
                field: "consumptionDurationSeconds",
                sort: "asc",
              },
              {
                label: "Start Weight",
                field: "startWeight",
                sort: "asc",
              },
              {
                label: "End Weight",
                field: "endWeight",
                sort: "asc",
              },
              {
                label: "Weight Differential",
                field: "weightDifferential",
                sort: "asc",
              },
              {
                label: "Configuration",
                field: "configuration",
                sort: "asc",
              },
              {
                label: "Device ID",
                field: "deviceId",
                sort: "asc",
              },
            ]}
            rows={this.state.consumptions}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default connect<{}, {}, any>(
  mapStateToProps,
  mapDispatchToProps
)(ConsumptionTab);
