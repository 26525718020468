import * as React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { capitalizeFirstLetter } from "src/utils/utilities";

import * as UserActions from "src/reducers/userReducer";
import * as AppActions from "../../reducers/appReducer";

interface IBubbleState {
  color: string;
  url: string;
}

interface IBubbleProps {
  id: number;
  loading: boolean;
  appState: any;
  idType: string;
  userId: number;
}

class Bubble extends React.Component<IBubbleProps, IBubbleState> {
  constructor(props: any) {
    super(props);
    this.state = {
      color: "#2d9ad7",
      url: ""
    };
  }

  componentDidMount() {
    this.decideColor();
  }

  decideColor() {
    if (this.props.idType === "device") {
      this.setState({ color: "#2d9ad7", url:  `/support/devices/${this.props.id}`});
    } else {
      this.setState({ color: "#9d9ea0", url: `/support/users/${this.props.userId}/pets/${this.props.id}` });
    }
  }

  public render() {
    return (
      <div
        style={{
          padding: 30,
          backgroundColor: this.state.color,
          textAlign: "center",
          fontFamily: "serif",
          color: "#f6f9fc",
          fontWeight: "bold",
          fontSize: 36,
          boxShadow: "0px 2px 4px 4px #172b4d",
          borderRadius: 12
        }}
      >
        <p>{capitalizeFirstLetter(this.props.idType)} ID:</p>
        <Link
          to={this.state.url}
          id="support_page"
          style={{ color: "#e3dac9", textDecoration: "underline" }}
        >
          {this.props.id}
        </Link>
      </div>
    );
  }
}

const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
    actions: bindActionCreators(AppActions, dispatch),
  };
}

export default connect<{}, {}, any>(
  mapStateToProps,
  mapDispatchToProps
)(Bubble);
