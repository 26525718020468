import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as UserActions from "src/reducers/userReducer";
import { BillingAPI, DeviceAPI } from "src/api";
import { Modal } from "react-bootstrap";
import * as Alert from "src/components/structure/Alert";
import Screen from "src/components/structure/Screen";
import DatePicker from "react-date-picker";

import Card from "src/components/structure/Card";
import { ListGroup } from "react-bootstrap";
import { styles } from "src/styles";

interface ILifecycleTabProps {
  deviceId: number;
  userId: number;
  match: any;
  location: any;
  userActions: any;
  userState: any;
}

interface ILifecycleTabState {
  loading: boolean;
  deviceStatus: string;
  billingStatus: string;
  registeredOn: string;
  onboardedOn: string;
  cancellationRequestedOn: string;
  newCancellationRequestedOn: string;
  deactivatedOn: string;
  newDeactivatedOn: string;
  showDeactivatedOnModal: boolean;
  showCancellationRequestedOnModal: boolean;
  showButtons: boolean;
  deactivateOn: string;
}

class LifecycleTab extends Component<ILifecycleTabProps, ILifecycleTabState> {
  constructor(props: ILifecycleTabProps) {
    super(props);

    this.state = {
      loading: false,
      deviceStatus: "",
      billingStatus: "",
      registeredOn: "",
      onboardedOn: "",
      cancellationRequestedOn: "",
      newCancellationRequestedOn: "",
      deactivatedOn: "",
      newDeactivatedOn: "",
      showDeactivatedOnModal: false,
      showCancellationRequestedOnModal: false,
      showButtons: false,
      deactivateOn: "",
    };

    this.updateField = this.updateField.bind(this);
    this.getDeviceLifecycle = this.getDeviceLifecycle.bind(this);
    this.showDeactivatedOnModal = this.showDeactivatedOnModal.bind(this);
    this.editDeactivatedOn = this.editDeactivatedOn.bind(this);
    this.editCancellationRequestedOn =
      this.editCancellationRequestedOn.bind(this);
  }

  updateField(e: any) {
    let ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  componentDidMount() {
    this.getDeviceLifecycle();
  }

  getDeviceLifecycle() {
    this.setState({ loading: true }, async () => {
      try {
        const res = await DeviceAPI.getDeviceLifecycleData(
          "wagz",
          this.props.deviceId
        );
        this.setState({
          ...res.body.data,
          loading: false,
        });
      } catch (err) {
        this.setState({ loading: false });
      }
    });
  }

  editDeactivatedOn() {
    this.setState({ loading: true }, async () => {
      try {
        await DeviceAPI.updateDevice(
          "wagz",
          this.props.userId,
          this.props.deviceId,
          { deactivatedOn: this.state.newDeactivatedOn }
        );
        Alert.success("Date has been updated");
        this.setState({
          loading: false,
          deactivatedOn: this.state.newDeactivatedOn,
        });
      } catch (error) {
        this.setState({ loading: false });
        Alert.error(
          "There was an issue updating that date. Please try again later."
        );
      }
    });
  }

  editCancellationRequestedOn() {
    this.setState({ loading: true }, async () => {
      try {
        await BillingAPI.updateBillingDevice(
          "wagz",
          this.props.userId,
          this.props.deviceId,
          {
            cancellationRequestedOn: this.state.newCancellationRequestedOn,
          }
        );
        Alert.success("Status updated successfully");
        this.setState(
          { loading: false, showCancellationRequestedOnModal: false },
          () => this.getDeviceLifecycle()
        );
      } catch (err) {
        this.setState({
          loading: false,
          showCancellationRequestedOnModal: false,
        });
        Alert.error(
          "There was an issue updating that date. Please try again later."
        );
      }
    });
  }

  render() {
    return (
      <Screen fileName="UsageTab.tsx" requiredRoles={["support", "product", "firmware", "mobile"]}>
        <div className="row">
          <div className="col-sm-12 col-lg-4">
            <Card title="Device Lifecycle Information">
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <div>
                    <p style={{ fontWeight: "bolder" }}>Device Status: </p>
                    {this.state.deviceStatus}
                  </div>
                </ListGroup.Item>
                <ListGroup.Item>
                  <div>
                    <p style={{ fontWeight: "bolder" }}>Registered On: </p>
                    {this.state.registeredOn}
                  </div>
                </ListGroup.Item>
                <ListGroup.Item>
                  <div>
                    <p style={{ fontWeight: "bolder" }}>Onboarded On: </p>
                    {this.processDate(this.state.onboardedOn, false)}
                  </div>
                </ListGroup.Item>
                <ListGroup.Item>
                  <div>
                    <p style={{ fontWeight: "bolder" }}>Deactivated On: </p>
                    {this.processDate(this.state.deactivatedOn, false)}
                  </div>

                  {this.state.showButtons && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: -40,
                      }}
                    >
                      {this.props.userState.user.accessLevel !==
                        "read_only" && (
                        <button
                          className="btn btn-sm btn-pri"
                          onClick={() => this.showDeactivatedOnModal()}
                        >
                          Change
                        </button>
                      )}
                      {this.props.userState.user.accessLevel ===
                        "read_only" && <div style={{ marginTop: 40 }}></div>}
                    </div>
                  )}
                </ListGroup.Item>
                <ListGroup.Item>
                  <div>
                    <p style={{ fontWeight: "bolder" }}>Billing Status: </p>
                    {this.state.billingStatus}
                  </div>
                </ListGroup.Item>
                <ListGroup.Item>
                  <div>
                    <p style={{ fontWeight: "bolder" }}>
                      Billing Cancellation Requested On:{" "}
                    </p>
                    {this.processDate(this.state.cancellationRequestedOn, false)}
                  </div>

                  {this.state.showButtons && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: -40,
                      }}
                    >
                      {this.props.userState.user.accessLevel !==
                        "read_only" && (
                        <button
                          className="btn btn-sm btn-pri"
                          onClick={() =>
                            this.showCancellationRequestedOnModal()
                          }
                        >
                          Change
                        </button>
                      )}
                      {this.props.userState.user.accessLevel ===
                        "read_only" && <div style={{ marginTop: 40 }}></div>}
                    </div>
                  )}
                </ListGroup.Item>
                <ListGroup.Item>
                  <div>
                    <p style={{ fontWeight: "bolder" }}>Device set to automatically deactivate on: </p>
                    {this.processDate(this.state.deactivateOn, true)}
                  </div>
                </ListGroup.Item>
              </ListGroup>
            </Card>
          </div>
          <div>
            <Modal
              dialogClassName="modal-75"
              show={this.state.showDeactivatedOnModal}
              onHide={() => {
                this.setState({ showDeactivatedOnModal: false });
              }}
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  Editing Deactivated On date for device {this.props.deviceId}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="center">
                  <p style={{ fontSize: 14, textAlign: "center" }}>
                    <strong>
                      CAUTION: This will change the "deactivated on..." date for
                      this device. This cannot be undone.
                    </strong>
                  </p>{" "}
                  <br />
                  <div style={{ marginLeft: 5, marginBottom: 10 }}>
                    <DatePicker
                      id="build"
                      onChange={(value: any) =>
                        this.setState({ newDeactivatedOn: value })
                      }
                      value={this.state.newDeactivatedOn}
                    />
                  </div>
                </div>
                <div className="text">
                  <button
                    className="btn btn-block btn-submit"
                    style={styles.button}
                    onClick={this.editDeactivatedOn}
                  >
                    Change date
                  </button>
                </div>
              </Modal.Body>
              <Modal.Footer></Modal.Footer>
            </Modal>
          </div>
          <div>
            <Modal
              dialogClassName="modal-75"
              show={this.state.showCancellationRequestedOnModal}
              onHide={() => {
                this.setState({ showCancellationRequestedOnModal: false });
              }}
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  Editing Cancellation Requested On for device{" "}
                  {this.props.deviceId}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="center">
                  <p style={{ fontSize: 14, textAlign: "center" }}>
                    <strong>
                      CAUTION: This will change the "cancellation requested
                      on..." date for this device. This cannot be undone.
                    </strong>
                  </p>{" "}
                  <br />
                  <div style={{ marginLeft: 5, marginBottom: 10 }}>
                    <DatePicker
                      id="build"
                      onChange={(value: any) =>
                        this.setState({ newCancellationRequestedOn: value })
                      }
                      value={this.state.newCancellationRequestedOn}
                    />
                  </div>
                </div>
                <div className="text">
                  <button
                    className="btn btn-block btn-submit"
                    style={styles.button}
                    onClick={this.editCancellationRequestedOn}
                  >
                    Change date
                  </button>
                </div>
              </Modal.Body>
              <Modal.Footer></Modal.Footer>
            </Modal>
          </div>
        </div>
      </Screen>
    );
  }

  processDate(input: string, dayOnly: boolean) {
    if (
      input === "1970-01-01 00:00:00" ||
      input === "1970-01-01T00:00:00Z" ||
      input === ""
    ) {
      return "N/A";
    }
    if (dayOnly) {
      return input.substring(0, 10)
    }
    return input;
  }

  private showDeactivatedOnModal() {
    this.setState({ showDeactivatedOnModal: true });
  }

  private showCancellationRequestedOnModal() {
    this.setState({ showCancellationRequestedOnModal: true });
  }
}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default connect<{}, {}, any>(
  mapStateToProps,
  mapDispatchToProps
)(LifecycleTab);
