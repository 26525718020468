import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Screen from "src/components/structure/Screen";
import * as Alert from "src/components/structure/Alert";
import * as UserActions from "src/reducers/userReducer";
import Table from "src/components/structure/Table";
import { DeviceAPI } from "src/api";

interface IDeviceInfoScreenProps {
  location: any;
  userActions: any;
  userState: any;
}

interface IDeviceInfoScreenState {
  loading: boolean;
  rows: any[];
}

class DeviceInfoScreen extends Component<IDeviceInfoScreenProps, IDeviceInfoScreenState> {
  constructor(props: IDeviceInfoScreenProps) {
    super(props);

    this.state = {
      loading: false,
      rows: [],
    };

    this.updateField = this.updateField.bind(this);
    this.fetch = this.fetch.bind(this);
  }

  private columns = [
    {
      label: "Serial",
      field: "productId",
      sort: "asc",
      width: 100,
    },
    {
      label: "IMEI",
      field: "imei",
      sort: "asc",
      width: 100,
    },
    {
      label: "ICCID",
      field: "iccid",
      sort: "asc",
      width: 100,
    },
    {
      label: "Inserted",
      field: "inserted",
      sort: "asc",
      width: 100,
    },
  ];

  componentDidMount(){
    this.fetch();
  }

  render() {
    return (
      <Screen id="deviceInfoScreen" requiredRoles={[]}>
        <div className="row">
          <div className="col-12">
            <Table 
              loading={this.state.loading}
              title="List of Devices from Database"
              csvFileName="generalDeviceInfo"
              showDownload={false}
              columns={this.columns}
              rows={this.state.rows}
            />
          </div>
        </div>
      </Screen>
    )
  }

  updateField(e: any) {
    let ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private fetch() {
    this.setState({ loading: true }, async () => {
      try{
        const result = await DeviceAPI.getDeviceInformation();
        const information = [];
        for(const s of result.body.data){
          information.push({
            ...s
          })
        }
        this.setState({ loading: false, rows: information });
      }catch(err){
        Alert.error("Could not fetch any device info");
        this.setState({ loading: false });
      }
    })
  }

}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DeviceInfoScreen) as any
);
