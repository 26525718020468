import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as UserActions from "src/reducers/userReducer";
import { DeviceAPI } from "src/api";

import { IDevice, IDevicePerformanceSetting } from "src/api/devices";

import Card from "src/components/structure/Card";
import { error, success } from "src/components/structure/Alert";

interface ISettingsTabProps {
  device: IDevice;
  accessLevel: string;
}

interface ISettingsTabState {
  loading: boolean;
  deviceSettings: IDevicePerformanceSetting[];
  allSettings: IDevicePerformanceSetting[];
}

class SettingsTab extends Component<ISettingsTabProps, ISettingsTabState> {
  constructor(props: ISettingsTabProps) {
    super(props);

    this.state = {
      loading: false,
      deviceSettings: [],
      allSettings: [],
    };

    this.updateField = this.updateField.bind(this);
    this.updateSettingValue = this.updateSettingValue.bind(this);
    this.updateSetting = this.updateSetting.bind(this);
    this.setup = this.setup.bind(this);
  }

  componentDidMount() {
    this.setup();
  }

  render() {
    return (
      <div className="row">
        <div className="col-8 offset-2">
          <Card title="Performance Settings" loading={this.state.loading}>
            <div className="row">
              <div className="col-3">
                <strong>Setting</strong>
              </div>
              <div className="col-2">
                <strong>Value</strong>
              </div>
              <div className="col-5">
                <strong>Description</strong>
              </div>
              <div className="col-2">
              </div>
            </div>
            {this.state.deviceSettings.map((setting, index) => {
              return (
                <div className="row" key={index} style={{marginTop: 10}}>
                  <div className="col-3">
                    {setting.settingName}
                  </div>
                  <div className="col-2">
                    <input type="text" className="form-control" disabled={this.props.accessLevel === "read_only"} id={setting.settingName} value={setting.settingValue} onChange={this.updateSettingValue} />
                  </div>
                  <div className="col-5">
                    {setting.settingDescription}
                  </div>
                  <div className="col-2">
                    {this.props.accessLevel !== "read_only" && (<button className="btn btn-success btn-block" onClick={() => {this.updateSetting(setting.settingName)}}>Update</button>)}
                  </div>
                </div>
              )
            })}
          </Card>
        </div>        
      </div>
    );
  }

  updateField(e: any) {
    let ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private updateSettingValue(e: any){
    const id = e.target.id;
    const val = e.target.value;
    const deviceSettings = this.state.deviceSettings;
    for(const s of deviceSettings){
      if(s.settingName === id){
        s.settingValue = val;
        break;
      }
    }
    this.setState({deviceSettings: deviceSettings});
  }

  private updateSetting(settingName: string){
    this.setState({ loading: true }, async () => {
      let settingValue: any = "";
      for(const s of this.state.deviceSettings){
        if(s.settingName === settingName){
          settingValue = s.settingValue;
          break;
        }
      }
      try{
        await DeviceAPI.setPerformanceSettingForDevice(this.props.device.service, this.props.device.registeredBy, this.props.device.id, settingName, settingValue);
        this.setState({loading: false}, () => {
          success(`We successfully set ${settingName} to ${settingValue}!`)
        })
      }catch(err){
        error("Could not update that setting. Try again or contact an admin.");
        this.setState({ loading: false });
      }
    })
  }

  private setup(){
    this.setState({ loading: true }, async () => {
      try{
        const allResult = await DeviceAPI.getAllPerformanceSettingsFieldsForFamily("wag2", this.props.device.productFamilyId);
        const currentResult = await DeviceAPI.getPerformanceSettingsForDevice(this.props.device.service, this.props.device.registeredBy, this.props.device.id);
        
        this.setState({ loading: false, allSettings: allResult.body.data, deviceSettings: currentResult.body.data.settings});
      }catch(err){
        error("Could not load the settings");
        this.setState({ loading: false });
      }
    })
  }
}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default connect<{}, {}, any>(mapStateToProps, mapDispatchToProps)(SettingsTab);
