import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal } from "react-bootstrap";

import * as AppActions from "src/reducers/appReducer";
import * as UserActions from "src/reducers/userReducer";

import { Card, Screen } from "src/components/structure";
import { BillingAPI } from "src/api";
import { error } from "src/components/structure/Alert";
import Select from "react-select";
import {
  couponTypes,
  couponUseTypes,
  couponDurations,
  plans,
  leadingCharacters
} from "src/utils/items";

interface IGenerateCouponsProps {
  loading: boolean;
  userState: any;
  onGeneration: any;
}

interface IGenerateCouponsState {
  loading: boolean;
  couponUseType: string;
  couponType: string;
  couponDuration: string;
  couponValue: string;
  alpha: boolean;
  numeric: boolean;
  numberOfCoupons: string;
  lengthOfCode: string;
  leading: string;
  plans: any[];

  showGeneratedModal: boolean;
  generated: any[];
}
class GenerateCoupons extends Component<
  IGenerateCouponsProps,
  IGenerateCouponsState
> {
  constructor(props: IGenerateCouponsProps) {
    super(props);

    this.state = {
      loading: false,
      couponUseType: "",
      couponType: "",
      couponDuration: "",
      couponValue: "",
      alpha: false,
      numeric: false,
      numberOfCoupons: "",
      lengthOfCode: "",
      leading: "",
      plans: [],
    
      showGeneratedModal: false,
      generated: [],
    };

    this.updateField = this.updateField.bind(this);
    this.generateCoupons = this.generateCoupons.bind(this);
    this.handleAlpha = this.handleAlpha.bind(this);
    this.handleNumeric = this.handleNumeric.bind(this);
    this.updatePlans = this.updatePlans.bind(this);
    this.toggleShowGeneratedModal = this.toggleShowGeneratedModal.bind(this);
  }

  render() {
    return (
      <Screen id="generate_coupon">
        <div className="col-11">
          <Card
            title="Generate New Coupons"
            loading={this.props.loading || this.state.loading}
          >
            <div className="form-group">
              <div className="row" style={{ marginLeft: 3 }}>
                <div style={{ width: "45%" }}>
                  <label>Number of Coupons to Generate *</label>
                  <input
                    type="number"
                    className="form-control"
                    id="numberOfCoupons"
                    value={this.state.numberOfCoupons}
                    onChange={this.updateField}
                  />
                </div>
                <div style={{ width: "45%", marginLeft: 20 }}>
                  <label>Length of Coupon Codes *</label>
              <label style={{ marginTop: 4, marginLeft: 6, fontSize: 11 }}>
                (max 20){" "}
              </label>
                  <input
                    type="number"
                    className="form-control"
                    id="lengthOfCode"
                    value={this.state.lengthOfCode}
                    onChange={this.updateField}
                  />
                </div>
              </div>
              <div>
                <label style={{ marginTop: 10 }}>
                  Content of Coupon Codes *
                </label>
                <label style={{ marginTop: 4, marginLeft: 6, fontSize: 11 }}>
                  You must select at least one, and you may select both
                </label>
                <div className="row">
                  <div
                    className="row"
                    style={{ marginTop: 8, marginLeft: 12 }}
                  >
                    <div>
                      <input
                        id="alpha"
                        type="checkbox"
                        checked={this.state.alpha}
                        onChange={this.handleAlpha}
                      />
                    </div>
                    <label style={{ textAlign: "center", marginLeft: 5 }}>
                      {" "}
                      Alpha{" "}
                    </label>
                  </div>
                  <div
                    className="row"
                    style={{ marginTop: 8, marginLeft: 30 }}
                  >
                    <div>
                      <input
                        id="numeric"
                        type="checkbox"
                        checked={this.state.numeric}
                        onChange={this.handleNumeric}
                      />
                    </div>
                    <label style={{ textAlign: "center", marginLeft: 5 }}>
                      Numeric
                    </label>
                  </div>
                </div>
              </div>
              <div style={{ marginLeft: 15, marginTop: 10 }}>
                <div className="row">
                  <div style={{ width: "20%" }}>
                    <label style={{ fontSize: 14 }}>Coupon Value Type *</label>
                  </div>
                  <div style={{ width: "20%", marginLeft: 15 }}>
                    <label style={{ fontSize: 14 }}>Number of Uses *</label>
                  </div>
                  <div style={{ width: "20%", marginLeft: 15 }}>
                    <label style={{ fontSize: 14 }}>Coupon Duration *</label>
                  </div>
                  <div style={{ width: "20%", marginLeft: 15 }}>
                    <label style={{ fontSize: 14 }}>Leading Characters</label>
                  </div>
                </div>
                <div className="row">
                  <div style={{ width: "20%" }}>
                    <Select
                      options={couponTypes}
                      onChange={this.updateSelection}
                      hideSelectedOptions={true}
                    />
                  </div>
                  <div style={{ width: "20%", marginLeft: 15 }}>
                    <Select
                      options={couponUseTypes}
                      onChange={this.updateSelection}
                      hideSelectedOptions={true}
                    />
                  </div>
                  <div style={{ width: "20%", marginLeft: 15 }}>
                    <Select
                      options={couponDurations}
                      onChange={this.updateSelection}
                      hideSelectedOptions={true}
                    />
                  </div>
                  <div style={{ width: "30%", marginLeft: 15 }}>
                    <Select
                      options={leadingCharacters}
                      onChange={this.updateSelection}
                      hideSelectedOptions={true}
                    />
                  </div>
                </div>
              </div>
              <label style={{ marginTop: 10 }}>Coupon Value *</label>
              <label style={{ marginTop: 4, marginLeft: 6, fontSize: 11 }}>
                Whole number for percent type, in cents for flat dollar discount
                (25 for 25%, 500 for $5, etc){" "}
              </label>
              <input
                type="number"
                className="form-control"
                id="couponValue"
                value={this.state.couponValue}
                onChange={this.updateField}
              />
            </div>
            <div className="form-group">
              <label style={{ marginLeft: 5 }}>Eligible Plan(s)</label>
              <Select
                options={plans}
                isMulti={true}
                onChange={this.updatePlans}
                hideSelectedOptions={true}
                isClearable={false}
              />
            </div>
            <div className="form-group">
              <button
                className="btn btn-block btn-success"
                onClick={this.generateCoupons}
              >
                Generate Coupons
              </button>
            </div>
          </Card>
        </div>
        <Modal show={this.state.showGeneratedModal} onHide={this.toggleShowGeneratedModal}>
            <Modal.Header closeButton>
              <Modal.Title>
                Generated Coupon Codes
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Below is the generated list of coupon codes.</p>
              <div className="row">
                <div className="col-3">Coupon Codes: </div>
                <div className="col-9">{this.state.generated.join(", ")}</div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-block btn-danger" onClick={this.toggleShowGeneratedModal}>Close</button>
            </Modal.Footer>
          </Modal>
      </Screen>
    );
  }

  updateField(e: any) {
    let ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  updateSelection = (input: any) => {
    let ns = this.state;
    ns[input.field] = input.value;
    this.setState(ns);
  };

  updatePlans(e: any) {
    if (e === null) {
      return
    }
    const plans = [];
    let v: any
    for (v of e) {
      plans.push(v.value)
    }
    this.setState({ plans: plans });
  }

  private handleAlpha() {
    this.setState({ alpha: !this.state.alpha });
  }

  private handleNumeric() {
    this.setState({ numeric: !this.state.numeric });
  }

  private toggleShowGeneratedModal(){
    this.setState({ showGeneratedModal: false });
  }

  private generateCoupons() {
    // Check for values
    if (
      this.state.couponUseType === "" ||
      this.state.couponType === "" ||
      this.state.couponDuration === "" ||
      this.state.couponValue === ""
    ) {
      return error("You must provide values for all required * fields.");
    }
    if (!this.state.alpha && !this.state.numeric) {
      return error("At least one content type must be selected.");
    }
    if (this.state.plans.length < 1) {
      return error(
        "Please provide at least 1 (one) Eligible Plan for these coupons."
      );
    }
    this.setState({ loading: true }, async () => {
      try {
        const result = await BillingAPI.generateCoupons("wagz2", {
          couponUseType: this.state.couponUseType,
          couponType: this.state.couponType,
          couponDuration: this.state.couponDuration,
          couponValue: parseInt(this.state.couponValue),
          alpha: this.state.alpha,
          numeric: this.state.numeric,
          numberOfCoupons: parseInt(this.state.numberOfCoupons),
          lengthOfCode: parseInt(this.state.lengthOfCode),
          leading: this.state.leading,
        });
        for (let r of result.body.data) {
          for (let p of this.state.plans) {
            await BillingAPI.linkCouponToPlan("wagz2", {
              couponCode: r,
              planId: p
            })
          };
        };
        return this.setState({
          loading: false,
          showGeneratedModal: true,
          generated: result.body.data,
          couponUseType: "",
          couponType: "",
          couponDuration: "",
          couponValue: "",
          alpha: false,
          numeric: false,
          numberOfCoupons: "",
          lengthOfCode: "",
          plans: [],
        }, () =>
          {
            this.props.onGeneration();
          }
        );
      } catch (err) {
        error(
          "Could not generate those coupons. Please contact an administrator."
        );
        this.setState({ loading: false });
      }
    });
  };
}

const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(AppActions, dispatch),
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GenerateCoupons);