import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Loading, Screen } from "src/components/structure";
import List from "./ListOfUsers";
import NewUser from "./NewUser";
import * as UserActions from "src/reducers/userReducer";
import { UserAPI } from "src/api";
import { IAdminUser } from "src/api/users";

interface IAdministrationProps {
  location: any;
  userActions: any;
  userState: any;
}

interface IAdministrationState {
  users: IAdminUser[];
  loading: boolean;
}

class Administration extends Component<IAdministrationProps, IAdministrationState> {
  constructor(props: IAdministrationProps) {
    super(props);

    this.state = {
      users: [],
      loading: false,
    };

    this.fetchUsers = this.fetchUsers.bind(this);
    this.handleNewUserCreated = this.handleNewUserCreated.bind(this);
  }

  componentDidMount(){
    this.fetchUsers();
  }

  render() {
    return (
      <Screen id="Administration-Administration" requiredRoles={["administrator"]}>
        <div className="row">
          <div className="col-sm-12 col-lg-10">
            {this.state.loading ? (<Loading />) : (<List
              users={this.state.users}
              loading={this.state.loading}
              onUserUpdated={this.fetchUsers}
            />)}
          </div>
          <div className="col-sm-12 col-lg-2">
            <NewUser
              loading={this.state.loading}
              onUserCreated={this.handleNewUserCreated}
            />
          </div>
        </div>
      </Screen>
    );
  }

  private fetchUsers(){
    this.setState({ loading: true }, async () => {
      try {
        const result = await UserAPI.getUsers();
        this.setState({
          users: result.body.data,
          loading: false,
        });
      } catch (err) {
        this.setState({ loading: false });
      }
    });
  }

  private handleNewUserCreated(newUser: any){
    this.setState({loading: true}, () => {
      const users = this.state.users;
      users.push(newUser);
      this.setState({users, loading: false});
    });
  }
}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Administration) as any
);