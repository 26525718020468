import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as UserActions from "src/reducers/userReducer";
import { BillingAPI } from "src/api";
import CacheManagementScreen from "src/components/screens/Backend/CacheManagement";
import { ListGroup, Table } from "react-bootstrap";
import { IDevice } from "src/api/devices";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import { styles } from "src/styles";

interface IBillingTabProps {
  device: IDevice;
  location: any;
  userActions: any;
  userState: any;
}

interface IBillingTabState {
  loading: boolean;
  chargifySubscriptionId: number;
  currentPlanId: number;
  status: "active" | "grace" | "cancel" | "deactivated";
  planDisplay: string;
  chargifyPlanId: number;
  planTerm: "monthly" | "annual" | "free" | "24months";
  coupons: any[];
}

class BillingTab extends Component<IBillingTabProps, IBillingTabState> {
  constructor(props: IBillingTabProps) {
    super(props);

    this.state = {
      loading: false,
      chargifySubscriptionId: 0,
      currentPlanId: 0,
      status: "active",
      planDisplay: "",
      chargifyPlanId: 0,
      planTerm: "free",
      coupons: [],
    };

    this.getBillingDevice = this.getBillingDevice.bind(this);
    this.getBillingPlan = this.getBillingPlan.bind(this);
  }

  componentDidMount() {
    this.getBillingDevice();
  }

  getBillingDevice() {
    this.setState({ loading: true }, async () => {
      let billingDevice: any = null;
      let coupons: any = [];
      try {
        const res = await BillingAPI.getBillingDevice(
          "wagz2",
          this.props.device.registeredBy,
          this.props.device.id
        );
        billingDevice = res.body.data;
      }catch(err){}

      try{
        const couponsResult = await BillingAPI.getCouponsForDeviceId(
          "wagz2",
          this.props.device.registeredBy,
          this.props.device.id
        );
        coupons = couponsResult.body.data;
      }catch(err){}
      this.setState({
        ...billingDevice,
        loading: false,
        coupons,
      }, () => {this.getBillingPlan(billingDevice.currentPlanId)});      
    });
  }

  getBillingPlan(plan: number) {
    this.setState({ loading: true }, async () => {
      try {
        const res = await BillingAPI.getPlanByID(plan);
        this.setState({
          loading: false,
          planDisplay: res.body.data.display,
          chargifyPlanId: res.body.data.chargifyId,
          planTerm: res.body.data.term,
        });
      } catch (err) {
        this.setState({ loading: false });
      }
    });
  }

  processValue(value: number, type: string) {
    if (type === "flat") {
      var flat = value / 100;
      return `$${flat.toFixed(2)}`;
    }
    return `${value}%`;
  }

  showCoupons() {
    const deviceCoupons = [];
    if (this.state.coupons.length === 0) {
      deviceCoupons.push(
        <strong key={1}>No coupons were used for this device</strong>
      );
    } else {
      let index = 0;
      for (let coups of this.state.coupons) {
        index += 1;
        deviceCoupons.push(
          <div key={index}>
            <Accordion allowZeroExpanded={true} allowMultipleExpanded={true}>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>{coups.couponCode}</AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <div className="row">
                    <div style={{ width: "80%" }}>
                      <div style={{ marginLeft: 20, marginTop: 20 }}>
                        <div>
                          <strong>Coupon Code: </strong>
                          {coups.couponCode}
                        </div>
                        <div>
                          <strong>Status: </strong>
                          {coups.status}
                        </div>
                        <div>
                          <strong>Value:</strong>{" "}
                          {this.processValue(
                            coups.couponValue,
                            coups.couponType
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </div>
        );
      }
    }
    return deviceCoupons;
  }

  render() {
    return (
      <div>
        <Table striped bordered hover>
          <thead style={styles.thead}>
            <tr>
              <th style={styles.tableHead}>Billing Device Info</th>
              <th style={styles.tableHead}>Billing Plan Info</th>
              <th style={styles.tableHead}>Coupons for Device</th>
              <th style={styles.tableHead}>Clear Cache</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={styles.tCellWidth}>
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    Current Plan ID: {this.state.currentPlanId}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Billing Status: {this.state.status}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Chargify Subscription ID:{" "}
                    {this.state.chargifySubscriptionId}
                  </ListGroup.Item>
                </ListGroup>
              </td>
              <td style={styles.tCellWidth}>
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    Plan Display: {this.state.planDisplay}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Plan Term: {this.state.planTerm}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Chargify Plan ID: {this.state.chargifyPlanId}
                  </ListGroup.Item>
                </ListGroup>
              </td>
              <td style={styles.tCellWidth}>{this.showCoupons()}</td>
              <td style={styles.tCellWidth}>
                <CacheManagementScreen
                  userId={this.props.device.registeredBy}
                  otherId={this.props.device.id}
                  cacheType="billing_device"
                />
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    );
  }
}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default connect<{}, {}, any>(
  mapStateToProps,
  mapDispatchToProps
)(BillingTab);
