import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as UserActions from "src/reducers/userReducer";
import { PetsAPI } from "src/api";
import Screen from "src/components/structure/Screen";
import { IPet, BlankPet } from "src/api/pets";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import PetInfoTab from "./PetTabs/InfoTab";
import MetricsTab from "./PetTabs/MetricsTab";
import LinkTab from "./PetTabs/LinkTab";
import PetManageTab from "./PetTabs/ManageTab";
import BreedsTab from "./PetTabs/BreedsTab";
import GeofencesInfoTab from "./PetTabs/GeofencesInfo";
import HealthBoxTab from "./PetTabs/HealthBoxTab";
import ConsumptionTab from "./PetTabs/ConsumptionTab";
import LocationScreen from "src/components/structure/LocationScreen";
import EventsTab from "src/components/structure/EventsTab";
import RankingsTab from "./PetTabs/RankingsTab";
import { AnalyticEventsScreen } from "src/components/screens/Reports/Analytics/AnalyticEvents";
import BowlStatisticsTab from "./PetTabs/BowlStatisticsTab";
import SettingsTab from "./PetTabs/SettingsTab";

interface IPetPageProps {
  match: any;
  history: any;
  location: any;
  userActions: any;
  userState: any;
}


interface IPetPageState {
  loading: boolean;
  petId: number;
  userId: number;
  pet: IPet;
  view: string;
}

class PetPage extends Component<IPetPageProps, IPetPageState> {
  constructor(props: IPetPageProps) {
    super(props);

    this.state = {
      loading: false,
      petId: this.props.match.params.petId,
      userId: this.props.match.params.userId,
      pet: BlankPet,
      view: "info",
    };

    this.goBack = this.goBack.bind(this);
  }


  goBack() {
    this.props.history.goBack();
  }

  componentDidMount() {
    this.getPet();
  }

  getPet() {
    this.setState({ loading: true }, async () => {
      try {
        const petResult = await PetsAPI.getPetById("wagz", this.state.userId, this.state.petId);
        this.setState({
          loading: false,
          pet: petResult.body.data,
        });
      } catch (err) {
        this.setState({ loading: false });
      }
    });
  }

  render() {
    if (this.state.loading) {
      return (
        <div style={{ textAlign: "center" }}>
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      );
    }
    return (
      <Screen requiredRoles={["support"]}>
        <div className="col-sm-12 col-lg-12">
          <div>
            <h1>{this.state.pet && this.state.pet.name ? this.state.pet.name : "Pet"} Information (ID: {this.state.petId})</h1>
          </div>
          <div className="row view-select-row">
            <div className="col-12">
              <Tabs>
                <TabList>
                  <Tab>Information</Tab>
                  <Tab>Metrics</Tab>
                  <Tab>Links</Tab>
                  <Tab>Events</Tab>
                  <Tab>Breeds</Tab>
                  <Tab>Geofences</Tab>
                  <Tab>Pet Bowl Statistics</Tab>
                  { this.state.pet.species === "cat" && (
                  <Tab>Health Box</Tab>
                  )}
                  <Tab>Bowl Consumptions</Tab>
                  <Tab>Locations</Tab>
                  <Tab>Metric Rankings</Tab>
                  <Tab>Analytic Events</Tab>
                  <Tab>Settings</Tab>
                  <Tab>Management</Tab>
                </TabList>
                <TabPanel>
                  <PetInfoTab pet={this.state.pet} />
                </TabPanel>
                <TabPanel>
                  <MetricsTab pet={this.state.pet} userId={this.state.pet.userId} />
                </TabPanel>
                <TabPanel>
                  <LinkTab pet={this.state.pet} userId={this.state.pet.userId} />
                </TabPanel>
                <TabPanel>
                  <EventsTab accessLevel={this.props.userState.user.accessLevel} loading={this.state.loading} eventsFor="pet" deviceId={0} userId={this.state.pet.userId} petId={this.state.pet.id} />
                </TabPanel>
                <TabPanel>
                  <BreedsTab pet={this.state.pet} userId={this.state.pet.userId} />
                </TabPanel>
                <TabPanel>
                  <GeofencesInfoTab pet={this.state.pet} userId={this.state.pet.userId} />
                </TabPanel>
                <TabPanel>
                  <BowlStatisticsTab pet={this.state.pet.id} user={this.state.pet.userId} loading={this.state.loading}/>
                </TabPanel>
                  { this.state.pet.species === "cat" && (
                <TabPanel>
                  <HealthBoxTab userId={this.state.pet.userId} petId={this.state.pet.id} />
                </TabPanel>
                  )}
                  <TabPanel>
                    <ConsumptionTab userId={this.state.pet.userId} petId={this.state.pet.id} />
                  </TabPanel>
                <TabPanel>
                  <LocationScreen userId={this.state.pet.userId} locationsFor="pet" petId={this.state.pet.id} />
                </TabPanel>
                <TabPanel>
                  <RankingsTab pet={this.state.pet} />
                </TabPanel>
                <TabPanel>
                  <AnalyticEventsScreen target="pet" targetId={this.state.petId} />)
                </TabPanel>
                <TabPanel>
                  <SettingsTab userId={this.state.pet.userId} petId={this.state.pet.id} />
                </TabPanel>
                <TabPanel>
                  <PetManageTab pet={this.state.pet} />
                </TabPanel>
              </Tabs>
            </div>
          </div>
        </div>
      </Screen>
    );
  }

}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PetPage) as any
);
