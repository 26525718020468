import React, { Component } from "react";
import PaymentCard from "react-payment-card-component";

interface CurrentPaymentMethodProps {
  brand: string;
  lastFour: string;
  expMonth: string;
  expYear: string;
  firstName: string;
  lastName: string;
}

export interface IPaymentMethod {
  brand: string;
  lastFour: string;
  expMonth: string;
  expYear: string;
  firstName: string;
  lastName: string;
  cvv: string;
}


export class CurrentPaymentMethod extends Component<
  CurrentPaymentMethodProps,
  any
  > {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div
        className="col-sm-12"
        style={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <PaymentCard
          className="col-sm-12"
          type="black"
          model="normal"
          brand={this.props.brand.toLowerCase()}
          number={`************${this.props.lastFour}`}
          holderName={this.props.firstName && this.props.firstName !== "" ? `${this.props.firstName} ${this.props.lastName}` : ""}
          expiration={this.props.expMonth + "/" + this.props.expYear}
          flipped={false}
        />
      </div>
    );
  }
}