import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Card, DatePicker, Screen, Table } from "src/components/structure";

import * as UserActions from "src/reducers/userReducer";
import { JobsAPI, UserAPI } from "src/api";
import { IJob } from "src/api/jobs";
import moment from "moment";
import { IAdminUser } from "src/api/users";
import { Modal } from "react-bootstrap";
import { JobCreateScreen } from "./JobCreate";

interface IJobsListScreenProps {
  userState: any;
}

interface IJobsListScreenState {
  loading: boolean;
  jobs: IJob[];

  // filters
  adminUsers: IAdminUser[];
  knownJobs: any[];
  name: string;
  targetService: string;
  requestingUserId: string;
  requestedOnBetweenStart: moment.Moment;
  requestedOnBetweenEnd: moment.Moment;
  status: string;

  orderBy: string;
  orderDirection: "ASC" | "DESC"

  showCreateJobModal: boolean;
}


const app = "wagz2";

const cols: any[] = [
  {
    "label": "ID",
    "field": "id",
  },
  {
    "label": "Requested On",
    "field": "requestedOn",
  },
  {
    "label": "Updated On",
    "field": "statusLastUpdatedOn",
    "sort": "desc",
  },
  {
    "label": "Job Name",
    "field": "name",
  },
  {
    "label": "Service",
    "field": "targetService",
  },
  {
    "label": "Requested By",
    "field": "requestingUser",
  },
  {
    "label": "Status",
    "field": "status",
  },
  {
    "label": "Notification Type",
    "field": "notificationType",
  },
  {
    "label": "",
    "field": "view",
  },
];


class JobsListScreen extends Component<
  IJobsListScreenProps,
  IJobsListScreenState
> {
  constructor(props: IJobsListScreenProps) {
    super(props);
    this.state = {
      loading: true,
      jobs: [],

      adminUsers: [],
      knownJobs: [],

      requestingUserId: "",
      name: "",
      targetService: "",
      requestedOnBetweenStart: moment().subtract(3, "days"),
      requestedOnBetweenEnd: moment().add(1, "days"),
      status: "",

      orderBy: "requestedOn",
      orderDirection: "DESC",
      
      showCreateJobModal: false,
    };

    this.updateFilter = this.updateFilter.bind(this);
    this.updateFilterRequestedOnBetweenEnd = this.updateFilterRequestedOnBetweenEnd.bind(this);
    this.updateFilterRequestedOnBetweenStart = this.updateFilterRequestedOnBetweenStart.bind(this);
    this.setup = this.setup.bind(this);
    this.getJobs = this.getJobs.bind(this);
    this.toggleCreateModal = this.toggleCreateModal.bind(this);
    this.handleJobCreated = this.handleJobCreated.bind(this);
  }

  componentDidMount(){
    this.setup();
  }

  render() {
    return (
      <Screen shouldAuthCheck={true} requiredRoles={["reporting"]}>
        <div className="row">
          <div className="col-12">
            <Card title="Job Filters">
              <div className="row">
                <div className="col-10">

                  <div className="row row-margin-bottom">
                    <div className="col-4">
                      <label>Requestor</label>
                      <select className="form-control" value={this.state.name} id="name" onChange={this.updateFilter}>
                        <option value="">All</option>
                        {this.state.adminUsers.map((u) => {
                          return <option key={u.id} value={u.id}>{u.firstName} {u.lastName} ({u.id})</option>
                        })}
                      </select>
                    </div>
                    <div className="col-4">
                      <label>Requested Between</label>
                      <DatePicker date={this.state.requestedOnBetweenStart} onDateSaved={this.updateFilterRequestedOnBetweenStart} />
                    </div>
                    <div className="col-4">
                      <label>And</label>
                      <DatePicker date={this.state.requestedOnBetweenEnd} onDateSaved={this.updateFilterRequestedOnBetweenEnd} />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-4">
                      <label>Status</label>
                      <select className="form-control" value={this.state.status} id="status" onChange={this.updateFilter}>
                        <option value="">All</option>
                        <option value="pending">Pending</option>
                        <option value="processing">Processing</option>
                        <option value="complete">Complete</option>
                        <option value="error">Error</option>
                      </select>                      
                    </div>
                    <div className="col-4">
                      <label>Target Service</label>
                      <select className="form-control" value={this.state.targetService} id="targetService" onChange={this.updateFilter}>
                        <option value="">All</option>
                        {this.state.knownJobs.map((j, i) => {
                          return <option key={i} value={j.targetService}>{j.targetService}</option>
                        })}
                      </select>
                    </div>
                    <div className="col-4">
                      <label>Name</label>
                      <select className="form-control" value={this.state.name} id="name" onChange={this.updateFilter}>
                        <option value="">All</option>
                        {this.state.knownJobs.map((j, i) => {
                          return <option key={i} value={j.name}>{j.name}</option>
                        })}
                      </select>                      
                    </div>
                  </div>


                </div>
                <div className="col-2">
                  <button className="btn btn-block btn-primary" onClick={this.getJobs} style={{height: "100%"}}>Update</button>
                </div>

              </div>
            </Card>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <Table
              title="Jobs"
              csvFileName={`jobs-as-of-${moment().format("YYYY-MM-DDTHH:mm:ss")}`}
              showDownload={true}
              columns={cols}
              rows={this.state.jobs}
              preElement={(
                <div className="row">
                  <div className="col-12" style={{textAlign: "right"}}>
                    <button className="btn btn-success" onClick={this.toggleCreateModal}>Create New Job</button>
                  </div>
                </div>
              )}
            />
          </div>
        </div>


        <Modal
            show={this.state.showCreateJobModal}
            onHide={this.toggleCreateModal}
            dialogClassName="modal-xl"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                Create New Project
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <JobCreateScreen adminUserId={this.props.userState.user.id} onCreate={this.handleJobCreated} />
            </Modal.Body>
          </Modal>
      </Screen>
    );
  }

  private toggleCreateModal(){
    this.setState({ showCreateJobModal: !this.state.showCreateJobModal });
  }

  private updateFilter(e: any){
    const ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private updateFilterRequestedOnBetweenStart(newDate: moment.Moment){
    this.setState({ requestedOnBetweenStart: newDate});
  }

  private updateFilterRequestedOnBetweenEnd(newDate: moment.Moment){
    this.setState({ requestedOnBetweenEnd: newDate});
  }

  private setup(){
    this.setState({ loading: true }, async () => {
      try{
        // get all of the admin users for filtering
        const usersResult = await UserAPI.getUsers({ status: "active"});
        // get all of the known jobs
        const knownJobsResult = await JobsAPI.getKnownJobs(app);

        // forward to the job get
        this.setState({ adminUsers: usersResult.body.data, knownJobs: knownJobsResult.body.data }, () => this.getJobs());
      }catch(err){

      }
    });
  }
  
  private getJobs(){
    this.setState({ loading: true }, async () => {
      try{
        const query: any = {
          requestedOnBetweenStart: this.state.requestedOnBetweenStart.local().startOf("day").format("YYYY-MM-DDTHH:mm:ss") + "Z",
          requestedOnBetweenEnd: this.state.requestedOnBetweenEnd.local().endOf("day").format("YYYY-MM-DDTHH:mm:ss") + "Z",
        }
        if(this.state.status !== ""){
          query.status = this.state.status;
        }
        if(this.state.name !== ""){
          query.name = this.state.name;
        }
        if(this.state.targetService !== ""){
          query.targetService = this.state.targetService;
        }
        if(this.state.requestingUserId !== ""){
          query.requestingUserType = "admin";
          query.requestingUserId = parseInt(this.state.targetService, 10);
        }


        const result = await JobsAPI.getJobs(app, query);
        const jobs: IJob[] = [];
        for(const j of result.body.data){
          j.requestedOn = moment(j.requestedOn).format("MM/DD/YY h:mm A");
          j.statusLastUpdatedOn = moment(j.statusLastUpdatedOn).format("MM/DD/YY h:mm A");
          j.view = (<Link to={`/reports/jobs/${j.id}`}>View</Link>);
          jobs.push(j);
        }
        // sort, in case the server doesn't do it
        jobs.sort((a, b) => {
          return moment(a.statusLastUpdatedOn, "MM/DD/YY h:mm A").isBefore(moment(b.statusLastUpdatedOn, "MM/DD/YY h:mm A")) ? 1 : -1;
        })
        this.setState({ loading: false, jobs});
      }catch(err){
        this.setState({ loading: false });
      }
    })
  }

  private handleJobCreated(job: IJob){
    const jobs = this.state.jobs;
    job.requestedOn = moment(job.requestedOn).format("MM/DD/YY h:mm A");
    job.statusLastUpdatedOn = moment(job.statusLastUpdatedOn).format("MM/DD/YY h:mm A");
    job.requestingUser = "Processing...";
    job.view = (<Link to={`/reports/jobs/${job.id}`}>View</Link>);
    jobs.unshift(job);
    this.setState({ jobs, showCreateJobModal: false });
  }
}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(JobsListScreen) as any
);
