import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import * as UserActions from "src/reducers/userReducer";
import Table from "src/components/structure/Table";
import { Modal } from "react-bootstrap";
import * as Alert from "src/components/structure/Alert";
import { capitalizeFirstLetter } from "src/utils/utilities";
import { PetsAPI } from "src/api";
import { styles } from "src/styles";
import { IColumns } from "src/utils/customTypes";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';

interface IPetsTabProps {
  userId: number;
  location: any;
  userActions: any;
  userState: any;
}

interface IPetsTabState {
  loading: boolean;
  showModal: boolean;
  id: number;
  name: string;
  species: string;
  gender: string | undefined;
  lifestyle: string;
  status: string;
  dailyWaterGoal: number;
  rows: any[];
  csvData: any[];
}

const cols = [
  {
    label: "",
    field: "view",
    sort: "disabled"
  },
  {
    label: "ID",
    field: "id",
    sort: "asc",
  },
  {
    label: "Name",
    field: "name",
    sort: "asc",
  },
  {
    label: "Species",
    field: "species",
    sort: "asc",
  },
  {
    label: "Gender",
    field: "gender",
    sort: "asc",
  },
  {
    label: "Lifestyle",
    field: "lifestyle",
    sort: "asc",
  },
  {
    label: "Daily Water Goal (oz.)",
    field: "dailyWaterGoal",
    sort: "asc",
  },
  {
    label: "Status",
    field: "status",
    sort: "asc",
  },
  {
    label: "Actions",
    field: "actions",
  },
];

class PetsTab extends Component<IPetsTabProps, IPetsTabState> {
  constructor(props: IPetsTabProps) {
    super(props);

    this.state = {
      loading: false,
      showModal: false,
      id: 0,
      name: "",
      species: "",
      gender: "",
      lifestyle: "",
      status: "",
      dailyWaterGoal: 0,
      rows: [],
      csvData: [],
    };

    this.updateField = this.updateField.bind(this);
    this.getUserPets = this.getUserPets.bind(this);
    this.editPet = this.editPet.bind(this);
    this.processData = this.processData.bind(this);
  }

  updateField(e: any) {
    let ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  componentDidMount() {
    this.getUserPets();
  }

  formatGender(gender: string) {
    switch (gender) {
      case "maleNotNeutered":
        return "Male";
      case "maleNeutered":
        return "Male";
      case "male":
        return "Male";
      case "femaleSpayed":
        return "Female";
      case "femaleNotSpayed":
        return "Female";
      case "female":
        return "Female";
    }
  }

  getUserPets() {
    this.setState({ loading: true }, async () => {
      try {
        const results = await PetsAPI.getPetsForUser("wagz", this.props.userId);

        const userPets = [];
        const csvData: any = [];
        for (let result of results.body.data) {
          result.species = capitalizeFirstLetter(result.species)
          result.gender = this.formatGender(result.gender);
          result.status = capitalizeFirstLetter(result.status);
          csvData.push(result);

          userPets.push({
            id: result.id,
            view: (
              <div style={{textAlign: "center"}}>
                <Link
                  to={`/support/users/${result.userId}/pets/${result.id}`}
                  id="support_page"
                  className="link-light"
                >
                  <FontAwesomeIcon icon={faEye} />
                </Link>
              </div>
            ),
            name: result.name,
            species: result.species,
            gender: result.gender,
            lifestyle: result.lifestyle,
            dailyWaterGoal: result.dailyWaterGoal,
            status: result.status,
            actions: (
              <div>{this.props.userState.user.accessLevel !== "read_only" && (<button
                className="btn btn-sm btn-pri col-sm-12"
                style={{marginTop: 0, marginBottom: 0}}
                onClick={() => this.showEditModal(result.id)}
              >
                View/Edit
              </button>)}</div>
            ),
          });
        }
        this.setState({
          loading: false,
          rows: userPets,
          csvData,
        });
      } catch (err) {
        this.setState({ loading: false });
      }
    });
  }

  showEditModal(id: any) {
    this.setState({ loading: true }, async () => {
      try {
        let res = await PetsAPI.getPetById("wagz", this.props.userId, id);

        this.setState({
          id: id,
          name: res.body.data.name,
          species: capitalizeFirstLetter(res.body.data.species),
          gender: this.formatGender(res.body.data.gender),
          lifestyle: res.body.data.lifestyle,
          dailyWaterGoal: res.body.data.dailyWaterGoal,
          status: res.body.data.status,
          showModal: true,
          loading: false,
        });
      } catch (err) {
        this.setState({ loading: false, showModal: false });
      }
    });
  }

  editPet() {
    this.setState({ loading: true }, async () => {
      try {
        await PetsAPI.updatePet("wagz", this.props.userId, this.state.id, {
          name: this.state.name,
          status: this.state.status,
        });
        Alert.success("Pet updated successfully");
        this.setState({ loading: false, showModal: false }, () =>
          this.getUserPets()
        );
      } catch (err) {
        this.setState({
          loading: false,
          showModal: false,
        });
        Alert.error("There was an issue updating that pet. Please try again later.");
      }
      this.getUserPets();
    });
  }

  processData(columns: IColumns[], rows: any) {
    const data = {
      columns,
      rows,
    };
    return data;
  }

  render() {
    return (
      <div>
        <Table
          title="Pets"
          csvFileName={`petsFor-userID-${this.props.userId}`}
          showDownload={true}
          columns={cols}
          rows={this.state.rows}
          csvData={this.state.csvData}
        />
        <div>
          <Modal
            dialogClassName="modal-75"
            show={this.state.showModal}
            onHide={() => {
              this.setState({ showModal: false });
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                Update Information for {this.state.name}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="center">
                <p style={{ fontSize: 14, textAlign: "center" }}>
                  <strong>
                    Update the fields you wish to change, then hit Submit.
                  </strong>
                </p>{" "}
                <br />
              </div>
              <div className="form-group" style={{ marginTop: 10 }}>
                <input
                  id="name"
                  type="text"
                  className="form-control"
                  placeholder="Name"
                  autoComplete="new-password"
                  onChange={this.updateField}
                  value={this.state.name}
                />
              </div>
              <div className="form-group">
                <select
                  id="species"
                  className="form-control"
                  placeholder="Species"
                  disabled={true}
                  value={this.state.species}
                >
                <option value={this.state.species}>{this.state.species}</option>
              </select>
              </div>
              <div className="form-group">
                <select
                  id="gender"
                  className="form-control"
                  placeholder="Gender"
                  disabled={true}
                  value={this.state.gender}
                >
                <option value={this.state.gender}>{this.state.gender}</option>
              </select>
              </div>
              <div className="form-group">
                <select
                  id="lifestyle"
                  className="form-control"
                  placeholder="LifeStyle"
                  disabled={true}
                  value={this.state.lifestyle}
                >
                <option value={this.state.lifestyle}>{this.state.lifestyle}</option>
              </select>
              </div>
              <div className="form-group">
                <select
                  className="form-control"
                  id="status"
                  value={this.state.status}
                  onChange={this.updateField}
                >
                  <option value="active">Active</option>
                  <option value="removed">Removed</option>
                </select>
              </div>
              <div className="text">
                <button
                  className="btn btn-block btn-submit"
                  style={styles.button}
                  onClick={this.editPet}
                >
                  Submit
                </button>
              </div>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>
        </div>
      </div>
    );
  }
}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default connect<{}, {}, any>(
  mapStateToProps,
  mapDispatchToProps
)(PetsTab);
