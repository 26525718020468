import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import Card from "src/components/structure/Card";
import { ListGroup } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMobile, faClipboard, faDog, faHeartbeat, faLaptopCode, faCodeBranch, faSimCard, faSignal, faMinusCircle, faGlobe, faMap,
  faUser, faMicrochip, faTv, faBatteryEmpty, faBatteryFull, faBatteryHalf, faBatteryQuarter, faBatteryThreeQuarters, faSearchLocation } from '@fortawesome/free-solid-svg-icons';
import * as UserActions from "src/reducers/userReducer";
import { DeviceAPI, getUrl, PetsAPI, UserAPI } from "src/api";
import { faAws, faBluetooth } from "@fortawesome/free-brands-svg-icons";
import { capitalizeFirstLetter } from "src/utils/utilities"
import moment from "moment";
import { faCalendarCheck } from "@fortawesome/free-regular-svg-icons";
import { BlankDevice } from "src/api/devices";
import { BlankUser, IUser } from "src/api/users";

const dateFormat = "MM/DD/YY HH:mm:ss A";

interface IInfoTabProps {
  deviceId: number;
  location: any;
  userActions: any;
  userState: any;
}

interface IInfoTabState {
  loading: boolean;
  nickname: string;
  productId: string;
  status: string;
  firmwareVersion: string;
  registeredOn: string;
  modemFirmwareVersion: string;
  registeredBy: any;
  imei: string;
  iccid: string;
  logInterval: number;
  heartbeatStatus: string;
  lastHeartbeat: string;
  onboardedOn: string;
  otaChannel: string;
  jasperUrl: string;
  pets: any[];
  statusBar: any;
  connectivityLog: any;
  user: any;
  liveTrackingEnabled: boolean;
  liveTrackingEnabledUntil: string;
  geofencesActive: "on" | "off",
  geofencesCorrections: "on" | "off",
}

class InfoTab extends Component<IInfoTabProps, IInfoTabState> {
  constructor(props: IInfoTabProps) {
    super(props);

    this.state = {
      loading: true,
      nickname: "",
      productId: "",
      status: "",
      firmwareVersion: "",
      registeredOn: "",
      modemFirmwareVersion: "",
      registeredBy: "",
      imei: "",
      iccid: "",
      logInterval: 0,
      heartbeatStatus: "",
      lastHeartbeat: "",
      onboardedOn: "",
      otaChannel: "",
      jasperUrl: "",
      pets: [],
      statusBar: null,
      connectivityLog: null,
      user: null,
      liveTrackingEnabled: false,
      liveTrackingEnabledUntil: "--",
      geofencesActive: "off", 
      geofencesCorrections: "off"
    };

    this.updateField = this.updateField.bind(this);
    this.getDeviceById = this.getDeviceById.bind(this);
  }

  updateField(e: any) {
    let ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  componentDidMount() {
    this.getDeviceById();
  }

  getDeviceById() {
    this.setState({ loading: true }, async () => {
      // each of these need to be in their own try/catch to avoid issues of
      // missing or inappropriate data
      let device: any = BlankDevice;
      const pets: any[] = [];
      let user: IUser = BlankUser;
      let connectivityLog: any = null;
      let statusBar: any = null;
      let liveTrackingEnabled: boolean = false;
      let liveTrackingEnabledUntil: string = "";
      let geofencesActive = "off";
      let geofencesCorrections = "off";

      try {
        const devicesResult = await DeviceAPI.getDeviceById(
          "wagz",
          this.props.deviceId
        );
        device = devicesResult.body.data;
      }catch(err){
      }

      try{
        const linksResult = await DeviceAPI.getPetDeviceLinksForDevice("wagz", this.props.deviceId);
        for(const l of linksResult.body.data){
          const pResult = await PetsAPI.getPetById("wagz2", device.registeredBy, l.petId);
          pets.push(pResult.body.data);

          // get the geofences for the pet
          const geoInfo = await PetsAPI.getGeofenceSettingsForPet("wagz2", device.registeredBy, l.petId)
          const g = geoInfo.body.data;
          geofencesActive = g.active;
          geofencesCorrections = g.corrections;
        }
      }catch(err){

      }

      try{
        const uResult = await UserAPI.getUserById("wagz2", device.registeredBy);
        user = uResult.body.data;
      }catch(err){

      }

      try{
        const statusBarResult = await DeviceAPI.getDeviceStatus("wagz2", device.id);
        statusBar = statusBarResult.body.data;
        if(statusBar.liveTrackingEnabled && statusBar.liveTrackingEnabled === "on"){
          liveTrackingEnabled = true;
          const ltUn = moment(statusBar.liveTrackingEnabledUntil).utc();
          if(ltUn.isBefore(moment())){
            liveTrackingEnabledUntil = `EXPIRED at ${ltUn.format("MM/DD/YY hh:mm:ss")}`;
          } else {
            liveTrackingEnabledUntil = `Live Tracking Enabled Until ${ltUn.format("MM/DD/YY hh:mm:ss")} (${ltUn.fromNow()})`;
          }
        } else {
          liveTrackingEnabled = false;
          liveTrackingEnabledUntil = "Live Tracking Not Enabled";
        }
      }catch(err){}

      try{
        const logsResult = await DeviceAPI.getDeviceConnectivityLogs("wagz2", device.registeredBy, device.id, moment().subtract(2, "days"), moment().add(1, "days"), { count: 1});
        // just in case there's a bunch, check the first and last to figure out which one to use
        const logsRaw = logsResult.body.data;
        if(logsRaw.length === 1){
          connectivityLog = logsRaw[0];
        }else if(logsRaw.length !== 0){
          const a = logsRaw[0];
          const b = logsRaw[logsRaw.length - 1];
          if(moment(a.posted).isBefore(moment(b.posted))){
            connectivityLog = b;
          } else {
            connectivityLog = a
          }
        }
      }catch(err){}

      const heartbeatDate = moment.utc(device.heartbeat);
      const lastHeartbeat = moment(heartbeatDate).format(dateFormat) + " (" + heartbeatDate.fromNow()+ ")";

      const registeredOnDate = moment.utc(device.registeredOn);
      const registeredOn = registeredOnDate.utc().format(dateFormat) + " (" + registeredOnDate.fromNow() + ")";

      const onboardedOnDate = moment.utc(device.onboardedOn);
      const onboardedOn = onboardedOnDate.utc().format(dateFormat) + " (" + onboardedOnDate.fromNow() + ")";

      this.setState({
        ...device,
        loading: false,
        iccid: device.simId,
        registeredOn,
        lastHeartbeat,
        onboardedOn,
        pets,
        user,
        connectivityLog,
        statusBar,
        liveTrackingEnabled,
        liveTrackingEnabledUntil,
        geofencesActive,
        geofencesCorrections,
        jasperUrl: getUrl("jasper", "sim", {"sim": device.simId})
      });
    });
  }

  render() {
    return (
      <div className="row">
        <div className="col-10 offset-1">
          <Card title="" loading={this.state.loading}>
            <div className="row">
              <div className="col-6">
                <ListGroup variant="flush">
                  <ListGroup.Item><FontAwesomeIcon icon={faDog} /> - {this.state.nickname}</ListGroup.Item>
                  <ListGroup.Item><FontAwesomeIcon icon={faCodeBranch} /> - Firmware Version: {this.state.firmwareVersion === "" ? "Unknown" : this.state.firmwareVersion}</ListGroup.Item>
                  <ListGroup.Item><FontAwesomeIcon icon={faMicrochip} /> - Modem FW Version: {this.state.modemFirmwareVersion === "" ? "Unknown" : this.state.modemFirmwareVersion}</ListGroup.Item>
                  <ListGroup.Item><FontAwesomeIcon icon={faTv} /> - OTA Channel: {this.state.otaChannel}</ListGroup.Item>
                  <ListGroup.Item><FontAwesomeIcon icon={faClipboard} /> - Device Status: {capitalizeFirstLetter(this.state.status)}</ListGroup.Item>
                  <ListGroup.Item><FontAwesomeIcon icon={faCalendarCheck} /> - Onboarded {this.state.onboardedOn}</ListGroup.Item>
                  <ListGroup.Item><FontAwesomeIcon icon={faUser} /> - Registered {this.state.registeredOn} by {this.state.user && this.state.user.id !== 0 ? <Link to={`/support/users/${this.state.user.id}`}>{`${this.state.user.id} ${this.state.user.firstName} ${this.state.user.lastName}`}</Link> : "Unknown"} </ListGroup.Item>
                    
                  <ListGroup.Item><FontAwesomeIcon icon={faDog} /> - Linked Pets
                    {this.state.pets.map((p: any) => {
                      return (
                        <div className="row" key={p.id}>
                          <div className="col-11 offset-1">
                            <Link to={`/support/users/${p.userId}/pets/${p.id}`}>{p.id} {p.name}</Link>
                          </div>
                        </div>
                      )
                    })}
                  </ListGroup.Item>
                </ListGroup>
              </div>
              <div className="col-6">
                <ListGroup variant="flush">
                  <ListGroup.Item><FontAwesomeIcon icon={faLaptopCode} /> - Serial Number:  {this.state.productId}</ListGroup.Item>
                  <ListGroup.Item><FontAwesomeIcon icon={faMobile} /> - IMEI:  {this.state.imei}</ListGroup.Item>
                  <ListGroup.Item><FontAwesomeIcon icon={faSimCard} /> - ICCID:  {this.state.iccid} || <a href={this.state.jasperUrl} target="new">View In Jasper Terminal</a></ListGroup.Item>
                  <ListGroup.Item><FontAwesomeIcon icon={faAws} /> - Current Log Interval: {this.state.logInterval === 0 ? "OFF" : `${this.state.logInterval} seconds`} (contact engineering to change)</ListGroup.Item>
                  
                  <ListGroup.Item><FontAwesomeIcon icon={faHeartbeat} /> - Last Heartbeat: {capitalizeFirstLetter(this.state.heartbeatStatus)}: {this.state.lastHeartbeat}</ListGroup.Item>
                  <ListGroup.Item><FontAwesomeIcon icon={faHeartbeat} /> - Last Connectivity Log: {this.state.connectivityLog ? (<span>{moment(this.state.connectivityLog.posted).format(dateFormat)} ({moment.utc(this.state.connectivityLog.posted).fromNow()})</span>) : (<span>Unavailable</span>)}</ListGroup.Item>
                  <ListGroup.Item><FontAwesomeIcon icon={faHeartbeat} /> - Status Bar {this.buildStatusBarDisplay()}
                  </ListGroup.Item>
                </ListGroup>
              </div>
            </div>
          </Card>
        </div>
      </div>
    );
  }

  buildStatusBarDisplay(){
    if(!this.state.statusBar){
      return (
        <strong>Unknown</strong>
      )
    }

    const sb = this.state.statusBar;
    // battery, cell, wifi, bluetooth, raw location
    const ago = moment(this.state.statusBar.lastUpdated).fromNow();

    return (
      <div key="sb-bar">
        <span key="sb-ago">last seen {ago}</span>
        <div className="row" style={{marginTop: 10, marginBottom: 10}}>
          <div className="col-3 offset-1">
            {sb.batteryLevel > 80 ? (<FontAwesomeIcon icon={faBatteryFull} className="text-success" />) :
            sb.batteryLevel > 50 ? (<FontAwesomeIcon icon={faBatteryThreeQuarters} className="text-success" />) :
            sb.batteryLevel > 25 ? (<FontAwesomeIcon icon={faBatteryHalf} className="text-warning" />) :
            sb.batteryLevel > 5 ? (<FontAwesomeIcon icon={faBatteryQuarter} className="text-danger" />) :
            (<FontAwesomeIcon icon={faBatteryEmpty} className="text-danger" />) }
            <span style={{marginLeft: 5}}>- {sb.batteryLevel}% Battery</span>
          </div>
          <div className="col-4">
            {sb.wifiLevel >= 4 ? (<FontAwesomeIcon icon={faSignal} className="text-success" />) :
            sb.wifiLevel >= 2 ? (<FontAwesomeIcon icon={faSignal} className="text-warning" />) :
            sb.wifiLevel === 1 ? (<FontAwesomeIcon icon={faSignal} className="text-danger" />) :
            (<FontAwesomeIcon icon={faMinusCircle} className="text-disabled"  />) }
            <span style={{marginLeft: 5}}>- {sb.wifiLevel} WiFi {sb.currentSSID !== "" && (<span>SSID: {sb.currentSSID}</span>)}</span>
          </div>
          <div className="col-4">
            {sb.cellLevel >= 4 ? (<FontAwesomeIcon icon={faSignal} className="text-success" />) :
            sb.cellLevel >= 2 ? (<FontAwesomeIcon icon={faSignal} className="text-warning" />) :
            sb.cellLevel === 1 ? (<FontAwesomeIcon icon={faSignal} className="text-danger" />) :
            (<FontAwesomeIcon icon={faMinusCircle} className="text-disabled" />) }
            <span style={{marginLeft: 5}}>- {sb.cellLevel} Cell </span>
          </div>
        </div>
        <div className="row">
          <div className="col-3 offset-1">
            {sb.bluetooth === "on" ? (<FontAwesomeIcon icon={faBluetooth} className="text-success" />) : <FontAwesomeIcon icon={faBluetooth} />}
            <span style={{marginLeft: 5}}>Bluetooth is {sb.bluetooth}</span>
          </div>
          <div className="col-4">
            {sb.locationAccuracy >= 4 ? (<FontAwesomeIcon icon={faGlobe} className="text-success" />) :
            sb.locationAccuracy >= 2 ? (<FontAwesomeIcon icon={faGlobe} className="text-warning" />) :
            sb.locationAccuracy === 1 ? (<FontAwesomeIcon icon={faGlobe} className="text-danger" />) :
            (<FontAwesomeIcon icon={faMinusCircle} className="text-disabled" />) }
            <span style={{marginLeft: 5}}>- {sb.locationAccuracy} Location Accuracy</span>
          </div>
          <div className="col-4">
            {this.state.geofencesActive === "on" ? (<FontAwesomeIcon icon={faMap} className="text-success" />) : <FontAwesomeIcon icon={faMap} className="text-disabled" />}
            <span style={{marginLeft: 5}}>Geofences are {this.state.geofencesActive}</span>
          </div>
        </div>
        <div className="row" style={{ marginTop: 10 }}>
          <div className="col-11 offset-1">
            {this.state.liveTrackingEnabled ? 
              (<FontAwesomeIcon icon={faSearchLocation} className="text-success blink" />) : 
              (<FontAwesomeIcon icon={faSearchLocation} className="text-gray"/>)}
            <span style={{marginLeft: 5}}>{this.state.liveTrackingEnabledUntil}</span>
            
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default connect<{}, {}, any>(
  mapStateToProps,
  mapDispatchToProps
)(InfoTab);
