import moment from "moment";
import { makeCall } from "./client";

export class BillingAPI {

  /**
   * Get the billing information for a user
   * @param app 
   * @param userId 
   * @param options 
   */
  public getBillingInformation = (app: string, userId: number, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('GET', `admin/${app}/users/${userId}/billing`, data);
  }

  /**
   * Get the billing device info for a device
   * @param app 
   * @param userId 
   * @param deviceId 
   * @param options 
   */
  public getBillingDevice = (app: string, userId: number, deviceId: number, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('GET', `admin/${app}/users/${userId}/billing/${deviceId}`, data);
  }

  /**
   * Updates the billing device info for a device
   * @param app 
   * @param userId 
   * @param deviceId 
   * @param options 
   */
  public updateBillingDevice = (app: string, userId: number, deviceId: number, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('PATCH', `admin/${app}/users/${userId}/billing/${deviceId}`, data);
  }

  /**
   * Get the billing device info for a device
   * @param app 
   * @param userId 
   * @param deviceId 
   * @param options 
   */
  public getUserBillingDevices = (app: string, userId: number, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('GET', `admin/${app}/users/${userId}/billingDevices`, data);
  }

  /**
   * Get all plans by productFamily
   * @param productFamily 
   * @param options 
   */
  public getPlansForFamily = (productFamily: string, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('GET', `admin/plans/family/${productFamily}`, data);
  }

  public getPlans = (options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('GET', `admin/plans`, data);
  }

  /**
   * Get the plan info by plan ID
   * @param planId 
   * @param options 
   */
  public getPlanByID = (planId: number, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('GET', `admin/plans/${planId}`, data);
  }

  /**
   * Update the plan info by plan ID
   * @param planId 
   * @param options 
   */
  public updatePlanByID = (planId: number, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('PATCH', `admin/plans/${planId}`, data);
  }

  /**
   * Swaps two device subscriptions
   * @param app 
   * @param userId 
   * @param deviceId 
   * @param additionalDeviceId
   * @param options 
   */
  public swapDeviceSubscriptions = (app: string, userId: number, deviceId: number, additionalDeviceId: number, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('PATCH', `admin/${app}/users/${userId}/devices/${deviceId}/exchange/${additionalDeviceId}`, data);
  }

  /**
   * Saves the billing information for a user
   * @param app 
   * @param userId 
   * @param token A Stripe tok_ token
   * @param options 
   */
  public saveBillingInformation = (app: string, userId: number, token: string, options: any = {}) => {
    const data = {
      ...options,
      token,
    };
    return makeCall('PUT', `admin/${app}/users/${userId}/billing`, data);
  }

  //
  // Free Users
  //


  /**
   * Gets the free users on the platform
   * @param app 
   * @param options 
   */
  public getFreeUsers = (app: string, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('GET', `admin/${app}/users/freeUsers`, data);
  }

  /**
   * Saves the free user email
   * @param app 
   * @param email 
   * @param options 
   */
  public saveFreeUser = (app: string, email: string, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('PUT', `admin/${app}/users/freeUsers/${email}`, data);
  }

  /**
   * Removes an email from the free users table
   * @param app 
   * @param email 
   * @param options 
   */
  public deleteFreeUser = (app: string, email: string, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('DELETE', `admin/${app}/users/freeUsers/${email}`, data);
  }

  public flushBillingDeviceCache = (app: string, userId: number, deviceId: number, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('DELETE', `/admin/${app}/users/${userId}/devices/${deviceId}/cache`, data);
  }

  //
  // Coupons
  //


  /**
   * Gets the free users on the platform
   * @param app 
   * @param options 
   */
  public getAllCoupons = (app: string, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('GET', `admin/${app}/coupons`, data);
  }

  /**
   * Get the coupon for a user ID
   * @param app 
   * @param userId 
   * @param options 
   */
  public getCouponsForUserId = (app: string, userId: number, options: any = {}) => {
    const data = {
      ...options
    };
    return makeCall('GET', `/admin/${app}/users/${userId}/coupons`, data);
  }

  /**
   * Get the coupon for a user ID
   * @param app 
   * @param userId 
   * @param options 
   */
  public getCouponsForDeviceId = (app: string, userId: number, deviceId: number, options: any = {}) => {
    const data = {
      ...options
    };
    return makeCall('GET', `/admin/${app}/users/${userId}/devices/${deviceId}/coupons`, data);
  }

  /**
   * Creates coupons
   * @param app 
   * @param options 
   */
  public createCoupon = (app: string, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('POST', `admin/${app}/coupons`, data);
  }

  /**
   * Generates coupons
   * @param app 
   * @param options 
   */
  public generateCoupons = (app: string, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('POST', `admin/${app}/coupons/generate`, data);
  }

  /**
   * Updates a specified Coupon
   * @param app 
   * @param couponCode 
   * @param options 
   */
  public updateCoupon = (app: string, couponCode: string, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('PATCH', `admin/${app}/coupons/${couponCode}`, data);
  }

  /**
   * Links a plan to coupon
   * @param app 
   * @param options 
   */
  public linkCouponToPlan = (app: string, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('POST', `admin/${app}/coupons/link`, data);
  }

  /**
   * Deletes a specified Coupon
   * @param app 
   * @param couponCode 
   * @param options 
   */
  public deleteCoupon = (app: string, couponCode: string, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('DELETE', `admin/${app}/coupons/${couponCode}`, data);
  }

  /**
   * Saves ProductID/Plan(s) combination
   * @param options 
   */
  public saveProductIDPlans = (options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('POST', `admin/backend/plans/product`, data);
  }

  /**
   * Get the billing information for a user
   * @param app 
   * @param userId 
   * @param options 
   */
  public getUserStripeLogs = (app: string, userId: number, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('GET', `admin/${app}/users/${userId}/logs/stripe`, data);
  }

  //
  // RMA
  //

  // 
  // RMA Codes
  //

  public getRMACodes = (app: string, data: any = {}, options: any = {}) => {
    const sendData = {
      ...data,
    };
    return makeCall('GET', `admin/rmas/codes`, sendData, options);
  }

  public createRMACode = (app: string, data: any = {}, options: any = {}) => {
    const sendData = {
      ...data,
    };
    return makeCall('POST', `admin/rmas/codes`, sendData, options);
  }

  public updateRMACode = (app: string, codeId: number, data: any = {}, options: any = {}) => {
    const sendData = {
      ...data,
    };
    return makeCall('PATCH', `admin/rmas/codes/${codeId}`, sendData, options);
  }

  public deleteRMACode = (app: string, codeId: number, data: any = {}, options: any = {}) => {
    const sendData = {
      ...data,
    };
    return makeCall('DELETE', `admin/rmas/codes/${codeId}`, sendData, options);
  }

  public getProductCodes = (app: string, data: any = {}, options: any = {}) => {
    const sendData = {
      ...data,
    };
    return makeCall('GET', `admin/product/codes`, sendData, options);
  }

  // 
  // RMA Categories
  //

  public getRMACategories = (app: string, data: any = {}, options: any = {}) => {
    const sendData = {
      ...data,
    };
    return makeCall('GET', `admin/rmas/categories`, sendData, options);
  }

  public createRMACategory = (app: string, data: any = {}, options: any = {}) => {
    const sendData = {
      ...data,
    };
    return makeCall('POST', `admin/rmas/categories`, sendData, options);
  }

  public updateRMACategory = (app: string, codeId: number, data: any = {}, options: any = {}) => {
    const sendData = {
      ...data,
    };
    return makeCall('PATCH', `admin/rmas/categories/${codeId}`, sendData, options);
  }

  public deleteRMACategory = (app: string, codeId: number, data: any = {}, options: any = {}) => {
    const sendData = {
      ...data,
    };
    return makeCall('DELETE', `admin/rmas/categories/${codeId}`, sendData, options);
  }

  // 
  // Actual RMAs
  //

  public getRMAs = (app: string, data: any = {}, options: any = {}) => {
    const sendData = {
      ...data,
    };
    return makeCall('GET', `admin/rmas`, sendData, options);
  }

  public createRMA = (app: string, data: any = {}, options: any = {}) => {
    const sendData = {
      ...data,
    };
    return makeCall('POST', `admin/rmas`, sendData, options);
  }

  public getRMA = (app: string, rmaId: number, data: any = {}, options: any = {}) => {
    const sendData = {
      ...data,
    };
    return makeCall('GET', `admin/rmas/${rmaId}`, sendData, options);
  }

  public updateRMA = (app: string, rmaId: number, data: any = {}, options: any = {}) => {
    const sendData = {
      ...data,
    };
    return makeCall('PATCH', `admin/rmas/${rmaId}`, sendData, options);
  }

  public deleteRMA = (app: string, rmaId: number, data: any = {}, options: any = {}) => {
    const sendData = {
      ...data,
    };
    return makeCall('DELETE', `admin/rmas/${rmaId}`, sendData, options);
  }

  public getRMAItems = (app: string, rmaId: number, data: any = {}, options: any = {}) => {
    const sendData = {
      ...data,
    };
    return makeCall('GET', `admin/rmas/${rmaId}/items`, sendData, options);
  }

  public createRMAItem = (app: string, rmaId: number, data: any = {}, options: any = {}) => {
    const sendData = {
      ...data,
    };
    return makeCall('POST', `admin/rmas/${rmaId}/items`, sendData, options);
  }
  
  public updateRMAItem = (app: string, rmaId: number, itemId: number, data: any = {}, options: any = {}) => {
    const sendData = {
      ...data,
    };
    return makeCall('PATCH', `admin/rmas/${rmaId}/items/${itemId}`, sendData, options);
  }

  public deleteRMAItem = (app: string, rmaId: number, itemId: number, data: any = {}, options: any = {}) => {
    const sendData = {
      ...data,
    };
    return makeCall('DELETE', `admin/rmas/${rmaId}/items/${itemId}`, sendData, options);
  }

  public calculateRefund = (app: string, data: any = {}, options: any = {}) => {
    const sendData = {
      ...data,
    };
    return makeCall('POST', `admin/refunds/calculator`, sendData, options);
  }

  // processRMAForAPI is a helper because Javascript and react are dumb with form controls
  public processRMAItemForAPI(item: IRMAItem): IRMAItem {
    const ret: IRMAItem = {
      ...item,
      rmaId: parseInt(item.rmaId + "", 10),
      deviceId: parseInt(item.deviceId + "", 10),
      quantity: parseInt(item.quantity + "", 10),
      returnCodeId: parseInt(item.returnCodeId + "", 10),
      primaryCategoryId: parseInt(item.primaryCategoryId + "", 10),
      secondaryCategoryId: parseInt(item.secondaryCategoryId + "", 10),
      tertiaryCategoryId: parseInt(item.tertiaryCategoryId + "", 10),
      receivedOn: moment(item.receivedOn).format("YYYY-MM-DD"),
      quantityReceived: parseInt(item.quantityReceived + "", 10),
      productRefundAmountInCents: parseFloat(item.productRefundAmountInCents + "") * 100,
      subscriptionRefundAmountInCents: parseFloat(item.subscriptionRefundAmountInCents + "") * 100,
    };
    ret.quantitiesMatched = ret.quantity === ret.quantityReceived ? "yes" : "no";
    return ret;
  }

}

export interface ICoupon {
  couponCode: string;
  couponName: string;
  couponUseType: string;
  couponType: string;
  couponDuration: string;
  couponValue: number;
  eligiblePlans: any;
};

export interface IRMA {
  id: number;
  createdOn: string;
  createdByAdminUserId: number;
  handledByAdminUserId: number;
  customerId: number;
  recipientId: number;
  customerFirstName: string;
  customerLastName: string;
  recipientFirstName: string;
  recipientLastName: string;
  rmaNumber: string;
  details: string;
  notes: string;
  rmaIssueDate: moment.Moment;
  rmaIssued? : "yes" | "no";
  status: "open" | "closed" | "partial";
  countOfItems: number;
};

export const BlankRMA: IRMA = {
  id: 0,
  createdOn: "2022-10-27T11:53:33Z",
  createdByAdminUserId: 0,
  handledByAdminUserId: 0,
  customerId: 0,
  recipientId: 0,
  customerFirstName: "",
  customerLastName: "",
  recipientFirstName: "",
  recipientLastName: "",
  rmaNumber: "",
  details: "",
  notes: "",
  rmaIssued: "no",
  rmaIssueDate: moment(),
  status: "open",
  countOfItems: 0
};



export interface IRMAItem {
  id: number;
  rmaId: number,
  deviceId: number;
  productId: string;
  productFamilyId?: string;
  productFamilyDisplay?: string;
  placeOfPurchase: "unknown" | "direct" | "internal" | "third_party";
  placeOfPurchaseSpecify: string;
  orderNumber: string;
  rmaType: string;
  returnTo: "portsmouth" | "warehouse" | "other";
  labelProvided: "yes" | "no";
  productNumber: number;
  productDescription: string;
  quantity: number;
  returnCodeId: number;
  returnCode: string;
  returnCodeDisplay: string;
  primaryCategoryId: number;
  primaryCategoryCode: string;
  primaryCategoryDisplay: string;
  secondaryCategoryId: number;
  secondaryCategoryCode: string;
  secondaryCategoryDisplay: string;
  tertiaryCategoryId: number;
  tertiaryCategoryCode: string;
  tertiaryCategoryDisplay: string;

  // refund
  productRefundDue: "yes_full" | "yes_partial" | "no" | "na";
  productRefunded: "yes" | "no" | "na";
  productRefundId: string;
  productRefundAmountInCents: number;
  subscriptionRefundDue: "yes_full" | "yes_partial" | "no" | "na";
  subscriptionRefunded: "yes" | "no" | "na";
  subscriptionRefundId: string;
  subscriptionRefundAmountInCents: number;
  shouldDeactivateInAdminPanel: "yes" | "no" | "na";
  didDeactivateInAdminPanel: "yes" | "no" | "na";
  deviceStatus: string;
  billingStatus: string;
  plan?: any | null;

  // reception
  received: string;
  receivedOn: moment.Moment | string;
  receivedByName: string;
  quantityReceived: number;
  quantitiesMatched: string;
  notes: string;
  disposition: string;
};

export const BlankRMAItem: IRMAItem = {
  id: 0,
  rmaId: 0,
  productId: "",
  deviceId: 0,
  placeOfPurchase: "internal",
  placeOfPurchaseSpecify: "",
  orderNumber: "",
  rmaType: "other",
  returnTo: "portsmouth",
  labelProvided: "no",
  productNumber: 0,
  productDescription: "",
  quantity: 0,
  returnCodeId: 0,
  returnCode: "",
  returnCodeDisplay: "",
  primaryCategoryId: 0,
  primaryCategoryCode: "",
  primaryCategoryDisplay: "",
  secondaryCategoryId: 0,
  secondaryCategoryCode: "",
  secondaryCategoryDisplay: "",
  tertiaryCategoryId: 0,
  tertiaryCategoryCode: "",
  tertiaryCategoryDisplay: "",

  productRefundDue: "na",
  productRefunded: "na",
  productRefundId: "",
  subscriptionRefundDue: "na",
  subscriptionRefunded: "na",
  subscriptionRefundId: "",
  shouldDeactivateInAdminPanel: "na",
  didDeactivateInAdminPanel: "na",
  subscriptionRefundAmountInCents: 0,
  productRefundAmountInCents: 0,
  deviceStatus: "unknown",
  billingStatus: "unknown",

  received: "",
  receivedOn: moment(),
  receivedByName: "",
  quantityReceived: 0,
  quantitiesMatched: "",
  notes: "",
  disposition: "",
};
