import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { MDBDataTable } from "mdbreact";
import CsvDownloader from "react-csv-downloader";
import Card from "./Card";

import * as UserActions from "../../reducers/userReducer";
import { styles } from "src/styles"

interface ITableState {
  hasPermission: boolean;
}

interface ITableProps {
  showDownload: boolean;
  title: string;
  csvFileName: string;
  csvData?: any;
  columns: any;
  rows: any;
  loading: boolean;
  userState: any;
  style?: any;
  preElement?: any;
  postElement?: any;
}

class Table extends React.Component<ITableProps, ITableState> {
  constructor(props: any) {
    super(props);
    this.state = {
      hasPermission: false
    };

    this.checkPermissions = this.checkPermissions.bind(this);
  }

  get title() {
    if (this.props.title === "") {
      return <span />;
    }
    return this.props.title;
  }

  get csvFileName() {
    if (this.props.csvFileName === "") {
      return "adminAppCSV";
    }
    return this.props.csvFileName;
  }

  get showDownload() {
    if (this.props.showDownload) {
      return true;
    }
    return false;
  }

  componentDidMount() {
    this.checkPermissions();
  }

  checkPermissions() {
    if (!this.showDownload) {
      return
    }
    const roles = this.props.userState.user.roles
    if (roles.includes("administrator") || roles.includes("reporting")) {
      this.setState({ hasPermission: true })
    }
  }

  processData(columns: any, rows: any) {
    const data = {
      columns,
      rows,
    };
    return data;
  }

  public render() {
    return (
      <section style={this.props.style !== null ? this.props.style : {}}>
        <Card title={this.title} loading={this.props.loading}>
          {this.props.preElement && (this.props.preElement)}
          <div>
            <MDBDataTable
              style={styles.tables}
              striped
              bordered
              small
              data={this.processData(this.props.columns, this.props.rows)}
              noBottomColumns={true}
              responsive={true}
            />
          </div>
          { this.state.hasPermission && (
          <div style={{ width: "50%" }}>
            <CsvDownloader
              datas={this.props.csvData ? this.props.csvData : this.props.rows}
              filename={this.csvFileName}
            >
              <button
              style={{ color: "white" }}
              className="btn btn-send btn-block"
              >
                Download to CSV
              </button>
            </CsvDownloader>
          </div>)}

          {this.props.postElement && (this.props.postElement)}
        </Card>
      </section>
    );
  }
}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(UserActions, dispatch),
  };
}

export default connect<{}, {}, any>(mapStateToProps, mapDispatchToProps)(Table);
