import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Screen, Table } from "src/components/structure";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faRunning } from '@fortawesome/free-solid-svg-icons';

import * as UserActions from "src/reducers/userReducer";
import { AnalyticsAPI, JobsAPI } from "src/api";
import Card from "src/components/structure/Card";
import QueryEngineQueryWizard from "./QueryEngine/QueryEngineQueryWizard";
import moment from "moment";
import { capitalizeFirstLetter } from "src/utils/utilities";
import { error } from "src/components/structure/Alert";
import QueryEngineResultsViewer from "./QueryEngine/QueryEngineResultsViewer";

interface IQueryEngineScreenProps {
  userState: any;
}

interface IQueryEngineScreenState {
  loading: boolean;
  myQueries: any[];
  sharedQueries: any[];
  selectedQueryGroup: "mine" | "shared";
  selectedQuery: any;
  selectedQueryId: number;

  showWizardModal: boolean;
  showResultsModal: boolean;
  showRunModal: boolean;
  showJobModal: boolean;
  runMethod: "now" | "job";
  runJobURL: string;
}


class QueryEngineScreen extends Component<
  IQueryEngineScreenProps,
  IQueryEngineScreenState
> {
  constructor(props: IQueryEngineScreenProps) {
    super(props);
    this.state = {
      loading: true,
      myQueries: [],
      sharedQueries: [],
      selectedQueryGroup: "mine",

      showWizardModal: false,
      showResultsModal: false,
      showRunModal: false,
      showJobModal: false,
      selectedQueryId: 0,
      selectedQuery: {},
      runMethod: "now",
      runJobURL: ""
    };

    this.updateField = this.updateField.bind(this);
    this.fetchQueries = this.fetchQueries.bind(this);
    this.toggleShowWizardModal = this.toggleShowWizardModal.bind(this);
    this.toggleShowResultsModal = this.toggleShowResultsModal.bind(this);
    this.clearSelectedQueryToCreate = this.clearSelectedQueryToCreate.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.editQuery = this.editQuery.bind(this);
    this.runQuery = this.runQuery.bind(this);
    this.openRunModal = this.openRunModal.bind(this);
    this.closeRunModal = this.closeRunModal.bind(this);
    this.runQueryAsJob = this.runQueryAsJob.bind(this);
    this.toggleShowJobModal = this.toggleShowJobModal.bind(this);
  }

  componentDidMount() {
    this.fetchQueries();
  }

  render() {
    return (
      <Screen shouldAuthCheck={true} requiredRoles={["reporting", "product"]}>
        <Card title="Saved Query Engine Queries" style={{ marginBottom: 20 }}>
          <div className="row">
            <div className="col-12">
              Below you will find your saved queries. For the first version, you will only see your queries and they cannot be scheduled. Sharing and scheduling will come in a future iteration.
            </div>
          </div>
          <div className="row">
            <div className="col-4">
              <button className="btn btn-block btn-primary" onClick={this.clearSelectedQueryToCreate}>Create New Query</button>
            </div>
          </div>
        </Card>

        <Table
          title="Queries"
          showDownload={false}
          columns={queryCols}
          rows={this.state.selectedQueryGroup === "mine" ? this.state.myQueries : this.state.sharedQueries}
        />

        <Modal
          show={this.state.showWizardModal}
          onHide={this.toggleShowWizardModal}
          dialogClassName="modal-x-large"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Wizard Query
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <QueryEngineQueryWizard startingQuery={this.state.selectedQuery} onComplete={this.handleSave} onCancel={this.toggleShowWizardModal} onRun={this.openRunModal} />
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.showResultsModal}
          onHide={this.toggleShowResultsModal}
          dialogClassName="modal-x-large"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Query Results
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <QueryEngineResultsViewer query={this.state.selectedQuery} />
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.showRunModal}
          onHide={this.closeRunModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Run Method
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <label>Please choose how you would like to run the query</label>
            <select className="form-control" value={this.state.runMethod} id="runMethod" onChange={this.updateField}>
              <option value="now">Run Now (Best for Small Queries)</option>
              <option value="job">Run as a Job</option>
            </select>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-block btn-primary" onClick={this.state.runMethod === "now" ? this.runQuery : this.runQueryAsJob}>Run Query</button>
          </Modal.Footer>
        </Modal>



        <Modal
          show={this.state.showJobModal}
          onHide={this.toggleShowJobModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Job Queued
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>The job has been queued successfully as a CSV and can be monitored at:</p>

            <strong><a href={this.state.runJobURL} target="_new">{this.state.runJobURL}</a></strong>

            <p>An email should also be sent to you when the job completes.</p>
          </Modal.Body>
        </Modal>
      </Screen>
    );
  }

  private updateField(e: any) {
    const ns = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private fetchQueries() {
    this.setState({ loading: true }, async () => {
      try {
        const userId = this.props.userState.user.id;
        // TODO: get the list of admin users on the platform to see who shared what?
        const myQueriesResult = await AnalyticsAPI.getSavedQueries(userId);
        const myQueries = this.processQueries(myQueriesResult.body.data);
        const sharedQueriesResult = await AnalyticsAPI.getSharedSavedQueries(userId);
        const sharedQueries = this.processQueries(sharedQueriesResult.body.data);


        this.setState({ sharedQueries, myQueries, loading: false })
      } catch (err) {
        console.log(err);
      }
    })
  }

  private processQueries(queries: any[]): any[] {
    const ret: any[] = [];
    for (const q of queries) {
      q.createdOn = moment(q.createdOn).format("YYYY-MM-DD HH:mm");
      q.lastRunOn = moment(q.lastRunOn).format("YYYY-MM-DD HH:mm");
      q.lastRunStatusDisplay = capitalizeFirstLetter(q.lastRunStatus);
      q.entityDisplay = capitalizeFirstLetter(q.entity);
      q.actions = (
        <div className="row">
          <div className="col-6" style={{ textAlign: "center" }}>
            <span className="text-primary" onClick={() => { this.editQuery(q) }}><FontAwesomeIcon icon={faEdit} /></span>
          </div>
          <div className="col-6" style={{ textAlign: "center" }}>
            <span className="text-success" onClick={() => { this.openRunModal(q) }}><FontAwesomeIcon icon={faRunning} /></span>
          </div>
        </div>
      );
      ret.push(q);
    }
    return ret;
  }

  private toggleShowWizardModal() {
    this.setState({ showWizardModal: !this.state.showWizardModal });
  }

  private toggleShowResultsModal() {
    this.setState({ showResultsModal: !this.state.showResultsModal });
  }

  private toggleShowJobModal() {
    this.setState({ showJobModal: !this.state.showJobModal });
  }

  private closeRunModal() {
    this.setState({ showRunModal: false });
  }

  private openRunModal(query: any) {
    this.setState({ selectedQuery: query, showRunModal: true });
  }

  private handleSave(data: any) {
    const dataToSend = {
      name: data.name,
      query: data,
      entity: data.entity,
    };
    if (dataToSend.name === "") {
      return error("Name is required");
    }
    this.setState({ loading: true }, async () => {
      try {
        const userId = this.props.userState.user.id;
        if (this.state.selectedQuery && this.state.selectedQuery.id) {
          await AnalyticsAPI.updateSavedQuery(userId, this.state.selectedQuery.id, dataToSend);
        } else {
          await AnalyticsAPI.createSavedQuery(userId, dataToSend);
        }

        this.setState({ loading: true, selectedQuery: {}, showWizardModal: false }, () => this.fetchQueries())
      } catch (err) {
        console.log(err);
      }
    });
  }

  private editQuery(query: any) {
    this.setState({ selectedQuery: query, showWizardModal: true })
  }

  private clearSelectedQueryToCreate() {
    this.setState({ selectedQuery: {}, showWizardModal: true });
  }

  private runQuery(query: any) {
    if (!query || !query.entity) {
      query = this.state.selectedQuery;
    }
    this.setState({ loading: true }, async () => {
      try {
        const userId = this.props.userState.user.id;
        if (query.id) {
          // run the saved version
          const result = await AnalyticsAPI.runSavedQuery(userId, query.id);
          query.query.output = result.body.data;
        } else {
          // run this query raw
          const result = await AnalyticsAPI.runQueryWithoutSaving(userId, query);
          // the query that comes in hasn't been saved, so we need to wrap it in a basic wrapper
          query = {
            name: query.name,
            entity: query.entity,
            query: {
              ...query,
              output: result.body.data,
            }
          }
        }
        this.setState({ loading: false, selectedQuery: query, showResultsModal: true })
      } catch (err) {
        error("We could not run that query. If this message took awhile to appear, you likely attempted to retrieve too much data. Save this query and run it as a job.");
      }
    })
  }

  private runQueryAsJob(query: any) {
    if (!query || !query.entity) {
      query = this.state.selectedQuery;
    }

    this.setState({ loading: true }, async () => {
      const parameters: any = {

      };
      if (query.id) {
        parameters["id"] = query.id;
      } else {
        parameters["query"] = query;
      }
      try {
        const data: any = {
          targetService: "analytics-service",
          name: query.id ? "run_saved_query" : "run_raw_query",
          notificationType: "email",
          notificationTarget: this.props.userState.user.email,
          outputType: "csv",
          parameters,
        };
        const result = await JobsAPI.createJob("wagz2", data);
        const job = result.body.data;
        const url = `/reports/jobs/${job.id}`
        this.setState({ loading: false, runJobURL: url, showRunModal: false, showJobModal: true})

      } catch (err) {
        error("Could not run as a job");
      }
    });
  }
}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(QueryEngineScreen) as any
);


const queryCols: any[] = [
  {
    label: "Name",
    field: "name"
  },
  {
    label: "Entity",
    field: "entityDisplay"
  },
  {
    label: "Created",
    field: "createdOn"
  },
  {
    label: "Last Run",
    field: "lastRunOn"
  },
  {
    label: "Status",
    field: "lastRunStatusDisplay"
  },
  {
    label: "Actions",
    field: "actions"
  },
];