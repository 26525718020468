import * as React from "react";
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, Tooltip, Legend } from "recharts";

export interface IBarGraphDataPoint {
  count: number;
  key: string;
}

interface IBarGraphProps {
  data: IBarGraphDataPoint[];
  hideLegend?: boolean;
  loading: boolean;
}

interface IBarGraphState {
  total: number;
}

export class BarGraph extends React.Component<IBarGraphProps, IBarGraphState> {

  constructor(props: IBarGraphProps){
    super(props);

    this.state = {
      total: 0,
    };
  }

  componentDidMount(){
    let total = 0;
    for(const d of this.props.data){
      total += d.count;
    }
    this.setState({ total });
  }

  public render() {
    if(this.props.loading){
      return (
        <div style={{textAlign: "center"}}>
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )
    }
    return (
      <div>
        <div className="row">
          <div className="col-12">
            <ResponsiveContainer width="100%" height="100%" minHeight={300}>
              <BarChart data={this.props.data} margin={{ top: 10, right: 10, left: 0, bottom: 0 }}>
              <Bar dataKey="count" fill="#8884d8" />
                <YAxis />
                <XAxis dataKey="key" />
                <Tooltip />
                {!this.props.hideLegend && (<Legend />)}
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>

        <div className="row">
          <div className="col-3 offset-9">
            <strong>Total: </strong>{this.state.total}
          </div>
        </div>
      </div>
    );
  }

}