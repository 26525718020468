import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Screen } from "src/components/structure";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import * as UserActions from "src/reducers/userReducer";
import { AnalyticEventsScreen } from "./Analytics/AnalyticEvents";
import { AnalyticDevicesScreen } from "./Analytics/AnalyticDevices";
import { AnalyticLocationsScreen } from "./Analytics/AnalyticLocations";
import { AnalyticGithubEventsScreen } from "./Analytics/AnalyticGithubEvents";
import { AnalyticCIEventsScreen } from "./Analytics/AnalyticCIEvents";

interface IAnalyticsScreenProps {
  userState: any;
}

interface IAnalyticsScreenState {
  isEngineerReporter: boolean;
}


class AnalyticsScreen extends Component<
  IAnalyticsScreenProps,
  IAnalyticsScreenState
> {
  constructor(props: IAnalyticsScreenProps) {
    super(props);
    this.state = {
      isEngineerReporter: false,
    };

  }

  componentDidMount(){
    let isEngineerReporter = false
    for(const r of this.props.userState.user.roles){
      if(r === "administrator" || r === "reporting"){
        isEngineerReporter = true;
      }
    }
    this.setState( { isEngineerReporter })
  }

  render() {
    return (
      <Screen shouldAuthCheck={true} requiredRoles={["reporting"]}>
        <Tabs>
          <TabList>
            <Tab>Events</Tab>
            <Tab>Devices</Tab>
            <Tab>Locations</Tab>
            {this.state.isEngineerReporter ? <Tab>Github</Tab> : (null)}
            {this.state.isEngineerReporter ? <Tab>CI</Tab> : (null)}
          </TabList>
          <TabPanel>
            <AnalyticEventsScreen target="all" targetId={0} />
          </TabPanel>
          <TabPanel>
            <AnalyticDevicesScreen target="all" targetId={0} />
          </TabPanel>
          <TabPanel>
            <AnalyticLocationsScreen target="all" targetId={0} />
          </TabPanel>
          {this.state.isEngineerReporter && (<TabPanel>
            <AnalyticGithubEventsScreen />
          </TabPanel>)}
          {this.state.isEngineerReporter && (<TabPanel>
            <AnalyticCIEventsScreen />
          </TabPanel>)}
        </Tabs>
      </Screen>
    );
  }

}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AnalyticsScreen) as any
);
