import { makeCall } from "./client";

export class AppsAPI {
  
  /**
   * Get the current App Version for an OS
   * @param app 
   * @param os 
   * @param options 
   */
  public getAppVersionForOS = (app: string, os: string, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('GET', `admin/${app}/mobile/apps/${os}`, data);
  }
  
  /**
   * Saves a new App Version for an OS
   * @param app 
   * @param os 
   * @param options 
   */
  public saveAppVersionForOS = (app: string, os: string, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('POST', `admin/${app}/mobile/apps/${os}`, data);
  }
  
  /**
   * Updates a specified App Version
   * @param app 
   * @param appVersionID 
   * @param options 
   */
  public updateAppVersion = (app: string, appVersionID: number, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('PATCH', `admin/${app}/mobile/apps/${appVersionID}`, data);
  }
  
  /**
   * Deletes a specified App Version
   * @param app 
   * @param appVersionID 
   * @param options 
   */
  public deleteAppVersion = (app: string, appVersionID: number, options: any = {}) => {
    const data = {
      ...options,
    };
    return makeCall('DELETE', `admin/${app}/mobile/apps/${appVersionID}`, data);
  }
}