import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Screen from "src/components/structure/Screen";
import * as AppActions from "src/reducers/appReducer";
import { error, success } from "src/components/structure/Alert";
import { BillingAPI, PetsAPI } from "src/api";

interface ICacheManagementScreenProps {
  userId: number;
  otherId: number;
  cacheType: string;
}

interface ICacheManagementScreenState {
  loading: boolean;
  title: string;
}

class CacheManagementScreen extends Component<
  ICacheManagementScreenProps,
  ICacheManagementScreenState
> {
  constructor(props: ICacheManagementScreenProps) {
    super(props);
    this.state = {
      loading: false,
      title: this.setTitle(this.props.cacheType),
    };

    this.setTitle = this.setTitle.bind(this);
    this.clearCache = this.clearCache.bind(this);
  }

  public render() {
    return (
      <Screen fileName="CacheManagement.tsx" requiredRoles={["backend"]}>
        <div>
          <button
            style={{ color: "white" }}
            className="btn btn-send btn-block"
            onClick={this.clearCache}
          >
            {this.state.title}
          </button>
          </div>
      </Screen>
    );
  }

  private setTitle(input: string): string {
    switch (input) {
      case "user_pets":
        return "Flush All Pet Caches for User";
      case "pet":
        return "Flush Cache for Pet";
      case "billing_device":
        return "Flush Billing Device Cache";
      default:
        return input;
    }
  }

  private clearCache() {
    this.setState({ loading: true }, async () => {
      try {
        if (this.props.cacheType === "user_pets") {
          await PetsAPI.flushUserPetCache(
            "wagz2",
            this.props.userId
          );
        } else if (this.props.cacheType === "pet") {
          await PetsAPI.flushPetCache(
            "wagz2",
            this.props.userId,
            this.props.otherId
          );
        } else if (this.props.cacheType === "billing_device") { 
          await BillingAPI.flushBillingDeviceCache(
            "wagz2",
            this.props.userId,
            this.props.otherId
          );
        }
        this.setState({ loading: false });
        success("Cache cleared successfully!");
      } catch (err) {
        error("Could not clear that cache.");
        this.setState({ loading: false });
      }
    });
  }
}

const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CacheManagementScreen);
