import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Screen } from "src/components/structure";
import MobileDevicesTab from "../Support/UserTabs/MobileDevicesTab";

interface IMyMobileDevicesProps {
  userState: any;
}

interface IMyMobileDevicesState {
  loading: boolean;
}

class MyDevices extends Component<
  IMyMobileDevicesProps,
  IMyMobileDevicesState
> {
  constructor(props: IMyMobileDevicesProps) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  render() {
    return (
      <Screen shouldAuthCheck={true}>
        <MobileDevicesTab userIds={this.props.userState.user.wagzUserIds} />
      </Screen>
    );
  }
}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps() {
  return {};
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MyDevices) as any
);
