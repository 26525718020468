import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as UserActions from "src/reducers/userReducer";
import { DeviceAPI, getUrl } from "src/api";
import { styles } from "src/styles";
import moment from "moment";
import { IDevice } from "src/api/devices";
import { Card } from "src/components/structure";

interface IShadowTabProps {
  device: IDevice;
  location: any;
  userActions: any;
  userState: any;
}

interface IShadowTabState {
  loading: boolean;
  currentDateTime: string;
  shadow: any;
  shadowFound: boolean;
  shadowLink: string;
  shadowLastReportedDateTime: string;
  shadowLastReportedAgo: string;
}

const dateFormat = "MM/DD/YY HH:mm:ss A"

class ShadowTab extends Component<IShadowTabProps, IShadowTabState> {
  constructor(props: IShadowTabProps) {
    super(props);

    this.state = {
      loading: true,
      currentDateTime: moment().format(dateFormat),
      shadow: "We could not access that shadow at this time.",
      shadowFound: false,
      shadowLink: "",
      shadowLastReportedDateTime: moment().format(dateFormat),
      shadowLastReportedAgo: "Unknown",
    };

    this.updateField = this.updateField.bind(this);
    this.getShadow = this.getShadow.bind(this);
  }

  updateField(e: any) {
    let ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  componentDidMount() {
    this.getShadow();
  }

  getShadow() {
    this.setState({ loading: true }, async () => {
      try {
        const res = await DeviceAPI.getDeviceShadow(
          "wagz",
          this.props.device.registeredBy,
          this.props.device.id
        );
        const shadow = res.body.data.state;
        let reportedTime = "Unknown";
        let reportedAgo = "";
        const reportedMoment = shadow.reported && shadow.reported.status && shadow.reported.status.lastUpdated ? moment(shadow.reported.status.lastUpdated) : false;
        if(reportedMoment){
          reportedTime = reportedMoment.format(dateFormat);
          reportedAgo = reportedMoment.fromNow();
        }
        this.setState({
          loading: false,
          shadow,
          shadowFound: true,
          shadowLastReportedAgo: reportedAgo,
          shadowLastReportedDateTime: reportedTime,
          shadowLink: getUrl("aws", "shadow", { "thingName": this.props.device.thingName })
        });
      } catch (err) {
        this.setState({ loading: false });
      }
    });
  }

  render() {
    return (
        <Card title="Thing Shadow" loading={this.state.loading}>
          {!this.state.shadowFound ? (
            <strong>Shadow Could Not Load!</strong>
          ) : (
              <>
                <div className="row">
                  <div className="col-6">
                    Shadow from Server As Of: <strong>{this.state.currentDateTime}</strong>
                  </div>
                  <div className="col-6">
                    Shadow From Device: <strong>{this.state.shadowLastReportedDateTime} ({this.state.shadowLastReportedAgo})</strong>
                  </div>
                </div>
  
                <div className="row">
                  <div className="col-6">
                  <div style={styles.shadowBox}>
                    <div style={{ margin: 10 }}>
                      <h4>Desired</h4>
                      <pre>{JSON.stringify(this.state.shadow.desired, null, 2)}</pre>
                    </div>
                  </div>
                  </div>
                  <div className="col-6">
                    <div style={styles.shadowBox}>
                      <div style={{ margin: 10 }}>
                        <h4>Reported</h4>
                        <pre>{JSON.stringify(this.state.shadow.reported, null, 2)}</pre>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row" style={{marginTop: 20}}>
                  <div className="col-3">
                    Thing Shadow Link (AWS Access Required):
                  </div>
                  <div className="col-9">
                    <a href={this.state.shadowLink} target="new">{this.state.shadowLink}</a>
                  </div>
                </div>
              </>
          )}
        </Card>
    );
  }
}

const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
  };
}

export default connect<{}, {}, any>(
  mapStateToProps,
  mapDispatchToProps
)(ShadowTab);
