import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as AppActions from "src/reducers/appReducer";
import { UserAPI } from "src/api"
import { Loading } from ".";
import moment from "moment";
import { styles } from "src/styles";

interface IScreenProps {
  id: string;
  showIds?: boolean;
  loading: boolean;
  appState: any;
  userState: any;
  children?: any;
  style?: any;
  shouldAuthCheck?: boolean;
  requiredRoles?: string[];
}

interface IScreenState {
  hasPermission: boolean;
  permissionCheckLoading: boolean;
  refreshLoading: boolean;
}

class Screen extends React.Component<IScreenProps, IScreenState> {
  constructor(props: any){
    super(props);
    this.state = {
      hasPermission: true,
      permissionCheckLoading: false,
      refreshLoading: false,
    };
  }

  async componentDidMount(){
    // we need to see if the token needs to be refreshed
    const refreshToken = window.localStorage.getItem("refresh_token");
    const expires = window.localStorage.getItem("expires");
    const foundUserId = window.localStorage.getItem("userId");

    if(expires && refreshToken && foundUserId){
      if(moment(expires).isAfter(moment().add(5, "minutes"))){
        this.setState({refreshLoading: true}, async () => {
          try{
            const userId = parseInt(foundUserId, 10);
            const result = await UserAPI.refreshToken(userId, refreshToken);
            window.localStorage.setItem("access_token", result.body.data.accessToken);
            window.localStorage.setItem("expires", result.body.data.expires);
            this.setState({refreshLoading: false});
          }catch(err){
            this.setState({refreshLoading: false});
          }
        })
      }
    }

    let hasPermission = false;
    // if neither hasPermission or checkIdentities are passed in, we
    // just allow it
    if(!this.props.requiredRoles || this.props.requiredRoles.length === 0){
      hasPermission = true;
    } else {
      if(this.props.requiredRoles && this.props.requiredRoles.length > 0){
        if(this.props.userState.user && this.props.userState.user.roles){
          for(const hasRole of this.props.userState.user.roles){
            // we just need to see if they are an admin OR have any of the required roles
            for(const targetRole of this.props.requiredRoles){
              if(hasRole === targetRole || hasRole === "administrator"){
                hasPermission = true;
                break;
              }
              if(hasPermission){
                break;
              }
            }
          }
        }
      }
    }    
    this.setState({hasPermission});
  }

  public render(){
    if(!this.state.hasPermission){
      return (
        <div className="permission-denied-container" style={styles.permission}>
          <p>Permission Denied</p>
        </div>
      );
    }
    if(this.state.permissionCheckLoading){
      return <Loading />;
    }
    if(this.state.refreshLoading){
      return <Loading />;
    }
    return (
        <section style={this.props.style !== null ? this.props.style: {}}>
          {this.props.showIds && (<div className="screen-show-ids-container">{this.props.id}</div>)}
          {this.props.loading && 
            <div style={{textAlign: "center"}}>
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          }
          {!this.props.loading &&  
            this.props.children
          }
        </section>
    );
  }
}

const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    userState: s.userState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(AppActions, dispatch),
  };
}

export default connect<{}, {}, any>(mapStateToProps, mapDispatchToProps)(Screen);