import React, { Component } from "react";
import { BillingAPI } from "src/api";
import { formatCurrency } from "src/utils/currency";
import { familyToDisplay } from "src/utils/utilities";
import { Loading } from ".";
import { error } from "./Alert";

interface IRefundCalculatorProps {
}

interface IRefundCalculatorState {
  loading: boolean;
  plans: any[];

  selectedPlanId: string;
  selectedDeviceId: string;
  originalCharge: string;
  monthsUsed: string;
  monthThreshold: string,
  term: string;
  monthlyValueCancellationInCents: string;
  monthlyValueReplacementInCents: string;
  taxRatePercent: string;
  partialRefundsInCents: string;

  calculated: boolean;
  preTaxCancellationRefundInCents: string;
  preTaxReplacementRefundInCents: string;
  postTaxCancellationRefundInCents: string;
  postTaxReplacementRefundInCents: string;
  errors: string[];
}

export default class RefundCalculator extends Component<IRefundCalculatorProps, IRefundCalculatorState> {
  constructor(props: IRefundCalculatorProps) {
    super(props);

    this.state = {
      loading: false,
      plans: [],

      selectedPlanId: "",
      selectedDeviceId: "",
      originalCharge: "",
      monthsUsed: "0",
      monthThreshold: "0",
      term: "",
      monthlyValueCancellationInCents: "",
      monthlyValueReplacementInCents: "",
      taxRatePercent: "",
      partialRefundsInCents: "",

      calculated: false,
      preTaxCancellationRefundInCents: "0",
      preTaxReplacementRefundInCents: "0",
      postTaxCancellationRefundInCents: "0",
      postTaxReplacementRefundInCents: "0",
      errors: []
    };

    this.updateField = this.updateField.bind(this);
    this.selectPlan = this.selectPlan.bind(this);
    this.setup = this.setup.bind(this);
    this.calculate = this.calculate.bind(this);
  }

  componentDidMount(){
    this.setup();
  }

  render() {
    if(this.state.loading){ 
      return (
        <Loading />
      );
    }
    return (
      <div>
        <div className="row row-margin-bottom">
          <div className="col-3">
            <label>Select a Plan</label>
            <select className="form-control" id="selectedPlanId" value={this.state.selectedPlanId} onChange={this.selectPlan}>
              <option value="">Enter My Own</option>
              {this.state.plans.map((p) => {
                return <option key={p.id} value={p.id}>{p.optionDisplay}</option>
              })}
            </select>
          </div>
          <div className="col-3">
            <label>Original Charge</label>
            <input type="text" className="form-control" value={this.state.originalCharge} id="originalCharge" disabled={this.state.selectedPlanId !== ""} onChange={this.updateField} />
          </div>
          <div className="col-3">
            <label>Monthly Cancellation Value</label>
            <input type="text" className="form-control" value={this.state.monthlyValueCancellationInCents} id="monthlyValueCancellationInCents" disabled={this.state.selectedPlanId !== ""} onChange={this.updateField} />
          </div>
          <div className="col-3">
            <label>Monthly Replacement Value</label>
            <input type="text" className="form-control" value={this.state.monthlyValueReplacementInCents} id="monthlyValueReplacementInCents" disabled={this.state.selectedPlanId !== ""} onChange={this.updateField} />
          </div>
        </div>

        <div className="row row-margin-bottom">
          <div className="col-3">
            <label>Device</label>
            <input type="text" className="form-control" value={this.state.selectedDeviceId} id="selectedDeviceId" onChange={this.updateField} />
            <span className="small">This will be replaced with a search</span>
          </div>
          <div className="col-2">
            <label>Months Used</label>
            <input type="text" className="form-control" value={this.state.monthsUsed} id="monthsUsed" disabled={this.state.selectedDeviceId !== ""} onChange={this.updateField} />
          </div>
          <div className="col-2">
            <label>Threshold</label>
            <input type="text" className="form-control" value={this.state.monthThreshold} id="monthThreshold" disabled={this.state.selectedDeviceId !== ""} onChange={this.updateField} />
          </div>
          <div className="col-2">
            <label>Tax Rate</label>
            <input type="text" className="form-control" value={this.state.taxRatePercent} id="taxRatePercent" disabled={this.state.selectedDeviceId !== ""} onChange={this.updateField} />
            <span className="small">For example, enter 7.5 for 7.5%</span>
          </div>
          <div className="col-3">
            <label>Partial Refunds</label>
            <input type="text" className="form-control" value={this.state.partialRefundsInCents} id="partialRefundsInCents" disabled={this.state.selectedDeviceId !== ""} onChange={this.updateField} />
          </div>
        </div>

        <div className="row row-margin-bottom">
          <div className="col-12">
            <button className="btn btn-block btn-primary" onClick={this.calculate}>↓ Calculate ↓</button>
          </div>
        </div>
        {this.state.calculated ? (
          <>
          <div className="row row-margin-bottom">
            <div className="col-3">
              <label>Pre-Tax Refund for Cancellation</label>
              <p>{this.state.preTaxCancellationRefundInCents}</p>
            </div>
            <div className="col-3">
              <label>Pre-Tax Refund for Replacement</label>
              <p>{this.state.preTaxReplacementRefundInCents}</p>
            </div>
            <div className="col-3">
              <label>Post-Tax Refund for Cancellation</label>
              <p>{this.state.postTaxCancellationRefundInCents}</p>
            </div>
            <div className="col-3">
              <label>Post-Tax Refund for Replacement</label>
              <p>{this.state.postTaxReplacementRefundInCents}</p>
            </div>
          </div>
          <div className="row row-margin-bottom">
            <div className="col-2">Errors:</div>
            <div className="col-10">
              {this.state.errors.length === 0 ? (
                <strong>None</strong>
              ): (
                <ul>
                  {this.state.errors.map((e) => {
                    return <li>{e}</li>
                  })}
                </ul>
              )}
            </div>
          </div>
          </>
        ) : (
          <>
            <div className="row row-margin-bottom">
              <div className="col-6 offset-3" style={{textAlign: "center"}}>
                <strong>No data or the data has changed and must be re-calculated.</strong>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }

  updateField(e: any) {
    let ns: any = this.state;
    ns[e.target.id] = e.target.value;
    ns.calculated = false;
    this.setState(ns);
  }

  private selectPlan(e: any){
    // changing the plan is special as it means changing a bunch of stuff
    let ns: IRefundCalculatorState = this.state;
    const planId = e.target.value;
    for(const p of this.state.plans){
      if(p.id +"" === planId + ""){
        ns.monthlyValueCancellationInCents = (p.monthlyValueCancellationInCents / 100) + "";
        ns.monthlyValueReplacementInCents = (p.monthlyValueReplacementInCents / 100) + "";
        ns.originalCharge = (p.amount / 100) + "";
        ns.monthThreshold = p.noRefundAfterMonths;
      }
    }
    ns.selectedPlanId = planId;
    ns.calculated = false;
    this.setState( ns );
  }

  private setup() {
    this.setState({ loading: true }, async () => {
      try{
        // first, get a list of plans
        const plansResult = await BillingAPI.getPlans({});
        let result = plansResult.body.data;
        // TODO: this is a fun bug I don't have time to fix; the original implementation of getting the plans
        // without a product family in the sdk has a bug that nests data in a data, so we will guard here
        // and investigate when we have more time
        if(result.data){
          result = result.data;
        }
        const plans: any[] = [];
        for(const p of result){
          // for now, only include plans that should be shown, aren't free, are wagz2, and are in a sold product family
          if(p.showPlan && p.amount !== 0 && p.platform === "wagz2"){
            const familyDisplay = familyToDisplay(p.productFamily);
            p.amountDisplay = formatCurrency(p.amount);
            p.optionDisplay = `${familyDisplay} (${p.productFamily}) - ${p.term} - ${p.amountDisplay}`;
            plans.push(p);
          }
        }
        plans.sort((a, b) => {
          return a.optionDisplay > b.optionDisplay ? 1 : -1;
        })
        this.setState({ loading: false, plans });
      }catch(err){
        this.setState({ loading: false });
      }
    })
  }

  private calculate(){
    this.setState( {loading: true }, async () => {
      try{
        let data: any = {
          planId: this.state.selectedPlanId !== "" ? parseInt(this.state.selectedPlanId) : "",
          deviceId: this.state.selectedDeviceId !== "" ? parseInt(this.state.selectedDeviceId) : "",
          originalCharge: this.state.originalCharge !== "" ? parseFloat(this.state.originalCharge) * 100 : "",
          monthsUsed: this.state.monthsUsed !== "" ? parseInt(this.state.monthsUsed) : "",
          monthsThreshold: this.state.monthThreshold !== "" ? parseInt(this.state.monthThreshold) : "",
          monthlyValueCancellationInCents: this.state.monthlyValueCancellationInCents !== "" ? parseFloat(this.state.monthlyValueCancellationInCents) * 100 : "",
          monthlyValueReplacementInCents: this.state.monthlyValueReplacementInCents !== "" ? parseFloat(this.state.monthlyValueReplacementInCents) * 100 : "",
          taxRatePercent: this.state.taxRatePercent !== "" ? parseFloat(this.state.taxRatePercent) / 100 : "",
          partialRefundsInCents: this.state.partialRefundsInCents !== "" ? parseFloat(this.state.partialRefundsInCents) * 100 : "",
        };

        const result = await BillingAPI.calculateRefund("wagz2", data);
        const r = result.body.data;

        // parse the outputs for display
        const preTaxCancellationRefundInCents = formatCurrency(r.preTaxCancellationRefundInCents);
        const preTaxReplacementRefundInCents = formatCurrency(r.preTaxReplacementRefundInCents);
        const postTaxCancellationRefundInCents = formatCurrency(r.postTaxCancellationRefundInCents);
        const postTaxReplacementRefundInCents = formatCurrency(r.postTaxReplacementRefundInCents);
        this.setState({
          preTaxCancellationRefundInCents,
          preTaxReplacementRefundInCents,
          postTaxCancellationRefundInCents,
          postTaxReplacementRefundInCents,
          errors: r.errors,
          calculated: true,
          loading: false,
        })

      }catch(err){
        error("We could not perform those calculations. Check the console for more information.");
        console.log(err);
        this.setState({ loading: false });
      }
    });
  }
}